import { IUser } from "@snackpass/snackpass-types";
import { getSelectedStores } from "../helpers";
import { CellInfo } from "react-table";
import Swal from "sweetalert2";
import {
    ToggleablePermissions,
    storesNamesMap
} from "src/components/AdminForm/types";
import api from "src/api";
import Modal from "react-modal";
import { useEffect, useMemo, useState } from "react";

const handleRDBAllClick = async (
    admin: IUser,
    isOn: boolean,
    storeList: storesNamesMap[],
    setAdmin: Function
) => {
    const storeNameList = storeList.map((entry) => entry.label);
    const storeIds = storeList.map((entry) => entry.value);
    const newPermissions = Object.values(ToggleablePermissions).reduce(
        (acc: { [key: string]: string[] }, key: string) => {
            acc[key] = isOn ? storeIds : [];
            return acc;
        },
        {}
    );
    const permissions = {
        ...admin.permissions,
        ...newPermissions
    };
    try {
        let response = await api.admins.update(admin._id, {
            user: { permissions },
            communicationPreferences: []
        });
        Swal.fire({
            title: `Permissions Updated to ${isOn ? "ON" : "OFF"} For:`,
            html: `${storeNameList.join(
                "<br />"
            )}<br /><span style="color:red">isOwner and hasPayouts always excluded from changes</span>`,
            width: "80%"
        });
        setAdmin(response.data.user);
    } catch (err) {
        Swal.fire(
            "Error setting permissions",
            err.response.data.message.details[0].message
        );
        console.log(err);
    }
};

const refreshThisAdmin = async (admin: IUser, setAdmin: Function) => {
    // blank update to trigger refresh of this admin's permissions
    let response = await api.admins.update(admin._id, {
        user: {},
        communicationPreferences: []
    });
    setAdmin(response.data.user);
};

const handleIndividualPermissionClick = async (
    admin: IUser,
    isOn: boolean,
    permission: string,
    setResult: (result: string) => void,
    setInternalAdmin: (admin: IUser) => void
) => {
    const permissions = {
        ...admin.permissions,
        [permission]: isOn ? admin.snackpassPermissions.storeIds : []
    };
    try {
        const response = await api.admins.update(admin._id, {
            user: { permissions },
            communicationPreferences: []
        });
        setResult(`${permission} turned ${isOn ? "on" : "off"}`);
        setInternalAdmin(response.data.user);
    } catch (err) {
        setResult(
            `Error setting permission: ${err.response.data.message.details[0].message}`
        );
        console.log(err);
    }
};

export const permissionsColumn = (
    setAdmin: Function,
    storeNameById: storesNamesMap
) => ({
    Header: "RDB Perms",
    id: "snackpass_permission",
    accessor: "snackpassPermissions",
    minWidth: 160,
    Cell: (props: CellInfo) => {
        Modal.setAppElement("#root");
        const [showSetPermModal, setShowSetPermModal] = useState(false);
        const [showCurPermModal, setShowCurPermModal] = useState(false);
        // when the admins table is searched it updates props.original but react just sees
        // another IUser object and doesn't realize anything has changed. Hence we tie to admin._id
        const adminOfStoreList = useMemo(
            () => getSelectedStores(props.original, storeNameById),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [props.original._id]
        );
        // Due to trying to accomplish some api calls in the modal and not refresh the underlying table
        // we are on top of we need to keep a local copy of the admin. For toggling individual permissions
        // we will keep this copy up to date in case multiple toggles are flipped in succession.
        // Whenever that modal is closed we will trigger an update to the underlying table.
        const [internalAdmin, setInternalAdmin] = useState(props.original);

        useEffect(() => {
            setInternalAdmin(props.original);
        }, [props.original, props.original._id]);

        return (
            <div style={permsStyle}>
                <button
                    style={permsStyle.buttonView}
                    onClick={() => {
                        setShowCurPermModal(true);
                    }}
                >
                    View
                </button>
                <button
                    style={permsStyle.buttonToggles}
                    onClick={() => {
                        setShowSetPermModal(true);
                    }}
                >
                    Toggles
                </button>
                <Modal
                    style={modalStyles.toggle}
                    isOpen={showSetPermModal}
                    onRequestClose={() => {
                        setShowSetPermModal(false);
                    }}
                    onAfterClose={() => {
                        refreshThisAdmin(props.original, setAdmin);
                    }}
                    contentLabel="Set Permissions"
                >
                    <PermissionTogglesModal
                        admin={internalAdmin}
                        adminOfStores={adminOfStoreList}
                        setInternalAdmin={setInternalAdmin}
                        setAdmin={setAdmin}
                        close={() => {
                            setShowSetPermModal(false);
                        }}
                    />
                </Modal>
                <Modal
                    style={modalStyles.current}
                    isOpen={showCurPermModal}
                    onRequestClose={() => {
                        setShowCurPermModal(false);
                    }}
                    contentLabel="Current Permissions"
                >
                    <CurrentPermissionsModal
                        admin={internalAdmin}
                        storeList={storeNameById}
                        close={() => {
                            setShowCurPermModal(false);
                        }}
                    />
                </Modal>
            </div>
        );
    }
});

type PermissionsTogglesProps = {
    admin: IUser;
    adminOfStores: {
        value: string;
        label: string;
    }[];
    setAdmin: Function;
    setInternalAdmin: (admin: IUser) => void;
    close: () => void;
};

const PermissionTogglesModal = ({
    admin,
    adminOfStores,
    setAdmin,
    setInternalAdmin,
    close
}: PermissionsTogglesProps) => {
    const storeCount = admin.snackpassPermissions.storeIds.length;
    const [resultText, setResultText] = useState("");

    return (
        <div>
            <div style={permsStyle.buttonRow}>
                <div>All changes will apply to {storeCount} stores</div>
                <div>
                    <button onClick={close}>x</button>
                </div>
            </div>
            <div style={permsStyle.resultText}>{resultText}</div>
            <div style={permsStyle.buttonRow}>
                <button
                    style={permsStyle.buttonOn}
                    onClick={() => {
                        handleRDBAllClick(admin, true, adminOfStores, setAdmin);
                    }}
                >
                    All On
                </button>
                <button
                    style={permsStyle.buttonOff}
                    onClick={() => {
                        handleRDBAllClick(
                            admin,
                            false,
                            adminOfStores,
                            setAdmin
                        );
                    }}
                >
                    All Off
                </button>
            </div>
            {Object.values(ToggleablePermissions).map((permission) => {
                return (
                    <div key={permission} style={permsStyle.buttonRow}>
                        <button
                            style={permsStyle.buttonOn}
                            onClick={() => {
                                handleIndividualPermissionClick(
                                    admin,
                                    true,
                                    permission,
                                    setResultText,
                                    setInternalAdmin
                                );
                            }}
                        >
                            On
                        </button>
                        {permission}
                        <button
                            style={permsStyle.buttonOff}
                            onClick={() => {
                                handleIndividualPermissionClick(
                                    admin,
                                    false,
                                    permission,
                                    setResultText,
                                    setInternalAdmin
                                );
                            }}
                        >
                            Off
                        </button>
                    </div>
                );
            })}
        </div>
    );
};

type PermissionsViewProps = {
    admin: IUser;
    storeList: storesNamesMap;
    close: () => void;
};

const CurrentPermissionsModal = ({
    admin,
    storeList,
    close
}: PermissionsViewProps) => {
    if (admin.permissions?.employeeId) {
        delete admin.permissions.employeeId;
    }
    type Permissions = {
        [key: string]: string[];
    };
    // @ts-ignore we just removed employeeId above
    const allPermissions: Permissions = admin.permissions ?? {};
    return (
        <>
            <table style={tableStyle}>
                <thead>
                    <tr>
                        <th style={tableStyle}>Permission</th>
                        <th style={tableStyle}>Stores</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(allPermissions).map((permission) => {
                        let storeIds = allPermissions[permission];
                        if (!Array.isArray(storeIds)) {
                            storeIds = [storeIds];
                        }
                        const stores = storeIds
                            .map((storeId) => {
                                return storeList[storeId] ?? storeId;
                            })
                            .join(", ");
                        return (
                            <tr key={permission}>
                                <td style={tableStyle}>{permission}</td>
                                <td style={tableStyle}>{stores}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div>
                <button style={permsStyle.buttonOn} onClick={close}>
                    Close
                </button>
            </div>
        </>
    );
};

const modalStyles = {
    current: {
        content: {
            margin: "auto",
            width: "90%"
        }
    },
    toggle: {
        content: {
            margin: "auto",
            width: "40%"
        }
    }
};

const permsStyle = {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "space-around",
    buttonOn: {
        color: "white",
        borderRadius: "12px",
        backgroundColor: "#2fc3ff",
        border: "1px solid #6b6b6b",
        margin: "3px"
    },
    buttonOff: {
        color: "white",
        borderRadius: "12px",
        backgroundColor: "rgb(255, 47, 54)",
        border: "1px solid #6b6b6b",
        margin: "3px"
    },
    buttonView: {
        color: "white",
        borderRadius: "12px",
        backgroundColor: "rgb(55, 98, 46)",
        border: "1px solid #6b6b6b",
        marginBottom: "5px",
        marginRight: "3px"
    },
    buttonToggles: {
        color: "white",
        borderRadius: "12px",
        backgroundColor: "rgb(182, 96, 10)",
        border: "1px solid #6b6b6b",
        marginBottom: "5px",
        marginLeft: "3px"
    },
    buttonRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    resultText: {
        border: "1px dashed #6b6b6b",
        minHeight: "23px",
        width: "100%",
        color: "red"
    }
};

const tableStyle = {
    border: "1px solid black"
};
