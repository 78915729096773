import formatNumber from "format-number";
import _ from "lodash";
import React from "react";
import swal from "sweetalert2";

import { Button, TextInput } from "../../../../SharedComponents";
import api from "../../../../api";
import { IUser } from "@snackpass/snackpass-types";
import { Colors } from "../../../../utils/Colors";

type Props = {
    setAdmin: Function;
    admin: IUser;
};
type State = {
    password: string | null;
    hidden: boolean;
    isSaving: boolean;
};

class Password extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            password: _.get(
                props,
                "admin.snackpassPermissions.adminPassword",
                ""
            ),
            hidden: true,
            isSaving: false,
        };
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        const oldPassword = this.props.admin.snackpassPermissions.adminPassword;
        const newPassword = prevProps.admin.snackpassPermissions.adminPassword;
        if (oldPassword !== newPassword) {
            this.setState({
                password: _.get(
                    this.props,
                    "admin.snackpassPermissions.adminPassword",
                    ""
                ),
            });
        }
    }

    handleButtonPress = async () => {
        this.setState({ isSaving: true });

        try {
            await this.savePassword();
        } catch (err) {
            swal.fire({
                title: "Error Saving",
                text: _.get(err, "response.data.message"),
                type: "error",
            });
        }
        this.setState({ isSaving: false });
    };

    /**
     * Save the current password to the user's Firebase account and set their admin password in
     * Mongo.
     */
    savePassword = async () => {
        let { admin } = this.props;

        let response = await api.admins.setPassword(admin._id, {
            password: this.state.password,
        });

        this.props.setAdmin(response.data.user);
    };

    /**
     * Return whether the user can save this password. The password must be truthy and not be
     * identical to their existing password.
     *
     * @return {boolean}
     */
    canSavePassword = () => {
        const oldPassword =
            this.props.admin.snackpassPermissions.adminPassword || "";
        return this.state.password && this.state.password !== oldPassword;
    };

    /**
     * Generate a random password and populate the password field with this.
     */
    generateRandomPassword = () => {
        const randomPassword = formatNumber({
            padLeft: 6,
            integerSeparator: "",
        })(Math.floor(Math.random() * 1000000));
        this.setState({
            password: randomPassword,
            hidden: false,
        });
    };

    render() {
        const existingPassword =
            this.props.admin.snackpassPermissions.adminPassword;
        const saveButtonDisabled =
            !this.state.password || this.state.password === existingPassword;

        const onFocus = () => {
            const password =
                this.state.password === existingPassword
                    ? ""
                    : this.state.password;
            this.setState({ password, hidden: false });
        };

        const onBlur = () => {
            if (this.state.password === "")
                this.setState({
                    password: existingPassword || null,
                    hidden: true,
                });
        };

        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    position: "relative",
                }}
            >
                <TextInput
                    style={{
                        width: 150,
                        height: "100%",
                        borderTopLeftRadius: 5,
                        borderBottomLeftRadius: 5,
                    }}
                    placeholder="Password"
                    containerStyle={{ margin: 0 }}
                    value={this.state.password || ""}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    type={this.state.hidden ? "password" : "text"}
                    onChangeText={password => this.setState({ password })}
                />
                <Button
                    disabled={saveButtonDisabled}
                    loading={this.state.isSaving}
                    label="Save"
                    onPress={this.handleButtonPress}
                    type="button"
                    style={{
                        margin: 0,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        height: "100%",
                    }}
                />
                <Button
                    label={"🎲 Random"}
                    onPress={this.generateRandomPassword}
                    type="button"
                    style={{
                        margin: 0,
                        height: "100%",
                        marginLeft: 5,
                        color: Colors.blue,
                        backgroundColor: Colors.white,
                        border: `1px solid ${Colors.blue}`,
                    }}
                />
            </div>
        );
    }
}

export default Password;
