import React, { CSSProperties, useState } from "react";
import { Image } from "../../SharedComponents";
import { Colors } from "../../utils";

interface Props {
    onChange?: any;
    onClear?: any;
    onSearch: any;
    style?: CSSProperties;
    containerStyle?: CSSProperties;
    placeholder?: string;
    searchIcon?: boolean;
    buttonColor?: string;
}

const MySearch = (props: Props) => {
    const [value, setValue] = useState("");
    const {
        searchIcon,
        placeholder,
        containerStyle,
        style,
        onChange,
        onClear,
        onSearch,
        buttonColor,
    } = props;

    const handleChange = (value: string) => {
        setValue(value);
        if (onChange) {
            onChange(value);
        }
    };

    const handleClear = () => {
        setValue("");
        if (onClear) {
            onClear();
        }
    };

    const handleSearch = () => {
        if (onSearch) {
            onSearch(value);
        }
    };

    const enterSearch = (e: any) => {
        if (e.key === "Enter") {
            handleSearch();
        }
    };

    return (
        <div style={Object.assign({}, styles.defaultContainer, containerStyle)}>
            {searchIcon && (
                <Image
                    source={require("../../assets/icons/magnifyingglass.png")}
                    style={styles.searchIcon}
                />
            )}
            <input
                type="text"
                name="storeSearch"
                id="storeSearch"
                value={value}
                onKeyPress={enterSearch}
                onChange={(e: any) => handleChange(e.target.value)}
                style={Object.assign({}, styles.searchArea, style)}
                placeholder={placeholder ? placeholder : "Search"}
            />
            <button
                style={Object.assign({}, styles.searchButton, {
                    backgroundColor: buttonColor ? buttonColor : Colors.blue,
                })}
                onClick={handleSearch}
            >
                Search
            </button>
            {value && (
                <p style={styles.clearButton} onClick={handleClear}>
                    clear
                </p>
            )}
        </div>
    );
};

const styles = {
    searchArea: {
        width: "100%",
        padding: "8px 10px",
        borderRadius: 5,
    } as CSSProperties,
    defaultContainer: {
        display: "flex",
        alignItems: "center",
        marginBottom: 2,
    } as CSSProperties,
    searchIcon: {
        width: "1.3rem",
        height: "1.3rem",
        margin: 5,
    } as CSSProperties,
    clearButton: {
        padding: 10,
        cursor: "pointer",
    },
    searchButton: {
        borderRadius: 5,
        border: "1px solid #efefef",
        color: "white",
        marginLeft: 10,
        width: 100,
        height: 39,
    },
};

export default MySearch;
