import React, { ReactNode } from "react";
export interface IView {
    row?: boolean;
    backgroundColor?: string;
    children?: ReactNode;
    style?: React.CSSProperties;
    className?: string;
    onPress?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const View = ({
    row,
    backgroundColor,
    children,
    className,
    onPress,
    style,
}: IView) => (
    <div
        className={className || ""}
        onClick={onPress}
        style={{
            display: "flex",
            backgroundColor: backgroundColor || "",
            flexDirection: row ? "row" : "column",
            ...style,
        }}
    >
        {children}
    </div>
);
