import { ITablet } from "@snackpass/snackpass-types";
import React, { Component } from "react";
import { Checkbox } from "semantic-ui-react";
import swal from "sweetalert2";

import { Colors } from "../../../utils/Colors";
import { Col, Row, Text } from "../../../SharedComponents";

type Props = {
    tablet: ITablet;
    isPrimary: boolean;
    setPrimaryStoreTablet: Function;
};

type State = {
    isLoading: boolean;
};

export default class TabletRow extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: false,
        };
    }

    _formatDate = (isoDate: string) => {
        let date = new Date(isoDate);
        let dateString = date.toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        });
        return dateString;
    };

    _getPrimaryTabletStatus = () => {
        if (this.props.isPrimary) {
            return "✅";
        }

        const lastPing = this.props.tablet.lastPing
            ? this._formatDate(this.props.tablet.lastPing.toString())
            : "null";

        return (
            <Checkbox
                disabled={this.state.isLoading}
                checked={false}
                onChange={async (event, data) => {
                    const result = await swal.fire({
                        title: `Set tablet ${this.props.tablet.serial} as primary for ${this.props.tablet.store.name}?`,
                        text: `Last ping time: ${lastPing}`,
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: Colors.green,
                        cancelButtonColor: Colors.gray,
                        confirmButtonText: "Set as primary",
                        cancelButtonText: "Cancel",
                        focusCancel: true,
                    });

                    if (!result.value) {
                        return;
                    }

                    this.setState({ isLoading: true });

                    try {
                        this.props.setPrimaryStoreTablet(this.props.tablet._id);
                    } catch (err) {
                        swal.fire({
                            title: `Couldn't set primary tablet`,
                            text: JSON.stringify(err),
                            type: "error",
                        });
                    }

                    this.setState({ isLoading: false });
                }}
            />
        );
    };

    render() {
        const { tablet } = this.props;
        const primaryTabletStatus = this._getPrimaryTabletStatus();

        return (
            <Row>
                {/* User Name */}
                <Col flex={4}>
                    <Text small>{tablet.user.name}</Text>
                </Col>
                {/* Primary? */}
                <Col
                    flex={4}
                    style={{ justifyContent: "center", alignItems: "center" }}
                >
                    <Text small>{primaryTabletStatus}</Text>
                </Col>
                {/* Serial Number */}
                <Col
                    flex={4}
                    style={{ justifyContent: "center", alignItems: "center" }}
                >
                    <Text small>{tablet.serial ? tablet.serial : null}</Text>
                </Col>
                {/* Printer Model */}
                <Col
                    flex={4}
                    style={{ justifyContent: "center", alignItems: "center" }}
                >
                    <Text small>
                        {tablet.printer && tablet.printer.model
                            ? tablet.printer.model
                            : null}
                    </Text>
                </Col>
                {/* Printer Connection */}
                <Col
                    flex={4}
                    style={{ justifyContent: "center", alignItems: "center" }}
                >
                    <Text
                        small
                        style={{
                            color:
                                tablet.printer && tablet.printer.isConnected
                                    ? Colors.green
                                    : Colors.red,
                        }}
                    >
                        {tablet.printer && tablet.printer.isConnected !== null
                            ? tablet.printer.isConnected
                                ? "connected"
                                : "not connected"
                            : null}
                    </Text>
                </Col>
                {/*<Col
                    flex={2}
                    style={{ justifyContent: "center", alignItems: "center" }}
                >
                    <Text small>
                        {tablet.printer && tablet.printer.port
                            ? tablet.printer.port
                            : null}
                    </Text>
                </Col>*/}
                <Col
                    flex={4}
                    style={{ justifyContent: "center", alignItems: "center" }}
                >
                    <Text small>
                        {tablet.lastPing
                            ? this._formatDate(tablet.lastPing.toString())
                            : null}
                    </Text>
                </Col>
                <Col
                    flex={4}
                    style={{ justifyContent: "center", alignItems: "center" }}
                >
                    <Text small>
                        {tablet.battery &&
                            (tablet.battery.level
                                ? Math.round(tablet.battery.level) + "%"
                                : null)}
                    </Text>
                </Col>
                <Col
                    flex={4}
                    style={{ justifyContent: "center", alignItems: "center" }}
                >
                    <Text
                        small
                        style={{
                            color:
                                tablet.battery && tablet.battery.isCharging
                                    ? Colors.green
                                    : Colors.red,
                        }}
                    >
                        {tablet.battery && tablet.battery.isCharging !== null
                            ? tablet.battery.isCharging
                                ? "charging"
                                : "not charging"
                            : null}
                    </Text>
                </Col>
                <Col
                    flex={4}
                    style={{ justifyContent: "center", alignItems: "center" }}
                >
                    <Text small>
                        {tablet.orders && tablet.orders.active !== null
                            ? "active: " + tablet.orders.active
                            : null}{" "}
                    </Text>
                    <Text small>
                        {tablet.orders && tablet.orders.pending !== null
                            ? "pending: " + tablet.orders.pending
                            : null}
                    </Text>
                </Col>
                <Col flex={1} />
            </Row>
        );
    }
}
