import React from "react";
import { useSelector } from "react-redux";

import { getStoreSubscriptionState } from "../../redux/storeSubscription";
import { Text } from "../../SharedComponents";
import { panel } from "../../utils/Styles";
import StorePicker from "./StorePicker";
import { CreateSubscriptionForm } from "./SubscriptionForm";

const StoreSubscriptionScreen = () => {
    const selectedStore = useSelector(getStoreSubscriptionState);
    return (
        <div style={panel}>
            <Text large style={styles.header}>
                Subscriptions
            </Text>
            <StorePicker />
            <CreateSubscriptionForm storeSubscription={selectedStore} />
        </div>
    );
};

export default StoreSubscriptionScreen;

const styles = {
    header: {
        marginBottom: 15,
    },
    title: {
        flex: 1,
        display: "flex",
        justifyContent: "space-between",
    },
};
