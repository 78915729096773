import React from "react";
import { Input } from "../../../SharedComponents";

type TochiMaterialsFormProps = {
    type: string | null;
    root: string;
};

const optionalMasks = ["decalMask", "alphaMask"];

//An abstraction to show materials
//Add an input of type select above this component
//Pass formProps.values./../.type as the "type" prop and the location in the structure as the root prop
//Make sure the root ends with a .
export const TochiMaterialsForm = ({ type, root }: TochiMaterialsFormProps) => {
    if (root && root[root.length - 1] !== ".")
        console.log("make sure your root prop ends with a .");

    if (type === "TEXTURE")
        return (
            <>
                {TochiMaterialsFormHelper(
                    ["Texture Map", "Alpha Mask"],
                    ["map", "alphaMask"],
                    root
                )}
            </>
        );
    else if (type === "GRADIENTMASK")
        return (
            <>
                {TochiMaterialsFormHelper(
                    ["Decal Mask", "Alpha Mask", "Gradient Mask"],
                    ["decalMask", "alphaMask", "gradientMask"],
                    root,
                    ["Color"]
                )}
            </>
        );
    else if (type === "RGBGRADIENTMASK")
        return (
            <>
                {TochiMaterialsFormHelper(
                    ["Decal Mask", "Alpha Mask", "Gradient Mask", "RGB Mask"],
                    ["decalMask", "alphaMask", "gradientMask", "rgbMask"],
                    root,
                    ["RColor", "GColor", "BColor"]
                )}
            </>
        );
    return <></>;
};

const TochiMaterialsFormHelper = (
    types: string[],
    names: string[],
    root: string,
    colorFields: string[] = []
) => {
    return (
        <div>
            {renderInputs(types, names, root)}
            {colorFields.length > 0 &&
                renderColorArrayFields(colorFields, root)}
        </div>
    );
};

const renderInputs = (types: string[], names: string[], root: string) => {
    if (types.length !== names.length)
        return <p>Something is wrong with the types and names arrays</p>;
    else {
        let renderedInputs = [];
        for (let i = 0; i < types.length; i += 1) {
            renderedInputs.push(
                <Input
                    name={`${root}${names[i]}`}
                    label={types[i]}
                    description={`${types[i]} to apply to the material`}
                    type="file-upload"
                    overrideMaxFileSize={500 * 1000}
                    showFilePreview={true}
                    required={!optionalMasks.includes(names[i])}
                />
            );
            if (names[i] === "decalMask")
                renderedInputs.push(
                    <Input
                        type="text"
                        name="decalColor"
                        label="Decal Color"
                        description="Color to apply to decal mask"
                    />
                );
        }
        return renderedInputs;
    }
};

const renderColorArrayFields = (fields: string[], root: string) => {
    let renderedFields = [];
    for (let f of fields) {
        renderedFields.push(
            <TochiColorArrayField
                label={f}
                colorsName={`${root}${f.toLowerCase()}s`}
            />
        );
    }
    return renderedFields;
};

const TochiColorArrayField = ({
    colorsName,
    label,
}: {
    colorsName: string;
    label: string;
}) => {
    return (
        <div style={{ display: "flex" }}>
            <Input
                inputContainerStyle={{ width: "50%" }}
                name={colorsName}
                label={label}
                description={`${label} to apply to gradient mask`}
                type="array"
                arrayType="color-text"
                required
            />
        </div>
    );
};
