import React from "react";

import { Button } from "../../SharedComponents";
import { Colors } from "../../utils/Colors";

type Props = {
    isDestructiveStyle: boolean;
    label: string;
    onPress: () => void;
};

export const ActionButton = ({ isDestructiveStyle, label, onPress }: Props) => (
    <Button
        style={{
            right: 20,
            width: 100,
            position: "relative",
            color: isDestructiveStyle ? Colors.white : Colors.gray,
            backgroundColor: isDestructiveStyle ? Colors.red : Colors.offWhite
        }}
        label={label}
        onPress={onPress}
    />
);
