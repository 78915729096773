import { IStore, IUser } from "@snackpass/snackpass-types";
import { compose, map, prop } from "lodash/fp";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";

import { refreshStores } from "./utils";

import { getPathFromState } from "../../utils/Helpers";

type StorePickerProps = {
    dispatchOnChange: (selectedStoreId: string | null) => {
        type: string;
        selectedStoreId: string | null;
    };
};

const StorePicker = ({ dispatchOnChange }: StorePickerProps) => {
    // When going straight to the Kiosks page, sometimes the getStores request
    // fires before the user is set, resulting in a 403, so we make currentUser
    // a dependency to trigger a rerender when the user eventually gets set.
    const currentUser: IUser[] = getPathFromState("user");
    const storeOptions = map(
        (store: IStore) => ({
            label: `${store.name} (${store.region})`,
            value: store._id,
        }),
        getPathFromState("stores").filter((s: IStore) => !s.isArchived)
    );
    const dispatch = useDispatch();

    useEffect(() => {
        refreshStores(dispatch);
    }, [currentUser]);

    const onChange = compose(dispatch, dispatchOnChange, prop("value"));
    return (
        <Select
            className="snackpass__react-select"
            placeholder="Pick a store to start"
            options={storeOptions}
            onChange={onChange}
        />
    );
};

export default StorePicker;
