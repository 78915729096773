import ReactDOM from "react-dom";
import App from "./App";
import "semantic-ui-css/semantic.min.css";
import "react-datasheet/lib/react-datasheet.css";
import "./index.css";
import "./reactDateTime.css";
import "antd/dist/antd.css";
import "react-responsive-modal/styles.css";

import { unregister } from "./serviceWorker";

// Unregister servicerWorker to bust browser cache on new builds
unregister();

ReactDOM.render(<App />, document.getElementById("root"));
