import React, { CSSProperties, useState } from "react";
import { Tooltip } from "antd";
import styled from "styled-components";

import { Colors } from "../utils/Colors";
import { copyToClipboard } from "../utils/Helpers";

type Props = {
    iconStyle?: any;
    icon?: any;
    value: string;
    copyText?: string;
    style?: CSSProperties;
};

const CopyButton = styled.div`
    &:hover {
        background-color: #d4d4da;
    }
`;

export const Copy = ({ iconStyle, icon, value, copyText, style }: Props) => {
    let [popupIsVisible, setPopupIsVisible] = useState(false);

    return (
        <Tooltip
            visible={popupIsVisible}
            title={
                <div style={{ padding: 0, color: Colors.white }}>
                    {copyText || "Copied!"}
                </div>
            }
            trigger="click"
            placement="bottom"
            arrowPointAtCenter={false}
        >
            <CopyButton
                onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    copyCode(value, setPopupIsVisible);
                }}
                className="copy-value"
                style={{
                    position: "relative",
                    marginRight: 5,
                    border: "1px solid #efefef",
                    color: Colors.gray,
                    cursor: "pointer",
                    padding: "5px 10px",
                    display: "inline-block",
                    borderRadius: 5,
                    fontSize: 15,
                    ...style,
                }}
            >
                {icon || <i style={iconStyle} className="fas fa-copy" />}
            </CopyButton>
        </Tooltip>
    );
};

const copyCode = (
    text: string,
    setPopupIsVisible: (isVisible: boolean) => void
) => {
    setPopupIsVisible(true);
    copyToClipboard(text);
    setTimeout(() => setPopupIsVisible(false), 1000);
};
