import { compose } from "lodash/fp";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";

import {
    getPrintersForFoodhall,
    getPrintersForStore,
    getPrintersForSearch,
} from "../../redux/printers";
import { getPathFromState } from "../../utils/Helpers";
import _ from "lodash";
import { View } from "src/components";

import StorePicker from "../../components/StorePicker";
import FoodhallPicker from "../../components/FoodhallPicker";
import CreatePrinterForm from "./CreatePrinterForm";
import PrintersTable from "./PrintersTable";

export enum MODE {
    STORE = "store",
    FOODHALL = "foodhall",
    SEARCH = "search",
}

const modeOptions = [
    { label: "Store", value: MODE.STORE },
    // { label: "Foodhall", value: MODE.FOODHALL },
    { label: "Search", value: MODE.SEARCH }
];

const Printers = () => {
    const [mode, setMode] = useState(MODE.STORE);
    const dispatch = useDispatch();
    const selectedStoreId = getPathFromState("printers.selectedStoreId");
    const selectedFoodhallId = getPathFromState("printers.selectedFoodhallId");
    const printerSearch = getPathFromState("printers.search"); 

    useEffect(() => {
        compose(dispatch, getPrintersForStore)(null);
        compose(dispatch, getPrintersForFoodhall)(null);
    }, []);

    const onChange = (option: any) => {
        compose(dispatch, getPrintersForStore)(null);
        compose(dispatch, getPrintersForFoodhall)(null);
        compose(dispatch, getPrintersForSearch)(null);
        setMode(option.value);
    };

    const searchOnChange = (e: React.FormEvent<HTMLInputElement>) => {
        compose(dispatch, getPrintersForSearch)(e.currentTarget.value);
    };

    let renderedMode;
    switch (mode) {
        case MODE.STORE:
            renderedMode = <StorePicker dispatchOnChange={getPrintersForStore} />;
            break;
        case MODE.FOODHALL:
            renderedMode = <FoodhallPicker dispatchOnChange={getPrintersForFoodhall} />;
            break;
        case MODE.SEARCH:
            renderedMode = (
                <input
                    style={styles.input}
                    type="text"
                    placeholder="Search by printer serial or name..."
                    onChange={searchOnChange}
                />
            );
            break;
    }

    return (
        <>
            <h1>Printers 🖨</h1>
            <View style={{ flexDirection: "row" }}>
                <View style={{ marginRight: 10 }}>
                    <Select
                        className="snackpass__react-select"
                        value={_.find(
                            modeOptions,
                            ({ value }) => value === mode
                        )}
                        options={modeOptions}
                        onChange={onChange}
                    />
                </View>
                {renderedMode}
            </View>

            {selectedStoreId || selectedFoodhallId || printerSearch ? (
                <div>
                    <br />
                    {selectedStoreId ? <CreatePrinterForm /> : null}
                    <PrintersTable mode={mode}/>
                </div>
            ) : null}
        </>
    );
};

const styles = {
    input: {
        width: "300px",
        height: "37px",
    } as React.CSSProperties,
};

export default Printers;
