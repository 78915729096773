import axios from "axios";
import config from "src/config";
import firebase from "../firebase";

const instance = axios.create({
    baseURL: config.apiURL
});

instance.defaults.headers.common["X-Snackpass-Client"] = "@snackpass/snackface";
instance.defaults.headers.common["X-Snackpass-Client-Version"] =
    process.env.REACT_APP_COMMIT_REF ?? process.env.REACT_APP_ENV ?? "";

firebase.auth().onAuthStateChanged(async (user) => {
    if (user) {
        const token = await user.getIdToken();
        instance.defaults.headers.common.Authorization = `Bearer ${token}`;

        setInterval(async () => {
            const token = await user.getIdToken(true);
            instance.defaults.headers.common.Authorization = `Bearer ${token}`;
        }, 10 * 60 * 1000);
    } else {
        delete instance.defaults.headers.common.Authorization;
    }
});

export default instance;
