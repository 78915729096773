import React from "react";
import { IPromotion, IHoursSchema } from "@snackpass/snackpass-types";

import moment from "moment";
import { connectModal } from "redux-modal";

import Modal from "react-modal";
import { Helpers } from "../utils";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
    },
    overlay: {
        zIndex: 1000,
    },
};

const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
];
export function getFormattedTime(fourDigitTime: number) {
    let formatted = fourDigitTime.toString().padStart(4, "0");
    let hours24 = parseInt(formatted.substring(0, 2), 10);
    let hours = ((hours24 + 11) % 12) + 1;
    let amPm = hours24 > 11 ? "pm" : "am";
    let minutes = formatted.substring(2);

    return hours + ":" + minutes + amPm;
}
const momentFormat = "MMMM Do YYYY, h:mm a";

export function getHoursText(hours: IHoursSchema) {
    let ret = "";
    let times: string[] = [];
    hours.local.forEach(range => {
        // take % 7 just to guarantee the index is a valid
        // index for daysOfWeek array
        // (this was in the code from a previous version so leaving it in just in case removing it breaks something)
        let startDay = Math.floor(range.start / (60 * 24)) % 7;
        let startHours = Math.floor((range.start % (60 * 24)) / 60);
        let startMin = range.start % 60;
        // take % 7 just to guarantee the index is a valid
        // index for daysOfWeek array
        // (this was in the code from a previous version so leaving it in just in case removing it breaks something)
        let endDay = Math.floor(range.end / (60 * 24)) % 7;
        let endHours = Math.floor((range.end % (60 * 24)) / 60);
        let endMin = range.end % 60;
        times.push(
            `${daysOfWeek[startDay]} ${getFormattedTime(
                startHours * 100 + startMin
            )} - ${daysOfWeek[endDay]} ${getFormattedTime(
                endHours * 100 + endMin
            )}`
        );
    });
    ret += times.join("\n");
    return ret;
}

export function getBooleans(promo: any) {
    return Object.keys(promo).map((promoId: string, index: number) => {
        let value = promo[promoId as keyof IPromotion];
        let type = typeof value;
        if (type !== "boolean") {
            return null;
        }
        if (!value) {
            return null;
        }
        return (
            <tr>
                <td>{promoId}</td>
                <td>✅</td>
            </tr>
        );
    });
}

export function getRewardExpiration(promo: IPromotion) {
    if (!promo.rewardExpiration) {
        return null;
    }
    let rewardExpiration = promo.rewardExpiration;
    let rewardExpirationDate = promo.rewardExpiration.date;
    let rewardExpirationDays = rewardExpiration.seconds
        ? rewardExpiration.seconds / 86400
        : "";
    return (
        <div>
            <br />
            <h3>reward expiration</h3>
            <tr>
                <td>reward expires</td>
                {promo.rewardExpiration.date ? (
                    <td>
                        {moment(rewardExpirationDate || new Date()).format(
                            momentFormat
                        )}
                    </td>
                ) : (
                    <td>{`${rewardExpirationDays} days`}</td>
                )}
            </tr>
        </div>
    );
}

export function getSubsidization(promo: IPromotion) {
    if (!promo.accounting || !promo.accounting.contributionPolicies.length) {
        return null;
    }
    const contributionPolicy = promo.accounting.contributionPolicies[0];
    return (
        <div>
            <br />
            <h3>subsidization</h3>
            {contributionPolicy.commissionVoid ? (
                <tr>
                    <td>commission void</td>
                    <td>✅</td>
                </tr>
            ) : null}
            {contributionPolicy.snackpassSubsidizationDollars ? (
                <tr>
                    <td>dollars subsidization</td>
                    <td>
                        {Helpers.toDollar(
                            contributionPolicy.snackpassSubsidizationDollars ||
                                0
                        )}
                    </td>
                </tr>
            ) : null}
            {contributionPolicy.snackpassSubsidizationPercent ? (
                <tr>
                    <td>percent subsidization</td>
                    <td>{contributionPolicy.snackpassSubsidizationPercent}%</td>
                </tr>
            ) : null}
            <tr>
                <td>conditions</td>
                <td>
                    <p style={{ whiteSpace: "pre-line" }}>
                        {JSON.stringify(contributionPolicy.conditions)}
                    </p>
                </td>
            </tr>
        </div>
    );
}

export function createPromoBlurb(promo: IPromotion) {
    // @ts-ignore
    let createdAt = new Date(promo.createdAt);
    // @ts-ignore
    let updatedAt = new Date(promo.updatedAt);
    return (
        <div className="promo-blurb">
            <h3>general</h3>

            <table>
                <tr>
                    <td>promo name</td>
                    <td>{promo.name}</td>
                </tr>
                <tr>
                    <td>store</td>
                    <td>
                        {promo.store.name} {promo.store.emoji}
                    </td>
                </tr>
                <tr>
                    <td>type</td>
                    <td>{promo.type}</td>
                </tr>
                <tr>
                    <td>created at</td>
                    <td>{moment(createdAt).format(momentFormat)}</td>
                </tr>
                <tr>
                    <td>last updated at</td>
                    <td>
                        {moment.duration(moment().diff(updatedAt)).humanize()}{" "}
                        ago
                    </td>
                </tr>
                {promo.hours ? (
                    <tr>
                        <td>hours</td>
                        <td>
                            <p style={{ whiteSpace: "pre-line" }}>
                                {getHoursText(promo.hours)}
                            </p>
                        </td>
                    </tr>
                ) : null}
                {getRewardExpiration(promo)}
                <h3>conditions</h3>
                <div>{getBooleans(promo.conditions || {})}</div>
                <h3>booleans</h3>
                <div>{getBooleans(promo)}</div>
                {getSubsidization(promo)}
            </table>
        </div>
    );
}

export interface IPromotionSummaryModal {
    show: boolean;
    handleHide: () => void;
    handleDestroy: () => void;
    promotion?: IPromotion;
    onSuccess: () => void;
}

const PromotionSummaryModal = ({
    promotion,
    show,
    handleHide,
}: IPromotionSummaryModal) => {
    if (!promotion) {
        return null;
    }
    return (
        <Modal
            isOpen={show}
            onRequestClose={() => {
                handleHide();
            }}
            style={customStyles}
            contentLabel="Promotion Blurb"
        >
            {promotion ? <div>{createPromoBlurb(promotion)}</div> : null}
            <button
                onClick={() => {
                    handleHide();
                }}
            >
                close
            </button>
        </Modal>
    );
};

export default connectModal({ name: "PromotionSummaryModal" })(
    PromotionSummaryModal
);
