import { IStore } from "@snackpass/snackpass-types";
import { SystemColors } from "@snackpass/design-system";
import prop from "lodash/fp/prop";
import React from "react";

import { Label } from "./Label";

const getLabels = (store: IStore) => [
    {
        text: "Delivery 🚚",
        visible: Boolean(store.delivery),
        textColor: SystemColors.v1.snackpass50,
        backgroundColor: SystemColors.v1.snackpass90
    },
    {
        text: "Kiosk 📺",
        visible: Boolean(store.hasKiosk),
        textColor: SystemColors.v1.apricot50,
        backgroundColor: SystemColors.v1.apricot90
    },
    {
        text: "Online Ordering 💻",
        visible: Boolean(store.onlineOrderingEnabled),
        textColor: SystemColors.v1.dragonfruit50,
        backgroundColor: SystemColors.v1.dragonfruit90
    },
    {
        text: "Postmates 🚲",
        visible: Boolean(prop("integrations.postmates.enabled", store)),
        textColor: SystemColors.v1.toastedSesame,
        backgroundColor: SystemColors.v1.gray80
    },
    {
        text: "It's a Checkmate 👨‍🍳👌",
        visible: Boolean(prop("integrations.checkmate.enabled", store)),
        textColor: SystemColors.v1.melon50,
        backgroundColor: SystemColors.v1.melon90
    },
    {
        text: "Onzway 🇫🇷",
        visible: Boolean(prop("integrations.onzway.enabled", store)),
        textColor: SystemColors.v1.boba50,
        backgroundColor: SystemColors.v1.boba90
    },
    {
        text: "Chowly 🍲",
        visible: Boolean(prop("integrations.chowly.enabled", store)),
        textColor: SystemColors.v1.dragonfruit50,
        backgroundColor: SystemColors.v1.dragonfruit90
    },
    {
        text: "Otter 🦦",
        visible: Boolean(prop("integrations.otter.enabled", store)),
        textColor: SystemColors.v1.dragonfruit50,
        backgroundColor: SystemColors.v1.dragonfruit90
    },
    {
        text: "Coming Soon 🔜",
        visible: Boolean(store.comingSoon),
        textColor: SystemColors.v1.blueberry50,
        backgroundColor: SystemColors.v1.blueberry90
    },
    {
        text: "Smarpus 🦄",
        visible: Boolean(prop("integrations.smarpus.enabled", store)),
        textColor: SystemColors.v1.ube50,
        backgroundColor: SystemColors.v1.ube90
    },
    {
        text: "1P POS-Side Integration 3️⃣",
        visible: Boolean(store.posIntegrations?.chowly.enabled),
        textColor: SystemColors.v1.candy50,
        backgroundColor: SystemColors.v1.candy90
    },
    {
        text: "1P Deliverect Integration",
        visible: Boolean(store.posIntegrations?.deliverect?.enabled),
        textColor: SystemColors.v1.candy50,
        backgroundColor: SystemColors.v1.candy90
    },
    {
        text: "Doordash 🚗 ",
        visible: Boolean(prop("integrations.doordash.enabled", store)),
        textColor: SystemColors.v1.melon50,
        backgroundColor: SystemColors.v1.melon90
    }
];

type Props = { store: IStore };

const Labels = ({ store }: Props) => (
    <>
        {getLabels(store).map((label) => (
            <Label
                key={label.text}
                text={label.text}
                visible={label.visible}
                textColor={label.textColor}
                backgroundColor={label.backgroundColor}
            />
        ))}
    </>
);

export default Labels;
