import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
import { Component } from "react";
import { Provider } from "react-redux";
import { applyMiddleware, createStore, Middleware } from "redux";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";
import { apolloClient } from "./api/graphql/apollo";

import config from "./config";
import Navigation from "./navigation";
import reducers from "./redux";
import rootSaga from "./sagas";
import { isProduction } from "./utils/Constants";
import { registerDispatch } from "./utils/ReduxUtil";
import CacheBusting from "./components/CacheBusting";

const sagaMiddleware = createSagaMiddleware();
const middleware: Middleware[] = [thunk, sagaMiddleware];

if (isProduction) {
    console.log("RUNNING PRODUCTION");

    Sentry.init({
        dsn: config.SENTRY_DSN,
        release: config.SENTRY_RELEASE
    });
} else {
    const logger = createLogger({
        diff: false
    });
    middleware.push(logger as Middleware);
}

const store = createStore(reducers, applyMiddleware(...middleware));

registerDispatch(store.dispatch);

sagaMiddleware.run(rootSaga);

// disable scrolling for inputs - scrolling creates accidnetal changes to
// number inputs!.
$(document).on("wheel", "input[type=number]", function (e) {
    $(this).blur();
});

type Props = {};
class App extends Component<Props> {
    render() {
        return (
            <Provider store={store}>
                <ApolloProvider client={apolloClient}>
                    <CacheBusting>
                        <Navigation />
                    </CacheBusting>
                </ApolloProvider>
            </Provider>
        );
    }
}

export { store };
export default App;
