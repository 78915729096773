/** Formik is a library that allows easier handling of form validation and
 * submission.
 *
 * The primary change and philosophy is using an "uncontrolled" form. This plays
 * better with html, different types, and reasoning about the form Validation is
 * set as a Yup object, passed as a prop to validation Schema.
 *
 * Before submission, there are a few clean ups required, due to the way some
 * fields are structured. For example, ensuring that the object discount has
 * only one child cannot be easily achieved with Formik, so we move that logic
 * to handleSubmit.
 */
import _ from "lodash";
import { withFormik } from "formik";
import { AdditionalProps, FormBody, MyFormProps } from "./FormBody";
import { initializeFields, validationSchema } from "./config";
import {
    Accounting,
    ContributionPolicy,
    IDealItem,
    IDiscount,
    IPartyPromoTier,
    IRewardExpiration as RewardExpiration,
    PromoTarget,
    SnackpassTimezoneEnum
} from "@snackpass/snackpass-types";

import { transformFormHoursVersionToHours } from "../Hours";
import {
    DealItemFormVersion,
    DiscountSelect,
    FormValues,
    ValuesToSubmit
} from "./types";
import API from "../../api/index";
import swal from "sweetalert2";
import { Colors } from "../../utils";
import { PROMO_CODE_STORE_ID } from "../../utils/Constants";
import { sendPromotionApprovalLog } from "./lib";

// The type of props PromotionForm receives
function createDiscountObject(
    discountType: DiscountSelect,
    values?: IDiscount
) {
    if (!values) return undefined;

    return {
        [discountType]: values[discountType]
    } as IDiscount;
}

function transformTargets(targets: {
    firstTime: boolean;
    students: boolean;
    subscribers: boolean;
}): PromoTarget[] {
    let ret: PromoTarget[] = [];
    if (targets.firstTime) {
        ret.push(PromoTarget.FirstTime);
    }
    if (targets.students) {
        ret.push(PromoTarget.Students);
    }
    if (targets.subscribers) {
        ret.push(PromoTarget.Subscribers);
    }
    return ret;
}

const SECONDS_IN_DAY = 86400;

function mapDealItemFormVersion(
    dealItem: DealItemFormVersion
): Partial<IDealItem> {
    let ret: DealItemFormVersion = { ...dealItem };
    ret.discount =
        ret.isDiscounted && ret.discount
            ? createDiscountObject(dealItem.discountType, ret.discount)
            : null;
    // @ts-ignore
    delete ret.discountType;
    // @ts-ignore
    delete ret.isDiscounted;
    return ret;
}

// unfortunately this is necessary because yup doesn't allow a child (ex.
// discount.percent) to validate using it's parent's sibling fields (ie
// discountType)
function passesDiscountValidation(values: FormValues): boolean {
    if (!["DEAL", "PARTY", "RETARGETING"].includes(values.type)) {
        if (values.discountType !== "isDoublePoints") {
            return _.isFinite(values.discount?.[values.discountType]);
        }
    }
    return true;
}

const MyEnhancedForm = withFormik<MyFormProps, FormValues & AdditionalProps>({
    enableReinitialize: true,
    mapPropsToValues: (props) => {
        // transform discount to discount type
        return {
            ...initializeFields(
                props.activeStore,
                props.promotion,
                props.editMode
            ),
            editMode: props.editMode,
            storeOptions: props.storeOptions,
            productOptions: props.productOptions,
            categoryOptions: props.categoryOptions
        };
    },
    validationSchema,
    handleSubmit: async (values, FormikBag) => {
        // If creating a promotion,
        // confirm w/ user that they cannot edit subsidization for a promotion
        // at a future date
        if (!FormikBag.props.editMode && values.accounting.isSubsidized) {
            let result = await swal.fire({
                text: "You cannot edit promotion subsidization after a promotion is created. If this okay, press Yes.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: Colors.green,
                cancelButtonColor: Colors.gray,
                confirmButtonText: "Yes, create it!",
                cancelButtonText: "Cancel"
            });
            // if no result.value, means the user clicked cancel
            // so should not create the promotion
            if (!result.value) {
                FormikBag.setSubmitting(false);
                return;
            }
        }

        // we can try to use yup to cast certain fields. would be more elegant
        // Can't do this with formik because it's an uncontrolled form
        // perhaps move all of this to a separate function for handling submit

        // should have all these as tests on backend too!!!

        // make a copy to not modify the actual form upon submitting
        let submitValues: FormValues & AdditionalProps = { ...values };
        //check if selected value on discountType is double points
        if (values.discountType === "isDoublePoints") {
            submitValues.discount = {
                isDoublePoints: true
            };
        }
        // transform discount, remove non discount types
        submitValues.discount = createDiscountObject(
            submitValues.discountType,
            submitValues.discount
        );

        // clean up code/codes field
        if (submitValues.isMulticode) {
            submitValues.code = "";
        } else {
            submitValues.codes = [];
        }

        // clean up deal items
        if (submitValues.type === "DEAL") {
            submitValues.discount = undefined;
            submitValues.dealItems = submitValues.dealItemsFormVersion.map(
                mapDealItemFormVersion
            );
        }

        // clean up multiuse fields
        if (["PROMO_CODE", "TIME_BOMB"].includes(submitValues.type)) {
            submitValues.multiuse = false;
        }
        if (["REWARD", "REFERRAL"].includes(submitValues.type)) {
            submitValues.multiuse = true;
        }

        // clean up points required
        if (submitValues.type !== "REWARD") {
            submitValues.pointsRequired = 0;
        }

        // clean up maximumUses fields
        if (submitValues.type === "REWARD") {
            submitValues.hasMaximumUses = false;
        }
        if (!submitValues.hasMaximumUses) {
            submitValues.maximumUses = null;
        }

        // clean up activeTimePeriod fields
        if (!submitValues.hasActiveTimePeriod) {
            submitValues.activeTimePeriod = null;
        }
        if (
            submitValues.activeTimePeriod &&
            submitValues.type === "TIME_BOMB"
        ) {
            submitValues.activeTimePeriod.endTime = null;
        }
        if (!["DISCOUNT", "DEAL"].includes(submitValues.type)) {
            submitValues.showActiveTimePeriodCountdown = false;
        }

        // clean up rewardExpiration fields
        if (!submitValues.rewardExpires) {
            submitValues.rewardExpiration = null;
        }

        if (
            submitValues.rewardExpiration &&
            submitValues.rewardExpiration.date &&
            submitValues.rewardExpirationType === "date"
        ) {
            submitValues.rewardExpiration.seconds = null;
        }

        if (
            submitValues.rewardExpires &&
            submitValues.rewardExpirationType === "seconds" &&
            submitValues.rewardExpirationDays
        ) {
            submitValues.rewardExpiration = {} as RewardExpiration;
            submitValues.rewardExpiration.date = null;
            submitValues.rewardExpiration.seconds =
                submitValues.rewardExpirationDays * SECONDS_IN_DAY;
        }

        // clean up product targets for deals
        if (submitValues.type === "DEAL") {
            submitValues.productIds = [];
            submitValues.categories = [];
            submitValues.storewide = false;
        }

        // move this to function. clean up subsidization
        if (
            !submitValues.accounting.isSubsidized &&
            submitValues.accounting.contributionPolicies[0]
        ) {
            // set to false if there are no snackpass contributions
            submitValues.applyTaxToSnackpassContribution = false;
            submitValues.accounting.contributionPolicies[0].snackpassSubsidizationDollars =
                null;
            submitValues.accounting.contributionPolicies[0].snackpassSubsidizationPercent =
                null;
            submitValues.accounting.contributionPolicies[0].conditions = {
                dollarsDiscounted: null,
                redemptions: null
            };
        }
        if (submitValues.accounting.contributionPolicies[0]) {
            if (
                submitValues.accounting.contributionPolicies[0]
                    .subsidizationType === "dollars"
            ) {
                submitValues.accounting.contributionPolicies[0].snackpassSubsidizationPercent =
                    null;
            }

            if (
                submitValues.accounting.contributionPolicies[0]
                    .subsidizationType === "percent"
            ) {
                submitValues.accounting.contributionPolicies[0].snackpassSubsidizationDollars =
                    null;
            }

            if (
                submitValues.accounting.contributionPolicies[0]
                    .subsidizationConditionsType === "redemptions"
            ) {
                submitValues.accounting.contributionPolicies[0].conditions.dollarsDiscounted =
                    null;
            }

            if (
                submitValues.accounting.contributionPolicies[0]
                    .subsidizationConditionsType === "dollarsDiscounted"
            ) {
                submitValues.accounting.contributionPolicies[0].conditions.redemptions =
                    null;
            }
        }

        // clean up hours
        const storeZone = FormikBag.props.activeStore
            ? FormikBag.props.activeStore.hours.zone
            : SnackpassTimezoneEnum.newYork;

        if (!submitValues.hasHours) {
            submitValues.hours = null;
        } else {
            submitValues.hours = {
                zone: storeZone,
                local: transformFormHoursVersionToHours(
                    submitValues.hoursFormVersion.local
                )
            };
        }

        // if not a party remove these fields
        if (submitValues.type !== "PARTY") {
            delete submitValues.tiers;
        } else {
            // make sure all party tiers have maxPeople set to null
            submitValues.tiers = _.map(
                submitValues.tiers,
                (tier: IPartyPromoTier) => {
                    return {
                        ...tier,
                        conditions: {
                            ...tier.conditions,
                            maxPeople: tier.conditions.maxPeople || null
                        }
                    };
                }
            );
            // Make sure cartMin is either a value or null
            submitValues.conditions = {
                ...submitValues.conditions,
                cartMin: submitValues.conditions?.cartMin || null
            };
        }

        // if not a referral remove these fields
        if (submitValues.type !== "REFERRAL") {
            delete submitValues.superCodeEligible;
        }

        // clean up targets
        submitValues.targets = transformTargets(
            submitValues.targetsFormVersion
        );

        // attempt to update/create
        // ignore discount validation for promo code store
        // since that's where global credit promos (which don't
        // use discounts) are created
        if (
            submitValues.storeId !== PROMO_CODE_STORE_ID &&
            !passesDiscountValidation(submitValues)
        ) {
            alert("Discount value is not entered!");
            FormikBag.setSubmitting(false);
            return;
        }

        // attempt to update/create
        if (FormikBag.props.editMode && !FormikBag.props.promotion) {
            alert("Promotion does not exist!");
            FormikBag.setSubmitting(false);
            return;
        }

        if (values.duplicateMode) {
            submitValues.name += " Copy";
        }
        // clean data
        const fieldsToOmit = [
            "storeOptions",
            "productOptions",
            "categoryOptions",
            "editMode",
            "dealItemsFormVersion",
            "hasMaximumUses",
            "discountType",
            "hasHours",
            "hasActiveTimePeriod",
            "targetsFormVersion",
            "storeName",
            "store",
            "rewardExpires",
            "rewardExpirationDays",
            "rewardExpirationType",
            "hoursFormVersion",
            "duplicateMode",
            "isRedemptionsMaxUnlimited",
            "voidCommission"
        ];

        const contributionPolicies: ContributionPolicy[] = [
            _.omit(submitValues.accounting.contributionPolicies[0], [
                "subsidizationType",
                "subsidizationConditionsType"
            ])
        ];

        const accounting: Accounting = {
            ..._.omit(submitValues.accounting, ["isSubsidized"]),
            contributionPolicies
        };

        const dealItems: Partial<IDealItem>[] = submitValues.dealItems.map(
            (dealItem) => _.omit(dealItem, ["discountType", "isDiscounted"])
        );

        let cleanedValues: ValuesToSubmit = {
            ..._.omit(submitValues, fieldsToOmit),
            accounting,
            dealItems
        };

        try {
            let response;
            if (FormikBag.props.editMode && FormikBag.props.promotion) {
                if (values.duplicateMode) {
                    // create a duplicate
                    response = await API.promotions.create(cleanedValues);

                    sendPromotionApprovalLog(
                        FormikBag.props.activeStore?.name,
                        FormikBag.props.activeStore?.region,
                        cleanedValues.name
                    );
                } else {
                    response = await API.promotions.update(
                        FormikBag.props.promotion._id,
                        cleanedValues
                    );
                }
            } else {
                // create mode
                response = await API.promotions.create(cleanedValues);
                sendPromotionApprovalLog(
                    FormikBag.props.activeStore?.name,
                    FormikBag.props.activeStore?.region,
                    cleanedValues.name
                );
            }
            let responseName = "created";
            if (FormikBag.props.editMode) {
                responseName = values.duplicateMode ? "duplicated" : "updated";
            }
            alert(`Promotion succesfully ${responseName} ✅`);
            FormikBag.setSubmitting(false);
            FormikBag.props.onSuccess &&
                FormikBag.props.onSuccess(response.data.promotion);
        } catch (err) {
            alert(
                "Error submitting promotion ❌ " +
                    JSON.stringify(err.response.data.message)
            );
            FormikBag.setSubmitting(false);
        }
    },
    displayName: "PromotionForm"
})(FormBody);

export default MyEnhancedForm;
