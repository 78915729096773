// Constants
export const SET_ACTIVE_STORE = "SET_ACTIVE_STORE";

// Actions
export interface SetActiveStore {
    type: "SET_ACTIVE_STORE";
    activeStore: string;
}
export function setActiveStore(activeStore: string) {
    return { type: "SET_ACTIVE_STORE", activeStore };
}

export type ActiveStoreAction = SetActiveStore;

// Reducer
const initialState: string | null = null;

export function activeStore(state = initialState, action: ActiveStoreAction) {
    switch (action.type) {
        case "SET_ACTIVE_STORE":
            return action.activeStore;
        default:
            return state;
    }
}
