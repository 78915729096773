import {
    IKiosk,
    IStoreSubscriptionPerk,
    RefundSource
} from "@snackpass/snackpass-types";
import { AxiosResponse } from "axios";
import axios from "./axios";

import { BanUserReasonsEnum } from "src/components/UserPreview/constants";

class Stores {
    static get = async (query?: any) => {
        return axios.get(`/admin/stores`, {
            params: { ...query, serviceFeeEnabled: true }
        });
    };
    static getById = async (storeId: string) => {
        return axios.get(`/stores/${storeId}`, {
            params: { serviceFeeEnabled: true }
        });
    };
    static create = async (body: Object) => {
        return axios.post(`/stores`, body);
    };
    static update = async (storeId: string, body: Object) => {
        return axios.put(`/stores/${storeId}`, body);
    };
    static remove = async (storeId: string) => {
        return axios.delete(`/stores/${storeId}`);
    };
    static getProducts = async (storeId: string) => {
        return axios.get(`/stores/${storeId}/products`);
    };
    static getStoreByTabletSerial = async (serial: string) => {
        return axios.get(`/stores/serial/${serial}`);
    };
    static getTablet = async (storeId: string) => {
        return axios.get(`/stores/${storeId}/tablet`);
    };
    static getTablets = async (storeId: string) => {
        return axios.get(`/stores/${storeId}/tablets`);
    };
    static updatePrimaryTablet = async (
        storeId: string,
        primaryTabletSerial: string | null
    ) => {
        return axios.post(`/stores/${storeId}/primaryTablet`, {
            tabletSerial: primaryTabletSerial
        });
    };
    static getContacts = async (storeId: string) => {
        return axios.get(`/stores/${storeId}/contacts`);
    };
    static getPayouts = async (params: Object) => {
        return axios.get(`/stores/payouts`, { params: params });
    };
    static getChangeLogs = async (storeId: string) => {
        return axios.get(`/stores/${storeId}/changes`);
    };

    static toggleGiftCardsFeature = async (
        toggle: boolean
    ): Promise<AxiosResponse<{}>> => {
        return axios.post(`/stores/enableGiftCards`, {
            hasGiftCardsEnabled: toggle
        });
    };
    static pushMenu = async (storeId: string, body: Object) => {
        return axios.post(`/stores/${storeId}/syncMenu`, body);
    };
}

class StoreMessages {
    static get = async (params?: any) => {
        // filter allowed params
        const paramOptions = ["storeId"];
        let paramPost: any = {};
        paramOptions.forEach((param) => {
            if (params && params[param]) {
                paramPost[param] = params[param];
            }
        });
        return axios.get(`/storeMessages`, {
            params: paramPost
        });
    };
    static create = async (body: Object) => {
        return axios.post(`/storeMessages`, body);
    };
}

class Promotions {
    static get = async (params?: any) => {
        const paramOptions = ["storeId"];
        let paramPost: any = {};
        paramOptions.forEach((param) => {
            if (params && params[param]) {
                paramPost[param] = params[param];
            }
        });
        return axios.get(`/promotions`, {
            params: paramPost
        });
    };
    static getById = async (promotionId: string) => {
        return axios.get(`/promotions/${promotionId}`);
    };
    static create = async (body: Object) => {
        return axios.post(`/promotions`, body);
    };
    static update = async (promotionId: string, body: Object) => {
        return axios.put(`/promotions/${promotionId}`, body);
    };
    static remove = async (promotionId: string) => {
        return axios.delete(`/promotions/${promotionId}`);
    };
}

class Uploads {
    static image = async (formData: FormData, path: string) => {
        return axios.post(`/uploads/image`, formData, {
            headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data"
            },
            params: {
                path
            }
        });
    };
    static video = async (formData: FormData, path: string) => {
        return axios.post(`/uploads/video`, formData, {
            headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data"
            },
            params: {
                path
            }
        });
    };
    static file = async (formData: FormData, path: string) => {
        return axios.post(`/uploads/file`, formData, {
            headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data"
            },
            params: {
                path
            }
        });
    };
}

class Admins {
    static get = async () => {
        return axios.get(`/admin/users/admins`);
    };
    static update = async (userId: string, updates: Object) => {
        return axios.put(`/admin/users/${userId}`, updates);
    };
    static setPassword = async (userId: string, body: Object) => {
        return axios.post(`/users/admins/${userId}/set-password`, body);
    };
    static create = async (body: Object) => {
        return axios.post("/users/admin", body);
    };
    /**
     * Search users
     * @memberof users
     * @param {string} query
     */
    static search = async (query: string) => {
        return axios.get("/users/admin/search", {
            params: {
                query: query
            }
        });
    };
}

class Purchases {
    /**
     * List purchases
     * @memberof purchases
     * @description sorted by most recent
     * @param { Object } params
     * @param { string= } params.userId show purchases only from this user
     * @param { string= } params.storeId show purchases only from this store
     * @param { string= } params.since show purchases since this date
     * @param { string= } params.before show purchases before this date
     * @param { string= } params.count limit to this many purchases
     * @param { boolean } params.includeKioskPurchases
     */
    static list = async (params: any) => {
        const paramsOptions = [
            "storeId",
            "userId",
            "since",
            "before",
            "count",
            "includeKioskPurchases"
        ];
        let paramsToPost: any = {};
        paramsOptions.forEach((param) => {
            if (params && params[param] !== "undefined") {
                paramsToPost[param] = params[param];
            }
        });
        return axios.get(`/purchases`, {
            params: paramsToPost
        });
    };
    /**
     * Search purchases
     * @memberof purchases
     * @param { Object } params
     * @param { string } params.field
     * @param { string } params.query
     * @param { before= } params.before
     */
    static search = async (
        params: Partial<{
            before: any;
            startDate: Date;
            endDate: Date;
            field: string;
            query: any;
        }>
    ) => {
        const queryKeys = ["field", "query", "before", "startDate", "endDate"];
        let postParams: any = {};
        queryKeys.forEach((key) => {
            if ((params as any)[key]) {
                postParams[key] = (params as any)[key];
            }
        });
        return axios.get(`/purchases/search`, {
            params: params
        });
    };

    /**
     * get purchase
     * @memberof purchases
     * @param {string } purchaseId
     */
    static getPurchase = async (purchaseId: string) => {
        return axios.get(`/purchases/${purchaseId}`);
    };

    /**
     * Update purchase status
     * @memberof purchases
     * @param {string } purchaseId
     * @param { Object } params
     * @param { string= } params.status status of purchase
     * @param { number= } params.pickupTimeDuration show purchases only from this store
     * @param { boolean= } params.isDelayed show purchases since this date
     * @param { number= } params.delayedDuration Number of minutes order is delayed
     * @param { boolean= } params.finishedEarly If order was finished early
     */
    static updateStatus = async (purchaseId: string, params: any) => {
        const paramsOptions = [
            "status",
            "pickupTimeDuration",
            "isDelayed",
            "delayDuration",
            "finishedEarly"
        ];
        let paramsToPost: any = {};
        paramsOptions.forEach((param) => {
            if (params[param] !== undefined) {
                paramsToPost[param] = params[param];
            }
        });
        return axios.put(`/purchases/${purchaseId}/status`, paramsToPost);
    };

    /**
     * This function refunds a purchase.
     *
     * @param {String} purchaseId The id of the purchase to refund.
     * @param {Object} params Object with refund information. Required reason field. Other fields optional.
     *                  ie: params = {
     *                          reason: "",
     *                          partialRefund: 5.00,
     *                          refundedProductIds: ["5b1aa85026bd8c88a8fcf065"]
     *                      }
     * @param {Function} callback A callback with an error and response
     */
    static refund = async (purchaseId: string, params: any) => {
        if (!params.reason || !purchaseId) {
            throw new Error(
                "Need a reason and purchase id to refund a purchase."
            );
        }
        let data: any = {
            reason: params.reason
        };
        if (params.partialRefund) data["partialRefund"] = params.partialRefund;
        if (params.refundedProductIds && params.refundedProductIds.length > 0)
            data["refundedProductIds"] = params.refundedProductIds;
        data["refundSource"] = RefundSource.Snackface;

        return axios.put(`/admin/purchases/${purchaseId}/refund`, data);
    };

    /**
     * get purchase
     * @memberof purchases
     * @param {string } purchaseId
     */
    static disputeEvidence = async (purchaseId: string) => {
        return axios.get(`/purchases/${purchaseId}/dispute-evidence`);
    };
    static submitDispute = async (purchaseId: string, evidence: any) => {
        return axios.post(`/purchases/dispute`, { purchaseId, evidence });
    };
}

class Users {
    static async getMe() {
        return axios.get("/users/me");
    }

    static getOne = async (userId: string) => {
        return axios.get(`/users/${userId}`);
    };

    static getMany = async (query: any) => {
        return axios.get(`/users/`, {
            params: {
                ...query
            }
        });
    };

    static search = async (query: string, searchingByEmail: boolean) => {
        // if searching by email the query should not be encoded (i.e. passed to axios' params object) because
        // mongo doesn't know how to search for something like 'email%40example.com'. Otherwise the query should be encoded.
        return axios.get(
            `/users/admin/search${searchingByEmail ? `?query=${query}` : ""}`,
            !searchingByEmail ? { params: { query } } : {}
        );
    };
    static getPunchcards = async (userId: string) => {
        return axios.get(`/punchcards`, {
            params: {
                userId: userId,
                inactive: true
            }
        });
    };

    static getRewards = async (userId: string) => {
        return axios.get(`/rewards`, {
            params: {
                userId: userId
            }
        });
    };

    static update = async (userId: string, updates: Object) => {
        return axios.put(`/users/${userId}`, updates);
    };
    static resetPassword = async (
        email: string,
        invalidateOldPassword: boolean
    ) => {
        return axios.post(`/users/reset-password`, {
            email,
            invalidateOldPassword
        });
    };
    static createAdmin = async (body: Object) => {
        return axios.post(`/users/admin`, body);
    };
    static boostUsers = async (body: Object) => {
        return axios.post(`/users/boost`, body);
    };
    static updateAccountEmails = async (uid: string, newEmail: string) => {
        return axios.put(`/users/accountEmails`, {
            uid,
            newEmail
        });
    };
    static banOrUnban = async (
        userId: string,
        willBan: boolean,
        decisionId?: BanUserReasonsEnum,
        banReason?: string
    ) => {
        return axios.post("/fraud/ban-user", {
            userId,
            isBanned: willBan,
            decisionId,
            banReason
        });
    };
}

class Devices {
    /**
     * @param {string} userid returns this user's device isBanned setting
     * @returns {
     *      isBanned: boolean,
     *      canDeviceBeBanned: boolean
     * }
     */
    static getIsDeviceBanned = async (userid: string) => {
        return axios.get(`/devices/user/${userid}/is-banned`);
    };

    /**
     * Set's the "isDeviceBanned" retrieved from getIsDeviceBanned
     *
     * @param {string} userid  update this user's information
     * @param {boolean} isBanned update isBanned on user to this
     */
    static setIsDeviceBanned = async (userid: string, isBanned: boolean) => {
        return axios.put(`/devices/user/${userid}`, { isBanned });
    };
}

class Rewards {
    static update = async (rewardId: string, updates: Object) => {
        return axios.put(`/rewards/${rewardId}`, updates);
    };
}

class Punchcards {
    static update = async (punchcardId: string, updates: Object) => {
        return axios.put(`/punchcards/${punchcardId}`, updates);
    };
}

class Gifts {
    static create = async (body: Object) => {
        return axios.post(`/gifts`, body);
    };
}

class Regions {
    static get = async ({
        populateNumStores,
        discardArchivedRegions
    }: {
        populateNumStores?: boolean;
        discardArchivedRegions?: boolean;
    }) => {
        return axios.get(`/admin/regions`, {
            params: {
                populateNumStores: populateNumStores,
                discardArchivedRegions: discardArchivedRegions
            }
        });
    };
    static getOne = async (regionId: string) => {
        return axios.get(`/regions/${regionId}`);
    };
    static update = async (regionId: string, body: Object) => {
        return axios.put(`/regions/${regionId}`, body);
    };
    static create = async (body: Object) => {
        return axios.post(`/regions/`, body);
    };
    static delete = async (regionId: string) => {
        return axios.delete(`/regions/${regionId}`);
    };
}

class Places {
    static search = async (query: string) => {
        return axios.get<{ result: google.maps.places.PlaceResult }>(
            "/stores/places-autocomplete",
            {
                params: {
                    search: query
                }
            }
        );
    };
}

class StoreContacts {
    static getMany = async () => {
        return axios.get(`/storeContacts`);
    };
    static update = async (storeContactId: string, updates: Object) => {
        return axios.put(`/storeContacts/${storeContactId}`, updates);
    };
    static delete = async (storeContactId: string) => {
        return axios.delete(`/storeContacts/${storeContactId}`);
    };
    static create = async (fields: Object) => {
        return axios.post(`/storeContacts`, fields);
    };
}

class StoreTablets {
    static get = async (params?: Object) => {
        return axios.get(`/storeTablets`, { params });
    };
    static setSilentMode = (tabletId: string, silentMode: boolean) =>
        axios.put(`/storeTablets/${tabletId}/silentMode`, { silentMode });
    static setPrimary = async (storeTabletId: string) => {
        return axios.put(`/storeTablets/${storeTabletId}/primary`);
    };
    static updateJAMF = async (storeTabletId: string) => {
        return axios.put(`/storeTablets/${storeTabletId}/jamf`);
    };
    static delete = async (storeTabletId: string) => {
        return axios.delete(`/storeTablets/${storeTabletId}`);
    };
}

class Logs {
    static send = async (body: Object) => {
        return axios.post(`/logs`, body);
    };
}

const kiosks = {
    get: (kioskId: string) => axios.get(`/kiosks/${kioskId}`),
    list: (params?: Object) => axios.get(`/kiosks`, { params }),
    register: ({
        deviceType,
        serial,
        paymentProvider,
        employeeMode,
        deviceModel,
        storeId
    }: {
        deviceType: string;
        serial: string;
        paymentProvider: string;
        employeeMode: boolean;
        deviceModel: string;
        storeId?: string;
    }) =>
        axios.post("/kiosks", {
            deviceType,
            serial,
            paymentProvider,
            employeeMode,
            deviceModel,
            storeId
        }),
    update: ({ serial, data }: { serial: string; data: Partial<IKiosk> }) =>
        axios.post(`/kiosks/${serial}/update`, data),
    delete: (kioskId: string) => axios.delete(`/kiosks/${kioskId}`),
    reboot: (kioskId: string) => axios.post(`/kiosks/${kioskId}/reboot`)
};

const printers = {
    list: (params?: Object) => axios.get(`/printers`, { params }),
    register: ({
        format,
        name,
        serial,
        storeId,
        foodhallId
    }: {
        format: string;
        name: string;
        serial: string;
        storeId?: string;
        foodhallId?: string;
    }) =>
        axios.post("/printers", {
            name,
            format,
            serial,
            ...(storeId ? { storeId } : { foodhallId })
        }),
    deprecate: (printerId: string, serial?: string) =>
        axios.patch(`/printers/${printerId}/deprecate`, { serial }),
    updatePrinter: (printerId: string, printerData: any) =>
        axios.patch(`/printers/${printerId}`, {
            printerData
        }),
    search: (params?: Object) => axios.get(`/printers/search`, { params }),
    listHistory: (params?: Object) => axios.get(`/printers/history`, { params })
};

const PendingPayouts = {
    list: () => axios.get("/pendingPayouts/"),
    sync: () => axios.post("/pendingPayouts/sync"),
    approve: () => axios.post("/pendingPayouts/approve")
};

const chains = {
    get: (chainId: string) => axios.get(`/chains/${chainId}`),
    list: (query?: any) => axios.get(`/chains/`, query),
    update: (chainId: string, body: any) =>
        axios.put(`/chains/${chainId}`, body),
    create: (body: any) => axios.post(`/chains`, body),
    delete: (chainId: string) => axios.delete(`/chains/${chainId}`),
    listStores: (chainId: string) => axios.post(`/chains/${chainId}/stores`)
};

const tochiItems = {
    get: async (query?: any) => axios.get(`/tochi/items/all`, query),
    create: (body: any) => axios.post(`/tochi/items`, body),
    update: (itemId: string, body: any) =>
        axios.put(`/tochi/items/${itemId}`, body),
    delete: async (itemId: string) => axios.delete(`/tochi/items/${itemId}`)
};

const tochiFiles = {
    upload: async (
        formData: FormData,
        path: string,
        version: string,
        overwrite: boolean,
        create: boolean
    ) =>
        axios.post(`/tochi/files`, formData, {
            headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data"
            },
            params: {
                path,
                version,
                overwrite,
                create
            }
        }),
    getFiles: async (query?: any) => axios.get(`/tochi/files`, query),
    getAnimations: async (query?: any) =>
        axios.get(`tochi/files/animations/all`, query),
    createAnimation: async (query?: any) =>
        axios.post(`tochi/files/animations/`, query)
};

const tochiMaterials = {
    get: async (materialId: string) =>
        axios.get(`/tochi/files/materials/${materialId}`),
    update: async (query: any, materialId: string | null) =>
        axios.put(`tochi/files/materials/${materialId}`, query)
};

const tochiUsers = {
    updateSnackcoin: async (body: any, userId: string) =>
        axios.put(`tochi/users/${userId}/snackcoin`, body)
};

const StoreSubscription = {
    get: (params: { storeId: string }) =>
        axios.get("/subscriptions/store", { params }),
    create: (body: {
        storeId: string;
        subscriptionName: string;
        perks: Pick<
            IStoreSubscriptionPerk,
            "name" | "color" | "description" | "icon"
        >[];
        price: number;
    }) => axios.post("/subscriptions/store/create", body)
};

const Billing = {
    getBillingMethods: (storeId: string) =>
        axios.get(`/billing/${storeId}/billing-methods`)
};

const RecurringPayment = {
    listSubscriptions: (storeId: string) =>
        axios.get(`/recurringPayment/${storeId}/subscriptions/list`),
    createOneTimeFee: (
        storeId: string,
        oneTimeFee: {
            product: {
                name: string;
                description: string;
            };
            unitAmount: {
                value: number;
                currency: string;
                units: string;
            };
            iterations: number;
        }
    ) =>
        axios.post(`recurringPayment/${storeId}/one-time-fees/create`, {
            oneTimeFee
        }),
    createSubscription: (
        storeId: string,
        subscription: {
            product: {
                name: string;
                description: string;
            };
            unitAmount: {
                value: number;
                currency: string;
                units: string;
            };
            trial: {
                durationInDays: number;
            };
        }
    ) =>
        axios.post(`recurringPayment/${storeId}/subscriptions/create`, {
            subscription
        })
};

const Auth = {
    sendVerification: (phoneNumber: string) => {
        return axios.post("phoneAuthentication/sendVerification", {
            phoneNumber
        });
    },
    verify: (phoneNumber: string, code: string, createUser = false) => {
        return axios.post("phoneAuthentication/verifyPhone", {
            phoneNumber,
            code,
            createUser
        });
    }
};

export default class API {
    static getAnalytics = async (route: string, params: {}) => {
        return axios.get(`/analytics/${route}`, {
            params
        });
    };

    // fill this out with stuff...
    static accountingData = { fetchData: () => {} };
    static stores = Stores;
    static storeMessages = StoreMessages;
    static promotions = Promotions;
    static admins = Admins;
    static uploads = Uploads;
    static places = Places;
    static purchases = Purchases;
    static users = Users;
    static rewards = Rewards;
    static punchcards = Punchcards;
    static gifts = Gifts;
    static regions = Regions;
    static storeContacts = StoreContacts;
    static storeTablets = StoreTablets;
    static logs = Logs;
    static devices = Devices;
    static subscriptions = StoreSubscription;
    static kiosks = kiosks;
    static printers = printers;
    static chains = chains;
    static tochiItems = tochiItems;
    static tochiFiles = tochiFiles;
    static tochiMaterials = tochiMaterials;
    static tochiUsers = tochiUsers;
    static auth = Auth;
    static pendingPayouts = PendingPayouts;
    static recurringPayment = RecurringPayment;
    static billing = Billing;
}
