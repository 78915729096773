import { connect } from "react-redux";
import TabletSerial from "../components/StoreForm/components/TabletSerial";
import { updateJAMFStoreTablet } from "../redux/storeTablets";

import { Action } from "redux";
import { StoreState } from "../redux/types";
import { ThunkDispatch } from "redux-thunk";

const mapStateToProps = (state: StoreState) => {
    return {};
};

// create handler than dispatches an action
const mapDispatchToProps = (
    dispatch: ThunkDispatch<StoreState, undefined, Action>
) => {
    return {
        dispatch,
        updateJAMFTablet: async (storeTabletId: string) => {
            return dispatch(updateJAMFStoreTablet(storeTabletId));
        },
    };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(TabletSerial);

export default Container;
