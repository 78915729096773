import React, { MutableRefObject, useRef, useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { FieldProps } from "formik";

import { FormValues } from "../types";
import { StyleObjectType } from "src/utils/types";

const AddressSearch = ({
    form,
    field
}: {
    form: FieldProps<FormValues>["form"];
    field: FieldProps<FormValues>["field"];
}) => {
    let timeout: MutableRefObject<NodeJS.Timeout | null> = useRef(null);
    const [sessionToken, setSessionToken] = useState(
        new google.maps.places.AutocompleteSessionToken()
    );

    const onChangeAddress = (address: string) => {
        form.setFieldValue("address", address);
        if (field) {
            form.setFieldTouched(field.name, true);
        }

        if (timeout.current) {
            clearTimeout(timeout.current);
        }
        timeout.current = setTimeout(async () => {
            try {
                const results = await geocodeByAddress(address);
                const latLng = await getLatLng(results[0]);
                form.setFieldValue("address", address);
                form.setFieldValue("geolocation", {
                    type: "Point",
                    coordinates: [latLng.lng, latLng.lat]
                });
            } catch (error) {
                form.setFieldValue("geolocation", null);
            }
        }, 1000);
    };

    const onSelectAddress = (address: string) => {
        onChangeAddress(address);
    };

    return (
        <PlacesAutocomplete
            value={field.value}
            onChange={onChangeAddress}
            onSelect={(address: string) => {
                onSelectAddress(address);
                setSessionToken(
                    new google.maps.places.AutocompleteSessionToken()
                );
            }}
            searchOptions={{
                componentRestrictions: { country: "us" },
                sessionToken
            }}
            debounce={500}
        >
            {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
            }) => (
                <div>
                    <input
                        {...getInputProps({
                            placeholder: "Search Places ...",
                            className: "location-search-input"
                        })}
                        style={styles.fixedWidth}
                    />
                    <div
                        className="autocomplete-dropdown-container"
                        style={styles.fixedWidth}
                    >
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                            const addProps = suggestion.active
                                ? {
                                      className: "suggestion-item--active",
                                      style: styles.active
                                  }
                                : {
                                      className: "suggestion-item",
                                      style: styles.default
                                  };
                            return (
                                <div
                                    {...getSuggestionItemProps(
                                        suggestion,
                                        addProps
                                    )}
                                    key={suggestion.placeId}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    );
};

const styles: StyleObjectType = {
    active: {
        backgroundColor: "#fafafa",
        cursor: "pointer"
    },
    default: {
        backgroundColor: "#ffffff",
        cursor: "pointer"
    },
    fixedWidth: {
        width: 350
    }
};

export default AddressSearch;
