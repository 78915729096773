import { connect } from "react-redux";
import {} from "../redux/activeUser";
import Punchcards from "../components/Punchcards";
import { Dispatch } from "redux";
import { StoreState } from "../redux/types";

const mapStateToProps = (state: StoreState) => {
    return {
        punchcards: state.activeUser.punchcards,
        rewards: state.activeUser.rewards,
        user: state.activeUser.user
    };
};

// create handler than dispatches an action
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {};
};

const Container = connect(mapStateToProps, mapDispatchToProps)(Punchcards);

export default Container;
