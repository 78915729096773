import { SystemColors } from "@snackpass/design-system";
import { IPurchase } from "@snackpass/snackpass-types";
import { formatNumber, NumberFormat } from "libphonenumber-js";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import Modal from "react-modal";

import { history } from "../../utils/history";
import { Colors } from "../../utils/Colors";
import * as Helpers from "../../utils/Helpers";

import { Image, Text, TouchableHighlight, View } from "../../SharedComponents";
import PurchaseDetails, { getTransactionSourceLabel } from "../PurchaseDetails";

const schoolImageMap = {
    providence: require("../../assets/schoollogos/brownschoollogo.png"),
    berkeley: require("../../assets/schoollogos/calschoollogo.jpg"),
    hanover: require("../../assets/schoollogos/dartmouthschoollogo.jpg"),
    cambridge: require("../../assets/schoollogos/harvardschoollogo.png"),
    ann_arbor: require("../../assets/schoollogos/michiganschoollogo.png"),
    new_haven: require("../../assets/schoollogos/yaleschoollogo.jpg"),
};

const formatStatus = (purchase: IPurchase) => {
    const batchString = `BATCHED: ${Helpers.formatDateTimeLong(
        purchase.purchaseLiveAt || new Date()
    )}`;

    if (purchase.status.length === 0) {
        return purchase.batchKey ? batchString : "";
    }

    const latestStatus = purchase.status[purchase.status.length - 1].type;
    return purchase.batchKey ? `${latestStatus}: ${batchString}` : latestStatus;
};

const formatComplaints = (complaints: any[]) => {
    if (!complaints || complaints.length === 0) {
        return "";
    }

    return `with complaints: ${complaints
        .map(complaint => ` ${complaint.value},`)
        .join("")}`;
};

const styles = {
    scheduledForText: {
        color: Colors.gray,
    },
    statusAndScheduledForCol: {
        display: "flex",
        flexDirection: "column",
    } as React.CSSProperties,
};

const modalStyles = {
    content: {
        margin: "auto",
        maxWidth: 500,
        width: "90%",
        left: "5%",
    },
};

type Props = {
    purchase: IPurchase;
    dispatch: Function;
    replacePurchase: (purchase: IPurchase) => void;
    showModal: (modal: string, params: any) => void;
};

type State = {
    modalVisible: boolean;
};

export default class PurchaseRow extends Component<Props, State> {
    state = { modalVisible: false };

    openModal = () => {
        const { purchase } = this.props;
        const userName = purchase.user ? purchase.user.name : "n/a";
        const storeName = purchase.store ? purchase.store.name : "n/a";

        console.log(
            `Purchase from customer ${userName} at ${storeName}`,
            purchase
        );
        this.setState({ modalVisible: true });
        history.push(`/purchases/${purchase._id}`);
    };

    closeModal = () => {
        this.setState({ modalVisible: false });
        history.push("/purchases");
    };

    _purchaseStatus = () => {
        const { purchase } = this.props;
        if (purchase.refund) {
            return "REFUNDED";
        } else if (purchase.submittedDisputeEvidence && purchase.dispute) {
            return "DISPUTED ⚔️";
        }
        return null;
    };

    render() {
        const { purchase } = this.props;

        return (
            <View>
                <Modal
                    style={modalStyles}
                    isOpen={this.state.modalVisible}
                    onRequestClose={this.closeModal}
                    contentLabel="Purchase"
                >
                    <PurchaseDetails
                        showModal={this.props.showModal}
                        purchase={purchase}
                        closeModal={this.closeModal}
                        replacePurchase={this.props.replacePurchase}
                    />
                </Modal>
                <TouchableHighlight
                    style={{
                        padding: "10px 0",
                        borderBottom: `1px solid ${Colors.lightGray}`,
                    }}
                    onPress={this.openModal}
                >
                    <Row>
                        <Col xs={2} style={{ minWidth: 100 }}>
                            <Text small>
                                {Helpers.formatDateTimeLong(
                                    new Date(purchase.createdAt)
                                )}
                            </Text>
                            <br />
                            <Text bold xsmall color={Colors.red}>
                                {this._purchaseStatus()}
                            </Text>
                        </Col>
                        <Col xs={2} style={{ padding: "0 5px", minWidth: 100 }}>
                            <>
                                <Text
                                    color={SystemColors.v1.toastedSesame}
                                    small
                                >
                                    {purchase.user?.name}
                                    {purchase.transactionSource !== "kiosk"
                                        ? purchase.isSpecialGift && " 🛍"
                                        : ""}
                                </Text>
                                <br />
                            </>
                            <Text color={SystemColors.v1.gray40} small>
                                Order #{purchase.receiptNumber}
                            </Text>
                            <br />
                            <Text color={SystemColors.v1.gray40} small>
                                {purchase.fulfillment}
                            </Text>
                        </Col>
                        <Col xs={1} style={{ minWidth: 75 }}>
                            {purchase.store && (
                                <Text small>{purchase.store.name}</Text>
                            )}
                            <br />
                            <Text color={SystemColors.v1.gray40} small>
                                {purchase.region}
                            </Text>
                            {/* Render the status below the store name on mobile */}
                            {isMobile && (
                                <Text style={{ marginTop: 5 }} bold small>
                                    {formatStatus(purchase)}
                                </Text>
                            )}
                        </Col>
                        {!isMobile && (
                            <Col xs={1}>
                                <Text small>
                                    {purchase.rating}{" "}
                                    {formatComplaints(purchase.complaints)}
                                </Text>
                            </Col>
                        )}
                        {!isMobile && (
                            <Col xs={2} style={styles.statusAndScheduledForCol}>
                                <Text small>{formatStatus(purchase)}</Text>
                                {purchase.isScheduledOrder &&
                                    purchase.scheduledDate && (
                                        <div style={styles.scheduledForText}>
                                            <Text xsmall>Scheduled for:</Text>
                                            <Text xsmall>
                                                {Helpers.formatDateTimeLong(
                                                    purchase.scheduledDate!
                                                )}
                                            </Text>
                                        </div>
                                    )}
                            </Col>
                        )}
                        {!isMobile && (
                            <Col xs={2} style={{ minWidth: 100 }}>
                                <Text small>
                                    {purchase.user && purchase.user.number
                                        ? formatNumber(
                                              purchase.user.number,
                                              "National" as NumberFormat
                                          )
                                        : null}
                                </Text>
                            </Col>
                        )}
                        <Col xs={1}>{getTransactionSourceLabel(purchase)}</Col>
                    </Row>
                </TouchableHighlight>
            </View>
        );
    }
}
