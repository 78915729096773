import { IRegion } from "@snackpass/snackpass-types";
import React, { useState, useEffect } from "react";
import { Button } from "../../SharedComponents";
import { compose } from "lodash/fp";
import Table, { CellInfo } from "react-table";
import { show } from "redux-modal";
import { useDispatch, useSelector } from "react-redux";
import { Colors } from "../../utils";

type Props = {
    regions: IRegion[];
    isLoading: boolean;
};

export const RegionsTable = (props: Props) => {
    const { regions, isLoading } = props;

    return (
        <Table
            style={{ textAlign: "center" }}
            data={regions}
            loading={isLoading}
            className="snackpass__table"
            defaultPageSize={20}
            freezeWhenExpanded={true}
            columns={[
                _nameColumn,
                _taxRateColumn,
                _stateColumn,
                _timezoneColumn,
                _actionsColumn(),
                _marketplaceColumn,
                _referralCreditColumn,
            ]}
        />
    );
};

const EditButton = ({ onClick }: { onClick: () => void }) => {
    return (
        <Button
            containerStyle={{
                display: "inline-block",
                margin: "0 5px",
            }}
            backgroundColor={Colors.white}
            labelColor={Colors.blue}
            label={<i className="fas fa-pen" />}
            onPress={onClick}
        />
    );
};

const _nameColumn = {
    Header: "Name",
    accessor: "name",
    id: "regionName",
    minWidth: 125,
};
const _taxRateColumn = {
    Header: "Tax Rate",
    accessor: (obj: IRegion) => (obj.taxRate ? obj.taxRate + "%" : ""),
    id: "regionTaxRate",
    minWidth: 125,
};

const _stateColumn = {
    Header: "State",
    accessor: "state",
    id: "regionUsState",
    minWidth: 125,
};
const _timezoneColumn = {
    Header: "Timezone",
    accessor: "tz",
    id: "regionTimeZone",
    minWidth: 125,
};

const _marketplaceColumn = {
    Header: "Marketplace",
    id: "marketplace",
    minWidth: 125,
    accessor: (obj: IRegion) => (obj.marketplaceRegion ? "✅" : "❌"),
};

const _referralCreditColumn = {
    Header: "Referral Credit",
    id: "referralCredit",
    accessor: "referralCredit",
};

const _actionsColumn = () => ({
    Header: "Actions",
    id: "regionActions",
    Cell: (props: CellInfo) => {
        const dispatch = useDispatch();
        const region = props.original;

        const showModal = compose(dispatch, show);
        return (
            <span>
                <EditButton
                    onClick={() => {
                        showModal("RegionFormModal", {
                            editMode: true,
                            regionObj: region,
                        });
                    }}
                />
            </span>
        );
    },
});
