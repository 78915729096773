import { IGiftCard } from "@snackpass/snackpass-types";
import { Dispatch } from "redux";
import api from "../api/graphql";
import * as Sentry from "@sentry/browser";
import { StoreState } from "./types";

// Constants
export const SET_GIFTCARDS = "SET_GIFTCARDS";
export const REFUND_GIFTCARD = "REFUND_GIFTCARD";

// Actions
interface SetGiftCards {
    type: "SET_GIFTCARDS";
    giftCards: IGiftCard[];
}
export function setGiftCardsAction(giftCards: IGiftCard[]) {
    return { type: SET_GIFTCARDS, giftCards: giftCards };
}

export type FetchGiftCards = () => Promise<any>;
export function fetchGiftCards(query: any = {}) {
    return (dispatch: Dispatch) => {
        return api.giftCards
            .giftCardsByStore(query)
            .then(giftcards => {
                dispatch(setGiftCardsAction(giftcards));
            })
            .catch(err => {
                Sentry.captureException(err);
                dispatch(setGiftCardsAction([]));
            });
    };
}

export type RefundGiftcard = () => Promise<IGiftCard>;
interface RefundGiftCard {
    type: "REFUND_GIFTCARD";
    refundGiftCard: IGiftCard;
}

export function refundGiftCardAction(refundGiftCard: IGiftCard) {
    return { type: REFUND_GIFTCARD, refundGiftCard: refundGiftCard };
}

export function refundGiftCardAPI(giftCardId: string) {
    return (dispatch: Dispatch) => {
        return api.giftCards
            .refundGiftCard(giftCardId)
            .then(refundedGiftCard => {
                dispatch(refundGiftCardAction(refundedGiftCard));
            })
            .catch(err => {
                Sentry.captureException(err);
                throw new Error(err);
            });
    };
}

export type GiftCardsActions = SetGiftCards | RefundGiftCard;

// Reducer
const initialState: IGiftCard[] = [];

export function giftcards(state = initialState, action: GiftCardsActions) {
    switch (action.type) {
        case SET_GIFTCARDS:
            return action.giftCards;
        case REFUND_GIFTCARD:
            return state.map((giftcard: IGiftCard) =>
                giftcard.id === action.refundGiftCard.id
                    ? action.refundGiftCard
                    : giftcard
            );
        default:
            return state;
    }
}

export const getGiftCards = (state: StoreState) => state.giftcards;
