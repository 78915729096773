import React from "react";
import { Card } from "antd";
import { Styles } from "../../../utils";
import TochiMaterialsSection from "./components/TochiMaterialsSection";
import InfoButton from "../components/InfoButton";

const infoText = [
    "Edit materials by searching by ObjectId",
    "Chicken Body: 5f63e089c70f8013b9bcb700",
    "Chicken Addons: 5f63e089c70f8013b9bcb701",
    "Chicken Eyes1: 5f63e089c70f8013b9bcb702",
    "Chicken Eyes2: 5f63e089c70f8013b9bcb703",
    "Egg: 5ec831d870204137e3d33dc8",
];

const TochiMaterials = () => {
    return (
        <div>
            <Card
                style={{
                    width: "90%",
                    margin: "0 auto",
                    borderRadius: 5,
                    ...Styles.shadowLight,
                }}
                headStyle={{
                    fontSize: 22,
                    borderBottom: "none",
                }}
                title="Tochi Materials 🎨"
                extra={
                    <InfoButton title="Tochi Assets Info" infoText={infoText} />
                }
            >
                <TochiMaterialsSection />
            </Card>
        </div>
    );
};

export default TochiMaterials;
