import { createSelector } from "reselect";
import { IStoreContact } from "@snackpass/snackpass-types";
import api from "../api";
import { StoreState } from "./types";
import { Dispatch } from "redux";
import * as Sentry from "@sentry/browser";

// Constants

export const FETCH_STORE_CONTACTS = "FETCH_STORE_CONTACTS";
export const SET_STORE_CONTACT = "SET_STORE_CONTACT";
export const SET_STORE_CONTACTS = "SET_STORE_CONTACTS";
export const SET_PROMOTIONS_FOR_STORE_CONTACT =
    "SET_PROMOTIONS_FOR_STORE_CONTACT";
export const REMOVE_STORE_CONTACT = "REMOVE_STORE_CONTACT";
export const ADD_STORE_CONTACT = "ADD_STORE_CONTACT";

// Actions
export interface FetchStoreContacts {
    type: "FETCH_STORE_CONTACTS";
    storeContacts: IStoreContact[];
}
export function FetchStoreContacts(storeContacts: IStoreContact[]) {
    return { type: "FETCH_STORE_CONTACTS", storeContacts };
}
export interface SetStoreContacts {
    type: "SET_STORE_CONTACTS";
    storeContacts: IStoreContact[];
}
export function setStoreContacts(storeContacts: IStoreContact[]) {
    return { type: "SET_STORE_CONTACTS", storeContacts };
}
export interface SetStoreContact {
    type: "SET_STORE_CONTACT";
    storeContact: IStoreContact;
}
export function setStoreContact(storeContact: IStoreContact) {
    return { type: "SET_STORE_CONTACT", storeContact };
}

export interface AddStoreContact {
    type: "ADD_STORE_CONTACT";
    storeContact: IStoreContact;
}
export function addStoreContact(storeContact: IStoreContact) {
    return { type: "ADD_STORE_CONTACT", storeContact };
}
export interface RemoveStoreContact {
    type: "REMOVE_STORE_CONTACT";
    storeContactId: string;
}
export function removeStoreContact(storeContactId: string) {
    return { type: "REMOVE_STORE_CONTACT", storeContactId };
}

export type StoreContactAction =
    | FetchStoreContacts
    | SetStoreContacts
    | SetStoreContact
    | AddStoreContact
    | RemoveStoreContact;

// Reducer
const initialState: IStoreContact[] = [];

export function storeContacts(
    state: IStoreContact[] = initialState,
    action: StoreContactAction
) {
    switch (action.type) {
        case "FETCH_STORE_CONTACTS":
            return action.storeContacts;
        case "SET_STORE_CONTACTS":
            return action.storeContacts;
        case "ADD_STORE_CONTACT":
            var contacts = [...state];
            contacts.push(action.storeContact);
            return contacts;
        case "SET_STORE_CONTACT":
            return state.map(storeContact => {
                if (storeContact._id === action.storeContact._id) {
                    return action.storeContact;
                }
                return storeContact;
            });
        case "REMOVE_STORE_CONTACT":
            return state.filter(storeContact => {
                if (storeContact._id === action.storeContactId) {
                    return false;
                }
                return true;
            });

        default:
            return state;
    }
}

export function fetchStoreContacts() {
    return (dispatch: Dispatch) => {
        return api.storeContacts
            .getMany()
            .then(response => {
                dispatch(setStoreContacts(response.data.contacts));
            })
            .catch(err => {
                Sentry.captureException(err);
                dispatch(setStoreContacts([]));
            });
    };
}

// selectors
export const getStoreContacts = (state: StoreState) => state.storeContacts;
export interface StoreSelectOption {
    value: string;
    label: string;
    storeContactObj: IStoreContact;
}

export const getStoreContactSelectOptions = createSelector(
    getStoreContacts,
    storeContacts => {
        return storeContacts.map(storeContact => {
            return {
                value: storeContact._id,
                label: `${storeContact.name} - ${storeContact.store.name}`,
                storeObj: { ...storeContact },
            };
        });
    }
);
