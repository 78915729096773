import React from "react";

import Modal from "react-responsive-modal";
import { connectModal, InjectedProps } from "redux-modal";
import { Colors } from "../utils";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";

const styles = {
    modal: {
        overlay: {
            background: "rgba(0,0,0,0.23)",
        },
        modal: {
            background: Colors.white,
            padding: 20,
            boxShadow: "0 2px 40px 0 rgba(0, 0, 0, 0.3)",
            borderRadius: 5,
            overflow: "hidden",
            marginBottom: 100,
        },
    },
};

export interface EmojiPickerModalProps extends InjectedProps {
    onSelectEmoji: (emoji: string) => void;
}

const EmojiPickerModal = ({
    show,
    handleHide,
    handleDestroy,
    onSelectEmoji,
}: EmojiPickerModalProps) => {
    return (
        <Modal
            onClose={() => {
                handleHide();
            }}
            open={show}
            styles={styles.modal}
        >
            <h2>Select Emoji:</h2>
            <Picker
                set="apple"
                onSelect={(value: any) => {
                    let emoji = value.native;
                    onSelectEmoji(emoji);
                    handleHide();
                }}
            />
        </Modal>
    );
};

export default connectModal({ name: "EmojiPickerModal" })(EmojiPickerModal);
