import React, { Component } from "react";
import { IUser, IPunchcard, IReward } from "@snackpass/snackpass-types";

import { View, Text } from "../../SharedComponents";

import { Row, Col } from "../../SharedComponents";
import * as Styles from "../../utils/Styles";
import Punchcard from "../../containers/Punchcard";

type Props = {
    punchcards: IPunchcard[];
    rewards: IReward[];
    user: IUser;
};

export default class Punchcards extends Component<Props> {
    render() {
        const { punchcards, rewards, user } = this.props;
        const headerRow = (
            <Row>
                <Col flex={2}>
                    <Text small>{"Store"}</Text>
                </Col>
                <Col flex={1} style={{ alignItems: "center" }}>
                    <Text small>{"# Purchases"}</Text>
                </Col>
                <Col flex={1} style={{ alignItems: "center" }}>
                    <Text small>{"Store Credit"}</Text>
                </Col>
                <Col flex={1} style={{ alignItems: "center" }}>
                    <Text small>{"Points Balance"}</Text>
                </Col>
                <Col flex={1} style={{ alignItems: "center" }}>
                    <Text small>{"Rewards"}</Text>
                </Col>
            </Row>
        );
        if (!user._id) {
            return null;
        }
        if (punchcards.length === 0) {
            return (
                <View
                    style={{
                        borderWidth: 1,
                        ...Styles.panel,
                        width: 500,
                        alignSelf: "flex-start"
                    }}
                >
                    <Text>No Punchcards Yet 🤷‍♂️</Text>
                </View>
            );
        }
        const sortPurchases = (a: any, b: any) => {
            return b.totalPurchases - a.totalPurchases;
        };
        return (
            <View
                style={{
                    ...Styles.panel
                }}
            >
                {headerRow}
                {punchcards.sort(sortPurchases).map((punchcard, i) => {
                    return (
                        <Punchcard
                            key={punchcard._id}
                            punchcard={punchcard}
                            rewards={rewards.filter((reward) => {
                                return reward.store._id === punchcard.store._id;
                            })}
                        />
                    );
                })}
            </View>
        );
    }
}
