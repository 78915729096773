import React, { Component } from "react";

import { View, Text, Button, Toggle } from "../../SharedComponents";
import { Modal as BootstrapModal } from "react-bootstrap";
import Modal from "react-modal";

import { RowInput, RowCustomInput } from "../../SharedComponents";

import api from "../../api";
import * as Helpers from "../../utils/Helpers";

import Select from "react-select";

import { GIFT_TEMPLATE, REWARD_TEMPLATE } from "../../utils/Templates";
import { IOption } from "../../redux/types";
import {
    IUser,
    IStore,
    IProduct,
    IRewardTemplate,
    IGiftTemplate
} from "@snackpass/snackpass-types";
import * as Sentry from "@sentry/browser";

type Props = {
    onCloseModal?: Function;
    buttonLabel?: string;
    dispatch: Function;
    stores: IStore[];
    user: IUser;
};

// this is messed up
interface State extends IGiftTemplate, IRewardTemplate {
    modalVisible: boolean;
    products: IProduct[];
    loading: boolean;
    addReward: boolean;
    storeObj: IStore | null;
    productId: string;
}

function formatJson(state: State, user: IUser) {
    let json: any = {
        message: state.message,
        storeId: state.storeId,
        toUserId: user._id,
        public: state.public,
        isAdmin: state.isAdmin,
        numPoints: state.numPoints,
        storeCredit: state.storeCredit,
        globalCredit: state.globalCredit,
        rewardTemplate: null
    };
    if (state.addReward) {
        const product = state.products.find(
            (product) => product._id === state.productId
        );
        json.rewardTemplate = {
            discount: { percentOff: 100 },
            name: `${product ? product.name : ""} 🛍`,
            nameForStore: "Gift Purchase",
            storewide: false,
            productIds: [state.productId],
            categories: []
        };
    }
    return json;
}

export default class GiftBuilder extends Component<Props, State> {
    state = {
        ...GIFT_TEMPLATE,
        ...REWARD_TEMPLATE,
        modalVisible: false,
        products: [],
        storeObj: null as IStore | null,
        loading: false,
        addReward: false,
        productId: ""
    };
    componentDidMount() {}
    loadProducts = (storeId: string) => {
        api.stores.getProducts(storeId).then((response) => {
            this.setState({
                products: response.data.products
            });
        });
    };
    endLoading = () => {
        this.setState({
            loading: false
        });
    };
    handleSubmit = () => {
        const { user } = this.props;
        if (this.state.loading) {
            return;
        }
        this.setState(
            {
                loading: true
            },
            () => {
                let post = formatJson(this.state, user);
                api.gifts
                    .create(post)
                    .then((response) => {
                        this.closeModal();
                        this.endLoading();
                    })
                    .catch((err) => {
                        Sentry.captureException(err);
                        window.alert(
                            `Problem creating gift 🤦‍♀\n${err.response.data.message}`
                        );
                        this.endLoading();
                    });
            }
        );
    };
    openModal = () => {
        this.setState({ modalVisible: true });
    };
    closeModal = () => {
        this.setState({ modalVisible: false });
        if (this.props.onCloseModal) {
            this.props.onCloseModal();
        }
    };
    render() {
        const { addReward } = this.state;
        /**
         *
         *
         *
         * Primary Gift Fields
         *
         *
         *
         */
        const storeOptions = this.props.stores.map((store) => ({
            _id: store._id,
            name: `${store.name} (${store.region})`
        }));
        let SelectStore = (
            <RowCustomInput label="Store">
                <Select
                    className="snackpass__react-select"
                    name="type"
                    placeholder="select store"
                    options={Helpers.formatArrayForSelect(storeOptions)}
                    onChange={(item: any) => {
                        if (!item) return;
                        let storeId = item.value;
                        let storeObj = Helpers.arrToObj(this.props.stores)[
                            storeId
                        ];
                        this.setState(
                            {
                                storeId: storeId,
                                storeObj: storeObj
                            },
                            () => {
                                this.loadProducts(storeId);
                            }
                        );
                    }}
                />
            </RowCustomInput>
        );
        /**
         *
         * Only show these fields if store is selected
         *
         */ let StoreCredit;
        let GlobalCredit;
        let NumPoints;
        let Message;
        let AddReward;
        if (this.state.storeId) {
            GlobalCredit = (
                <RowInput
                    label="Snackpass credit"
                    number
                    placeholder="Snackpass credit"
                    value={this.state.globalCredit}
                    onChangeText={(globalCredit) => {
                        this.setState({
                            globalCredit: globalCredit
                        });
                    }}
                />
            );
            StoreCredit = (
                <RowInput
                    label="Store credit"
                    number
                    placeholder="store credit"
                    value={this.state.storeCredit}
                    onChangeText={(storeCredit) => {
                        this.setState({
                            storeCredit: storeCredit
                        });
                    }}
                />
            );
            NumPoints = (
                <RowInput
                    label="Points"
                    placeholder="numPoints"
                    integer
                    value={this.state.numPoints}
                    onChangeText={(numPoints) => {
                        this.setState({
                            numPoints: numPoints
                        });
                    }}
                />
            );
            Message = (
                <RowInput
                    label="Message"
                    placeholder="message 💭"
                    value={this.state.message}
                    onChangeText={(message) =>
                        this.setState({ message: message })
                    }
                />
            );
            AddReward = (
                <RowCustomInput label="Add Reward">
                    <Toggle
                        value={this.state.addReward}
                        onToggle={(value) =>
                            this.setState({
                                addReward: value
                            })
                        }
                    />
                </RowCustomInput>
            );
        }
        /**
         *
         * Optional Reward Template
         *
         */ let SelectProduct;
        SelectProduct = (
            <RowCustomInput label="Products">
                <Select
                    name="type"
                    placeholder="select products"
                    options={Helpers.formatArrayForSelect(this.state.products)}
                    className="snackpass__react-select"
                    style={{ width: 400, height: "auto" }}
                    onChange={(selected: IOption) => {
                        selected &&
                            this.setState({
                                productId: selected.value as string
                            });
                    }}
                />
            </RowCustomInput>
        );
        if (this.state.storewide) {
            SelectProduct = null;
        }
        let RewardTemplate;
        if (addReward) {
            RewardTemplate = <div>{SelectProduct}</div>;
        }
        /**
         *
         * End of Reward Template
         *
         */ return (
            <View>
                <div>
                    <Button
                        onPress={this.openModal}
                        label={this.props.buttonLabel || "Send Gift 🎁"}
                    />
                </div>
                <Modal
                    isOpen={this.state.modalVisible}
                    onRequestClose={this.closeModal}
                >
                    <BootstrapModal.Header
                        closeButton
                        onClick={this.closeModal}
                    >
                        <BootstrapModal.Title>
                            <Text large>Gift Builder 🎁</Text>
                        </BootstrapModal.Title>
                    </BootstrapModal.Header>
                    <BootstrapModal.Body>
                        <View>
                            {SelectStore}
                            {GlobalCredit}
                            {StoreCredit}
                            {NumPoints}
                            {Message}
                            {AddReward}
                            {RewardTemplate}
                        </View>
                    </BootstrapModal.Body>
                    <BootstrapModal.Footer>
                        <div>
                            <Button
                                label="Submit"
                                onPress={this.handleSubmit}
                            />
                        </div>
                        <br />
                    </BootstrapModal.Footer>
                </Modal>
            </View>
        );
    }
}
