import { Colors } from "../../../utils";

export const styles = {
    mobileRowContainer: {
        display: "flex" as "flex",
        flex: 1,
        flexDirection: "column" as "column",
        backgroundColor: Colors.white,
        borderColor: Colors.lightGray,
        borderWidth: 0,
        borderBottomWidth: 1,
        padding: 10,
        paddingLeft: 15,
        paddingRight: 15,
        alignSelf: "stretch" as "stretch",
        borderStyle: "solid" as "solid",
        alignItems: "stretch" as "stretch",
        justifyContent: "space-between" as "space-between",
    },
};
