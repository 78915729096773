import { HourItemTime, HoursItem, DayOfWeek } from "./types";
import {
    Time,
    NormalizedWeekMinuteOffset,
    computeTime,
    parseTime,
} from "../../utils/TimeUtil";
import moment from "moment";
import { ITimeRangeSchema } from "@snackpass/snackpass-types";

export const MINUTES_PER_HOUR = 60;
export const MINUTES_PER_DAY = MINUTES_PER_HOUR * 24;
export const DAYS_OF_WEEK = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
];

export const dayOptions = DAYS_OF_WEEK.map(d => ({
    label: d,
    value: d,
}));

export const TIMEZONES = [
    { label: "Eastern", value: 0 },
    { label: "Pacific", value: -180 },
    { label: "Central", value: -60 },
    { label: "Mountain", value: -120 },
];

export const format = "h:mm a";

export function hourItemTimeToLennyTime(hourItemTime: HourItemTime): Time {
    let m = moment(hourItemTime.time);
    return {
        day: DAYS_OF_WEEK.indexOf(hourItemTime.dayOfWeek),
        hours: m.hours(),
        minutes: m.minutes(),
        seconds: m.seconds(),
    };
}

export function hourItemTimeToNormalizedWeekOffset(
    hourItemTime: HourItemTime
): NormalizedWeekMinuteOffset {
    return computeTime(hourItemTimeToLennyTime(hourItemTime));
}

export function formatHourItem(hoursItem: HoursItem): ITimeRangeSchema {
    return {
        start: hourItemTimeToNormalizedWeekOffset(hoursItem.startTime),
        end: hourItemTimeToNormalizedWeekOffset(hoursItem.endTime),
    };
}

export function transformFormHoursVersionToHours(
    local: HoursItem[]
): ITimeRangeSchema[] {
    return local
        .map(hoursItem => formatHourItem(hoursItem))
        .sort((a: any, b: any) => {
            if (a.start > b.start) {
                return 1;
            } else if (a.start === b.start && a.end > b.end) {
                return 1;
            }
            return -1;
        });
}

export function transformHoursToFormHoursVersion(
    local: ITimeRangeSchema[]
): HoursItem[] {
    return local.map(timeRange => {
        return formatTimeRangeAsHourItem(timeRange);
    });
}

export function formatTimeRangeAsHourItem(
    timeRange: ITimeRangeSchema
): HoursItem {
    let parseStartTime = parseTime(timeRange.start);
    let parseEndTime = parseTime(timeRange.end);
    return {
        startTime: {
            time: moment()
                .hour(parseStartTime.hours)
                .minute(parseStartTime.minutes)
                .toDate(),
            dayOfWeek: DAYS_OF_WEEK[
                parseTime(timeRange.start).day
            ] as DayOfWeek,
        },
        endTime: {
            time: moment()
                .hour(parseEndTime.hours)
                .minute(parseEndTime.minutes)
                .toDate(),
            dayOfWeek: DAYS_OF_WEEK[parseTime(timeRange.end).day] as DayOfWeek,
        },
    };
}
