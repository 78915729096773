import React, { useState, useEffect } from "react";

import * as Styles from "../../utils/Styles";

import { IRegion } from "@snackpass/snackpass-types";
import { Button } from "../../SharedComponents";
import RegionFormModal from "../../modals/RegionFormModal";
import { RegionsTable } from "../../components/RegionsTable/RegionsTable";
import { Card } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { show, hide } from "redux-modal";
import {
    getRegionsLoaded,
    getRegions,
    fetchRegions,
} from "../../redux/regions";
import Search from "../../components/SearchBar";
import { Colors } from "../../utils";

const Regions = () => {
    const dispatch = useDispatch();
    const regions = useSelector(getRegions);
    const isLoading = useSelector(getRegionsLoaded);
    const { handleSearch, filteredRegions } = useRegionSearch(regions);

    return (
        <div>
            <RegionFormModal
                onSuccess={() => {
                    dispatch(fetchRegions());
                    dispatch(hide("RegionFormModal"));
                }}
            />
            <Card
                style={{
                    width: "90%",
                    margin: "0 auto",
                    borderRadius: 5,
                    ...Styles.shadowLight,
                }}
                headStyle={{
                    fontSize: 22,
                    borderBottom: "none",
                }}
                bodyStyle={{
                    padding: 0,
                }}
                title="Regions 🌍"
                extra={
                    <Button
                        label="New Region"
                        onPress={() => dispatch(show("RegionFormModal"))}
                    />
                }
            >
                <div style={styles.searchContainer}>
                    <Search
                        onSearch={handleSearch}
                        onClear={handleSearch}
                        buttonColor={Colors.blue}
                        placeholder="Search for a region by name"
                    />
                </div>
                <RegionsTable regions={filteredRegions} isLoading={isLoading} />
            </Card>
        </div>
    );
};

const useRegionSearch = (regions: IRegion[]) => {
    const [filteredRegions, setFilteredRegions] = useState<IRegion[]>([]);
    const [query, setQuery] = useState("");

    const filterBySearch = (query: string) => {
        return (region: IRegion) => {
            const patt = new RegExp(query, "i");
            return patt.test(region.name);
        };
    };

    const searchResults = (query: string = "") =>
        regions.filter(filterBySearch(query));

    const handleSearch = (query: string = "") => setQuery(query);

    const filterRegions = () => searchResults(query);

    useEffect(() => {
        setFilteredRegions(filterRegions());
    }, [regions, query]);

    return { handleSearch, filteredRegions };
};

export default Regions;

const styles = {
    searchContainer: {
        padding: "0 24px 12px 24px",
        marginTop: "-6px",
    },
};
