/**
 * This file declares all routes and which components
 * are displayed when a certain route is navigated to
 *
 * Also includes the top navigation bar which persists
 * in all of the pages.
 */
import React from "react";
import firebase from "../firebase";
import { Text, Button } from "../SharedComponents";

const NotAuthorized = () => (
    <div
        style={{
            flexDirection: "column",
            alignItems: "center",
            display: "flex",
        }}
    >
        <Text center style={{ width: "100%", marginTop: 100 }}>
            You are not authorized 😑 Please contact team@snackpass.co
        </Text>
        <Button
            label="Sign Out"
            onPress={() =>
                firebase
                    .auth()
                    .signOut()
                    .then(() => {
                        window.location.reload();
                    })
            }
        />
    </div>
);

export default NotAuthorized;
