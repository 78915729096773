import { IUser } from "@snackpass/snackpass-types";
import { connect } from "react-redux";

import { setAdmin } from "../redux/admins";
import CopyPasswordResetLink from "../SharedComponents/CopyPasswordResetLink";
import { Dispatch } from "redux";
import { StoreState } from "../redux/types";

const mapStateToProps = (state: StoreState, props: any) => {
    return {};
};

// create handler than dispatches an action
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setAdmin: (admin: IUser) => {
            dispatch(setAdmin(admin));
        },
        dispatch,
    };
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(CopyPasswordResetLink);

export default Container;
