import React from "react";
import Select from "react-select";
import { TAX_REMITTER_OPTIONS } from "src/components/RegionForm/RegionForm";
import { IOption } from "src/redux/types";

export interface MySelectProps {
    options: IOption[];
    isMulti: boolean;
    field: any;
    form: any;
}
// Requires funky mapping of options to value because select requires
// value={ value: "x", label: "y"} as the value, whereas the value in form is just
// stored as value="x"

export class MySelect extends React.Component<MySelectProps> {
    handleChange = (value: any) => {
        const { isMulti } = this.props;
        // this is going to call setFieldValue and manually update values.topcis
        // when isMulti, instead of sending up array of objects of IOption,
        // convert to array of values (which are strings)
        // when not multi, send back value (string) instead of object (IOption)
        this.props.form.setFieldValue(
            this.props.field.name,
            isMulti ? value.map((option: IOption) => option.value) : value.value
        );
    };

    handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched.topcis
        this.props.form.setFieldTouched(this.props.field.name, true);
    };

    componentDidUpdate(prevProps: MySelectProps) {
        const {
            form: {
                values: { state },
                setFieldValue
            }
        } = this.props;

        if (state !== prevProps.form.values.state && state === "CA") {
            // default to Snackpass
            setFieldValue("remitter", TAX_REMITTER_OPTIONS[1].value);
        }
    }

    render() {
        const { options, isMulti, field } = this.props;
        const value = field.value;
        const singleSelectValue = options.find((o) => o.value === value);
        return (
            <div style={{ margin: "1rem 0" }}>
                <Select
                    id="color"
                    options={options}
                    isMulti={isMulti}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    value={
                        isMulti && value
                            ? // not performant, but clearest representation of what is happening
                              value.map((val: string) =>
                                  options.find((o) => o.value === val)
                              )
                            : singleSelectValue
                    }
                    className="snackpass__react-select"
                />
            </div>
        );
    }
}
