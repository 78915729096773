import React, { Component } from "react";

import { View, Text } from "../SharedComponents";
import AsyncSelect from "react-select/async";

import api from "../api";
import { Colors } from "../utils/Colors";
import firebase from "../firebase";

import { isEmail, transformIfPhoneNumber } from "../utils/Helpers";
import { IUser } from "@snackpass/snackpass-types";

type Props = {
    dispatch: Function;
    setActiveUser: Function;
    setPunchcards: Function;
    setRewards: Function;
};

type State = {
    query: string;
    isLoading: boolean;
};

export default class SearchUser extends Component<Props, State> {
    typingTimer: any;
    state: State = {
        query: "",
        isLoading: false
    };
    componentDidMount() {
        let query = new URLSearchParams(window.location.search);
        const email = query.get("email");

        firebase.auth().onAuthStateChanged(async (u) => {
            if (u) {
                if (email) {
                    setTimeout(async () => {
                        let results = await this.search(email as any);
                        this.handleSelect(results[0]);
                    }, 100);
                }
            }
        });
    }

    handleChange = (query: string, callback: any) => {
        this.setState({
            query: query,
            isLoading: true
        });

        if (!query) {
            return this.setState({ isLoading: false });
        }

        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(async () => {
            // check and transform if value is a phone number
            query = transformIfPhoneNumber(query);

            let users = await this.search(query);
            this.setState({ isLoading: false });
            callback(users);
        }, 500);
    };

    handleSelect = (item: any) => {
        this.props.setActiveUser(item.user);
        api.users.getPunchcards(item.user._id).then((response) => {
            this.props.setPunchcards(response.data.punchcards);
        });
        api.users.getRewards(item.user._id).then((response) => {
            this.props.setRewards(response.data.rewards);
        });
        this.updateUrl(item.user.email);
    };

    updateUrl(email: string) {
        if (!email) {
            return;
        }
        let search = [];
        if (email !== undefined) {
            search.push("email=" + email);
        }
        let searchQuery = "";
        if (search.length > 0) {
            searchQuery = "?" + search.join("&");
        }
        let newurl =
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname +
            searchQuery;
        window.history.pushState({ path: newurl }, "", newurl);
    }

    search = async (query: string) => {
        const _isEmail = isEmail(query);
        let res = await api.users.search(query, _isEmail);
        return res.data.users.map((user: IUser) => {
            return {
                value: user._id,
                user: user,
                label: `${user.name} - ${user.email}`
            };
        });
    };

    render() {
        return (
            <View>
                <View
                    style={{
                        padding: 15,
                        alignSelf: "center",
                        borderColor: Colors.lightGray,
                        alignItems: "center"
                    }}
                >
                    <Text small bold style={{ marginRight: 10 }}>
                        Search User
                    </Text>
                    <AsyncSelect
                        className="snackpass__react-select"
                        name="type"
                        isLoading={this.state.isLoading}
                        placeholder="select user"
                        loadOptions={this.handleChange}
                        onChange={this.handleSelect}
                        styles={{
                            container: (base) => ({
                                ...base,
                                width: "400px"
                            }),
                            menu: (base) => ({
                                ...base,
                                width: "400px"
                            })
                        }}
                    />
                </View>
            </View>
        );
    }
}
