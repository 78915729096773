import React, { CSSProperties } from "react";
import { Colors } from "src/utils/Colors";
import { Button } from "../../SharedComponents";
import { Option } from "src/screens/Stores/index";

type Props = {
    display: Option | null | undefined;
    setFilter: (value: null) => void;
    setFilterDisplay: (display: null) => void;
};

// button component that only handles clearing each filter dorpdown menu on the snackface homepage
const ClearFilterButton = (props: Props) => {
    const { display, setFilter, setFilterDisplay } = props;

    if (!display) {
        return null;
    }

    return (
        <Button
            label="X"
            onPress={() => {
                setFilter(null);
                setFilterDisplay(null);
            }}
            style={styles.clearButton}
        />
    );
};

export default ClearFilterButton;

const styles = {
    clearButton: {
        height: 40,
        width: 40,
        borderRadius: 5,
        backgroundColor: Colors.red,
        cursor: "pointer",
    } as CSSProperties,
};
