import React, { Component } from "react";

import { View, Text } from "../../SharedComponents";
import * as Styles from "../../utils/Styles";
import SearchUser from "../../containers/SearchUser";
import UserPreview from "../../containers/UserPreview";
import Punchcards from "../../containers/Punchcards";

type Props = {};

export default class Users extends Component<Props> {
    componentDidMount() {}
    render() {
        return (
            <section style={{ flex: 1 }}>
                <View style={Styles.panel}>
                    <Text large>Users 🧜‍♀️</Text>
                    <hr />
                    <SearchUser />
                </View>
                <br />
                <UserPreview />
                <br />
                <Punchcards />
            </section>
        );
    }
}
