// This file can be abstracted for all forms using Formik
import React, { ReactNode, CSSProperties } from "react";
import { Field, ErrorMessage, FieldProps, FastField, FieldArray } from "formik";
import { Checkbox } from "react-formik-ui";
import Select from "react-select";

import { Colors } from "../utils";
import { IOption } from "../redux/types";
import { Toggle } from "../SharedComponents/Toggle";
import { FileUpload } from "../SharedComponents/FileUpload";
import _ from "lodash";

const tinycolor = require("tinycolor2");

export const styles: { [className: string]: CSSProperties } = {
    inputContainer: {
        backgroundColor: Colors.white,
        borderColor: Colors.lightGray,
        borderWidth: 0,
        borderBottomWidth: 1,
        borderStyle: "solid" as "solid"
    } as CSSProperties,
    inputInnerContainer: {
        display: "flex" as "flex",
        flexDirection: "row" as "row",
        alignSelf: "stretch" as "stretch",
        alignItems: "flex-start" as "flex-start",
        paddingTop: 15,
        paddingBottom: 15
    } as CSSProperties,
    inputLabel: {
        marginBottom: 5
    } as CSSProperties,
    inputDescription: {
        fontSize: 12,
        color: Colors.warmGrey
    } as CSSProperties,
    header: {
        background: Colors.blue2,
        padding: 15,
        paddingTop: 5,
        paddingBottom: 5,
        margin: 0,
        borderWidth: 0,
        borderBottomWidth: 1,
        borderColor: "white" as "white",
        borderStyle: "solid" as "solid",
        marginBottom: 10,
        color: "white" as "white"
    } as CSSProperties,
    errorMessage: {
        color: Colors.red,
        fontSize: 12
    } as CSSProperties
};

interface InputProps {
    required?: boolean;
    label: string;
    name: any;
    description?: string;
    type:
        | "toggle"
        | "select"
        | "text"
        | "date"
        | "datetime"
        | "email"
        | "password"
        | "number"
        | "hours-of-week"
        | "checkbox"
        | "read-only-text"
        | "file-upload"
        | "array";
    children?: ReactNode;
    options?: IOption[];
    isMulti?: boolean;
    index?: number;
    flexDirection?: any;
    fastfield?: boolean;
    disabled?: boolean;
    value?: string;
    placeholder?: string;
    onSelect?: (value: any) => void;
    onToggle?: (value: any) => void;
    inputContainerStyle?: CSSProperties;

    // For file upload
    showsInputUrl?: boolean;
    imagePath?: string;
    showFilePreview?: boolean;
    onFileUploaded?: (file: any) => void;
    allowedFiles?: string[];
    error?: string;
    overrideMaxFileSize?: number;
    arrayType?: "color-text" | "number";
    checkedValues?: string[];
    selectClassNameOverride?: string;
    isClearable?: boolean;
}

// Custom wrapper around Formik's Field component switches field type with
// either Formik Field or our own custom input. If you are adding a new custom
// input, be sure to use the component prop for Formik Field.
export const Input = ({
    name,
    label,
    description,
    type,
    options,
    required,
    children,
    isMulti = false,
    flexDirection = "column" as "column",
    fastfield = false,
    disabled,
    placeholder,
    onSelect,
    onToggle,
    inputContainerStyle,
    // For file upload
    showsInputUrl,
    imagePath,
    showFilePreview,
    onFileUploaded,
    allowedFiles,
    error,
    overrideMaxFileSize,
    arrayType,
    selectClassNameOverride,
    isClearable
}: InputProps) => {
    // Map type prop to different types of custom inputs.
    let FieldInput;
    if (type === "select") {
        if (options) {
            FieldInput = (
                <Field
                    name={name}
                    component={({ form, field }: FieldProps<any>) => (
                        <MySelect
                            form={form}
                            field={field}
                            disabled={disabled}
                            options={options}
                            isMulti={isMulti}
                            onSelect={onSelect}
                            selectClassNameOverride={selectClassNameOverride}
                            isClearable={isClearable}
                        />
                    )}
                />
            );
        } else {
            FieldInput = (
                <span style={styles.errorMessage}>
                    Pass options to component
                </span>
            );
        }
    } else if (type === "toggle") {
        FieldInput = (
            <Field
                name={name}
                component={({ form, field }: FieldProps<any>) => (
                    <MyToggle
                        form={form}
                        field={field}
                        disabled={disabled}
                        onToggle={onToggle}
                    />
                )}
            />
        );
    } else if (type === "checkbox") {
        FieldInput = <Checkbox name={name} />;
    } else if (type === "read-only-text") {
        FieldInput = (
            <Field
                name={name}
                render={({ field, form }: FieldProps<any>) => (
                    <p style={{ fontWeight: "bold" }}>{field.value}</p>
                )}
            />
        );
    } else if (type === "file-upload") {
        FieldInput = (
            <Field
                name={name}
                render={({ field, form }: FieldProps<any>) => (
                    <FileUpload
                        onFileUpload={(url, fileData) => {
                            form.setFieldValue(name, url);
                            onFileUploaded && onFileUploaded(fileData);
                        }}
                        allowedFiles={allowedFiles}
                        showFilePreview={showFilePreview}
                        showsInputUrl={showsInputUrl}
                        multipleFiles={false}
                        prevUrl={field.value}
                        path={imagePath}
                        overrideMaxFileSize={overrideMaxFileSize}
                    />
                )}
            />
        );
    } else if (type === "array") {
        FieldInput = (
            <FieldArray
                name={name}
                render={(arrayHelpers) => (
                    <div>
                        {_.get(arrayHelpers.form.values, arrayHelpers.name).map(
                            (item: any, index: any) => (
                                <div key={index}>
                                    <div
                                        style={{
                                            display: "flex",
                                            paddingBottom: 5
                                        }}
                                    >
                                        <FieldArrayField
                                            arrayType={arrayType}
                                            arrayHelpers={arrayHelpers}
                                            index={index}
                                        />
                                        <button
                                            style={styles.button}
                                            type="button"
                                            onClick={() =>
                                                arrayHelpers.remove(index)
                                            }
                                        >
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            )
                        )}
                        <button
                            type="button"
                            onClick={() => arrayHelpers.push("")}
                        >
                            Add
                        </button>
                    </div>
                )}
            />
        );
    } else {
        if (fastfield) {
            FieldInput = (
                <FastField style={{ width: "100%" }} name={name} type={type}>
                    {children}
                </FastField>
            );
        } else {
            FieldInput = (
                <Field
                    style={{ width: "100%" }}
                    placeholder={placeholder}
                    name={name}
                    type={type}
                >
                    {children}
                </Field>
            );
        }
    }

    // required prop is purely stylistic.
    let Required;
    if (required) {
        Required = <span style={{ color: Colors.red }}>*</span>;
    }

    let Disabled;
    if (disabled) {
        Disabled = (
            <span style={{ color: Colors.gray }}>(this field is disabled)</span>
        );
    }

    return (
        <div style={{ ...styles.inputContainer, ...inputContainerStyle }}>
            <div
                style={{
                    ...styles.inputInnerContainer,
                    flexDirection: flexDirection
                }}
            >
                <div style={{ flex: 1, paddingRight: 20 }}>
                    <p style={styles.inputLabel}>
                        {label} {Required}
                    </p>
                    <p style={styles.inputDescription}>{description} </p>

                    <ErrorMessage
                        // Class is for testing
                        className={`error-${name}`}
                        name={name}
                        render={(msg) => (
                            <span style={styles.errorMessage}>{msg}</span>
                        )}
                    />
                </div>
                <div className="snackpass__input-container" style={{ flex: 1 }}>
                    {FieldInput} <br />
                    {Disabled}
                </div>
            </div>
        </div>
    );
};

// Header function to divide form into easy to read sections
export const Header = ({ label }: { label: string }) => (
    <div>
        <h3 style={styles.header}>{label}</h3>
    </div>
);

interface MySelectProps {
    options: IOption[];
    isMulti: boolean;
    field: any;
    form: any;
    disabled?: boolean;
    onSelect?: (value: any) => void;
    selectClassNameOverride?: string;
    isClearable?: boolean;
}

// Requires funky mapping of options to value because select requires
// value={ value: "x", label: "y"} as the value, whereas the value in form is just
// stored as value="x"
export class MySelect extends React.Component<MySelectProps> {
    handleChange = (value: any) => {
        const { isMulti, disabled, onSelect } = this.props;

        // do not change if the select is disabled
        if (disabled) {
            return;
        }

        // this is going to call setFieldValue and manually update the form value
        // when isMulti, instead of sending up array of objects of IOption,
        // convert to array of values (which are strings)
        // when not multi, send back value (string) instead of object (IOption)
        this.props.form.setFieldValue(
            this.props.field.name,
            isMulti
                ? value.map((option: IOption) => option.value)
                : value === null || value === undefined
                ? null
                : value.value
        );

        if (onSelect) {
            onSelect(value?.value);
        }
    };

    handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched.topcis
        this.props.form.setFieldTouched(this.props.field.name, true);
    };

    render() {
        const {
            options,
            isMulti,
            field,
            selectClassNameOverride,
            isClearable
        } = this.props;
        const value = field.value;
        const singleSelectValue = options.find((o) => o.value === value);
        return (
            <div style={{ margin: "1rem 0", width: "100%" }}>
                <Select
                    id="color"
                    options={options}
                    isMulti={isMulti}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    className={
                        selectClassNameOverride ?? "snackpass__react-select"
                    }
                    value={
                        isMulti && value
                            ? // not performant, but clearest representation of what is happening
                              value.map((val: string) =>
                                  options.find((o) => o.value === val)
                              )
                            : singleSelectValue
                    }
                    isClearable={isClearable ?? false}
                />
            </div>
        );
    }
}

interface ToggleProps extends FieldProps<any> {
    disabled?: boolean;
    onToggle?: (value: any) => void;
}

const MyToggle = ({ field, form, disabled, onToggle }: ToggleProps) => {
    const onFieldChange = (value: any) => {
        if (disabled) {
            return;
        }
        form.setFieldValue(field.name, value);
        // use try/catch because sometimes, if nested object, the field will not
        // exist yet.
        if (onToggle) {
            onToggle(value);
        }
        try {
            form.setFieldTouched(field.name, true);
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <Toggle
            disabled={disabled}
            value={field.value}
            onToggle={onFieldChange}
        />
    );
};

const FieldArrayField = ({ arrayType, arrayHelpers, index }: any) => {
    if (!arrayType) {
        return (
            <Field
                style={{ width: "100%" }}
                name={`${arrayHelpers.name}.${index}`}
            />
        );
    }
    if (arrayType === "color-text") {
        return (
            <Field
                style={{ width: "100%" }}
                name={`${arrayHelpers.name}.${index}`}
                innerRef={(target: any) => {
                    if (!target) return;
                    let c = tinycolor(target.value);
                    if (c.isValid()) {
                        target.style.backgroundColor = c.toHexString();
                        if (c.isLight()) {
                            target.style.color = Colors.text;
                        } else {
                            target.style.color = Colors.offWhite;
                        }
                    } else {
                        target.style.backgroundColor = Colors.white;
                        target.style.color = Colors.text;
                    }
                }}
            />
        );
    } else {
        return (
            <Field
                style={{ width: "100%" }}
                name={`${arrayHelpers.name}.${index}`}
                type={arrayType}
            />
        );
    }
};
