export type FormValues = {
    _id: string | null;
    type: string | null;
    map: string | null;
    gradientMask: string | null;
    colors: string[];
    rgbMask: string | null;
    rcolors: string[];
    gcolors: string[];
    bcolors: string[];
    decalMask: string | null;
    decalColor: string | null;
    alphaMask: string | null;
};

export const TOCHIMATERIAL_FORM_TEMPLATE: FormValues = {
    _id: null,
    type: null,
    map: null,
    gradientMask: null,
    colors: [],
    rgbMask: null,
    rcolors: [],
    gcolors: [],
    bcolors: [],
    decalMask: null,
    decalColor: null,
    alphaMask: null,
};
