import React, { ReactNode } from "react";
import { Colors } from "../utils";

export interface IText {
    xxlarge?: boolean;
    xlarge?: boolean;
    large?: boolean;
    small?: boolean;
    xsmall?: boolean;
    center?: boolean;
    bold?: boolean;
    color?: string;
    underline?: boolean;
    lineThrough?: boolean;
    style?: any;
    children?: ReactNode;
    onPress?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const Text = ({
    xxlarge,
    xlarge,
    large,
    small,
    xsmall,
    center,
    bold,
    color,
    underline,
    lineThrough,
    style,
    children,
    onPress,
}: IText) => {
    let fontSize = 16;
    let textAlign;
    textAlign = "left" as "left";

    let fontWeight: any;
    fontWeight = "normal" as "normal";

    let fontStyle = "none";

    if (xlarge) {
        fontSize = 30;
    }
    if (xxlarge) {
        fontSize = 36;
    }
    if (large) {
        fontSize = 18;
    }
    if (small) {
        fontSize = 14;
    }
    if (xsmall) {
        fontSize = 12;
    }
    if (center) {
        textAlign = "center" as "center";
    }
    if (bold) {
        fontWeight = "bold" as "bold";
    }
    if (underline) {
        fontStyle = "underline" as "underline";
    }
    if (lineThrough) {
        fontStyle = "line-through" as "line-through";
    }
    return (
        <span
            onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                onPress ? onPress(event) : () => {}
            }
            style={{
                color: color || Colors.text,
                fontSize: fontSize,
                textAlign: textAlign,
                fontWeight: fontWeight,
                ...style,
                textDecoration: fontStyle,
                display: "inline-block",
            }}
        >
            {children}
        </span>
    );
};
