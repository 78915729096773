import React, { Component } from "react";

import { View, Text, TouchableHighlight, Button } from "../../SharedComponents";

import { Colors } from "../../utils";
import { IPurchase } from "@snackpass/snackpass-types";

type Props = {
    delayPickup: Function;
    purchase: IPurchase;
    finishEarly: () => void;
    setAsCompleted: Function;
};
type State = {
    now: number;
    showDelayPanel: boolean;
};

const delayOptions = ["2", "5", "8", "10", "15"];

const secondsToTime = (secs: number) => {
    let hours = Math.floor(secs / 3600);
    secs %= 3600;
    let minutes = Math.floor(secs / 60);
    let seconds = secs % 60;

    let obj = {
        h: Math.floor(hours),
        m: Math.floor(minutes),
        s: Math.floor(seconds)
    };
    return obj;
};

const now = () => {
    return new Date().getTime();
};

export default class OrderStarted extends Component<Props, State> {
    state = {
        now: now(),
        showDelayPanel: false
    };
    getDelayButton = (time: string, i: number) => {
        const { delayPickup } = this.props;

        let innerText = `${time} min`;
        return (
            <TouchableHighlight
                key={i}
                onPress={() => {
                    delayPickup(time);
                }}
                style={ComponentStyles.buttonContainer}
            >
                <View key={i} style={ComponentStyles.selectTimeButton}>
                    <Text center color={Colors.white}>
                        {innerText}
                    </Text>
                </View>
            </TouchableHighlight>
        );
    };
    toggleDelayPanel = () => {
        this.setState({ showDelayPanel: !this.state.showDelayPanel });
    };
    _timer = () => {
        const { purchase, finishEarly } = this.props;
        const { now, showDelayPanel } = this.state;

        if (!purchase) {
            return null;
        }
        if (!purchase.pickupTimeDuration) {
            return null;
        }

        // @ts-ignore
        let secondsLeft = (new Date(purchase.pickupTime) - now) / 1000;
        if (secondsLeft < 0) {
            return <Text>{"Time's Up!"}</Text>;
        }

        let timeLeft = secondsToTime(secondsLeft);

        let minutes = timeLeft.m as number | string;
        if (minutes < 10) minutes = `0${minutes}`;

        let seconds = timeLeft.s as number | string;
        if (seconds < 10) {
            seconds = `0${seconds}`;
        }

        if (showDelayPanel) {
            return (
                <View>
                    <Text>{"Need to delay? Delay Order by:"}</Text>
                    <View
                        style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            margin: 10
                        }}
                    >
                        {delayOptions.map((time, i) => {
                            return this.getDelayButton(time, i);
                        })}
                    </View>

                    <Button
                        label={"Cancel"}
                        backgroundColor={Colors.red}
                        onPress={this.toggleDelayPanel}
                        style={{ width: 100, alignSelf: "flex-end" }}
                    />
                </View>
            );
        }

        return (
            <View
                style={{
                    flexDirection: "row",
                    justifyContent: "space-between"
                }}
            >
                <Text>
                    {`Time Left: ${timeLeft.h ? timeLeft.h + ":" : ""}
                    ${minutes}:${seconds}`}
                </Text>
                <View style={{ flexDirection: "row" }}>
                    <Button
                        label={"Finish Early"}
                        style={{ padding: 10 }}
                        onPress={finishEarly}
                    />
                    <Button
                        label={"Delay"}
                        backgroundColor={Colors.red}
                        style={{ padding: 10 }}
                        onPress={this.toggleDelayPanel}
                    />
                </View>
            </View>
        );
    };

    render() {
        const { purchase } = this.props;

        if (
            purchase.status.length &&
            purchase.status[purchase.status.length - 1].type === "COMPLETED"
        ) {
            return null;
        }
        return <View style={ComponentStyles.container}>{this._timer()}</View>;
    }
}

let ComponentStyles = {
    container: {
        width: "100%",
        justifyContent: "flex-start"
    },
    buttonContainer: {
        width: "23%",
        height: 50,
        marginVertical: 5
    },
    selectTimeButton: {
        backgroundColor: Colors.gray,
        borderRadius: 10,
        width: "100%",
        height: "100%",
        paddingVertical: 10,
        paddingHorizontal: 15,
        justifyContent: "center"
    }
};
