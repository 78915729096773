import React from "react";

import Modal from "react-responsive-modal";
import { connectModal, InjectedProps } from "redux-modal";
import ChainForm from "../components/ChainForm";
import { Colors } from "../utils";
import { IStore } from "@snackpass/snackpass-types";

const styles = {
    modal: {
        overlay: {
            background: "rgba(0,0,0,0.23)"
        },
        modal: {
            background: Colors.white,
            padding: 20,
            boxShadow: "0 2px 40px 0 rgba(0, 0, 0, 0.3)",
            borderRadius: 5,
            marginBottom: 100,
            width: "100%",
            maxWidth: "1000px" // react-responsive-modal typically has max width 800px, override as 1000px since the chain table is dense
        }
    }
};

export interface StoreFormModal extends InjectedProps {
    chain?: any;
    editMode?: boolean;
    onSuccess: (store: IStore) => void;
}

const StoreChainModal = ({
    show,
    handleHide,
    chain,
    editMode
}: StoreFormModal) => {
    return (
        <Modal onClose={handleHide} open={show} styles={styles.modal}>
            <ChainForm
                editMode={editMode}
                chain={chain}
                onSuccess={handleHide}
            />
        </Modal>
    );
};

export default connectModal({ name: "StoreChainModal" })(StoreChainModal);
