import { ITochiFile, ITochiObjectItem } from "@snackpass/snackpass-types";
import _ from "lodash";
import * as Yup from "yup";
import { TochiColors } from "../../../utils/TochiColors";
import { TOCHIITEM_FORM_TEMPLATE, FormValues } from "./types";
var tinycolor = require("tinycolor2");

export const itemTemplateOptions = [{ label: "Test", value: "TEST" }];

export const itemTypeOptions = [{ label: "Object", value: "OBJECT" }];

export const itemZoneOptions = [
    { label: "Head", value: "HEAD" },
    { label: "Top", value: "TOP" },
    { label: "Feet", value: "FEET" },
    { label: "Eyes", value: "EYES" },
    { label: "Ears", value: "EARS" },
    { label: "Waist", value: "WAIST" },
    { label: "Mouth", value: "MOUTH" },
    { label: "Neck", value: "NECK" },
    { label: "Forehead", value: "FOREHEAD" },
    { label: "Prop", value: "PROP" },
    { label: "Platform", value: "PLATFORM" },
];
export const itemRarityOptions = [
    { label: "Common", value: "COMMON" },
    { label: "Uncommon", value: "UNCOMMON" },
    { label: "Rare", value: "RARE" },
    { label: "Epic", value: "EPIC" },
    { label: "Legendary", value: "LEGENDARY" },
];
export const itemHideSubmeshOptions = [
    { label: "Hair", value: "HAIR" },
    { label: "Tail", value: "TAIL" },
    { label: "Feet", value: "FEET" },
];

export const materialTypeOptions = [
    { label: "Texture", value: "TEXTURE" },
    { label: "Gradient Mask", value: "GRADIENTMASK" },
    { label: "RGB Gradient Mask", value: "RGBGRADIENTMASK" },
];

export const itemCategoryOptions = [
    { label: "Aviators", value: "GLASSES_AVIATORS" },
    { label: "Campus Hoodie", value: "HOODIE_CAMPUS" },
    { label: "Canada Goose Jacket", value: "JACKET_CANADA_GOOSE" },
    { label: "Crown", value: "HAT_CROWN" },
    { label: "Eggshell Hat", value: "HAT_EGGSHELL" },
    { label: "Football", value: "FOOTBALL" },
    { label: "Headphones", value: "HEADPHONES" },
    { label: "Hipser Glasses", value: "GLASSES_HIPSTER" },
    { label: "Letterman", value: "JACKET_LETTERMAN" },
    { label: "Mage", value: "MAGE" },
    { label: "Normal Hoodie", value: "HOODIE_NORMAL" },
    { label: "Shirt", value: "SHIRT" },
    { label: "Shuttershades", value: "GLASSES_SHUTTERSHADES" },
    { label: "Sneakers", value: "SNEAKERS" },
    { label: "Wizard", value: "WIZARD" },
];

export const itemTabOptions = [
    { label: "Head", value: "HEAD" },
    { label: "Top", value: "TOP" },
    { label: "Feet", value: "FEET" },
    { label: "Accessories", value: "ACCESSORIES" },
    { label: "Full", value: "FULL" },
    { label: "Props", value: "PROPS" },
    { label: "Platform", value: "PLATFORM" },
];

export const platformOffsetOptions = [
    { label: "Platform -1", value: "PLATFORM_-1" },
    { label: "Platform -2", value: "PLATFORM_-2" },
    { label: "Platform -3", value: "PLATFORM_-3" },
];

export function initializeFields(item?: ITochiObjectItem): FormValues {
    if (!item) {
        return { ...TOCHIITEM_FORM_TEMPLATE };
    }

    return {
        template: _.get(item, "template", TOCHIITEM_FORM_TEMPLATE.template),
        name: _.get(item, "name", TOCHIITEM_FORM_TEMPLATE.name),
        type: _.get(item, "type", TOCHIITEM_FORM_TEMPLATE.type),
        zone: _.get(item, "zone", TOCHIITEM_FORM_TEMPLATE.zone),
        hide: _.get(item, "hide", TOCHIITEM_FORM_TEMPLATE.hide),
        description: _.get(
            item,
            "description",
            TOCHIITEM_FORM_TEMPLATE.description
        ),
        category: _.get(item, "category", TOCHIITEM_FORM_TEMPLATE.category),
        droppedByText: _.get(
            item,
            "giftText",
            TOCHIITEM_FORM_TEMPLATE.droppedByText
        ),
        rarity: _.get(item, "rarity", TOCHIITEM_FORM_TEMPLATE.rarity),
        thumbnail: _.get(item, "thumbnail", TOCHIITEM_FORM_TEMPLATE.thumbnail),
        platformOffset: _.get(
            item,
            "platformOffset",
            TOCHIITEM_FORM_TEMPLATE.platformOffset
        ),
        asset: {
            rig: {
                name: _.get(
                    item.asset.rig,
                    "name",
                    TOCHIITEM_FORM_TEMPLATE.asset.rig.name
                ),
            },
            alternateRigs: {
                "PLATFORM_-1": _.get(
                    item.asset.alternateRigs,
                    "PLATFORM_-1",
                    TOCHIITEM_FORM_TEMPLATE.asset.alternateRigs["PLATFORM_-1"]
                ),
                "PLATFORM_-2": _.get(
                    item.asset.alternateRigs,
                    "PLATFORM_-2",
                    TOCHIITEM_FORM_TEMPLATE.asset.alternateRigs["PLATFORM_-2"]
                ),
                "PLATFORM_-3": _.get(
                    item.asset.alternateRigs,
                    "PLATFORM_-3",
                    TOCHIITEM_FORM_TEMPLATE.asset.alternateRigs["PLATFORM_-3"]
                ),
            },
            material: {
                type: _.get(
                    item.asset.material,
                    "type",
                    TOCHIITEM_FORM_TEMPLATE.asset.material.type
                ),
                map:
                    _.get(
                        item.asset.material,
                        "map",
                        TOCHIITEM_FORM_TEMPLATE.asset.material.map
                    ) || null,
                gradientMask: _.get(
                    item.asset.material,
                    "gradientMask",
                    TOCHIITEM_FORM_TEMPLATE.asset.material.gradientMask
                ),
                colors: _.get(
                    item.asset.material,
                    "colors",
                    TOCHIITEM_FORM_TEMPLATE.asset.material.colors
                ),
                rgbMask: _.get(
                    item.asset.material,
                    "rgbMask",
                    TOCHIITEM_FORM_TEMPLATE.asset.material.rgbMask
                ),
                rcolors: _.get(
                    item.asset.material,
                    "rcolors",
                    TOCHIITEM_FORM_TEMPLATE.asset.material.rcolors
                ),
                gcolors: _.get(
                    item.asset.material,
                    "gcolors",
                    TOCHIITEM_FORM_TEMPLATE.asset.material.gcolors
                ),
                bcolors: _.get(
                    item.asset.material,
                    "bcolors",
                    TOCHIITEM_FORM_TEMPLATE.asset.material.bcolors
                ),
                decalMask: _.get(
                    item.asset.material,
                    "decalMask",
                    TOCHIITEM_FORM_TEMPLATE.asset.material.decalMask
                ),
                decalColor: _.get(
                    item.asset.material,
                    "decalColor",
                    TOCHIITEM_FORM_TEMPLATE.asset.material.decalColor
                ),
                alphaMask: _.get(
                    item.asset.material,
                    "alphaMask",
                    TOCHIITEM_FORM_TEMPLATE.asset.material.alphaMask
                ),
            },
        },

        price: _.get(item, "price", TOCHIITEM_FORM_TEMPLATE.price),
        tab: _.get(item, "tab", TOCHIITEM_FORM_TEMPLATE.tab),
        isUnlockable: _.get(
            item,
            "isUnlockable",
            TOCHIITEM_FORM_TEMPLATE.isUnlockable
        ),
        isActive: _.get(item, "isActive", TOCHIITEM_FORM_TEMPLATE.isActive),
        releaseDate: _.get(
            item,
            "releaseDate",
            TOCHIITEM_FORM_TEMPLATE.releaseDate
        ),
        createdAt: _.get(item, "createdAt", TOCHIITEM_FORM_TEMPLATE.createdAt),
    };
}

const validateColorArray = {
    name: "Invalid color array",
    message: "Must be only valid colors",
    test: (value: string[]) => {
        let valid = true;
        for (let i = 0; i < value.length; ++i) {
            let c = tinycolor(value[i]);
            if (!c.isValid()) valid = false;
            if (!valid) valid = TochiColors.includes(value[i]);
        }
        return valid;
    },
};
const validateColor = {
    name: "Invalid color",
    message: "Must be only valid colors",
    test: (value: string) => {
        let valid = true;
        let c = tinycolor(value);
        valid = c.isValid();
        if (!valid) valid = TochiColors.includes(value);
        return valid;
    },
};

export const validationSchema = Yup.object().shape({
    //template: Yup.string().nullable().oneOf(itemTemplateOptions.map(itemTemplate => itemTemplate.value)),
    name: Yup.string().required().nullable(),
    type: Yup.string()
        .oneOf(itemTypeOptions.map(itemType => itemType.value))
        .ensure()
        .required()
        .nullable(),
    zone: Yup.array(
        Yup.string().oneOf(itemZoneOptions.map(itemZone => itemZone.value))
    )
        .ensure()
        .required()
        .nullable(),
    hide: Yup.array(
        Yup.string().oneOf(
            itemHideSubmeshOptions.map(itemType => itemType.value)
        )
    )
        .ensure()
        .nullable(),
    description: Yup.string().required().nullable(),
    droppedByText: Yup.array().nullable(),
    rarity: Yup.string()
        .oneOf(itemRarityOptions.map(itemType => itemType.value))
        .required()
        .nullable(),
    thumbnail: Yup.string().url().required().nullable(),
    asset: Yup.object().shape({
        rig: Yup.object().shape({
            name: Yup.string().required().nullable(),
        }),
        material: Yup.object().shape({
            type: Yup.string()
                .oneOf(materialTypeOptions.map(matType => matType.value))
                .required()
                .nullable(),
            map: Yup.string().nullable().when("type", {
                is: "TEXTURE",
                then: Yup.string().url().required(),
            }),
            gradientMask: Yup.string()
                .nullable()
                .when("type", {
                    is: value =>
                        value &&
                        ["GRADIENTMASK", "RGBGRADIENTMASK"].includes(value),
                    then: Yup.string().url().required(),
                }),
            colors: Yup.array().when("type", {
                is: "GRADIENTMASK",
                then: Yup.array()
                    .ensure()
                    .min(1)
                    .required()
                    .test(validateColorArray),
            }),
            rgbMask: Yup.string().nullable().when("type", {
                is: "RGBGRADIENTMASK",
                then: Yup.string().url().required(),
            }),
            rcolors: Yup.array().when("type", {
                is: "RGBGRADIENTMASK",
                then: Yup.array()
                    .ensure()
                    .min(1)
                    .required()
                    .test(validateColorArray),
            }),
            gcolors: Yup.array().when("type", {
                is: "RGBGRADIENTMASK",
                then: Yup.array()
                    .ensure()
                    .min(1)
                    .required()
                    .test(validateColorArray),
            }),
            bcolors: Yup.array().when("type", {
                is: "RGBGRADIENTMASK",
                then: Yup.array()
                    .ensure()
                    .min(1)
                    .required()
                    .test(validateColorArray),
            }),
            decalMask: Yup.string().nullable().url(),
            decalColor: Yup.string()
                .nullable()
                .when("decalMask", {
                    is: value => value,
                    then: Yup.string().required().test(validateColor),
                }),
            alphaMask: Yup.string().nullable().url(),
        }),
    }),

    price: Yup.number().required().nullable(),
    tab: Yup.string()
        .oneOf(itemTabOptions.map(tab => tab.value))
        .nullable(),
    isUnlockable: Yup.boolean(),
    isActive: Yup.boolean(),
    createdAt: Yup.date().nullable(),
    releaseDate: Yup.date().nullable(),
});

export const giftTextByCategory: Record<string, string[]> = {
    FOOTBALL: [
        "{name}'s not even a football player. They just prefer to keep their head safe.",
        "{name}'s team won the Super Fowl!",
        "Who dat? Who dat? Who dat eatin' some snacks?",
        "{name} scored a touchdown and earned {coin} SnackCoin!",
        "{name} only knows one conversation topic, and that's sports.",
    ],
    GLASSES_AVIATORS: [
        "Fly? {name} doesn't have to. They're already fly enough.",
        "{name} spotted a burger-shaped cloud while chilling on the green.",
        "Bring on the sunshine and lemonade.",
        "{name} decided you're cool enough for some SnackCoin. They would know.",
        "Can you snackpass an effortlessly cool vibe? {name}'s asking for a friend.",
    ],
    GLASSES_HIPSTER: [
        "{name} would rather not admit how many pictures they took of their coffee.",
        "SnackCoin? {name} collected it before it was cool.",
        "{name} ate some avocado toast, and then blogged about it.",
        "{name} went to a concert today. You wouldn't know the band.",
        "{name} decided SnackCoin's too mainstream. You can have theirs.",
    ],
    GLASSES_SHUTTERSHADES: [
        "{name} spent all day vibing.",
        "{name} tried to pick up some chicks, but they crossed the road.",
        "Blocked out some haters, made some SnackCoin.",
        "{name}'s got Kanye on repeat - let the 2010s roll! ",
        "The party didn't start 'till {name} walked in.",
    ],
    HAT_CROWN: [
        "{name} collected taxes from the pheasants and got {coin} SnackCoin!",
        "Sneaking around in the palace treasury, {name} found {coin} SnackCoin!",
        "You'll get your SnackCoin, but only if you bow to {name} first.",
        "{name} doesn't really understand how to run the chick-dom, but that's okay.",
        "All hail {name} Teggeryan, Protector of Chick-dom, and Breaker of Coops!",
    ],
    HAT_EGGSHELL: [
        "{name} was literally born yesterday, and they already made {coin} SnackCoin!",
        "{name} found some spare change in their egg today!",
        "{name} is absolutely certain that the egg came first.",
        "{name} thinks you're totally egg-celent!",
        "{name} found {coin} SnackCoin while eggs-ercising!",
    ],
    HEADPHONES: [
        "{name} dropped a single on soundcloud - 20 followers and counting.",
        "{name} fell asleep listening to a soothing rooster compilation.",
        "{name} enjoyed some relaxing classical music today.",
        "Good luck trying to talk to {name}. They're channeling their inner Chicky Romero.",
        "{name} was gifted {coin} SnackCoin while streaming Chicken Simulator!",
    ],
    HOODIE_CAMPUS: [
        "{name} finally got into a party at Sigma Coup last night!",
        "{name} snackpassed caffeine to fuel up for their all-nighter!",
        "Don't tell their parents, but {name} was late to their lecture looking for SnackCoin!",
        "{name}'s parents sent them some SnackCoin and made their day!",
        "{name} was initiated into the Chi Ken birdority today!",
    ],
    HOODIE_NORMAL: [
        "{name} snuggled on the couch, made some popcorn, and watched netflix!",
        "{name} snackpassed a hot chocolate from the Chick Café!",
        "Did {name} wear that hoodie for a week, or do they just have 5 of the same?",
        "No free pizza is safe from {name}.",
        "{name}'s wings are too short to lift up the hood, but that's okay!",
    ],
    JACKET_CANADA_GOOSE: [
        "{name} is snug as a bug in a rug, or a chick in a...jacket.",
        "{name} had a great day. Just don't tell them what's in their coat.",
        "Sometimes, it pays to be extra poofy. Here's {coin} SnackCoin!",
        "Everyone knows the minute the first leaf falls, it's Canada Chick weather!",
        "{name} found {coin} Snackcoin in their jacket pocket!",
    ],
    JACKET_LETTERMAN: [
        "{name} earned letters for their jacket today! CC for Cool Chick.",
        "You could say {name}'s a bit of a bad bird.",
        "If napping was a sport, {name} would be an athlete.",
        "{name} proudly repped Chicken High today.",
        "{name} relived their high school glory days!",
    ],
    MAGE: [
        "{name} is never late, nor early. {name} arrives precisely when they mean to.",
        "Abracadabra! Here's {coin} SnackCoin.",
        "Sure, {name} could conjure up some food...but Snackpass just does it better.",
    ],
    SHIRT: [
        "{name} tried riding a bike today, but couldn't quite reach the handlebars.",
        "Sunbathing is great when you're a chicken...no weird tan lines!",
        "{name} sold some lemonade on the green for {coin} SnackCoin!",
        "Sure, {name} could've gone for a bolder outfit, but they're a little chicken.",
    ],
    SNEAKERS: [" "],
    WIZARD: [
        "{name} wanted to be a Chickendor, but was sorted into Huffowlpuff.",
        "{name} got some funny looks while picking up their Snackpass order on a broom.",
    ],
};

export const templates: Record<string, any> = {
    TEST: {
        name: "Test Item",
        type: "OBJECT",
        zone: ["HEAD"],
        hide: ["HAIR"],
        description: "This is a test item",
        category: null,
        droppedByText: [],
        rarity: "COMMON",
        thumbnail: "https://bit.ly/3oS6Sin",
        asset: {
            rig: {
                name: "Clothes_Shirt_RG",
            },
            material: {
                type: "TEXTURE",
                map: "https://bit.ly/3oS6Sin",
                gradientMask: null,
                colors: [],
                rgbMask: null,
                rcolors: [],
                gcolors: [],
                bcolors: [],
                decalMask: null,
                decalColor: null,
                alphaMask: null,
            },
        },
        price: 10,
        tab: "HEAD",
        isUnlockable: false,
        isActive: false,
    },
};
