import React, { CSSProperties, useEffect, useState } from "react";
import { Text, Button, Toggle, Row, Col } from "../../SharedComponents";
import Select from "react-select";
import * as Sentry from "@sentry/browser";
import api from "src/api";
import { Colors, Helpers, Styles } from "../../utils";
import { IOption } from "../../redux/types";
import {
    IStore,
    IGiftCard,
    GiftCardDiscriminator
} from "@snackpass/snackpass-types";
import ReactTable, { DefaultFilterFunction } from "react-table";

import "react-table/react-table.css";
import moment from "moment";
import { formatNumber, NumberFormat } from "libphonenumber-js";
import GiftCardRefundError from "src/modals/GiftCardRefundError";
import { Link } from "react-router-dom";

const selectorStyle = {
    width: 200,
    margin: 10
};

const filtersInitialState = {
    selectedStoreOption: null as IOption | null
};

const GIFTCARD = "Gift Cards";
const CENTS_CEIL = 100;
const NOT_ACTIVATED = "NOT ACTIVATED";
const GIFT_CARD_FEATURE = "GIFT CARD FEATURE ENABLED";
const GENERAL_GIFT_CARD_FEATURE = "GIFT CARD FEATURE FOR ALL STORES";
const ACTIVE_LABEL = "ON";
const INACTIVE_LABEL = "OFF";

enum GiftCardStatus {
    INACTIVE = "INACTIVE",
    SENT = "SENT",
    REDEEM = "REDEEMED",
    OOC = "OUT_OF_CREDIT",
    REFUNDED = "REFUNDED"
}

export interface GiftCardsScreenProps {
    giftcards: IGiftCard[];
    fetchStoreData: (storeId: string) => Promise<any>;
    fetchStores: () => Promise<any>;
    refundGiftCard: (giftCardId: string) => Promise<any>;
    setStore: (store: IStore) => void;
    storeOptions: IOption[];
    activeStore: IStore | null;
    showModal: (modal: string, props?: Object) => void;
    hideModal: (modal: string) => void;
}
const GiftCardsScreen = ({
    giftcards,
    activeStore,
    storeOptions,
    fetchStores,
    fetchStoreData,
    refundGiftCard,
    setStore,
    showModal,
    hideModal
}: GiftCardsScreenProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [filters, setFilters] = useState(filtersInitialState);

    // select store
    async function handleSelectStore(item: IOption) {
        if (!item) return;
        setIsLoading(true);
        setFilters({ ...filters, selectedStoreOption: item });
        await fetchStoreData(item.value as string);
        setIsLoading(false);
    }

    const handleRefund = async (giftCardId: string) => {
        try {
            await refundGiftCard(giftCardId);
        } catch (e) {
            showModal("GiftCardRefundError");
        }
    };

    const handleGiftCardToggle = async (hasGiftCardsEnabled: boolean) => {
        try {
            const res = await api.stores.update(activeStore!._id, {
                hasGiftCardsEnabled
            });
            setStore(res.data.store);
        } catch (err) {
            Sentry.captureException(err);
            window.alert("Problem updating feature 🤦‍♀️");
        }
    };

    const getLabel = (status: string, remCredit: number, maxCredit: number) => {
        if (status === GiftCardStatus.REFUNDED) return "Refunded";
        else if (remCredit < maxCredit) return "Can't be Refunded";
        else return "Refund";
    };

    const filterMethod: DefaultFilterFunction = (filter, row) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
            ? String(row[id]?.toLowerCase()).includes(
                  filter.value?.toLowerCase()
              )
            : true;
    };

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 300);
    }, []);

    return (
        <div style={{ ...Styles.panel, overflow: "scroll" }}>
            <Text large>{GIFTCARD}</Text>
            <Select
                className="snackpass__react-select"
                name="store"
                placeholder="select store first! 🚀"
                options={storeOptions}
                clearable={true}
                clearValueText="clear"
                style={selectorStyle}
                onChange={handleSelectStore}
                value={filters.selectedStoreOption}
            />

            <GiftCardRefundError />
            <hr />

            {activeStore ? (
                <>
                    <Row>
                        <Col style={styles.colStyles}>
                            <Text style={styles.textGCFeatures}>
                                {GIFT_CARD_FEATURE}
                            </Text>
                        </Col>
                        <Col>
                            <Toggle
                                activeLabel={ACTIVE_LABEL}
                                inactiveLabel={INACTIVE_LABEL}
                                value={activeStore.hasGiftCardsEnabled}
                                onToggle={handleGiftCardToggle}
                                label={GIFT_CARD_FEATURE}
                            />
                        </Col>
                    </Row>
                    <hr />
                </>
            ) : null}

            <ReactTable
                data={giftcards}
                loading={isLoading}
                className="-striped"
                defaultPageSize={20}
                defaultFilterMethod={filterMethod}
                defaultSorted={[
                    {
                        // the sorting model for the table
                        id: "createdAt",
                        desc: true
                    }
                ]}
                columns={[
                    {
                        Header: "Gift Card ID",
                        accessor: "id",
                        id: "id",
                        Cell: (props) => (
                            <div
                                style={{
                                    flexDirection: "row",
                                    display: "flex",
                                    flexWrap: "wrap"
                                }}
                            >
                                <span style={{ width: "100%" }}>
                                    {props.value}
                                </span>
                            </div>
                        )
                    },
                    {
                        Header: "Sender Email",
                        accessor: "fromEmail",
                        id: "fromEmail",
                        minWidth: 200,
                        Filter: ({ onChange, filter }) => {
                            return (
                                <input
                                    placeholder="Search sender's email"
                                    value={filter ? filter.value : ""}
                                    onChange={(e) => onChange(e.target.value)}
                                />
                            );
                        },
                        Cell: (props) => (
                            <div
                                style={{
                                    flexDirection: "row",
                                    display: "flex",
                                    flexWrap: "wrap"
                                }}
                            >
                                <span style={{ width: "100%" }}>
                                    {props.original.fromEmail}
                                </span>
                            </div>
                        ),
                        filterable: true,
                        style: { whiteSpace: "unset" }
                    },
                    {
                        Header: "Sender Name",
                        accessor: "fromName",
                        id: "senderName"
                    },
                    {
                        Header: "Recipient Number",
                        accessor: "toPhone",
                        id: "toPhone",
                        minWidth: 200,
                        Filter: ({ onChange, filter }) => {
                            return (
                                <input
                                    placeholder="Search recipient's phone"
                                    value={filter ? filter.value : ""}
                                    onChange={(e) => onChange(e.target.value)}
                                />
                            );
                        },
                        Cell: (props) => (
                            <div
                                style={{
                                    flexDirection: "row",
                                    display: "flex",
                                    flexWrap: "wrap"
                                }}
                            >
                                <span style={{ width: "100%" }}>
                                    {formatNumber(
                                        props.original.toPhone,
                                        "National" as NumberFormat
                                    )}
                                </span>
                            </div>
                        ),
                        filterable: true,
                        style: { whiteSpace: "unset" }
                    },
                    {
                        Header: "Recipient Name",
                        accessor: "toName",
                        id: "toName"
                    },
                    {
                        Header: "Status",
                        accessor: "status",
                        id: "status"
                    },
                    {
                        Header: "Code",
                        accessor: "code",
                        id: "code"
                    },
                    {
                        id: "activationDate",
                        Header: "Activated At",
                        accessor: "activationDate",
                        Cell: (props) => {
                            return props.value ? (
                                <span>
                                    {moment(props.value).format(
                                        "MMM Do YY - HH:mm"
                                    )}
                                </span>
                            ) : (
                                <span>{NOT_ACTIVATED}</span>
                            );
                        }
                    },
                    {
                        Header: "Remaining Credit",
                        id: "remainingCredit",
                        accessor: "remainingCreditCents",
                        Cell: (props) => (
                            <span>
                                {Helpers.toDollar(props.value / CENTS_CEIL)}
                            </span>
                        )
                    },
                    {
                        Header: "Total Credit",
                        id: "maxCredit",
                        accessor: "maxCreditCents",
                        Cell: (props) => (
                            <span>
                                {Helpers.toDollar(props.value / CENTS_CEIL)}
                            </span>
                        )
                    },
                    {
                        id: "actions",
                        Header: "Actions",
                        minWidth: 150,
                        Cell: (props) => {
                            return (
                                <div
                                    style={{
                                        flexDirection: "row",
                                        display: "flex",
                                        flexWrap: "wrap"
                                    }}
                                >
                                    {props.original.purchaseId != null ? (
                                        <Link
                                            style={{ display: "inline-block" }}
                                            to={`/purchases/${props.original.purchaseId}`}
                                            target="_blank"
                                        >
                                            <Button
                                                label={`See purchase: ${
                                                    props.original.type ===
                                                    GiftCardDiscriminator.PHYSICAL_GIFT_CARD
                                                        ? "PGC"
                                                        : "DGC"
                                                }`}
                                            />
                                        </Link>
                                    ) : (
                                        <Button
                                            onPress={() =>
                                                handleRefund(props.original.id)
                                            }
                                            backgroundColor={Colors.blue}
                                            label={getLabel(
                                                props.original.status,
                                                props.original
                                                    .remainingCreditCents,
                                                props.original.maxCreditCents
                                            )}
                                            disabled={
                                                props.original.status ===
                                                    "REFUNDED" ||
                                                props.original
                                                    .remainingCreditCents <
                                                    props.original
                                                        .maxCreditCents
                                            }
                                            type={"button"}
                                        />
                                    )}
                                </div>
                            );
                        }
                    },
                    {
                        id: "createdAt",
                        Header: "Created At",
                        accessor: "createdAt",
                        Cell: (props) => (
                            <span>
                                {moment(props.value).format(
                                    "MMM Do YY - HH:mm"
                                )}
                            </span>
                        )
                    },
                    {
                        id: "updatedAt",
                        Header: "Updated At",
                        accessor: "updatedAt",
                        Cell: (props) => (
                            <span>
                                {moment(props.value).format(
                                    "MMM Do YY - HH:mm"
                                )}
                            </span>
                        )
                    }
                ]}
            />
        </div>
    );
};

export default GiftCardsScreen;

const styles = {
    textGCFeatures: {
        fontWeight: 500,
        textAlign: "center"
    } as CSSProperties,
    colStyles: {
        marginRight: "1%"
    } as CSSProperties
};
