import React, { Component } from "react";
import Iframe from "react-iframe";

class Sales extends Component {
    state = {};
    render() {
        return (
            <div style={{ flex: 1 }}>
                <Iframe
                    url="https://airtable.com/embed/shr7H52KEAxd001as?backgroundColor=tealLightA"
                    width="100%"
                    height="100%"
                    className="airtable-embed"
                    allowFullScreen
                    style={{
                        background: "transparent",
                        border: "1px solid #ccc",
                    }}
                    onmousewheel=""
                />
            </div>
        );
    }
}

export default Sales;
