import _ from "lodash";
import { withFormik } from "formik";
import swal from "sweetalert2";
import { FormBody } from "./FormBody";
import { validationSchema, initializeFields } from "./config";
import {
    IStore,
    IDeliveryRange,
    FeePolicy,
    IHoursSchema
} from "@snackpass/snackpass-types";
import { FormValues } from "./types";
import API from "../../api/index";
import { setStore } from "../../redux/stores";

interface MyFormProps {
    buttonLabel?: string;
    storeObj?: IStore;
    editMode: boolean;
    setStore: Function;
    addStore: Function;
    removeStore: Function;
    onSuccess?: (store: IStore) => void;
    dispatch: Function;
}

function stringWithCommasToNumberList(str: string | null): number[] {
    if (!str || !_.isString(str)) return [];
    let numbers = [];
    for (let elem of str.split(",")) {
        let number = elem.trim();
        if (number && !isNaN(parseInt(number))) {
            numbers.push(parseInt(number));
        }
    }
    return numbers;
}

// update type of range
function wrapHours(hours: IHoursSchema | null) {
    hours?.local.forEach((range: any, idx: number) => {
        if (range.end < range.start) {
            let end = range.end;
            hours.local[idx].end = 60 * 24 * 7 - 1;
            hours.local.push({
                start: 0,
                end: end
            });
        }
    });

    return hours;
}

/**
 * Return the default pickup time for form submission.
 *
 * @param {FormValues} submitValues
 * @param {IStore?} storeObj
 */
function getDefaultPickupTime(
    submitValues: FormValues,
    storeAutoAcceptsOrders: boolean,
    storeObj?: IStore
): number {
    if (storeAutoAcceptsOrders && submitValues.pickupTimeType === "specific") {
        return submitValues.defaultPickupTime || 0;
    }

    // If the pickupTimeType is not "specific", then preserve whatever the original value was.
    return (storeObj && storeObj.defaultPickupTime) || 0;
}

/**
 * Return the default pickup min time for form submission.
 *
 * @param {FormValues} submitValues
 * @param {IStore?} storeObj
 */
function getDefaultPickupMinTime(
    submitValues: FormValues,
    storeAutoAcceptsOrders: boolean,
    storeObj?: IStore
): number {
    if (storeAutoAcceptsOrders && submitValues.pickupTimeType === "range") {
        return submitValues.defaultPickupMinTime || 0;
    }

    // If the pickupTimeType is not "range", then preserve whatever the original value was.
    return (storeObj && storeObj.defaultPickupMinTime) || 0;
}

/**
 * Return the default pickup max time for form submission.
 *
 * @param {FormValues} submitValues
 * @param {IStore?} storeObj
 */
function getDefaultPickupMaxTime(
    submitValues: FormValues,
    storeAutoAcceptsOrders: boolean,
    storeObj?: IStore
): number {
    if (storeAutoAcceptsOrders && submitValues.pickupTimeType === "range") {
        return submitValues.defaultPickupMaxTime || 0;
    }

    // If the pickupTimeType is not "range", then preserve whatever the original value was.
    return (storeObj && storeObj.defaultPickupMaxTime) || 0;
}

/**
 * Return whether the user has confirmed that the tablet can be updated. Confirmation is collected
 * with a modal.
 *
 * @param {Store} store
 * @param {any} submitValues
 * @return {boolean}
 */
async function confirmTabletUpdate(store: IStore, submitValues: any) {
    let primaryTablet = null;
    const response = await API.stores.getTablets(store._id);
    const tablets = response.data.tablets;

    if (tablets && store.primaryStoreTablet) {
        primaryTablet = tablets.find(
            (tablet: any) => tablet._id === store.primaryStoreTablet
        );
    }

    // If there was previously no primary tablet or the primary tablet serial hasn't changed,
    // don't require confirmation.
    if (!primaryTablet || primaryTablet.serial === submitValues.tabletSerial) {
        return true;
    }

    if (store.name === "Hogwarts" || submitValues.tabletSerial === "POSDEBUG") {
        await swal.fire({
            title: "Cannot change POSDEBUG store tablet right now :(",
            text: "Sorry, but we have locked Hogwarts to POSDEBUG for now. Please contact Sean W. if you need to change this for some reason"
        });
        return false;
    }

    const storeName = store.name;
    const alertMessage =
        `You should only change the tablet of ${storeName} if you're sure that they're no longer using this tablet. ` +
        `If ${storeName} is still using their old tablet and you accidentally remove it here, ` +
        `then the tablet will no longer work for ${storeName}.\n\n If you stil want to proceed, please type in the name of the store you would ` +
        `like to remove the tablet from:`;
    const { value: storeNameInput } = await swal.fire({
        title: `Change tablet of ${storeName}`,
        text: alertMessage,
        input: "text",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: `Change tablet for ${storeName}`,
        cancelButtonText: `Never mind`,
        inputValidator: (value) => {
            return new Promise((resolve) => {
                if (value !== storeName) {
                    resolve("Doesn't match");
                }

                resolve(null);
            });
        }
    });

    const confirmed = storeNameInput === storeName;

    // Only if the tablet serial has cleared and the user has confirmed the tablet serial update
    // should be set unlinkPrimaryTablet to true.
    if (confirmed && !submitValues.tabletSerial) {
        submitValues.unlinkPrimaryTablet = true;
    }

    return confirmed;
}

function logIfStoreHoursModified(params: {
    oldStore: IStore;
    newStore: IStore;
}) {
    if (!params.newStore.isLive) {
        return;
    }

    let oldHours = _.get(params.oldStore, "hours");
    let newHours = _.get(params.newStore, "hours");
    if (_.isEqual(oldHours, newHours)) {
        return;
    }
    let message = `Store hours 🕘 modified to:\n${_.get(
        params.newStore,
        "hoursDescription",
        "N/A"
    )}`;
    API.logs.send({
        channel: "logs-ops",
        title: `🔔 ${params.newStore.name} in ${params.newStore.region}`,
        description: message
    });
}

//maybe put StoreBuilder/index.tsx functions here instead?
const StoreForm = withFormik<MyFormProps, FormValues>({
    enableReinitialize: true,
    mapPropsToValues: (props) =>
        // transform store to store type
        // fix initializeFields function...
        ({
            ...initializeFields(props.storeObj),
            editMode: props.editMode
        }),
    validationSchema,
    handleSubmit: async (values, FormikBag) => {
        let submitValues = { ...values };
        const storeAutoAcceptsOrders =
            values.posAutoAcceptsOrders ||
            values.serverAutoAcceptsOrders ||
            false;

        // If not editing, set isLive to false by default
        if (!FormikBag.props.editMode && !FormikBag.props.storeObj) {
            submitValues.isLive = false;
        }

        // Make sure pickupMin is null if it isn't set
        // (if you delete it, it sets to "" so need to make sure it is null)
        if (!values.pickupMin) {
            submitValues.pickupMin = null;
        }

        // Set to null if empty string
        if (!values.phoneNumber) {
            submitValues.phoneNumber = null;
        }

        /** Transform and clean up fields here */

        // cleaning up deliveryMin based on json info of StoreBuilder line 293
        if (
            submitValues.delivery &&
            submitValues.deliveryRanges &&
            submitValues.deliveryRanges.length
        ) {
            submitValues.deliveryMin =
                submitValues.deliveryRanges[0].deliveryMin;
        }
        // cleaning up deliveryFee
        if (
            submitValues.delivery &&
            submitValues.deliveryRanges &&
            submitValues.deliveryRanges.length
        ) {
            submitValues.deliveryFee =
                submitValues.deliveryRanges[0].deliveryFee;

            //Clean up any "" in number fields
            submitValues.deliveryRanges = _.map(
                submitValues.deliveryRanges,
                (range: IDeliveryRange) => {
                    return {
                        ...range,
                        deliveryFee: range.deliveryFee || 0,
                        deliveryMin: range.deliveryMin || 0,
                        start: range.start || 0,
                        end: range.end || 0,
                        feePolicies: _.map(
                            range.feePolicies,
                            (policy: FeePolicy) => ({
                                ...policy,
                                flat: policy.flat || 0,
                                percent: policy.percent || 0
                            })
                        )
                    };
                }
            );
        }
        // cleaning up specialDeliveryHours
        submitValues.specialDeliveryHours = submitValues.hasSpecialDeliveryHours
            ? wrapHours(submitValues.specialDeliveryHours)
            : null;

        //cleaning up convenienceFee
        if (!submitValues.hasConvenienceFee) {
            submitValues.convenienceFee = 0;
        }

        //cleaning up defaultPickupTime
        submitValues.defaultPickupTime = getDefaultPickupTime(
            submitValues,
            storeAutoAcceptsOrders,
            FormikBag.props.storeObj
        );

        //cleaning up defaultPickupMinTime
        submitValues.defaultPickupMinTime = getDefaultPickupMinTime(
            submitValues,
            storeAutoAcceptsOrders,
            FormikBag.props.storeObj
        );

        //cleaning up defaultPickupMaxTime
        submitValues.defaultPickupMaxTime = getDefaultPickupMaxTime(
            submitValues,
            storeAutoAcceptsOrders,
            FormikBag.props.storeObj
        );

        submitValues.customPickUpTimes = stringWithCommasToNumberList(
            submitValues.customPickUpTimes as any
        ).map((number) => number.toString());

        submitValues.customDeliveryTimes = stringWithCommasToNumberList(
            submitValues.customDeliveryTimes as any
        ) as any;

        if (
            submitValues.posIntegrations &&
            !submitValues.posIntegrations.deliverect.enabled
        ) {
            // When disabling integration also delete locationId to avoid conflicts with other stores
            submitValues.posIntegrations.deliverect = {
                apiKey: "",
                enabled: false
            };
        }

        if (FormikBag.props.editMode && !FormikBag.props.storeObj) {
            // Attempt to update/create
            alert("Store does not exist!");
            return;
        }

        // If the tablet serial has changed, warn the user that updating the tablet serial may
        // cause problems.
        if (
            FormikBag.props.editMode &&
            FormikBag.props.storeObj &&
            FormikBag.props.storeObj.primaryStoreTablet
        ) {
            const confirmed = await confirmTabletUpdate(
                FormikBag.props.storeObj,
                submitValues
            );
            if (!confirmed) {
                return;
            }
        }

        // We omit _id from the submitted form values. This will prevent us
        // from having the server try to save a passed _id for a store during
        // update (which should be fine) or create (which would break because
        // _id would be "" in that case).
        // Additionally, any other fields that are not actual Store schema
        // fields or should not be updated are removed.
        const fieldsToRemove: (keyof FormValues)[] = [
            "_id",
            "autoAcceptsOrders",
            "storeBuilderSectionExpanded",
            "pickupOptionsSectionExpanded",
            "tipOptionsExpanded",
            "dineInOptionsSectionExpanded",
            "deliveryOptionsSectionExpanded",
            "commissionSectionExpanded",
            "creditCardPolicySectionExpanded",
            "integrationsSectionExpanded",
            "feeSectionExpanded",
            "tabletOptionsSectionExpanded",
            "autoAcceptOrdersSectionExpanded",
            "posTogglesExpanded",
            "upsellSectionExpanded",
            "kioskPreferencesSectionExpanded",
            "recurringPaymentsSectionExpanded"
        ];

        // Submit the form
        try {
            const filteredSubmitValues = _.omit(submitValues, fieldsToRemove);
            let response;

            // in edit mode
            if (FormikBag.props.editMode && FormikBag.props.storeObj) {
                response = await API.stores.update(
                    FormikBag.props.storeObj._id,
                    filteredSubmitValues
                );
                logIfStoreHoursModified({
                    oldStore: FormikBag.props.storeObj,
                    newStore: response.data.store
                });
            } else {
                // in create mode
                response = await API.stores.create(filteredSubmitValues);
            }

            let store = response.data.store;

            let responseName = "created";
            if (FormikBag.props.editMode) {
                responseName = "updated";
            }

            alert(`Store successfully ${responseName} ✅`);
            FormikBag.setSubmitting(false);

            if (FormikBag.props.editMode) {
                FormikBag.props.setStore(store);
            } else {
                FormikBag.props.addStore(store);
            }
            FormikBag.props.onSuccess && FormikBag.props.onSuccess(store);
            FormikBag.props.dispatch(setStore(store));
        } catch (err) {
            alert(
                "Error submitting store ❌ " +
                    JSON.stringify(err.response.data.message)
            );
            FormikBag.setSubmitting(false);
        }
    },
    displayName: "StoreForm"
})(FormBody);

export default StoreForm;
