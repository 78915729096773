import { ITablet as IStoreTablet } from "@snackpass/snackpass-types";
import { Dispatch } from "redux";
import api from "../api";
import * as Sentry from "@sentry/browser";
import { setStore } from "./stores";

// Constants
export const SET_STORE_TABLETS = "SET_STORE_TABLETS";
export const REMOVE_STORE_TABLET = "REMOVE_STORE_TABLET";
export const UPDATE_STORE_TABLET = "UPDATE_STORE_TABLET";

// Actions
export interface SetStoreTablets {
    type: "SET_STORE_TABLETS";
    storeTablets: IStoreTablet[];
}
export function setStoreTablets(storeTablets: IStoreTablet[]) {
    return { type: SET_STORE_TABLETS, storeTablets };
}
export interface UpdateStoreTablet {
    type: "UPDATE_STORE_TABLET";
    storeTablet: IStoreTablet;
}
export function updateStoreTablet(storeTablet: IStoreTablet) {
    return { type: UPDATE_STORE_TABLET, storeTablet };
}
export interface RemoveStoreTablet {
    type: "REMOVE_STORE_TABLET";
    storeTabletId: string;
}
export function removeStoreTablet(storeTabletId: string) {
    return { type: REMOVE_STORE_TABLET, storeTabletId };
}

export const setTabletSilentMode =
    (tabletId: string, silentMode: boolean) => (dispatch: Dispatch) =>
        api.storeTablets
            .setSilentMode(tabletId, silentMode)
            .then(res => dispatch(updateStoreTablet(res.data.storeTablet)))
            .catch(console.error);

export function setPrimaryStoreTablet(storeTabletId: string) {
    return (dispatch: Dispatch) => {
        return api.storeTablets
            .setPrimary(storeTabletId)
            .then(response => {
                dispatch(updateStoreTablet(response.data.storeTablet));
                dispatch(setStore(response.data.store));
            })
            .catch(err => {
                Sentry.captureException(err);
            });
    };
}

export function updateJAMFStoreTablet(storeTabletId: string) {
    return (dispatch: Dispatch) => {
        return api.storeTablets
            .updateJAMF(storeTabletId)
            .then(response => {
                dispatch(updateStoreTablet(response.data.storeTablet));
            })
            .catch(err => {
                Sentry.captureException(err);
            });
    };
}

export type StoreTabletAction =
    | SetStoreTablets
    | UpdateStoreTablet
    | RemoveStoreTablet;

// Reducer
const initialState: IStoreTablet[] = [];

export function storeTablets(
    state: IStoreTablet[] = initialState,
    action: StoreTabletAction
) {
    switch (action.type) {
        case "SET_STORE_TABLETS":
            return action.storeTablets;
        case "UPDATE_STORE_TABLET":
            return state.map(storeTablet => {
                if (storeTablet._id === action.storeTablet._id) {
                    return action.storeTablet;
                }
                return storeTablet;
            });
        case "REMOVE_STORE_TABLET":
            return state.filter(storeTablet => {
                if (storeTablet._id === action.storeTabletId) {
                    return false;
                }
                return true;
            });
        default:
            return state;
    }
}
