import { StoreState, ObjectId } from "./types";
import { IStore } from "@snackpass/snackpass-types";
import { Dispatch, Action } from "redux";
import api from "../api";
import * as Sentry from "@sentry/browser";
import { setAuthorized } from "./authorized";

// Constants
export const SET_ARCHIVED_STORES = "SET_ARCHIVED_STORES";
export const UPDATE_ARCHIVED_STORE = "UPDATE_ARCHIVED_STORE";
export const REMOVE_ARCHIVED_STORE = "REMOVE_STORE";
export const ADD_ARCHIVED_STORE = "ADD_ARCHIVED_STORE";

// Actions
export interface ISetArchivedStores extends Action {
    type: "SET_ARCHIVED_STORES";
    stores: IStore[];
}
export function setArchivedStores(stores: IStore[]) {
    return { type: SET_ARCHIVED_STORES, stores };
}

export interface ISetArchivedStore {
    type: "UPDATE_ARCHIVED_STORE";
    store: IStore;
}
export function updateArchivedStore(store: IStore) {
    return { type: "UPDATE_ARCHIVED_STORE", store };
}

export interface IAddArchivedStore {
    type: "ADD_ARCHIVED_STORE";
    store: IStore;
}
export function addArchivedStore(store: IStore) {
    return { type: "ADD_ARCHIVED_STORE", store };
}

export interface IRemoveArchivedStore {
    type: "REMOVE_ARCHIVED_STORE";
    storeId: ObjectId;
}
export function removeArchivedStore(storeId: string) {
    return { type: "REMOVE_ARCHIVED_STORE", storeId };
}

export function fetchArchivedStores(query?: any) {
    return (dispatch: Dispatch) => {
        return api.stores
            .get({ ...query, isArchived: true })
            .then(response => {
                dispatch(setArchivedStores(response.data.stores));
            })
            .catch(err => {
                Sentry.captureException(err);
                dispatch(setAuthorized(false));
                dispatch(setArchivedStores([]));
            });
    };
}

export type StoreAction =
    | ISetArchivedStore
    | ISetArchivedStores
    | IAddArchivedStore
    | IRemoveArchivedStore;

// Reducer
const initialState: IStore[] = [];

export function archivedStores(
    state: IStore[] = initialState,
    action: StoreAction
) {
    switch (action.type) {
        case "SET_ARCHIVED_STORES":
            return action.stores;
        case "UPDATE_ARCHIVED_STORE": {
            return state.map(store => {
                if (store._id === action.store._id) {
                    return action.store;
                }
                return store;
            });
        }
        case "REMOVE_ARCHIVED_STORE":
            return state.filter(store => {
                if (store._id === action.storeId) {
                    return false;
                }
                return true;
            });
        case "ADD_ARCHIVED_STORE": {
            let currentStores: IStore[] = state.slice();
            currentStores.push(action.store);
            return currentStores;
        }
        default:
            return state;
    }
}

// selectors
export const getArchivedStores = (state: StoreState) => state.archivedStores;
