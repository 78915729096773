import React, { CSSProperties, useState } from "react";
import { Colors } from "../../../utils";
import { Select, MenuItem, InputLabel, TextField } from "@material-ui/core";
import { Field, FieldProps } from "formik";
import { Input } from "./Input";
import { Helpers } from "../../../utils";
import { FeePolicyPayer, FeePolicyRecipient } from "@snackpass/snackpass-types";
import { Text } from "../../../SharedComponents";
import _ from "lodash";
import { FeePolicyType, ONLY_FLAT_FEE_POLICIES } from "../config";

type FeePolicyProps = {
    policySelectOptions: string[];
    path?: string;
    index: number;
    policyTypes: FeePolicyType[];
    showTaxable: boolean;
    hasPercentDefault?: boolean;
    onPolicyRemove: () => void;
};

const FeePolicy = ({
    policySelectOptions,
    path = "feePolicies",
    index,
    showTaxable,
    policyTypes,
    hasPercentDefault,
    onPolicyRemove,
}: FeePolicyProps) => {
    const [hasPercent, enablePercent] = useState(hasPercentDefault);
    const parsedPath = `${path}.${index}`;

    const updatePayerRecipient = (
        fieldProps: FieldProps,
        payer: FeePolicyPayer,
        recipient: FeePolicyRecipient
    ) => {
        fieldProps.form.setFieldValue(`${parsedPath}.payer`, payer);
        fieldProps.form.setFieldValue(`${parsedPath}.recipient`, recipient);
    };

    const buildMenuItems = (menuItems: string[]) =>
        menuItems.map((item: string, n: number) => (
            <MenuItem value={item} key={n}>
                {item}
            </MenuItem>
        ));

    const onPolicyTypeChange = (event: any, fieldProps: FieldProps) => {
        const newValue = event.target.value as string;
        const type = _.find(
            policyTypes,
            (type: FeePolicyType) => type.value === newValue
        );
        if (type) {
            updatePayerRecipient(fieldProps, type.payer, type.recipient);
            enablePercent(!ONLY_FLAT_FEE_POLICIES.includes(type.value));
        }
        //Continue calling Formik onChange handler
        fieldProps.field.onChange(event);
    };

    const PolicySelectField = () => (
        <>
            <InputLabel>Type</InputLabel>
            <Field name={`${parsedPath}.name`}>
                {(fieldProps: FieldProps) => (
                    <Select
                        style={styles.policySelect}
                        {...fieldProps.field}
                        onChange={e => {
                            onPolicyTypeChange(e, fieldProps);
                        }}
                    >
                        {buildMenuItems(policySelectOptions)}
                    </Select>
                )}
            </Field>
        </>
    );

    const PolicyPayerRecipientField = (label: "payer" | "recipient") => (
        <Field name={`${parsedPath}.${label}`}>
            {(fieldProps: FieldProps) => (
                <TextField
                    style={styles.policyPayerRecipientField}
                    label={Helpers.capitalize(label)}
                    disabled
                    {...fieldProps.field}
                />
            )}
        </Field>
    );

    return (
        <div style={styles.rowContainer} key={index}>
            <div style={styles.innerRowContainer}>
                <div style={styles.innerRowLeft}>
                    {PolicySelectField()}
                    <div style={styles.policyRecipientPayerContainer}>
                        {PolicyPayerRecipientField("payer")}
                        <Text small color="#b5b5b5">
                            pays
                        </Text>
                        {PolicyPayerRecipientField("recipient")}
                    </div>
                </div>
            </div>
            <div style={styles.policyDetails}>
                <Input
                    name={`${parsedPath}.flat`}
                    label="Flat Fee"
                    type="number"
                />
                {hasPercent && (
                    <Input
                        name={`${parsedPath}.percent`}
                        label="Percent Fee"
                        type="number"
                    />
                )}

                {showTaxable && (
                    <Input
                        name={`${parsedPath}.isTaxable`}
                        label="Taxable"
                        description="Should this fee be taxed?"
                        type="toggle"
                    />
                )}
            </div>
            <button
                type="button"
                style={styles.removeButton}
                onClick={onPolicyRemove}
            >
                remove
            </button>
        </div>
    );
};

const styles = {
    tiersContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        marginTop: 8,
    } as CSSProperties,
    rowContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px",
        width: "95%",
        margin: "auto",
        marginTop: 8,
        border: "solid 1.5px",
        borderColor: Colors.blue,
        borderRadius: 6,
    },
    innerRowContainer: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
    },
    innerRowLeft: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginRight: 20,
    } as CSSProperties,
    policySelect: {
        minWidth: 250,
    },
    policyPayerRecipientField: {
        maxWidth: 80,
    },
    policyDetails: { flex: 1, marginRight: 10 },
    policyRecipientPayerContainer: {
        marginTop: 15,
        display: "flex",
        width: "100%",
        maxWidth: 220,
        justifyContent: "space-between",
        alignItems: "center",
    },
    removeButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Colors.red,
        color: Colors.offWhite,
        padding: "4px 8px",
        marginLeft: 10,
    },
};

export default FeePolicy;
