import { createSelector } from "reselect";
import { StoreState, ObjectId } from "./types";
import { IPromotion, IStore, IProduct } from "@snackpass/snackpass-types";
import { Dispatch, Action } from "redux";
import api from "../api";
import * as Sentry from "@sentry/browser";
import _ from "lodash";
import { getPromotions } from "./promotions";
import { setFetchingStores } from "./networkRequests";
import { setAuthorized } from "./authorized";

// Constants
export const SET_STORE = "SET_STORE";
export const SET_STORES = "SET_STORES";
export const SET_PROMOTIONS_FOR_STORE = "SET_PROMOTIONS_FOR_STORE";
export const SET_PRODUCTS_FOR_STORE = "SET_PRODUCTS_FOR_STORE";
export const REMOVE_STORE = "REMOVE_STORE";
export const EDIT_STORES = "EDIT_STORES";
export const ADD_STORE = "ADD_STORE";

// Actions
export interface SetStores extends Action {
    type: "SET_STORES";
    stores: IStore[];
}
export function setStores(stores: IStore[]) {
    return { type: SET_STORES, stores };
}

export interface SetPromotionsForStore {
    type: "SET_PROMOTIONS_FOR_STORE";
    storeId: ObjectId;
    promotions: IPromotion[];
}
export function setPromotionsForStore(
    promotions: IPromotion[],
    storeId: string
) {
    return { type: SET_PROMOTIONS_FOR_STORE, promotions, storeId };
}

export interface SetProductsForStore {
    type: "SET_PRODUCTS_FOR_STORE";
    storeId: ObjectId;
    products: IProduct[];
}
export function setProductsForStore(products: IProduct[], storeId: string) {
    return { type: SET_PRODUCTS_FOR_STORE, products, storeId };
}

export interface SetStore {
    type: "SET_STORE";
    store: IStore;
}
export function setStore(store: IStore) {
    return { type: SET_STORE, store };
}

export interface IAddStore {
    type: "ADD_STORE";
    store: IStore;
}
export function addStore(store: IStore) {
    return { type: ADD_STORE, store };
}

export interface IRemoveStore {
    type: "REMOVE_STORE";
    storeId: ObjectId;
}
export function removeStore(storeId: string) {
    return { type: REMOVE_STORE, storeId };
}

export interface IEditStores {
    type: "EDIT_STORES";
    store: IStore;
}
export function editStores(store: IStore) {
    return { type: EDIT_STORES, store };
}

export function fetchStores(query?: any) {
    return (dispatch: Dispatch) => {
        dispatch(setFetchingStores(true));
        return api.stores
            .get(query)
            .then(response => {
                dispatch(setStores(response.data.stores));
                dispatch(setFetchingStores(false));
            })
            .catch(err => {
                Sentry.captureException(err);
                dispatch(setAuthorized(false));
                dispatch(setStores([]));
                dispatch(setFetchingStores(false));
            });
    };
}

export function fetchStoreProducts(storeId: string) {
    return (dispatch: Dispatch) => {
        return api.stores
            .getProducts(storeId)
            .then(response => {
                dispatch(setProductsForStore(response.data.products, storeId));
            })
            .catch(err => {
                Sentry.captureException(err);
                dispatch(setProductsForStore([], storeId));
            });
    };
}

export type StoreAction =
    | SetStores
    | SetPromotionsForStore
    | SetProductsForStore
    | SetStore
    | IAddStore
    | IRemoveStore
    | IEditStores;

// Reducer
const initialState: IStore[] = [];

export function stores(state: IStore[] = initialState, action: StoreAction) {
    switch (action.type) {
        case SET_STORES:
            return action.stores;
        case SET_PROMOTIONS_FOR_STORE:
            return state.map(store => {
                if (store._id === action.storeId) {
                    return { ...store, promotions: action.promotions };
                }
                return store;
            });
        case SET_PRODUCTS_FOR_STORE:
            return state.map(store => {
                if (store._id === action.storeId) {
                    return { ...store, products: action.products };
                }
                return store;
            });
        case SET_STORE: {
            return state.map(store => {
                if (store._id === action.store._id) {
                    return action.store;
                }
                return store;
            });
        }
        case REMOVE_STORE:
            return state.filter(store => {
                if (store._id === action.storeId) {
                    return false;
                }
                return true;
            });
        case ADD_STORE: {
            let currentStores: IStore[] = state.slice();
            currentStores.push(action.store);
            return currentStores;
        }
        default:
            return state;
    }
}

// selectors
export const getStores = (state: StoreState) => state.stores;
export const getActiveStoreId = (state: StoreState) => state.activeStore;

export const getStoresWithPromotions = createSelector(
    [getStores, getPromotions],
    (stores, promotions) => {
        let promotionsByStore: any = {};
        promotions.forEach(p => {
            let storeId = _.get(p, "store._id", "");
            if (!promotionsByStore[storeId]) {
                promotionsByStore[storeId] = [];
            }
            promotionsByStore[storeId].push(p);
        });
        return stores.map(s => {
            return { ...s, promotions: promotionsByStore[s._id] };
        });
    }
);

export interface StoreSelectOption {
    value: string;
    label: string;
    storeObj: IStore;
}

export const getStoreSelectOptions = createSelector(getStores, stores => {
    return stores.map(store => {
        return {
            value: store._id,
            label: `${store.name} - ${store.region}`,
            storeObj: { ...store },
        };
    });
});

export const getActiveStore = createSelector(
    [getStores, getActiveStoreId],
    (stores: IStore[], activeStoreId: string): IStore | null => {
        return stores.filter(store => {
            return store._id === activeStoreId;
        })[0];
    }
);

export const getStorePrimaryTablets = createSelector(getStores, stores => {
    let storeToPrimaryTabletMapping: any = {};
    stores.forEach((store: IStore) => {
        storeToPrimaryTabletMapping[store._id] = _.get(
            store,
            "primaryStoreTablet",
            null
        );
    });
    return storeToPrimaryTabletMapping;
});

export const getChainStores = createSelector(
    [getStores, (state: any, chainId: string) => chainId],
    (stores, chainId) => {
        if (!chainId) {
            return [];
        }

        return stores.filter(store => store.chainId === chainId);
    }
);

export const getChainIdToStores = createSelector([getStores], stores => {
    return _.groupBy(stores, "chainId");
});

//selectors (get the state itself from redux state)
export const getFetchingStores = (state: StoreState) =>
    state.networkRequests.fetchingStores;
