import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { IRegion } from "@snackpass/snackpass-types";
import { SalesTaxRemitter } from "@snackpass/snackpass-types";

import _ from "lodash";
import API from "../../api";
import { AxiosResponse } from "axios";
import { MySelect } from "src/SharedComponents/MySelect";
import { Checkbox } from "react-formik-ui";
import { US_TIME_ZONES, US_STATES } from "../../utils/Constants";
import { validationSchema } from "./validation";
import { Colors } from "../../utils/Colors";

const US_TIME_ZONE_OPTIONS = US_TIME_ZONES.map((val) => {
    return { label: val, value: val };
});

export const TAX_REMITTER_OPTIONS: {
    label: string;
    value: SalesTaxRemitter;
}[] = [
    { label: "Store", value: "store" },
    { label: "Snackpass", value: "snackpass" }
];

const styles = {
    errorMessage: {
        color: Colors.red,
        fontSize: 12,
        margin: "1rem 0"
    },
    infoMessage: {
        color: Colors.gray,
        fontSize: 12,
        margin: "1rem 0"
    },
    archiveRegionTitle: {
        display: "inline",
        paddingLeft: "10px"
    },
    regionFormContainer: {
        margin: "1rem 0"
    }
};

type RegionFormProps = {
    regionObj: any;
    editMode: boolean;
    onSuccess: (regionObj: IRegion) => void;
};

const REGION_TEMPLATE = {
    name: undefined,
    slug: undefined,
    taxRate: undefined,
    tz: undefined,
    state: undefined,
    marketplaceRegion: true,
    geolocation: {
        type: "Point",
        coordinates: [undefined, undefined]
    },
    referralCredit: 0,
    remitter: "store",
    archived: undefined
};

const _initialValues = (regionObj: any) => {
    return regionObj
        ? _.pick(regionObj, _.merge(Object.keys(REGION_TEMPLATE)))
        : REGION_TEMPLATE;
};

const ArchiveButton = ({ region }: any) => {
    if (region?.numStores !== undefined) {
        const message =
            region.numStores >= 1
                ? "You can't archive this region because it's being used by one or more of our stores."
                : "This region can be archived because it is not being used by any of our stores";

        const style =
            region.numStores >= 1 ? styles.errorMessage : styles.infoMessage;

        const disabled = region.numStores >= 1 ? true : false;

        return (
            <div>
                <hr />
                <div>
                    <Field
                        type="checkbox"
                        name="archived"
                        disabled={disabled}
                    />
                    <p style={styles.archiveRegionTitle}>Archive Region</p>
                    <div style={style}>{message}</div>
                </div>
                <hr />
            </div>
        );
    } else {
        return null;
    }
};

const RegionForm = ({ regionObj, onSuccess, editMode }: RegionFormProps) => (
    <div>
        <Formik
            initialValues={_initialValues(regionObj)}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                regionObj = _.merge(regionObj, values);

                let response: Promise<AxiosResponse>;

                if (regionObj._id) {
                    response = API.regions.update(regionObj._id, regionObj);
                } else {
                    response = API.regions.create(regionObj);
                }

                response
                    .then((result) => {
                        console.log(result);
                        setSubmitting(false);
                        onSuccess(result.data);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }}
        >
            {({ isSubmitting, isValid }) => (
                <Form>
                    <div>Name</div>
                    <div style={styles.regionFormContainer}>
                        <Field type="text" name="name" />
                        <div style={styles.errorMessage}>
                            <ErrorMessage name="name" component="div" />
                        </div>
                    </div>
                    <hr />
                    Time Zone
                    <div>
                        <Field
                            name="tz"
                            component={MySelect}
                            options={US_TIME_ZONE_OPTIONS}
                            isMulti={false}
                        />

                        <div style={styles.errorMessage}>
                            <ErrorMessage name="tz" component="div" />
                        </div>
                    </div>
                    <hr />
                    <div>Slug</div>
                    <div style={styles.regionFormContainer}>
                        <Field disabled={editMode} type="text" name="slug" />
                        {editMode && (
                            <div style={styles.infoMessage}>
                                Editing region slugs has been temporarily
                                disabled.
                            </div>
                        )}
                        <div style={styles.errorMessage}>
                            <ErrorMessage name="slug" component="div" />
                        </div>
                    </div>
                    <hr />
                    <div style={styles.regionFormContainer}>State</div>
                    <div>
                        <Field
                            name="state"
                            component={MySelect}
                            options={Object.keys(US_STATES).map((val) => {
                                return { label: val, value: val };
                            })}
                            isMulti={false}
                        />
                        <div style={styles.errorMessage}>
                            <ErrorMessage name="state" component="div" />
                        </div>
                    </div>
                    <div style={styles.regionFormContainer}>Geolocation</div>
                    <div>
                        <div style={styles.regionFormContainer}>
                            {" "}
                            Latitude (should be positive)
                        </div>
                        <Field
                            name="geolocation.coordinates[1]"
                            type="text"
                            required={true}
                        />
                        <ErrorMessage name="latitude" component="div" />
                        <div style={styles.errorMessage}>
                            <ErrorMessage
                                name="geolocation.coordinates[1]"
                                component="div"
                            />
                        </div>
                    </div>
                    <div>
                        <div style={styles.regionFormContainer}>
                            {" "}
                            Longitude (should be negative)
                        </div>
                        <Field
                            name="geolocation.coordinates[0]"
                            type="text"
                            required={true}
                        />
                        <div style={styles.errorMessage}>
                            <ErrorMessage
                                name="geolocation.coordinates[0]"
                                component="div"
                            />
                        </div>
                    </div>
                    <div>MarketPlace Region</div>
                    <div>
                        <Checkbox name="marketplaceRegion" />
                    </div>
                    <hr />
                    <div>Tax Rate</div>
                    <div style={styles.regionFormContainer}>
                        <Field type="number" name="taxRate" step="any" />
                        <div style={styles.errorMessage}>
                            <ErrorMessage name="taxRate" component="div" />
                        </div>
                    </div>
                    <hr />
                    <div>Referral Credit</div>
                    <div style={styles.regionFormContainer}>
                        <Field type="number" name="referralCredit" step="any" />
                        <div style={styles.errorMessage}>
                            <ErrorMessage
                                name="referralCredit"
                                component="div"
                            />
                        </div>
                    </div>
                    <hr />
                    <div>Tax Remitter</div>
                    <div style={styles.regionFormContainer}>
                        <Field
                            name="remitter"
                            component={MySelect}
                            options={TAX_REMITTER_OPTIONS}
                            isMulti={false}
                        />
                        <div style={styles.errorMessage}>
                            <ErrorMessage name="remitter" component="div" />
                        </div>
                    </div>
                    <ArchiveButton region={regionObj} />
                    <button type="submit" disabled={isSubmitting || !isValid}>
                        Submit
                    </button>
                </Form>
            )}
        </Formik>
    </div>
);

export default RegionForm;
