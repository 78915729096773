import { IPurchase } from "@snackpass/snackpass-types";
import React from "react";

import { Text, View } from "../../SharedComponents";
import * as Helpers from "../../utils/Helpers";

type RewardPromoSectionProps = { label: string; name: string };

const RewardPromoSection = ({ label, name }: RewardPromoSectionProps) => (
    <View style={{ flexDirection: "column" }}>
        <Text style={{ marginLeft: 10, color: "darkgreen" }} small>
            {`${label} applied: ${name}`}
        </Text>
    </View>
);

type Props = { purchase: IPurchase };

const Items = ({ purchase }: Props) => (
    <View>
        <Text>Items</Text>
        <Text small>{purchase.notes}</Text>
        {purchase.items.map((item, index) => {
            let previousAddonGroupName: string | null = null;

            return (
                <Text key={`${purchase._id}/${index}`} small>
                    {/* Item Name and Price */}
                    {`${item.product?.name} for ${Helpers.toDollar(
                        item.basePriceAfterDiscount
                    )}`}

                    {/* Item Promotion */}
                    {item.promotion && (
                        <RewardPromoSection
                            label="Promo"
                            name={item.promotion.name}
                        />
                    )}

                    {/* Item Reward */}
                    {item.reward && (
                        <RewardPromoSection
                            label="Reward"
                            name={item.reward.name}
                        />
                    )}

                    {/* Item Notes */}
                    {item.notes && (
                        <View style={{ flexDirection: "column" }}>
                            <Text style={{ marginLeft: 20 }} small>
                                Notes: {item.notes}
                            </Text>
                        </View>
                    )}

                    {/* Addons and Subcategories */}
                    {item.selectedAddons.map((addon, addonIdx) => {
                        const showCategory = !(
                            previousAddonGroupName === addon.addonGroup.name
                        );
                        previousAddonGroupName = addon.addonGroup.name;
                        const price = addon.priceAfterDiscount;

                        return (
                            <View
                                key={addonIdx}
                                style={{ flexDirection: "column" }}
                            >
                                {showCategory && (
                                    <Text style={{ marginLeft: 20 }} small>
                                        {addon.addonGroup.name}
                                    </Text>
                                )}
                                <Text style={{ marginLeft: 40 }} small>
                                    {`${addon.name} ${
                                        price
                                            ? `for ${Helpers.toDollar(price)}`
                                            : ""
                                    }`}
                                </Text>
                            </View>
                        );
                    })}
                </Text>
            );
        })}
    </View>
);

export default Items;
