import { connect } from "react-redux";
import {} from "../redux/activeUser";
import Punchcard from "../components/Punchcard";
import { Dispatch } from "redux";
import { StoreState } from "../redux/types";

const mapStateToProps = (state: StoreState) => {
    return {
        punchcards: state.activeUser.punchcards,
        user: state.activeUser.user,
    };
};

// create handler than dispatches an action
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        dispatch,
    };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(Punchcard);

export default Container;
