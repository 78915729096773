import { connect } from "react-redux";
import {
    setRegions,
    removeRegion,
    addRegion,
    setRegion,
} from "../redux/regions";
import RegionForm from "../components/RegionForm/RegionForm";
import { Dispatch } from "redux";
import { RegionState } from "../redux/types";
import { IRegion } from "@snackpass/snackpass-types";

const mapStateToProps = (state: RegionState) => {
    return {
        regions: state.regions,
    };
};

// create handler than dispatches an action
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setRegions: (regions: IRegion[]) => {
            dispatch(setRegions(regions));
        },
        removeRegion: (region: string) => {
            dispatch(removeRegion(region));
        },
        setRegion: (region: IRegion) => {
            dispatch(setRegion(region));
        },
        addRegion: (region: IRegion) => {
            dispatch(addRegion(region));
        },

        dispatch,
    };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(RegionForm);

export default Container;
