import { IReward, IUser } from "@snackpass/snackpass-types";

// Constants
export const SET_ACTIVE_USER = "SET_ACTIVE_USER";
export const SET_PUNCHCARDS = "SET_PUNCHCARDS";
export const SET_REWARDS = "SET_REWARDS";

// Actions
export interface SetActiveUser {
    type: "SET_ACTIVE_USER";
    activeUser: IUser;
}
export function setActiveUser(activeUser: IUser) {
    return { type: SET_ACTIVE_USER, activeUser };
}

export interface SetPunchcards {
    type: "SET_PUNCHCARDS";
    punchcards: any[];
}

export function setPunchcards(punchcards: any[]) {
    return { type: "SET_PUNCHCARDS", punchcards };
}

export interface SetRewards {
    type: "SET_REWARDS";
    rewards: any[];
}

export function setRewards(rewards: IReward[]) {
    return { type: "SET_REWARDS", rewards };
}

export type ActiveUserAction = SetActiveUser | SetPunchcards | SetRewards;

// Reducer
const initialState = {
    punchcards: [],
    user: {},
    rewards: []
};

export function activeUser(state = initialState, action: ActiveUserAction) {
    switch (action.type) {
        case "SET_ACTIVE_USER":
            return { ...state, user: action.activeUser };
        case "SET_PUNCHCARDS":
            return { ...state, punchcards: action.punchcards };
        case "SET_REWARDS":
            return { ...state, rewards: action.rewards };
        default:
            return state;
    }
}
