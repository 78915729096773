import { IPurchase } from "@snackpass/snackpass-types";
import { prop } from "lodash/fp";
import React from "react";

import { Text, View } from "../../SharedComponents";
import * as Helpers from "../../utils/Helpers";

const textHighlight = { color: "#0645AD " };

const CustomerName = ({ name, email }: { name: string; email?: string }) => (
    <a href={email ? `/users?email=${email}` : "#"} style={textHighlight}>
        {name}
    </a>
);

const CustomerEmail = ({ email }: { email?: string }) =>
    email ? (
        <a href={`mailto:${email}`} style={textHighlight}>
            {email}
        </a>
    ) : null;

type Props = { purchase: IPurchase };

const CustomerDetails = ({ purchase }: Props) => {
    const isKioskPurchase = purchase.transactionSource === "kiosk";
    const name = isKioskPurchase
        ? purchase.customerName
        : prop("user.name", purchase) || "Unknown";
    const email = prop("user.email", purchase) || undefined;
    const number = prop("user.number", purchase);
    const countryCode = prop("user.countryCode", purchase) || undefined;

    return (
        <View>
            <Text>Customer</Text>
            <CustomerName name={name} email={email} />
            <CustomerEmail email={email} />
            {Helpers._displayNumber(number, undefined, undefined, countryCode)}
        </View>
    );
};

export default CustomerDetails;
