import React, { Component } from "react";

import { Text, Toggle } from "../../SharedComponents";
import { Row, Col } from "../../SharedComponents";
import { ReduxUtil } from "../../utils/ReduxUtil";
import { IUser, IReward } from "@snackpass/snackpass-types";
import { Colors } from "../../utils";
import ReactTooltip from "react-tooltip";
import moment from "moment-timezone";

type Props = {
    dispatch: Function;
    reward: IReward;
    user: IUser;
};
type State = {};
export default class Reward extends Component<Props, State> {
    handleToggleRedeemed = () => {
        const { reward, user, dispatch } = this.props;
        let updates = { used: !reward.used };
        ReduxUtil.updateReward(user._id, reward._id, updates, dispatch);
        ReactTooltip.rebuild();
    };
    renderRewardStatus = () => {
        const { reward } = this.props;
        return (
            <span
                style={{
                    textAlign: "left",
                    color: Colors.gray,
                    display: "inline-block",
                }}
            >
                {reward.used ? "Reward is used" : "Reward is available"}
            </span>
        );
    };
    render() {
        const { reward } = this.props;

        const dateFormat = "MMM DD, YYYY - HH:mm:ss";
        const createdOn = moment(reward.createdAt).format(dateFormat);
        const expiration = reward.expirationDate
            ? `expires on ${moment(reward.expirationDate).format(dateFormat)}`
            : "";

        return (
            <Row
                style={{
                    backgroundColor: "rgb(245, 245, 245)",
                }}
            >
                <Col flex={1}>
                    <Text small>{reward.name}</Text>
                </Col>
                <Col flex={2} style={{ alignItems: "center" }}>
                    <Text small>{`created on ${createdOn}`}</Text>
                </Col>
                <Col flex={3} style={{ alignItems: "center" }}>
                    <Text small>{expiration}</Text>
                </Col>
                <Col style={{ width: 80 }}>
                    <div
                        key={reward.used ? "reward-available" : "reward-user"}
                        data-tip={`Toggling this will make the reward ${
                            reward.used ? "available" : "used"
                        }.`}
                        data-for={`reward-${reward._id}`}
                        style={{
                            display: "inline-block",
                            width: 80,
                        }}
                    >
                        <ReactTooltip
                            id={`reward-${reward._id}`}
                            place="top"
                            effect="solid"
                        />

                        <Toggle
                            onToggle={this.handleToggleRedeemed}
                            value={reward.used}
                            activeLabel={""}
                            inactiveLabel={""}
                        />
                    </div>
                </Col>
                <Col style={{ width: 150 }}>{this.renderRewardStatus()}</Col>
            </Row>
        );
    }
}
