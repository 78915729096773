import { connect } from "react-redux";
import { setActiveUser } from "../redux/activeUser";
import UserPreview from "../components/UserPreview";
import { StoreState, Dispatch } from "../redux/types";
import { IUser } from "@snackpass/snackpass-types";

const mapStateToProps = (state: StoreState) => {
    return {
        user: state.activeUser.user,
        regions: state.regions,
    };
};

// create handler than dispatches an action
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setActiveUser: (activeUser: IUser) => {
            dispatch(setActiveUser(activeUser));
        },
    };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(UserPreview);

export default Container;
