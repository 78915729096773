import React, { CSSProperties } from "react";
import "rc-time-picker/assets/index.css";
import { ArrayHelpers, FieldArray } from "formik";
import { FeePolicy, ReservedFeeEnum } from "@snackpass/snackpass-types";
import {
    FeePolicyType,
    FEE_POLICY_TYPES,
    ONLY_FLAT_FEE_POLICIES
} from "../config";
import { Text } from "../../../SharedComponents";
import { Colors } from "../../../utils";
import _ from "lodash/fp";
import FeePolicyField from "./FeePolicy";
import { FEE_POLICY_TEMPLATE } from "src/utils/Templates";

type Props = {
    policies: FeePolicy[];
    path?: string;
    template?: FeePolicy;
    showTaxable?: boolean;
    options?: {
        [name: string]: FeePolicyType;
    };
};

const FeePolicies = ({
    policies,
    path = "feePolicies",
    showTaxable = true,
    template = FEE_POLICY_TEMPLATE,
    options = FEE_POLICY_TYPES
}: Props) => {
    const selectOptions: string[] = _.keys(options);
    const policyTypes: FeePolicyType[] = _.values(options);

    return (
        <FieldArray
            name={path}
            render={(arrayHelpers: ArrayHelpers) => {
                return (
                    <div style={styles.tiersContainer}>
                        {policies.length ? (
                            policies.map((_, n) => (
                                <FeePolicyField
                                    policySelectOptions={selectOptions}
                                    policyTypes={policyTypes}
                                    path={path}
                                    index={n}
                                    showTaxable={showTaxable}
                                    onPolicyRemove={() =>
                                        arrayHelpers.remove(n)
                                    }
                                    key={n}
                                    hasPercentDefault={
                                        !ONLY_FLAT_FEE_POLICIES.includes(
                                            template.name as ReservedFeeEnum
                                        )
                                    }
                                />
                            ))
                        ) : (
                            <Text small center>
                                No fees set.
                                <br />
                                Click + to add a new fee policy
                            </Text>
                        )}
                        <button
                            style={styles.addButton}
                            type="button"
                            onClick={() => arrayHelpers.push(template)}
                        >
                            +
                        </button>
                    </div>
                );
            }}
        />
    );
};

const styles = {
    tiersContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        marginTop: 8
    } as CSSProperties,
    policySelect: {
        minWidth: 250
    },
    policyPayerRecipientField: {
        maxWidth: 80
    },
    addButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Colors.offWhite,
        color: Colors.text,
        height: 30,
        width: 30,
        margin: "10px 0"
    }
};

export default FeePolicies;
