import { IStore } from "@snackpass/snackpass-types";
import { compose, flow, map, noop, prop } from "lodash/fp";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";

import {
    setSelectedStoreId,
    setStoreSubscription,
} from "../../redux/storeSubscription";
import { View } from "../../SharedComponents";
import { getPathFromState } from "../../utils/Helpers";
import api from "../../api";

interface IOption {
    value: string;
    label: string;
}

const StorePicker = () => {
    const dispatch = useDispatch();
    const _onChange = (selectedOption: IOption) => {
        dispatch(setSelectedStoreId(selectedOption.value));
        api.subscriptions
            .get({ storeId: selectedOption.value })
            .then(
                flow(
                    prop("data.storeSubscriptionPlan"),
                    setStoreSubscription,
                    dispatch
                )
            )
            .catch(console.error);
    };
    const storeOptions = map(
        (store: IStore) => ({ label: store.name, value: store._id }),
        getPathFromState("stores")
    );

    return (
        <View>
            <Select
                className="snackpass__react-select"
                placeholder="Pick a store to start"
                options={storeOptions}
                onChange={_onChange}
            />
        </View>
    );
};

export default StorePicker;
