import React, { useMemo } from "react";
import _ from "lodash";
import { TextArea } from "semantic-ui-react";

enum pauseReasons {
    closedEarly = "Store closed early",
    busy = "Store too busy",
    connectionIssues = "Internet connection issues",
    printerIssues = "Printer issues",
    noInventory = "Out of inventory",
    unableToReach = "Snackpass is unable to reach the restaurant about pending orders",
    other = "Other",
}
type Props = {
    setOtherPausedReason: (otherPausedReason: string) => void;
    closedUntilReason: string | null;
    setClosedUntilReason: (pauseReason: pauseReasons) => void;
};

type OptionProps = {
    closedUntilReason: string | null;
    setClosedUntilReason: (pauseReason: pauseReasons) => void;
};

type OtherProps = {
    closedUntilReason: string | null;
    setOtherPausedReason: (otherPausedReason: string) => void;
};

// Render free-form text box for "Other" input
const OtherTextInput = ({
    closedUntilReason,
    setOtherPausedReason,
}: OtherProps) => {
    if (closedUntilReason === pauseReasons.other) {
        return (
            <div>
                <TextArea
                    placeholder={"Please enter other reason here. (Compulsory)"}
                    onChange={event => {
                        let value = _.get(event.target, "value");
                        setOtherPausedReason(value);
                    }}
                />
            </div>
        );
    }
    return null;
};

// Render radio buttons for store close options; memoize buttons
const OptionInput = ({
    closedUntilReason,
    setClosedUntilReason,
}: OptionProps) => {
    const memoizedOptions = useMemo(
        () =>
            Object.values(pauseReasons).map(pauseReason => {
                return (
                    <div>
                        <input
                            checked={closedUntilReason === pauseReason}
                            type="radio"
                            name="pause-reason"
                            onChange={() => setClosedUntilReason(pauseReason)}
                        />
                        <span style={{ marginLeft: 10 }}>{pauseReason}</span>
                        <br />
                    </div>
                );
            }),
        [closedUntilReason]
    );

    return <div>{memoizedOptions}</div>;
};

const ReasonInput = ({
    setOtherPausedReason,
    closedUntilReason,
    setClosedUntilReason,
}: Props) => {
    return (
        <div>
            <OptionInput
                closedUntilReason={closedUntilReason}
                setClosedUntilReason={setClosedUntilReason}
            />
            <OtherTextInput
                setOtherPausedReason={setOtherPausedReason}
                closedUntilReason={closedUntilReason}
            />
        </div>
    );
};

export default ReasonInput;
