import React from "react";
import { Colors } from "../../utils";

export const styles = {
    header: {
        background: Colors.blue2,
        padding: 15,
        paddingTop: 5,
        paddingBottom: 5,
        margin: 0,
        borderWidth: 0,
        borderBottomWidth: 1,
        borderColor: "white",
        borderStyle: "solid",
        marginBottom: 10,
        color: "white",
    },
};

// Header function to divide form into easy to read sections
export const Header = ({ label }: { label: string }) => (
    <div>
        <h3 style={styles.header}>{label}</h3>
    </div>
);
