import { connect } from "react-redux";
import Stores from "../screens/Stores";
import { StoreState } from "../redux/types";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { show } from "redux-modal";
import { getFetchingStores } from "../redux/stores";
import { getRegionOptions } from "../redux/regions";

const mapStateToProps = (state: StoreState) => {
    return {
        stores: state.stores,
        user: state.user,
        fetchingStores: getFetchingStores(state),
        regionOptions: getRegionOptions(state),
        regions: state.regions,
    };
};

// create handler than dispatches an action
const mapDispatchToProps = (
    dispatch: ThunkDispatch<StoreState, undefined, Action>
) => {
    return {
        showModal: (modal: string, props?: Object) => {
            dispatch(show(modal, props));
        },
    };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(Stores);

export default Container;
