import { GraphQLClient } from "graphql-request";
import config from "src/config";
import firebase from "src/firebase";

const GQL = new GraphQLClient(config.gqlUrl);

firebase.auth().onIdTokenChanged(async user => {
    const token = await user?.getIdToken();
    GQL.setHeaders({
        authorization: token ? `Bearer ${token}` : "",
    });
});

firebase.auth().onAuthStateChanged(async user => {
    const token = await user?.getIdToken();
    GQL.setHeaders({
        authorization: token ? `Bearer ${token}` : "",
    });
});

export { GQL };
