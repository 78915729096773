import React from "react";

import { Colors } from "../../utils/Colors";

import { FormValues } from "./types";

// This is necessary because if the Yup schema has errors, it will fail
// silently and will allow you to submit the form, which is dumb.
export function validYupSchema(values: FormValues, validationSchema: any) {
    try {
        const isValid = validationSchema.isValidSync(values);

        return (
            <p
                style={{
                    ...styles.textContainer,
                    ...(isValid
                        ? styles.successContainer
                        : styles.errorContainer)
                }}
            >
                {isValid ? "Valid Store ✅" : "Store contains errors ❌"}
            </p>
        );
    } catch (err) {
        console.log("===ERRORS===", err);
        return (
            <p style={styles.errorContainer}>
                Yup Schema contains errors! ❌❌❌❌ {JSON.stringify(err)} Alert
                the eng team!
            </p>
        );
    }
}

export function getWarnings(values: FormValues, warningSchema: any) {
    try {
        warningSchema.validateSync(values, { abortEarly: false });
        return [];
    } catch (err) {
        console.log(err);
        return err.inner;
    }
}

export const Warning = ({ text }: { text: string }) => (
    <div
        style={{
            ...styles.textContainer,
            ...styles.warningContainer
        }}
    >
        <p>{text}</p>
    </div>
);

export const Warnings = ({ warnings }: { warnings: Array<any> }) => (
    <React.Fragment>
        {warnings.map((warning: any) => (
            <Warning text={warning.message} />
        ))}
    </React.Fragment>
);

export const WarningsSummary = ({ warnings }: { warnings: Array<any> }) =>
    warnings.length === 0 ? null : (
        <Warning text={`⚠️ Store has ${warnings.length} warnings ⚠️`} />
    );

const styles = {
    textContainer: {
        maxWidth: "80%",
        padding: 10,
        margin: 15,
        borderRadius: 4,
        borderStyle: "solid",
        borderWidth: 1
    },
    errorContainer: {
        background: Colors.lightRed,
        borderColor: Colors.red
    },
    warningContainer: {
        background: Colors.lightYellow,
        borderColor: Colors.yellow
    },
    successContainer: {
        background: "#F1FFF7",
        borderColor: "#5FC990"
    }
};
