import _ from "lodash";
import stringifyObject from "stringify-object";

export default class FormikHelpers {
    /**
     * Return a stringified version of a Formik errors object. If empty, return null.
     *
     * @param {any} errors A (possibly empty) Formik errors object
     * @returns {string | null}
     */
    static stringifyErrors(errors: any): string | null {
        return stringifyObject(errors, {
            singleQuotes: false,
            transform: (
                obj: any | any[],
                prop: any,
                originalResult: string
            ) => {
                let value = obj[prop];
                if (value && _.isArray(stringifyObject)) {
                    // Remove all undefined or null
                    // keys from the list.
                    // If a list has 4 elements, and the last one is invalid,
                    // Yup returns [ undefined, undefined, undefined, VALIDATION_ERRORS[3] ],
                    // so we want to filter the keys that are valid out
                    return stringifyObject(
                        (value || []).filter((v: any) => v),
                        { singleQuotes: true }
                    );
                } else {
                    return originalResult;
                }
            },
        });
    }
}
