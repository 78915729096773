import React, { useState } from "react";
import { Button, Toggle } from "../../SharedComponents";
import _ from "lodash";
import fp from "lodash/fp";
import Table, { CellInfo } from "react-table";
import { IStore, IChain, IRegion } from "@snackpass/snackpass-types";
import { Colors } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import API from "../../api";
import { setStore, getChainStores } from "../../redux/stores";
import Swal from "sweetalert2";
import { StoreState } from "../../redux/types";
import { getRegions } from "../../redux/regions";

type ChainStoresTableProps = {
    chain: IChain;
    isSubRow?: boolean;
};

export const ChainStoresTable = ({
    chain,
    isSubRow = true
}: ChainStoresTableProps) => {
    const stores = useSelector((state: StoreState) =>
        getChainStores(state, chain._id)
    );

    return (
        <Table
            showPagination={false}
            style={{ textAlign: "center" }}
            data={stores}
            // If stores length is 0, default to 3 so the "no data" looks good
            pageSize={stores.length || 3}
            className={`snackpass__table ${
                isSubRow ? "snackpass__child-table" : ""
            }`}
            columns={[
                indexColumn,
                nameColumn,
                regionColumn,
                addressColumn,
                actionsColumn,
                chainWidePunchcard,
                chainWideGiftCard
            ]}
        />
    );
};

const indexColumn = {
    Header: "",
    id: "storeIndex",
    minWidth: 30,
    Cell: (props: CellInfo) => {
        return <span>{props.index + 1}</span>;
    }
};

const nameColumn = {
    Header: "Name",
    accessor: "name",
    id: "storeName",
    minWidth: 125,
    Cell: (props: CellInfo) => {
        return (
            <span>
                {_.get(props.original, "emoji")} {_.get(props.original, "name")}
            </span>
        );
    }
};

const regionColumn = {
    Header: "Region",
    id: "storeRegion",
    Cell: (props: CellInfo) => {
        return (
            <span>
                {fp.compose(
                    fp.prop("name"),
                    fp.find((r: IRegion) => {
                        return r.slug === props.original.region;
                    })
                )(useSelector(getRegions))}
            </span>
        );
    }
};

const addressColumn = {
    Header: "Address",
    accessor: "address",
    id: "storeAddress"
};

const actionsColumn = {
    Header: "Actions",
    id: "storeActions",
    Cell: (props: CellInfo) => {
        const store = props.original;
        return <DeleteButton store={store} />;
    }
};

const chainWidePunchcard = {
    Header: "Chain-wide Punchcard",
    id: "chainWidePunchcard",
    Cell: (props: CellInfo) => {
        const store = props.original;
        return <SetChainWidePunchcardSwitch store={store} />;
    }
};

const SetChainWidePunchcardSwitch = ({ store }: { store: IStore }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const setChainWidePunchcard = async (enabled: boolean) => {
        try {
            setLoading(true);
            const res = await API.stores.update(store._id, {
                hasChainWidePunchcard: enabled
            });

            setLoading(false);

            dispatch(setStore(res.data.store));
        } catch (err) {
            setLoading(false);
            Swal.fire({
                type: "error",
                text: "An error occurred while enabling or disabling chain-wide punchcard."
            });
        }
    };

    return (
        <div style={{ display: "inline-block" }}>
            <Toggle
                activeLabel="ON"
                inactiveLabel="OFF"
                value={store.hasChainWidePunchcard}
                onToggle={(enabled) => setChainWidePunchcard(enabled)}
                disabled={loading}
            />
        </div>
    );
};

const chainWideGiftCard = {
    Header: "Chain-wide Gift Card",
    id: "chainWideGiftCard",
    Cell: (props: CellInfo) => {
        const store = props.original;
        return <SetChainWideGiftCardSwitch store={store} />;
    }
};

const SetChainWideGiftCardSwitch = ({ store }: { store: IStore }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const setChainWideGiftCard = async (enabled: boolean) => {
        try {
            setLoading(true);
            const res = await API.stores.update(store._id, {
                hasChainWideGiftCard: enabled
            });

            setLoading(false);

            dispatch(setStore(res.data.store));
        } catch (err) {
            setLoading(false);
            Swal.fire({
                type: "error",
                text: "An error occurred while enabling or disabling chain-wide gift card."
            });
        }
    };

    return (
        <div style={{ display: "inline-block" }}>
            <Toggle
                activeLabel="ON"
                inactiveLabel="OFF"
                value={store.hasChainWideGiftCard}
                onToggle={(enabled) => setChainWideGiftCard(enabled)}
                disabled={loading}
            />
        </div>
    );
};

const DeleteButton = ({ store }: { store: IStore }) => {
    const dispatch = useDispatch();

    const onPress = async () => {
        let result = await Swal.fire({
            title: "Remove Store from Chain?",
            text: "Are you sure you want to remove this store from this chain? Note: removing a store from a chain does NOT delete the store from snackpass.",
            type: "warning",
            showConfirmButton: true,
            showCancelButton: true
        });

        if (result.value) {
            try {
                let res = await API.stores.update(store._id, {
                    chainId: null
                });
                dispatch(setStore(res.data.store));
            } catch (err) {
                Swal.fire({
                    type: "error",
                    text: "An error occurred removing this store."
                });
            }
        }
    };

    return (
        <span>
            <Button
                containerStyle={{
                    display: "inline-block",
                    margin: "0 5px"
                }}
                backgroundColor={Colors.white}
                labelColor={Colors.red}
                label={<i className="fas fa-trash" />}
                type="button"
                onPress={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onPress();
                }}
            />
        </span>
    );
};
