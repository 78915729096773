import React from "react";
import { Colors } from "../utils";
import { BeatLoader } from "react-spinners";

export interface IButton {
    label?: string | any;
    loading?: boolean;
    className?: string;
    labelColor?: string;
    backgroundColor?: string;
    style?: any;
    containerStyle?: any;
    onPress?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onPressDisabled?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
    type?: "button" | "submit";
}

export const Button = ({
    label,
    className,
    labelColor = "white",
    backgroundColor = Colors.blue,
    style,
    containerStyle,
    onPress,
    onPressDisabled,
    loading = false,
    disabled = false,
    type
}: IButton) => (
    <div style={containerStyle}>
        <button
            type={type}
            onClick={(e: any) => {
                if (loading || !onPress) return;
                if (disabled) {
                    if (onPressDisabled) onPressDisabled(e);
                    return;
                }
                onPress(e);
            }}
            style={{
                backgroundColor: backgroundColor,
                color: labelColor as string,
                border: "1px solid #efefef",
                ...style,
                ...(disabled && {
                    backgroundColor: Colors.gray,
                    color: Colors.white
                })
            }}
            className={className}
        >
            {loading ? <BeatLoader color={labelColor} size={10} /> : label}
        </button>
    </div>
);
