import { SystemColors } from "@snackpass/design-system";
import _ from "lodash";
import React, { Component } from "react";
import { Nav } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { Menu, Dropdown } from "semantic-ui-react";

import config from "../../config";
import firebase from "../../firebase";
import { Constants } from "../../utils";

const nameMap = {
    stores: "Stores",
    accounting: "Accounting",
    admins: "Admins",
    users: "Users",
    purchases: "Purchases",
    metrics: "Metrics",
    promotions: "Promotions",
    giftcards: "Gift Cards",
    qrcodes: "QR Codes",
    sales: "Sales",
    restaurant_airtable: "Restaurants",
    "store-tablets": "Store Tablets",
    "": "",
    "sign out": "Sign Out",
    kiosks: "Kiosks",
    chains: "Store Chains",
    regions: "Regions",
    payouts: "Payouts",
    subscriptions: "Subscriptions",
    printers: "Printers"
};

type Value = keyof typeof nameMap;

const MenuItem = ({
    value,
    activeItem,
    onClick
}: {
    value: Value;
    onClick: (name: Value) => void;
    activeItem: Value;
}) => (
    <Link to={`/${value}`}>
        {/* //@ts-ignore */}
        <Menu.Item
            as={Nav}
            name={value}
            active={activeItem === value}
            onClick={(e: any, data: any) => {
                onClick(data.name);
            }}
        >
            {nameMap[value]}
        </Menu.Item>
    </Link>
);

type NavbarProps = {
    history: any;
};

type State = {
    activeItem: Value;
};

class NavBar extends Component<NavbarProps, State> {
    state = { activeItem: "" as Value };

    componentDidMount() {
        const activeItem: Value = window.location.pathname.replace(
            /\//g,
            ""
        ) as Value;

        if (!nameMap[activeItem]) {
            document.title = `Snackface 🙇‍♀️`;
        } else {
            document.title = `${nameMap[activeItem]} - Snackface 🙇‍♀️`;
        }

        this.setState({ activeItem });
    }

    handleItemClick = (name: Value) => {
        this.setState({ activeItem: name });
        document.title = `${nameMap[name]} - Snackface 🙇‍♀️`;
    };

    signOut = () => {
        firebase.auth().signOut();
        window.location.reload();
    };

    render() {
        const { history } = this.props;
        const { activeItem } = this.state;

        const buildMenuItems = (collection: string[]) =>
            _.map(collection, (value: Value, n: number) => (
                <MenuItem
                    activeItem={activeItem}
                    value={value}
                    onClick={this.handleItemClick}
                    key={n}
                />
            ));
        const capitalize = (word: string) =>
            word.replace(/^\w/, (c: string) => c.toUpperCase());
        const buildDropdownItems = (collection: string[]) =>
            _.map(collection, (value: Value, n: number) => (
                <Dropdown.Item
                    onClick={() => {
                        this.handleItemClick(value);
                        history.replace(value);
                    }}
                    key={n}
                >
                    {nameMap[value] || capitalize(value)}
                </Dropdown.Item>
            ));

        return (
            <Menu vertical style={{ width: Constants.VERTICAL_MENU_WIDTH }}>
                <Menu.Item>
                    <h3>Snackface 🙇</h3>
                    <span style={{ color: SystemColors.v1.gray50 }}>
                        version: {config.VERSION}
                    </span>
                </Menu.Item>
                <Menu.Item>
                    Admin
                    <Menu.Menu>
                        {buildMenuItems([
                            "stores",
                            "promotions",
                            "giftcards",
                            "regions"
                        ])}
                        <Dropdown item text="Other">
                            <Dropdown.Menu>
                                {buildDropdownItems([
                                    "subscriptions",
                                    "chains"
                                ])}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu.Menu>
                </Menu.Item>
                <Menu.Item>
                    Account Mgmt
                    <Menu.Menu>
                        {buildMenuItems([
                            "admins"
                            // "accounting",
                        ])}
                        <Dropdown item text="Other">
                            <Dropdown.Menu>
                                {buildDropdownItems([
                                    "qrcodes",
                                    "kiosks",
                                    "store-tablets",
                                    "payouts",
                                    "printers"
                                ])}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu.Menu>
                </Menu.Item>
                <Menu.Item>
                    Service
                    <Menu.Menu>
                        {buildMenuItems(["users", "purchases"])}
                    </Menu.Menu>
                </Menu.Item>
                <Menu.Item name="sales">
                    <Menu.Menu>
                        <MenuItem
                            activeItem={activeItem}
                            value={"sign out"}
                            onClick={this.signOut}
                        />
                    </Menu.Menu>
                </Menu.Item>
            </Menu>
        );
    }
}

export const VerticalNav = withRouter(({ history }) => (
    <div style={{ position: "fixed" }}>
        <NavBar history={history} />
    </div>
));
