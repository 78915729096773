import _ from "lodash";
import React from "react";
import swal from "sweetalert2";
import { BeatLoader } from "react-spinners";
import { FormValues } from "../types";
import API from "../../../api";
import { Input } from "./Input";
import { setStore } from "../../../redux/stores";
import { Button, TextInput } from "../../../SharedComponents";
import { Colors } from "../../../utils";
import { HOGWARTS_ID, HOGWARTS_TABLET_SERIAL } from "src/utils/Constants";

type Props = {
    editMode?: boolean;
    values: FormValues;
    setFieldValue: (field: string, value: any) => void;
    dispatch: Function;
    updateJAMFTablet: (storeTabletId: string) => Promise<any>;
};

type State = {
    loadingSerial: boolean;
    isLoadingStoreForSerial: boolean;
    primaryTablet?: any;
};

export default class TabletSerial extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            loadingSerial: false,
            isLoadingStoreForSerial: false
        };
    }

    fetchSerial = async (store: any) => {
        if (!store.primaryStoreTablet) {
            return;
        }

        this.setState({ loadingSerial: true });
        const response = await API.stores.getTablets(store._id);
        const tablets = response.data.tablets;

        if (tablets) {
            const primaryTablet = tablets.find(
                (tablet: any) => tablet._id === store.primaryStoreTablet
            );

            if (primaryTablet) {
                this.props.setFieldValue("tabletSerial", primaryTablet.serial);
                this.setState({
                    primaryTablet
                });
            }
        }

        this.setState({ loadingSerial: false });
    };

    fetchStoreWithSerial = _.debounce((serial: string) => {
        this.setState({ isLoadingStoreForSerial: true });
        API.stores.getStoreByTabletSerial(serial).then((response) => {
            this.setState({ isLoadingStoreForSerial: false });
            const store = response.data.store;
            if (store && store.name !== this.props.values.name) {
                this.props.setFieldValue("storeWithSerial", store);
            }
        });
    }, 1000);

    onTabletSerialUpdate = async (serial: string) => {
        const trimmedSerial = serial.trim();
        this.props.setFieldValue("tabletSerial", trimmedSerial);
        this.props.setFieldValue("storeWithSerial", null);

        if (!trimmedSerial) {
            return;
        }

        // Do all this asynchronously so we don't block the input.
        this.fetchStoreWithSerial(trimmedSerial);
    };

    removeSerialFromStore = async () => {
        alert("Sorry, please use RDB devices page to manage Order Hubs.");
    };

    isDevStore = () => this.props.values._id === HOGWARTS_ID;

    componentDidMount() {
        if (this.props.editMode) {
            this.fetchSerial(this.props.values);
        }
    }

    render() {
        if (this.state.loadingSerial) {
            return null;
        }

        const serialCollisionMessage = this.props.values.storeWithSerial
            ? `This serial number is already taken by ${this.props.values.storeWithSerial.name}`
            : null;

        return (
            <div>
                <div>
                    <Input
                        disabled
                        name="tabletSerial"
                        label="Tablet serial number"
                        description="Please use RDB devices page to manage Order Hubs"
                        type="text"
                        component={
                            <TextInput
                                value={
                                    this.isDevStore()
                                        ? HOGWARTS_TABLET_SERIAL
                                        : this.props.values.tabletSerial
                                }
                                placeholder="Tablet serial number"
                                onChangeText={(value: string) =>
                                    this.isDevStore()
                                        ? null
                                        : this.onTabletSerialUpdate(value)
                                }
                            />
                        }
                    />
                    {this.state.isLoadingStoreForSerial && (
                        <div
                            style={{
                                width: "100%",
                                padding: 20,
                                textAlign: "center"
                            }}
                        >
                            <BeatLoader color={Colors.blue} size={10} />
                        </div>
                    )}
                    {serialCollisionMessage && (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                color: Colors.red,
                                paddingTop: 20,
                                paddingBottom: 20
                            }}
                        >
                            {serialCollisionMessage}
                            <Button
                                label={`Unlink tablet from ${
                                    this.props.values.storeWithSerial &&
                                    this.props.values.storeWithSerial.name
                                }`}
                                onPress={() => {
                                    this.removeSerialFromStore();
                                }}
                                type="button"
                                backgroundColor={Colors.red}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
