import React from "react";
import { Colors, Helpers } from "src/utils";
import { Text } from "src/SharedComponents";
interface RippleProps {
    value: string | number;
}

export default function RippleButton({ value }: RippleProps) {
    return (
        <Text center color={Colors.text}>
            {Helpers.toDollar(value)}
        </Text>
    );
}
