import { IStore, IStoreContact, IUser } from "@snackpass/snackpass-types";
import { Form, FormikProps } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";

import API from "../../api";
import { Colors } from "../../utils/Colors";
import { validationSchema } from "./config";
import { Input } from "./components/Input";
import NotificationPreferenceSelect, {
    MessageType
} from "./components/NotificationPreferenceSelect";
import * as Helpers from "../../utils/Helpers";
import { Button, TextInput } from "../../SharedComponents";
import { styles } from "./styles";
import { FormValues } from "./types";
import { validYupSchema } from "./validation";
import FormikHelpers from "../../utils/FormikHelpers";

interface AdditionalProps {
    admin?: IUser;
    storeObj?: IStore;
    email?: string;
    promotingUser: boolean;
    stores: IStore[];
}

const renderPhoneNumberRecommendationText = (
    props: AdditionalProps & FormikProps<FormValues>
) => {
    if (props.values.number) {
        return null;
    }

    return (
        <div style={{ color: Colors.red }}>
            It is strongly recommended that you add a phone number. If this
            contact wants to receive SMS messages or use SMS auth to log into
            the restaurant dashboard or Partner App, they must have a phone
            number here!
        </div>
    );
};

const renderSubmitSection = (
    props: AdditionalProps & FormikProps<FormValues>,
    initialized: boolean
) => {
    let action = "Create";
    if (props.promotingUser) {
        action = "Promote user to admin";
    } else if (props.admin) {
        action = "Update";
    }

    const isValid =
        validationSchema.isValidSync(props.values) &&
        !props.values.internal.matchingEmailUser &&
        !props.values.internal.matchingNumberUser;
    const isLoading =
        props.isSubmitting ||
        props.values.internal.loadingUsers ||
        !initialized;

    return (
        <Button
            type="submit"
            label={action}
            onPress={() => props.handleSubmit()}
            disabled={!isValid || isLoading}
            loading={isLoading}
            style={{ width: 120, height: 40 }}
        />
    );
};

const handleEmailChange = (
    props: AdditionalProps & FormikProps<FormValues>,
    email: string
) => {
    props.setFieldValue("email", email.toLowerCase());

    const isValid = Helpers.validateEmail(email);
    if (isValid) {
        props.setFieldValue("internal.loadingUsers", true);
        findUsersForEmail(props, email);
    }

    props.setFieldValue("internal.matchingEmailUser", null);
};

const findUsersForEmail = _.debounce(
    (props: AdditionalProps & FormikProps<FormValues>, email: string) => {
        API.users.getMany({ email }).then((response) => {
            const users = response.data.users;
            const matchingEmailUser = users.find(
                (user: IUser) => user.email === email
            );
            if (
                matchingEmailUser &&
                (!props.admin || props.admin._id !== matchingEmailUser._id)
            ) {
                props.setFieldValue(
                    "internal.matchingEmailUser",
                    matchingEmailUser
                );
            }

            props.setFieldValue("internal.loadingUsers", false);
        });
    },
    1000
);

const handleNumberChange = (
    props: AdditionalProps & FormikProps<FormValues>,
    number: string
) => {
    // We set the form value first before normalizing because we don't want to "normalize" partial
    // phone numbers to null lol.
    props.setFieldValue("number", number);

    const isValid = Helpers.normalizePhoneNumber(number);
    if (isValid) {
        props.setFieldValue("internal.loadingUsers", true);
        findUsersForNumber(props, number);
    }

    props.setFieldValue("internal.matchingNumberUser", null);
};

const findUsersForNumber = _.debounce(
    (props: AdditionalProps & FormikProps<FormValues>, number: string) => {
        API.users.getMany({ number }).then((response) => {
            const normalizedNumber = Helpers.normalizePhoneNumber(number);
            const users = response.data.users;
            const matchingNumberUser = users.find(
                (user: IUser) => user.number === normalizedNumber
            );
            if (
                matchingNumberUser &&
                (!props.admin || props.admin._id !== matchingNumberUser._id)
            ) {
                props.setFieldValue(
                    "internal.matchingNumberUser",
                    matchingNumberUser
                );
            }

            props.setFieldValue("internal.loadingUsers", false);
        });
    },
    1000
);

const renderEmailInput = (props: AdditionalProps & FormikProps<FormValues>) => {
    if (props.promotingUser || props.email) {
        return <Input name="email" label="Email" type="read-only-text" />;
    }

    let emailErrorMessage;
    if (props.values.internal.matchingEmailUser) {
        emailErrorMessage = (
            <div style={{ color: Colors.red }}>This email is already taken</div>
        );
    }

    return (
        <Input
            name="email"
            label="Email"
            type="email"
            leftComponent={emailErrorMessage}
            component={
                <TextInput
                    value={props.values.email}
                    onChangeText={(email: string) =>
                        handleEmailChange(props, email)
                    }
                />
            }
        />
    );
};

const renderNumberInput = (
    props: AdditionalProps & FormikProps<FormValues>
) => {
    if (props.promotingUser) {
        return <Input name="number" label="Phone" type="read-only-text" />;
    }

    let errorMessage;
    if (props.values.internal.matchingNumberUser) {
        errorMessage = (
            <div style={{ color: Colors.red }}>
                This number is already taken
            </div>
        );
    }

    // Show a strong but non-blocking warning to add a phone number if the field has been left blank.
    const phoneNumberRecommendationText =
        renderPhoneNumberRecommendationText(props);

    return (
        <Input
            name="number"
            label="Phone"
            type="phone"
            leftComponent={errorMessage || phoneNumberRecommendationText}
            component={
                <TextInput
                    value={props.values.number}
                    onChangeText={(number: string) =>
                        handleNumberChange(props, number)
                    }
                />
            }
        />
    );
};

const renderErrorSummary = (errors: any) => {
    if (Object.keys(errors || {}).length === 0) {
        return null;
    }

    const stringifiedErrors = FormikHelpers.stringifyErrors(errors);

    return (
        <div style={styles.errorContainer}>
            <p style={{ whiteSpace: "pre-wrap" }}>{stringifiedErrors}</p>
        </div>
    );
};

export const FormBody = (props: AdditionalProps & FormikProps<FormValues>) => {
    const [initialized, setInitialized] = useState<boolean>(false);
    const submitSection = renderSubmitSection(props, initialized);
    const emailInput = renderEmailInput(props);
    const numberInput = renderNumberInput(props);
    const errorSummary = renderErrorSummary(props.errors);

    useEffect(() => {
        if (props.email) {
            props.setFieldValue("email", props.email);
        }

        if (props.admin) {
            API.stores
                .getContacts(_.get(props, "storeObj._id", ""))
                .then((res) => {
                    const storeContacts = res.data.contacts;
                    const storeContact = storeContacts.find(
                        (storeContact: IStoreContact) =>
                            storeContact.user._id === props.admin!._id
                    );

                    if (storeContact) {
                        props.setFieldValue(
                            "disabledEmailCommunications",
                            new Set(storeContact.disabledEmailCommunications)
                        );

                        props.setFieldValue(
                            "disabledSmsCommunications",
                            new Set(storeContact.disabledSmsCommunications)
                        );
                    }
                });
        }

        setInitialized(true);
    }, []);

    return (
        <Form
            onKeyPress={(e) => {
                if (e.key === "Enter") {
                    e.preventDefault();
                }
            }}
        >
            <div style={{ fontSize: 14 }}>
                <span style={{ color: "darkgoldenrod" }}>Attention:</span> If
                you need to set permissions please do so via the{" "}
                <a href="/admins">admins menu</a> on the left nav
            </div>

            {validYupSchema(props.values, validationSchema)}
            <Input
                name="firstName"
                label="First name"
                type={props.promotingUser ? "read-only-text" : "text"}
            />
            <Input
                name="lastName"
                label="Last name"
                type={props.promotingUser ? "read-only-text" : "text"}
            />
            {emailInput}
            <Input
                name="snackpassPermissions.storeRole"
                label="Store Role"
                type="text"
            />
            {numberInput}
            <Input
                name="disabledSmsCommunications"
                label="Disabled SMS notifications"
                type={props.promotingUser ? "read-only-text" : "select"}
                component={
                    <NotificationPreferenceSelect
                        onChange={(value: Set<MessageType>) =>
                            props.setFieldValue(
                                `disabledSmsCommunications`,
                                value
                            )
                        }
                        value={props.values.disabledSmsCommunications}
                    />
                }
            />
            <Input
                name="disabledEmailCommunications"
                label="Disabled email notifications"
                type={props.promotingUser ? "read-only-text" : "select"}
                component={
                    <NotificationPreferenceSelect
                        onChange={(value: Set<MessageType>) =>
                            props.setFieldValue(
                                `disabledEmailCommunications`,
                                value
                            )
                        }
                        value={props.values.disabledEmailCommunications}
                    />
                }
            />
            {submitSection}
            {errorSummary}
        </Form>
    );
};
