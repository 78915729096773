import * as Sentry from "@sentry/browser";
import React from "react";
import { Button, Copy } from "../../SharedComponents";
import _ from "lodash";
import fp from "lodash/fp";
import { compose } from "lodash/fp";
import { message } from "antd";
import Table, { CellInfo, RowInfo } from "react-table";
import { IChain, IStore } from "@snackpass/snackpass-types";
import Swal from "sweetalert2";
import { Dispatch } from "redux";
import { show } from "redux-modal";
import { useDispatch, useSelector } from "react-redux";
import { Toggle } from "../../SharedComponents";
import { confirmAlert } from "react-confirm-alert";
import QRCode from "qrcode.react";

import { ChainStoresTable } from "../../components/Chains/ChainStoresTable";
import { Colors, QrCodes } from "../../utils";
import API from "../../api";
import * as chainsRedux from "../../redux/chains";
import { getChainIdToStores } from "../../redux/stores";

export const ChainsTable = () => {
    const isLoading = useSelector(chainsRedux.getChainsStatus) === "LOADING";
    const chains = useSelector(chainsRedux.getChainsList);
    const chainIdToStores = useSelector(getChainIdToStores);
    return (
        <Table
            style={{ textAlign: "center" }}
            data={chains}
            loading={isLoading}
            className="snackpass__table"
            defaultPageSize={20}
            freezeWhenExpanded={true}
            SubComponent={(row: RowInfo) => {
                let chain = row.original;
                return <ChainStoresTable chain={chain} />;
            }}
            columns={[
                _nameColumn,
                _numberOfStoresColumn(chainIdToStores),
                _isFoodHallColumn(),
                _chainQrCodeColumn(),
                _actionsColumn(),
            ]}
        />
    );
};

const EditButton = ({ onClick }: { onClick: () => void }) => {
    return (
        <Button
            containerStyle={{
                display: "inline-block",
                margin: "0 5px",
            }}
            backgroundColor={Colors.white}
            labelColor={Colors.blue}
            label={<i className="fas fa-pen" />}
            onPress={onClick}
        />
    );
};

const DeleteButton = ({ onClick }: { onClick: () => void }) => {
    return (
        <Button
            containerStyle={{
                display: "inline-block",
                margin: "0 5px",
            }}
            backgroundColor={Colors.white}
            labelColor={Colors.red}
            label={<i className="fas fa-trash" />}
            onPress={onClick}
        />
    );
};

const _nameColumn = {
    Header: "Name",
    accessor: "name",
    id: "chainName",
    minWidth: 125,
};

const _numberOfStoresColumn = (chainIdToStores: _.Dictionary<IStore[]>) => ({
    Header: "Num. Stores",
    id: "storesLength",
    Cell: (props: CellInfo) => {
        let numberOfStores = (chainIdToStores[props.original._id] || []).length;
        return <span>{numberOfStores}</span>;
    },
});

const _isFoodHallColumn = () => ({
    Header: "FoodHall",
    accessor: "isFoodHall",
    id: "isFoodHall",
    Cell: (props: CellInfo) => {
        const dispatch = useDispatch();
        return (
            <div style={{ display: "inline-block" }}>
                <Toggle
                    activeLabel="ON"
                    inactiveLabel="OFF"
                    value={props.value}
                    onToggle={() =>
                        toggleFoodHallConfirm(props.original, dispatch)
                    }
                />
            </div>
        );
    },
});

const _chainQrCodeColumn = () => ({
    Header: "Chain QR Code",
    id: "chainQrCode",
    Cell: (props: CellInfo) => {
        const chain = props.original;
        const chainUrl = _getChainURL(chain) + "?utm_source=qr";
        return (
            <>
                <Button
                    label="Download"
                    onPress={() => QrCodes.downloadQR(chain.name, chainUrl)}
                />
                <div style={{ display: "none" }}>
                    <QRCode id={chainUrl} value={chainUrl} size={160} />
                </div>
            </>
        );
    },
});

const _actionsColumn = () => ({
    Header: "Actions",
    id: "chainActions",
    Cell: (props: CellInfo) => {
        const dispatch = useDispatch();
        const chain = props.original;

        const showModal = compose(dispatch, show);

        return (
            <span>
                <EditButton
                    onClick={() => {
                        showModal("StoreChainModal", {
                            editMode: true,
                            chain,
                        });
                    }}
                />
                <DeleteButton onClick={() => _deleteChain(dispatch, chain)} />
                <Copy value={_getChainURL(chain)} />
            </span>
        );
    },
});

const _getChainURL = (chain: IChain) =>
    chain.isFoodHall
        ? `https://order.snackpass.co/foodhalls/${chain._id}`
        : `https://order.snackpass.co/chains/${chain._id}`;

const _deleteChain = async (dispatch: Dispatch, chain: IChain) => {
    let result = await Swal.fire({
        title: "Delete Chain?",
        text: "Are you sure you wish to delete this chain?",
        type: "warning",
        showConfirmButton: true,
        showCancelButton: true,
    });

    if (result.value) {
        await API.chains.delete(chain._id);
        dispatch(chainsRedux.removeChain(chain));
    }
};

// foodhall toggle
const toggleFoodHall = async (chain: IChain, dispatch: Dispatch) => {
    const isFoodHall = !fp.propOr(false, "isFoodHall", chain);
    API.chains
        .update(chain._id, {
            fields: {
                name: chain.name,
                isFoodHall,
            },
        })
        .then(res => {
            const _addChain = fp.compose(dispatch, chainsRedux.addChain);
            _addChain(fp.prop("data.chain", res));
        })
        .catch(err => {
            Sentry.captureException(err);
            message.error("Could not update chain");
            console.log(err.response);
        });
};

const toggleFoodHallConfirm = async (chain: IChain, dispatch: Dispatch) => {
    const message = {
        title: "Are you sure?",
        message:
            "Toggling a chain's isFoodHall property changes the behavior of the chain's storefronts.",
        buttons: [
            {
                label: "Cancel",
                onClick: () => {},
            },
            {
                label: "Confirm Change",
                onClick: () => toggleFoodHall(chain, dispatch),
            },
        ],
    };
    confirmAlert(message);
};
