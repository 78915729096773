import React, { CSSProperties } from "react";
import { Button, Text, TextInput } from "src/SharedComponents";
import { IAddress } from "@snackpass/snackpass-types";
import { captureException } from "@sentry/browser";
import { FormValues } from "../types";
import api from "../../../api";
import { USEFUL_ADDRESS_COMPONENTS } from "src/utils/Constants";
import { find, reduce } from "lodash";
import { compose, first, intersection } from "lodash/fp";
import { isSubstring } from "src/utils/Helpers";
import { StyleObjectType } from "src/utils/types";

interface AddressFieldProps {
    name: string;
    field: string;
    value: string | null;
    style: CSSProperties;
    setFieldValue: (field: string, value: string) => void;
    disabled?: boolean;
}

interface AddressComponentProps {
    addressComponents: IAddress;
    values: FormValues;
    setFieldValue: (field: string, value: any) => void;
}

type SnackpassGoogleAddressMap = {
    [k in typeof USEFUL_ADDRESS_COMPONENTS[number]]: {
        long: string;
        short: string;
    };
};

const AddressField = ({
    name,
    field,
    value,
    style,
    setFieldValue,
    disabled
}: AddressFieldProps) => {
    const handleChangeText = (value: string) => {
        setFieldValue(`addressComponents.${field}`, value);
    };

    return (
        <TextInput
            label={name}
            value={value}
            style={style}
            type="text"
            containerStyle={styles.row}
            onChangeText={handleChangeText}
            disabled={disabled}
        />
    );
};

const AddressComponents = ({
    addressComponents,
    values,
    setFieldValue
}: AddressComponentProps) => {
    const { line1, line2, city, county, state, zip, country } =
        addressComponents;

    /**
     * Fetches address data from Google API, strips any establishment name included in address,
     * and builds an address object to then set the addressComponents fields with
     */
    const handlePress = async () => {
        try {
            const {
                data: { result }
            } = await api.places.search(values.address);

            let address = values.address;
            if (isSubstring(result.vicinity ?? "", ",")) {
                const name = result.name || "";
                address = address.replace(name, "");
            }

            const addressObj = {
                ...normalizeAddress(result.address_components ?? [], address),
                line2,
                full: result.formatted_address
            };

            setFieldValue("addressComponents", addressObj);
        } catch (err) {
            console.log(err);
            captureException(err);
        }
    };

    return (
        <div style={styles.container}>
            <Text small> Address Components </Text>
            <div style={styles.row}>
                <AddressField
                    name="Line 1"
                    field="line1"
                    value={line1}
                    style={styles.long}
                    setFieldValue={setFieldValue}
                    disabled={true}
                />
                <AddressField
                    name="Line 2"
                    field="line2"
                    value={line2}
                    style={styles.long}
                    setFieldValue={setFieldValue}
                />
                <Button
                    label="Break Down Address"
                    onPress={handlePress}
                    type="button"
                />
            </div>
            <div style={styles.row}>
                <AddressField
                    name="City"
                    field="city"
                    value={city}
                    style={styles.medium}
                    setFieldValue={setFieldValue}
                />
                <AddressField
                    name="County"
                    field="county"
                    value={county}
                    style={styles.medium}
                    setFieldValue={setFieldValue}
                    disabled={true}
                />
                <AddressField
                    name="State"
                    field="state"
                    value={state}
                    style={styles.short}
                    setFieldValue={setFieldValue}
                    disabled={true}
                />
                <AddressField
                    name="Zip"
                    field="zip"
                    value={zip}
                    style={styles.medium}
                    setFieldValue={setFieldValue}
                    disabled={true}
                />
                <AddressField
                    name="Country"
                    field="country"
                    value={country}
                    style={styles.short}
                    setFieldValue={setFieldValue}
                    disabled={true}
                />
            </div>
        </div>
    );
};

const styles: StyleObjectType = {
    container: {
        marginTop: 10,
        margin: 5
    },
    row: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    long: {
        width: 200,
        margin: 10
    },
    medium: {
        width: 100,
        margin: 10
    },
    short: {
        width: 50,
        margin: 10
    }
};

/**
 * Builds a map of values from meaningful address fields and uses this map to construct an address object
 * @param addressComps an array of address components produced by the Google API
 * @param completeAddr a string representing the complete address (assumed to be a source of truth)
 * @returns a normalized address object
 */
const normalizeAddress = (
    addressComps: google.maps.GeocoderAddressComponent[],
    completeAddr: string
) => {
    const addressMap = reduce(
        addressComps,
        (acc, partial) => {
            const addressType: keyof SnackpassGoogleAddressMap = compose(
                first,
                intersection(USEFUL_ADDRESS_COMPONENTS)
            )(partial.types);

            if (addressType) {
                acc[addressType] = {
                    long: partial.long_name,
                    short: partial.short_name
                };
            }
            return acc;
        },
        {} as SnackpassGoogleAddressMap
    );

    // Find which property contains a "city" value that is included in the complete address
    const city = find(
        [addressMap.locality, addressMap.sublocality, addressMap.neighborhood],
        (val) =>
            completeAddr.includes(val?.long) ||
            completeAddr.includes(val?.short)
    );

    return {
        full: null,
        line1:
            addressMap.premise?.short ??
            `${addressMap.street_number?.short ?? ""} ${
                addressMap.route?.short ?? ""
            }`.trim(),
        line2: null,
        city: city?.long ?? "",
        county: addressMap.administrative_area_level_2?.short ?? "",
        state: addressMap.administrative_area_level_1?.short ?? "",
        zip: addressMap.postal_code?.short ?? "",
        country: "USA"
    } as IAddress;
};

export default AddressComponents;
