import React, { Component } from "react";

import { View, Text, Button } from "../../SharedComponents";

import StartOrderTimes from "./StartOrderTimes";
import Completed from "./Completed";

import { Colors } from "../../utils";
import api from "../../api";

import { connect } from "react-redux";
import { replacePurchase } from "../../redux/purchases";
import { Dispatch } from "../../redux/types";
import {
    IPurchase,
    PurchaseStatus as PurchaseStatusEnum
} from "@snackpass/snackpass-types";
import * as Sentry from "@sentry/browser";
import Loader from "react-loader-spinner";
import * as Helpers from "../../utils/Helpers";
import _ from "lodash";

type Props = {
    purchase: IPurchase;
    replacePurchase: (purchase: IPurchase) => void;
};

type State = {};

class PurchaseStatus extends Component<Props, State> {
    state = {
        loading: false,
        selectedPickupTime: null,
        delayTime: 0
    };
    startLoading = () => {
        this.setState({ loading: true });
    };
    endLoading = () => {
        this.setState({ loading: false, selectedPickupTime: null });
    };
    setAsCompleted = () => {
        const { purchase, replacePurchase } = this.props;

        this.startLoading();

        let params = {
            status: "COMPLETED"
        };

        api.purchases
            .updateStatus(purchase._id, params)
            .then((res) => {
                replacePurchase(res.data.purchase);
                this.endLoading();
            })
            .catch((err) => {
                Sentry.captureException(err);
                window.alert(err.response.data.message);
                this.endLoading();
            });
    };
    markAsReceived = () => {
        const { purchase, replacePurchase } = this.props;

        this.startLoading();

        let params = {
            status: "RECEIVED"
        };

        api.purchases
            .updateStatus(purchase._id, params)
            .then((res) => {
                replacePurchase(res.data.purchase);
                this.endLoading();
            })
            .catch((err) => {
                Sentry.captureException(err);
                window.alert(err.response.data.message);
                this.endLoading();
            });
    };
    finishEarly = () => {
        const { purchase, replacePurchase } = this.props;
        const { selectedPickupTime } = this.state;

        this.startLoading();

        let params = {
            status: "COMPLETED",
            pickupTimeDuration: selectedPickupTime,
            finishedEarly: true
        };

        api.purchases
            .updateStatus(purchase._id, params)
            .then((res) => {
                replacePurchase(res.data.purchase);
                this.endLoading();
            })
            .catch((err) => {
                Sentry.captureException(err);
                window.alert(err.response.data.message);
                this.endLoading();
            });
    };
    delayPickup = (delayTime: string) => {
        const { purchase, replacePurchase } = this.props;

        this.setState({ delayTime: delayTime });
        this.startLoading();
        let params = {
            status: "STARTED",
            pickupTimeDuration: purchase.pickupTimeDuration,
            isDelayed: true,
            delayDuration: delayTime,
            finishEarly: purchase.finishedEarly
        };

        api.purchases
            .updateStatus(purchase._id, params)
            .then((res) => {
                replacePurchase(res.data.purchase);
                this.endLoading();
            })
            .catch((err) => {
                Sentry.captureException(err);
                window.alert(err.response.data.message);
                this.endLoading();
            });
    };
    handlePressStart = () => {
        const { purchase, replacePurchase } = this.props;
        const { selectedPickupTime } = this.state;

        this.startLoading();

        let params = {
            status: "STARTED",
            pickupTimeDuration: selectedPickupTime,
            isDelayed: false,
            delayTime: purchase.delayDuration,
            finishEarly: purchase.finishedEarly
        };

        api.purchases
            .updateStatus(purchase._id, params)
            .then((res) => {
                replacePurchase(res.data.purchase);
                this.endLoading();
            })
            .catch((err) => {
                Sentry.captureException(err);
                window.alert(err.response.data.message);
                this.endLoading();
            });
    };
    handlePressTime = (time: string) => {
        this.setState({ selectedPickupTime: parseInt(time) }, () => {
            this.handlePressStart();
        });
    };
    getPurchaseStatus = (purchase: IPurchase) => {
        let status = purchase.status.length
            ? purchase.status[purchase.status.length - 1].type
            : null;

        // Check if purchase has been marked received ("received" status is legacy)
        if (!status && purchase.received) {
            status = PurchaseStatusEnum.received;
        }
        return status;
    };

    _body = () => {
        const { purchase } = this.props;

        const status = this.getPurchaseStatus(purchase);
        const refundSection = purchase.partialRefund && (
            <Text color={Colors.red} style={{ marginBottom: 10 }}>
                PARTIALLY REFUNDED{" "}
                {Helpers.toDollar(_.get(purchase, "refundedAmount", 0))}
            </Text>
        );

        if (!status) {
            return (
                <>
                    {refundSection}
                    <Button
                        label={"Mark as received"}
                        onPress={this.markAsReceived}
                        style={{ width: 200 }}
                    />
                </>
            );
        } else if (status === "RECEIVED") {
            return (
                <View>
                    {refundSection}
                    <StartOrderTimes
                        purchase={purchase}
                        handlePressTime={this.handlePressTime}
                    />
                </View>
            );
        }

        // If purchase status is STARTED or COMPLETED
        return (
            <View>
                {refundSection}
                <Completed
                    finishEarly={this.finishEarly}
                    setAsCompleted={this.setAsCompleted}
                    delayPickup={this.delayPickup}
                    purchase={purchase}
                />
            </View>
        );
    };
    render() {
        const { purchase } = this.props;
        const { loading } = this.state;

        let status = this.getPurchaseStatus(purchase);
        if (loading) {
            return (
                <div
                    style={{
                        padding: 30,
                        justifyContent: "center",
                        alignSelf: "center"
                    }}
                >
                    <Text>loading...</Text>
                    <Loader
                        type="Oval"
                        color="#00BFFF"
                        height={60}
                        width={60}
                    />
                </div>
            );
        }

        if (purchase.refund && !purchase.partialRefund) {
            return (
                <View>
                    <Text color={Colors.red}>
                        FULLY REFUNDED{" "}
                        {Helpers.toDollar(_.get(purchase, "refundedAmount", 0))}
                    </Text>
                    <Text style={{ marginTop: 30 }} color={Colors.gray}>
                        Reason: {purchase.refundReason}
                    </Text>
                </View>
            );
        } else if (purchase.submittedDisputeEvidence && purchase.dispute) {
            return (
                <View>
                    <Text color={Colors.red}>DISPUTED ⚔️</Text>
                </View>
            );
        }

        return (
            <View>
                <View style={ComponentStyles.container}>
                    <Text>
                        {"Purchase Status: "}
                        <Text color={Colors.blue}>
                            {status
                                ? status
                                : purchase.batchKey
                                ? "BATCHED"
                                : "NOT RECEIVED"}
                        </Text>
                    </Text>
                    {purchase.isDelayed && (
                        <Text
                            color={Colors.red}
                        >{`Delayed ${purchase.delayDuration} min`}</Text>
                    )}
                </View>
                <View style={{ padding: 10 }}>{this._body()}</View>
            </View>
        );
    }
}

let ComponentStyles = {
    container: {
        flexDirection: "row" as "row",
        justifyContent: "space-between"
    }
};

const mapStateToProps = (state: any) => {
    return {};
};

// create handler than dispatches an action
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        replacePurchase: (purchase: IPurchase) => {
            dispatch(replacePurchase(purchase));
        },
        dispatch
    };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(PurchaseStatus);

export default Container;
