export interface FormValues {
    template: string | null;
    name: string | null;
    type: string | null;
    zone: string[];
    hide: string[];
    description: string | null;
    category: string | null;
    droppedByText: string[] | null;
    rarity: string | null;
    thumbnail: string | null;
    platformOffset: string | undefined;
    asset: {
        rig: {
            name: string | null;
        };
        alternateRigs: {
            "PLATFORM_-1": { name: string | null };
            "PLATFORM_-2": { name: string | null };
            "PLATFORM_-3": { name: string | null };
        };
        material: {
            type: string | null;
            map: string | null;
            gradientMask: string | null;
            colors: string[];
            rgbMask: string | null;
            rcolors: string[];
            gcolors: string[];
            bcolors: string[];
            decalMask: string | null;
            decalColor: string | null;
            alphaMask: string | null;
        };
    };
    price: number | null;
    tab: string | null;
    isUnlockable: boolean;
    isActive: boolean;
    createdAt: Date | null;
    releaseDate: Date | null;
}

export const TOCHIITEM_FORM_TEMPLATE: FormValues = {
    template: null,
    name: null,
    type: null,
    zone: [],
    hide: [],
    description: null,
    category: null,
    droppedByText: [],
    rarity: null,
    thumbnail: null,
    platformOffset: undefined,
    asset: {
        rig: {
            name: null,
        },
        alternateRigs: {
            "PLATFORM_-1": { name: null },
            "PLATFORM_-2": { name: null },
            "PLATFORM_-3": { name: null },
        },
        material: {
            type: null,
            map: null,
            gradientMask: null,
            colors: [],
            rgbMask: null,
            rcolors: [],
            gcolors: [],
            bcolors: [],
            decalMask: null,
            decalColor: null,
            alphaMask: null,
        },
    },
    price: null,
    tab: null,
    isUnlockable: false,
    isActive: false,
    createdAt: null,
    releaseDate: null,
};
