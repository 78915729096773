import Login from "./Login";
import { connect } from "react-redux";
import { StoreState } from "../../redux/types";
import { Dispatch } from "redux";

function mapStateToProps(state: StoreState) {
    return {};
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
