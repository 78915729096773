import { Colors } from "./Colors";

export const container = {
    margin: 50,
};

export const shadow = {
    boxShadow: "2px 2px 2px 2px rgba(0,0,0,.2)",
};

export const shadowLight = {
    boxShadow: "0 2px 4px 0 rgba(211,211,211,0.50)",
};

export const panel = {
    padding: 20,
    backgroundColor: Colors.white,
    borderRadius: 8,
    ...shadowLight,
    flex: 1,
    alignItems: "center",
    alignSelf: "center",
};

export const featureImage = {
    width: 200,
    height: 144,
    borderRadius: 3,
    backgroundSize: "cover",
    backgroundPosition: "center",
    overflow: "hidden",
};

export const flexRow = {
    flexDirection: "row" as "row",
    alignItems: "center" as "center",
    justifyContent: "center" as "center",
};

export const flexCol = {
    flexDirection: "column" as "column",
    alignItems: "center" as "center",
    justifyContent: "center" as "center",
};

export const row = {
    padding: 10,
    borderWidth: 0,
    flexDirection: "row" as "row",
};
