import React, { useState, CSSProperties } from "react";
import ReactDropzone from "react-dropzone";
import _ from "lodash";
import api from "../../../../api";
import Swal from "sweetalert2";
import Loader from "react-loader-spinner";
import Table from "react-table";

import { TextInput, Button, Toggle, Text } from "../../../../SharedComponents";

const MAX_FILE_SIZE = 1000 * 1000; // 1 mb
const ALLOWED_FILE_TYPES = [".glb", ".json"];
const ALLOW_MULTIPLE_FILES = true;

const TochiFileUpload = () => {
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState<File[]>([]);
    const [version, setVersion] = useState("");
    const [create, setCreate] = useState(false);
    const [overwrite, setOverwrite] = useState(false);

    const handleDrop = (newFiles: File[]) => {
        let currentFiles: File[] = files.map(file => file);

        const filterDuplicates = (currentFiles: File[], filesToAdd: File[]) => {
            let names: string[] = currentFiles.map(file => file.name);
            return filesToAdd.filter(file => !names.includes(file.name));
        };

        currentFiles.push(...filterDuplicates(currentFiles, newFiles));

        setFiles(currentFiles);
    };

    const handleChange = (value: string) => {
        setVersion(value);
    };

    const handleOverwrite = () => {
        if (overwrite) {
            setOverwrite(false);
        } else {
            Swal.fire({
                type: "warning",
                title: `Are you sure you want to overwrite current file versions of ${
                    version || "(no version entered)"
                }?`,
                text: "Do NOT enable if you don't know exactly what this is. It will make things very very bad.",
                showCancelButton: true,
                cancelButtonText: "I don't know what I'm doing",
                confirmButtonText: "Overwrite",
            }).then(result => {
                if (result.value) {
                    Swal.fire({
                        type: "warning",
                        title: "Overwrite Enabled",
                        text: `files with version ${
                            version || "(no version entered)"
                        } will be written over`,
                    });
                    setOverwrite(true);
                }
            });
        }
    };

    const handleCreateNew = () => {
        if (create) {
            setCreate(false);
        } else {
            Swal.fire({
                type: "warning",
                title: "Are you sure you want to create new TochiFiles?",
                text: `This will create new TochiFiles when a matching version number ${
                    version || "(no version entered)"
                } does not exist.`,
                showCancelButton: true,
                cancelButtonText: "No I don't",
                confirmButtonText: "Create Files",
            }).then(result => {
                if (result.value) {
                    Swal.fire({
                        type: "success",
                        title: "Files will be created",
                        text: `files with version ${
                            version || "(no version entered)"
                        } will be created for unmatched files`,
                    });
                    setCreate(true);
                }
            });
        }
    };

    const versionIsFormatted = () => {
        let versionRegEx: RegExp = /\d+-\d+/;
        return versionRegEx.test(version);
    };

    const handleSubmit = async () => {
        if (!versionIsFormatted()) {
            Swal.fire({
                title: "Version formatted incorrectly",
                text: "please enter version number in the format 'X-Y'",
                type: "error",
            });
            return;
        }

        let formData = new FormData();
        for (let file of files) {
            if (MAX_FILE_SIZE < file.size) {
                alert("CANNOT UPLOAD FILE");
                Swal.fire({
                    title: "Cannot Upload File",
                    text: `Max file size is ${
                        MAX_FILE_SIZE / 1000
                    } kb, but yours is ${file.size / 1000} kb.`,
                    type: "error",
                });
                // Throw an error and block upload if file is too big
                return;
            }

            // Create form data and upload file to server
            formData.append("files", file);
        }

        try {
            setLoading(true);
            await api.tochiFiles.upload(
                formData,
                "tochi",
                version,
                overwrite,
                create
            );
            setFiles([]);
            setOverwrite(false);
            setCreate(false);
            //clear text input box
            Swal.fire({
                title: "Success!",
                text: "Files have been uploaded",
                type: "success",
            });
        } catch (err) {
            Swal.fire({
                title: "Error occurred uploading.",
                text: _.get(err, "response.data.message", "Unknown error."),
                type: "error",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        Swal.fire({
            title: "Are you sure you want to clear the queue?",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
        }).then(result => {
            if (result.value) {
                setFiles([]);
                setOverwrite(false);
                setCreate(false);
                Swal.fire({
                    title: "Queue cleared",
                });
            }
        });
    };

    const renderDropMessage = () => {
        if (loading) {
            return (
                <span>
                    Loading <br />
                    <Loader
                        type="Oval"
                        color="#00BFFF"
                        height={80}
                        width={100}
                    />
                </span>
            );
        }
        if (files.length > 0)
            return <p>Drop some more files here, or submit what you've got</p>;

        return <p>Drag 'n' drop some GLBs here, or click to select files</p>;
    };

    const renderTable = () => {
        if (files.length > 0) {
            return (
                <div>
                    <Table
                        data={files}
                        style={styles.queueTable}
                        defaultPageSize={5}
                        freezeWhenExpanded={true}
                        columns={[
                            {
                                Header: "Files in queue",
                                accessor: "name",
                                id: "filesForUpload",
                                minWidth: 125,
                            },
                        ]}
                    />
                </div>
            );
        }
        return;
    };

    return (
        <div>
            <ReactDropzone
                accept={ALLOWED_FILE_TYPES}
                multiple={ALLOW_MULTIPLE_FILES}
                onDrop={handleDrop}
            >
                {({ getRootProps, getInputProps }) => (
                    <section style={styles.dropzone} {...getRootProps()}>
                        <input {...getInputProps()} />
                        {renderDropMessage()}
                    </section>
                )}
            </ReactDropzone>
            <div>{renderTable()}</div>
            <div style={styles.bottomBar}>
                <Text style={styles.version}>Version #: </Text>
                <TextInput
                    placeholder="eg. 0-2"
                    onChangeText={handleChange}
                    style={styles.version}
                />
                <div style={styles.toggleGroup}>
                    <div style={styles.toggle}>
                        <Toggle onToggle={handleOverwrite} value={overwrite} />
                        <Text style={styles.toggleText}>
                            Overwrite current version
                        </Text>
                    </div>
                    <div style={styles.toggle}>
                        <Toggle onToggle={handleCreateNew} value={create} />
                        <Text style={styles.toggleText}>
                            Create new tochiFiles if needed
                        </Text>
                    </div>
                </div>
                <Button
                    label="cancel"
                    onPress={handleCancel}
                    style={styles.button}
                />
                <Button
                    label="submit"
                    onPress={handleSubmit}
                    style={styles.button}
                />
            </div>
        </div>
    );
};

const styles = {
    bottomBar: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    } as CSSProperties,
    button: { margin: 10 } as CSSProperties,
    dropzone: {
        padding: 50,
        backgroundColor: "#f5f5f5",
        borderRadius: 5,
        textAlign: "center",
        border: "2px solid #4d4d4d",
    } as CSSProperties,
    queueTable: {
        textAlign: "center",
        marginTop: 15,
    } as CSSProperties,
    toggle: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    } as CSSProperties,
    toggleGroup: { marginLeft: 25, marginRight: 25 } as CSSProperties,
    toggleText: { marginLeft: 15 } as CSSProperties,
    version: { margin: 10 } as CSSProperties,
};
export { TochiFileUpload };
