import React, { Component } from "react";
import { Text } from "./Text";
import { TextInput } from "./TextInput";
import { TouchableHighlight } from "./TouchableHighlight";
import * as Helpers from "../utils/Helpers";
import { Styles, Colors } from "../utils";

type Props = {
    value: any;
    onSubmit: (value: string) => void;
    placeholder?: string;
    number?: boolean;
    dollar?: boolean;
    small?: boolean;
    xsmall?: boolean;
    toggleShowSubmitButton?: (value: boolean) => void;
    showPlaceholder?: string;
};
type State = {
    editing: boolean;
    value: string;
};

export class EditBlock extends Component<Props, State> {
    state = {
        editing: false,
        value: ""
    };

    componentWillReceiveProps = (nextProps: Props, prevProps: Props) => {
        try {
            if (prevProps.value !== nextProps.value && this.state.editing) {
                this.setState({ value: nextProps.value });
                this.toggleEditing();
            }
        } catch (error) {
            console.log(error);
        }
    };

    componentDidMount() {
        this.setState({ value: this.props.value });
    }

    toggleEditing = () => {
        if (this.props.toggleShowSubmitButton) {
            let toggleSubmit = !this.state.editing ? false : true;
            this.props.toggleShowSubmitButton(toggleSubmit);
        }
        this.setState({ editing: !this.state.editing });
    };

    handleChange = (value: string) => this.setState({ value });

    handleSubmit = () => {
        this.toggleEditing();
        this.props.onSubmit(this.state.value);
    };

    handleCancel = () => this.toggleEditing();

    render() {
        const { value, number, dollar, placeholder, showPlaceholder } =
            this.props;

        if (this.state.editing) {
            return (
                <TextInput
                    placeholder={placeholder}
                    number={number || false}
                    onChangeText={this.handleChange}
                    value={this.state.value}
                    onSubmit={this.handleSubmit}
                    onEscape={this.handleCancel}
                />
            );
        }

        let children;
        if (showPlaceholder) {
            children = value ? value : showPlaceholder;
        } else {
            children = dollar ? Helpers.toDollar(value) : value;
        }

        return (
            <TouchableHighlight
                onPress={this.toggleEditing}
                style={{ maxWidth: 400, ...Styles.flexRow }}
            >
                <Text
                    {...this.props}
                    center
                    color={
                        !value && showPlaceholder
                            ? Colors.warmGrey
                            : Colors.text
                    }
                >
                    {children || "No value"}
                </Text>
            </TouchableHighlight>
        );
    }
}
