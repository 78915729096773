import React, { Component, CSSProperties } from "react";
import { Alert } from "react-bootstrap";

// CAC content has been moved to Looker
class NoMoreCAC extends Component {
    render() {
        return (
            <div style={styles.container}>
                <Alert>
                    CAC content has been moved to&nbsp;
                    <a
                        style={styles.link}
                        target="__blank"
                        href="https://snackpass.looker.com/dashboards/51?Type=&filter_config=%7B%22Type%22:%5B%7B%22type%22:%22%3D%22,%22values%22:%5B%7B%22constant%22:%22%22%7D,%7B%7D%5D,%22id%22:2%7D%5D%7D"
                    >
                        Looker
                    </a>
                </Alert>
            </div>
        );
    }
}
export default NoMoreCAC;

const styles = {
    container: {
        margin: 30,
        display: "flex",
        justifyContent: "center",
    } as CSSProperties,
    link: {
        color: "#072c33",
        fontWeight: "bold",
    } as CSSProperties,
};
