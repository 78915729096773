import { IGiftCard } from "@snackpass/snackpass-types";
import { gql } from "graphql-request";
import { GQL } from "src/api/graphql/client";
import { GiftCard } from "./fragments";
class GiftCards {
    static async giftCardsByStore(storeId: string): Promise<IGiftCard[]> {
        const query = gql`
            ${GiftCard}
            query giftCardsByStore($storeId: ID!) {
                giftCardsByStore(storeId: $storeId) {
                    ...GiftCard
                }
            }
        `;

        try {
            const { giftCardsByStore } = await GQL.request(query, { storeId });
            return giftCardsByStore;
        } catch (error) {
            throw new Error(error);
        }
    }

    static refundGiftCard = async (giftCardId: string) => {
        const query = gql`
            ${GiftCard}
            mutation refundGiftCard($giftCardId: ID!) {
                refundGiftCard(id: $giftCardId) {
                    ...GiftCard
                }
            }
        `;

        try {
            const { refundGiftCard } = await GQL.request(query, { giftCardId });
            return refundGiftCard;
        } catch (error) {
            throw new Error(error);
        }
    };
}
export default GiftCards;
