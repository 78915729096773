import * as Sentry from "@sentry/browser";
import {
    IRegion,
    IUser,
    ReferralChannel,
    ReferralChannelType
} from "@snackpass/snackpass-types";
import { formatNumber, NumberFormat, CountryCode } from "libphonenumber-js";
import _ from "lodash";
import moment from "moment";
import { Component } from "react";
import Select from "react-select";
import RippleButton from "src/SharedComponents/RippleButton";
import swal from "sweetalert2";

import API from "../../api";
import GiftBuilder from "../../containers/GiftBuilder";
import { Item } from "../../redux/types";
import {
    Button,
    EditBlock,
    FormRow,
    RowCustomInput,
    RowInput,
    Text,
    Toggle,
    View,
    Image
} from "../../SharedComponents";
import { Colors } from "../../utils";
import * as Styles from "../../utils/Styles";
import { BanUserReasons } from "./components/BanUserReasons";

type Props = {
    user: IUser;
    setActiveUser: Function;
    regions: IRegion[];
};
type State = {
    showMore: boolean;
    isBanned: boolean;
    canDeviceBeBanned: boolean;
    isAskForBanReasonModalOpen: boolean;
    referrerName: string;
};
export default class UserPreview extends Component<Props, State> {
    state = {
        showMore: false,
        isBanned: false,
        canDeviceBeBanned: false,
        isAskForBanReasonModalOpen: false,
        referrerName: "N/A"
    };
    componentWillReceiveProps = async (nextProps: Props) => {
        try {
            const { user } = nextProps;
            const res = await API.devices.getIsDeviceBanned(user._id);
            const isBanned = res.data.isBanned;
            const canDeviceBeBanned = res.data.canDeviceBeBanned;
            const referrerName = await this.getReferred(user);
            this.setState({ isBanned, canDeviceBeBanned, referrerName });
        } catch (error) {
            console.log(error);
        }
    };

    getReferred = async (user: IUser) => {
        try {
            if (
                user.referredFromId &&
                typeof user.referredFromId === "string"
            ) {
                const referrerRes = await API.users.getOne(user.referredFromId);
                return `${referrerRes.data.user.name} (${referrerRes.data.user.username})`;
            } else {
                return "N/A";
            }
        } catch (error) {
            console.log(error);
            return "N/A";
        }
    };

    handleSubmitCredit = (value: string) => {
        const { user, setActiveUser } = this.props;
        let update = {
            user: {
                credit: parseFloat(value)
            },
            communicationPreferences: []
        };
        API.users
            .update(user._id, update)
            .then((response) => {
                setActiveUser(response.data.user);
            })
            .catch((error) => {
                Sentry.captureException(error);
            });
    };
    /**
     * Abstraction for any number input
     */
    handleSubmitNumberValue = (value: string, key: string) => {
        const { user, setActiveUser } = this.props;
        let update = {
            user: {
                [key]: parseFloat(value)
            },
            communicationPreferences: []
        };
        API.users
            .update(user._id, update)
            .then((response) => {
                setActiveUser(response.data.user);
            })
            .catch((error) => {
                Sentry.captureException(error);
            });
    };
    /**
     * Abstraction for any toggle
     */
    handleToggle = (key: string) => {
        const { user, setActiveUser } = this.props;
        //@ts-ignore
        let previousValue = user[key] ? true : false;
        let update = {
            user: {
                [key]: !previousValue
            },
            communicationPreferences: []
        };
        API.users
            .update(user._id, update)
            .then((response) => {
                setActiveUser(response.data.user);
            })
            .catch((error) => {
                Sentry.captureException(error);
            });
    };

    handleSubmitPhoneNumber = (value: any) => {
        const { user, setActiveUser } = this.props;

        let updateValue = value ? value : null;
        let update = {
            user: {
                number: updateValue
            },
            communicationPreferences: []
        };

        API.users
            .update(user._id, update)
            .then((response) => {
                setActiveUser(response.data.user);
            })
            .catch((error) => {
                Sentry.captureException(error);

                alert(
                    "There was an trying to update this user's phone number. It is possible that the phone number is already taken by a different user in firebase or mongo."
                );
            });
    };

    handleSubmitEmail = (value: any) => {
        const { user, setActiveUser } = this.props;
        API.users
            .updateAccountEmails(user.uid ?? "", value)
            .then((response) => {
                setActiveUser(response.data.user);
            })
            .catch((error) => {
                Sentry.captureException(error);

                alert(
                    "There was an error trying to update this user's email. It is possible that the email is already taken by a different user in firebase or mongo."
                );
            });
    };

    handleToggleIsStudent = () => {
        const { user, setActiveUser } = this.props;

        let update = {
            user: {
                isStudent: !user.isStudent
            },
            communicationPreferences: []
        };
        API.users
            .update(user._id, update)
            .then((response) => {
                setActiveUser(response.data.user);
            })
            .catch((error) => {
                Sentry.captureException(error);
            });
    };

    handleToggleTestEnabled = () => {
        const { user, setActiveUser } = this.props;

        let update = {
            user: {
                test: {
                    ...user.test,
                    testEnabled: !user.test.testEnabled
                }
            },
            communicationPreferences: []
        };

        API.users
            .update(user._id, update)
            .then((response) => {
                setActiveUser(response.data.user);
            })
            .catch((error) => {
                Sentry.captureException(error);
            });
    };

    handleToggleSuperCodeEnabled = () => {
        const { user, setActiveUser } = this.props;
        let superCodeEnabled = _.get(user, "superCodeEnabled", false);

        let update = {
            user: {
                superCodeEnabled: !superCodeEnabled
            },
            communicationPreferences: []
        };

        API.users
            .update(user._id, update)
            .then((response) => {
                setActiveUser(response.data.user);
            })
            .catch((error) => {
                Sentry.captureException(error);
            });
    };

    handleToggleAccountManager = () => {
        const { user, setActiveUser } = this.props;

        let update = {
            user: {
                snackpassPermissions: {
                    isAccountManager:
                        !user.snackpassPermissions.isAccountManager
                }
            },
            communicationPreferences: []
        };

        API.users
            .update(user._id, update)
            .then((response) => {
                setActiveUser(response.data.user);
            })
            .catch((error) => {
                Sentry.captureException(error);
            });
    };

    handleToggleAccountExecutive = () => {
        const { user, setActiveUser } = this.props;

        let update = {
            user: {
                snackpassPermissions: {
                    isAccountExecutive:
                        !user.snackpassPermissions.isAccountExecutive
                }
            },
            communicationPreferences: []
        };

        API.users
            .update(user._id, update)
            .then((response) => {
                setActiveUser(response.data.user);
            })
            .catch((error) => {
                Sentry.captureException(error);
            });
    };

    handleAlertFeedbackAfterUpdatingBannedStatus = (isBanned: boolean) => {
        this.setState({ isAskForBanReasonModalOpen: false });
        swal.fire({
            type: "success",
            text: `Successfully ${isBanned ? "banned" : "unbanned"} the user.`
        });
    };

    handleToggleBanned = async () => {
        const { user, setActiveUser } = this.props;

        try {
            if (user.isBanned) {
                // unban the user
                const res = await API.users.banOrUnban(user._id, false);
                const updatedUser = res.data.user;
                setActiveUser(updatedUser);
                this.handleAlertFeedbackAfterUpdatingBannedStatus(false);
            } else {
                // will show modal asking why user should be banned (the modal handles all logic)
                this.setState({ isAskForBanReasonModalOpen: true });
            }
        } catch (error) {
            Sentry.captureException(error);
        }
    };

    handleToggleRippleEnabled = () => {
        const { user, setActiveUser } = this.props;
        let rippleEnabled = user.rippleEnabled ? user.rippleEnabled : false;
        let update = {
            user: {
                rippleEnabled: !rippleEnabled
            },
            communicationPreferences: []
        };
        API.users
            .update(user._id, update)
            .then((response) => {
                setActiveUser(response.data.user);
            })
            .catch((error) => {
                Sentry.captureException(error);
            });
    };

    handleToggleTestRegion = (item: Item) => {
        const { user, setActiveUser } = this.props;

        let update = {
            user: {
                test: {
                    ...user.test,
                    region: item.value
                }
            },
            communicationPreferences: []
        };

        API.users
            .update(user._id, update)
            .then((response) => {
                setActiveUser(response.data.user);
            })
            .catch((error) => {
                Sentry.captureException(error);
            });
    };

    handleReferralChannelType = (referralChannelType: ReferralChannelType) => {
        const { user, setActiveUser } = this.props;

        let update = {
            user: {
                referralChannelType
            },
            communicationPreferences: []
        };

        API.users
            .update(user._id, update)
            .then((response) => {
                setActiveUser(response.data.user);
            })
            .catch((error) => {
                Sentry.captureException(error);
            });
    };

    handleToggleNumberVerified = () => {
        const { user, setActiveUser } = this.props;
        let numberVerified = user.numberVerified ? user.numberVerified : false;
        let update = {
            user: {
                numberVerified: !numberVerified
            },
            communicationPreferences: []
        };
        API.users
            .update(user._id, update)
            .then((response) => {
                setActiveUser(response.data.user);
            })
            .catch((error) => {
                Sentry.captureException(error);
            });
    };
    handleUpdateUser = (updates: Object) => {
        const { user, setActiveUser } = this.props;

        const transformedUpdates = {
            user: updates,
            communicationPreferences: []
        };

        API.users.update(user._id, transformedUpdates).then((response) => {
            setActiveUser(response.data.user);
        });
    };
    handleToggleIsDeviceBanned = async () => {
        try {
            const { user } = this.props;
            const isBanned = this.state.isBanned;
            await API.devices.setIsDeviceBanned(user._id, !isBanned);
            this.setState({ isBanned: !isBanned });
        } catch (error) {
            Sentry.captureException(error);
        }
    };
    handleToggleTochiEnabled = async () => {
        try {
            const { user, setActiveUser } = this.props;
            const update = {
                user: {
                    featurePermissions: {
                        tochi: !user.featurePermissions.tochi
                    }
                },
                communicationPreferences: []
            };
            await API.users.update(user._id, update).then((res) => {
                setActiveUser(res.data.user);
            });
        } catch (error) {
            Sentry.captureException(error);
        }
    };
    handleGiftUserSnackcoin = async (snackcoin: string) => {
        try {
            const { user } = this.props;
            await API.tochiUsers
                .updateSnackcoin({ snackcoin: snackcoin }, user._id)
                .then((res) => {
                    swal.fire(
                        "Success",
                        `Gifted ${user.firstName} ${snackcoin} Snackcoin!`,
                        "success"
                    );
                });
        } catch (error) {
            Sentry.captureException(error);
            console.log(error);
        }
    };
    /**
     * @param birthdayString A birthday string formatted as mm/dd/yyyy
     */
    handleUpdateBirthday = (birthdayString: string) => {
        let { user, setActiveUser } = this.props;
        let timestamp = Date.parse(birthdayString);

        // If the user put a birthday
        // and it isn't valid show an error message
        if (
            birthdayString &&
            (isNaN(timestamp) || birthdayString.split("/").length !== 3)
        ) {
            return swal.fire(
                "Invalid Birthday",
                "Birthdays must be formatted mm/dd/yyyy",
                "error"
            );
        }

        // If user put a new birthday, create a Date object from it
        // otherwise set it to null (unset the birthday)
        let birthday = birthdayString ? new Date(timestamp) : null;

        // convert birthday to the date entered but at midnight UTC
        if (birthday) {
            let day = birthday.getDate();
            let month = birthday.getMonth();
            let year = birthday.getFullYear();
            birthday = new Date(Date.UTC(year, month, day, 0, 0, 0));
        }

        API.users
            .update(user._id, {
                user: {
                    birthday
                },
                communicationPreferences: []
            })
            .then((response) => {
                setActiveUser(response.data.user);
            })
            .catch((error) => {
                Sentry.captureException(error);
            });
    };
    markEmailAasVerified = async () => {
        let { user, setActiveUser } = this.props;
        try {
            let result = await swal.fire({
                text: "If the user has already verified their email, this won't do anything. But if they haven't, it will manually mark their email as verified. If this is okay, press the yes button.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: Colors.green,
                cancelButtonColor: Colors.gray,
                confirmButtonText: "Yes, verify it!",
                cancelButtonText: "Cancel"
            });

            if (!result.value) {
                return;
            }

            let res = await API.users.update(user._id, {
                user: {
                    emailVerified: true
                },
                communicationPreferences: []
            });
            setActiveUser(res.data.user);
        } catch (err) {
            Sentry.captureException(err);
        }
    };
    deletePhoneNumber = async () => {
        // Pass a null to the backend, where it sets the field to undefined
        this.handleSubmitPhoneNumber(null);
    };
    render() {
        const { user } = this.props;
        const { showMore } = this.state;
        if (!user._id) {
            return null;
        }
        const readOnlyFieldsTop = [
            { key: "name" },
            { key: "_id", desc: "MongoDB ID" },
            { key: "uid", desc: "Firebase ID" },
            { key: "purchaseCount" }
        ];
        const readOnlyFields = [
            "firstName",
            "lastName",
            "username",
            "address",
            "geolocationPermission",
            "notificationPermission",
            "inboxUnread",
            "chickenInboxUnread",
            "lastRegion",
            "playerId"
        ];
        let Email = (
            <RowCustomInput label="email">
                <EditBlock
                    small
                    value={user.email || ""}
                    onSubmit={(value) => this.handleSubmitEmail(value)}
                    showPlaceholder={"enter email"}
                />
            </RowCustomInput>
        );
        let PhoneNumber = (
            <RowCustomInput label="number">
                {user.countryCode && user.countryCode !== "US" && (
                    <span style={{ color: "red", marginRight: "4px" }}>
                        Non-US Number:
                    </span>
                )}
                <EditBlock
                    small
                    number
                    value={
                        user.number
                            ? user.countryCode && user.countryCode !== "US"
                                ? formatNumber(
                                      user.number,
                                      user.countryCode as CountryCode,
                                      "INTERNATIONAL"
                                  )
                                : formatNumber(
                                      user.number,
                                      "National" as NumberFormat
                                  )
                            : ""
                    }
                    onSubmit={(value) => {
                        this.handleSubmitPhoneNumber(value);
                    }}
                    showPlaceholder={"enter phone number"}
                />
                <Button label="Delete" onPress={this.deletePhoneNumber} />
            </RowCustomInput>
        );
        let Credit = (
            <RowCustomInput label="Credit 💰">
                <EditBlock
                    small
                    number
                    dollar
                    value={user.credit || 0}
                    onSubmit={(value) => {
                        if (!value) {
                            value = "0";
                        }
                        this.handleSubmitCredit(value);
                    }}
                />
            </RowCustomInput>
        );
        const Code = (
            <RowCustomInput label="referral code">
                <EditBlock
                    small
                    value={user.code}
                    onSubmit={(code) => {
                        this.handleUpdateUser({ code });
                    }}
                />
            </RowCustomInput>
        );
        const Username = (
            <RowCustomInput label="username">
                <EditBlock
                    small
                    value={user.username}
                    onSubmit={(username) => {
                        this.handleUpdateUser({ username });
                    }}
                />
            </RowCustomInput>
        );
        const FirstName = (
            <RowCustomInput label="first name">
                <EditBlock
                    small
                    value={user.firstName}
                    onSubmit={(firstName) => {
                        this.handleUpdateUser({ firstName });
                    }}
                />
            </RowCustomInput>
        );
        const LastName = (
            <RowCustomInput label="last name">
                <EditBlock
                    small
                    value={user.lastName}
                    onSubmit={(lastName) => {
                        this.handleUpdateUser({ lastName });
                    }}
                />
            </RowCustomInput>
        );
        const Birthday = (
            <RowCustomInput label="Birthday">
                <EditBlock
                    placeholder="mm/dd/yyyy"
                    small
                    value={
                        user.birthday
                            ? moment(user.birthday).utc().format("MM/DD/YYYY")
                            : "None"
                    }
                    onSubmit={(birthday) => {
                        this.handleUpdateBirthday(birthday);
                    }}
                />
            </RowCustomInput>
        );
        let EmailVerified = (
            <RowCustomInput
                label="Verify email"
                description={`Manually mark a user's email as verified. This doesn't send an email to ${user.name}.`}
            >
                <Button label="Verify" onPress={this.markEmailAasVerified} />
            </RowCustomInput>
        );
        let IsStudent = (
            <RowCustomInput label="Is Student">
                <Toggle
                    value={user.isStudent}
                    onToggle={this.handleToggleIsStudent}
                />
            </RowCustomInput>
        );
        let NumberVerified = (
            <RowCustomInput label="Number Verified">
                <Toggle
                    value={user.numberVerified}
                    onToggle={this.handleToggleNumberVerified}
                />
            </RowCustomInput>
        );
        let testEnabled = (
            <RowCustomInput label="Test Enabled">
                <Toggle
                    value={user.test.testEnabled}
                    onToggle={this.handleToggleTestEnabled}
                />
            </RowCustomInput>
        );

        let isSuperUser = (
            <RowCustomInput label=" is Super Code User">
                <Toggle
                    value={user.superCodeEnabled}
                    onToggle={this.handleToggleSuperCodeEnabled}
                />
            </RowCustomInput>
        );
        const isAccountManager = (
            <RowCustomInput label="Is Account Manager">
                <Toggle
                    value={user.snackpassPermissions.isAccountManager}
                    onToggle={this.handleToggleAccountManager}
                />
            </RowCustomInput>
        );
        const isAccountExecutive = (
            <RowCustomInput label="Is Account Executive">
                <Toggle
                    value={user.snackpassPermissions.isAccountExecutive}
                    onToggle={this.handleToggleAccountExecutive}
                />
            </RowCustomInput>
        );
        const IsReferralChannel = (
            <RowCustomInput label=" show referral channel on snackface">
                <Toggle
                    value={user.isReferralChannel}
                    onToggle={() => {
                        this.handleToggle("isReferralChannel");
                    }}
                />
            </RowCustomInput>
        );
        const ReferralChannelType = (
            <RowCustomInput label="Referral Channel Type">
                <Select
                    name="type"
                    placeholder="referral channel type"
                    options={Object.entries(ReferralChannel).map(
                        (referralChannelType) => ({
                            label: _.startCase(referralChannelType[0]),
                            value: referralChannelType[1]
                        })
                    )}
                    value={{
                        label: _.startCase(
                            _.camelCase(user.referralChannelType ?? "")
                        )
                    }}
                    className="snackpass__react-select"
                    onChange={({ value }: { value: ReferralChannelType }) =>
                        this.handleReferralChannelType(value)
                    }
                />
            </RowCustomInput>
        );
        let isUserBanned = (
            <RowCustomInput label="Is User Banned">
                <Toggle
                    value={user.isBanned}
                    onToggle={this.handleToggleBanned}
                />
                <BanUserReasons
                    activeUser={this.props.user}
                    sendAlertFeedback={
                        this.handleAlertFeedbackAfterUpdatingBannedStatus
                    }
                    isAlertOpen={this.state.isAskForBanReasonModalOpen}
                    forceCloseAlert={() =>
                        this.setState({ isAskForBanReasonModalOpen: false })
                    }
                />
            </RowCustomInput>
        );
        const banReason = (
            <RowCustomInput label="Ban Reason">
                <EditBlock
                    small
                    value={user.banReason}
                    onSubmit={(banReason) => {
                        this.handleUpdateUser({ banReason });
                    }}
                />
            </RowCustomInput>
        );
        const isDeviceBanned = (
            <RowCustomInput label="Is Device Banned">
                <Toggle
                    value={this.state.isBanned}
                    disabled={!this.state.canDeviceBeBanned}
                    onToggle={this.handleToggleIsDeviceBanned}
                />
            </RowCustomInput>
        );
        let testRegion = (
            <RowCustomInput label="Test Region">
                <Select
                    name="type"
                    placeholder="test region"
                    options={this.props.regions.map((r: IRegion) => ({
                        label: r.name,
                        value: r.slug
                    }))}
                    value={{ label: user.test.region }}
                    className="snackpass__react-select"
                    onInputChange={this.handleToggleTestRegion}
                    onChange={this.handleToggleTestRegion}
                />
            </RowCustomInput>
        );
        let SocialMediaPosts = (
            <RowInput
                percent
                label="# Social Media Posts"
                onChangeText={(numAmbassadorSocialMediaPosts) => {
                    this.handleUpdateUser({
                        numAmbassadorSocialMediaPosts
                    });
                }}
                description=""
                placeholder=""
                value={user.numAmbassadorSocialMediaPosts}
            />
        );
        let ReferrerName = (
            <FormRow
                required={false}
                key={"referrerName"}
                label={"Referred By User"}
                //@ts-ignore
                value={this.state.referrerName}
            />
        );
        // ripple enabled
        let rippleEnabled = (
            <RowCustomInput label="Ripple Enabled">
                <Toggle
                    value={user.rippleEnabled}
                    onToggle={() => {
                        this.handleToggle("rippleEnabled");
                    }}
                />
            </RowCustomInput>
        );
        let SourceRippleCredit = (
            <RowCustomInput
                label="Source Ripple Credit 💰"
                description="How much the referrer gets if ripple is enabled"
            >
                <RippleButton value={user.sourceRippleCredit} />
            </RowCustomInput>
        );
        let TargetRippleCredit = (
            <RowCustomInput
                label="Target Ripple Credit 💰"
                description="How much the referred gets if ripple is enabled"
            >
                <RippleButton value={user.targetRippleCredit} />
            </RowCustomInput>
        );
        const isTochiEnabled = (
            <RowCustomInput
                label="Tochi Enabled 🐣"
                description="Does this user have tochi enabled"
            >
                <Toggle
                    value={user.featurePermissions.tochi}
                    onToggle={this.handleToggleTochiEnabled}
                />
            </RowCustomInput>
        );
        const GiftUserSnackcoin = (
            <RowCustomInput
                label="Tochi Snackcoin"
                description="Gift this user some Snackcoin for tochi"
            >
                <Image
                    source={require("src/assets/icons/snackcoin.png")}
                    style={{ width: 25, marginRight: 10 }}
                />
                <EditBlock
                    small
                    number
                    value={"Enter Gift!"}
                    onSubmit={(value) => {
                        this.handleGiftUserSnackcoin(value);
                    }}
                />
            </RowCustomInput>
        );
        if (!user.isReferralChannel) {
            SocialMediaPosts = <span />;
        }
        let More;
        if (showMore) {
            More = (
                <div style={{ width: "100%" }}>
                    {readOnlyFields.map((field) => {
                        return (
                            <FormRow
                                required={false}
                                key={field}
                                label={field}
                                //@ts-ignore
                                value={user[field]}
                            />
                        );
                    })}
                </div>
            );
        }
        return (
            <View
                style={{
                    ...Styles.panel,
                    alignSelf: "flex-start"
                }}
            >
                <GiftBuilder />
                {readOnlyFieldsTop.map((field) => {
                    //@ts-ignore
                    let value = user[field.key];
                    return (
                        <View>
                            {field.desc && (
                                <Text
                                    style={{ fontSize: 13, color: Colors.gray }}
                                >
                                    {field.desc}
                                </Text>
                            )}
                            <FormRow
                                required={false}
                                key={field.key}
                                label={field.key}
                                value={value}
                            />
                        </View>
                    );
                })}
                {Email}
                {PhoneNumber}
                {Credit}
                {Username}
                {FirstName}
                {LastName}
                {Birthday}
                {Code}
                {ReferrerName}
                {EmailVerified}
                {IsStudent}
                {NumberVerified}
                {isSuperUser}
                {isAccountManager}
                {isAccountExecutive}
                {IsReferralChannel}
                {ReferralChannelType}
                {SocialMediaPosts}
                {isUserBanned}
                {user.isBanned && banReason}
                {isDeviceBanned}
                {testEnabled}
                {testRegion}
                {rippleEnabled}
                {SourceRippleCredit}
                {TargetRippleCredit}
                {isTochiEnabled}
                {GiftUserSnackcoin}
                {More}
                <div>
                    <Button
                        label={showMore ? "Hide" : "Show more"}
                        onPress={() => {
                            this.setState({ showMore: !this.state.showMore });
                        }}
                    />
                </div>
            </View>
        );
    }
}
