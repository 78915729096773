import { connect } from "react-redux";
import { setStore } from "../redux/stores";
import StorePauseModal from "../modals/StorePauseModal";
import { StoreState } from "../redux/types";
import { Dispatch } from "redux";
import { IStore } from "@snackpass/snackpass-types";

const mapStateToProps = (state: StoreState) => {
    return {};
};

// create handler than dispatches an action
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        dispatch,
        setStore: (store: IStore) => {
            dispatch(setStore(store));
        },
    };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(StorePauseModal);

export default Container;
