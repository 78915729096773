/**
 * This file declares all routes and which components
 * are displayed when a certain route is navigated to
 *
 * Also includes the top navigation bar which persists
 * in all of the pages.
 */
import * as Sentry from "@sentry/browser";
import _ from "lodash";
import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import API from "../api";
import LoadingScreen from "../components/LoadingScreen";
import MobileNavigationMenu from "../components/MobileNavigationMenu";
import NotAuthorized from "../components/NotAuthorized";
import Admins from "../containers/Admins";
import Promotions from "../containers/Promotions";
import GiftCards from "../containers/GiftCards";
import QrCodes from "../screens/QrCodes";
import PromotionsMetrics from "../containers/PromotionsMetrics";
import Purchases from "../containers/Purchases";
import Regions from "../containers/Regions";
import Stores from "../containers/Stores";
import StoreTablets from "../containers/StoreTablets";
import Users from "../containers/Users";
import VerticalNavigationMenu from "../containers/VerticalNavigationMenu";
import firebase from "../firebase";
import { fetchRegions } from "../redux/regions";
import { fetchStores } from "../redux/stores";
import { StoreState } from "../redux/types";
import { setUser } from "../redux/user";
import Accounting from "../screens/Accounting";
import Kiosks from "../screens/Kiosks";
import Login from "../screens/Login";
import RestaurantAirtable from "../screens/RestaurantAirtable";
import Sales from "../screens/Sales";
import TochiFiles from "../screens/Tochi/TochiFile";
import TochiItems from "../screens/Tochi/TochiItems";
import TochiMaterials from "../screens/Tochi/TochiMaterials";
import Chains from "../screens/Chains";
import StoreSubscriptionScreen from "../screens/StoreSubscription";
import Payouts from "../screens/Payouts";
import Printers from "../screens/Printers";
import { Constants } from "../utils";

type Props = {
    dispatch: Function;
    authorized: boolean;
    fetchStores: (query?: any) => Promise<void>;
    fetchRegions: (query?: any) => Promise<void>;
};
type State = {
    error?: any;
    user?: any;
    loaded: boolean;
};

const routes = [
    { path: "/stores", component: Stores },
    { path: "/admins", component: Admins },
    { path: "/store-tablets", component: StoreTablets },
    { path: "/purchases", component: Purchases },
    { path: "/purchases/:id", component: Purchases },
    { path: "/regions", component: Regions },
    { path: "/users", component: Users },
    { path: "/accounting", component: Accounting },
    { path: "/promotions", component: Promotions },
    { path: "/giftcards", component: GiftCards },
    { path: "/qrcodes", component: QrCodes },
    { path: "/cac", component: PromotionsMetrics },
    { path: "/sales", component: Sales },
    { path: "/restaurant_airtable", component: RestaurantAirtable },
    { path: "/kiosks", component: Kiosks },
    { path: "/printers", component: Printers },
    { path: "/chains", component: Chains },
    { path: "/payouts", component: Payouts },
    { path: "/tochi/items", component: TochiItems },
    { path: "/tochi/files", component: TochiFiles },
    { path: "/tochi/materials", component: TochiMaterials },
    { path: "/subscriptions", component: StoreSubscriptionScreen }
];

class Navigation extends Component<Props, State> {
    state = {
        user: null,
        loaded: false,
        error: null
    };
    componentDidCatch(error: any, errorInfo: any) {
        this.setState({ error });
        Sentry.withScope((scope) => {
            Object.keys(errorInfo).forEach((key) => {
                scope.setExtra(key, errorInfo[key]);
            });
            Sentry.captureException(error);
        });
    }

    sentryUser(user: any) {
        // Sentry to add metadata for user
        Sentry.configureScope((scope) => {
            scope.setUser({
                email: user.email,
                id: user.uid,
                uid: user.uid,
                name: user.name
            });
        });
    }

    componentDidMount() {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.sentryUser(user);
                // initial load
                setTimeout(async () => {
                    API.users.getMe().then((response) => {
                        this.props.dispatch(setUser(response.data.user));
                    });
                    this.props.fetchStores();
                    this.props.fetchRegions();
                }, 200);
                this.setState({ user });
            }
            this.setState({ loaded: true });
        });
    }

    getNavBar = () => {
        return (
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: isMobile ? "column" : "row",
                    backgroundColor: "#f7f8fa"
                }}
            >
                {isMobile ? (
                    <MobileNavigationMenu />
                ) : (
                    <div
                        className="menu"
                        style={{
                            width: Constants.VERTICAL_MENU_WIDTH,
                            margin: 10
                        }}
                    >
                        <VerticalNavigationMenu />
                    </div>
                )}

                <div
                    style={{
                        display: "display",
                        flex: 1,
                        margin: 10,
                        minWidth: !isMobile ? 700 : "auto"
                    }}
                >
                    <Route exact path="/" component={Stores} />
                    {_.map(routes, ({ path, component }, n) => (
                        <Route path={path} component={component} key={n} />
                    ))}
                </div>
            </div>
        );
    };
    render() {
        if (this.state.error) {
            return (
                <a onClick={() => Sentry.showReportDialog()}>Report feedback</a>
            );
        }
        if (!this.state.loaded) {
            return <LoadingScreen />;
        }
        if (!this.state.user) {
            return <Login />;
        }
        if (!this.props.authorized) {
            return <NotAuthorized />;
        }
        // Vertical menu will disappear if less than 1000px
        return <Router>{this.getNavBar()}</Router>;
    }
}
const mapStateToProps = (state: StoreState) => {
    return {
        authorized: state.authorized
    };
};
const mapDispatchToProps = (
    dispatch: ThunkDispatch<StoreState, undefined, Action>
) => {
    return {
        fetchStores: (query?: any) => {
            return dispatch(fetchStores(query));
        },
        fetchRegions: () => {
            return dispatch(fetchRegions());
        },
        dispatch
    };
};
const NavigationContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Navigation);
export default NavigationContainer;
