import React, { Component } from "react";

import { View, Text, Button } from "../../SharedComponents";
import { Row, Col, EditBlock } from "../../SharedComponents";
import { ReduxUtil } from "../../utils/ReduxUtil";
import Rewards from "./Rewards";
import { IUser, IPunchcard, IReward } from "@snackpass/snackpass-types";

type Props = {
    punchcard: IPunchcard;
    rewards: IReward[];
    dispatch: Function;
    user: IUser;
};
type State = {
    credit: number;
    showRewards: boolean;
};
export default class Punchcard extends Component<Props, State> {
    state = {
        credit: 0,
        showRewards: false
    };
    componentDidMount() {
        const { punchcard } = this.props;
        this.setState({
            credit: punchcard.credit
        });
    }

    toggle = () => {
        this.setState({
            showRewards: !this.state.showRewards
        });
    };
    handleSubmitCredit = (value: string) => {
        const { punchcard, user, dispatch } = this.props;
        let updates = {
            credit: parseFloat(value)
        };
        ReduxUtil.updatePunchcard(user._id, punchcard._id, updates, dispatch);
    };
    handleSubmitPoints = (value: string) => {
        const { punchcard, user, dispatch } = this.props;
        let updates = {
            pointsBalance: parseInt(value)
        };
        ReduxUtil.updatePunchcard(user._id, punchcard._id, updates, dispatch);
    };
    render() {
        const { punchcard, rewards, user, dispatch } = this.props;
        const { showRewards } = this.state;

        let numRewards = rewards?.length ?? 0;

        let buttonText = !showRewards
            ? `show ${numRewards} rewards 👀`
            : `hide rewards`;

        let ShowRewardButton = (
            <div>
                <Button onPress={this.toggle} label={buttonText} />
            </div>
        );

        return (
            <View>
                <Row>
                    <Col flex={2}>
                        <Text small>
                            {punchcard.store.name} {punchcard.store.emoji}
                        </Text>
                    </Col>
                    <Col flex={1} style={{ alignItems: "center" }}>
                        <Text small>{punchcard.totalPurchases}</Text>
                    </Col>

                    <Col flex={1}>
                        <EditBlock
                            xsmall
                            number
                            dollar
                            value={punchcard.credit}
                            onSubmit={(value) => {
                                if (!value) {
                                    value = "0";
                                }
                                this.handleSubmitCredit(value);
                            }}
                        />
                    </Col>
                    <Col flex={1}>
                        <EditBlock
                            xsmall
                            value={punchcard.pointsBalance}
                            onSubmit={(value: string) => {
                                if (!value) {
                                    value = "0";
                                }
                                this.handleSubmitPoints(value);
                            }}
                        />
                    </Col>
                    <Col flex={1} style={{ alignItems: "center" }}>
                        {ShowRewardButton}
                    </Col>
                </Row>
                <Rewards
                    rewards={rewards}
                    dispatch={dispatch}
                    user={user}
                    visible={this.state.showRewards}
                />
            </View>
        );
    }
}
