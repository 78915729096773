export const SET_FETCHING_STORES = "SET_FETCHING_STORES";

export interface ISetFetchingStores {
    type: "SET_FETCHING_STORES";
    fetchingStores: boolean;
}
// Action
export function setFetchingStores(fetchingStores: boolean) {
    return { type: SET_FETCHING_STORES, fetchingStores };
}

export type NetworkRequestsAction = ISetFetchingStores;

export interface INetworkRequestsState {
    fetchingStores: boolean;
}

// Reducer (handles the actions)
const initialState: INetworkRequestsState = { fetchingStores: false };

export function networkRequests(
    state: INetworkRequestsState = initialState,
    action: NetworkRequestsAction
) {
    switch (action.type) {
        case SET_FETCHING_STORES: {
            return { ...state, fetchingStores: action.fetchingStores };
        }
        default:
            return state;
    }
}
