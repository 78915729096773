import React, { Component, CSSProperties } from "react";

import { View, Text, Button } from "../SharedComponents";
import Select from "react-select";
import { Colors } from "../utils/Colors";
import TextField from "@material-ui/core/TextField";
import { Coordinate } from "../redux/types";
import {
    IHoursSchema,
    ITimeRangeSchema,
    SnackpassTimezoneEnum
} from "@snackpass/snackpass-types";

const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
];
const daysOfWeekShort = ["M", "T", "W", "Th", "F", "S", "Su"];

type SelectOption = {
    label: string;
    value: number;
};

const TIMEZONES: SelectOption[] = [
    { label: SnackpassTimezoneEnum.newYork, value: 0 },
    { label: SnackpassTimezoneEnum.la, value: -180 },
    { label: SnackpassTimezoneEnum.chicago, value: -60 },
    { label: SnackpassTimezoneEnum.denver, value: -120 }
];

type Props = {
    hours: IHoursSchema;
    onChange: Function;
    label: string;
};

type State = {
    currentTime: Coordinate;
    boundTime: Coordinate;
    clicked: boolean;
    cursorVisible: boolean;
};

export class HoursPicker extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            currentTime: {
                coordinate: 0,
                row: 0
            },
            boundTime: {
                coordinate: 0,
                row: 0
            },
            clicked: false,
            cursorVisible: false
        };
    }

    getBar = (index: number) => {
        if (
            this.state.currentTime.row > index &&
            this.state.boundTime.row < index
        ) {
            return (
                <View
                    style={{
                        position: "relative",
                        left: "0%",
                        height: 40,
                        width: "100%",
                        backgroundColor: "rgba(47,195,255,0.5)",
                        borderRadius: 5
                    }}
                />
            );
        }
        if (this.state.boundTime.row === index) {
            let left = Math.floor((this.state.boundTime.coordinate / 96) * 100);
            let right =
                this.state.boundTime.row === this.state.currentTime.row
                    ? Math.floor((this.state.currentTime.coordinate / 96) * 100)
                    : 100;
            return (
                <View
                    style={{
                        position: "relative",
                        left: left + "%",
                        height: 40,
                        width: right - left + "%",
                        backgroundColor: "rgba(47,195,255,0.5)",
                        borderRadius: 5
                    }}
                />
            );
        }
        if (this.state.currentTime.row === index) {
            let left =
                this.state.boundTime.row === this.state.currentTime.row
                    ? Math.floor((this.state.boundTime.coordinate / 96) * 100)
                    : 0;
            let right = Math.floor(
                (this.state.currentTime.coordinate / 96) * 100
            );
            return (
                <View
                    style={{
                        position: "relative",
                        left: left + "%",
                        height: 40,
                        width: right - left + "%",
                        backgroundColor: "rgba(47,195,255,0.5)",
                        borderRadius: 5
                    }}
                />
            );
        }
        return null;
    };

    formatTimeForTextField = (minutes: number) => {
        const convertedMinutes =
            minutes - Math.floor(minutes / (60 * 24)) * 24 * 60;

        const newHours = Math.floor(convertedMinutes / 60);
        var newMinutes = convertedMinutes % 60;

        var formattedTime;
        var newMinutesString;
        if (newMinutes < 10 && newHours < 10) {
            var newHoursString = "0" + newHours.toString();
            newMinutesString = "0" + newMinutes.toString();
            formattedTime = newHoursString + ":" + newMinutesString;
        } else if (newMinutes < 10) {
            newMinutesString = "0" + newMinutes.toString();
            formattedTime = newHours.toString() + ":" + newMinutesString;
        } else if (newHours < 10) {
            newHoursString = "0" + newHours.toString();
            formattedTime = newHoursString + ":" + newMinutes.toString();
        } else {
            formattedTime = newHours.toString() + ":" + newMinutes.toString();
        }
        return formattedTime;
    };

    handleTextFieldChange = (
        time: string,
        day: number,
        type: string,
        index: number
    ) => {
        const { hours, onChange } = { ...this.props };
        const hour = parseInt(time.substring(0, 2));
        const minute = parseInt(time.substring(3, 5));
        const formattedTime = hour * 60 + minute + 24 * 60 * day;
        var newLocalArray = hours.local;

        if (type === "start") {
            newLocalArray[index] = {
                start: formattedTime,
                end: newLocalArray[index].end
            };
        } else {
            newLocalArray[index] = {
                end: formattedTime,
                start: newLocalArray[index].start
            };
        }

        const newHours = {
            local: newLocalArray,
            zone: hours.zone
        };

        setTimeout(function () {
            onChange(newHours);
        }, 1500);
    };

    hoursAndMinutesToTime = (h: number, m: number) => {
        var hours = h;
        var minutes = m;

        var extension = "PM";
        if (hours < 12 || hours === 24) {
            extension = "AM";
        }

        if (hours === 0) {
            hours = 12;
        }
        if (hours > 12) {
            hours -= 12;
        }

        var hoursString = hours + "";
        if (hours < 10) {
            hoursString = "0" + hours;
        }

        var minutesString = minutes + "";
        if (minutes < 10) {
            minutesString = "0" + minutes;
        }

        return hoursString + ":" + minutesString + " " + extension;
    };
    getTime = (coordinate: number) => {
        var hours = Math.floor(coordinate / 4);
        let minutes = (coordinate % 4) * 15;

        return this.hoursAndMinutesToTime(hours, minutes);
    };
    handleHoursAdded = (range: ITimeRangeSchema) => {
        let hours = { ...this.props.hours };
        hours.local.push(range);
        this.props.onChange(hours);
    };
    handleHoursRemove = (index: number) => {
        let hours = { ...this.props.hours };

        hours.local.splice(index, 1);

        this.props.onChange(hours);
    };
    _getRangeView = (range: ITimeRangeSchema, index: number, n: number) => {
        let start = range.start;
        let end = range.end === 24 * 7 * 60 - 1 ? range.end + 1 : range.end;

        let startIndex = Math.floor(start / (24 * 60));
        let endIndex = Math.floor(end / (24 * 60));

        if (startIndex < index && endIndex > index) {
            return (
                <View
                    style={{
                        position: "absolute",
                        left: 0 + "%",
                        height: 40,
                        width: 100 + "%",
                        backgroundColor: "rgba(95,201,144,0.5)",
                        borderRadius: 5,
                        padding: 5
                    }}
                    key={n}
                >
                    {index === startIndex ? (
                        <Text style={{ color: Colors.offWhite, fontSize: 12 }}>
                            {this.minutesToTime(start) +
                                " - " +
                                this.minutesToTime(end)}
                        </Text>
                    ) : null}
                </View>
            );
        }
        if (startIndex === index && endIndex === index) {
            return (
                <View
                    style={{
                        position: "absolute",
                        left:
                            Math.floor(
                                ((start % (60 * 24)) / (60 * 24)) * 100
                            ) + "%",
                        height: 40,
                        width:
                            Math.floor(((end % (60 * 24)) / (60 * 24)) * 100) -
                            Math.floor(
                                ((start % (60 * 24)) / (60 * 24)) * 100
                            ) +
                            "%",
                        backgroundColor: "rgba(95,201,144,0.5)",
                        borderRadius: 5,
                        padding: 5
                    }}
                    key={n}
                >
                    {index === startIndex ? (
                        <Text style={{ color: Colors.offWhite, fontSize: 12 }}>
                            {this.minutesToTime(start) +
                                " - " +
                                this.minutesToTime(end)}
                        </Text>
                    ) : null}
                </View>
            );
        }
        if (startIndex === index) {
            return (
                <View
                    style={{
                        position: "absolute",
                        left:
                            Math.floor(
                                ((start % (60 * 24)) / (60 * 24)) * 100
                            ) + "%",
                        height: 40,
                        width:
                            100 -
                            Math.floor(
                                ((start % (60 * 24)) / (60 * 24)) * 100
                            ) +
                            "%",
                        backgroundColor: "rgba(95,201,144,0.5)",
                        borderRadius: 5,
                        padding: 5
                    }}
                    key={n}
                >
                    {index === startIndex ? (
                        <Text style={{ color: Colors.offWhite, fontSize: 12 }}>
                            {this.minutesToTime(start) +
                                " - " +
                                this.minutesToTime(end)}
                        </Text>
                    ) : null}
                </View>
            );
        }
        if (endIndex === index) {
            return (
                <View
                    style={{
                        position: "absolute",
                        left: "0%",
                        height: 40,
                        width:
                            Math.floor(((end % (60 * 24)) / (60 * 24)) * 100) +
                            "%",
                        backgroundColor: "rgba(95,201,144,0.5)",
                        borderRadius: 5,
                        padding: 5
                    }}
                    key={n}
                >
                    {index === startIndex ? (
                        <Text style={{ color: Colors.offWhite, fontSize: 12 }}>
                            {this.minutesToTime(start) +
                                " - " +
                                this.minutesToTime(end)}
                        </Text>
                    ) : null}
                </View>
            );
        }
        return null;
    };
    minutesToTime = (minutes: number, dayOfWeekStyle?: string) => {
        if (minutes === 24 * 7 * 60) {
            return daysOfWeekShort[6] + " 11:59 PM";
        }
        let date =
            dayOfWeekStyle === "long"
                ? daysOfWeek[Math.floor(minutes / (60 * 24))]
                : daysOfWeekShort[Math.floor(minutes / (60 * 24))];
        let hour = Math.floor((minutes % (60 * 24)) / 60);
        let minute = Math.floor((minutes % (60 * 24)) % 60);
        return date + " " + this.hoursAndMinutesToTime(hour, minute);
    };

    render() {
        const { hours } = this.props;
        return (
            <View>
                <View style={styles.titleContainer}>
                    <View key={"DUMMY VIEW"} style={{ width: 150 }} />
                    <Text style={styles.pickerLabel}>{this.props.label}</Text>
                    <div style={styles.timezoneSelectContainer}>
                        <Select
                            required
                            name={"Timezone"}
                            value={TIMEZONES.find(
                                (tz) => tz.label === hours.zone
                            )}
                            className="snackpass__react-select"
                            options={TIMEZONES}
                            isDisabled={true}
                        />
                        <Text xsmall>
                            <span style={{ color: "red" }}>*</span> Timezone is
                            based on selected region
                        </Text>
                    </div>
                </View>
                <View style={{ flexDirection: "row" }}>
                    <View style={styles.daysOfWeekContainer}>
                        {daysOfWeek.map((day, index) => {
                            return (
                                <View key={index} style={styles.dayOfWeek}>
                                    <Text style={{ color: Colors.white }}>
                                        {day}
                                    </Text>
                                </View>
                            );
                        })}
                    </View>
                    <div
                        onMouseEnter={() => {
                            this.setState({ cursorVisible: true });
                        }}
                        onMouseLeave={() => {
                            this.setState({
                                cursorVisible: false,
                                clicked: false,
                                currentTime: {
                                    coordinate: 0,
                                    row: 0
                                },
                                boundTime: {
                                    coordinate: 0,
                                    row: 0
                                }
                            });
                        }}
                        style={styles.timePicker}
                    >
                        {daysOfWeek.map((day, index) => {
                            return (
                                <div
                                    key={index}
                                    onMouseMove={(event) => {
                                        let val = Math.round(
                                            ((event.clientX -
                                                event.currentTarget.getBoundingClientRect()
                                                    .left) /
                                                (event.currentTarget.getBoundingClientRect()
                                                    .right -
                                                    event.currentTarget.getBoundingClientRect()
                                                        .left)) *
                                                96
                                        );
                                        if (val > 96) {
                                            val = 96;
                                        }
                                        if (val < 0) {
                                            val = 0;
                                        }
                                        this.setState({
                                            currentTime: {
                                                coordinate: val,
                                                row: index
                                            }
                                        });
                                        if (index < this.state.boundTime.row) {
                                            this.setState({
                                                clicked: false
                                            });
                                        }
                                    }}
                                    onClick={(event) => {
                                        this.setState((previousState) => {
                                            if (previousState.clicked) {
                                                if (
                                                    previousState.boundTime !==
                                                    previousState.currentTime
                                                ) {
                                                    let startMinutes =
                                                        previousState.boundTime
                                                            .row *
                                                            60 *
                                                            24 +
                                                            previousState
                                                                .boundTime
                                                                .coordinate *
                                                                15 !==
                                                        24 * 7 * 60
                                                            ? previousState
                                                                  .boundTime
                                                                  .row *
                                                                  60 *
                                                                  24 +
                                                              previousState
                                                                  .boundTime
                                                                  .coordinate *
                                                                  15
                                                            : 24 * 7 * 60 - 1;

                                                    let endMinutes =
                                                        previousState
                                                            .currentTime.row *
                                                            60 *
                                                            24 +
                                                            previousState
                                                                .currentTime
                                                                .coordinate *
                                                                15 !==
                                                        24 * 7 * 60
                                                            ? previousState
                                                                  .currentTime
                                                                  .row *
                                                                  60 *
                                                                  24 +
                                                              previousState
                                                                  .currentTime
                                                                  .coordinate *
                                                                  15
                                                            : 24 * 7 * 60 - 1;
                                                    if (
                                                        endMinutes >
                                                        startMinutes
                                                    ) {
                                                        this.handleHoursAdded({
                                                            start: startMinutes,
                                                            end: endMinutes
                                                        });
                                                    }
                                                }

                                                return {
                                                    clicked: false,
                                                    boundTime: {
                                                        coordinate: 0,
                                                        row: 0
                                                    }
                                                };
                                            } else {
                                                return {
                                                    boundTime:
                                                        previousState.currentTime,
                                                    clicked: true
                                                };
                                            }
                                        });
                                    }}
                                    style={{
                                        width: "100%",
                                        margin: 5
                                    }}
                                >
                                    <View style={styles.timePickerRow}>
                                        {index <= this.state.currentTime.row &&
                                        index >= this.state.boundTime.row &&
                                        this.state.clicked
                                            ? this.getBar(index)
                                            : null}

                                        {this.props.hours.local.map(
                                            (range, n) =>
                                                this._getRangeView(
                                                    range,
                                                    index,
                                                    n
                                                )
                                        )}

                                        {this.state.currentTime.row === index &&
                                        this.state.cursorVisible ? (
                                            <View
                                                style={{
                                                    ...styles.timePickerOverlay,
                                                    left:
                                                        Math.floor(
                                                            (this.state
                                                                .currentTime
                                                                .coordinate /
                                                                96) *
                                                                100
                                                        ) + "%"
                                                }}
                                            >
                                                <View
                                                    style={
                                                        styles.timePickerOverlayTime
                                                    }
                                                >
                                                    <Text
                                                        style={{
                                                            color: Colors.offWhite
                                                        }}
                                                    >
                                                        {this.getTime(
                                                            this.state
                                                                .currentTime
                                                                .coordinate
                                                        )}
                                                    </Text>
                                                </View>
                                            </View>
                                        ) : null}
                                    </View>
                                </div>
                            );
                        })}
                    </div>
                </View>
                <View style={styles.reviewHoursContainer}>
                    <div style={styles.reviewHoursInnerContainer}>
                        {this.props.hours.local
                            .sort(function (a, b) {
                                return a.start - b.start;
                            })
                            .map((range, index) => {
                                return (
                                    <div
                                        key={index}
                                        style={styles.reviewHoursRowContainer}
                                    >
                                        <View style={styles.reviewHoursRow}>
                                            <div
                                                style={styles.weekdayContainer}
                                            >
                                                <Text
                                                    style={styles.weekdayLabel}
                                                >
                                                    {
                                                        daysOfWeek[
                                                            Math.floor(
                                                                range.start /
                                                                    (60 * 24)
                                                            )
                                                        ]
                                                    }
                                                </Text>
                                                <TextField
                                                    key={this.formatTimeForTextField(
                                                        range.start
                                                    )}
                                                    id="time"
                                                    type="time"
                                                    defaultValue={this.formatTimeForTextField(
                                                        range.start
                                                    )}
                                                    onChange={(event) => {
                                                        this.handleTextFieldChange(
                                                            event.target.value,
                                                            Math.floor(
                                                                range.start /
                                                                    (60 * 24)
                                                            ),
                                                            "start",
                                                            index
                                                        );
                                                    }}
                                                />
                                            </div>

                                            <div style={styles.weekdayDivider}>
                                                <Text
                                                    style={styles.weekdayLabel}
                                                >
                                                    {" - "}
                                                </Text>
                                            </div>

                                            <div
                                                style={styles.weekdayContainer}
                                            >
                                                <Text
                                                    style={styles.weekdayLabel}
                                                >
                                                    {
                                                        daysOfWeek[
                                                            Math.floor(
                                                                range.end /
                                                                    (60 * 24)
                                                            )
                                                        ]
                                                    }
                                                </Text>
                                                <TextField
                                                    key={this.formatTimeForTextField(
                                                        range.end
                                                    )}
                                                    id="time"
                                                    type="time"
                                                    defaultValue={this.formatTimeForTextField(
                                                        range.end
                                                    )}
                                                    onChange={(event) => {
                                                        this.handleTextFieldChange(
                                                            event.target.value,
                                                            Math.floor(
                                                                range.end /
                                                                    (60 * 24)
                                                            ),
                                                            "end",
                                                            index
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </View>
                                        <View>
                                            <Button
                                                onPress={() => {
                                                    this.handleHoursRemove(
                                                        index
                                                    );
                                                }}
                                                type="button"
                                                label={"remove"}
                                                style={{
                                                    backgroundColor: Colors.red
                                                }}
                                            />
                                        </View>
                                    </div>
                                );
                            })}
                    </div>
                </View>
            </View>
        );
    }
}

const styles = {
    titleContainer: {
        width: "100%",
        alignItems: "center",
        marginBottom: 10,
        marginTop: 20,
        justifyContent: "space-between",
        flexDirection: "row"
    } as CSSProperties,
    pickerLabel: { color: Colors.text, fontSize: 18 } as CSSProperties,
    timezoneSelectContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    } as CSSProperties,
    daysOfWeekContainer: {
        flexDirection: "column",
        width: "20%",
        alignItems: "center"
    } as CSSProperties,
    dayOfWeek: {
        backgroundColor: Colors.blue2,
        borderRadius: 5,
        margin: 5,
        height: 40,
        justifyContent: "center",
        alignItems: "center"
    } as CSSProperties,
    timePicker: {
        display: "flex",
        flexDirection: "column",
        width: "80%",
        alignItems: "center"
    } as CSSProperties,
    timePickerRow: {
        position: "relative",
        borderRadius: 5,
        backgroundColor: Colors.lightGray,
        height: 40
    } as CSSProperties,
    timePickerOverlay: {
        position: "absolute",
        height: 40,
        width: 1,
        backgroundColor: Colors.blue
    } as CSSProperties,
    timePickerOverlayTime: {
        position: "absolute",
        borderRadius: 5,
        left: 5,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 5,
        paddingBottom: 5,
        backgroundColor: Colors.blue
    } as CSSProperties,
    reviewHoursContainer: {
        alignItems: "center",
        marginTop: 5,
        flexDirection: "column"
    } as CSSProperties,
    reviewHoursInnerContainer: {
        backgroundColor: Colors.offWhite,
        borderRadius: 5
    } as CSSProperties,
    reviewHoursRowContainer: {
        display: "flex",
        flexDirection: "row",
        borderRadius: 5,
        margin: 7,
        justifyContent: "space-between"
    } as CSSProperties,
    reviewHoursRow: {
        display: "flex",
        flexDirection: "row",
        marginRight: 5,
        paddingLeft: 5,
        justifyContent: "center",
        alignItems: "center"
    } as CSSProperties,
    weekdayContainer: {
        display: "flex",
        flexDirection: "column"
    } as CSSProperties,
    weekdayLabel: {
        color: Colors.text,
        fontSize: 16
    } as CSSProperties,
    weekdayDivider: {
        margin: "0 10px"
    } as CSSProperties
};
