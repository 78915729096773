import React, { Component } from "react";

import {
    View,
    Text,
    TouchableHighlight,
    Row,
    Col,
    Image
} from "../../../SharedComponents";
import TabletRow from "./TabletRow";
import { Colors } from "../../../utils/Colors";
import { ITablet, IStore } from "@snackpass/snackpass-types";

type Props = {
    store: IStore;
    tablets: Array<ITablet>;
    visible: boolean;
    setPrimaryStoreTablet: Function;
};

export default class Tablets extends Component<Props> {
    rowFromString = (str: string) => {
        return (
            <Row
                style={{
                    backgroundColor: Colors.offWhite
                }}
            >
                <Col flex={2}>
                    <Text small>{str}</Text>
                </Col>
            </Row>
        );
    };

    _nameLabel = () => {
        return (
            <Col flex={4}>
                <Text small>Name</Text>
            </Col>
        );
    };
    _primaryLabel = () => {
        return (
            <Col
                flex={4}
                style={{ justifyContent: "center", alignItems: "center" }}
            >
                <Text small>Primary?</Text>
            </Col>
        );
    };

    _serialLabel = () => {
        return (
            <Col
                flex={4}
                style={{ justifyContent: "center", alignItems: "center" }}
            >
                <Text small>Serial #</Text>
            </Col>
        );
    };

    _printerModelLabel = () => {
        return (
            <Col
                flex={4}
                style={{ justifyContent: "center", alignItems: "center" }}
            >
                <Text small>Printer Model</Text>
            </Col>
        );
    };

    _printerConnectionLabel = () => {
        return (
            <Col
                flex={4}
                style={{ justifyContent: "center", alignItems: "center" }}
            >
                <Text small>Printer Connection</Text>
            </Col>
        );
    };

    _lastPingLabel = () => {
        return (
            <Col
                flex={4}
                style={{ justifyContent: "center", alignItems: "center" }}
            >
                <Text small>Last Ping</Text>
            </Col>
        );
    };

    _batteryLevelLabel = () => {
        return (
            <Col
                flex={4}
                style={{ justifyContent: "center", alignItems: "center" }}
            >
                <Text small>Battery Level</Text>
            </Col>
        );
    };

    _chargingLabel = () => {
        return (
            <Col
                flex={4}
                style={{ justifyContent: "center", alignItems: "center" }}
            >
                <Text small>Charging</Text>
            </Col>
        );
    };

    _ordersLabel = () => {
        return (
            <Col
                flex={4}
                style={{ justifyContent: "center", alignItems: "center" }}
            >
                <Text small>Orders</Text>
            </Col>
        );
    };

    labels = () => {
        return (
            <Row style={{ backgroundColor: Colors.offWhite }}>
                {this._nameLabel()}
                {this._primaryLabel()}
                {this._serialLabel()}
                {this._printerModelLabel()}
                {this._printerConnectionLabel()}
                {this._lastPingLabel()}
                {this._batteryLevelLabel()}
                {this._chargingLabel()}
                {this._ordersLabel()}
            </Row>
        );
    };

    render() {
        const { tablets, visible, setPrimaryStoreTablet } = this.props;

        if (!visible) {
            return null;
        }
        return (
            <View
                style={{
                    alignSelf: "stretch",
                    paddingLeft: 40,
                    marginBottom: 20
                }}
            >
                {tablets && tablets.length ? this.labels() : null}
                {tablets
                    ? tablets.length
                        ? tablets.map((tablet, i) => {
                              return (
                                  <TabletRow
                                      isPrimary={
                                          tablet._id ===
                                          this.props.store.primaryStoreTablet
                                      }
                                      tablet={tablet}
                                      key={i}
                                      setPrimaryStoreTablet={
                                          setPrimaryStoreTablet
                                      }
                                  />
                              );
                          })
                        : this.rowFromString("No tablets found!")
                    : this.rowFromString("loading...")}
            </View>
        );
    }
}
