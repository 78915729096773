import React, { useEffect, useState } from "react";
import { Text, Toggle, Button } from "../../SharedComponents";

import Select from "react-select";

import { Colors, Styles } from "../../utils";
import { IOption } from "../../redux/types";
import { IStore, IPromotion } from "@snackpass/snackpass-types";
import ReactTable from "react-table";

import "react-table/react-table.css";
import moment from "moment";
import PromotionFormModal from "../../modals/PromotionFormModal";
import API from "../../api";
import { isSubsidized } from "../../components/PromotionForm/config";
import PromotionSummaryModal from "../../modals/PromotionSummaryModal";
import { message } from "antd";
import { sendPromotionApprovalLog } from "../../components/PromotionForm/lib";

const selectorStyle = {
    width: 200,
    margin: 10
};

const filtersInitialState = {
    selectedArchiveOption: {
        value: "not_archived",
        label: "Not Archived"
    } as IOption | null,
    selectedStoreOption: null as IOption | null
};
export interface PromotionsScreenProps {
    promotions: IPromotion[];
    fetchStoreData: (storeId: string, promoQuery?: any) => Promise<any>;
    fetchStores: () => Promise<any>;
    updatePromotion: (promotionId: string, body?: Object) => Promise<void>;
    storeOptions: IOption[];
    showModal: (modal: string, props?: Object) => void;
    activeStore: IStore | null;
}
const PromotionsScreen = ({
    promotions,
    storeOptions,
    showModal,
    fetchStoreData,
    updatePromotion,
    activeStore
}: PromotionsScreenProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [filters, setFilters] = useState(filtersInitialState);

    // select store
    async function handleSelectStore(item: IOption) {
        if (!item) return;
        setIsLoading(true);
        setFilters({ ...filters, selectedStoreOption: item });
        await fetchStoreData(item.value as string, { storeId: item.value });
        setIsLoading(false);
    }
    async function handleSelectArchive(selected: IOption | null) {
        setFilters({ ...filters, selectedArchiveOption: selected });
    }
    function filterPromotions(promotion: IPromotion) {
        let selectedArchiveOption = filters.selectedArchiveOption;
        if (
            selectedArchiveOption &&
            selectedArchiveOption.value === "archived"
        ) {
            return promotion.isArchived;
        }
        if (
            selectedArchiveOption &&
            selectedArchiveOption.value === "not_archived"
        ) {
            return !promotion.isArchived;
        }
        return true;
    }

    function _archived(isArchived: boolean) {
        if (!isArchived) return null;
        return (
            <div
                style={{
                    marginTop: 5,
                    display: "block",
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "#4d4d4d",
                    padding: "2px 10px",
                    borderRadius: 5
                }}
            >
                ARCHIVED
            </div>
        );
    }

    const _handleArchive = (props: any) => async () => {
        // update to be the opposite of what it currently is
        if (props.original.isRunning) {
            message.error("A Live promotion can't be archived");
            return;
        }
        await updatePromotion(props.original._id, {
            isArchived: !props.original.isArchived
        });
    };

    const _handleToggleLive = (props: any) => async (value: boolean) => {
        if (props.original.isArchived) {
            message.error("An archived promotion can't be live");
            return;
        }
        setIsLoading(true);
        await API.promotions.update(props.original._id, { isRunning: value });
        sendPromotionApprovalLog(
            props.original.store.name,
            props.original.store.region,
            props.original.name,
            value
        );

        filters.selectedStoreOption &&
            handleSelectStore(filters.selectedStoreOption);
    };

    const _handleToggleLeadReferral =
        (props: any) => async (value: boolean) => {
            if (props.original.type !== "REFERRAL") {
                message.error(
                    "Only referral type promotions can be lead referral"
                );
                return;
            }
            setIsLoading(true);
            await API.promotions.update(props.original._id, {
                isLeadPromotion: value
            });
            API.logs
                .send({
                    channel: "logs-ops",
                    title: `${props.original.store.name} in ${props.original.store.region}`,
                    description: `${props.original.name} was ${
                        value ? "toggled" : "removed"
                    } as the lead promotion`
                })
                .catch(function (error) {
                    console.log(error);
                });
            filters.selectedStoreOption &&
                handleSelectStore(filters.selectedStoreOption);
        };

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 300);
    }, []);

    return (
        <div style={{ ...Styles.panel, overflow: "scroll" }}>
            <Text large>Promotions </Text>
            {activeStore ? (
                <Button
                    label={`Create Promo For ${activeStore.name}`}
                    onPress={() => {
                        showModal("PromotionFormModal");
                    }}
                />
            ) : null}
            <Select
                className="snackpass__react-select"
                name="store"
                placeholder="select store first! 🚀"
                options={storeOptions}
                clearable={true}
                clearValueText="clear"
                style={selectorStyle}
                onChange={handleSelectStore}
                value={filters.selectedStoreOption}
            />
            <hr />
            <PromotionFormModal
                onSuccess={() => {
                    filters.selectedStoreOption &&
                        handleSelectStore(filters.selectedStoreOption);
                }}
            />
            <PromotionSummaryModal />
            <ReactTable
                data={promotions.filter(filterPromotions)}
                loading={isLoading}
                className="-striped"
                defaultPageSize={20}
                defaultSorted={[
                    {
                        // the sorting model for the table
                        id: "createdAt",
                        desc: true
                    }
                ]}
                columns={[
                    {
                        Header: "Promo ID",
                        accessor: "_id",
                        id: "_id",
                        Cell: (props) => (
                            <div
                                style={{
                                    flexDirection: "row",
                                    display: "flex",
                                    flexWrap: "wrap"
                                }}
                            >
                                <span style={{ width: "100%" }}>
                                    {props.value}
                                </span>
                            </div>
                        )
                    },
                    {
                        Header: "Name",
                        accessor: "name",
                        id: "name",
                        minWidth: 200,
                        Filter: ({ onChange, filter }) => {
                            return (
                                <input
                                    placeholder="Search name of discount"
                                    value={filter ? filter.value : ""}
                                    onChange={(e) => onChange(e.target.value)}
                                />
                            );
                        },
                        Cell: (props) => (
                            <div
                                style={{
                                    flexDirection: "row",
                                    display: "flex",
                                    flexWrap: "wrap"
                                }}
                            >
                                <span style={{ width: "100%" }}>
                                    {props.original.name}
                                </span>
                            </div>
                        ),
                        filterable: true,
                        style: { whiteSpace: "unset" }
                    },
                    {
                        id: "storeName",
                        Header: "Store",
                        accessor: "store.name"
                    },
                    {
                        id: "actions",
                        Header: "Actions",
                        minWidth: 150,
                        Cell: (props) => (
                            <div
                                style={{
                                    flexDirection: "row",
                                    display: "flex",
                                    flexWrap: "wrap"
                                }}
                            >
                                <Button
                                    disabled={
                                        props.original.type === "RETARGETING"
                                    }
                                    onPressDisabled={() => {
                                        alert(
                                            "Sorry, retargeting promos are deprecated."
                                        );
                                    }}
                                    onPress={() => {
                                        showModal("PromotionFormModal", {
                                            editMode: true,
                                            promotion: props.original
                                        });
                                    }}
                                    backgroundColor={Colors.apricot}
                                    label="Edit"
                                />
                                <Button
                                    onPress={_handleArchive(props)}
                                    backgroundColor={
                                        props.original.isArchived
                                            ? Colors.green
                                            : Colors.red
                                    }
                                    label={
                                        props.original.isArchived
                                            ? "Unarchive"
                                            : "Archive"
                                    }
                                />
                                <Button
                                    onPress={() => {
                                        showModal("PromotionSummaryModal", {
                                            promotion: props.original
                                        });
                                    }}
                                    backgroundColor={Colors.blue}
                                    label="Show Summary"
                                />
                            </div>
                        )
                    },
                    {
                        Header: "Type",
                        accessor: "type",
                        id: "type"
                    },
                    {
                        id: "isLive",
                        Header: "Status",
                        accessor: "isRunning",
                        Cell: (props) => (
                            <Toggle
                                activeLabel="LIVE"
                                inactiveLabel="TEST"
                                value={props.value}
                                onToggle={_handleToggleLive(props)}
                            />
                        )
                    },
                    {
                        id: "isLeadReferral",
                        Header: "Lead Referral",
                        accessor: "isLeadPromotion",
                        Cell: (props) => (
                            <Toggle
                                activeLabel=""
                                inactiveLabel=""
                                value={props.value}
                                onToggle={_handleToggleLeadReferral(props)}
                            />
                        )
                    },

                    {
                        id: "createdAt",
                        Header: "Created At",
                        accessor: "createdAt",
                        Cell: (props) => (
                            <span>
                                {moment(props.value).format("MMM Do YY")}
                            </span>
                        )
                    },
                    {
                        id: "totalUses",
                        Header: "Total Uses",
                        accessor: "totalUses",
                        Cell: (props) => (
                            <span style={{ color: Colors.blue }}>
                                {props.value}
                            </span>
                        )
                    },
                    {
                        Header: "Special Hours",
                        accessor: "hours",
                        id: "hours",
                        Cell: (props) => <span>{props.value ? "🕰" : ""}</span>
                    },
                    {
                        Header: "Start/End Time",
                        accessor: "activeTimePeriod",
                        id: "activeTimePeriod",
                        Cell: (props) => <span>{props.value ? "📅" : ""}</span>
                    },
                    {
                        Header: "Subsidized",
                        accessor: "accounting",
                        id: "accounting",
                        Cell: (props) => (
                            <span>{isSubsidized(props.value) ? "💵" : ""}</span>
                        )
                    },
                    {
                        Header: "Archived",
                        accessor: "isArchived",
                        minWidth: 200,
                        Filter: ({ onChange, filter }) => {
                            return (
                                <div>
                                    <Select
                                        className="snackpass__react-select"
                                        name="archived"
                                        placeholder="archived or active"
                                        options={[
                                            {
                                                label: "Archived",
                                                value: "archived"
                                            },
                                            {
                                                label: "Not Archived",
                                                value: "not_archived"
                                            }
                                        ]}
                                        defaultValue={
                                            filters.selectedArchiveOption
                                        }
                                        styles={{
                                            // this is needed or else the options are behind
                                            // the table and cannot select them
                                            menu: () => ({})
                                        }}
                                        isClearable={true}
                                        style={selectorStyle}
                                        onChange={(option: IOption) => {
                                            onChange(
                                                option ? option.value : null
                                            );
                                            handleSelectArchive(option);
                                        }}
                                    />
                                </div>
                            );
                        },
                        Cell: (props) => (
                            <div
                                style={{
                                    flexDirection: "row",
                                    display: "flex",
                                    flexWrap: "wrap"
                                }}
                            >
                                {_archived(props.original.isArchived)}
                            </div>
                        ),
                        filterable: true,
                        style: { whiteSpace: "unset" },
                        filterMethod: (filter: any, row: any, column: any) => {
                            let value = filters.selectedArchiveOption;
                            if (!value) return true;
                            if (value.value === "archived") {
                                return row.isArchived;
                            }
                            if (value.value === "not_archived") {
                                return !row.isArchived;
                            }
                            return true;
                        }
                    },
                    {
                        id: "updatedAt",
                        Header: "Updated At",
                        accessor: "updatedAt",
                        Cell: (props) => (
                            <span>
                                {moment(props.value).format("MMM Do YY")}
                            </span>
                        )
                    }
                ]}
            />
        </div>
    );
};

export default PromotionsScreen;
