import React, { Component } from "react";

import { View, Text, Button } from "../../../SharedComponents";
import api from "../../../api";
import { Helpers } from "../../../utils";

import { Colors } from "../../../utils/Colors";
import { IStore } from "@snackpass/snackpass-types";
import _ from "lodash";
import { Modal as BootstrapModal } from "react-bootstrap";
import Modal from "react-modal";

type Props = {
    storeObj: IStore;
    setStore: Function;
    onShowModal: any;
};

type State = {
    duration: string;
    showPauseReason: boolean;
};

export default class ClosedUntil extends Component<Props, State> {
    state = {
        duration: "",
        showPauseReason: false,
    };

    unPauseStore = () => {
        const { storeObj } = this.props;
        let post = {
            closedUntil: null,
            closedUntilReason: null,
        };
        api.stores.update(storeObj._id, post).then(response => {
            this.props.setStore(response.data.store);
        });
    };
    viewPauseReason = () => {
        this.setState({ showPauseReason: true });
    };
    handleHidePauseReason = () => {
        this.setState({ showPauseReason: false });
    };
    removeRushHour = () => {
        const { storeObj } = this.props;
        let post = {
            rushHourUntil: null,
        };
        api.stores.update(storeObj._id, post).then(response => {
            this.props.setStore(response.data.store);
        });
    };

    renderPause = () => {
        let { storeObj } = this.props;
        let store = storeObj as IStore;
        let closedUntil = store.closedUntil as Date;
        let closedUntilReason = _.get(store, "closedUntilReason");
        return (
            <View style={{ cursor: "pointer" }}>
                <Text
                    small
                    color={store.closedUntil ? Colors.red : Colors.green}
                >
                    Paused until
                </Text>
                <Text
                    small
                    color={store.closedUntil ? Colors.red : Colors.green}
                >
                    {` ${Helpers.formatDateTimeLong(closedUntil)}`}
                </Text>
                {closedUntilReason && (
                    <Button
                        style={{ backgroundColor: Colors.gray, width: 75 }}
                        onPress={this.viewPauseReason}
                        label="View Reason"
                    />
                )}
                <Button
                    style={{ backgroundColor: Colors.gray, width: 75 }}
                    onPress={this.unPauseStore}
                    label="Unpause"
                />
                <Modal
                    isOpen={this.state.showPauseReason}
                    onRequestClose={this.handleHidePauseReason}
                >
                    <BootstrapModal.Body
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Text small>
                            {"Reason: "}
                            {closedUntilReason}
                        </Text>
                    </BootstrapModal.Body>
                </Modal>
            </View>
        );
    };
    renderRushHour = () => {
        let { storeObj } = this.props;
        let store = storeObj as IStore;
        let rushHourUntil = store.rushHourUntil as Date;
        return (
            <View style={{ cursor: "pointer" }}>
                <Text
                    small
                    color={store.rushHourUntil ? Colors.red : Colors.green}
                >
                    Rush Hour Until
                </Text>
                <Text
                    small
                    color={store.rushHourUntil ? Colors.red : Colors.green}
                >
                    {` ${Helpers.formatDateTime(rushHourUntil)}`}
                </Text>
                <Button
                    style={{ backgroundColor: Colors.gray, width: 75 }}
                    onPress={this.removeRushHour}
                    label="End rush-hour"
                />
            </View>
        );
    };
    render() {
        const { storeObj } = this.props;
        // If not closed or rush hour, return null
        if (!storeObj.closedUntil && !storeObj.rushHourUntil) {
            return null;
        }
        if (storeObj.closedUntil) return this.renderPause();
        if (storeObj.rushHourUntil) return this.renderRushHour();
    }
}
