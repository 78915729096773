import { ITochiFile, ITochiAnimation } from "@snackpass/snackpass-types";
import { IOption } from "src/redux/types";
import React, { useEffect, useState, CSSProperties } from "react";
import ReactSelect from "react-select";

import api from "src/api";
import _ from "lodash";
import { Button } from "src/SharedComponents";

type Props = {
    tochiFiles: ITochiFile[];
    tochiAnims: ITochiAnimation[];
};

export const TochiFileToAnimation = ({ tochiFiles, tochiAnims }: Props) => {
    const [anims, setAnims] = useState<IOption[]>([]);
    const [animToConvert, setAnimToConvert] = useState("");
    const [animType, setAnimType] = useState("");

    useEffect(() => {
        getAnimNames();
    }, [tochiFiles, tochiAnims]);

    const getAnimNames = () => {
        const animNames: string[] = tochiAnims.map(anim => anim.name);
        const notAnimedFiles: string[] = tochiFiles
            .filter(
                (file: ITochiFile) =>
                    file.name.includes("_ANIM") &&
                    !animNames.includes(file.name.split("_ANIM")[0])
            )
            .map((anim: ITochiFile) => anim.name);
        const animOptions = notAnimedFiles.map(animName => {
            return { label: animName, value: animName };
        });
        setAnims(animOptions);
    };

    const handleAnimChange = (value: string) => {
        setAnimToConvert(value);
    };

    const handleTypeChange = (value: string) => {
        setAnimType(value);
    };

    const handleConvert = async () => {
        api.tochiFiles
            .createAnimation({ name: animToConvert, type: animType })
            .then(res => {
                setAnimType("");
                setAnimToConvert("");
            })
            .catch(err => console.log(err));
    };

    const handleCancel = () => {
        setAnimToConvert("");
        setAnimType("");
    };

    return (
        <div style={styles.container}>
            <div style={styles.select}>
                <ReactSelect
                    id="color"
                    options={anims}
                    isMulti={false}
                    placeholder="animation"
                    onChange={handleAnimChange}
                    value={animToConvert}
                />
            </div>
            <div style={styles.select}>
                <ReactSelect
                    id="color"
                    options={types}
                    isMulti={false}
                    placeholder="type"
                    onChange={handleTypeChange}
                    value={animType}
                />
            </div>
            <Button
                label="convert"
                style={styles.button}
                disabled={!(animToConvert && animType)}
                onPress={handleConvert}
            />
            <Button label="cancel" onPress={handleCancel} />
        </div>
    );
};

const types = [
    { label: "IDLE", value: "IDLE" },
    { label: "ACTION", value: "ACTION" },
    { label: "MOOD", value: "MOOD" },
];

const styles = {
    container: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    } as CSSProperties,
    select: {
        marginRight: 25,
        width: 150,
    } as CSSProperties,
    button: {
        marginRight: 25,
    },
};
