import { KioskModelEnum, KioskModelType } from "@snackpass/snackpass-types";
import { compose, prop } from "lodash/fp";
import { Action } from "redux";

import api from "../../api";
import { setKioskList } from "../../redux/kiosks";
import { SelectOption } from "src/shared/types";

export const refreshKiosks = (params: {
    dispatch: (a: Action) => any;
    selectedStoreId?: string;
}) => {
    const { dispatch, selectedStoreId } = params;
    const processApiResp = compose(dispatch, setKioskList, prop("data.kiosks"));
    return api.kiosks.list({ storeId: selectedStoreId }).then(processApiResp);
};

const KioskModelLabels = Object.values(KioskModelEnum);
const KioskModelValues = Object.keys(KioskModelEnum);

export const KIOSK_MODEL_OPTIONS: SelectOption<KioskModelType>[] =
    KioskModelValues.map((value: string, index: number) => {
        return {
            label: KioskModelLabels[index],
            value: value as KioskModelType
        };
    });
