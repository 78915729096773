import React, { Component } from "react";

import { View } from "../../SharedComponents";
import * as Styles from "../../utils/Styles";
import Reward from "./Reward";
import { IUser, IReward, IPunchcard } from "@snackpass/snackpass-types";

type Props = {
    rewards: IReward[];
    dispatch: Function;
    user: IUser;
    visible: boolean;
};

type State = {
    open: boolean;
};
export default class Rewards extends Component<Props, State> {
    render() {
        const { rewards, dispatch, user, visible } = this.props;
        if (!visible) {
            return null;
        }

        const sortUnredeemed = (a: any, b: any) => {
            if (a.used && !b.used) {
                return 1;
            }
            if (b.used && !a.used) {
                return -1;
            }
            return 0;
        };
        return (
            <View
                style={{
                    width: "100%",
                    margin: "10px 0",
                    borderRadius: 10,
                    overflow: "hidden",
                    ...Styles.shadowLight
                }}
            >
                {rewards.sort(sortUnredeemed).map((reward, i) => {
                    return (
                        <Reward
                            dispatch={dispatch}
                            reward={reward}
                            user={user}
                            key={i}
                        />
                    );
                })}
            </View>
        );
    }
}
