import React from "react";
import { Toggle } from "../../../SharedComponents";
import { FieldProps } from "formik";
import { FormValues } from "../types";

interface ToggleProps extends FieldProps<FormValues> {
    onChange?: any;
}

const MyToggle = ({ field, form, onChange }: ToggleProps) => {
    const onFieldChange = (value: any) => {
        // Trigger the onChange callback
        if (onChange) {
            onChange(value);
        }

        form.setFieldValue(field.name, value);
        // use try/catch because sometimes, if nested object, the field will not
        // exist yet.
        try {
            form.setFieldTouched(field.name, true);
        } catch (err) {
            console.log(err);
        }
    };
    return <Toggle value={field.value} onToggle={onFieldChange} />;
};

export default MyToggle;
