import moment from "moment-timezone";
import {
    IHoursSchema,
    SnackpassTimezoneEnum
} from "@snackpass/snackpass-types";

export type Time = {
    day: number;
    hours: number;
    minutes: number;
    seconds?: number;
    am?: boolean;
    pm?: boolean;
};

export const defaultHours = {
    zone: SnackpassTimezoneEnum.newYork,
    local: [
        { start: 6420, end: 7080 },
        { start: 660, end: 1320 },
        { start: 7860, end: 8520 },
        { start: 9300, end: 9960 },
        { start: 4980, end: 5640 },
        { start: 2100, end: 2760 },
        { start: 3540, end: 4200 },
        { start: 7110, end: 7280 },
        { start: 8550, end: 8720 }
    ]
};

const MINUTES_PER_HOUR = 60;
const MINUTES_PER_DAY = MINUTES_PER_HOUR * 24;

export function parseTime(time: number): Time {
    let day = Math.floor(time / MINUTES_PER_DAY);
    let hours = Math.floor(time / MINUTES_PER_HOUR) % 24;
    let minutes = time % MINUTES_PER_HOUR;

    return { day, hours, minutes };
}

// Number of minutes past midnight of start of week
// 300 = 3am monday (Monday 12am is 0)
export type NormalizedWeekMinuteOffset = number;

export function computeTime(time: Time): NormalizedWeekMinuteOffset {
    return (
        time.day * MINUTES_PER_DAY +
        time.hours * MINUTES_PER_HOUR +
        time.minutes
    );
}

export function isOpen(hours: IHoursSchema) {
    let currTime = moment().tz(hours.zone);
    let minutes = currTime.diff(currTime.clone().startOf("isoWeek"), "minutes");

    for (const timeRange of hours.local) {
        if (minutes >= timeRange.start && minutes <= timeRange.end) return true;
    }
    return false;
}
