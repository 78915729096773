import React from "react";
import * as Sentry from "@sentry/browser";

import { styles } from "./styles";
import { FormValues } from "./types";

// This is necessary because if the yup schema has errors, it will fail silently
// and will allow you to submit the form, which is dumb.
export function validYupSchema(values: FormValues, validationSchema: any) {
    try {
        let isValid = validationSchema.isValidSync(values);

        return (
            <p
                style={
                    isValid ? styles.successContainer : styles.errorContainer
                }
            >
                {isValid ? "Valid admin ✅" : "Admin contains errors ❌"}
            </p>
        );
    } catch (err) {
        Sentry.captureException(err);
        console.log("===ERRORS===", err);

        return (
            <p style={styles.errorContainer}>
                Yup Schema contains errors! ❌❌❌❌ {JSON.stringify(err)} Alert
                the eng team!
            </p>
        );
    }
}
