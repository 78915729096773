import { ITochiItem } from "@snackpass/snackpass-types";
import _ from "lodash";
import { compose } from "lodash/fp";
import React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Table, { CellInfo } from "react-table";
import Select from "react-select";
import { Dispatch } from "redux";
import { show } from "redux-modal";
import Swal from "sweetalert2";
import api from "../../../../api";
import * as tochiItemsRedux from "../../../../redux/tochiItems";
import { Button, Text, Image, EditBlock } from "../../../../SharedComponents";
import { Colors } from "../../../../utils";
import { updateTochiItem } from "../../../../redux/tochiItems";
import { itemRarityOptions } from "./TochiItemsForm/config";

interface raritySelectOption {
    label: string;
    value: string;
}

export const TochiItemsTable = () => {
    const items = useSelector(tochiItemsRedux.getTochiItems);
    const dispatch = useDispatch();

    const tochiItemsColumn = (
        header: string,
        accessor: string,
        id: string,
        filterable: boolean
    ) => {
        return {
            Header: header,
            accessor: accessor,
            id: id,
            minWidth: 125,
            Filter: ({ onChange, filter }: any) => {
                return (
                    <input
                        placeholder={"Search " + accessor}
                        value={filter ? filter.value : ""}
                        onChange={(e) => onChange(e.target.value)}
                        style={{ width: 125 }}
                    />
                );
            },
            Cell: (props: CellInfo) => renderCell(id, props),
            filterable: filterable,
            style: { whiteSpace: "unset" },
            filterMethod: (filter: any, row: any) => {
                let name: number | string | string[] = _.get(row, id, "");
                let value: string | null = _.get(filter, "value", "");
                if (value) {
                    return renderCellString(name)
                        .toLowerCase()
                        .includes(value.toLowerCase());
                }
                return true;
            }
        };
    };

    const handleReleaseDate = async (value: string, props: CellInfo) => {
        const date = moment(value, "MM/DD/YYYY").toDate();
        const item = props.original;
        item.releaseDate = date;
        await api.tochiItems
            .update(item._id, item)
            .then((res) => dispatch(updateTochiItem(res.data.item)));
    };

    const handlePrice = async (value: string, props: CellInfo) => {
        const item = props.original;
        item.price = value;
        await api.tochiItems
            .update(item._id, item)
            .then((res) => dispatch(updateTochiItem(res.data.item)));
    };

    const handleRarity = async (value: string, props: CellInfo) => {
        const item = props.original;
        item.rarity = value;
        await api.tochiItems
            .update(item._id, item)
            .then((res) => dispatch(updateTochiItem(res.data.item)));
    };

    const renderCell = (id: string, props: CellInfo) => {
        switch (id) {
            case "tochiItemThumbnail":
                return <Image source={props.value} style={{ width: 50 }} />;
            case "tochiItemPrice":
                return (
                    <EditBlock
                        small
                        number
                        value={props.value}
                        onSubmit={(value: string) => handlePrice(value, props)}
                    />
                );
            case "tochiItemRarity":
                return (
                    <Select
                        name="type"
                        placeholder="rarity"
                        value={{
                            label:
                                props.value[0] +
                                props.value.toLowerCase().slice(1),
                            value: props.value
                        }}
                        styles={{
                            // this is needed or else the options are behind
                            // the table and cannot select them
                            menu: () => ({}),
                            multiValueLabel: () => ({
                                whiteSpace: "normal"
                            })
                        }}
                        isClearable={false}
                        options={itemRarityOptions}
                        className="snackpass__react-select"
                        onChange={(option: raritySelectOption) =>
                            handleRarity(option.value, props)
                        }
                    />
                );
            case "tochiItemReleaseDate": {
                let dateString;
                if (props.value) {
                    const [year, month, day] = props.value
                        .toString()
                        .split("T")[0]
                        .split("-");
                    dateString = `${month}/${day}/${year}`;
                }
                return (
                    <EditBlock
                        small
                        value={dateString || "No Release Date"}
                        onSubmit={(value: string) =>
                            handleReleaseDate(value, props)
                        }
                    />
                );
            }
            case "tochiItemIsActive":
                return (
                    <div style={{ flex: 1, justifyContent: "center" }}>
                        <Text small> {props.value ? "✅" : "❌"} </Text>
                    </div>
                );
            default:
                return <Text small>{renderCellString(props.value)}</Text>;
        }
    };

    const renderCellString = (values: number | string | string[]) => {
        if (!values) {
            return "";
        }
        if (typeof values === "string" || typeof values === "number") {
            return values.toString();
        } else {
            let cell: string = "";
            const vals = Array.from(values);
            if (vals.length > 1) {
                for (let v of vals) {
                    cell += v + ", ";
                }
                return cell.slice(0, cell.length - 2);
            } else {
                return vals[0];
            }
        }
    };

    const _nameColumn = tochiItemsColumn("Name", "name", "tochiItemName", true);
    const _thumbnailColumn = tochiItemsColumn(
        "Thumbnail",
        "thumbnail",
        "tochiItemThumbnail",
        false
    );
    const _rarityColumn = tochiItemsColumn(
        "✏️ Rarity",
        "rarity",
        "tochiItemRarity",
        true
    );
    const _priceColumn = tochiItemsColumn(
        "✏️ Price",
        "price",
        "tochiItemPrice",
        true
    );
    const _isActiveColumn = tochiItemsColumn(
        "Is Active",
        "isActive",
        "tochiItemIsActive",
        false
    );
    const _releaseDateColumn = tochiItemsColumn(
        "✏️ Release Date",
        "releaseDate",
        "tochiItemReleaseDate",
        false
    );

    return (
        <Table
            style={{ textAlign: "center" }}
            data={items}
            className="snackpass__table"
            defaultPageSize={20}
            freezeWhenExpanded={true}
            columns={[
                _nameColumn,
                _thumbnailColumn,
                _rarityColumn,
                _priceColumn,
                _isActiveColumn,
                _releaseDateColumn,
                _actionsColumn()
            ]}
        />
    );
};

const TochiItemButton = ({
    icon,
    onClick
}: {
    icon: string;
    onClick: () => void;
}) => {
    return (
        <Button
            containerStyle={{
                display: "inline-block",
                margin: "0 5px"
            }}
            backgroundColor={Colors.white}
            labelColor={Colors.blue}
            label={<i className={`fas ${icon}`} />}
            onPress={onClick}
        />
    );
};

const _actionsColumn = () => ({
    Header: "Actions",
    id: "tochiItemsActions",
    Cell: (props: CellInfo) => {
        const dispatch = useDispatch();
        const item = props.original;
        const showModal = compose(dispatch, show);

        return (
            <span>
                <TochiItemButton
                    icon={"fa-pen"}
                    onClick={() => {
                        showModal("TochiItemsModal", {
                            editMode: true,
                            item
                        });
                    }}
                />
                <TochiItemButton
                    icon={"fa-trash"}
                    onClick={() => _deleteTochiItem(dispatch, item)}
                />
                <TochiItemButton
                    icon={"fa-plus"}
                    onClick={() =>
                        showModal("TochiItemsModal", {
                            editMode: false,
                            item
                        })
                    }
                />
            </span>
        );
    }
});

const _deleteTochiItem = async (dispatch: Dispatch, item: ITochiItem) => {
    let result = await Swal.fire({
        title: "Delete Item?",
        text: "Are you sure you wish to delete this item?",
        type: "warning",
        showConfirmButton: true,
        showCancelButton: true
    });

    if (result.value) {
        await api.tochiItems.delete(item._id);
        dispatch(tochiItemsRedux.removeTochiItem(item));
    }
};
