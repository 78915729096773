import React, { useState } from "react";
import { ChainStoresTable } from "../../../components/Chains/ChainStoresTable";
import { IStore, IChain } from "@snackpass/snackpass-types";
import Select from "react-select";
import { Button } from "../../../SharedComponents";
import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import API from "../../../api";
import { setStore, getStoreSelectOptions } from "../../../redux/stores";
import Swal from "sweetalert2";

type ManageChainStoresProps = {
    chain: IChain;
};

const ManageChainStores = ({ chain }: ManageChainStoresProps) => {
    const [storeToAdd, setStoreToAdd] = useState<IStore | null>(null);
    const [isAddingStore, setIsAddingStore] = useState<boolean>(false);

    const dispatch = useDispatch();
    const storeOptions = useSelector(getStoreSelectOptions);
    const _addStoreToChain = addStoreToChain(dispatch, setIsAddingStore, chain);

    return (
        <div
            style={{
                margin: "20px auto",
                borderRadius: 5,
                border: "1px solid #efefef",
            }}
        >
            <h3
                style={{
                    padding: 20,
                }}
            >
                Stores
            </h3>

            <ChainStoresTable chain={chain} isSubRow={false} />

            <div
                style={{
                    padding: "5px 20px 20px 20px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                }}
            >
                <h3>Select a store</h3>
                <div style={{ flex: 1, margin: "0 10px" }}>
                    <Select
                        options={storeOptions}
                        isMulti={false}
                        onChange={(value: any) => {
                            setStoreToAdd(value.storeObj);
                        }}
                    />
                </div>
                <Button
                    disabled={!storeToAdd}
                    onPress={e => {
                        e.stopPropagation();
                        _addStoreToChain(storeToAdd as IStore);
                    }}
                    loading={isAddingStore}
                    type="button"
                    className="add-stores-btn"
                    label="Add Store"
                />
            </div>
        </div>
    );
};

const addStoreToChain = _.curry(
    async (
        dispatch: Dispatch,
        setIsLoading: (isLoading: boolean) => void,
        chain: IChain,
        store: IStore
    ) => {
        setIsLoading(true);
        try {
            let res = await API.stores.update(store._id, {
                chainId: chain._id,
            });
            dispatch(setStore(res.data.store));
        } catch (err) {
            Swal.fire({
                type: "error",
                title: "An error occurred",
            });
        } finally {
            setIsLoading(false);
        }
    }
);

export default ManageChainStores;
