import { gql } from "graphql-request";

export const GiftCard = gql`
    fragment GiftCard on GiftCard {
        id
        remainingCreditCents
        storeId
        toPhone
        toName
        fromEmail
        fromName
        status
        maxCreditCents
        code
        activationDate
        createdAt
        updatedAt
        type
        purchaseId
    }
`;
