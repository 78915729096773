import { IStoreSubscription } from "@snackpass/snackpass-types";
import { createSelector } from "reselect";
import { prop } from "lodash/fp";

import { StoreState, ObjectId, StoreSubscriptionState } from "./types";

export type SetSubscriptions = {
    type: "SET_SUBSCRIPTIONS";
    subscription: IStoreSubscription;
};
export type SetSelectedStoreId = {
    type: "SET_SUBSCRIPTIONS_STORE_ID";
    selectedStoreId: null | ObjectId;
};

export const setStoreSubscription = (subscription: IStoreSubscription) => ({
    type: "SET_SUBSCRIPTIONS",
    subscription,
});

export const setSelectedStoreId = (selectedStoreId: null | string) => ({
    type: "SET_SUBSCRIPTIONS_STORE_ID",
    selectedStoreId,
});
export type StoreSubscriptionAction = SetSubscriptions | SetSelectedStoreId;

export const getStoreSubscriptionState = (state: StoreState) =>
    state.storeSubscriptions.subscription;
export const getSelectedStoreId = (state: StoreState) =>
    state.storeSubscriptions.selectedStoreId;

const initialState: StoreSubscriptionState = {
    selectedStoreId: null,
    subscription: null,
};
export default function subscriptions(
    state = initialState,
    action: StoreSubscriptionAction
) {
    switch (action.type) {
        case "SET_SUBSCRIPTIONS":
            return { ...state, subscription: action.subscription };
        case "SET_SUBSCRIPTIONS_STORE_ID":
            return { ...state, selectedStoreId: action.selectedStoreId };
        default:
            return state;
    }
}
