import React from "react";

import Modal from "react-responsive-modal";
import { connectModal } from "redux-modal";
import PromotionForm from "../containers/PromotionForm";
import { Colors } from "../utils";
import { IPromotion } from "@snackpass/snackpass-types";

const styles = {
    modal: {
        overlay: {
            background: "rgba(0,0,0,0.23)",
        },
        modal: {
            background: Colors.white,
            padding: 20,
            boxShadow: "0 2px 40px 0 rgba(0, 0, 0, 0.3)",
            borderRadius: 5,
            overflow: "hidden",
            marginBottom: 100,
            width: "85%",
        },
    },
};

export interface PromotionFormModalProps {
    show: boolean;
    handleHide: () => void;
    handleDestroy: () => void;
    editMode?: boolean;
    promotion?: IPromotion;
    onSuccess: () => void;
}

const PromotionFormModal = ({
    show,
    handleHide,
    handleDestroy,
    editMode,
    promotion,
    onSuccess,
}: PromotionFormModalProps) => {
    return (
        <Modal
            onClose={() => {
                handleHide();
            }}
            open={show}
            styles={styles.modal}
        >
            <PromotionForm
                editMode={editMode}
                promotion={promotion}
                onSuccess={(promotion: IPromotion) => {
                    handleHide();
                    onSuccess();
                }}
            />
        </Modal>
    );
};

export default connectModal({ name: "PromotionFormModal" })(PromotionFormModal);
