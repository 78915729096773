type ObjectId = string;

export const GET_PRINTERS_FOR_STORE = "GET_PRINTERS_FOR_STORE";
export const GET_PRINTERS_FOR_FOODHALL = "GET_PRINTERS_FOR_FOODHALL";
export const GET_PRINTERS_FOR_SEARCH = "GET_PRINTERS_FOR_SEARCH";
export const SET_PRINTERS = "SET_PRINTERS";

interface GetPrintersForStore {
    type: "GET_PRINTERS_FOR_STORE";
    selectedStoreId: ObjectId | null;
}

interface GetPrintersForFoodhall {
    type: "GET_PRINTERS_FOR_FOODHALL";
    selectedFoodhallId: ObjectId | null;
}

interface GetPrintersForSearch {
    type: "GET_PRINTERS_FOR_SEARCH";
    search: string | null;
}

interface SetPrinters {
    type: "SET_PRINTERS";
    printers: [];
}

// Actions
export const getPrintersForStore = (selectedStoreId: ObjectId | null) => ({
    type: GET_PRINTERS_FOR_STORE,
    selectedStoreId,
});

export const getPrintersForFoodhall = (
    selectedFoodhallId: ObjectId | null
) => ({
    type: GET_PRINTERS_FOR_FOODHALL,
    selectedFoodhallId,
});

export const getPrintersForSearch = (search: string | null) => ({
    type: GET_PRINTERS_FOR_SEARCH,
    search
});

export const setPrinters = (printers: []) => ({
    type: SET_PRINTERS,
    printers,
});

export type PrintersAction =
    | GetPrintersForStore
    | GetPrintersForFoodhall
    | GetPrintersForSearch
    | SetPrinters;

// State
export interface PrintersState {
    selectedStoreId: ObjectId | null;
    selectedFoodhallId: ObjectId | null;
    search: string | null;
    printers: [];
}

const initialState: PrintersState = {
    selectedStoreId: null,
    selectedFoodhallId: null,
    search: null,
    printers: [],
};

// Reducer
export const printers = (
    state: PrintersState = initialState,
    action: PrintersAction
): PrintersState => {
    switch (action.type) {
        case GET_PRINTERS_FOR_STORE:
            return { ...state, selectedStoreId: action.selectedStoreId };
        case GET_PRINTERS_FOR_FOODHALL:
            return { ...state, selectedFoodhallId: action.selectedFoodhallId };
        case GET_PRINTERS_FOR_SEARCH:
            return { ...state, search: action.search };
        case SET_PRINTERS:
            return { ...state, printers: action.printers };
        default:
            return state;
    }
};
