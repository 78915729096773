import { Colors } from "../../utils";

let styles = {
    container: {
        width: 310,
        border: "1px solid rgb(220, 220, 220)",
        boxShadow: "rgba(223, 223, 223, 0.5) 0px 2px 4px 0px",
        backgroundColor: "#fff",
        borderRadius: 5,
        display: "flex",
    },
    toggle: {
        cursor: "pointer",
        textAlign: "center" as "center",
        display: "inline-block",
        padding: 10,
        flex: 1,
    },
    input: {
        marginTop: 10,
        marginBottom: 20,
        background: "#FFFFFF",
        border: "1px solid #DCDCDC",
        boxShadow: "0 2px 4px 0 rgba(223, 223, 223, 0.50)",
        padding: 12,
        borderRadius: 6,
        width: 280,
        paddingLeft: 15,
        paddingRight: 15,
    },
    selectMoney: {
        height: 40,
        width: 150,
        fontFamily: "Rubik",
        fontSize: 14,
        paddingLeft: 20,
        color: Colors.text,
        outline: "none",
    },
};

export default styles;
