import React from "react";
import ReactDropzone from "react-dropzone";
import _ from "lodash";
import api from "../api";
import swal from "sweetalert2";
import Loader from "react-loader-spinner";

type Props = {
    path?: string; // ie. "promotion" etc...
    multipleFiles: boolean;
    prevUrl: string;
    onFileUpload: (url: string, data: any) => void;
    showsInputUrl?: boolean;
    showFilePreview?: boolean;
    allowedFiles?: string | string[];
    overrideMaxFileSize?: number;
};
type State = {
    url: string | null;
    loading: boolean;
};

const DEFAULT_MAX_FILE_SIZE = 300 * 1000; // 300 kb

class FileUpload extends React.Component<Props, State> {
    state: State = {
        url: null,
        loading: false,
    };

    onDrop = async (files: File[]) => {
        let { path } = this.props;
        let MAX_FILE_SIZE =
            this.props.overrideMaxFileSize || DEFAULT_MAX_FILE_SIZE;
        this.setState({ loading: true });
        try {
            for (let file of files) {
                if (MAX_FILE_SIZE < file.size) {
                    void swal.fire(
                        "Cannot Upload File",
                        `Max file size is 300 kb, but yours is ${
                            file.size / 1000
                        } kb.`,
                        "error"
                    );
                    // Skip this file if it is too big
                    continue;
                }

                // Create form data and upload file to server
                var formData = new FormData();
                formData.append("file", file);
                let res = await api.uploads.file(formData, path || "files");

                // Set the url and upload props
                let url = res.data.location;
                this.setState({ url });
                this.props.onFileUpload(url, res.data);
            }
        } catch (err) {
            void swal.fire({
                title: "Error occurred uploading.",
                text: _.get(err, "response.data.message", "Unknown error."),
                type: "error",
            });
        }

        this.setState({ loading: false });
    };
    renderDropMessage = () => {
        let { showFilePreview, prevUrl } = this.props;
        let { url, loading } = this.state;
        if (loading) {
            return (
                <span>
                    Loading <br />
                    <Loader
                        type="Oval"
                        color="#00BFFF"
                        height="80"
                        width="100"
                    />
                </span>
            );
        }
        if (!showFilePreview || (!url && !prevUrl)) {
            return (
                <p>Drag 'n' drop some files here, or click to select files</p>
            );
        }

        return (
            <img
                src={!url ? prevUrl : url}
                style={{
                    width: "80%",
                    height: "auto",
                }}
            />
        );
    };
    render() {
        let {
            multipleFiles = false,
            prevUrl,
            allowedFiles,
            showsInputUrl = true,
        } = this.props;
        return (
            <div>
                {showsInputUrl && (
                    <input
                        style={{ width: "100%" }}
                        placeholder="image url"
                        type="text"
                        value={!prevUrl ? "" : prevUrl}
                        onChange={e => {
                            this.props.onFileUpload(e.target.value, {});
                        }}
                    />
                )}
                <ReactDropzone
                    accept={allowedFiles}
                    multiple={multipleFiles}
                    onDrop={this.onDrop}
                >
                    {({ getRootProps, getInputProps }) => (
                        <section
                            style={{
                                padding: 50,
                                backgroundColor: "#f5f5f5",
                                borderRadius: 5,
                                textAlign: "center",
                                border: "2px solid #4d4d4d",
                            }}
                            {...getRootProps()}
                        >
                            <input {...getInputProps()} />
                            {this.renderDropMessage()}
                        </section>
                    )}
                </ReactDropzone>
            </div>
        );
    }
}
export { FileUpload };
