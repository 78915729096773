import { IChain, IUser } from "@snackpass/snackpass-types";
import { compose, map, filter, prop } from "lodash/fp";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import { fetchChains, getFoodhallList } from "src/redux/chains";
import { getPathFromState } from "src/utils/Helpers";

type FoodhallPickerProps = {
    dispatchOnChange: (selectedFoodhallId: string | null) => {
        type: string;
        selectedFoodhallId: string | null;
    };
};

const FoodhallPicker = ({ dispatchOnChange }: FoodhallPickerProps) => {
    const currentUser: IUser = getPathFromState("user");
    const dispatch = useDispatch();
    const _fetchChains = compose(dispatch, fetchChains);

    useEffect(() => {
        if (currentUser._id) {
            _fetchChains();
        }
    }, [currentUser]);

    const foodhalls = useSelector(getFoodhallList);
    const foodhallOptions = map(
        (foodhall: IChain) => ({ label: foodhall.name, value: foodhall._id }),
        foodhalls
    );

    const onChange = compose(dispatch, dispatchOnChange, prop("value"));

    return (
        <Select
            className="snackpass__react-select"
            placeholder="Pick a foodhall to start"
            options={foodhallOptions}
            onChange={onChange}
        />
    );
};

export default FoodhallPicker;
