import * as Sentry from "@sentry/browser";
import { SystemColors } from "@snackpass/design-system";
import {
    IPurchase,
    PaymentProvider,
    StorePurchasedAt,
    FoodHallPurchasedAt,
    IInStoreCharge,
    IInStoreChargeType,
    Integration,
    DeliveryMetadata,
    ICharge,
    IStripeCharge
} from "@snackpass/snackpass-types";
import { prop, propOr } from "lodash/fp";
import moment from "moment-timezone";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Icon } from "semantic-ui-react";
import swal from "sweetalert2";

import api from "../../api";
import { Colors } from "../../utils/Colors";
import * as Helpers from "../../utils/Helpers";

import Refund from "../../containers/Refund";
import { Text, View } from "../../SharedComponents";
import PartialRefund from "../PartialRefund";
import PurchaseStatus from "../PurchaseStatus";

import Accounting from "./Accounting";
import CustomerDetails from "./CustomerDetails";
import Items from "./Items";
import { Label } from "../StoreRow/components/Label";

import { ActionButton } from "./ActionButton";
import { filterNulls } from "src/utils/arrays/filterNulls";

export const getTransactionSourceLabel = (purchase: IPurchase) => {
    let colorSet;
    let label = "";

    if (purchase.transactionSource === "app") {
        colorSet = "snackpass";
        label = "App 📱";
    } else if (purchase.transactionSource === "kiosk") {
        colorSet = "apricot";
        label = "Kiosk 📺";
    } else if (purchase.transactionSource === "online") {
        colorSet = "dragonfruit";
        label = "Online 💻";
    }

    return (
        <Label
            key={label}
            text={label}
            visible={true}
            // @ts-ignore
            textColor={SystemColors[`${colorSet}50`]}
            // @ts-ignore
            backgroundColor={SystemColors[`${colorSet}90`]}
        />
    );
};

const _getPickupTime = (purchase: IPurchase) => {
    let fulfillmentType = (purchase.fulfillment || "unknown").toLowerCase();
    fulfillmentType =
        fulfillmentType.charAt(0).toUpperCase() + fulfillmentType.slice(1);

    return purchase.pickupTime ? (
        <Text small>
            {fulfillmentType +
                " Time: " +
                Helpers.formatDateTime(purchase.pickupTime)}
        </Text>
    ) : null;
};

const _specialGiftDetails = (purchase: IPurchase) => (
    <View>
        <Text>
            This purchase created a special gift that can be redeemed by a
            different user.
        </Text>
        <Text>
            The current status of the special gift is{" "}
            {purchase.specialGiftState}.
        </Text>

        <hr />
    </View>
);

const env = process.env.REACT_APP_ENV === "production" ? "v2" : "sandbox";
const batchLink =
    "https://console.firebase.google.com/u/0/project/futura-c7f12/database/futura-c7f12/data/";
const _batchDetails = (purchase: IPurchase) =>
    purchase.batchKey ? (
        <View>
            <Text>Batch Details</Text>
            <a href={`${batchLink}/${env}/${purchase.batchKey}`}>
                <Text small color={Colors.blue}>
                    {`Batch Key: ${purchase.batchKey}`}
                </Text>
            </a>
            <Text small>{`Time Placed: ${Helpers.formatDateTimeLong(
                new Date(purchase.createdAt)
            )}`}</Text>
            <Text small>{`Time Scheduled: ${Helpers.formatDateTimeLong(
                purchase.purchaseLiveAt || new Date()
            )}`}</Text>
            <hr />
        </View>
    ) : null;

const _purchaseOverview = (purchase: IPurchase) => (
    <View>
        <Text>
            {purchase.isSpecialGift ? "Special Gift Purchase 🛍" : "Purchase"}
        </Text>
        {propOr(false, "catering.isCatering", purchase) && (
            <Text small>Catering Purchase</Text>
        )}
        {prop("foodHall", purchase) && <Text small>FoodHall Purchase</Text>}
        <Text small>Order #{purchase.receiptNumber}</Text>
        <Text small>
            {Helpers.formatDateTimeLong(new Date(purchase.createdAt))}
        </Text>
        <Text small>{purchase.fulfillment}</Text>
        {prop("tableNumber", purchase) && (
            <Text small>Table {purchase.tableNumber}</Text>
        )}
        <Text small>
            {purchase.fulfillment === "DELIVERY"
                ? purchase.deliveryAddress
                : ""}
        </Text>
        <Text small>
            + {purchase.pointsEarned} {purchase.store && purchase.store.emoji}
        </Text>
        {_deliveryDetails(purchase)}
        {_getPickupTime(purchase)}
    </View>
);

const _getAdminUrl = (deliveryInfo: DeliveryMetadata | null) => {
    if (!deliveryInfo) {
        return null;
    }

    const deliveryId = deliveryInfo?.deliveryId;
    const provider = deliveryInfo?.provider;

    if (!deliveryId) {
        return null;
    }

    if (provider === Integration.Postmates) {
        return `https://partner.postmates.com/dashboard/home/deliveries/${deliveryId}`;
    }

    if (provider === Integration.Doordash) {
        return (
            deliveryInfo?.trackingUrl ||
            `https://www.doordash.com/drive/portal/order/${deliveryId}`
        );
    }

    return null;
};

const _deliveryDetails = (purchase: IPurchase) => {
    if (purchase.fulfillment !== "DELIVERY" || !purchase.deliveryInfo) {
        return null;
    }

    const pickupEta = purchase.deliveryInfo?.pickupEta
        ? moment(purchase.deliveryInfo?.pickupEta).format("h:mma (M/D)")
        : null;

    const dropoffEta = purchase.deliveryInfo?.dropoffEta
        ? moment(purchase.deliveryInfo?.dropoffEta).format("h:mma (M/D)")
        : null;

    const adminUrl = _getAdminUrl(purchase.deliveryInfo);

    return (
        <div style={{ marginTop: 30 }}>
            <Text>Delivery Info</Text>
            <br />
            <Text small>Provider: {purchase.deliveryInfo?.provider}</Text>
            <br />
            <Text small>
                ETA: pickup: {pickupEta || "n/a"}, dropoff:{" "}
                {dropoffEta || "n/a"}
            </Text>
            <br />
            <Text small>Delivery ID: {purchase.deliveryInfo?.deliveryId}</Text>
            <br />
            {adminUrl && (
                <Text small>
                    <a target="_blank" href={adminUrl} rel="noreferrer">
                        Open in {purchase.deliveryInfo?.provider || "provider"}{" "}
                        dashboard
                    </a>
                </Text>
            )}
            <hr />
        </div>
    );
};

const _storeDetails = (store: StorePurchasedAt) => (
    <View>
        <Text>Store</Text>
        <Text bold small>
            Name: <Text small>{store.name}</Text>
        </Text>
        <Text bold small>
            Address: <Text small>{store.address}</Text>
        </Text>
        {store.phoneNumber ? (
            <Text bold small>
                Phone Number:{" "}
                <Text small>
                    {Helpers._displayNumber(store.phoneNumber || "", "tel")}
                </Text>
            </Text>
        ) : null}
        <hr />
    </View>
);
const _foodHallDetails = (foodHall: FoodHallPurchasedAt) => (
    <View>
        <Text>FoodHall</Text>
        <Text small>Name: {foodHall.name}</Text>
        <Text small>Chain ID: {foodHall.chainId}</Text>
        <hr />
    </View>
);

const _miscDetails = (purchase: IPurchase) => (
    <View>
        <Text>Misc.</Text>
        <Text small>Purchase ID: {purchase._id}</Text>
        <Text small>Store ID: {purchase.store && purchase.store._id}</Text>
        {prop("groupId", purchase) && (
            <Text small>Group ID: {purchase.groupId}</Text>
        )}
        <hr />
    </View>
);

const _storeCardFee = (storeChargeFee: any) =>
    storeChargeFee ? (
        <View>
            <Text>Store Card Charge Fee</Text>
            <Text small>Flat Fee: {Helpers.toDollar(storeChargeFee.flat)}</Text>
            <Text small>
                Percent Fee: {Helpers.toDollar(storeChargeFee.percent)}
            </Text>
            <Text small>Total: {Helpers.toDollar(storeChargeFee.total)}</Text>
            <hr />
        </View>
    ) : null;

const _paymentProvider = (
    paymentProviderId: PaymentProvider | undefined,
    charges: Array<ICharge>
) => {
    const paymentIntentIDs = filterNulls(
        charges.map(
            (charge) => (charge as IStripeCharge | undefined)?.paymentIntentId
        )
    );
    const chargeIds = filterNulls(charges.map((charge) => charge.chargeId));
    return paymentProviderId ? (
        <View>
            <Text>Payment Provider</Text>
            <Text small>{paymentProviderId || "n/a"}</Text>
            {paymentIntentIDs.length !== 0 && (
                <Text small>
                    Stripe Payment Intent: {paymentIntentIDs.join(", ")}
                </Text>
            )}
            {chargeIds.length !== 0 && (
                <Text small>Charge ID: {chargeIds.join(", ")}</Text>
            )}
            <hr />
        </View>
    ) : null;
};

const _paymentProviderCardFee = (
    paymentProviderId: PaymentProvider | undefined,
    paymentProviderFee: any
) =>
    paymentProviderFee && paymentProviderId ? (
        <View>
            <Text>Payment Provider Charge Fee</Text>
            <Text small>
                Flat Fee: {Helpers.toDollar(paymentProviderFee.flat)}
            </Text>
            <Text small>
                Percent Fee: {Helpers.toDollar(paymentProviderFee.percent)}
            </Text>
            <Text small>
                Total: {Helpers.toDollar(paymentProviderFee.total)}
            </Text>
        </View>
    ) : null;

const styles = {
    transactionSourceView: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center"
    } as React.CSSProperties
};

type Props = {
    purchase: IPurchase;
    dispatch?: () => void;
    closeModal: () => void;
    replacePurchase: (purchase: IPurchase) => void;
    showModal: (modal: string, params: any) => void;
};

const PurchaseDetails = ({
    purchase,
    closeModal,
    showModal,
    replacePurchase,
    dispatch
}: Props) => {
    let timerRef = useRef<NodeJS.Timeout>();

    // Only allow refunding purchases that have not already been refunded and
    // are paid for (i.e. not `unpaid`).
    const allowRefund =
        !purchase.refund &&
        purchase.paymentProviderId !== PaymentProvider.unpaid;

    const [showFullRefund, setShowFullRefund] = useState(false);
    const [showPartialRefund, setShowPartialRefund] = useState(false);

    const updatePurchase = useCallback(
        () =>
            api.purchases
                .getPurchase(purchase._id)
                .then((res) => replacePurchase(res.data.purchase))
                .catch(Sentry.captureException),
        [replacePurchase, purchase._id]
    );

    useEffect(() => {
        // Code below updates purchase state every 1 second
        // This is done specifically for the start/delay/finish buttons in
        // order to avoid inconsistencies between POS and Snackface.
        timerRef.current = setInterval(() => {
            if (allowRefund) {
                updatePurchase();
            }
        }, 1000);

        return () => {
            clearInterval(timerRef.current);
        };
    }, [allowRefund, updatePurchase]);

    const onPressFullRefund = () => setShowFullRefund(true);

    const onPressPartialRefund = () => setShowPartialRefund(true);

    const onPressGoBackFromRefund = () => {
        setShowFullRefund(false);
        setShowPartialRefund(false);
    };

    const PartialRefundSection = (
        <PartialRefund
            purchase={purchase}
            onSubmit={(refundedAmount: number) => {
                setShowPartialRefund(false);
                return swal.fire({
                    title: "SUCCESSFULLY PARTIALLY REFUNDED!",
                    text: Helpers.toDollar(refundedAmount),
                    type: "success",
                    confirmButtonColor: Colors.green,
                    confirmButtonText: "Ok"
                });
            }}
            closeModal={closeModal}
        />
    );

    const FullRefundSection = (
        <Refund
            purchase={purchase}
            onSubmit={() => {
                setShowFullRefund(false);
                return swal.fire({
                    title: "SUCCESSFULLY REFUNDED!",
                    type: "success",
                    confirmButtonColor: Colors.green,
                    confirmButtonText: "Ok"
                });
            }}
            closeModal={closeModal}
        />
    );

    const RefundButtons = !allowRefund ? null : (
        <div style={{ position: "absolute", right: 0, top: 70 }}>
            {!showFullRefund && !showPartialRefund ? (
                <>
                    <ActionButton
                        isDestructiveStyle={true}
                        label="Partial Refund"
                        onPress={onPressPartialRefund}
                    />
                    <ActionButton
                        isDestructiveStyle={true}
                        label="Refund"
                        onPress={onPressFullRefund}
                    />
                </>
            ) : (
                <ActionButton
                    isDestructiveStyle={false}
                    label="Go back"
                    onPress={onPressGoBackFromRefund}
                />
            )}
        </div>
    );

    // TODO: only show this if the purchase is disputed, this will be added when
    // we have Stripe webhook handler for disputes.
    const DisputeSection =
        !purchase.submittedDisputeEvidence &&
        purchase.paymentProviderId === "stripe" ? (
            <ActionButton
                isDestructiveStyle={true}
                label={"Fight Dispute"}
                onPress={() => showModal("DisputeEvidenceModal", { purchase })}
            />
        ) : null;

    const KioskDetailsSection =
        purchase.transactionSource !== "kiosk" ? null : (
            <View>
                <Text>Kiosk-Specific Details</Text>
                {purchase.paymentProviderId === "inStore" ? (
                    <Text bold small>
                        {(purchase.charges[0] as IInStoreCharge | undefined)
                            ?.type === IInStoreChargeType.cash
                            ? "Cash Payment"
                            : "In-store Payment (other)"}
                    </Text>
                ) : (
                    <Text small>
                        {purchase.charges.length
                            ? purchase.charges[0].chargeId
                            : "No Charges"}
                    </Text>
                )}
                <hr />
            </View>
        );

    return (
        <View>
            <span style={{ position: "absolute", top: 10, right: 10 }}>
                <Icon
                    onClick={closeModal}
                    name="close"
                    color={"grey"}
                    size={"big"}
                />
            </span>

            <View style={styles.transactionSourceView}>
                {getTransactionSourceLabel(purchase)}
            </View>

            {purchase.isScheduledOrder && purchase.scheduledDate && (
                <Text small>
                    Scheduled for:{" "}
                    {Helpers.formatDateTimeLong(purchase.scheduledDate)}
                </Text>
            )}

            {RefundButtons}

            {showFullRefund ? (
                FullRefundSection
            ) : showPartialRefund ? (
                PartialRefundSection
            ) : (
                <View>
                    {_purchaseOverview(purchase)}
                    <hr />
                    {KioskDetailsSection}
                    <CustomerDetails purchase={purchase} />
                    {purchase.isSpecialGift && _specialGiftDetails(purchase)}
                    <PurchaseStatus purchase={purchase} />
                    {_batchDetails(purchase)}
                    <Items purchase={purchase} />
                    <hr />
                    <Accounting purchase={purchase} />
                    <hr />
                    {purchase.store && _storeDetails(purchase.store)}
                    {purchase.foodHall && _foodHallDetails(purchase.foodHall)}
                    {_miscDetails(purchase)}
                    {_storeCardFee(purchase.storeChargeFee)}
                    {_paymentProvider(
                        purchase.paymentProviderId,
                        purchase.charges
                    )}
                    {_paymentProviderCardFee(
                        purchase.paymentProviderId,
                        purchase.paymentProviderChargeFee
                    )}
                    {DisputeSection}
                </View>
            )}
        </View>
    );
};
export default PurchaseDetails;
