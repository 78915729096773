import { takeLatest, call, put } from "redux-saga/effects";
import { API_GET_CHAINS, setChains, FetchChains } from "../redux/chains";
import * as Sentry from "@sentry/browser";
import API from "../api";

function* _fetchChains(action: FetchChains) {
    try {
        // @ts-expect-error 'yield' expression implicitly results in an 'any' type because its containing generator lacks a return-type annotation.ts(7057)
        let res = yield call(API.chains.list, action.query);
        yield put(setChains(res.data.chains));
    } catch (error) {
        Sentry.captureException(error);
    }
}

export default function* chainsSaga() {
    yield takeLatest(API_GET_CHAINS, _fetchChains);
}
