import { Card } from "antd";
import React, { useState, useEffect, CSSProperties } from "react";
import { ITochiFile, ITochiAnimation } from "@snackpass/snackpass-types";
import { Styles } from "src/utils";
import api from "src/api";
import { TochiFileUpload } from "./components/TochiFileUpload";
import { TochiFilesTable } from "./components/TochiFilesTable";
import { TochiFileToAnimation } from "./components/TochiFileToAnimation";
import InfoButton from "../components/InfoButton";
import { Button } from "src/SharedComponents";

const infoText: string[] = [
    "Drag and drop files into the uploader (or click the field for a dialogue box)." +
        "You can add files in multiple batches. This takes GLBs or JSONs.",
    "Set a version number in the format X-Y in the input box.",
    "The overwrite switch will overwrite any files with the same version number that you enter." +
        "Do not do this unless you're certian about it.",
    "The create new files switch will create new files for all models where a file with the same name does not exist already." +
        "The first version of any new files will be the version you're entering right now.",
    "Click refresh to load the files table. It will show each file and which versions exist for that file",
];

const TochiFiles = () => {
    const [refreshing, setRefreshing] = useState(false);
    const [tochiAnims, setTochiAnims] = useState<ITochiAnimation[]>([]);
    const [tochiFiles, setTochiFiles] = useState<ITochiFile[]>([]);

    useEffect(() => {
        const timer = setTimeout(() => {
            getFiles();
            getAnimations();
        }, 1000);
        return () => clearTimeout(timer);
    }, [refreshing]);

    const getFiles = async () => {
        api.tochiFiles
            .getFiles()
            .then(res => setTochiFiles(res.data.files))
            .catch(err => {
                console.log(err);
            });
    };

    const getAnimations = async () => {
        api.tochiFiles
            .getAnimations()
            .then(res => setTochiAnims(res.data.anims))
            .catch(err => {
                console.log(err);
            });
    };

    const handleRefresh = () => {
        setRefreshing(!refreshing);
    };

    return (
        <div>
            <div>
                <Card
                    style={styles.card}
                    headStyle={styles.head}
                    title="Tochi Files Upload 🔨"
                    extra={
                        <InfoButton
                            title="Tochi Files Info"
                            infoText={infoText}
                        />
                    }
                >
                    <TochiFileUpload />
                </Card>
            </div>
            <div style={styles.lowerCard}>
                <Card
                    style={styles.card}
                    headStyle={styles.head}
                    title="Tochi Files 🔨"
                    extra={<Button label="refresh" onPress={handleRefresh} />}
                >
                    <TochiFilesTable
                        tochiFiles={tochiFiles}
                        tochiAnims={tochiAnims}
                    />
                </Card>
            </div>
            <div style={styles.lowerCard}>
                <Card
                    style={styles.card}
                    headStyle={styles.head}
                    title="Tochi Animations 🔨"
                >
                    <TochiFileToAnimation
                        tochiFiles={tochiFiles}
                        tochiAnims={tochiAnims}
                    />
                </Card>
            </div>
        </div>
    );
};

const styles = {
    card: {
        width: "90%",
        margin: "0 auto",
        borderRadius: 5,
        ...Styles.shadowLight,
    } as CSSProperties,
    head: {
        fontSize: 22,
        borderBottom: "none",
    } as CSSProperties,
    lowerCard: { marginTop: 25 } as CSSProperties,
};

export default TochiFiles;
