import { connectModal } from "redux-modal";
import Modal from "react-responsive-modal";
import React from "react";
import { IRegion } from "@snackpass/snackpass-types";

import RegionForm from "../containers/RegionForm";
import { Colors } from "../utils";

const styles = {
    modal: {
        overlay: {
            background: "rgba(0,0,0,0.23)",
        },
        modal: {
            background: Colors.white,
            padding: 20,
            boxShadow: "0 2px 40px 0 rgba(0, 0, 0, 0.3)",
            borderRadius: 5,
            overflow: "hidden",
            marginBottom: 100,
            width: "85%",
        },
    },
};

export interface RegionFormModalProps {
    show: boolean;
    handleHide: () => void;
    handleDestroy: () => void;
    regionObj?: IRegion;
    editMode: boolean;
    onSuccess: (region: IRegion) => void;
}

const RegionFormModal = ({
    show,
    handleHide,
    regionObj,
    editMode,
    onSuccess,
}: RegionFormModalProps) => {
    return (
        <Modal
            onClose={() => {
                handleHide();
            }}
            open={show}
            styles={styles.modal}
        >
            <RegionForm
                editMode={editMode}
                regionObj={regionObj}
                onSuccess={onSuccess}
            />
        </Modal>
    );
};

export default connectModal({
    name: "RegionFormModal",
})(RegionFormModal);
