import { CSSProperties } from "react";
import Modal from "react-responsive-modal";
import { connectModal, InjectedProps } from "redux-modal";
import { Colors } from "../utils/Colors";
import { IStore } from "@snackpass/snackpass-types";
import { StoreAdminList } from "../components/StoreAdminList";

const styles = {
    modal: {
        overlay: {
            display: "flex",
            justifyContent: "center"
        },
        modal: {
            background: Colors.white,
            padding: 20,
            boxShadow: "0 2px 40px 0 rgba(0, 0, 0, 0.3)",
            overflowY: "scroll",
            maxWidth: "none",
            width: "100%",
            height: "100%",
            borderRadius: 5,
            alignSelf: "stretch"
        } as CSSProperties
    }
};

interface Props extends InjectedProps {
    storeObj: IStore;
    onSuccess: (store: IStore) => void;
    stores: IStore[];
}

const AdminModal = (props: Props) => (
    <Modal onClose={props.handleHide} open={props.show} styles={styles.modal}>
        <StoreAdminList storeObj={props.storeObj} />
    </Modal>
);

export default connectModal({ name: "AdminModal" })(AdminModal);
