import { IUser } from "@snackpass/snackpass-types";
import _ from "lodash";
import { storesNamesMap } from "src/components/AdminForm/types";

export const getStoreListForUserPath =
    (path: string) => (user: IUser, storeNameById: storesNamesMap) =>
        (_.get(user, path, []) as string[]).map((storeId) => ({
            value: storeId,
            label: storeNameById[storeId]
        }));

export const getSelectedStores = getStoreListForUserPath(
    "snackpassPermissions.storeIds"
);

export const getOwnedStores = getStoreListForUserPath("permissions.isOwner");
export const getPayoutsForStores = getStoreListForUserPath(
    "permissions.hasPayouts"
);
