import React from "react";
import { FieldProps } from "formik";
import { FormValues } from "../types";
import moment from "moment";
import ReactDateTime from "react-datetime";
import _ from "lodash";
import { useSelector } from "react-redux";
import { getRegions } from "../../../redux/regions";
import { SnackpassTimezoneEnum } from "@snackpass/snackpass-types";

const DEFAULT_TIMEZONE = SnackpassTimezoneEnum.newYork;
const MyFormikDateTime = ({
    field,
    form,
    ...props
}: FieldProps<FormValues>) => {
    // all regions should exist in REGIONS and have an associated timezone
    let storeTimezone = DEFAULT_TIMEZONE;
    let regions = useSelector(getRegions);
    if (form.values.region) {
        const region = regions.find(
            (item: any) => item.slug === form.values.region
        );
        storeTimezone = region
            ? (region.tz as SnackpassTimezoneEnum)
            : DEFAULT_TIMEZONE;
    }

    const onFieldChange = (value: any) => {
        let dateValue = value;

        // if the date field isn't in a valid date format,
        // react-datetime's onChange handler returns a string
        // otherwise it returns a moment object
        // this is why we can't override DateTime's onChange
        // prop with Formik's field.onChange
        if (value instanceof moment) {
            dateValue = moment(value).tz(storeTimezone);

            if (_.get(props, "useLocalStartOfDay")) {
                dateValue = dateValue.startOf("day");
            }

            dateValue = dateValue.toDate();
            form.setFieldValue(field.name, dateValue);
        } else {
            // if the user removed the value
            form.setFieldValue(field.name, null);
        }
    };
    const onFieldBlur = () => {
        // use try/catch because sometimes, if nested object, the field will not
        // exist yet.
        try {
            form.setFieldTouched(field.name, true);
        } catch (err) {
            console.log(err);
        }
    };
    // if a store has a createdAt time, the date-picker only allows dates from
    // the day after the createdAt day and onwards.
    const isValid = (current: Date) => {
        let dateMin = _.get(props, "dateMin");
        if (!dateMin) {
            return true;
        }
        return moment(current).isAfter(moment(dateMin).subtract(1, "days"));
    };
    // assumes local (Pacific) if nothing set, then converts to displayTimeZone
    return (
        <ReactDateTime
            onChange={onFieldChange}
            onClose={onFieldBlur}
            displayTimeZone={storeTimezone}
            timeFormat={!_.get(props, "isDateOnly")}
            isValidDate={isValid}
            value={field.value ? new Date(field.value) : undefined}
        />
    );
};

export default MyFormikDateTime;
