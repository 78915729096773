import React, { Component } from "react";

import { View, Text } from "../../SharedComponents";

import StoreTabletsTable from "./components/StoreTabletsTable";
import * as Styles from "../../utils/Styles";
import { ReduxUtil, Colors } from "../../utils";
import API from "../../api";
import _ from "lodash";
import { ITablet as IStoreTablet, IStore } from "@snackpass/snackpass-types";
import { isMobile } from "react-device-detect";

type SelectItem = {
    value: string;
    label: string;
};

type Props = {
    storeToPrimaryTablet: { [storeId in string]?: string };
    stores: IStore[];
    storeItems: Array<SelectItem>;
    storeTablets: Array<IStoreTablet>;
    setTabletSilentMode: (tabletId: string, silentMode: boolean) => void;
    setPrimaryTablet: (storeTabletId: string) => void;
    updateJAMFTablet: (storeTabletId: string) => void;
    removeStoreTablet: (storeTabletId: string) => void;
    dispatch: Function;
};
type State = {
    showModal: boolean;
    selectedStoreTablet: IStoreTablet | null;
};
export default class StoreTablets extends Component<Props, State> {
    state: State = { showModal: false, selectedStoreTablet: null };

    componentDidMount() {
        setTimeout(() => ReduxUtil.loadStoreTablets(this.props.dispatch), 250);
    }

    onDelete = async (storeTabletId: string) => {
        alert(
            "Sorry, direct StoreTablet management is no longer allowed -- please use RDB devices page to manage order hubs"
        );
    };

    render() {
        const { storeTablets, storeToPrimaryTablet } = this.props;
        return (
            <View
                style={{
                    width: "100%",
                    display: "block",
                    overflowY: "hidden",
                    padding: isMobile ? 5 : 20,
                    backgroundColor: Colors.white,
                    borderRadius: 8,
                    ...Styles.shadowLight,
                    alignItems: "center",
                    alignSelf: "center",
                    textAlign: "center"
                }}
            >
                <Text large>Store Tablets 📱</Text>
                <br />
                <br />
                <View
                    style={{
                        ...Styles.row,
                        position: "relative",
                        justifyContent: "center",
                        fontWeight: "bold",
                        color: Colors.red
                    }}
                >
                    Note: The new store builder has a tablet management section.
                    Use that instead if you can! 🙇‍♂️
                </View>
                <View
                    style={{
                        ...Styles.row,
                        position: "relative",
                        justifyContent: "space-between"
                    }}
                >
                    <View
                        style={{
                            justifyContent: "center"
                        }}
                    />
                </View>
                <StoreTabletsTable
                    storeToPrimaryTablet={storeToPrimaryTablet}
                    storeTablets={storeTablets}
                    setTabletSilentMode={this.props.setTabletSilentMode}
                    setPrimaryTablet={this.props.setPrimaryTablet}
                    updateJAMFTablet={this.props.updateJAMFTablet}
                    onDelete={this.onDelete}
                    onEdit={(storeTablet: any) =>
                        this.setState({
                            showModal: true,
                            selectedStoreTablet: storeTablet
                        })
                    }
                />
            </View>
        );
    }
}
