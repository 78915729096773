import { FormikBag, withFormik } from "formik";
import swal from "sweetalert2";
import { FormBody } from "./FormBody";
import { validationSchema, initializeFields } from "./config";
import { IStore, IUser } from "@snackpass/snackpass-types";
import { FormValues } from "./types";
import API from "../../api/index";

interface FormProps {
    storeObj?: IStore;
    admin?: IUser;
    email?: string;
    promotingUser: boolean;
    onSuccess: Function;
    setAdmin: Function;
    addAdmin: Function;
    removeAdmin: Function;
    dispatch: Function;
    stores: IStore[];
}

const getUpdatedSnackpassPermissions = (
    formikBag: FormikBag<FormProps, FormValues>,
    values: FormValues
) => {
    const existingPermissions = (formikBag.props.admin &&
        formikBag.props.admin.snackpassPermissions) ?? {
        storeIds: [],
        storeRoles: []
    };

    const role = values.snackpassPermissions.storeRole || "";

    const dedupedStoreIds = Array.from(
        new Set([
            ...existingPermissions.storeIds,
            formikBag.props.storeObj?._id
        ])
    );

    let newStoreRoles = [];
    //duplicates storeRoles and appropriately updates or appends new StoreRole
    newStoreRoles = existingPermissions.storeRoles.map((storeRole) => {
        return {
            storeId: storeRole.storeId,
            role: storeRole.role
        };
    });
    if (role) {
        const newStoreRolesUser = newStoreRoles.find(
            (user) => user.storeId === formikBag.props.storeObj?._id
        );
        if (newStoreRolesUser) {
            newStoreRolesUser.role = role;
        } else if (formikBag.props.storeObj?._id) {
            newStoreRoles.push({
                storeId: formikBag.props.storeObj?._id,
                role
            });
        }
    }

    return {
        isAdmin: true,
        accounting: values.snackpassPermissions.accounting,
        team: existingPermissions!.team,
        storeIds: dedupedStoreIds,
        storeRoles: newStoreRoles,
        isSnackpassEmployee: values.snackpassPermissions.isSnackpassEmployee
    };
};

//maybe put StoreBuilder/index.tsx functions here instead?
const AdminForm = withFormik<FormProps, FormValues>({
    enableReinitialize: true,
    mapPropsToValues: (props) => {
        return initializeFields(props.admin, props.storeObj?._id);
    },
    validationSchema,
    handleSubmit: async (values, formikBag) => {
        let submitValues: any = {
            user: {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                number: values.number || null,
                snackpassPermissions: getUpdatedSnackpassPermissions(
                    formikBag,
                    values
                )
            },
            communicationPreferences: [
                {
                    storeId: formikBag.props.storeObj?._id,
                    disabledSmsCommunications: Array.from(
                        values.disabledSmsCommunications
                    ),
                    disabledEmailCommunications: Array.from(
                        values.disabledEmailCommunications
                    )
                }
            ]
        };

        try {
            let response;
            // In edit mode
            if (formikBag.props.admin) {
                response = await API.admins.update(
                    formikBag.props.admin._id,
                    submitValues
                );
            } else {
                // In create mode
                response = await API.admins.create(submitValues);
            }

            const admin = response.data.user;

            if (
                formikBag.props.admin &&
                formikBag.props.admin.snackpassPermissions.isAdmin
            ) {
                formikBag.props.setAdmin(admin);
            } else {
                formikBag.props.addAdmin(admin);
            }

            let responseName = "created";
            if (formikBag.props.admin) {
                responseName = "updated";
            }

            swal.fire({
                title: `Admin ${responseName}`,
                type: "success"
            });
            formikBag.setSubmitting(false);

            formikBag.props.onSuccess();
        } catch (err) {
            alert(
                "Error submitting admin ❌ " +
                    JSON.stringify(err.response.data.message)
            );
            formikBag.setSubmitting(false);
        }
    },
    displayName: "AdminForm"
})(FormBody);

export default AdminForm;
