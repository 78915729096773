import { IPromotion } from "@snackpass/snackpass-types";
import { Dispatch } from "redux";
import api from "../api";
import * as Sentry from "@sentry/browser";
import { StoreState } from "./types";
// Constants

export const SET_PROMOTIONS = "SET_PROMOTIONS";
export const UPDATE_PROMOTION = "UPDATE_PROMOTION";
export const REMOVE_PROMOTION = "REMOVE_PROMOTION";
// Actions

interface SetPromotions {
    type: "SET_PROMOTIONS";
    promotions: IPromotion[];
}
export function setPromotions(promotions: IPromotion[]) {
    return { type: SET_PROMOTIONS, promotions };
}

interface UpdatePromotion {
    type: "UPDATE_PROMOTION";
    promotion: IPromotion;
}
export function updatePromo(promotion: IPromotion) {
    return { type: UPDATE_PROMOTION, promotion };
}

interface RemovePromotion {
    type: "REMOVE_PROMOTION";
    promotionId: string;
}
export function removePromotion(promotionId: string) {
    return { type: REMOVE_PROMOTION, promotionId };
}

export type FetchPromotions = () => Promise<any>;
export function fetchPromotions(query: any = {}) {
    return (dispatch: Dispatch) => {
        let params: any = query;
        return api.promotions
            .get(params)
            .then(response => {
                dispatch(setPromotions(response.data.promotions));
            })
            .catch(err => {
                Sentry.captureException(err);
                dispatch(setPromotions([]));
            });
    };
}

export function updatePromotion(promotionId: string, fields: any = {}) {
    return (dispatch: Dispatch) => {
        return api.promotions
            .update(promotionId, fields)
            .then(response => {
                dispatch(updatePromo(response.data.promotion));
            })
            .catch(err => {
                Sentry.captureException(err);
            });
    };
}

export type PromotionAction = SetPromotions | UpdatePromotion | RemovePromotion;

// Reducer
const initialState: IPromotion[] = [];

export function promotions(state = initialState, action: PromotionAction) {
    switch (action.type) {
        case UPDATE_PROMOTION:
            return state.map((promotion: IPromotion) => {
                // if this promotion is the promotion to set,
                // return the new promotion passed via action
                if (promotion._id === action.promotion._id) {
                    return action.promotion;
                }
                // otherwise just return the promotion as it isn't being
                // set / altered
                return promotion;
            });
        case REMOVE_PROMOTION:
            // return all promotions whose id is not the promotionId that is removed
            return state.filter(
                promotion => promotion._id !== action.promotionId
            );
        case SET_PROMOTIONS:
            return action.promotions;
        default:
            return state;
    }
}

export const getPromotions = (state: StoreState) => state.promotions;
