import React from "react";
import Modal from "react-responsive-modal";
import { connectModal } from "redux-modal";
import { Colors } from "../utils";
import { Text } from "src/SharedComponents";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

export interface GiftCardRefundErrorProps {
    handleHide: () => void;
}

const GiftCardRefundError = ({ handleHide }: GiftCardRefundErrorProps) => {
    return (
        <Modal
            onClose={handleHide}
            open={true}
            styles={styles.modal}
            showCloseIcon={true}
            closeOnOverlayClick={false}
        >
            <div style={styles.rowStyles}>
                <Text style={styles.textGCFeatures}>
                    There was a problem refunding your gift card, please try
                    again
                </Text>
            </div>
        </Modal>
    );
};

export default connectModal({ name: "GiftCardRefundError" })(
    GiftCardRefundError
);

const styles = {
    modal: {
        overlay: {
            background: "rgba(0,0,0,0.23)",
        },
        modal: {
            background: Colors.white,
            padding: 20,
            boxShadow: "0 2px 40px 0 rgba(0, 0, 0, 0.3)",
            borderRadius: 5,
            overflow: "hidden",
            width: "85%",
        },
    },
    textGCFeatures: {
        fontWeight: 500,
        textAlign: "center",
        justifyContent: "center",
        marginTop: "30px",
    } as CSSProperties,
    colStyles: {
        margin: "3%",
    } as CSSProperties,
    rowStyles: {
        justifyContent: "center",
        display: "flex",
    } as CSSProperties,
};
