import { connect } from "react-redux";
import { setAdmins, removeAdmin, addAdmin, setAdmin } from "../redux/admins";
import AdminForm from "../components/AdminForm";
import { Dispatch } from "redux";
import { StoreState } from "../redux/types";
import { IUser } from "@snackpass/snackpass-types";

const mapStateToProps = (state: StoreState) => {
    return {};
};

// create handler than dispatches an action
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setAdmins: (admins: IUser[]) => {
            dispatch(setAdmins(admins));
        },
        removeAdmin: (admin: string) => {
            dispatch(removeAdmin(admin));
        },
        setAdmin: (admin: IUser) => {
            dispatch(setAdmin(admin));
        },
        addAdmin: (admin: IUser) => {
            dispatch(addAdmin(admin));
        },
        dispatch,
    };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(AdminForm);

export default Container;
