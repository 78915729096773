import { compose, prop } from "lodash/fp";
import { Action } from "redux";

import api from "../../api";
import { setStores } from "../../redux/stores";

export const refreshStores = (dispatch: (a: Action) => any) => {
    const processApiResp = compose(dispatch, setStores, prop("data.stores"));
    // We should really rename this to stores.list().
    return api.stores.get().then(processApiResp);
};
