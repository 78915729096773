import { connect } from "react-redux";
import {
    setStores,
    removeStore,
    addStore,
    setStore,
    setPromotionsForStore,
} from "../redux/stores";
import StoreForm from "../components/StoreForm";
import { Dispatch } from "redux";
import { StoreState } from "../redux/types";
import { IPromotion, IStore } from "@snackpass/snackpass-types";

const mapStateToProps = (state: StoreState) => {
    return {};
};

// create handler than dispatches an action
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setStores: (stores: IStore[]) => {
            dispatch(setStores(stores));
        },
        removeStore: (store: string) => {
            dispatch(removeStore(store));
        },
        setStore: (store: IStore) => {
            dispatch(setStore(store));
        },
        addStore: (store: IStore) => {
            dispatch(addStore(store));
        },
        setPromotionsForStore: (promotions: IPromotion[], storeId: string) => {
            dispatch(setPromotionsForStore(promotions, storeId));
        },
        dispatch,
    };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(StoreForm);

export default Container;
