import { ITochiItem, ITochiObjectItem } from "@snackpass/snackpass-types";
import { Form, Formik, FormikActions, FormikProps } from "formik";
import _, { divide } from "lodash";
import React, { useEffect, useState, CSSProperties } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import Swal from "sweetalert2";
import api from "../../../../../api";
import { addTochiItem, updateTochiItem } from "../../../../../redux/tochiItems";
import { Button, Input } from "../../../../../SharedComponents";
import { MySelect } from "../../../../../SharedComponents/Select";
import FormikHelpers from "../../../../../utils/FormikHelpers";
import { TochiMaterialsForm } from "../../../components/TochiMaterialsForm";
import {
    initializeFields,
    itemHideSubmeshOptions,
    itemRarityOptions,
    itemTypeOptions,
    itemZoneOptions,
    materialTypeOptions,
    validationSchema,
    itemCategoryOptions,
    giftTextByCategory,
    itemTabOptions,
    platformOffsetOptions,
} from "./config";
import { FormValues } from "./types";
import { Colors } from "../../../../../utils";

export interface TochiItemFormProps {
    editMode?: any;
    item?: ITochiItem;
    onSuccess: (chain: ITochiItem) => void;
}

const TochiItemForm = (props: TochiItemFormProps) => {
    const [fileOptions, setFileOptions] = useState([] as any);
    const dispatch = useDispatch();

    const getTochiFilesFunc = () => {
        const fileCall = async () => {
            api.tochiFiles
                .getFiles()
                .then(res => {
                    let files: any[] = [];
                    let _files = res.data.files;
                    for (const f of _files) {
                        if (!f.name.includes("ANIM"))
                            files.push({ label: f.name, value: f.name });
                    }
                    setFileOptions(files);
                })
                .catch(err => {
                    console.log(err);
                });
        };
        fileCall();
    };
    useEffect(() => {
        getTochiFilesFunc();
    }, []);

    const _onSubmit = onSubmit(props, dispatch);

    return (
        <Formik
            enableReinitialize={true}
            validationSchema={validationSchema}
            initialValues={initializeFields(props.item as ITochiObjectItem)}
            onSubmit={_onSubmit}
        >
            {(formProps: FormikProps<FormValues>) => {
                const handleCategorySelect = (value: string) => {
                    formProps.values.droppedByText = giftTextByCategory[value];
                };

                const errorSummary = renderErrorSummary(formProps.errors);
                const submitSection = renderSubmitSection(props, formProps);
                return (
                    <Form onKeyPress={ignoreEnterKey}>
                        <Header label="General" />
                        <Input
                            name="name"
                            label="Item Name"
                            description="User facing item name"
                            type="text"
                            required
                        />
                        <Input
                            name="type"
                            label="Item Type"
                            description="What type of item is this?"
                            required
                            type="select"
                            options={itemTypeOptions}
                        />
                        <Input
                            name="zone"
                            label="Item Zone"
                            description="Where to position the item on the chicken (select multiple if needed)"
                            required
                            type="select"
                            isMulti={true}
                            options={itemZoneOptions}
                        />
                        {formProps.values.zone.includes("FEET") && (
                            <Input
                                name="platformOffset"
                                label="Platform Offset"
                                description="Feet only, the offset required for the model"
                                type="select"
                                isMulti={false}
                                options={platformOffsetOptions}
                            />
                        )}
                        <Input
                            name="hide"
                            label="Hide Submeshes"
                            description="Which submeshes are hidden by this item (select multiple if needed)"
                            type="select"
                            isMulti={true}
                            options={itemHideSubmeshOptions}
                        />
                        <Input
                            name="description"
                            label="Item Description"
                            description="User facing item description"
                            type="text"
                            required
                        />
                        <Header label="Inventory" />
                        <Input
                            name="rarity"
                            label="Item Rarity"
                            description="The rarity of this item"
                            required
                            type="select"
                            options={itemRarityOptions}
                        />
                        <Input
                            name="category"
                            label="Category"
                            description="Category of item (shirt, hoodie, etc) for auto filling fields"
                            type="select"
                            options={itemCategoryOptions}
                            onSelect={value => handleCategorySelect(value)}
                        />
                        <Input
                            name="droppedByText"
                            label="Dropped By Text"
                            description="User facing gift text for this item"
                            type="array"
                        />
                        <Input
                            name="tab"
                            label="Inventory Tab"
                            description="What tab in the user's inventory does this go under?"
                            type="select"
                            options={itemTabOptions}
                        />
                        <Input
                            name="thumbnail"
                            label="Thumbnail"
                            description="User facing wardrobe/store preview image"
                            type="file-upload"
                            overrideMaxFileSize={400 * 1000}
                            showFilePreview={true}
                            required
                        />
                        <Header label="3D Asset" />
                        <Input
                            name="asset.rig.name"
                            label="Asset Model"
                            description="User facing model for item"
                            type="select"
                            options={fileOptions}
                            required
                        />
                        {formProps.values.zone.includes("PLATFORM") &&
                            platformOffsetOptions.map(option => (
                                <Input
                                    name={`asset.alternateRigs.${option.value}.name`}
                                    label={option.label}
                                    description={`Alternate rig for ${option.label}`}
                                    type="select"
                                    options={fileOptions}
                                />
                            ))}

                        <Input
                            name="asset.material.type"
                            label="Material Type"
                            description="The type of material to create (ask Tim if you are unsure)"
                            required
                            type="select"
                            options={materialTypeOptions}
                        />
                        <TochiMaterialsForm
                            type={formProps.values.asset.material.type}
                            root="asset.material."
                        />
                        <Header label="Store" />
                        <Input
                            name="price"
                            label="Item Price"
                            description="User facing Snackcoin price"
                            type="number"
                            required
                        />
                        <Input
                            name="isUnlockable"
                            label="Is Unlockable"
                            description="Is the item available to everyone and able to be bought"
                            type="toggle"
                        />
                        <Input
                            name="isActive"
                            label="Is Active"
                            description="Is the item able to show up in the shop"
                            type="toggle"
                        />
                        <Input
                            name="releaseDate"
                            label="Release Date"
                            description="The date this item is getting released"
                            type="text"
                            placeholder="MM/DD/YYYY"
                        />

                        {submitSection}
                        {errorSummary}
                    </Form>
                );
            }}
        </Formik>
    );
};

const onSubmit = _.curry(
    async (
        props: TochiItemFormProps,
        dispatch: Dispatch,
        values: FormValues,
        formikActions: FormikActions<FormValues>
    ) => {
        formikActions.setSubmitting(true);

        try {
            let body: any = _.omit(values, ["category", "template"]);
            const item = props.item;
            const editMode = props.editMode;

            let response;
            if (editMode && item) {
                response = await api.tochiItems.update(item._id, body);
                dispatch(updateTochiItem(response.data.item));
            } else {
                response = await api.tochiItems.create(body);
                dispatch(addTochiItem(response.data.item));
            }

            props.onSuccess(response.data.item);
        } catch (err) {
            Swal.fire({
                title: `An error occurred`,
                text: _.get(
                    err,
                    "response.data.message",
                    "Could not save item."
                ),
            });
        } finally {
            Swal.fire({
                type: "success",
                title: "Item " + (props.editMode ? "Updated" : "Created"),
            });
            formikActions.setSubmitting(false);
        }
    }
);

const renderErrorSummary = (errors: any) => {
    if (Object.keys(errors || {}).length === 0) {
        return null;
    }

    const stringifiedErrors = FormikHelpers.stringifyErrors(errors);

    return (
        <div style={styles.errorContainer}>
            <p className="error" style={{ whiteSpace: "pre-wrap" }}>
                {stringifiedErrors}
            </p>
        </div>
    );
};

const renderSubmitSection = (
    props: TochiItemFormProps,
    formProps: FormikProps<FormValues>
) => {
    return (
        <Button
            label={props.editMode ? "Save" : "Create New"}
            disabled={!formProps.isValid}
            onPress={() => formProps.handleSubmit()}
            type="button"
            className="submit-btn"
            loading={formProps.isSubmitting}
            style={{ width: 120, height: 40 }}
        />
    );
};

const ignoreEnterKey = (e: { key: string; preventDefault: Function }) => {
    if (e.key === "Enter") {
        e.preventDefault();
    }
};

export const Header = ({ label }: { label: string }) => (
    <div>
        <h3 style={styles.header}>{label}</h3>
    </div>
);

const styles = {
    errorContainer: {
        padding: 10,
        background: "#FFFBFB",
        borderRadius: 4,
        borderColor: "#FFBABA",
        borderStyle: "solid",
        borderWidth: 1,
        marginTop: 20,
    } as CSSProperties,
    button: {
        color: Colors.white,
        backgroundColor: Colors.blue,
    } as CSSProperties,
    successContainer: {
        padding: 10,
        background: "#F1FFF7",
        borderRadius: 4,
        borderColor: "#5FC990",
        borderStyle: "solid",
        borderWidth: 1,
        marginTop: 20,
    } as CSSProperties,
    errorMessage: {
        color: Colors.red,
        fontSize: 12,
    } as CSSProperties,
    header: {
        color: Colors.blue,
        paddingTop: 5,
        paddingBottom: 5,
        margin: 0,
        borderWidth: 0,
        borderBottomWidth: 1,
        marginBottom: 10,
    } as CSSProperties,
};

export default TochiItemForm;
