import { ChainGiftCardPayoutType, IChain } from "@snackpass/snackpass-types";
import _ from "lodash";
import * as Yup from "yup";
import { CHAIN_FORM_TEMPLATE, ChainFormTemplate } from "../../utils/Templates";

export type FormValues = Omit<ChainFormTemplate, "stores">;

export function initializeFields(chain?: IChain): FormValues {
    if (!chain) {
        return { ...CHAIN_FORM_TEMPLATE };
    }

    return {
        name: _.get(chain, "name", CHAIN_FORM_TEMPLATE.name),
        logoUrl: _.get(chain, "logoUrl", CHAIN_FORM_TEMPLATE.logoUrl),
        giftCardPayoutType: _.get(
            chain,
            "giftCardPayoutType",
            CHAIN_FORM_TEMPLATE.giftCardPayoutType
        ),
        rootStore: _.get(chain, "rootStore", CHAIN_FORM_TEMPLATE.rootStore)
    };
}

export const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required.").nullable(),
    logoUrl: Yup.string().url().nullable(),
    giftCardPayoutType: Yup.string()
        .oneOf(Object.values(ChainGiftCardPayoutType))
        .nullable(),
    rootStore: Yup.string()
        .nullable()
        .when(["giftCardPayoutType"], (giftCardPayoutType, schema) => {
            return giftCardPayoutType === ChainGiftCardPayoutType.Pooling
                ? schema.required(
                      "Root store is required when using Single Account Gift Card Payout Type."
                  )
                : schema;
        })
});
