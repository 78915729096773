import * as Sentry from "@sentry/browser";
import { IPurchase } from "@snackpass/snackpass-types";
import React from "react";
import Loader from "react-loader-spinner";

import api from "../../api";
import swal from "sweetalert2";

import { View, Text, TouchableHighlight, Button } from "../../SharedComponents";

import { Colors, Styles } from "../../utils";

enum RefundReason {
    other = "other reason",
    store_closed_early = "the store closed early",
    problem_in_restaurant = "there is a problem in the restaurant",
    out_of_item = "the store is out of an item",
    customer_cancelled = "the customer cancelled order",
    delivery_range = "Out of delivery range",
    delivery_problem_3p = "delivery provider error"
}

type Props = {
    purchase: IPurchase;
    dispatch: Function;
    replacePurchase: Function;
    onSubmit: Function;
    closeModal: Function;
};

type State = {
    selectedReason: RefundReason | null;
    isSubmittingRefund: boolean;
};

export default class Refund extends React.Component<Props, State> {
    state = { selectedReason: null, isSubmittingRefund: false };

    setCancelReason = (reason: RefundReason) =>
        this.setState({ selectedReason: reason });

    submitRefund = async () => {
        this.setState({ isSubmittingRefund: true });

        await api.purchases
            .refund(this.props.purchase._id, {
                reason: this.state.selectedReason
            })
            .then(({ data }) => {
                this.props.replacePurchase(data.purchase);
                this.props.onSubmit();
            })
            .catch(async (err) => {
                Sentry.captureException(err);
                // Grab API error if possible
                const errorMessage =
                    err.response?.data?.message || err.toString();

                await swal.fire({
                    title: errorMessage,
                    type: "error",
                    confirmButtonColor: Colors.red,
                    confirmButtonText: "Ok"
                });
                this.setState({ isSubmittingRefund: false });
            });
    };

    _submitButton = () => {
        const { selectedReason, isSubmittingRefund } = this.state;

        return !selectedReason ? null : (
            <div style={{ marginTop: 20 }}>
                {isSubmittingRefund ? (
                    <div
                        style={{
                            padding: 20,
                            justifyContent: "center",
                            alignSelf: "center"
                        }}
                    >
                        <Loader
                            type="Oval"
                            color="#00BFFF"
                            height={70}
                            width={70}
                        />
                    </div>
                ) : (
                    <Button
                        style={{ width: 300 }}
                        label="Confirm"
                        backgroundColor={Colors.red}
                        onPress={this.submitRefund}
                    />
                )}
            </div>
        );
    };

    render = () => {
        const { purchase } = this.props;
        const { selectedReason } = this.state;

        return purchase.refund ? null : (
            <View
                style={{
                    flex: 1,
                    alignSelf: "stretch"
                }}
            >
                <View
                    style={{
                        paddingTop: 30,
                        paddingBottom: 150,
                        alignSelf: "stretch"
                    }}
                >
                    <View
                        style={{
                            ...Styles.flexCol,
                            alignSelf: "stretch",
                            alignItems: "flex-start",
                            padding: 10
                        }}
                    >
                        <Text>Reason for Refund: </Text>

                        {/*  Show a list of possible RefundReason to chose from */}
                        {Object.values(RefundReason).map((reason, i) => {
                            return (
                                <TouchableHighlight
                                    key={i}
                                    onPress={() => this.setCancelReason(reason)}
                                >
                                    <View
                                        style={{
                                            marginTop: 10,
                                            backgroundColor:
                                                selectedReason === reason
                                                    ? Colors.red
                                                    : Colors.offWhite,
                                            borderRadius: 12,
                                            paddingLeft: 15,
                                            paddingRight: 15,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            alignSelf: "stretch"
                                        }}
                                    >
                                        <Text
                                            color={
                                                selectedReason === reason
                                                    ? Colors.white
                                                    : Colors.text
                                            }
                                        >
                                            {reason}
                                        </Text>
                                    </View>
                                </TouchableHighlight>
                            );
                        })}
                        {this._submitButton()}
                    </View>
                </View>
            </View>
        );
    };
}
