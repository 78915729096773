/**
 * Templates provide the initial fields to the Builders (PromotionBuilder, StoreBuilder) that
 * you can set to the component's state
 */

import {
    ChainGiftCardPayoutType,
    DefaultTipType,
    FeePolicy,
    FeePolicyPayer,
    FeePolicyRecipient,
    IConvenienceFeePolicy,
    IGiftTemplate,
    IRewardTemplate,
    KioskCustomerIdentifier,
    KioskFulfillment,
    KioskTipDisplay,
    OrderStatusConfiguration,
    ReservedFeeEnum,
    SnackpassTimezoneEnum,
    TransactionSource
} from "@snackpass/snackpass-types";

import { MessageType } from "../components/AdminForm/components/NotificationPreferenceSelect";
import { Colors } from "./Colors";
import { defaultHours } from "./TimeUtil";
import { ObjectId } from "src/redux/types";

export const INTEGRATIONS_TEMPLATE = {
    kiwi: { enabled: false },
    checkmate: { enabled: false },
    chowly: { enabled: false, apiKey: "" },
    otter: { enabled: false, storeId: "" },
    postmates: { enabled: false },
    onzway: { enabled: false },
    smarpus: { enabled: false, externalId: "" },
    doordash: { enabled: false }
};

export const STORE_CONTACT_TEMPLATE = {
    name: "",
    phoneNumber: "",
    email: "",
    disabledSmsCommunications: [],
    disabledEmailCommunications: [],
    context: null
};

export const FEE_POLICY_RULES_TEMPLATE = {
    cartMax: undefined,
    cartMin: undefined,
    fulfillments: undefined,
    transactionSources: undefined,
    isCatering: undefined,
    isQr: undefined
};

export const FEE_POLICY_TEMPLATE: FeePolicy = {
    name: ReservedFeeEnum.BagStoreFee,
    payer: FeePolicyPayer.Customer,
    recipient: FeePolicyRecipient.Store,
    flat: 0,
    percent: 0,
    rules: FEE_POLICY_RULES_TEMPLATE,
    isTaxable: false
};

export const DELIVERY_FEE_POLICY_TEMPLATE: FeePolicy = {
    name: ReservedFeeEnum.DeliveryFee3P,
    payer: FeePolicyPayer.Customer,
    recipient: FeePolicyRecipient.Snackpass,
    flat: 0,
    percent: 0,
    rules: FEE_POLICY_RULES_TEMPLATE,
    isTaxable: true
};

export const convenienceFeePolicyParams: TransactionSource[] = [
    "app",
    "kiosk",
    "online"
];

const convenienceFeePolicies: IConvenienceFeePolicy[] =
    convenienceFeePolicyParams.map((transactionSource) => ({
        transactionSource,
        value: 0
    }));

export const specialDeliveryHoursLocalTemplate = [
    { start: 600, end: 1320 },
    { start: 2040, end: 2760 }
];

export const STORE_TEMPLATE = {
    _id: "",
    name: "",
    address: "",
    addressComponents: {
        line1: "",
        line2: "",
        city: "",
        county: "",
        state: "",
        country: "USA",
        zip: "",
        full: ""
    },
    color: "#F75AA2",
    brandColor: "",
    emoji: "",
    hours: defaultHours,
    region: "",
    pod: "",
    taxRate: 6.35,
    specifyTaxRateByFulfillment: false,
    taxRateDineIn: 6.35,
    taxRatePickup: 6.35,
    taxRateDelivery: 6.35,
    hasGifting: true,
    noNotes: false,
    showTipOnPickup: true,
    showNoTip: false,
    defaultTipping: false,
    defaultTip: {
        defaultTipType: DefaultTipType.Percent,
        defaultTips: [10, 15, 20, 25],
        defaultTipValue: 15
    },
    defaultTipPercentage: 15,
    phoneNumber: "",
    faxNumber: null,
    salesforceId: undefined,
    geolocation: {
        type: "Point",
        coordinates: []
    },
    email: "",
    alerts: {
        email: false,
        fax: false,
        phone: false
    },
    defaultCommissionPolicy: {
        fixed: 0,
        percent: 0
    },
    customCommissionPolicies: [],
    chargeFeePolicy: {
        policyType: "FixedChargeFeePolicy",
        percentage: 2.9,
        flat: 0.3,
        allowRefunds: false
    },
    customChargeFeePolicies: [],
    customCreditCardFee: false,
    convenienceFeePolicies,
    shouldCheckFreeTrial: false,
    numFreeTrialPurchases: 0,
    accountManager: null,
    accountExecutive: null,
    thumbnailUrl: null,
    logoUrl: null,
    isArchived: false,
    referralChannels: [],
    referralConversionText: "",

    customCommissions: false,

    // Schedule ahead fields
    hasScheduledOrders: true,
    hasMultipleIntegrations: true,
    hasBatching: false,
    scheduleAheadBatchSize: 10,
    scheduleAheadInterval: 15,
    scheduledOrderMinLeadTime: 15,

    onlineOrderingEnabled: false,
    digitalMenuEnabled: false,
    delivery: false,
    deliveryFee: 0,
    deliveryMin: 0,
    deliveryRanges: [],
    hasSpecialDeliveryHours: false,
    specialDeliveryHours: {
        zone: SnackpassTimezoneEnum.newYork,
        local: [
            { start: 600, end: 1320 },
            { start: 2040, end: 2760 }
        ]
    },
    storeDelivers: false,
    deliveryTime: null,
    deliveryOnlyEmail: "",
    pickupMin: null,
    scheduledOrderPosTriggerTime: null,
    closedUntil: null,
    closedUntilReason: null,
    rushHourUntil: null,
    primaryStoreTablet: null,
    autoWakeStoreTablet: false,
    languages: [],
    allowPauseRestOfDayOnPOS: true,
    aggressiveBatteryModeOnPOS: true,
    allowViewEarningsOnTablet: true,
    hasTablet: true,
    monthlyFee: 0,
    slug: "",
    notes: "",
    pickupDirections: null,
    pickupTime: null,
    searchVal: "",
    isPrimaryStore: false,

    // TODO(seankwalker): remove these once deleted from snackpass-types (deprecated)
    hasConvenienceFee: false,
    convenienceFee: 0,

    tabletUseQuietTone: true,
    test: true,

    hasKds: false,
    kdsSkipInProgress: true,
    hasKiosk: false,
    kioskPreferences: {
        customerIdentifierType: "ORDER_NUMBER" as KioskCustomerIdentifier,
        fulfillmentTypes: ["PICKUP"] as KioskFulfillment[],
        tipDisplay: "FLAT" as KioskTipDisplay,
        rewardsEnabled: false,
        requirePhoneInput: true,
        allowCashPayments: false,
        attractScreenUrl: null,
        customWayfindingMessage: null,
        disablePopularSection: false
    },
    registerRewardsEnabled: false,
    tipImageUrl: "",
    allowOrderConfirmationMessages: false,
    customPickUpTimes: [],
    customDeliveryTimes: [],
    pos: {
        disable: false,
        disableFlashAnimations: false,
        disableRefund: false,
        disableSoldOut: false
    },
    allowPauseOnPOS: false,
    pickup: true,
    dineInPreferences: {
        hasDineIn: false,
        serviceType: null,
        directions: null,
        requireTableNumber: false
    },
    payInStore: false,
    requestStoreToJoinSnackpass: false,
    comingSoon: false,
    isTestStore: false,
    initialReceiptNumber: 100,
    serverAutoAcceptsOrders: false,
    defaultPickupTime: 10,
    pickupTimeType: "specific",
    defaultPickupMinTime: 5,
    defaultPickupMaxTime: 15,
    integrations: INTEGRATIONS_TEMPLATE,
    posIntegrations: {
        chowly: {
            enabled: false,
            apiKey: ""
        },
        deliverect: {
            enabled: false,
            locationId: ""
        },
        ghostKitchen: false
    },
    isLive: false,
    dateLiveOnSnackpass: undefined,
    allowOrderNow: true,
    hasPickupNow: true,
    aboutUs: "",
    feePolicies: [], // TODO: Replace this with changes in tax remittance feature (leo)
    hasServerDirectPrint: false,

    // payoutPolicy is required on the Store schema on the backend, but
    // we want to allow the backend to assign its default value instead of
    // sending one from the client.
    payoutPolicy: undefined,
    pendingPayoutPolicy: null,
    hasEmployeeAuditTracking: false,
    recurringPaymentsIds: {
        stripe: null
    },
    dashboardPreferences: {
        bypassRecurringPaymentsRestriction: false
    },
    orderStatusConfiguration: OrderStatusConfiguration.AutoAcceptManualComplete
};

export const FEATURE_TEMPLATE = {
    active: false,
    name: "",
    textColor: Colors.pink,
    storeId: "",
    image: "",
    order: 1,
    showTimeBombModal: false,
    promotionId: null,
    isArchived: false,
    lastTimeArchived: null
};

export const REWARD_TEMPLATE: IRewardTemplate = {
    discount: null,
    name: "",
    nameForStore: "",
    storewide: false,
    productIds: [],
    categories: []
};

export const GIFT_TEMPLATE: IGiftTemplate = {
    numPoints: "",
    message: "",
    storeId: "",
    toUserId: "",
    selectedProductIds: [],
    isAdmin: true,
    public: false,
    storeCredit: "",
    globalCredit: ""
};

export const ADMIN_TEMPLATE = {
    firstName: "",
    lastName: "",
    number: "",
    email: "",
    snackpassPermissions: {
        accounting: true,
        storeRole: "",
        isSnackpassEmployee: false
    },
    permissions: {},
    disabledSmsCommunications: new Set<MessageType>(),
    disabledEmailCommunications: new Set<MessageType>()
};

export const ADMIN_FORM_INTERNAL_TEMPLATE = {
    storeContact: null,
    matchingEmailUser: null,
    matchingNumberUser: null,
    loadingUsers: false,
    errors: "",
    success: ""
};

export type ChainFormTemplate = {
    name: string | null;
    logoUrl: string | null;
    stores: [];
    giftCardPayoutType: ChainGiftCardPayoutType;
    rootStore: ObjectId | null;
};

export const CHAIN_FORM_TEMPLATE: ChainFormTemplate = {
    name: null,
    logoUrl: null,
    stores: [],
    giftCardPayoutType: ChainGiftCardPayoutType.Separate,
    rootStore: null
};
