import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import KiosksTable from "./KiosksTable";

import { getKiosksForStore } from "../../redux/kiosks";
import { getPathFromState } from "../../utils/Helpers";
import { View } from "src/components";

import StorePicker from "../../components/StorePicker";

const Kiosks = () => {
    const dispatch = useDispatch();
    const selectedStoreId = getPathFromState("kiosks.selectedStoreId");

    // Make sure the selected store is unset if we navigate away from the page
    // and back.
    useEffect(() => {
        dispatch(getKiosksForStore(null));
    }, [dispatch]);

    return (
        <>
            <h1>Kiosks 🍞</h1>
            <View style={{ flexDirection: "row" }}>
                <StorePicker dispatchOnChange={getKiosksForStore} />
            </View>
            {selectedStoreId ? (
                <div>
                    <br />
                    <KiosksTable />
                </div>
            ) : null}
        </>
    );
};

export default Kiosks;
