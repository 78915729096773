import {
    baseTimezone,
    SnackpassTimezoneEnum
} from "@snackpass/snackpass-types";
import moment from "moment-timezone";

export const isProduction = process.env.REACT_APP_ENV === "production";

export const VERTICAL_MENU_WIDTH = 180;

export const GOOGLE_API_KEY = "AIzaSyDHAYgLxjriS4aqmu4DqaQUjt8pYEx9rqs";

export const HOGWARTS_TABLET_SERIAL = "POSDEBUG";
export const HOGWARTS_ID = "5b4a618f326bd9777aa5dc90";
export const PROMO_CODE_STORE_ID = "5f6def5fb2622200b6e148d7";

export const US_TIME_ZONES = [
    SnackpassTimezoneEnum.honolulu,
    SnackpassTimezoneEnum.juneau,
    SnackpassTimezoneEnum.la,
    SnackpassTimezoneEnum.phoenix,
    SnackpassTimezoneEnum.denver,
    SnackpassTimezoneEnum.chicago,
    SnackpassTimezoneEnum.newYork,
    SnackpassTimezoneEnum.indianapolis
];

export const US_STATES = {
    AK: "Alaska",
    AL: "Alabama",
    AR: "Arkansas",
    AS: "American Samoa",
    AZ: "Arizona",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DC: "District of Columbia",
    DE: "Delaware",
    FL: "Florida",
    GA: "Georgia",
    GU: "Guam",
    HI: "Hawaii",
    IA: "Iowa",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    MA: "Massachusetts",
    MD: "Maryland",
    ME: "Maine",
    MI: "Michigan",
    MN: "Minnesota",
    MO: "Missouri",
    MS: "Mississippi",
    MT: "Montana",
    NC: "North Carolina",
    ND: "North Dakota",
    NE: "Nebraska",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NV: "Nevada",
    NY: "New York",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PA: "Pennsylvania",
    PR: "Puerto Rico",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VA: "Virginia",
    VI: "Virgin Islands",
    VT: "Vermont",
    WA: "Washington",
    WI: "Wisconsin",
    WV: "West Virginia",
    WY: "Wyoming"
};
export const PROMOTION_TYPE_ENUM = [
    { value: "REWARD", label: "Reward" },
    { value: "PROMO_CODE", label: "Promotion code" },
    { value: "DISCOUNT", label: "Discount" },
    { value: "REFERRAL", label: "Referral" },
    { value: "DEAL", label: "Deal" },
    { value: "TIME_BOMB", label: "Time Bomb" },
    { value: "PARTY", label: "Party" },
    { value: "BIRTHDAY", label: "Birthday" }
];

export const TARGETS = [
    {
        value: "students",
        label: "students"
    },
    {
        value: "firstTime",
        label: "firstTime"
    },
    {
        value: "secondTime",
        label: "secondTime"
    }
];

export const DISCOUNT_TYPES = [
    {
        value: "newPrice",
        label: "New Price"
    },
    {
        value: "dollarsOff",
        label: "Dollars Off"
    },
    {
        value: "percentOff",
        label: "Percent Off (%)"
    }
];

export const USEFUL_ADDRESS_COMPONENTS = [
    "street_number",
    "route",
    "locality",
    "sublocality",
    "neighborhood",
    "administrative_area_level_2",
    "administrative_area_level_1",
    "postal_code",
    "premise"
] as const;
