import { IUser } from "@snackpass/snackpass-types";
import { Card } from "antd";
import { compose } from "lodash/fp";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import { TochiItemsTable } from "./components/TochiItemsTable";
import TochiItemsModal from "./components/TochiItemsModal";
import { fetchTochiItems } from "../../../redux/tochiItems";
import { Button } from "../../../SharedComponents";
import { Styles } from "../../../utils";
import { getPathFromState } from "../../../utils/Helpers";
import InfoButton from "../components/InfoButton";

const infoText: string[] = [
    "Create, edit, duplicate, and delete items.",
    "Items, Zones, Rarity, and Price columns are all searchable and sortable.",
    "Columns that can be edited in the table are marked with a ✏️",
    "Zone and Hide Submesh form fields can take multiple values.",
    "The Asset Model form field is searchable",
    "Just refresh the page if a texture doesn't load when you want to edit it!",
];

const TochiItems = () => {
    const currentUser: IUser = getPathFromState("user");
    const dispatch = useDispatch();
    const _fetchTochiItems = compose(dispatch, fetchTochiItems);

    useEffect(() => {
        if (currentUser._id) {
            _fetchTochiItems();
        }
    }, [currentUser, _fetchTochiItems]);

    return (
        <div>
            <TochiItemsModal />
            <Card
                style={styles.card}
                headStyle={styles.head}
                bodyStyle={styles.body}
                title="Tochi Items 🐣"
                extra={
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <Button
                            label="New Item"
                            onPress={() => dispatch(show("TochiItemsModal"))}
                            style={{ marginRight: 20 }}
                        />
                        <InfoButton
                            title="Tochi Items Info"
                            infoText={infoText}
                        />
                    </div>
                }
            >
                <TochiItemsTable />
            </Card>
        </div>
    );
};

const styles = {
    body: {
        padding: 0,
    } as React.CSSProperties,
    card: {
        width: "90%",
        margin: "0 auto",
        borderRadius: 5,
        ...Styles.shadowLight,
    } as React.CSSProperties,
    head: {
        fontSize: 22,
        borderBottom: "none",
    } as React.CSSProperties,
};

export default TochiItems;
