import { connect } from "react-redux";
import SearchUser from "../components/SearchUser";
import { setActiveUser, setPunchcards, setRewards } from "../redux/activeUser";
import { Dispatch } from "redux";
import { StoreState } from "../redux/types";
import { IUser } from "@snackpass/snackpass-types";

const mapStateToProps = (state: StoreState) => {
    return {};
};

// create handler than dispatches an action
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        dispatch,
        setActiveUser: (activeUser: IUser) => {
            dispatch(setActiveUser(activeUser));
        },
        setPunchcards: (punchcards: any) => {
            dispatch(setPunchcards(punchcards));
        },
        setRewards: (rewards: any) => {
            dispatch(setRewards(rewards));
        }
    };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(SearchUser);

export default Container;
