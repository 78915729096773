import { combineReducers } from "redux";
import { reducer as modal } from "redux-modal";

import { activeStore } from "./activeStore";
import { activeUser } from "./activeUser";
import { admins, AdminAction } from "./admins";
import { archivedStores } from "./archivedStores";
import { authorized } from "./authorized";
import { kiosks, KiosksAction } from "./kiosks";
import storeSubscriptions, {
    StoreSubscriptionAction
} from "./storeSubscription";
import { networkRequests, NetworkRequestsAction } from "./networkRequests";
import { printers, PrintersAction } from "./printers";
import { promotions, PromotionAction } from "./promotions";
import { giftcards, GiftCardsActions } from "./giftcards";
import { purchases, PurchaseAction } from "./purchases";
import { regions, RegionAction } from "./regions";
import { storeContacts } from "./storeContacts";
import { stores, StoreAction } from "./stores";
import { storeTablets, StoreTabletAction } from "./storeTablets";
import { StoreState } from "./types";
import { user } from "./user";
import { chains, ChainsAction } from "./chains";
import { tochiItems, TochiItemAction } from "./tochiItems";

export type RootActions =
    | AdminAction
    | KiosksAction
    | NetworkRequestsAction
    | GiftCardsActions
    | PrintersAction
    | PromotionAction
    | PurchaseAction
    | RegionAction
    | StoreAction
    | StoreTabletAction
    | ChainsAction
    | TochiItemAction
    | StoreSubscriptionAction;

const reducer = combineReducers<StoreState, RootActions>({
    activeStore,
    activeUser,
    admins,
    archivedStores,
    authorized,
    kiosks,
    modal,
    networkRequests,
    printers,
    promotions,
    giftcards,
    purchases,
    regions,
    storeContacts,
    stores,
    storeTablets,
    user,
    chains,
    tochiItems,
    storeSubscriptions
} as any);

export default reducer;
