import {
    IKiosk,
    KioskModelType,
    KioskPaymentProvider
} from "@snackpass/snackpass-types";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Table from "react-table";
import Select from "react-select";
import { Dispatch } from "redux";

import { refreshKiosks, KIOSK_MODEL_OPTIONS } from "./utils";

import { SelectOption } from "../../shared/types";

import api from "../../api";
import { Toggle } from "../../SharedComponents";
import { Colors } from "../../utils/Colors";
import {
    alertAxiosError,
    getPathFromState,
    toPercentString
} from "../../utils/Helpers";

import swal from "sweetalert2";
import _ from "lodash";
import { View } from "src/components";

const PAYMENT_PROVIDER_OPTIONS: SelectOption<KioskPaymentProvider>[] = [
    { label: "Stripe", value: "stripe" }
];

const KiosksTable = () => {
    const dispatch = useDispatch();
    const selectedStoreId = getPathFromState("kiosks.selectedStoreId");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        refreshKiosks({ dispatch, selectedStoreId }).then(() => {
            setLoading(false);
        });
    }, [selectedStoreId]);

    const kioskList: IKiosk[] = getPathFromState("kiosks.kioskList");
    return (
        <Table
            style={styles.kiosksTable}
            data={kioskList}
            columns={columns}
            loading={loading}
        />
    );
};

const EmployeeModeToggle = ({ original: kiosk }: { original: IKiosk }) => {
    const dispatch = useDispatch();
    const selectedStoreId = getPathFromState("kiosks.selectedStoreId");

    const handleToggle = (value: boolean) =>
        onUpdate(
            dispatch,
            kiosk.serial,
            { employeeMode: value },
            selectedStoreId
        );

    return (
        <View style={styles.toggle}>
            <Toggle value={!!kiosk.employeeMode} onToggle={handleToggle} />
        </View>
    );
};

const AllowCashToggle = ({ original: kiosk }: { original: IKiosk }) => {
    const dispatch = useDispatch();
    const selectedStoreId = getPathFromState("kiosks.selectedStoreId");

    const handleToggle = (value: boolean) => {
        onUpdate(
            dispatch,
            kiosk.serial,
            { allowCashPayments: value },
            selectedStoreId
        );
    };

    return (
        <View style={styles.toggle}>
            <Toggle value={!!kiosk.allowCashPayments} onToggle={handleToggle} />
        </View>
    );
};

const AllowOtherPaymentsToggle = ({
    original: kiosk
}: {
    original: IKiosk;
}) => {
    const dispatch = useDispatch();
    const selectedStoreId = getPathFromState("kiosks.selectedStoreId");

    const handleToggle = (value: boolean) => {
        onUpdate(
            dispatch,
            kiosk.serial,
            { allowOtherPayments: value },
            selectedStoreId
        );
    };

    return (
        <View style={styles.toggle}>
            <Toggle
                value={!!kiosk.allowOtherPayments}
                onToggle={handleToggle}
            />
        </View>
    );
};

const onUpdate = (
    dispatch: Dispatch,
    serial: string,
    data: Partial<IKiosk>,
    storeId?: string
) =>
    api.kiosks
        .update({ serial, data })
        .then(() => {
            refreshKiosks({
                dispatch,
                selectedStoreId: storeId
            });
            return swal.fire({
                title: "SUCCESSFULLY UPDATED!",
                type: "success",
                confirmButtonColor: Colors.green,
                confirmButtonText: "Ok"
            });
        })
        .catch((err) => {
            alertAxiosError(err);
            return swal.fire({
                title: "ERROR UPDATING!",
                type: "error",
                confirmButtonColor: Colors.red,
                confirmButtonText: "Ok"
            });
        });

const PaymentProviderInput = ({ original: kiosk }: { original: IKiosk }) => {
    const dispatch = useDispatch();
    const selectedStoreId = getPathFromState("kiosks.selectedStoreId");

    const onChange = (option: SelectOption<KioskPaymentProvider>) =>
        onUpdate(
            dispatch,
            kiosk.serial,
            { paymentProvider: option.value },
            selectedStoreId
        );

    const _getValue = _.find(
        PAYMENT_PROVIDER_OPTIONS,
        ({ value }) => value === kiosk.paymentProvider
    );

    return (
        <View style={{ flexDirection: "row" }}>
            <Select
                className="snackpass__react-select"
                value={_getValue}
                styles={{
                    // this is needed or else the options are behind
                    // the table and cannot select them
                    menu: () => ({}),
                    multiValueLabel: () => ({
                        whiteSpace: "normal"
                    })
                }}
                options={PAYMENT_PROVIDER_OPTIONS}
                onChange={onChange}
            />
        </View>
    );
};

const ReaderInfo = ({ original: kiosk }: { original: IKiosk }) => {
    const batteryPercentage = kiosk.reader?.batteryLevel
        ? toPercentString(kiosk.reader?.batteryLevel)
        : undefined;
    return (
        <div>
            {kiosk.paymentProvider === "stripe" ? (
                <div style={styles.readerInfoContainer}>
                    <span style={styles.readerInfoContent}>
                        Serial: {kiosk.reader?.serialNumber || "N/A"}
                    </span>
                    <span style={styles.readerInfoContent}>
                        Version: {kiosk.reader?.deviceSoftwareVersion || "N/A"}
                    </span>
                    <span style={styles.readerInfoContent}>
                        Battery: {batteryPercentage || "N/A"}
                    </span>
                    <span style={styles.readerInfoContent}>
                        Connection: {kiosk.reader?.connectionType || "N/A"}
                    </span>
                    <span style={styles.readerInfoContent}>
                        Is connected:{" "}
                        {kiosk.reader?.isConnected === null ||
                        kiosk.reader?.isConnected === undefined
                            ? "N/A"
                            : `${kiosk.reader?.isConnected}`}
                    </span>
                    <span style={styles.readerInfoContent}>
                        Last ping: {kiosk.reader?.lastPing?.toString() || "N/A"}
                    </span>
                    <span style={styles.readerInfoContent}>
                        Saved: {kiosk.savedReader?.serialNumber || "N/A"}
                    </span>
                </div>
            ) : (
                <span>N/A</span>
            )}
        </div>
    );
};

const KioskModelInput = ({ original: kiosk }: { original: IKiosk }) => {
    const dispatch = useDispatch();
    const selectedStoreId = getPathFromState("kiosks.selectedStoreId");

    const onChange = (option: SelectOption<KioskModelType>) =>
        onUpdate(
            dispatch,
            kiosk.serial,
            { deviceModel: option.value },
            selectedStoreId
        );
    return (
        <View style={styles.kioskModelInput}>
            <Select
                className="snackpass__react-select"
                value={_.find(
                    KIOSK_MODEL_OPTIONS,
                    ({ value }) => kiosk.deviceModel === value
                )}
                styles={styles.dropdown}
                options={KIOSK_MODEL_OPTIONS}
                onChange={onChange}
            />
        </View>
    );
};

const dateFormatter = (props: { value: string }) => (
    <span>
        {props.value
            ? moment(props.value).format("MM/DD/YY HH:mm:ss")
            : "Never"}
    </span>
);

const columns = [
    { Header: "Serial #", accessor: "serial", width: 125 },
    { Header: "Model", Cell: KioskModelInput, width: 185 },
    {
        Header: "Created At",
        id: "createdAt",
        accessor: "createdAt",
        Cell: dateFormatter,
        width: 150
    },
    {
        Header: "Last Ping",
        id: "lastPing",
        accessor: "lastPing",
        Cell: dateFormatter,
        width: 150
    },
    { Header: "App Version", accessor: "appVersion" },
    { Header: "Purchase Count", accessor: "purchaseCount", width: 125 },
    { Header: "Payment Provider", Cell: PaymentProviderInput, width: 150 },
    { Header: "Employee Mode", Cell: EmployeeModeToggle, width: 125 },
    { Header: "Allow Cash", Cell: AllowCashToggle },
    { Header: "Allow Other", Cell: AllowOtherPaymentsToggle },
    {
        Header: "Stripe Reader Info",
        Cell: ReaderInfo,
        width: 350
    }
];

const styles: { [k: string]: React.CSSProperties } = {
    kiosksTable: { marginTop: "20px", textAlign: "center" },
    input: {
        marginBottom: "10px",
        marginTop: "10px",
        width: "200px"
    },
    button: {
        marginLeft: "0px",
        width: "200px"
    },
    errorMessage: {
        color: "red",
        marginLeft: "10px"
    },
    toggle: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
    },
    readerInfoContainer: {
        textAlign: "left",
        overflow: "auto"
    },
    readerInfoContent: {
        display: "block",
        paddingRight: "10px"
    },
    kioskModelInput: {
        flexDirection: "row",
        textAlign: "left",
        fontSize: 11,
        width: 175
    },
    dropdown: {
        // this is needed or else the options are behind
        // the table and cannot select them
        menu: () => ({}),
        multiValueLabel: () => ({
            whiteSpace: "normal"
        })
    } as React.CSSProperties
};

export default KiosksTable;
