import * as Styles from "../../utils/Styles";
import { Colors } from "../../utils/Colors";

const styles = {
    button: {
        backgroundColor: Colors.blue2,
        color: Colors.white,
        padding: 10,
    },
    logo: {
        width: 100,
        margin: 50,
        borderRadius: 100,
        ...Styles.shadowLight,
    },
    header: {
        fontWeight: 100,
        fontFamily: "Rubik",
        color: Colors.text,
        margin: "10px 0",
    },
    body: {
        textAlign: "center" as "center",
        background: Colors.offWhite,
        height: "100vh",
        width: "100%",
    },
    container: {
        backgroundColor: Colors.white,
        width: 400,
        margin: "auto" as "auto",
        borderRadius: 8,
        // height: 400,
        overflow: "hidden" as "hidden",
        padding: 50,
        textAlign: "center" as "center",
        ...Styles.shadowLight,
    },
};

export default styles;
