import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Table, { Column } from "react-table";
import Select from "react-select";

import { insertValueIntoArray, refreshPrinters } from "./utils";

import api from "../../api";
import { Button } from "../../SharedComponents";
import { alertAxiosError, getPathFromState } from "../../utils/Helpers";

import _ from "lodash";
import { View } from "src/components";
import { Printer, PrinterFormat } from "@snackpass/snackpass-types";
import { SelectOption } from "src/shared/types";
import { Colors } from "src/utils";
import { PrinterHistoryModal } from "./PrinterHistoryModal";
import { MODE } from "./index";

const PRINTER_FORMAT_OPTIONS = [
    { label: "RECEIPT", value: PrinterFormat.RECEIPT },
    { label: "CHIT", value: PrinterFormat.CHIT },
    { label: "LABEL_IMAGE", value: PrinterFormat.LABEL_IMAGE },
    { label: "CUSTOMER", value: PrinterFormat.CUSTOMER }
];

const PrintersTable = ({ mode }: { mode: MODE }) => {
    const dispatch = useDispatch();
    const selectedStoreId = getPathFromState("printers.selectedStoreId");
    const selectedFoodhallId = getPathFromState("printers.selectedFoodhallId");
    const printerSearch = getPathFromState("printers.search");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        refreshPrinters({
            dispatch,
            selectedStoreId,
            selectedFoodhallId,
            printerSearch
        }).then(() => {
            setLoading(false);
        });
    }, [
        selectedStoreId,
        selectedFoodhallId,
        printerSearch,
        dispatch,
        setLoading,
        refreshPrinters
    ]);

    const printers = getPathFromState("printers.printers");
    return (
        <Table
            style={styles.printersTable}
            data={printers}
            columns={mode === MODE.STORE ? columns : columnsWithStore}
            loading={loading}
        />
    );
};

const Deprecate = ({ original: printer }: { original: any }) => {
    const dispatch = useDispatch();
    const selectedStoreId = getPathFromState("printers.selectedStoreId");
    const selectedFoodhallId = getPathFromState("printers.selectedFoodhallId");

    const onPress = async () =>
        alert("Sorry, please use RDB devices page to delete printers.");

    return (
        <Button
            backgroundColor={"red"}
            label={"Deprecate"}
            onPress={onPress}
            disabled
        />
    );
};

const DeprecateReplace = ({ original: printer }: { original: any }) => {
    const dispatch = useDispatch();
    const selectedStoreId = getPathFromState("printers.selectedStoreId");
    const selectedFoodhallId = getPathFromState("printers.selectedFoodhallId");

    const onPress = async () => {
        const newSerial = prompt("Please enter new serial");

        if (newSerial) {
            api.printers
                .deprecate(printer._id, newSerial)
                .then(() =>
                    refreshPrinters({
                        dispatch,
                        selectedStoreId,
                        selectedFoodhallId
                    })
                )
                .catch(alertAxiosError);
        }
    };

    return (
        <Button backgroundColor={"red"} label={"Replace"} onPress={onPress} />
    );
};

const dateFormatter = (props: { value: string }) => (
    <span>
        {props.value
            ? moment(props.value).format("MM/DD/YY HH:mm:ss")
            : "Never"}
    </span>
);

const FormatSelect = ({ original: printer }: { original: Printer }) => {
    const dispatch = useDispatch();
    const selectedStoreId = getPathFromState("printers.selectedStoreId");
    const selectedFoodhallId = getPathFromState("printers.selectedFoodhallId");

    const onChange = async (option: SelectOption<PrinterFormat>) => {
        const { value: format } = option;
        printer.format = format;

        try {
            await api.printers.updatePrinter(printer.id, printer);
            refreshPrinters({
                dispatch,
                selectedStoreId,
                selectedFoodhallId
            });
        } catch (err) {
            alertAxiosError(err);
        }
    };

    const _getValue = _.find(
        PRINTER_FORMAT_OPTIONS,
        ({ value }) => value === printer.format
    );

    return (
        <View style={{ flexDirection: "row" }}>
            <Select
                className="snackpass__react-select"
                value={_getValue}
                styles={{
                    menu: () => ({}),
                    multiValueLabel: () => ({
                        whiteSpace: "normal"
                    })
                }}
                options={PRINTER_FORMAT_OPTIONS}
                onChange={onChange}
            />
        </View>
    );
};

const PrinterHistory = ({ original: printer }: { original: Printer }) => {
    const [historyOpen, setHistoryOpen] = useState(false);

    return (
        <>
            {historyOpen ? (
                <PrinterHistoryModal
                    isOpen={historyOpen}
                    onClose={() => setHistoryOpen(false)}
                    printerSerial={printer.serial}
                />
            ) : null}
            <button
                style={{ backgroundColor: Colors.blue, color: Colors.white }}
                onClick={() => setHistoryOpen(true)}
            >
                View History
            </button>
        </>
    );
};

const columns = [
    { Header: "Name", accessor: "name" },
    { Header: "Serial #", accessor: "serial" },
    { Header: "Format", accessor: "format", Cell: FormatSelect },
    {
        Header: "Created At",
        id: "createdAt",
        accessor: "createdAt",
        Cell: dateFormatter
    },
    {
        Header: "Last Ping",
        id: "lastPing",
        accessor: "lastPing",
        Cell: dateFormatter
    },
    { Header: "History", Cell: PrinterHistory },
    { Header: "Replace", Cell: DeprecateReplace },
    { Header: "Deprecate", Cell: Deprecate }
];

const storeColumn = { Header: "Store", accessor: "store.name" };
const columnsWithStore = insertValueIntoArray<Column>(columns, 3, storeColumn);

const styles = {
    printersTable: { marginTop: "20px", textAlign: "center" }
};

export default PrintersTable;
