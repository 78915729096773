import React from "react";

export interface IImage {
    source: string;
    style?: any;
}

export const Image = ({ source, style }: IImage) => {
    if (!source) {
        return null;
    }
    return <img style={style} src={source} />;
};
