import React, { useState } from "react";
import { Modal as BootstrapModal } from "react-bootstrap";
import Modal from "react-modal";
import { View, Button, Text } from "../../SharedComponents";
import { Colors } from "../../utils";
import moment from "moment";
import api from "../../api";
import { IStore } from "@snackpass/snackpass-types";
import SelectTimePeriod, { TimeMode } from "./SelectTimePeriod";
import swal from "sweetalert2";
import { connectModal } from "redux-modal";
import _ from "lodash";
import ReasonInput from "./ReasonInput";

type Props = {
    store: IStore;
    show: boolean;
    setStore: (store: IStore) => void;
    onSubmit: (body: any) => void;
    handleHide: () => void;
    handleDestroy: () => void;
    onSuccess: () => void;
};

// arbitrarily set it as 5 years after current
const YEAR_LIMIT = moment(new Date()).year() + 5;

export function StorePauseModal(props: Props) {
    const [loading, setLoading] = useState<boolean>(false);
    const [date, setDate] = useState<moment.Moment | null>(null);
    const [duration, setDuration] = useState<any>(null);
    const [customDuration, setCustomDuration] = useState<any>(null);
    const [mode, setMode] = useState<"pause" | "rush-hour">("pause");
    const [timeMode, setTimeMode] = useState<TimeMode>("DEFAULT_TIME");
    const [closedUntilReason, setClosedUntilReason] = useState<string | null>(
        null
    );
    const [dateIsValid, setDateIsValid] = useState<boolean>(true);
    const [otherPausedReason, setOtherPausedReason] = useState<string | null>(
        ""
    );

    const onChangeTimeMode = (timeMode: TimeMode) => {
        setTimeMode(timeMode);
        // Clear form content when time mode switches to avoid residual data.
        clearReasons();
    };
    const clearReasons = () => {
        setClosedUntilReason(null);
        setOtherPausedReason("");
        setDateIsValid(true);
        setDate(null);
        setDuration(null);
    };
    const onChangeDuration = (item: any) => {
        setDuration(item);
    };
    const onChangeCustomDuration = (item: any) => {
        setCustomDuration(item);
    };
    const onChangeDate = (date: moment.Moment | null) => {
        setDate(date);
        setDateIsValid(
            !!date?.year() && date?.year() < YEAR_LIMIT && date.isValid()
        );
    };

    const canSubmit = () => {
        // Only allow submitting if the following conditions are met:
        // 1. Date or Duration is set.
        // 2. And one of the following:-
        //    a. Rush hour mode.
        //    b. Paused for non-"Other" reason.
        //    c. Paused for "Other" reason and reason is given.
        return (
            ((duration !== "CUSTOM" && duration) ||
                customDuration ||
                (date && dateIsValid)) &&
            (mode === "rush-hour" ||
                (closedUntilReason === "Other" && otherPausedReason) ||
                (closedUntilReason !== "Other" && closedUntilReason))
        );
    };
    const onSubmit = async () => {
        let { store, handleHide } = props;

        let dateToPauseUntil: Date | null = null;
        if (timeMode === "DEFAULT_TIME") {
            let value = duration === "CUSTOM" ? customDuration : duration;
            if (value === "REST_OF_DAY") {
                dateToPauseUntil = moment()
                    .add(1, "day")
                    .startOf("day")
                    .add(8, "hours")
                    .toDate();
            } else if (value) {
                dateToPauseUntil = moment()
                    .add(parseInt(value), "minutes")
                    .toDate();
            }
        } else if (timeMode === "ARBITRARY_DATE" && date) {
            dateToPauseUntil = date.toDate();
        }

        // Make sure there is a date to pause until,
        // otherwise alert user that it is invalid
        if (!dateToPauseUntil || isNaN(dateToPauseUntil as any)) {
            return swal.fire(
                "Invalid pause / rush hour",
                "Please put valid duration or date.",
                "error"
            );
        }

        // Build the request body
        let body: any = {};
        if (mode === "pause") {
            body["closedUntil"] = dateToPauseUntil;
            body["closedUntilReason"] =
                closedUntilReason === "Other"
                    ? closedUntilReason + " - " + otherPausedReason
                    : closedUntilReason;
        } else if (mode === "rush-hour") {
            body["rushHourUntil"] = dateToPauseUntil;
        }
        setLoading(true);
        try {
            let res = await api.stores.update(store._id, body);
            props.setStore(res.data.store);
            setLoading(false);
            handleHide();
        } catch (err) {
            let message = _.get(err, "response.data.message");
            setLoading(false);
            return swal.fire(
                "Failed to update",
                message || "Unknown error",
                "error"
            );
        }
    };
    const renderPauseNotesInput = () => {
        return (
            mode === "pause" && (
                <div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <h4 style={{ marginBottom: 10 }}>Pause Reasons:</h4>
                        <p
                            style={{
                                color: Colors.red,
                                marginLeft: 3,
                            }}
                        >
                            *
                        </p>
                    </div>
                    <ReasonInput
                        setOtherPausedReason={setOtherPausedReason}
                        closedUntilReason={closedUntilReason}
                        setClosedUntilReason={setClosedUntilReason}
                    />
                </div>
            )
        );
    };
    const renderButtonOptions = () => {
        return (
            <div>
                <h4>Would you like to pause or rush-hour?</h4>
                <input
                    checked={mode === "pause"}
                    type="radio"
                    name="mode"
                    onChange={() => setMode("pause")}
                />
                <span style={{ marginLeft: 10 }}>Pause</span>
                <br />
                <input
                    checked={mode === "rush-hour"}
                    type="radio"
                    name="mode"
                    onChange={() => setMode("rush-hour")}
                />
                <span style={{ marginLeft: 10 }}>Rush Hour</span>
            </div>
        );
    };
    let { handleHide, store } = props;
    return (
        <Modal isOpen={true} onRequestClose={handleHide}>
            <BootstrapModal.Header closeButton onClick={handleHide}>
                <BootstrapModal.Title>
                    <Text large>Pause / Rush Hour ⏸️ </Text>
                </BootstrapModal.Title>
            </BootstrapModal.Header>
            <BootstrapModal.Body>
                <View>
                    {renderButtonOptions()}
                    <div style={{ margin: "25px 0" }}>
                        <SelectTimePeriod
                            mode={mode}
                            timeMode={timeMode}
                            onChangeTimeMode={onChangeTimeMode}
                            date={date}
                            duration={duration}
                            customDuration={customDuration}
                            onChangeDate={onChangeDate}
                            onChangeDuration={onChangeDuration}
                            onChangeCustomDuration={onChangeCustomDuration}
                            store={store}
                        />
                        <p style={{ color: "red" }}>
                            {!dateIsValid && "That date is invalid."}
                        </p>
                        {renderPauseNotesInput()}
                    </div>
                    {canSubmit() && (
                        <div
                            style={{
                                width: 200,
                                textAlign: "center",
                                margin: "20px auto",
                            }}
                        >
                            <Button
                                style={{ width: "100%" }}
                                label="submit"
                                loading={loading}
                                onPress={onSubmit}
                            />
                        </div>
                    )}
                </View>
            </BootstrapModal.Body>
        </Modal>
    );
}

export default connectModal({ name: "StorePauseModal" })(StorePauseModal);
