import React from "react";
import { Colors } from "../../../utils";

type Props = {
    expanded?: boolean;
    label: string;
    onClick?: Function;
};

// Header function to divide form into easy to read sections
export const Header = ({ label, expanded, onClick }: Props) => (
    <div>
        <h3 onClick={() => onClick && onClick()} style={styles.header}>
            {label}
            <span
                style={{
                    position: "absolute",
                    right: 30,
                    color: "#fff",
                }}
            >
                {expanded ? "-" : "+"}
            </span>
        </h3>
    </div>
);

const styles = {
    header: {
        cursor: "pointer",
        background: Colors.blue2,
        padding: 15,
        paddingTop: 5,
        paddingBottom: 5,
        margin: 0,
        borderWidth: 0,
        borderBottomWidth: 1,
        borderColor: "white",
        borderStyle: "solid",
        marginBottom: 10,
        color: "white",
    },
};

export default Header;
