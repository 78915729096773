import _ from "lodash";
import { IChain } from "@snackpass/snackpass-types";
import { createSelector } from "reselect";

type ReducerStatus = "LOADING" | "SUCCESS" | "ERROR" | "NONE";

// Constants
export const SET_CHAINS = "SET_CHAINS";
export const ADD_CHAIN = "ADD_CHAIN";
export const REMOVE_CHAIN = "REMOVE_CHAIN";
export const API_GET_CHAINS = "API_GET_CHAINS";

export const fetchChains = (query?: any) =>
    ({
        type: API_GET_CHAINS,
        query
    }) as const;

export type FetchChains = ReturnType<typeof fetchChains>;

export const setChains = (chains: IChain[]) =>
    ({
        type: SET_CHAINS,
        chains
    }) as const;

export type SetChains = ReturnType<typeof setChains>;

export const addChain = (chain: IChain) =>
    ({
        type: ADD_CHAIN,
        chain
    }) as const;

export type AddChain = ReturnType<typeof addChain>;

export const removeChain = (chain: IChain) =>
    ({
        type: REMOVE_CHAIN,
        chain
    }) as const;

export type RemoveChain = ReturnType<typeof removeChain>;

export type ChainsAction = SetChains | AddChain | RemoveChain;

// State
export interface ChainsState {
    status: ReducerStatus;
    chainsMap: { [chainId: string]: IChain };
}

export const chainInitialState: ChainsState = {
    status: "NONE",
    chainsMap: {}
};

// Reducer
export const chains = (
    state: ChainsState = chainInitialState,
    action: ChainsAction
) => {
    switch (action.type) {
        case SET_CHAINS:
            return {
                ...state,
                chainsMap: _.keyBy(action.chains, "_id")
            };
        case ADD_CHAIN:
            return {
                ...state,
                chainsMap: {
                    ...state.chainsMap,
                    [action.chain._id]: action.chain
                }
            };
        case REMOVE_CHAIN:
            return {
                ...state,
                chainsMap: _.omit(state.chainsMap, action.chain._id)
            };
        default:
            return state;
    }
};

// Selectors
export const getChainsMap = (state: any) =>
    state.chains.chainsMap as { [chainId: string]: IChain };

export const getChainsStatus = (state: any) =>
    state.chains.status as ReducerStatus;

export const getChainsList = createSelector(
    [getChainsMap],
    (chains) => _.values(chains) as IChain[]
);

export const getFoodhallList = createSelector(
    [getChainsMap],
    (chains) =>
        _(chains)
            .filter((chain) => chain.isFoodHall || false)
            .value() as IChain[]
);
