import imageCompression from "browser-image-compression";
import { message } from "antd";

type Upload = (image: Blob) => void;

/**
 * takes an image and compresses it; if it's not compressed enough,
 * it will compress recursively until requirements are met or the
 * number of compressive iterations exceeds MAX_COMPRESS_LOOPS
 *
 * borrowed from src/components/ProductEditor/helpers.tsx in restaurant dashboard
 */
export const compressThenUpload = (upload: Upload, image: File) => {
    // try upload, if image is not small enough then compress again
    const attemptUpload = (iterations: number) => (compressedImage: File) => {
        if (compressedImage.size < constants.MAX_FILE_SIZE) {
            upload(compressedImage);
            if (iterations !== 1) helpers.COMPRESS.WARN_QUALITY();
        } else {
            if (iterations >= constants.MAX_COMPRESS_LOOPS) {
                message.error(constants.COMPRESS.OVERSIZED);
                return;
            }
            // compress recursively
            compress(compressedImage, iterations + 1);
        }
    };

    const compress = (image: File, iterations: number) => {
        imageCompression(image, constants.COMPRESS.OPTIONS)
            .then(attemptUpload(iterations))
            .catch(helpers.COMPRESS.ERROR);
    };

    attemptUpload(0)(image); // preserves quality if below max file size
};

export const constants = {
    MAX_COMPRESS_LOOPS: 3,
    MAX_FILE_SIZE: 200 * 1024, // 200kb
    COMPRESS: {
        // NOTE (alita): await doesn't work properly for browser-image-compression
        // maxSizeMB must be a bit lower than maximum otherwise it causes problems
        OPTIONS: { maxSizeMB: 0.19, useWebWorker: true },
        QUALITY:
            "Due to the large image size, the \
            image may be blurrier than desired",
        ERROR: "There was an error when compressing the image",
        OVERSIZED:
            "The image was too large to compress, the required compressed size is 200kb"
    }
};

const helpers = {
    COMPRESS: {
        WARN_QUALITY: () => {
            return window.alert(constants.COMPRESS.QUALITY);
        },
        ERROR: (err: any) => {
            console.log(err);
            return window.alert(constants.COMPRESS.ERROR);
        }
    }
};
