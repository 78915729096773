import React from "react";

// Screens

import { Text, View } from "../SharedComponents";
import { Styles } from "../utils";

const LoadingScreen = () => (
    <View
        style={{
            flex: 1,
            height: "100%",
            position: "absolute",
            width: "100%",
            ...Styles.flexCol,
            justifyContent: "center"
        }}
    >
        <p
            style={{
                fontSize: 100,
                textAlign: "center",
                marginBottom: 0
            }}
        >
            🐡
        </p>
        <Text center>loading...</Text>
    </View>
);

export default LoadingScreen;
