import {
    Formik,
    Form,
    FieldArray,
    FormikActions,
    Field,
    FieldArrayRenderProps,
    FormikProps,
} from "formik";
import { curry, noop } from "lodash/fp";
import React from "react";
import api from "../../api";
import * as Yup from "yup";
import { Input, styles } from "../../SharedComponents/Input";
import { getSelectedStoreId } from "../../redux/storeSubscription";
import {
    IStoreSubscription,
    IStoreSubscriptionPerk,
} from "@snackpass/snackpass-types";
import { Button, RowCustomInput, View } from "../../SharedComponents";
import { useSelector } from "react-redux";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

export interface FormValues
    extends Omit<
        IStoreSubscription,
        "_id" | "storeId" | "productId" | "priceId" | "perks" | "price"
    > {
    perks: Omit<IStoreSubscriptionPerk, "_id" | "storeId">[];
    price: number;
    name: string;
}

interface IOption {
    value: string;
    label: string;
}

const iconOptions: IOption[] = [
    {
        value: "pizza",
        label: "Pizza",
    },
    {
        value: "star",
        label: "Star",
    },
    {
        value: "arrows",
        label: "Arrows",
    },
    {
        value: "utensils",
        label: "Utensils",
    },
    {
        value: "tagCircle",
        label: "Tag Circle",
    },
    {
        value: "starCircle",
        label: "Star Circle",
    },
    {
        value: "shirtCircle",
        label: "Shirt Circle",
    },
    {
        value: "personCircle",
        label: "Person Circle",
    },
];

interface Props {
    storeSubscription: null | IStoreSubscription;
}

export const CreateSubscriptionForm = ({ storeSubscription }: Props) => {
    const selectedStoreId = useSelector(getSelectedStoreId);
    const _onSubmit =
        selectedStoreId === null ? noop : onSubmit(selectedStoreId);
    const initialValues: FormValues = {
        price: 0,
        perks: [],
        name: "",
    };
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={_onSubmit}
        >
            {(formProps: FormikProps<FormValues>) => (
                <Form>
                    <RowCustomInput
                        label="Price"
                        required
                        description="The price of monthly subscriptions you are looking to offer"
                    >
                        <Field
                            name="price"
                            type="number"
                            placeholder={storeSubscription?.price?.inDollars}
                        />
                    </RowCustomInput>
                    <RowCustomInput
                        label="Name"
                        required
                        description="Name of the subscription you want to offer (not shown to users)"
                    >
                        <Field name="name" type="text" />
                    </RowCustomInput>
                    <FieldArray
                        name="perks"
                        render={(arrayHelpers: FieldArrayRenderProps) => (
                            <PerkFields arrayHelpers={arrayHelpers} />
                        )}
                    />
                    <View style={stylesButtonView}>
                        <Button
                            style={styles.button}
                            label="Offer a subscription!"
                            type="submit"
                        />
                    </View>
                </Form>
            )}
        </Formik>
    );
};
interface PerkFields {
    arrayHelpers: FieldArrayRenderProps;
}

const PerkFields = ({ arrayHelpers }: PerkFields) => (
    <>
        <Perks
            perks={arrayHelpers.form.values.perks}
            arrayHelpers={arrayHelpers}
        />
        <Button
            label="Add another perk"
            type="button"
            onPress={() =>
                arrayHelpers.push({
                    color: "",
                    description: "",
                    icon: "",
                    name: "",
                })
            }
        />
    </>
);
interface PerksProps {
    perks: IStoreSubscriptionPerk[];
    arrayHelpers: FieldArrayRenderProps;
}

const Perks = ({ perks, arrayHelpers }: PerksProps) => (
    <>
        {perks.map((perk, index) => (
            <Perk elem={perk} index={index} arrayHelpers={arrayHelpers} />
        ))}
    </>
);

interface PerkProps {
    elem: IStoreSubscriptionPerk;
    index: number;
    arrayHelpers: FieldArrayRenderProps;
}

const Perk = ({ elem, index, arrayHelpers }: PerkProps) => (
    <RowCustomInput label={`Perk #${(index + 1).toString()}`}>
        <RowCustomInput label="">
            <Input
                name={`perks.${index}.name`}
                label="Name"
                type="text"
                placeholder={elem.name || "name"}
            />
        </RowCustomInput>
        <RowCustomInput label="">
            <Input
                name={`perks.${index}.description`}
                label="Description"
                type="text"
                placeholder={elem.description || "description"}
            />
        </RowCustomInput>
        <RowCustomInput label="">
            <Input
                name={`perks.${index}.color`}
                label="Color"
                type="text"
                placeholder={elem.color || "color"}
            />
        </RowCustomInput>
        <View style={stylesIconOptionView}>
            <Input
                name={`perks.${index}.icon`}
                type="select"
                label="Icon"
                options={iconOptions}
                placeholder="icon"
            />
        </View>
        <Button
            backgroundColor="red"
            label="remove"
            onPress={() => arrayHelpers.remove(index)}
        />
    </RowCustomInput>
);

export const validationSchema = Yup.object().shape({
    name: Yup.string().default("").required("Subscription name is required"),
    perks: Yup.array().of(
        Yup.object({
            name: Yup.string().required(),
            description: Yup.string().required(),
            color: Yup.string().required(),
            icon: Yup.string().required(),
        })
    ),
    price: Yup.number().required("Please enter a price for the subscription"),
});

const onSubmit = curry(
    (
        storeId: string,
        values: FormValues,
        actions: FormikActions<FormValues>
    ) => {
        const { perks, price, name } = values;
        actions.resetForm();
        return createStoreSubscription(storeId, name, perks, price);
    }
);

const createStoreSubscription = (
    storeId: string,
    name: string,
    perks: Omit<IStoreSubscriptionPerk, "_id" | "storeId">[],
    price: number
) =>
    api.subscriptions
        .create({
            storeId,
            subscriptionName: name,
            perks,
            price,
        })
        .then(res => {
            alert("Added new Store Subscription 💎");
        })
        .catch(console.error);

const stylesButtonView = {
    paddingLeft: "50%",
} as CSSProperties;
const stylesIconOptionView = {
    marginTop: 25,
} as CSSProperties;
