import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { IUser } from "@snackpass/snackpass-types";
import _ from "lodash";
import React, { CSSProperties, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import API from "src/api";
import { BanUserReasonsEnum } from "src/components/UserPreview/constants";
import { setActiveUser } from "src/redux/activeUser";
import { Colors } from "src/utils";

type Props = {
    isAlertOpen: boolean;
    activeUser?: IUser;
    sendAlertFeedback: (isBanned: boolean) => void;
    forceCloseAlert: () => void;
};

export const BanUserReasons = ({
    activeUser,
    sendAlertFeedback,
    isAlertOpen,
    forceCloseAlert,
}: Props) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [notes, setNotes] = useState<string>("");

    const _handleOnClose = () => {
        setNotes("");
        forceCloseAlert();
    };

    const finishBanningUser = async (reason?: BanUserReasonsEnum) => {
        try {
            setIsLoading(true);
            const res = await API.users.banOrUnban(
                activeUser?._id || "",
                true,
                reason,
                notes
            );
            const { user } = res.data;
            dispatch(setActiveUser(user));
            sendAlertFeedback(true);
        } catch (e) {
            alert(e.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog
            open={isAlertOpen}
            onClose={_handleOnClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText
                    id="alert-dialog-description"
                    style={styles.text}
                >
                    Why do you want to ban this user?
                </DialogContentText>
                <TextField
                    label="Additional notes (optional)"
                    value={notes}
                    onChange={event => setNotes(event.target.value)}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={isLoading}
                    onClick={() =>
                        finishBanningUser(
                            BanUserReasonsEnum.LooksBadPaymentFraud
                        )
                    }
                    style={styles.reasonBtn}
                >
                    Payment Fraud
                </Button>
                <Button
                    disabled={isLoading}
                    onClick={() =>
                        finishBanningUser(
                            BanUserReasonsEnum.LooksBadPromotionAbuse
                        )
                    }
                    color="primary"
                    style={styles.reasonBtn}
                >
                    Promo Abuse
                </Button>
                <Button
                    disabled={isLoading}
                    onClick={() => finishBanningUser()}
                    color="primary"
                    style={styles.noReasonBtn}
                >
                    Don't Provide Reason
                </Button>
                <Button
                    disabled={isLoading}
                    onClick={forceCloseAlert}
                    style={styles.cancelBtn}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const styles = {
    reasonBtn: {
        flex: 1,
        paddingTop: 5,
        paddingBottom: 5,
        backgroundColor: Colors.blue,
        color: Colors.white,
        fontWeight: 600,
        height: 50,
    } as CSSProperties,
    noReasonBtn: {
        flex: 1,
        paddingTop: 5,
        paddingBottom: 5,
        backgroundColor: Colors.purple,
        color: Colors.white,
        fontWeight: 600,
        height: 50,
    } as CSSProperties,
    cancelBtn: {
        flex: 1,
        paddingTop: 5,
        paddingBottom: 5,
        backgroundColor: Colors.lightGray,
        color: Colors.text,
        fontWeight: 600,
        height: 50,
    } as CSSProperties,
    text: {
        fontSize: 18,
    },
};
