import React, { ReactNode } from "react";

// All the props that are passed to the view component.
export interface IView {
    row?: boolean;
    backgroundColor?: string;
    style?: React.CSSProperties;
    className?: string;
    onPress?: (event: React.MouseEvent<HTMLDivElement>) => void;
    children?: ReactNode;
}

export const View = ({
    row,
    backgroundColor,
    className,
    onPress,
    children,
    style,
}: IView) => (
    <div
        className={className || ""}
        onClick={onPress}
        style={{
            display: "flex",
            backgroundColor: backgroundColor || "",
            alignSelf: "stretch",
            flexDirection: row ? "row" : "column",
            ...style,
        }}
    >
        {children}
    </div>
);
