import moment from "moment";
import React, { useEffect, useState } from "react";
import Modal from "react-responsive-modal";
import Table from "react-table";
import {
    IStore,
    PrinterUpdateAction,
} from "@snackpass/snackpass-types";

import { Colors } from "src/utils";
import api from "../../api";
import _ from "lodash";

type PrinterHistory = {
    serial: string;
    name: string;
    format: string;
    store: IStore;
    printer: string;
    status: string;
    asbStatus: string;
    lastPing: Date;
    action: PrinterUpdateAction;
    createdAt: Date;
};

type ReaderHistoryProps = {
    isOpen: boolean,
    onClose: () => void,
    printerSerial: string,
};

const INITIAL_PAGE = 0;
const DEFAULT_PAGE_SIZE = 10;

export const PrinterHistoryModal = ({ 
    isOpen, 
    onClose, 
    printerSerial, 
}: ReaderHistoryProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(INITIAL_PAGE);
    const [history, setHistory] = useState<PrinterHistory[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [fetchedPages, setFetchedPages] = useState<Set<number>>(new Set());

    const fetchHistory = async (page: number) => {
        // If page already fetched, don't do anything
        if (fetchedPages.has(page)) {
            return;
        }
        setIsLoading(true);

        const res = await api.printers.listHistory({ serial: printerSerial, page: page + 1});

        setHistory([...history, ...res?.data.histories]);
        setTotalPages(res?.data.totalPages);
        setPageSize(res?.data.pageSize);
        setFetchedPages(fetchedPages.add(page));

        setIsLoading(false);
    };

    useEffect(() => {
        fetchHistory(currentPage);
    }, [currentPage]);

    return (
        <Modal
            onClose={() => {
              onClose();
            }}
            open={isOpen}
            styles={styles.modal}
        >
            {history &&
                <Table
                    data={history}
                    columns={columns}
                    loading={isLoading}
                    page={currentPage}
                    pages={totalPages}
                    pageSize={pageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                    showPageSizeOptions={false}
                    resizable={false}
                    sortable={false}
                />
            }
        </Modal>
    );
};

const dateFormatter = (props: { value: string }) => (
    <span>
        {props.value
            ? moment(props.value).format("MM/DD/YY HH:mm:ss")
            : "Never"}
    </span>
);

const columns = [
    { Header: "Action", accessor: "action", minWidth: 40 },
    { Header: "Serial", accessor: "serial", minWidth: 40 },
    { Header: "Name", accessor: "name", minWidth: 40 },
    { Header: "Format", accessor: "format", minWidth: 40 },
    { Header: "Store Name", accessor: "store.name", minWidth: 40 },
    {
        Header: "Created At",
        id: "createdAt",
        accessor: "createdAt",
        Cell: dateFormatter,
        minWidth: 50
    },
];

const styles = {
  select: {
      margin: "10px",
  },
  modal: {
      overlay: {
          background: "rgba(0,0,0,0.23)",
      },
      modal: {
          background: Colors.white,
          padding: 20,
          boxShadow: "0 2px 40px 0 rgba(0, 0, 0, 0.3)",
          borderRadius: 5,
          overflow: "hidden",
          width: "100%",
          minWidth: "85%",
          minHeight: "40%",
      },
  },
};