import Modal from "react-responsive-modal";
import { connectModal } from "redux-modal";
import StoreForm from "../containers/StoreForm";
import { Colors } from "../utils";
import { IStore } from "@snackpass/snackpass-types";

const styles = {
    modal: {
        overlay: {
            background: "rgba(0,0,0,0.23)",
        },
        modal: {
            background: Colors.white,
            padding: 20,
            boxShadow: "0 2px 40px 0 rgba(0, 0, 0, 0.3)",
            borderRadius: 5,
            overflow: "hidden",
            marginBottom: 100,
            width: "85%",
        },
    },
};

export interface StoreFormModalProps {
    show: boolean;
    //modalVisible: boolean;
    handleHide: () => void;
    handleDestroy: () => void;
    storeObj?: IStore; // store to pass in editMode
    editMode: boolean;
    onSuccess: (store: IStore) => void;
}

const StoreFormModal = ({
    show,
    handleHide,
    storeObj,
    editMode,
}: StoreFormModalProps) => {
    // Valid
    if (storeObj) {
        console.log(`Store ${storeObj.name}:`, storeObj);
    }
    return (
        <Modal
            onClose={() => {
                handleHide();
            }}
            open={show}
            styles={styles.modal}
        >
            <StoreForm
                editMode={editMode}
                storeObj={storeObj}
                onSuccess={handleHide}
            />
        </Modal>
    );
};

export default connectModal({ name: "StoreFormModal" })(StoreFormModal);
