import { IStore } from "@snackpass/snackpass-types";
import { connect } from "react-redux";
import { Action } from "redux";
import { show } from "redux-modal";
import { ThunkDispatch } from "redux-thunk";

import { removeStore, setStore, addStore } from "../redux/stores";
import StoreRow from "../components/StoreRow";
import { setPrimaryStoreTablet } from "../redux/storeTablets";
import { StoreState } from "../redux/types";
import { removeArchivedStore, addArchivedStore } from "../redux/archivedStores";

const mapStateToProps = (state: StoreState) => {
    return {};
};

// create handler than dispatches an action
const mapDispatchToProps = (
    dispatch: ThunkDispatch<StoreState, undefined, Action>
) => {
    return {
        dispatch,
        showModal: (modal: string, props: any) => {
            dispatch(show(modal, props));
        },
        setStore: (store: IStore) => {
            dispatch(setStore(store));
        },
        removeStore: (store: string) => {
            dispatch(removeStore(store));
        },
        addStore: (store: IStore) => {
            dispatch(addStore(store));
        },
        // Below adds a store to the archivedStores redux key
        removeArchivedStore: (storeId: string) => {
            dispatch(removeArchivedStore(storeId));
        },
        addArchivedStore: (store: IStore) => {
            dispatch(addArchivedStore(store));
        },
        setPrimaryStoreTablet: async (storeTabletId: string) => {
            return dispatch(setPrimaryStoreTablet(storeTabletId));
        },
    };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(StoreRow);

export default Container;
