import { Action } from "redux";
import { IPurchase } from "@snackpass/snackpass-types";
// Constants

export const SET_PURCHASES = "SET_PURCHASES";
export const ADD_PURCHASES = "ADD_PURCHASES";
export const REPLACE_PURCHASE = "REPLACE_PURCHASE";

// Actions
export interface ISetPurchases extends Action {
    type: "SET_PURCHASES";
    purchases: IPurchase[];
}
export function setPurchases(purchases: IPurchase[]) {
    return { type: SET_PURCHASES, purchases };
}

export interface IAddPurchases extends Action {
    type: "ADD_PURCHASES";
    purchases: IPurchase[];
}
export function addPurchases(purchases: IPurchase[]) {
    return { type: ADD_PURCHASES, purchases };
}

export interface IReplacePurchase extends Action {
    type: "REPLACE_PURCHASE";
    purchase: IPurchase;
}
export function replacePurchase(purchase: IPurchase) {
    return { type: REPLACE_PURCHASE, purchase };
}

export type PurchaseAction = ISetPurchases | IAddPurchases | IReplacePurchase;

// Reducer
const initialState: IPurchase[] = [];

export function purchases(
    state: Array<IPurchase> = initialState,
    action: PurchaseAction
) {
    switch (action.type) {
        case SET_PURCHASES:
            return action.purchases;
        case REPLACE_PURCHASE:
            let purchases = [...state];
            for (let i = 0; i < purchases.length; i++) {
                const p = purchases[i];
                if (p._id === action.purchase._id) {
                    purchases[i] = action.purchase;
                }
            }
            return purchases;
        case ADD_PURCHASES:
            let ret = [...state];
            ret = ret.concat(action.purchases);
            return ret;
        default:
            return state;
    }
}
