import _ from "lodash";
import { createSelector } from "reselect";

import { StoreState } from "./types";
import { IUser } from "@snackpass/snackpass-types";

// Constants
export const SET_USER = "SET_USER";
export const UPDATE_USER = "UPDATE_USER";

// Actions
export interface SetUser {
    type: "SET_USER";
    user: IUser;
}
export function setUser(user: IUser) {
    return { type: SET_USER, user };
}

export interface UpdateUser {
    type: "UPDATE_USER";
    user: IUser;
}
export function updateUser(user: IUser) {
    return { type: "UPDATE_USER", user };
}

export type UserAction = SetUser | UpdateUser;

// Selectors
export const getUser = (state: StoreState) => state.user;

export const getIsEng = createSelector([getUser], user =>
    _.get(user, "snackpassPermissions.isEng", false)
);

// Reducer
const initialState = {};

export function user(state = initialState, action: UserAction) {
    switch (action.type) {
        case "SET_USER":
            return action.user;
        case "UPDATE_USER":
            return { ...state, ...action.user };
        default:
            return state;
    }
}
