import React from "react";
import { View } from "./View";

export interface ITouchableHighlight {
    className?: string;
    onPress?: (event: React.MouseEvent<HTMLDivElement>) => void;
    style?: React.CSSProperties;
    children?: JSX.Element | JSX.Element[];
}

export const TouchableHighlight = ({
    className,
    onPress,
    style,
    children,
}: ITouchableHighlight) => (
    <View
        className="touchable-highlight"
        onPress={onPress}
        style={{
            cursor: "pointer",
            display: "flex",
            ...style,
        }}
    >
        {children}
    </View>
);
