import React, { Component } from "react";
import { Dispatch } from "redux";

import { View, Text } from "../../SharedComponents";

import CreateAdmin from "./CreateAdmin";
import * as Styles from "../../utils/Styles";
import AdminsTable from "./components/AdminsTable";
import { IOption } from "../../redux/types";
import { ReduxUtil } from "../../utils";
import { IUser } from "@snackpass/snackpass-types";
import { isMobile } from "react-device-detect";

type Props = {
    user: IUser;
    storeItems: IOption[];
    admins: IUser[];
    setAdmin: (admin: IUser) => void;
    removeAdmin: (id: string) => void;
    dispatch: Dispatch;
    storeNameById: Record<string, string>;
};

type State = {
    admins: IUser[];
};

const sortCreatedAt = (a: IUser, b: IUser) =>
    new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();

const isPerson = (user: IUser) => user.userType === "PERSON";

export default class Admins extends Component<Props, State> {
    componentDidMount() {
        setTimeout(() => {
            ReduxUtil.loadAdmins(this.props.dispatch);
        }, 250);
    }

    render() {
        const {
            user,
            storeItems,
            storeNameById,
            admins,
            setAdmin,
            removeAdmin,
            dispatch
        } = this.props;

        return (
            <View
                style={{
                    ...Styles.panel,
                    display: "block",
                    padding: isMobile ? 5 : 20
                }}
            >
                <Text large>Admins 👩‍💻</Text>
                <hr />
                <CreateAdmin dispatch={dispatch} storeItems={storeItems} />
                <AdminsTable
                    loading={!user || admins.length <= 0}
                    setAdmin={setAdmin}
                    removeAdmin={removeAdmin}
                    admins={admins.sort(sortCreatedAt).filter(isPerson)}
                    storeNameById={storeNameById}
                    storeOptions={storeItems}
                />
            </View>
        );
    }
}
