import { Colors } from "../utils";
import ToggleButton from "react-toggle-button";

export interface IToggle {
    value?: any;
    activeColor?: string;
    inactiveColor?: string;
    onToggle?: (value: boolean) => void;
    activeLabel?: string;
    inactiveLabel?: string;
    label?: string;
    disabled?: boolean;
}

export const Toggle = ({
    value,
    activeColor = Colors.green2,
    inactiveColor = "#414244",
    onToggle,
    activeLabel,
    inactiveLabel,
    disabled,
}: IToggle) => (
    <ToggleButton
        activeLabel={activeLabel}
        inactiveLabel={inactiveLabel}
        colors={{
            active: {
                base: activeColor,
                hover: activeColor,
            },
            inactive: {
                base: disabled ? Colors.lightGray : inactiveColor,
                hover: disabled ? Colors.lightGray : inactiveColor,
            },
        }}
        value={value || false}
        onToggle={(value: boolean) => {
            !disabled && onToggle && onToggle(!value);
        }}
    />
);
