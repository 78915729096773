import React, { useState } from "react";
import { Field } from "formik";
import styled from "styled-components";
import { Colors } from "../../../utils";

type InputProps = {
    isEnterprise?: boolean | string;
};

export const EnterpriseInput = ({ isEnterprise }: InputProps) => {
    const [isEnterpriseChecked, setIsEnterpriseChecked] =
        useState(isEnterprise);

    return (
        <Container>
            <TitleDescription>
                <p>Is this store an Enterprise?</p>
                <Description>
                    Any brand that has 7 or more locations
                </Description>
            </TitleDescription>
            <RadioContainer>
                <label>
                    <Field
                        type="radio"
                        name="isEnterprise"
                        value={true}
                        checked={isEnterpriseChecked === true}
                        onClick={() => setIsEnterpriseChecked(true)}
                    />
                    Enterprise
                </label>
                <label>
                    <Field
                        type="radio"
                        name="isEnterprise"
                        value={false}
                        checked={isEnterpriseChecked === false}
                        onClick={() => setIsEnterpriseChecked(false)}
                    />
                    Non Enterprise
                </label>
            </RadioContainer>
        </Container>
    );
};

const Container = styled.div`
    border-bottom: 1px solid ${Colors.lightGray};
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
`;
const RadioContainer = styled.div`
    display: flex;
    justify-content: space-around;
    width: 50%;
`;
const TitleDescription = styled.div`
    display: flex;
    flex-direction: column;
`;
const Description = styled.text`
    font-size: 12px;
    color: ${Colors.warmGrey};
    margin-top: 1px;
`;
