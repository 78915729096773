import React from "react";

import Modal from "react-responsive-modal";
import { connectModal } from "redux-modal";
import QrCodeForm from "../components/QrCodeForm";
import { Colors } from "../utils";

export interface QrCodeFormModalProps {
    show: boolean;
    handleHide: () => void;
}

const QrCodeFormModal = ({ show, handleHide }: QrCodeFormModalProps) => {
    return (
        <Modal onClose={handleHide} open={show} styles={styles.modal}>
            <QrCodeForm />
        </Modal>
    );
};

export default connectModal({ name: "QrCodeFormModal" })(QrCodeFormModal);

const styles = {
    modal: {
        overlay: {
            background: "rgba(0,0,0,0.23)",
        },
        modal: {
            background: Colors.white,
            padding: 20,
            boxShadow: "0 2px 40px 0 rgba(0, 0, 0, 0.3)",
            borderRadius: 5,
            overflow: "hidden",
            marginTop: 100,
            width: "85%",
        },
    },
};
