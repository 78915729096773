import React, { useEffect } from "react";
import { Card } from "antd";
import { compose } from "lodash/fp";
import { IUser } from "@snackpass/snackpass-types";
import { show } from "redux-modal";

import { ChainsTable } from "../../components/Chains/ChainsTable";
import StoreChainModal from "../../containers/StoreChainModal";
import { Button } from "../../SharedComponents";
import { Styles } from "../../utils";
import { useDispatch } from "react-redux";
import { fetchChains } from "../../redux/chains";
import { getPathFromState } from "../../utils/Helpers";

const Chains = () => {
    const currentUser: IUser = getPathFromState("user");
    const dispatch = useDispatch();
    const _fetchChains = compose(dispatch, fetchChains);

    useEffect(() => {
        if (currentUser._id) {
            _fetchChains();
        }
    }, [currentUser]);

    return (
        <div>
            <StoreChainModal />
            <Card
                style={{
                    width: "90%",
                    margin: "0 auto",
                    borderRadius: 5,
                    ...Styles.shadowLight,
                }}
                headStyle={{
                    fontSize: 22,
                    borderBottom: "none",
                }}
                bodyStyle={{
                    padding: 0,
                }}
                title="Store Chains + FoodHalls 🏢"
                extra={
                    <Button
                        label="Make Chain"
                        onPress={() => dispatch(show("StoreChainModal"))}
                    />
                }
            >
                <ChainsTable />
            </Card>
        </div>
    );
};

export default Chains;
