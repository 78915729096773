import { connect } from "react-redux";
import {} from "../redux/activeUser";
import Stores from "../screens/Users";
import { Dispatch } from "redux";
import { StoreState } from "../redux/types";

const mapStateToProps = (state: StoreState) => {
    return {
        activeUser: state.activeUser,
    };
};

// create handler than dispatches an action
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {};
};

const Container = connect(mapStateToProps, mapDispatchToProps)(Stores);

export default Container;
