import {
    Form,
    FormikProps,
    Formik,
    FormikActions,
    Field,
    FieldProps
} from "formik";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChainGiftCardPayoutType, IChain } from "@snackpass/snackpass-types";
import Swal from "sweetalert2";
import { Dispatch } from "redux";

import { FormValues, validationSchema } from "./form";
import { Button, Input } from "../../SharedComponents";
import { styles } from "./styles";
import FormikHelpers from "../../utils/FormikHelpers";
import ManageChainStores from "./components/ManageChainStores";
import { initializeFields } from "./form";
import API from "../../api";
import { addChain } from "../../redux/chains";
import { StoreState } from "src/redux/types";
import { getChainStores } from "src/redux/stores";

export interface ChainFormProps {
    editMode?: any;
    chain?: IChain;
    onSuccess: (chain: IChain) => void;
}

const ChainForm = (props: ChainFormProps) => {
    const { chain } = props;
    const chainId = chain?._id; // Undefined if creating a new chain
    const dispatch = useDispatch();

    const _onSubmit = onSubmit(props, dispatch);

    const storesInChain = useSelector((state: StoreState) =>
        chainId ? getChainStores(state, chainId) : []
    );

    return (
        <Formik
            enableReinitialize={true}
            validationSchema={validationSchema}
            initialValues={initializeFields(props.chain)}
            onSubmit={_onSubmit}
        >
            {(formProps: FormikProps<FormValues>) => {
                const errorSummary = renderErrorSummary(formProps.errors);
                const submitSection = renderSubmitSection(props, formProps);

                return (
                    <Form onKeyPress={ignoreEnterKey}>
                        <Field name="name" render={renderInput} />
                        <Input name="logoUrl" label="Logo" type="file-upload" />
                        <Input
                            name="giftCardPayoutType"
                            label="Gift Card Payout Type"
                            type="select"
                            description="If set to Single Account, the Root Store will receive all money from gift cards being bought for any store below opted into chain-wide gift card."
                            options={(
                                Object.keys(ChainGiftCardPayoutType) as Array<
                                    keyof typeof ChainGiftCardPayoutType
                                >
                            ).map((type) => ({
                                label:
                                    ChainGiftCardPayoutType[type] ===
                                    ChainGiftCardPayoutType.Pooling
                                        ? "Single Account"
                                        : "Purchase Location",
                                value: ChainGiftCardPayoutType[type]
                            }))}
                        />

                        {props.editMode ? (
                            <div>
                                <Input
                                    name="rootStore"
                                    label="Root Store"
                                    type="select"
                                    selectClassNameOverride="snackpass__react-select_big"
                                    description="Represents the store that has ownership over all the stores in the chain."
                                    options={storesInChain.map((store) => ({
                                        value: store._id,
                                        label: `${store.name} - ${store.region}`
                                    }))}
                                    isClearable={true}
                                />
                                <ManageChainStores
                                    chain={props.chain as IChain}
                                />
                            </div>
                        ) : (
                            <div
                                style={{
                                    margin: "20px auto",
                                    padding: "50px 0",
                                    textAlign: "center",
                                    borderRadius: 5,
                                    border: "1px solid #efefef"
                                }}
                            >
                                <h3>
                                    You can add stores to this chain after you
                                    create it!
                                </h3>
                            </div>
                        )}

                        {submitSection}
                        {errorSummary}
                    </Form>
                );
            }}
        </Formik>
    );
};

const onSubmit = _.curry(
    async (
        props: ChainFormProps,
        dispatch: Dispatch,
        values: FormValues,
        formikActions: FormikActions<FormValues>
    ) => {
        formikActions.setSubmitting(true);

        try {
            const body = _.pick(values, [
                "name",
                "logoUrl",
                "giftCardPayoutType",
                "rootStore"
            ]);
            const chain = props.chain;
            const editMode = props.editMode;

            let response;
            if (editMode && chain) {
                response = await API.chains.update(chain._id, { fields: body });
            } else {
                response = await API.chains.create({ fields: body });
            }

            dispatch(addChain(response.data.chain));
            props.onSuccess(response.data.chain);
        } catch (err) {
            Swal.fire({
                title: `An error occurred`,
                text: _.get(
                    err,
                    "response.data.message",
                    "Could not save chain."
                )
            });
        } finally {
            formikActions.setSubmitting(false);
        }
    }
);

const renderErrorSummary = (errors: any) => {
    if (Object.keys(errors || {}).length === 0) {
        return null;
    }

    const stringifiedErrors = FormikHelpers.stringifyErrors(errors);

    return (
        <div style={styles.errorContainer}>
            <p className="error" style={{ whiteSpace: "pre-wrap" }}>
                {stringifiedErrors}
            </p>
        </div>
    );
};

const renderSubmitSection = (
    props: ChainFormProps,
    formProps: FormikProps<FormValues>
) => {
    return (
        <Button
            label={props.editMode ? "Save" : "Create"}
            disabled={!formProps.isValid}
            onPress={() => formProps.handleSubmit()}
            type="button"
            className="submit-btn"
            loading={formProps.isSubmitting}
            style={{ width: 120, height: 40 }}
        />
    );
};

const renderInput = ({ field, form }: FieldProps<FormValues>) => (
    <div>
        <input
            className={`input-${field.name}`}
            type="text"
            {...field}
            placeholder="Enter name..."
        />
        <span className={`error-${field.name}`} style={styles.errorMessage}>
            {form.touched[field.name as keyof FormValues] &&
                form.errors[field.name as keyof FormValues]}
        </span>
    </div>
);

const ignoreEnterKey = (e: { key: string; preventDefault: Function }) => {
    if (e.key === "Enter") {
        e.preventDefault();
    }
};

export default ChainForm;
