// This file can be abstracted for all forms using Formik
import React, { ReactNode } from "react";

import { Colors } from "../../utils";
import { Field, ErrorMessage, FieldProps, FastField } from "formik";
import { IOption } from "../../redux/types";

import { Checkbox } from "react-formik-ui";
import ReactDateTime from "react-datetime";
import Select from "react-select";
import moment from "moment";
import { Hours } from "../Hours";
import { FormValues } from "./types";
import { Toggle, FileUpload } from "../../SharedComponents";
import { SnackpassTimezoneEnum } from "@snackpass/snackpass-types";

export const styles = {
    inputContainer: {
        backgroundColor: Colors.white,
        borderColor: Colors.lightGray,
        borderWidth: 0,
        borderBottomWidth: 1,
        borderStyle: "solid"
    },
    inputInnerContainer: {
        display: "flex" as "flex",
        flexDirection: "row" as "row",
        alignSelf: "stretch",
        alignItems: "center",
        paddingTop: 15,
        paddingBottom: 15
    },
    inputLabel: {
        marginBottom: 5
    },
    inputDescription: {
        whiteSpace: "pre-wrap" as "pre-wrap",
        fontSize: 12,
        color: Colors.warmGrey
    },
    header: {
        background: Colors.blue2,
        padding: 15,
        paddingTop: 5,
        paddingBottom: 5,
        margin: 0,
        borderWidth: 0,
        borderBottomWidth: 1,
        borderColor: "white",
        borderStyle: "solid",
        marginBottom: 10,
        color: "white"
    },
    errorMessage: {
        color: Colors.red,
        fontSize: 12
    },
    button: {
        color: Colors.white,
        backgroundColor: Colors.blue
    },
    dealItemContainer: {
        borderWidth: 1,
        borderColor: Colors.lightGray,
        borderRadius: 8,
        margin: 20,
        boxShadow: "0px 0px 7px rgba(0,0,0,.5)",
        borderStyle: "solid",
        padding: 20
    },
    errorContainer: {
        maxWidth: "80%",
        padding: 10,
        background: "#FFFBFB",
        borderRadius: 4,
        borderColor: "#FFBABA",
        borderStyle: "solid",
        borderWidth: 1,
        margin: 15
    },
    succcessContainer: {
        maxWidth: "80%",
        padding: 10,
        background: "#F1FFF7",
        borderRadius: 4,
        borderColor: "#5FC990",
        borderStyle: "solid",
        borderWidth: 1,
        margin: 15
    }
};
interface InputProps {
    required?: boolean;
    label: string;
    name: any;
    description?: string;
    type:
        | "toggle"
        | "select"
        | "text"
        | "date"
        | "datetime"
        | "email"
        | "password"
        | "number"
        | "hours-of-week"
        | "checkbox"
        | "read-only-text"
        | "image-upload";
    children?: ReactNode;
    options?: IOption[];
    isMulti?: boolean;
    index?: number;
    flexDirection?: any;
    fastfield?: boolean;
    disabled?: boolean;
    value?: string;
    placeholder?: string;
    onSelect?: (value: any) => void;
    onToggle?: (value: any) => void;
}

// Custom wrapper around Formik's Field component switches field type with
// either Formik Field or our own custom input. If you are adding a new custom
// input, be sure to use the component prop for Formik Field.
export const Input = ({
    name,
    label,
    description,
    type,
    options,
    required,
    children,
    isMulti = false,
    flexDirection = "row" as "row",
    fastfield = false,
    disabled,
    placeholder,
    value,
    onSelect,
    onToggle
}: InputProps) => {
    // Map type prop to different types of custom inputs.
    let FieldInput;
    if (type === "select") {
        if (options) {
            FieldInput = (
                <Field
                    name={name}
                    component={({ form, field }: FieldProps<FormValues>) => (
                        <MySelect
                            form={form}
                            field={field}
                            disabled={disabled}
                            options={options}
                            isMulti={isMulti}
                            onSelect={onSelect}
                        />
                    )}
                />
            );
        } else {
            FieldInput = (
                <span style={styles.errorMessage}>
                    Pass options to component
                </span>
            );
        }
    } else if (type === "toggle") {
        FieldInput = (
            <Field
                name={name}
                component={({ form, field }: FieldProps<FormValues>) => (
                    <MyToggle
                        form={form}
                        field={field}
                        disabled={disabled}
                        onToggle={onToggle}
                    />
                )}
            />
        );
    } else if (type === "datetime") {
        // use react- datetime for selecting date times
        FieldInput = <Field name={name} component={MyFormikPromoActiveTime} />;
    } else if (type === "hours-of-week") {
        // Custom Hour Picker
        FieldInput = <Hours name={name} />;
    } else if (type === "checkbox") {
        FieldInput = <Checkbox name={name} />;
    } else if (type === "read-only-text") {
        FieldInput = (
            <Field
                name={name}
                render={({ field, form }: FieldProps<FormValues>) => (
                    <p style={{ fontWeight: "bold" }}>{field.value}</p>
                )}
            />
        );
    } else if (type === "image-upload") {
        FieldInput = (
            <Field
                name={name}
                render={({ field, form }: FieldProps<FormValues>) => (
                    <FileUpload
                        onFileUpload={(url) => {
                            form.setFieldValue("imageUrl", url);
                        }}
                        showsInputUrl={true}
                        multipleFiles={false}
                        prevUrl={field.value}
                        path="promotion"
                    />
                )}
            />
        );
    } else {
        if (fastfield) {
            FieldInput = (
                <FastField name={name} type={type}>
                    {children}
                </FastField>
            );
        } else {
            FieldInput = (
                <Field placeholder={placeholder} name={name} type={type}>
                    {children}
                </Field>
            );
        }
    }

    // required prop is purely stylistic.
    let Required;
    if (required) {
        Required = <span style={{ color: Colors.red }}>*</span>;
    }

    let Disabled;
    if (disabled) {
        Disabled = (
            <span style={{ color: Colors.gray }}>(this field is disabled)</span>
        );
    }

    return (
        <div style={styles.inputContainer}>
            <div
                style={{
                    ...styles.inputInnerContainer,
                    flexDirection: flexDirection
                }}
            >
                <div style={{ flex: 1, paddingRight: 20 }}>
                    <p style={styles.inputLabel}>
                        {label} {Required}
                    </p>

                    <p style={styles.inputDescription}>{description} </p>
                    <ErrorMessage
                        name={name}
                        render={(msg) => (
                            <span style={styles.errorMessage}>{msg}</span>
                        )}
                    />
                </div>
                <div style={{ flex: 1 }}>
                    {FieldInput} <br />
                    {Disabled}
                </div>
            </div>
        </div>
    );
};

// Header function to divide form into easy to read sections
export const Header = ({ label }: { label: string }) => (
    <div>
        <h3 style={styles.header}>{label}</h3>
    </div>
);

interface MySelectProps {
    options: IOption[];
    isMulti: boolean;
    field: any;
    form: any;
    disabled?: boolean;
    onSelect?: (value: any) => void;
}

// Requires funky mapping of options to value because select requires
// value={ value: "x", label: "y"} as the value, whereas the value in form is just
// stored as value="x"
export class MySelect extends React.Component<MySelectProps> {
    handleChange = (value: any) => {
        const { isMulti, disabled, onSelect } = this.props;

        // do not change if the select is disabled
        if (disabled) {
            return;
        }

        // this is going to call setFieldValue and manually update values.topcis
        // when isMulti, instead of sending up array of objects of IOption,
        // convert to array of values (which are strings)
        // when not multi, send back value (string) instead of object (IOption)
        this.props.form.setFieldValue(
            this.props.field.name,
            isMulti
                ? value
                    ? value.map((option: IOption) => option.value)
                    : []
                : value?.value
        );

        if (onSelect) {
            onSelect(value?.value);
        }
    };

    handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched.topcis
        this.props.form.setFieldTouched(this.props.field.name, true);
    };

    render() {
        const { options, isMulti, field } = this.props;
        const value = field.value;
        const singleSelectValue = options.find((o) => o.value === value);
        return (
            <div style={{ margin: "1rem 0" }}>
                <Select
                    id="color"
                    options={options}
                    isMulti={isMulti}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    className="snackpass__react-select"
                    value={
                        isMulti && value
                            ? // not performant, but clearest representation of what is happening
                              value.map((val: string) =>
                                  options.find((o) => o.value === val)
                              )
                            : singleSelectValue
                    }
                />
            </div>
        );
    }
}

const MyFormikPromoActiveTime = ({ field, form }: FieldProps<FormValues>) => {
    const storeTimezone =
        form.values.store?.hours.zone ?? SnackpassTimezoneEnum.newYork;

    const onFieldChange = (value: any) => {
        let dateValue = value;
        // if the date field isn't in a valid date format,
        // react-datetime's onChange handler returns a string
        // otherwise it returns a moment object
        // this is why we can't override DateTime's onChange
        // prop with Formik's field.onChange
        if (value instanceof moment) {
            dateValue = moment(value).tz(storeTimezone).toDate();
            form.setFieldValue(field.name, dateValue);
        } else {
            // if the user removed the value
            form.setFieldValue(field.name, null);
        }
    };
    const onFieldBlur = () => {
        // use try/catch because sometimes, if nested object, the field will not
        // exist yet.
        try {
            form.setFieldTouched(field.name, true);
        } catch (err) {
            console.log(err);
        }
    };
    // assumes local (Pacific) if nothing set, then converts to displayTimeZone
    return (
        <ReactDateTime
            value={field.value ? new Date(field.value) : undefined}
            onChange={onFieldChange}
            onClose={onFieldBlur}
            displayTimeZone={storeTimezone}
        />
    );
};

interface ToggleProps extends FieldProps<FormValues> {
    disabled?: boolean;
    onToggle?: (value: any) => void;
}

const MyToggle = ({ field, form, disabled, onToggle }: ToggleProps) => {
    const onFieldChange = (value: any) => {
        if (disabled) {
            return;
        }
        form.setFieldValue(field.name, value);
        // use try/catch because sometimes, if nested object, the field will not
        // exist yet.
        if (onToggle) {
            onToggle(value);
        }
        try {
            form.setFieldTouched(field.name, true);
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <Toggle
            disabled={disabled}
            value={field.value}
            onToggle={onFieldChange}
        />
    );
};

// This is necessary because if the yup schema has errors, it will fail silently
// and will allow you to submit the form, which is dumb.
export function validYupSchema(values: FormValues, validationSchema: any) {
    try {
        let isValid = validationSchema.isValidSync(values);

        return (
            <p
                style={
                    isValid ? styles.succcessContainer : styles.errorContainer
                }
            >
                {isValid ? "Valid Promo ✅" : "Promo contains errors ❌"}
            </p>
        );
    } catch (err) {
        return (
            <p style={styles.errorContainer}>
                Yup Schema contains errors! ❌❌❌❌ {JSON.stringify(err)} Alert
                the eng team!
            </p>
        );
    }
}
