import React, { useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { show } from "redux-modal";
import { propOr } from "lodash/fp";

import { setActiveStore } from "../../redux/activeStore";
import { getStoreSelectOptions, getActiveStore } from "../../redux/stores";
import { Text, Button } from "../../SharedComponents";
import { Styles, Colors } from "../../utils";
import { IOption } from "../../redux/types";
import QrCodeFormModal from "../../modals/QrCodeFormModal";

const filtersInitialState = {
    selectedArchiveOption: {
        value: "not_archived",
        label: "Not Archived",
    } as IOption | null,
    selectedStoreOption: null as IOption | null,
};

const QrCodesScreen = () => {
    const activeStore = useSelector(getActiveStore);
    const activeStoreIsLive = propOr(false, "isLive", activeStore);
    const storeOptions = useSelector(getStoreSelectOptions);
    const dispatch = useDispatch();
    const [filters, setFilters] = useState(filtersInitialState);

    // select store
    async function handleSelectStore(item: IOption) {
        if (!item) return;
        setFilters({ ...filters, selectedStoreOption: item });
        dispatch(setActiveStore(item.value as string));
    }

    return (
        <div style={styles.panel}>
            <div style={styles.contentWrap}>
                <Text large>Find Store QR Code 📱</Text>
                <div style={styles.divider} />
                <div style={styles.selectWrap}>
                    <Select
                        className="snackpass__react-select"
                        name="store"
                        placeholder="select store first! 🚀"
                        options={storeOptions}
                        clearable={true}
                        clearValueText="clear"
                        onChange={handleSelectStore}
                        value={filters.selectedStoreOption}
                    />
                </div>
                <div style={styles.divider} />
                {activeStore ? (
                    <div style={styles.liveText}>
                        {activeStoreIsLive ? (
                            <Text>
                                Store is{" "}
                                <span style={styles.blueBoldText}>Live</span> 🎉
                            </Text>
                        ) : (
                            <Text>
                                Store is{" "}
                                <span style={styles.redBoldText}>Not Live</span>{" "}
                                😓
                            </Text>
                        )}
                    </div>
                ) : null}
                <div style={styles.divider} />
                {activeStore ? (
                    <Button
                        label={`Get QR Code For ${activeStore.name}`}
                        onPress={() => dispatch(show("QrCodeFormModal"))}
                    />
                ) : null}
            </div>
            <QrCodeFormModal />
        </div>
    );
};

export default QrCodesScreen;

const styles = {
    panel: {
        ...Styles.panel,
        overflow: "scroll",
        height: 500,
        textAlign: "center",
    } as React.CSSProperties,
    contentWrap: {
        display: "inline-block",
        marginTop: 10,
        textAlign: "center",
    } as React.CSSProperties,
    divider: {
        width: "100%",
    } as React.CSSProperties,
    selectWrap: {
        display: "inline-block",
    } as React.CSSProperties,
    liveText: {
        display: "inline-block",
        marginTop: 20,
        marginBottom: 20,
    } as React.CSSProperties,
    blueBoldText: {
        color: Colors.blue,
        fontWeight: 700,
    } as React.CSSProperties,
    redBoldText: {
        color: Colors.red,
        fontWeight: 700,
    } as React.CSSProperties,
};
