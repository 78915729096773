// Load CSS
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import React from "react";
import styles from "./styles";
import { Colors, Helpers } from "../../utils";
import Select from "react-select";
import { Text } from "../../SharedComponents";
import { IStore } from "@snackpass/snackpass-types";
import { IOption } from "../../redux/types";
import moment from "moment";
import ReactDateTime from "react-datetime";

export type TimeMode = "DEFAULT_TIME" | "ARBITRARY_DATE";

type ToggleOptionProps = {
    timeMode: TimeMode;
    onChangeMode: (timeMode: TimeMode) => void;
};

export const DURATION_OPTIONS = [
    { value: "CUSTOM", label: "Custom Duration" },
    { value: 5, label: "5 Minutes" },
    { value: 10, label: "10 Minutes" },
    { value: 15, label: "15 Minutes" },
    { value: 20, label: "20 Minutes" },
    { value: 25, label: "25 Minutes" },
    { value: 30, label: "30 Minutes" },
    { value: 45, label: "45 Minutes" },
    { value: 1 * 60, label: "1 Hours" },
    { value: 2 * 60, label: "2 Hours" },
    { value: 6 * 60, label: "6 Hours" },
    { value: 12 * 60, label: "12 Hours" },
    { value: 24 * 60, label: "24 Hours" },
    { value: 48 * 60, label: "48 Hours" },
    { value: 24 * 60 * 7, label: "1 Week" },
    { value: 24 * 60 * 7 * 2, label: "2 Weeks" },
    { value: 24 * 60 * 30, label: "30 Days" },
    { value: "REST_OF_DAY", label: "Rest of day" },
];

const Default = ({ timeMode, onChangeMode }: ToggleOptionProps) => {
    return (
        <div
            onClick={() => onChangeMode("DEFAULT_TIME")}
            style={{
                ...styles.toggle,
                borderRight: "1px solid #ccc",
                borderTopLeftRadius: 4,
                borderBottomLeftRadius: 4,
                color: timeMode === "DEFAULT_TIME" ? Colors.white : Colors.gray,
                backgroundColor:
                    timeMode === "DEFAULT_TIME" ? Colors.blue : "transparent",
            }}
        >
            Num. Minutes
        </div>
    );
};

const Arbitrary = ({ timeMode, onChangeMode }: ToggleOptionProps) => {
    return (
        <div
            onClick={() => onChangeMode("ARBITRARY_DATE")}
            style={{
                ...styles.toggle,
                borderTopRightRadius: 4,
                borderBottomRightRadius: 4,
                color:
                    timeMode === "ARBITRARY_DATE" ? Colors.white : Colors.gray,
                backgroundColor:
                    timeMode === "ARBITRARY_DATE" ? Colors.blue : "transparent",
            }}
        >
            Until Date
        </div>
    );
};

type ToggleProps = {
    mode: any;
    timeMode: TimeMode;
    date: null | moment.Moment;
    duration: any;
    customDuration: any;
    store: IStore;
    onChangeTimeMode: (timeMode: TimeMode) => void;
    onChangeDuration: (duration: any) => void;
    onChangeDate: (date: moment.Moment | null) => void;
    onChangeCustomDuration: (customDuration: any) => void;
};

const Toggle = ({
    timeMode,
    date,
    duration,
    customDuration,
    onChangeTimeMode,
    onChangeDate,
    onChangeDuration,
    onChangeCustomDuration,
    store,
    mode,
}: ToggleProps) => {
    const renderDurationMessage = () => {
        if (!duration || !store) return <span />;
        let durationValue = duration;

        if (durationValue === "REST_OF_DAY") {
            let timeZone = moment.tz.guess();
            var timeZoneOffset = new Date().getTimezoneOffset();
            let timezone = moment.tz.zone(timeZone);

            return (
                <Text style={{ textAlign: "center" }}>
                    <span>
                        This will pause the store until 8 AM tomorrow{" "}
                        {timezone ? `(${timezone.abbr(timeZoneOffset)})` : ""}.
                    </span>
                </Text>
            );
        }

        if (durationValue === "CUSTOM") {
            if (!customDuration) {
                return;
            }
            durationValue = customDuration;
        }

        let untilTime = moment()
            .add(durationValue, "minutes")
            .format("h:mm a, M/DD");

        // convert time
        let hrs = Math.floor(durationValue / 60);
        let mins = durationValue % 60;

        return (
            <Text style={{ textAlign: "center" }}>
                <span>
                    Are you sure you want to {mode} {store.name} for{" "}
                    {hrs ? hrs + " Hours " : null}
                    {mins ? mins + " Minutes " : null}
                    (until {untilTime})
                </span>
            </Text>
        );
    };

    const renderDurationSelect = () => {
        return (
            <Select
                name="type"
                placeholder="select duration"
                options={DURATION_OPTIONS}
                className="snackpass__react-select"
                onChange={(item: IOption) => {
                    if (!item.value) {
                        return;
                    }
                    let value = (item as IOption).value;
                    onChangeDuration(value);
                    if (customDuration && item.value !== "CUSTOM") {
                        onChangeCustomDuration(null);
                    }
                }}
            />
        );
    };

    const renderDurationInput = () => {
        let hrs = Math.floor(customDuration / 60);
        let mins = customDuration % 60;
        return (
            <div>
                <input
                    name="text"
                    style={{ width: 200 }}
                    placeholder="Input time in minutes"
                    onKeyPress={event => {
                        if (!parseInt(event.key) && event.key !== "0") {
                            event.preventDefault();
                        }
                    }}
                    onChange={event => {
                        onChangeCustomDuration(event.target.value);
                    }}
                />
                {customDuration ? (
                    <Text small style={{ marginLeft: 20 }}>
                        {hrs ? hrs : 0} Hours : {mins ? mins : 0} Minutes
                    </Text>
                ) : null}
            </div>
        );
    };

    const renderSelectArbitraryDate = () => {
        return (
            <div style={{ maxWidth: 300 }}>
                <ReactDateTime
                    value={date || undefined}
                    onChange={value => onChangeDate(moment(value))}
                />
            </div>
        );
    };

    return (
        <div>
            <div style={styles.container}>
                <Default timeMode={timeMode} onChangeMode={onChangeTimeMode} />
                <Arbitrary
                    timeMode={timeMode}
                    onChangeMode={onChangeTimeMode}
                />
            </div>
            {timeMode === "DEFAULT_TIME" && (
                <div style={{ margin: "20px 0" }}>
                    <div
                        style={{
                            margin: "20px 0",
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <h4>Duration:</h4>
                        <p
                            style={{
                                color: Colors.red,
                                marginLeft: 3,
                            }}
                        >
                            *
                        </p>
                    </div>
                    {renderDurationSelect()}
                    <br />
                    {duration === "CUSTOM" ? renderDurationInput() : null}
                    <br />
                    {renderDurationMessage()}
                </div>
            )}
            {timeMode === "ARBITRARY_DATE" && (
                <div style={{ margin: "20px 0" }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <h4>{Helpers.capitalize(mode)} until:</h4>
                        <p
                            style={{
                                color: Colors.red,
                                marginLeft: 3,
                            }}
                        >
                            *
                        </p>
                    </div>
                    <br />
                    {renderSelectArbitraryDate()}
                </div>
            )}
        </div>
    );
};

export default Toggle;
