import React, { useState, useEffect, CSSProperties, useRef } from "react";
import { Button, View, Text } from "../../../SharedComponents";
import Modal from "react-modal";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

type ImageCropperProps = {
    onSubmit: (file: File) => void;
    onCancel: () => void;
    imageFile: File | null;
    isOpen: boolean;
    onRequestClose: () => void;
};

/* dialogue that let users crop their image
 * aspect ratio is 1:1 for store logos
 */
export const ImageCropperModal = ({
    onSubmit,
    onCancel,
    imageFile,
    isOpen,
    onRequestClose
}: ImageCropperProps) => {
    const [imgSrc, setImgSrc] = useState<string | null>(null);
    const [fileExtension, setFileExtension] = useState<string>("");
    const [cropper, setCropper] = useState<any>();

    // for dynamic modal height
    const imageRef = useRef<HTMLDivElement>(null);
    const [imageRenderedHeight, setImageRenderedHeight] = useState<number>(0);

    useEffect(() => {
        if (imageFile) {
            getImageSrcFromFile(imageFile);
            // save the file name and extension for later
            const fileNameData = imageFile.name.split(".");
            setFileExtension(fileNameData.pop() || "");
        }
    }, [imageFile]);

    useEffect(() => {
        if (imageRef.current) {
            setImageRenderedHeight(imageRef.current.clientHeight);
        }
    }, [setImageRenderedHeight]);

    const modalStyles = {
        content: {
            margin: "auto",
            maxWidth: 600,
            maxHeight: imageRenderedHeight + 490,
            width: "90%",
            height: "90%"
        },
        overlay: { zIndex: 1000 }
    };

    // convert image file to base64 data URL & set to imageSrc
    const getImageSrcFromFile = (file: File | null) => {
        if (file) {
            const reader = new FileReader();
            reader.addEventListener(
                "load",
                () => {
                    // result is string with a data URL representing the file's data
                    const res = reader.result as string;
                    setImgSrc(res);
                },
                false
            );

            // cleanup event listener on load end
            const onLoadEnd = (e: ProgressEvent<FileReader>) => {
                reader.removeEventListener("loadend", onLoadEnd, false);
            };

            reader.addEventListener("loadend", onLoadEnd, false);
            reader.readAsDataURL(file);
        }
    };

    // convert base64 data URL to image file
    const getFileFromImageSrc = async (
        img64: string,
        filename: string
    ): Promise<File> => {
        const res: Response = await fetch(img64);
        const blob: Blob = await res.blob();
        const mime: string = "image/" + fileExtension;
        return new File([blob], filename, { type: mime });
    };

    const handleOnSave = async () => {
        if (typeof cropper !== "undefined") {
            const cropped_data = cropper.getCroppedCanvas().toDataURL();
            const newFilename = `cropped_${imageFile?.name || ""}`;
            const croppedFile = await getFileFromImageSrc(
                cropped_data,
                newFilename
            );
            if (croppedFile) {
                onSubmit(croppedFile);
                resetModal();
            }
        }
    };

    const resetModal = () => {
        // reset state
        setImgSrc(null);
        setFileExtension("");
    };

    return (
        <Modal
            style={modalStyles}
            isOpen={isOpen}
            onRequestClose={() => {
                onRequestClose();
                resetModal();
            }}
            contentLabel="Image Crop"
            ariaHideApp={false}
            // prevents mouse dragging outside of modal accidentally closing it
            shouldCloseOnOverlayClick={false}
        >
            <Text
                style={{ ...styles.centerText, marginBottom: 4 }}
                bold
                large
                center
            >
                <h2>Click and drag to make a selection</h2>
            </Text>
            <View style={styles.buttonsContainer}>
                <Button
                    containerStyle={styles.button}
                    onPress={(e) => {
                        onCancel();
                        resetModal();
                    }}
                    label="Cancel"
                />
                <Button
                    containerStyle={styles.button}
                    style={styles.innerButton}
                    onPress={(e) => {
                        handleOnSave();
                    }}
                    label="Save"
                />
            </View>
            <View>
                <div ref={imageRef} style={styles.imageContainers}>
                    <Cropper
                        initialAspectRatio={1}
                        aspectRatio={1}
                        preview=".img-preview"
                        src={imgSrc || ""}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false}
                        onInitialized={(instance) => {
                            setCropper(instance);
                        }}
                        guides={true}
                    />
                </div>
                <View>
                    <Text
                        style={{
                            ...styles.centerText,
                            marginTop: 10,
                            marginBottom: 10
                        }}
                        large
                        center
                    >
                        Preview
                    </Text>
                </View>
                <View style={styles.imageContainers}>
                    <div style={styles.imagePreview} className="img-preview" />
                </View>
            </View>
        </Modal>
    );
};

const styles = {
    centerText: {
        width: "100%"
    } as CSSProperties,
    buttonsContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginBottom: 7
    } as CSSProperties,
    button: {
        margin: 10
    } as CSSProperties,
    innerButton: {
        width: "67px"
    } as CSSProperties,
    imagePreview: {
        height: "260px",
        width: "260px",
        overflow: "hidden"
    } as CSSProperties,
    imageContainers: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
    } as CSSProperties
};
