import React from "react";
import { Header } from "./Header";

type Props = {
    label: string;
    expanded: boolean;
    onClick?: Function;
    children?: any;
};

const SectionContainer = ({ label, expanded, onClick, children }: Props) => {
    return (
        <div>
            <Header expanded={expanded} onClick={onClick} label={label} />
            <div style={{ display: expanded ? "block" : "none" }}>
                {children}
            </div>
        </div>
    );
};

export default SectionContainer;
