import { connect } from "react-redux";
import { VerticalNav } from "../components/VerticalNavigationMenu";
import { StoreState, Dispatch } from "../redux/types";

const mapStateToProps = (state: StoreState) => {
    return {
        configuration: state.configuration,
    };
};

// create handler than dispatches an action
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {};
};

const Container = connect(mapStateToProps, mapDispatchToProps)(VerticalNav);

export default Container;
