import React, { FC } from "react";
import { connectModal, InjectedProps } from "redux-modal";
import { Toast } from "react-bootstrap";
import { Row, Col, Typography, Button } from "antd";
import styled from "styled-components";

const StyledToast = styled(Toast)`
    position: absolute;
    top: 2%;
    right: 1%;
    background-color: white;
    z-index: 999;
`;

const CacheBustingModal: FC<InjectedProps> = ({ show, handleHide }) => {
    const onClickReload = () => window.location.reload();

    return (
        <StyledToast show={show} onClose={handleHide}>
            <Toast.Header
                style={{ display: "flex", justifyContent: "space-between" }}
            >
                <Typography
                    style={{
                        fontWeight: 700,
                        fontSize: "1.2rem",
                        marginBlockEnd: "auto",
                    }}
                >
                    Snackface Update
                </Typography>
            </Toast.Header>
            <Toast.Body>
                <Row style={{ marginTop: "5px" }}>
                    <Typography
                        style={{ fontWeight: "normal", fontSize: "1rem" }}
                    >
                        A new update is available. Would you like to refresh the
                        page?
                    </Typography>
                </Row>
                <Row
                    gutter={4}
                    style={{ marginRight: "3px", marginTop: "15px" }}
                >
                    <Col span={5}>
                        <Button
                            type="primary"
                            shape="round"
                            onClick={onClickReload}
                        >
                            Refresh
                        </Button>
                    </Col>
                </Row>
            </Toast.Body>
        </StyledToast>
    );
};

export default connectModal({ name: "CacheBustingModal" })(CacheBustingModal);
