import { ITochiFile, ITochiAnimation } from "@snackpass/snackpass-types";
import React, { useEffect, useState, CSSProperties } from "react";
import Table, { CellInfo } from "react-table";
import _ from "lodash";

interface TochiColumn {
    Header: string;
    accessor: string;
    id: string;
    minWidth: number;
    Cell?: (props: CellInfo) => string;
}

type VersionStatus = Record<string, boolean>;
type AnimationData = Record<string, VersionStatus>;
type Props = {
    tochiFiles: ITochiFile[];
    tochiAnims: ITochiAnimation[];
};

export const TochiFilesTable = ({ tochiFiles, tochiAnims }: Props) => {
    const [rigs, setRigs] = useState<ITochiFile[]>([]);
    const [anims, setAnims] = useState<ITochiFile[]>([]);
    const [animData, setAnimData] = useState<AnimationData>({});

    useEffect(() => {
        filterFiles();
        unwrapAnims();
    }, [tochiFiles, tochiAnims]);

    const filterFiles = () => {
        const newRigs = tochiFiles.filter((file: ITochiFile) =>
            file.name.includes("_RG")
        );
        const newAnims = tochiFiles.filter((file: ITochiFile) =>
            file.name.includes("_ANIM")
        );
        setRigs(newRigs);
        setAnims(newAnims);
    };

    const unwrapAnims = () => {
        const animInfo: AnimationData = {};
        for (let anim of tochiAnims) {
            if (anim.events) {
                let versions: VersionStatus = {};
                const keys = Array.from(Object.keys(anim.events));
                const values = Array.from(Object.values(anim.events));
                for (let i = 0; i < keys.length; i += 1) {
                    versions[keys[i]] = values[i] ? true : false;
                }
                animInfo[`${anim.name}_ANIM`] = versions;
            }
        }
        setAnimData(animInfo);
    };

    const createColumns = (rig: boolean) => {
        let columns: TochiColumn[] = [];
        let versions: string[] = [];
        columns.push(nameColumn(rig));
        const filesToSort = rig ? rigs : anims;
        for (const file of filesToSort) {
            let currentVs: string[] = Array.from(Object.keys(file.assets));
            for (let v of currentVs) {
                if (!versions.includes(v)) {
                    versions.push(v);
                }
            }
        }
        versions.sort(sortVersions);
        for (const v of versions) {
            let curCol: TochiColumn = {
                Header: v,
                accessor: `assets/${v}`,
                id: `tochiFileVersion${v}`,
                minWidth: 125,
                Cell: (props: CellInfo) => {
                    const file = props.original;
                    let json: string = "";
                    if (animData[file.name]) {
                        json = animData[file.name][v] ? "🟢" : "🔴";
                    }
                    return file.assets[v]
                        ? `✅ ${!rig ? `(${json})` : ""}`
                        : "❌";
                },
            };
            columns.push(curCol);
        }
        return columns;
    };

    const sortVersions = (a: string, b: string) => {
        return parseInt(b.replace("-", "")) - parseInt(a.replace("-", ""));
    };

    return (
        <div>
            <div>
                <Table
                    style={styles.table}
                    data={rigs}
                    className="snackpass__table"
                    defaultPageSize={20}
                    freezeWhenExpanded={true}
                    columns={createColumns(true)}
                />
            </div>
            <div style={styles.tableSeparation}>
                <Table
                    style={styles.table}
                    data={anims}
                    className="snackpass__table"
                    defaultPageSize={20}
                    freezeWhenExpanded={true}
                    columns={createColumns(false)}
                />
            </div>
        </div>
    );
};

const nameColumn: any = (rig: boolean) => {
    return {
        Header: rig ? "Rigs" : "Animations",
        accessor: "name",
        id: "tochiItemName",
        minWidth: 125,
        Filter: ({ onChange, filter }: any) => {
            return (
                <input
                    placeholder={`Search ${rig ? "rigs" : "animations"}`}
                    value={filter ? filter.value : ""}
                    onChange={e => onChange(e.target.value)}
                    style={{ width: 125 }}
                />
            );
        },
        filterable: true,
        filterMethod: (filter: any, row: any) => {
            let name: string = _.get(row, "tochiItemName", "");
            let value: string | null = _.get(filter, "value", "");
            if (value) {
                return name.toLowerCase().includes(value.toLowerCase());
            }
            return true;
        },
        Cell: (props: CellInfo) =>
            props.original.name.split(rig ? "_RG" : "_ANIM"),
    };
};

const styles = {
    table: {
        textAlign: "center",
    } as CSSProperties,
    tableSeparation: {
        marginTop: 25,
    } as CSSProperties,
};
