import { connect } from "react-redux";
import { replacePurchase } from "../redux/purchases";
import PurchaseRow from "../components/PurchaseRow";
import { StoreState } from "../redux/types";
import { Dispatch } from "redux";
import { IPurchase } from "@snackpass/snackpass-types";
import { show } from "redux-modal";

const mapStateToProps = (state: StoreState) => {
    return {};
};

// create handler than dispatches an action
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        replacePurchase: (purchase: IPurchase) => {
            dispatch(replacePurchase(purchase));
        },
        showModal: (modal: string, props?: Object) => {
            dispatch(show(modal, props));
        },
        dispatch,
    };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(PurchaseRow);

export default Container;
