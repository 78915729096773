import { connect } from "react-redux";
import Admins from "../screens/Admins";
import { setAdmins, setAdmin, removeAdmin } from "../redux/admins";
import { getUser } from "../redux/user";

import * as Helpers from "../utils/Helpers";
import { StoreState } from "../redux/types";
import { Dispatch } from "redux";
import { IStore, IUser } from "@snackpass/snackpass-types";

export interface storesNamesMap {
    [storeId: string]: string;
}

export function formatStoreNames(stores: IStore[]) {
    let ret: storesNamesMap = {};
    stores.forEach((store) => {
        ret[store._id] = store.name;
    });
    return ret;
}

const mapStateToProps = (state: StoreState) => {
    return {
        user: getUser(state),
        storeItems: Helpers.formatArrayForSelect(state.stores),
        admins: state.admins,
        storeNameById: formatStoreNames(state.stores)
    };
};

// create handler that dispatches an action
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setAdmins: (admins: IUser[]) => {
            dispatch(setAdmins(admins));
        },
        setAdmin: (admin: IUser) => {
            dispatch(setAdmin(admin));
        },
        removeAdmin: (adminId: string) => {
            dispatch(removeAdmin(adminId));
        },
        dispatch
    };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(Admins);

export default Container;
