import { prop } from "lodash/fp";
import { Action, compose } from "redux";
import api from "src/api";
import { setPrinters } from "src/redux/printers";

export const refreshPrinters = (params: {
    dispatch: (a: Action) => any;
    selectedStoreId?: string;
    selectedFoodhallId?: string;
    printerSearch?: string;
}) => {
    const { dispatch, selectedStoreId, selectedFoodhallId, printerSearch } = params;
    const processApiResp = compose(
        dispatch,
        setPrinters,
        prop("data.printers")
    );

    if (printerSearch) {
        return api.printers
            .search({ query: printerSearch })
            .then(processApiResp);
    }

    return api.printers
        .list(
            selectedStoreId
                ? { storeId: selectedStoreId }
                : { foodhallId: selectedFoodhallId }
        )
        .then(processApiResp);
};

export const insertValueIntoArray = <T>(arr: T[], index: number, newItem: T): T[] => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index)
]