import { IKiosk } from "@snackpass/snackpass-types";

type ObjectId = string;

// Constants
export const SET_KIOSK_LIST = "SET_KIOSK_LIST";

export const GET_KIOSKS_FOR_STORE = "GET_KIOSKS_FOR_STORE";

// Actions
interface GetKiosksForStore {
    type: "GET_KIOSKS_FOR_STORE";
    selectedStoreId: ObjectId | null;
}

export const getKiosksForStore = (selectedStoreId: ObjectId | null) => ({
    type: GET_KIOSKS_FOR_STORE,
    selectedStoreId
});

interface SetKioskList {
    type: "SET_KIOSK_LIST";
    kioskList: IKiosk[];
}

export const setKioskList = (kioskList: IKiosk[]) => ({
    type: "SET_KIOSK_LIST",
    kioskList
});

export type KiosksAction = GetKiosksForStore | SetKioskList;

// State
export interface KiosksState {
    selectedStoreId: ObjectId | null;
    kioskList: IKiosk[];
}

const initialState: KiosksState = {
    selectedStoreId: null,
    kioskList: []
};

// Reducer
export const kiosks = (
    state: KiosksState = initialState,
    action: KiosksAction
): KiosksState => {
    switch (action.type) {
        case "GET_KIOSKS_FOR_STORE":
            return { ...state, selectedStoreId: action.selectedStoreId };
        case "SET_KIOSK_LIST":
            return { ...state, kioskList: action.kioskList };
        default:
            return state;
    }
};
