import * as Sentry from "@sentry/browser";
import { IUser } from "@snackpass/snackpass-types";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import { createSelector } from "reselect";

import api from "../api";
import { StoreState } from "./types";

// Constants
export const SET_ADMIN = "SET_ADMIN";
export const SET_ADMINS = "SET_ADMINS";
export const ADD_ADMIN = "ADD_ADMIN";
export const SET_PROMOTIONS_FOR_ADMIN = "SET_PROMOTIONS_FOR_ADMIN";
export const REMOVE_ADMIN = "REMOVE_ADMIN";

// Actions
export interface SetAdmins {
    type: "SET_ADMINS";
    admins: IUser[];
}
export function setAdmins(admins: IUser[]) {
    return { type: SET_ADMINS, admins };
}

export interface SetAdmin {
    type: "SET_ADMIN";
    admin: IUser;
}
export function setAdmin(admin: IUser) {
    return { type: SET_ADMIN, admin };
}

export interface AddAdmin {
    type: "ADD_ADMIN";
    admin: IUser;
}
export function addAdmin(admin: IUser) {
    return { type: ADD_ADMIN, admin };
}

export interface RemoveAdmin {
    type: "REMOVE_ADMIN";
    adminId: string;
}
export function removeAdmin(adminId: string) {
    return { type: REMOVE_ADMIN, adminId };
}

export function fetchAdmins() {
    return (dispatch: Dispatch) => {
        return api.admins
            .get()
            .then(response => {
                dispatch(setAdmins(response.data.users));
            })
            .catch(err => {
                Sentry.captureException(err);
                dispatch(setAdmins([]));
            });
    };
}

export type AdminAction = SetAdmins | SetAdmin | AddAdmin | RemoveAdmin;

// Reducer
const initialState: IUser[] = [];

export function admins(state: IUser[] = initialState, action: AdminAction) {
    switch (action.type) {
        case "SET_ADMINS":
            return action.admins;
        case "SET_ADMIN":
            return state.map(admin => {
                if (admin._id === action.admin._id) {
                    return action.admin;
                }
                return admin;
            });
        case "ADD_ADMIN": {
            let currentAdmins: IUser[] = state.slice();
            currentAdmins.push(action.admin);
            return currentAdmins;
        }
        case "REMOVE_ADMIN":
            return state.filter(admin => {
                if (admin._id === action.adminId) {
                    return false;
                }
                return true;
            });

        default:
            return state;
    }
}

// Selectors
export const getAdmins = (state: StoreState) => state.admins;

export const useAdminsForStore = (
    storeId?: string,
    excludeTabletUsers: boolean = true
) => {
    const admins = useSelector(getAdmins);
    return storeId
        ? admins.filter(
              admin =>
                  admin.snackpassPermissions.storeIds.includes(storeId) &&
                  (!excludeTabletUsers || admin.userType !== "TABLET")
          )
        : [];
};

export const getAdminSelectOptions = createSelector(getAdmins, admins => {
    return admins.map(admin => {
        return {
            value: admin._id,
            label: `${admin.name}`,
            adminObj: { ...admin },
        };
    });
});
