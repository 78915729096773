import React, { ReactNode } from "react";
import { IOption, IOptionNumber } from "../../../redux/types";
import { Colors } from "../../../utils";
import MySelect from "./Select";
import MyToggle from "./Toggle";
import { Checkbox } from "react-formik-ui";
import { Field, ErrorMessage, FieldProps, FastField } from "formik";
import { FormValues } from "../types";
import { TextArea } from "semantic-ui-react";

export const styles = {
    inputContainer: {
        backgroundColor: Colors.white,
        borderColor: Colors.lightGray,
        borderWidth: 0,
        borderBottomWidth: 1,
        borderStyle: "solid",
    },
    inputInnerContainer: {
        display: "flex" as "flex",
        flexDirection: "row" as "row",
        alignSelf: "stretch",
        alignItems: "center",
        paddingTop: 15,
        paddingBottom: 15,
    },
    inputLabel: {
        marginBottom: 5,
    },
    inputDescription: {
        fontSize: 12,
        color: Colors.warmGrey,
    },
    errorMessage: {
        color: Colors.red,
        fontSize: 12,
    },
};

interface InputProps {
    required?: boolean;
    label: string;
    name: any;
    description?: string;
    message?: string;
    value?: string;
    placeholder?: string;
    type:
        | "toggle"
        | "select"
        | "text"
        | "text-field"
        | "date"
        | "datetime"
        | "email"
        | "phone"
        | "password"
        | "number"
        | "hours-of-week"
        | "checkbox"
        | "read-only-text"
        | "address";
    children?: ReactNode;
    options?: IOption[] | IOptionNumber[];
    isMulti?: boolean;
    index?: number;
    flexDirection?: any;
    fastfield?: boolean;
    onChange?: any;
    component?: any;
    leftComponent?: any;
    disabled?: boolean;
    dateMin?: Date;
    isDateOnly?: boolean;
}

// Custom wrapper around Formik's Field component switches field type with
// either Formik Field or our own custom input. If you are adding a new custom
// input, be sure to use the component prop for Formik Field.
export const Input = ({
    name,
    label,
    placeholder,
    description,
    type,
    options,
    required,
    children,
    isMulti = false,
    message = "",
    flexDirection = "row" as "row",
    fastfield = false,
    value,
    onChange,
    component,
    leftComponent,
    disabled,
}: InputProps) => {
    // Map type prop to different types of custom inputs.
    let FieldInput;
    if (type === "select") {
        if (options) {
            FieldInput = (
                <Field
                    name={name}
                    component={MySelect}
                    options={options}
                    isMulti={isMulti}
                />
            );
        } else {
            FieldInput = (
                <span style={styles.errorMessage}>
                    Pass options to component
                </span>
            );
        }
    } else if (type === "toggle") {
        FieldInput = (
            <Field
                name={name}
                component={(props: any) => (
                    <MyToggle {...props} onChange={onChange} />
                )}
            />
        );
    } else if (type === "number") {
        FieldInput = <Field name={name} disabled={disabled} />;
    } else if (type === "checkbox") {
        FieldInput = <Checkbox name={name} />;
    } else if (type === "read-only-text") {
        FieldInput = (
            <Field
                name={name}
                render={({ field, form }: FieldProps<FormValues>) => (
                    <p style={{ fontWeight: "bold" }}>{field.value}</p>
                )}
            />
        );
    } else if (type === "text-field") {
        FieldInput = (
            <Field
                render={({ field, form }: FieldProps<FormValues>) => (
                    <TextArea name={name} onChange={field.onChange}>
                        {value}
                    </TextArea>
                )}
            />
        );
    } else {
        if (fastfield) {
            FieldInput = (
                <FastField name={name} type={type}>
                    {children}
                </FastField>
            );
        } else {
            if (onChange) {
                FieldInput = (
                    <Field
                        placeholder={placeholder}
                        name={name}
                        type={type}
                        onChange={onChange}
                    >
                        {children}
                    </Field>
                );
            } else {
                FieldInput = (
                    <Field placeholder={placeholder} name={name} type={type}>
                        {children}
                    </Field>
                );
            }
        }
    }

    // if component, replace the field input with it
    if (component) {
        FieldInput = component;
    }

    // required prop is purely stylistic.
    let Required;
    if (required) {
        Required = <span style={{ color: Colors.red }}>*</span>;
    }

    let Disabled;
    if (disabled) {
        Disabled = (
            <span style={{ color: Colors.gray }}>(this field is disabled)</span>
        );
    }

    return (
        <div style={styles.inputContainer}>
            <div
                style={{
                    ...styles.inputInnerContainer,
                    flexDirection: flexDirection,
                }}
            >
                <div style={{ flex: 1, paddingRight: 20 }}>
                    <p style={styles.inputLabel}>
                        {label} {Required}
                        {leftComponent}
                    </p>
                    <p style={styles.inputDescription}>{description} </p>
                    <p style={styles.inputDescription}>{message} </p>
                    <ErrorMessage
                        name={name}
                        render={msg => (
                            <span style={styles.errorMessage}>{msg}</span>
                        )}
                    />
                </div>
                <div style={{ flex: 1 }}>{FieldInput}</div>
                {Disabled}
            </div>
        </div>
    );
};

export default Input;
