import React, { useState } from "react";
import { Message, Input, Button } from "semantic-ui-react";
import Firebase from "../../firebase";
import styles from "./styles";
import * as Sentry from "@sentry/browser";
import API from "src/api";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loggingIn, setLoggingIn] = useState(false); //controls showing loader on login button
    const [error, setError] = useState("");

    const login = () => {
        if (!email || !password) {
            return setError("Missing fields");
        }
        setLoggingIn(true);
        Firebase.auth()
            .signInWithEmailAndPassword(email, password)
            .then(function (user) {
                // The signed-in user info.
                setLoggingIn(false);
                setError("");
                window.location.href = "/";
            })
            .catch(function (error) {
                Sentry.captureException(error);
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;

                if (errorCode === "auth/user-not-found") {
                    errorMessage = "No user with this email exists.";
                }
                console.log(error);
                setLoggingIn(false);
                setError(errorMessage);
            });
    };

    const enterLogin = (e: any) => {
        if (e.key === "Enter") {
            login();
        }
    };

    const _verifyUser = async (phoneNumber: string) => {
        const code =
            prompt(
                "We just sent you a verification code! Please enter here."
            ) || "";

        try {
            // for now, we do not allow user creation on restaurant dashboard
            const verifyPhoneResponse = await API.auth.verify(
                phoneNumber,
                code,
                false
            );
            const { customAuthToken, user, existingUser } =
                verifyPhoneResponse.data;
            if (!user) {
                alert("No user available");
                return;
            }
            await Firebase.auth().signInWithCustomToken(customAuthToken);
        } catch (err) {
            alert("verification code invalid");
        }
    };

    const _promptPhoneNumber = async () => {
        const phoneNumber = prompt("Enter phone number here 📲") || "";

        try {
            const response = await API.auth.sendVerification(phoneNumber);
            if (response?.status !== 200) {
                alert("invalid phone number");
                return;
            }
            _verifyUser(phoneNumber);
        } catch (err) {
            alert("invalid phone number");
        }
    };

    return (
        <div style={styles.body}>
            <img style={styles.logo} src="logo.png" />
            <div style={styles.container}>
                <h1 style={styles.header}>Login</h1>
                <div style={{ width: 300, margin: "auto" }}>
                    <br />
                    {error && (
                        <Message negative>
                            <p>{error}</p>
                        </Message>
                    )}
                    <Button
                        style={styles.button}
                        fluid
                        onClick={_promptPhoneNumber}
                        label="Login With Phone Number"
                    />
                    <hr />
                    <p>or</p>
                    <br />
                    <Input
                        value={email}
                        onKeyPress={enterLogin}
                        onChange={e => setEmail(e.target.value)}
                        fluid
                        placeholder="Email"
                    />
                    <br />
                    <Input
                        type="password"
                        fluid
                        value={password}
                        onKeyPress={enterLogin}
                        onChange={e => setPassword(e.target.value)}
                        placeholder="Password"
                    />
                    <br />
                    <Button
                        onClick={login}
                        style={styles.button}
                        fluid
                        loading={loggingIn}
                    >
                        Login
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Login;
