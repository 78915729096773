import { Colors } from "../../utils";

export const styles = {
    errorContainer: {
        padding: 10,
        background: "#FFFBFB",
        borderRadius: 4,
        borderColor: "#FFBABA",
        borderStyle: "solid",
        borderWidth: 1,
        marginTop: 20,
    },
    button: {
        color: Colors.white,
        backgroundColor: Colors.blue,
    },
    successContainer: {
        padding: 10,
        background: "#F1FFF7",
        borderRadius: 4,
        borderColor: "#5FC990",
        borderStyle: "solid",
        borderWidth: 1,
        marginTop: 20,
    },

    errorMessage: {
        color: Colors.red,
        fontSize: 12,
    },
};
