import { IStore, IUser } from "@snackpass/snackpass-types";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import API from "../../api";
import AdminForm from "../../containers/AdminForm";
import { setAdmin, useAdminsForStore } from "../../redux/admins";
import { Button, Text, TextInput } from "../../SharedComponents";
import { Colors } from "../../utils/Colors";
import * as Helpers from "../../utils/Helpers";
import { ReduxUtil } from "../../utils/ReduxUtil";
import StoreAdminTable from "./components/StoreAdminTable";
import { getStores } from "src/redux/stores";

type Props = {
    storeObj?: IStore;
};

const styles: { [className: string]: React.CSSProperties } = {
    container: {
        margin: 40
    },
    addButtonStyle: {
        width: 120,
        height: 40
    },
    header: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 40
    },
    tableCell: {
        display: "flex",
        alignItems: "center",
        padding: 10
    },
    lookupForm: {
        marginTop: 40
    },
    emailInputRow: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center"
    },
    payoutPermWarning: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 20
    }
};

type CurrentViewType = "list" | "lookup" | "form" | "view";

export const StoreAdminList = ({ storeObj }: Props) => {
    const dispatch = useDispatch();
    const stores = useSelector(getStores);
    const admins = useAdminsForStore(storeObj?._id);

    const [loadingAdmins, setLoadingAdmins] = useState<boolean>(false);
    const [matchingUser, setMatchingUser] = useState<IUser | null>(null);
    const [lookingUpUser, setLookingUpUser] = useState<boolean>(false);
    const [emailQuery, setEmailQuery] = useState<string>("");
    const [currentView, setCurrentView] = useState<CurrentViewType>("list");
    const [promotingUser, setPromotingUser] = useState<boolean>(false);
    const [adminInEditMode, setAdminInEditMode] = useState<undefined | IUser>();

    useEffect(() => {
        if (!admins.length) {
            setLoadingAdmins(true);
            ReduxUtil.loadAdmins(dispatch).finally(() =>
                setLoadingAdmins(false)
            );
        }
    }, []);

    const noAdminsWithPayoutsPermission =
        !loadingAdmins &&
        !!storeObj?._id &&
        !admins.some((admin) =>
            admin.permissions?.hasPayouts?.includes(storeObj._id)
        );

    const switchToFormView = (admin?: IUser) => {
        setCurrentView("form");
        setAdminInEditMode(admin);
    };

    const switchToLookupView = () => setCurrentView("lookup");

    const exitEditMode = () => {
        setCurrentView("list");
        setPromotingUser(false);
        setAdminInEditMode(undefined);
    };

    const handleEmailInputChange = (email: string) => {
        email = email.trim().toLocaleLowerCase();
        setMatchingUser(null);
        const validEmail = Helpers.validateEmail(email);
        if (validEmail) {
            setEmailQuery(email);
            setLookingUpUser(true);
            findUserForEmail(email);
        } else {
            setEmailQuery(email);
        }
    };

    const handleLookupAction = () => {
        if (matchingUser) {
            const userAlreadyAdmin = isUserAlreadyAdminOfStore();
            setPromotingUser(!userAlreadyAdmin);
            setAdminInEditMode(matchingUser);
            setCurrentView("form");
        } else {
            switchToFormView();
        }
    };

    const findUserForEmail = _.debounce((email: string) => {
        API.users.getMany({ email }).then((response) => {
            const users = response.data.users;
            const matchingUser = users.find(
                (user: IUser) => user.email === email
            );
            setLookingUpUser(false);
            setMatchingUser(matchingUser);
        });
    }, 1000);

    const isUserAlreadyAdminOfStore = () =>
        admins.some((admin) => admin._id === matchingUser!._id);

    const renderListView = () => (
        <div style={styles.container}>
            <div style={styles.header}>
                <div>
                    <h2>Store Admins</h2>
                    <Text>{storeObj?.name}</Text>
                </div>
                <Button
                    label="Add"
                    style={styles.addButtonStyle}
                    onPress={switchToLookupView}
                />
            </div>
            {noAdminsWithPayoutsPermission ? (
                <div style={styles.payoutPermWarning}>
                    <Text bold center color={Colors.red}>
                        No admins for this store have payouts permission. Please
                        add an admin with payouts permission to allow the store
                        to set up payouts!
                    </Text>
                </div>
            ) : null}
            <StoreAdminTable
                storeObj={storeObj}
                admins={admins}
                loadingAdmins={loadingAdmins}
                setAdmin={(user: IUser) => dispatch(setAdmin(user))}
                dispatch={dispatch}
                switchToEditMode={switchToFormView.bind(this)}
            />
        </div>
    );

    const renderFormView = () => {
        let action = "Create admin";
        if (adminInEditMode) {
            if (promotingUser) {
                action = "Promote user to admin";
            } else {
                action = "Edit store admin";
            }
        }

        let email;
        if (!adminInEditMode && !promotingUser) {
            email = emailQuery;
        }

        return (
            <div style={styles.container}>
                <div>
                    <Button label="◀ Back to list" onPress={exitEditMode} />
                    <h2>{action}</h2>
                    <Text>{storeObj?.name}</Text>
                </div>
                <AdminForm
                    admin={adminInEditMode}
                    storeObj={storeObj}
                    promotingUser={promotingUser}
                    email={email}
                    onSuccess={exitEditMode}
                    stores={stores}
                />
            </div>
        );
    };

    const renderLookupView = () => {
        let action = "Proceed with new admin";
        let userAlreadyAdmin = false;
        if (matchingUser) {
            userAlreadyAdmin = isUserAlreadyAdminOfStore();
            action = userAlreadyAdmin
                ? "Proceed with existing store admin of this store"
                : "Proceed with existing user";
        }

        const buttonColor = matchingUser ? Colors.green : Colors.blue;
        const validEmail = Helpers.validateEmail(emailQuery);

        let helpText = null;
        if (matchingUser) {
            const message = userAlreadyAdmin
                ? "This user is already an admin of this store!"
                : "Found existing user!";
            const color = userAlreadyAdmin ? Colors.red : Colors.green;
            helpText = <div style={{ color }}>{message}</div>;
        }

        return (
            <div style={styles.container}>
                <div>
                    <Button label="◀ Back to list" onPress={exitEditMode} />
                </div>
                <div style={styles.lookupForm}>
                    <h2>What's the store admin's email address?</h2>
                    <div style={styles.emailInputRow}>
                        <TextInput
                            placeholder="Email"
                            value={emailQuery}
                            onChangeText={handleEmailInputChange}
                        />
                        <Button
                            style={{ height: 36, backgroundColor: buttonColor }}
                            label={action}
                            onPress={handleLookupAction}
                            loading={lookingUpUser}
                            disabled={lookingUpUser || !validEmail}
                        />
                    </div>
                    {helpText}
                </div>
            </div>
        );
    };

    if (currentView === "form") {
        return renderFormView();
    } else if (currentView === "lookup") {
        return renderLookupView();
    }
    return renderListView();
};
