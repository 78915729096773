import { ITochiMaterial } from "@snackpass/snackpass-types";
import _ from "lodash";
import * as Yup from "yup";
import { TochiColors } from "../../utils/TochiColors";
import { TOCHIMATERIAL_FORM_TEMPLATE, FormValues } from "./types";
const tinycolor = require("tinycolor2");

export const materialTypeOptions = [
    { label: "Texture", value: "TEXTURE" },
    { label: "Gradient Mask", value: "GRADIENTMASK" },
    { label: "RGB Gradient Mask", value: "RGBGRADIENTMASK" },
];

export function initializeFields(material?: ITochiMaterial | null): FormValues {
    return {
        _id: _.get(material, "_id", TOCHIMATERIAL_FORM_TEMPLATE._id),
        type: _.get(material, "type", TOCHIMATERIAL_FORM_TEMPLATE.type),
        map: _.get(material, "map", TOCHIMATERIAL_FORM_TEMPLATE.map) || null,
        gradientMask: _.get(
            material,
            "gradientMask",
            TOCHIMATERIAL_FORM_TEMPLATE.gradientMask
        ),
        colors: _.get(material, "colors", TOCHIMATERIAL_FORM_TEMPLATE.colors),
        rgbMask: _.get(
            material,
            "rgbMask",
            TOCHIMATERIAL_FORM_TEMPLATE.rgbMask
        ),
        rcolors: _.get(
            material,
            "rcolors",
            TOCHIMATERIAL_FORM_TEMPLATE.rcolors
        ),
        gcolors: _.get(
            material,
            "gcolors",
            TOCHIMATERIAL_FORM_TEMPLATE.gcolors
        ),
        bcolors: _.get(
            material,
            "bcolors",
            TOCHIMATERIAL_FORM_TEMPLATE.bcolors
        ),
        decalMask: _.get(
            material,
            "decalMask",
            TOCHIMATERIAL_FORM_TEMPLATE.decalMask
        ),
        decalColor: _.get(
            material,
            "decalColor",
            TOCHIMATERIAL_FORM_TEMPLATE.decalColor
        ),
        alphaMask: _.get(
            material,
            "alphaMask",
            TOCHIMATERIAL_FORM_TEMPLATE.alphaMask
        ),
    };
}

const validateColorArray = {
    name: "Invalid color array",
    message: "Must be only valid colors",
    test: (value: string[]) => {
        let valid = true;
        for (let i = 0; i < value.length; ++i) {
            let c = tinycolor(value[i]);
            if (!c.isValid()) valid = false;
            if (!valid) valid = TochiColors.includes(value[i]);
        }
        return valid;
    },
};
const validateColor = {
    name: "Invalid color",
    message: "Must be only valid colors",
    test: (value: string) => {
        let valid = true;
        let c = tinycolor(value);
        valid = c.isValid();
        if (!valid) valid = TochiColors.includes(value);
        return valid;
    },
};

export const validationSchema = Yup.object().shape({
    _id: Yup.string().required().nullable(),
    type: Yup.string()
        .oneOf(materialTypeOptions.map(matType => matType.value))
        .required()
        .nullable(),
    map: Yup.string().nullable().when("type", {
        is: "TEXTURE",
        then: Yup.string().url().required(),
    }),
    gradientMask: Yup.string()
        .nullable()
        .when("type", {
            is: value =>
                value && ["GRADIENTMASK", "RGBGRADIENTMASK"].includes(value),
            then: Yup.string().url().required(),
        }),
    colors: Yup.array().when("type", {
        is: "GRADIENTMASK",
        then: Yup.array().ensure().min(1).required().test(validateColorArray),
    }),
    rgbMask: Yup.string().nullable().when("type", {
        is: "RGBGRADIENTMASK",
        then: Yup.string().url().required(),
    }),
    rcolors: Yup.array().when("type", {
        is: "RGBGRADIENTMASK",
        then: Yup.array().ensure().min(1).required().test(validateColorArray),
    }),
    gcolors: Yup.array().when("type", {
        is: "RGBGRADIENTMASK",
        then: Yup.array().ensure().min(1).required().test(validateColorArray),
    }),
    bcolors: Yup.array().when("type", {
        is: "RGBGRADIENTMASK",
        then: Yup.array().ensure().min(1).required().test(validateColorArray),
    }),
    decalMask: Yup.string().nullable().url(),
    decalColor: Yup.string()
        .nullable()
        .when("decalMask", {
            is: value => value,
            then: Yup.string().required().test(validateColor),
        }),
    alphaMask: Yup.string().nullable().url(),
});
