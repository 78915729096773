import { connect } from "react-redux";
import Purchases from "../screens/Purchases";
import {
    setPurchases,
    addPurchases,
    replacePurchase,
} from "../redux/purchases";
import { StoreState } from "../redux/types";
import { Dispatch } from "redux";
import { IPurchase } from "@snackpass/snackpass-types";
import { show } from "redux-modal";

const mapStateToProps = (state: StoreState) => {
    return {
        purchases: state.purchases,
        // purchasesLoaded: state.purchasesLoaded
    };
};

// create handler than dispatches an action
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setPurchases: (purchases: IPurchase[]) => {
            dispatch(setPurchases(purchases));
        },
        addPurchases: (purchases: IPurchase[]) => {
            dispatch(addPurchases(purchases));
        },
        replacePurchase: (purchase: IPurchase) => {
            dispatch(replacePurchase(purchase));
        },
        showModal: (modal: string, props?: Object) => {
            dispatch(show(modal, props));
        },
        dispatch,
    };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(Purchases);

export default Container;
