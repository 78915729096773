import * as Yup from "yup";
import { US_STATES, US_TIME_ZONES } from "../../utils/Constants";

export const validationSchema = Yup.object().shape({
    name: Yup.string().min(4, "Too Short!").required("Name is required."),

    slug: Yup.string().min(4, "Too Short!").required("Slug is required."),

    tz: Yup.string()
        .required("Timezone is required.")
        .oneOf(US_TIME_ZONES, "Timezone is required."),

    state: Yup.string()
        .oneOf(Object.keys(US_STATES), "State is required")
        .required("State is required"),

    taxRate: Yup.number()
        .default(0)
        .min(0)
        .max(100)
        .required("Tax Rate is required"),

    geolocation: Yup.object({
        type: Yup.string(),
        coordinates: Yup.array((Yup.number(), Yup.number())),
    }).required("Lat/Long is required"),

    referralCredit: Yup.number()
        .default(0)
        .min(0)
        .max(100) // better safe than sorry
        .required("Referral Credit is required"),

    remitter: Yup.string()
        .oneOf(["store", "snackpass"])
        .required("Tax Remitter is required"),
});
