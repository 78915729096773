import React, { Component } from "react";

import { View, Text, Button, TextInput, Toggle } from "../../SharedComponents";
import Select from "react-select";
import api from "../../api";
import { ReduxUtil } from "../../utils/ReduxUtil";
import { Modal as BootstrapModal } from "react-bootstrap";
import Modal from "react-modal";
import { Styles } from "../../utils";

import { Colors } from "../../utils/Colors";
import { IOption } from "../../redux/types";
import { IUser } from "@snackpass/snackpass-types";
import * as Sentry from "@sentry/browser";
import { isMobile } from "react-device-detect";

const SearchingText = ({ searching }: { searching: string }) => {
    if (!searching) {
        return null;
    }
    return (
        <Text style={{ color: Colors.warmGrey, paddingLeft: 5 }}>
            last search: {searching}
        </Text>
    );
};

type Props = {
    dispatch: Function;
    storeItems: Array<IOption>;
};

type State = {
    isLoading: boolean;
    query: string;
    users: Array<IUser>;
    searching: string;
    showingCreateNewAdminModal: boolean;
    email: string;
    password: string;
    store: IOption | null;
    teamPermissions: boolean;
    menuPermissions: boolean;
    selectedUser: IUser | null;
};

export default class CreateAdmin extends Component<Props, State> {
    state = {
        isLoading: false,
        query: "",
        users: [],
        searching: "",
        showingCreateNewAdminModal: false,
        email: "",
        password: "",
        store: null as IOption | null,
        teamPermissions: false,
        menuPermissions: false,
        selectedUser: null as IUser | null
    };
    componentDidMount() {}

    typingTimer: any;

    handleChange = (query: string) => {
        this.setState({
            query: query
        });
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => {
            this.search(query);
        }, 200);
    };
    loading = (isLoading?: boolean) => {
        // set to the boolean passed otherwise just toggle isLoading
        this.setState({
            isLoading:
                isLoading !== undefined ? isLoading : !this.state.isLoading
        });
    };
    handleEmailChange = (email: string) => {
        this.setState({
            email: email
        });
    };
    handlePasswordChange = (password: string) => {
        this.setState({
            password: password
        });
    };
    handleSelectedUserChange = (user: IUser) => {
        this.setState({
            selectedUser: user
        });
    };
    handleCreateNewAdmin = () => {
        const { dispatch } = this.props;
        const { store, email, teamPermissions, menuPermissions } = this.state;
        let storeIds = [];
        if (store) {
            storeIds.push(store.value);
        }
        let newAdmin: any = {
            firstName: "Snackpass",
            lastName: "Admin",
            email: email,
            snackpassPermissions: {
                storeIds: storeIds,
                adminPassword: this.state.password,
                isAdmin: true,
                team: teamPermissions,
                menu: menuPermissions
            }
        };

        this.loading();
        api.users
            .createAdmin({
                user: newAdmin,
                communicationPreferences: []
            })
            .then((response) => {
                ReduxUtil.loadAdmins(dispatch);
                this.setState({
                    email: "",
                    password: "",
                    store: null,
                    showingCreateNewAdminModal: false,
                    teamPermissions: false,
                    menuPermissions: false
                });
                this.loading();
            })
            .catch((err) => {
                Sentry.captureException(err);
                this.loading();
                console.log(err);
                console.log(err.response);
                window.alert(
                    `error creating admin\n${err.response.data.message}`
                );
            });
    };
    handlePromoteUser = () => {
        const { dispatch } = this.props;
        const { selectedUser } = this.state;
        if (!selectedUser) {
            return;
        }
        let post = {
            user: {
                snackpassPermissions: {
                    isAdmin: true
                }
            },
            communicationPreferences: []
        };
        api.admins
            .update(selectedUser._id, post)
            .then((response) => {
                ReduxUtil.loadAdmins(dispatch);
            })
            .catch((err) => {
                Sentry.captureException(err);
                window.alert(
                    `error creating admin\n${err.response.data.message}`
                );
            });
    };
    search = (query: string) => {
        if (!query) return;
        this.setState({
            searching: query
        });
        api.admins
            .search(query)
            .then((response) => {
                this.setState({
                    users: response.data.users.map((user: IUser) => {
                        return {
                            ...user,
                            value: user._id,
                            label: user.email
                        };
                    })
                });
            })
            .catch((err) => {
                Sentry.captureException(err);
            });
    };
    toggleCreateNewAdminModal = () => {
        if (this.state.showingCreateNewAdminModal) {
            this.setState({
                email: "",
                password: "",
                store: null,
                teamPermissions: false,
                menuPermissions: false
            });
        }
        this.setState((previousState) => {
            return {
                showingCreateNewAdminModal:
                    !previousState.showingCreateNewAdminModal
            };
        });
    };
    handleSelectStoreId = (store: IOption) => {
        this.setState({ store: store });
    };
    toggleTeamPermissions = () => {
        this.setState((previousState) => {
            return { teamPermissions: !previousState.teamPermissions };
        });
    };
    toggleMenuPermissions = () => {
        this.setState((previousState) => {
            return { menuPermissions: !previousState.menuPermissions };
        });
    };
    render() {
        const { searching, store, isLoading } = this.state;
        const { storeItems } = this.props;
        return (
            <View
                style={{
                    alignSelf: "stretch"
                }}
            >
                <Modal
                    isOpen={this.state.showingCreateNewAdminModal}
                    onRequestClose={this.toggleCreateNewAdminModal}
                >
                    <BootstrapModal.Header
                        closeButton
                        onClick={this.toggleCreateNewAdminModal}
                    >
                        <BootstrapModal.Title>
                            <Text large>Create New Admin User 👷‍♀️</Text>
                        </BootstrapModal.Title>
                    </BootstrapModal.Header>
                    <BootstrapModal.Body>
                        <View
                            style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                        >
                            <Text>Email Address</Text>
                            <TextInput
                                style={{ width: 200 }}
                                placeholder={"email"}
                                onChangeText={this.handleEmailChange}
                                value={this.state.email}
                            />
                        </View>
                        <View
                            style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                        >
                            <Text>Password</Text>
                            <TextInput
                                style={{ width: 200 }}
                                placeholder={"password"}
                                onChangeText={this.handlePasswordChange}
                                value={this.state.password}
                            />
                        </View>
                        <View
                            style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                        >
                            <Text>Store</Text>
                            <Text small>
                                <Select
                                    name="type"
                                    isClearable={true}
                                    placeholder="select store"
                                    value={store}
                                    options={storeItems}
                                    className="snackpass__react-select"
                                    style={{
                                        marginTop: 10,
                                        marginBottom: 10
                                    }}
                                    styles={{
                                        singleValue: () => ({
                                            whiteSpace: "normal"
                                        })
                                    }}
                                    onChange={this.handleSelectStoreId}
                                />
                            </Text>
                        </View>
                        <View
                            style={{
                                marginTop: 10,
                                marginBottom: 10,
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                        >
                            <Text>Team Permissions</Text>
                            <Toggle
                                activeLabel="ON"
                                inactiveLabel="OFF"
                                value={this.state.teamPermissions}
                                onToggle={this.toggleTeamPermissions}
                            />
                        </View>
                        <View
                            style={{
                                marginTop: 10,
                                marginBottom: 10,
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                        >
                            <Text>Menu Permissions</Text>
                            <Toggle
                                activeLabel="ON"
                                inactiveLabel="OFF"
                                value={this.state.menuPermissions}
                                onToggle={this.toggleMenuPermissions}
                            />
                        </View>
                    </BootstrapModal.Body>
                    <BootstrapModal.Footer>
                        <Button
                            backgroundColor={Colors.red}
                            label={"Cancel"}
                            onPress={this.toggleCreateNewAdminModal}
                        />
                        <Button
                            backgroundColor={Colors.blue}
                            label={"Create"}
                            loading={isLoading}
                            onPress={this.handleCreateNewAdmin}
                        />
                        <br />
                    </BootstrapModal.Footer>
                </Modal>

                <View
                    style={{
                        ...Styles.row,
                        alignSelf: "stretch",
                        borderColor: Colors.lightGray,
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}
                >
                    <View
                        row
                        style={{
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <Text small bold>
                            Promote User
                        </Text>
                        <View
                            row
                            style={{
                                alignItems: "center",
                                margin: 10
                            }}
                        >
                            <Select
                                name="type"
                                placeholder="select user"
                                options={this.state.users}
                                style={{
                                    marginRight: 10
                                }}
                                className="snackpass__react-select"
                                onInputChange={this.handleChange}
                                onChange={this.handleSelectedUserChange}
                                filterOptions={false}
                            />
                            <Button
                                disabled={!this.state.selectedUser}
                                loading={this.state.isLoading}
                                label="Promote"
                                onPress={() => this.handlePromoteUser()}
                                type="button"
                            />
                            <SearchingText searching={searching} />
                        </View>
                    </View>
                    <View
                        style={{
                            justifyContent: "center",
                            display: isMobile ? "block" : "inline-block"
                        }}
                    >
                        {/* <Button
                            style={{ marginLeft: 5, height: 36 }}
                            label={"Create New Admin User"}
                            onPress={this.toggleCreateNewAdminModal}
                        /> */}
                    </View>
                </View>
            </View>
        );
    }
}
