// TODO: This file should be gradually deprecated in favor of redux thunk. See
// "fetchPromotions()" in redux/promotions for example
import api from "../api";
import * as storeActions from "../redux/stores";
import * as adminsActions from "../redux/admins";
import * as activeUserActions from "../redux/activeUser";
import * as regionActions from "../redux/regions";
import * as storeContactActions from "../redux/storeContacts";
import * as storeTabletActions from "../redux/storeTablets";
import { show } from "redux-modal";

import * as Sentry from "@sentry/browser";

let _dispatch: (action: any) => any = () => {
    throw new Error(
        "src/utils/ReduxUtils.dispatch is not initialized. Please call `ReduxUtils.registerDispatch()`."
    );
};

export const registerDispatch = (fn: (action: any) => any): void => {
    _dispatch = fn;
};

export class ReduxUtil {
    static showModal(name: string, props?: any) {
        _dispatch(show(name, props) as any);
    }

    static loadStoreProductsAndPromotions(
        storeId: string,
        dispatch: Function,
        callback?: Function
    ) {
        api.promotions.get({ storeId: storeId }).then((response) => {
            let promotions = response.data.promotions;
            dispatch(storeActions.setPromotionsForStore(promotions, storeId));
        });
        api.stores.getProducts(storeId).then((response) => {
            let products = response.data.products;
            dispatch(storeActions.setProductsForStore(products, storeId));
            if (callback) callback();
        });
    }
    static async loadStorePromotions(
        storeId: string,
        dispatch: Function,
        callback?: Function
    ) {
        await api.promotions.get({ storeId: storeId }).then((response) => {
            let promotions = response.data.promotions;
            dispatch(storeActions.setPromotionsForStore(promotions, storeId));
            if (callback) callback();
        });
    }
    static loadRegions(dispatch: Function, callback?: Function) {
        api.regions.get({ populateNumStores: true }).then((response) => {
            let regions = response.data.regions;
            dispatch(regionActions.setRegions(regions));
            if (callback) callback(null, response);
        });
    }

    static loadStores(dispatch: Function, callback?: Function) {
        api.stores
            .get()
            .then((response) => {
                dispatch(storeActions.setStores(response.data.stores));
            })
            .catch((err) => {
                Sentry.captureException(err);
                if (callback) callback(err);
            });
    }
    static async loadStoreContacts(dispatch: Function) {
        let response: any;
        try {
            response = await api.storeContacts.getMany();
        } catch (err) {
            Sentry.captureException(err);
            console.log(err.response);
        }

        dispatch(storeContactActions.setStoreContacts(response.data.contacts));
    }
    static async loadStoreTablets(dispatch: Function) {
        let response: any;
        try {
            response = await api.storeTablets.get();
        } catch (err) {
            Sentry.captureException(err);
            console.log(err.response);
        }

        dispatch(
            storeTabletActions.setStoreTablets(response.data.storeTablets)
        );
    }
    static loadAdmins(dispatch: Function, callback?: Function) {
        return api.admins
            .get()
            .then((response) => {
                dispatch(adminsActions.setAdmins(response.data.users));
            })
            .catch((err) => {
                Sentry.captureException(err);
                console.log(err.response);
            });
    }
    static loadPurchases(dispatch: Function, callback?: Function) {}
    static loadPunchcards(
        userId: string,
        dispatch: Function,
        callback?: Function
    ) {
        api.users.getPunchcards(userId).then((response) => {
            dispatch(activeUserActions.setPunchcards(response.data.punchcards));
            if (callback) callback(null, response);
        });
    }
    static updatePunchcard(
        userId: string,
        punchcardId: string,
        updates: Object,
        dispatch: Function,
        callback?: Function
    ) {
        api.punchcards.update(punchcardId, updates).then(() => {
            this.loadPunchcards(userId, dispatch);
        });
    }
    static updateReward(
        userId: string,
        rewardId: string,
        updates: Object,
        dispatch: Function
    ) {
        api.rewards.update(rewardId, updates).then(() => {
            this.loadPunchcards(userId, dispatch);
        });
    }
}
