import { ITochiItem } from "@snackpass/snackpass-types";
import React from "react";
import Modal from "react-responsive-modal";
import { connectModal, InjectedProps } from "redux-modal";
import { Colors } from "../../../../utils";
import TochiItemsForm from "./TochiItemsForm";

const styles = {
    modal: {
        overlay: {
            background: "rgba(0,0,0,0.23)",
        },
        modal: {
            background: Colors.white,
            padding: 20,
            boxShadow: "0 2px 40px 0 rgba(0, 0, 0, 0.3)",
            borderRadius: 5,
            marginBottom: 100,
            width: "85%",
        },
    },
};

export interface TochiItemsFormModal extends InjectedProps {
    item?: any;
    editMode?: boolean;
    onSuccess: (item: ITochiItem) => void;
}

const TochiItemsModal = ({
    show,
    handleHide,
    item,
    editMode,
}: TochiItemsFormModal) => {
    return (
        <Modal onClose={handleHide} open={show} styles={styles.modal}>
            <TochiItemsForm
                editMode={editMode}
                item={item}
                onSuccess={handleHide}
            />
        </Modal>
    );
};

export default connectModal({ name: "TochiItemsModal" })(TochiItemsModal);
