/**
 * This file declares all routes and which components
 * are displayed when a certain route is navigated to
 *
 * Also includes the top navigation bar which persists
 * in all of the pages.
 */
import React, { Component } from "react";
import { Button } from "../../SharedComponents";

import { Link } from "react-router-dom";

const nameMap: any = {
    stores: "Stores",
    accounting: "Accounting",
    admins: "Admins",
    users: "Users",
    purchases: "Purchases",
    metrics: "Metrics",
    promotions: "Promotions",
    giftcards: "Gift Cards",
    sales: "Sales",
    restaurant_airtable: "Restaurants",
    "store-tablets": "Store Tablets"
};
const mobileLinks = [
    "stores",
    "admins",
    "users",
    "purchases",
    "promotions",
    "giftcards",
    "store-tablets"
];
class MobileNavigationMenu extends Component {
    componentDidMount() {
        let activeItem = window.location.pathname.replace(/\//g, "");
        if (!nameMap[activeItem]) {
            document.title = `Snackface 🙇‍♀️`;
        } else {
            document.title = `${nameMap[activeItem]} - Snackface 🙇‍♀️`;
        }
    }
    handleLinkPress = (val: string) => {
        document.title = `${nameMap[val]} - Snackface 🙇‍♀️`;
    };
    render() {
        return (
            <div
                style={{
                    margin: 10
                }}
            >
                {mobileLinks.map((val, i) => {
                    return (
                        <Link
                            style={{ display: "inline-block" }}
                            to={`/${val}`}
                            key={i}
                        >
                            <Button
                                onPress={this.handleLinkPress.bind(this, val)}
                                label={nameMap[val]}
                            />
                        </Link>
                    );
                })}
            </div>
        );
    }
}
export default MobileNavigationMenu;
