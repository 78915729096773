import React, { Component, ReactNode } from "react";

import { Col } from "./Col";
import { Row } from "./Row";
import { Text } from "./Text";
import { TextInput } from "./TextInput";
import { TouchableHighlight } from "./TouchableHighlight";
import { View } from "./View";
import CurrencyInput from "react-currency-input";

import { Colors } from "../utils/Colors";

interface IRequired {
    required?: boolean;
}

const Required = ({ required }: IRequired) => {
    if (!required) {
        return null;
    }
    return (
        <Text small color={Colors.red}>
            *
        </Text>
    );
};

export interface IRowInput {
    label?: string;
    onChangeText: (value: any) => void;
    value?: any;
    placeholder?: string;
    inputWidth?: number;
    description?: string;
    required?: boolean;
    text?: boolean;
    currency?: boolean;
    percent?: boolean;
    integer?: boolean;
    number?: boolean;
    currencyPrecision?: number;
    type?: string;
}

export const RowInput = ({
    label,
    onChangeText,
    value,
    placeholder,
    inputWidth,
    description,
    required,
    text,
    percent,
    integer,
    currency = false,
    currencyPrecision = 2,
    type = "text",
}: IRowInput) => (
    <Row style={{ marginRight: 10 }}>
        <Col flex={1}>
            <Text small>
                {label}
                <Required required={required} />
            </Text>
            <Text small style={{ fontSize: 12, color: Colors.warmGrey }}>
                {description}
            </Text>
        </Col>
        {!currency ? (
            <TextInput
                placeholder={placeholder || "Promotion Name"}
                value={value}
                style={{ width: inputWidth }}
                onChangeText={onChangeText}
                type={type}
            />
        ) : (
            <CurrencyInput
                value={value}
                precision={currencyPrecision}
                onChangeEvent={(
                    event: any,
                    maskedvalue: string,
                    floatvalue: string
                ) => onChangeText(floatvalue)}
            />
        )}
    </Row>
);

export interface IFormRow {
    label: string;
    value: string;
    required: boolean;
}

export const FormRow = ({ label, value, required }: IFormRow) => (
    <Row>
        <Col flex={1}>
            <Text>
                <span>{label}</span> <Required required={required} />
            </Text>
        </Col>
        <Col>
            <Text>{value}</Text>
        </Col>
    </Row>
);

type State = {
    isCollapsed: boolean;
};

export interface IRowCustomInput {
    label?: string;
    description?: string;
    required?: boolean;
    children?: ReactNode;
    placeholder?: string;
}

export const RowCustomInput = ({
    label,
    description,
    required,
    children,
    placeholder,
}: IRowCustomInput) => (
    <Row style={{ padding: 10 }}>
        <Col flex={1}>
            <Text small>
                {label} <Required required={required} />
            </Text>
            <Text small style={{ fontSize: 12, color: Colors.warmGrey }}>
                {description}
            </Text>
        </Col>
        {children}
    </Row>
);

export interface ISectionHeader {
    label?: string;
}

export const SectionHeader = ({ label }: ISectionHeader) => (
    <div style={{ marginTop: 0 }}>
        <div style={{ padding: 20 }}>
            <h3 style={{ textAlign: "center" }}>{label}</h3>
        </div>
        <hr style={{ marginTop: 0 }} />
    </div>
);

export interface ISection {
    children?: ReactNode;
}

export const Section = ({ children }: ISection) => (
    <div style={{}}>{children}</div>
);

export interface ICollapsableSection {
    state?: State;
    label?: string;
    children?: ReactNode;
}

export class CollapsableSection extends Component<ICollapsableSection> {
    state = {
        isCollapsed: true,
    };
    toggleCollapse = () => {
        const { isCollapsed } = this.state;
        this.setState({ isCollapsed: !isCollapsed });
    };
    render() {
        const { label } = this.props;
        const { isCollapsed } = this.state;
        return (
            <div>
                <TouchableHighlight
                    onPress={this.toggleCollapse}
                    style={{
                        padding: 0,
                        marginTop: 5,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            paddingLeft: 10,
                            paddingRight: 5,
                            paddingTop: 5,
                            paddingBottom: 5,
                            backgroundColor: Colors.blue2,
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Text style={{ color: Colors.white }}>{label}</Text>
                        <View
                            style={{
                                alignItems: "center",
                                justifyContent: "center",
                                width: 25,
                                height: 25,
                            }}
                        >
                            <Text xxlarge style={{ color: Colors.white }}>
                                {isCollapsed ? "+" : "-"}
                            </Text>
                        </View>
                    </div>
                </TouchableHighlight>
                {isCollapsed ? null : (
                    <div style={{ padding: 5 }}>{this.props.children}</div>
                )}
            </div>
        );
    }
}
