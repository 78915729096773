import { connect } from "react-redux";
import Promotions from "../screens/Promotions";
import { fetchPromotions, updatePromotion } from "../redux/promotions";
import {
    fetchStores,
    getStoreSelectOptions,
    fetchStoreProducts,
    getActiveStore,
} from "../redux/stores";
import { Action } from "redux";
import { StoreState } from "../redux/types";
import { ThunkDispatch } from "redux-thunk";
import { show } from "redux-modal";
import { setActiveStore } from "../redux/activeStore";

const mapStateToProps = (state: StoreState) => {
    return {
        promotions: state.promotions,
        storeOptions: getStoreSelectOptions(state),
        activeStore: getActiveStore(state),
    };
};

// create handler than dispatches an action
const mapDispatchToProps = (
    dispatch: ThunkDispatch<StoreState, undefined, Action>
) => {
    return {
        fetchStores: () => {
            return dispatch(fetchStores());
        },
        fetchStoreData: async (storeId: string, promoQuery?: Object) => {
            dispatch(setActiveStore(storeId));
            await dispatch(fetchPromotions(promoQuery));
            await dispatch(fetchStoreProducts(storeId));
        },
        updatePromotion: async (promotionId: string, fields: any) => {
            await dispatch(updatePromotion(promotionId, fields));
        },
        showModal: (modal: string, props?: Object) => {
            dispatch(show(modal, props));
        },
    };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(Promotions);

export default Container;
