// Color palette
export const Colors = {
    green2: "#5fc990",
    blue: "#2fc3ff",
    pastelPurple: "#b997ff",
    apricot: "#ffbe5f",
    warmGrey: "#929292",
    white: "#ffffff",
    blue2: "#5aaff7",
    offWhite: "#f7f7f7",
    paleSalmon: "#ffb1b0",
    gray: "#929292",
    lightGray: "#e3e3e3",
    text: "#4e4e4e",
    salmon: "#ff7676",
    purple: "#bc76ff",
    darkishPink: "#e13e89",
    pink: "#f75aa2",
    brownishGrey: "#646464",
    green: "#5FC990",
    lightRed: "#fef2f2",
    red: "#F05454",
    yellow: "#FBBF24",
    lightYellow: "#FFFBEB",
    transparent: "rgba(0,0,0,0)",
};
