import React from "react";

import { Text } from "../../../SharedComponents";

type Props = {
    visible: boolean;
    text: string;
    backgroundColor: string;
    textColor: string;
};

export const Label = ({ visible, text, backgroundColor, textColor }: Props) => {
    if (!visible) {
        return null;
    }
    return (
        <div
            style={{
                background: backgroundColor,
                padding: 5,
                borderRadius: 5,
                display: "inline-block",
                margin: 5,
            }}
        >
            <Text style={{ fontWeight: 700 }} xsmall color={textColor}>
                {text.toUpperCase()}
            </Text>
        </div>
    );
};
