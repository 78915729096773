import React, { CSSProperties } from "react";
import { IPurchase, IProduct, IStore, IUser } from "@snackpass/snackpass-types";

import { connectModal } from "redux-modal";

import Modal from "react-modal";
import API from "../api";
import { Divider, TextArea } from "semantic-ui-react";
import _ from "lodash";
import { Colors } from "../utils";
import { Button } from "../SharedComponents";
import swal from "sweetalert2";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "80%",
        maxWidth: 500,
        height: "90%",
        overflowY: "scroll",
    } as CSSProperties,
    overlay: {
        zIndex: 1000,
    },
};

export interface IDisputeEvidenceModal {
    show: boolean;
    handleHide: () => void;
    handleDestroy: () => void;
    purchase?: IPurchase;
    onSuccess: () => void;
}
type DisputeData = {
    products: IProduct[];
    store: IStore | null;
    user: IUser | null;
    isStudent: boolean;
    numberOfPurchases: number;
};
type State = {
    disputeData: DisputeData;
    message: string;
    isSubmittingEvidence: boolean;
};
class DisputeEvidenceModal extends React.Component<
    IDisputeEvidenceModal,
    State
> {
    state: State = {
        disputeData: {
            products: [],
            store: null,
            user: null,
            isStudent: false,
            numberOfPurchases: 0,
        },
        message: "",
        isSubmittingEvidence: false,
    };
    componentDidMount = () => {
        this.fetchDisputeEvidence();
    };
    fetchDisputeEvidence = async () => {
        if (!this.props.purchase) {
            window.alert("No purchase specified");
        }

        let res = await API.purchases.disputeEvidence(
            (this.props.purchase as IPurchase)._id
        );
        let disputeData: DisputeData = res.data.evidence;
        this.setState({ disputeData });
    };
    submitDispute = async () => {
        if (!this.props.purchase) {
            window.alert("No purchase specified");
        }

        let { message } = this.state;
        this.setState({ isSubmittingEvidence: true });
        try {
            let res = await API.purchases.submitDispute(
                (this.props.purchase as IPurchase)._id,
                {
                    additionalInfo: `${this._additionalEvidence()} ${this._studentEvidence()} ${message}`,
                    productDescription: this._productEvidence(),
                }
            );
            let dispute = res.data.dispute;
            let chargeId = dispute.charge;
            let stripeLink = `https://dashboard.stripe.com/payments/${chargeId}`;
            swal.fire({
                title: "Success!",
                type: "success",
                html: `We submitted this evidence to stripe. Next step is to go to <a href="${stripeLink}">${stripeLink}</a> and add any other information, and then submit to the banking network. Almost there!`,
            });
        } catch (err) {
            let message = _.get(err, "response.data.message", "");
            swal.fire({
                title: "Failed to submit evidence",
                html: message || "Unknown error",
                type: "error",
            });
        }
        this.setState({ isSubmittingEvidence: false });
    };
    _numberOfProducts = () => {
        let { disputeData } = this.state;
        return disputeData.products.length || 1;
    };
    _productNames = () => {
        let { disputeData } = this.state;
        return disputeData.products
            .map((product: IProduct) => product.name)
            .join(", ");
    };
    _restaurantName = () => {
        let { disputeData } = this.state;
        return _.get(disputeData.store, "name", "");
    };
    _userName = () => {
        let { disputeData } = this.state;
        return _.get(disputeData.user, "name", "");
    };
    _userEmail = () => {
        let { disputeData } = this.state;
        return _.get(disputeData.user, "email");
    };
    _studentEvidence = (): string => {
        let { disputeData } = this.state;
        if (!disputeData.isStudent) return "";

        return ` Moreover, this user confirmed with their student email ${this._userEmail()}.
        This is an email provided by their university, which in many
        cases requires its own 2 factor authentication. The user also
        gets an email of the receipt on every purchase. The customer is
        disputing these orders as "unrecognized", yet it is very clear
        that our app is called "Snackpass" and our name on the credit
        card statement appears "Snackpass".`;
    };
    _additionalEvidence = (): string => {
        return `${this._userName()} ordered on Snackpass, went to
                the restaurant and picked up his/her food, and received reward
                points on their purchase, just like the tens of thousands of
                other customers on our platform. Our restaurants mark all orders
                as confirmed when the customer picks up the order in person,
                otherwise they are refunded. In order to purchase on Snackpass,
                you must sign up using 2 factor authentication. First, the user
                enters their personal email. It is required that the user
                confirm their email using a confirm email link sent to that
                email's inbox. Moreover, the user must enter their personal
                phone number and confirm it with a verification code sent to
                that number via SMS. Your account can then only be accessed on
                the phone you signed up with. No one can steal your credit card
                and purchase using your login info. They would have to
                physically steal your phone and use it. It is highly unlikely
                that this is the case.`;
    };
    _productEvidence = (): string => {
        return `Snackpass is an app to order ahead food at people's favorite restaurants. The customer ordered ${this._numberOfProducts()} items (${this._productNames()}) at a restaurant called ${this._restaurantName()} using the Snackpass app.`;
    };
    render() {
        let { message, isSubmittingEvidence } = this.state;
        let { purchase, show, handleHide } = this.props;
        if (!purchase) {
            return null;
        }
        return (
            <Modal
                isOpen={show}
                onRequestClose={() => {
                    handleHide();
                }}
                style={customStyles}
                contentLabel="Promotion Blurb"
            >
                <h3>Fight dispute ⚔️</h3>
                <p>
                    We generated the below evidence. Feel free to add a message
                    that will get added to the evidence and submitted to the
                    credit card network.
                </p>
                <Divider />
                <TextArea
                    style={{
                        width: "100%",
                        maxWidth: 500,
                        margin: "30px auto",
                    }}
                    placeholder="Message"
                    value={this.state.message}
                    onInput={(e, data) =>
                        this.setState({ message: data.value as string })
                    }
                />
                <div
                    style={{
                        padding: 20,
                        backgroundColor: "rgb(245, 245, 245)",
                        borderRadius: 10,
                    }}
                >
                    <p>{this._productEvidence()}</p>
                    <p>
                        {this._additionalEvidence()} {this._studentEvidence()}
                    </p>
                    <p>{message}</p>
                </div>
                <Button
                    onPress={this.submitDispute}
                    loading={isSubmittingEvidence}
                    style={{
                        backgroundColor: Colors.green,
                        color: Colors.white,
                    }}
                    label="submit evidence"
                />
                <button
                    onClick={() => {
                        handleHide();
                    }}
                >
                    close
                </button>
            </Modal>
        );
    }
}

export default connectModal({ name: "DisputeEvidenceModal" })(
    DisputeEvidenceModal
);
