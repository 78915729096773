import React from "react";
import {
    View,
    Text,
    TouchableHighlight,
    Button,
    EditBlock
} from "../../SharedComponents";
import { Styles, Colors } from "../../utils";
import api from "../../api";
import { connect } from "react-redux";
import { replacePurchase } from "../../redux/purchases";
import { Dispatch } from "../../redux/types";
import * as Sentry from "@sentry/browser";
import { IPurchase, IOrderItem } from "@snackpass/snackpass-types";
import Loader from "react-loader-spinner";
import swal from "sweetalert2";
import _ from "lodash";

type Props = {
    purchase: IPurchase;
    replacePurchase: (purchase: IPurchase) => void;
    dispatch: Dispatch;
    onSubmit: (refundedAmount: number) => void;
    closeModal: () => void;
};

type State = {
    productsToRefund: Record<number, IOrderItem>;
    amountToRefund: number;
    isSubmittingRefund: boolean;
    showSubmitButton: boolean;
};

class PartialRefund extends React.Component<Props, State> {
    state: State = {
        productsToRefund: {},
        amountToRefund: 0,
        isSubmittingRefund: false,
        showSubmitButton: true
    };

    toggleShowSubmitButton = (value: boolean) => {
        this.setState({ showSubmitButton: value });
    };

    addItem = (item: IOrderItem, index: number) => {
        const { productsToRefund, amountToRefund } = this.state;

        this.setState({
            productsToRefund: { ...productsToRefund, [index]: item },
            amountToRefund: amountToRefund + item.totalPriceAfterDiscount
        });
    };

    removeItem = (item: IOrderItem, index: number) => {
        const { productsToRefund, amountToRefund } = this.state;

        this.setState({
            productsToRefund: _.omit(productsToRefund, index),
            amountToRefund: amountToRefund - item.totalPriceAfterDiscount
        });
    };

    submitRefund = async () => {
        const { purchase, replacePurchase, onSubmit, closeModal } = this.props;
        let { amountToRefund, productsToRefund } = this.state;

        this.setState({ isSubmittingRefund: true });

        const productIds = Object.values(productsToRefund)
            .filter((p) => !!p.product)
            .map((p) => p.product!._id);

        if (
            amountToRefund <= 0 ||
            amountToRefund > (purchase.amountPaidByCustomer || 0)
        ) {
            const errorMessage =
                amountToRefund <= 0
                    ? "Error! Cannot refund amount less than or equal to 0!"
                    : "Error! Cannot refund more than amount paid by customer!";

            await swal.fire({
                title: errorMessage,
                type: "error",
                confirmButtonColor: Colors.red,
                confirmButtonText: "Ok"
            });

            this.setState({ isSubmittingRefund: false });
            return;
        }

        await api.purchases
            .refund(purchase._id, {
                reason: "other", // TODO: Support submitting reason for partial refund
                partialRefund: amountToRefund,
                refundedProductIds: productIds
            })
            .then(({ data }) => {
                replacePurchase(data.purchase);
                onSubmit(data.purchase.refundedAmount);
            })
            .catch(async (err) => {
                Sentry.captureException(err);

                // Grab API error if possible
                const errorMessage =
                    err.response?.data?.message || err.toString();

                await swal.fire({
                    title: errorMessage,
                    type: "error",
                    confirmButtonColor: Colors.red,
                    confirmButtonText: "Ok"
                });

                this.setState({ isSubmittingRefund: false });
            });
    };

    _submitButton = () =>
        this.state.isSubmittingRefund ? (
            <div
                style={{
                    padding: 20,
                    justifyContent: "center",
                    alignSelf: "center"
                }}
            >
                <Loader type="Oval" color="#00BFFF" height={70} width={70} />
            </div>
        ) : (
            <Button
                style={{ width: 300 }}
                label="Confirm"
                backgroundColor={Colors.red}
                onPress={this.submitRefund}
            />
        );

    render = () => {
        const { purchase } = this.props;
        const { productsToRefund, amountToRefund, showSubmitButton } =
            this.state;

        return purchase.refund ? null : (
            <View
                style={{
                    flex: 1,
                    alignSelf: "stretch"
                }}
            >
                <View
                    style={{
                        paddingTop: 30,
                        paddingBottom: 150,
                        alignSelf: "stretch"
                    }}
                >
                    <View
                        style={{
                            ...Styles.flexCol,
                            alignSelf: "stretch",
                            alignItems: "flex-start",
                            padding: 10
                        }}
                    >
                        <Text>Choose Products to refund: </Text>

                        <View
                            style={{
                                padding: 30
                            }}
                        >
                            {purchase.items.map((item, index) => (
                                <TouchableHighlight
                                    key={index}
                                    onPress={() => {
                                        if (productsToRefund[index]) {
                                            this.removeItem(item, index);
                                        } else {
                                            this.addItem(item, index);
                                        }
                                    }}
                                >
                                    <View
                                        style={{
                                            padding: 10,
                                            borderRadius: 5,
                                            backgroundColor: productsToRefund[
                                                index
                                            ]
                                                ? Colors.red
                                                : Colors.white
                                        }}
                                    >
                                        <Text
                                            color={
                                                productsToRefund[index]
                                                    ? Colors.white
                                                    : Colors.text
                                            }
                                        >
                                            {`${
                                                item.product?.name || ""
                                            } for $${item.totalPriceAfterDiscount.toFixed(
                                                2
                                            )}`}
                                        </Text>
                                    </View>
                                </TouchableHighlight>
                            ))}
                        </View>

                        <View style={{ margin: 10 }}>
                            <View style={{ flex: 2 }}>
                                <Text>Partial Refund Amount:</Text>
                                <Text
                                    style={{ fontSize: 11, marginBottom: 10 }}
                                >
                                    (Note: hit "return" to save your custom
                                    amount!)
                                </Text>
                            </View>
                            <EditBlock
                                small
                                number
                                dollar
                                value={amountToRefund}
                                toggleShowSubmitButton={
                                    this.toggleShowSubmitButton
                                }
                                onSubmit={(value: string) =>
                                    this.setState({
                                        amountToRefund: value
                                            ? parseFloat(value)
                                            : 0
                                    })
                                }
                            />
                        </View>
                        <Text />
                        {amountToRefund !== 0 && showSubmitButton
                            ? this._submitButton()
                            : null}
                    </View>
                </View>
            </View>
        );
    };
}

const mapStateToProps = (state: any) => {
    return {};
};

// create handler than dispatches an action
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        replacePurchase: (purchase: IPurchase) => {
            dispatch(replacePurchase(purchase));
        },
        dispatch
    };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(PartialRefund);

export default Container;
