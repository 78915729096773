import {
    IHoursSchema,
    IRegion,
    SnackpassTimezoneEnum
} from "@snackpass/snackpass-types";
import { Form, FormikProps } from "formik";
import _ from "lodash";
import { CSSProperties, useEffect } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import { Divider } from "semantic-ui-react";
import stringifyObject from "stringify-object";
import TabletSerial from "../../containers/TabletSerial";

import EmojiPickerModal from "../../modals/EmojiPickerModal";

import { fetchAdmins } from "../../redux/admins";
import { getRegionOptionsWitTaxRate, getRegions } from "../../redux/regions";

import { Button } from "../../SharedComponents";

import { Colors } from "../../utils/Colors";
import { capitalize } from "../../utils/Helpers";
import { ReduxUtil } from "../../utils/ReduxUtil";

import { ColorPicker } from "../ColorPicker";
import { HoursPicker } from "../HoursPicker";

import { ImageInput, Input, SectionContainer } from "./components";

import AddressComponents from "./components/AddressComponents";
import DeliveryRanges from "./components/DeliveryRanges";
import { EnterpriseInput } from "./components/Enterprise";
import ScheduleAhead from "./components/ScheduleAhead";
import { validationSchema, warningSchema } from "./config";
import { FormValues } from "./types";
import {
    getWarnings,
    validYupSchema,
    Warnings,
    WarningsSummary
} from "./validation";

const pickupTimeSpecific = "specific";
const pickupTimeRange = "range";
const pickupTimeTypes = [
    {
        label: "Specific time",
        value: pickupTimeSpecific
    },
    {
        label: "Time range",
        value: pickupTimeRange
    }
];

const INTEGRATIONS = {
    POS: [
        {
            name: "checkmate",
            extraField: "locationId",
            extraFieldLabel: "Authorization Key"
        },
        {
            name: "otter",
            extraField: "storeId",
            extraFieldLabel: "Authorization Key"
        },
        {
            name: "chowly",
            extraField: "apiKey",
            extraFieldLabel: "Authorization Key"
        }
    ],
    Delivery: [{ name: "postmates", label: "Uber" }, { name: "doordash" }],
    Printer: [
        {
            name: "smarpus",
            extraField: "externalId",
            extraFieldLabel: "External ID"
        },
        { name: "onzway" }
    ]
};

interface AdditionalProps {
    storeObj?: any;
    editMode?: boolean; //maybe don't need create mode too
}

const GraphicSelectHours = ({
    label,
    name,
    hours,
    setFieldValue,
    ...props
}: any) => (
    <HoursPicker
        label={label}
        hours={hours}
        onChange={(newHours: IHoursSchema) => {
            setFieldValue(name, newHours);
        }}
    />
);

const EmailInput = ({ name, label, emailInput, setFieldValue }: any) => (
    <div>
        {emailInput.split(",").map((item: string, index: number) => (
            <Input
                key={index}
                name={name}
                index={index}
                label={label}
                type="email"
                value={item}
                required
            />
        ))}
        <Button
            onPress={() => {
                let emails = emailInput ? emailInput.split(",") : [];
                emails.splice(emails.length, 0, "");
                setFieldValue("email", emails.join(","));
            }}
            type="button"
            label="Add New Email"
        />
    </div>
);

const hasMultipleIntegrations = (integrations: any) => {
    if (integrations.checkmate?.enabled && integrations.otter?.enabled) {
        return true;
    } else if (
        integrations.checkmate?.enabled &&
        integrations.chowly?.enabled
    ) {
        return true;
    } else if (integrations.chowly?.enabled && integrations.otter?.enabled)
        return true;
    return false;
};

function getAutoAcceptDefaultPickupMessage(values: FormValues): string | null {
    if (values.pickupTimeType === "range") {
        const minTime = values.defaultPickupMinTime || 0;
        const maxTime = values.defaultPickupMaxTime || 0;

        // If the min and max times are the same or the min is larger than max or either is not
        // positive, then return null.
        if (minTime >= maxTime || minTime <= 0 || maxTime <= 0) {
            return null;
        }

        return `Your order from ${
            values.name || "<store>"
        } has been placed. Ready in ${minTime}-${maxTime} minutes ⏱`;
    }

    // Despite FormValues setting a number type for defaultPickupValue, sometimes defaultPickupTime
    // is a string here. For now, we can just convert as needed.
    const pickupTime =
        typeof values.defaultPickupTime === "string"
            ? Number.parseInt(values.defaultPickupTime)
            : values.defaultPickupTime;
    if (pickupTime === 0) {
        return `Your order from ${
            values.name || "<store>"
        } has been placed and is ready for pickup now! 🎉`;
    }

    return `Your order from ${
        values.name ? values.name : "<store>"
    } has been placed. Ready in ${pickupTime} minutes ⏱`;
}

export const FormBody = (props: AdditionalProps & FormikProps<FormValues>) => {
    const {
        values,
        errors,
        editMode,
        isSubmitting,
        setFieldValue,
        initialValues
    } = props;
    const dispatch = useDispatch();
    // The form action is disabled if there are any validation errors or the tablet serial number
    // is taken by another store.
    const tabletSerialIsTaken = !!props.values.storeWithSerial;
    const hasValidationErrors = Object.keys(errors || {}).length > 0;
    const formActionDisabled =
        hasValidationErrors ||
        tabletSerialIsTaken ||
        hasMultipleIntegrations(values.integrations);
    const storeAutoAcceptsOrders = values.serverAutoAcceptsOrders;

    const warnings = getWarnings(values, warningSchema);

    useEffect(() => {
        dispatch(fetchAdmins());
    }, [dispatch]);

    const _updateSpecialDeliveryHoursTZ = (value: boolean) => {
        if (value) {
            const region = findRegion(values.region);

            if (region) {
                const newDelivery = {
                    ...values.specialDeliveryHours
                };
                newDelivery.zone = getZone(region);
                setFieldValue("specialDeliveryHours", newDelivery);
            }
        }
    };

    const renderIntegrationInputSection = (
        {
            name,
            label,
            extraField,
            extraFieldLabel
        }: {
            name: string;
            label?: string;
            extraField?: string;
            extraFieldLabel?: string;
        },
        n: number
    ) => {
        const integrationLabel = label || capitalize(name);

        return (
            <div key={n}>
                <Input
                    name={`integrations.${name}.enabled`}
                    label={`Enable ${integrationLabel} Integration`}
                    type="toggle"
                    required
                />
                {extraField &&
                    _.get(values, `integrations.${name}.enabled`) && (
                        <Input
                            name={`integrations.${name}.${extraField}`}
                            label={`${integrationLabel} ${extraFieldLabel}`}
                            type="text"
                            required
                        />
                    )}
            </div>
        );
    };

    const integrationInputs = Object.entries(INTEGRATIONS).map(
        ([section, integrations]) => ({
            label: section,
            inputs:
                section === "POS"
                    ? [
                          ...integrations.map(renderIntegrationInputSection),
                          <div>
                              {/** 1P POS (3p ordering) toggle and text entry included here */}
                              <Input
                                  name="posIntegrations.chowly.enabled"
                                  label="Enable 1P POS Integration (Chowly)"
                                  type="toggle"
                              />
                              <Input
                                  name="posIntegrations.deliverect.enabled"
                                  label="Enable 1P POS Integration (Deliverect)"
                                  type="toggle"
                                  disabled={
                                      !Boolean(
                                          values.posIntegrations?.deliverect
                                              ?.apiKey
                                      )
                                  }
                                  description={
                                      values.posIntegrations?.deliverect?.apiKey
                                          ? undefined
                                          : `Snackpass Location Id: ${values._id}`
                                  }
                              />
                              {values.posIntegrations?.chowly.enabled && (
                                  <Input
                                      name="posIntegrations.chowly.apiKey"
                                      label="1P POS Integration API Key"
                                      description={`https://api.snackpass.co/api/v4/webhooks/chowly/${values._id}`} // webhook
                                      type="text"
                                  />
                              )}
                          </div>
                      ]
                    : integrations.map(renderIntegrationInputSection)
        })
    );

    const IntegrationsSection = (
        <SectionContainer
            label="Integrations"
            expanded={values.integrationsSectionExpanded}
            onClick={() =>
                setFieldValue(
                    "integrationsSectionExpanded",
                    !values.integrationsSectionExpanded
                )
            }
        >
            {integrationInputs.map((section) => (
                <div style={{ marginBottom: "10px" }}>
                    <h2>{section.label} Integrations</h2>
                    {section.inputs}
                </div>
            ))}
        </SectionContainer>
    );

    const findRegion = (name: string | undefined) =>
        name && regions.find((region: IRegion) => region.slug === name);

    const getZone = (region: IRegion) => {
        return region?.tz ?? SnackpassTimezoneEnum.newYork;
    };

    const regions = useSelector(getRegions);
    const isCA = values.addressComponents.state === "CA";

    return (
        <Form
            onKeyPress={(e) => {
                if (e.key === "Enter") {
                    e.preventDefault();
                }
            }}
        >
            <EmojiPickerModal />
            {validYupSchema(values, validationSchema)}
            <WarningsSummary warnings={warnings} />
            <br />
            <br />
            <SectionContainer
                label="Store Builder 👷‍♀️"
                expanded={values.storeBuilderSectionExpanded}
                onClick={() =>
                    setFieldValue(
                        "storeBuilderSectionExpanded",
                        !values.storeBuilderSectionExpanded
                    )
                }
            >
                <Input name="name" label="Store Name" type="text" required />
                <Input
                    name="slug"
                    label="Store Slug"
                    type="text"
                    required
                    description="Abbreviated store name for restaurant referral codes. For restaurants with short names like ‘Abe’s Pizza’ use ‘abespizza’, longer names can be made shorter like ‘Curry &amp; Kabob’ could be ‘curkab’, ‘Taiwan Professional Tea’ ‘tptea’, etc."
                />
                <AddressComponents
                    addressComponents={values.addressComponents}
                    values={values}
                    setFieldValue={setFieldValue}
                />
                <Input
                    name="address"
                    label="Enter Store Address"
                    description="Auto populate store information"
                    message={
                        _.get(values, "geolocation.coordinates.length") === 2
                            ? "Found geolocation ✅"
                            : ""
                    }
                    type="address"
                    required
                />
                <Input
                    name="salesforceId"
                    label="Salesforce Account ID"
                    type="text"
                />
                <EmailInput
                    name="email"
                    label="Owner Email"
                    emailInput={values.email || ""}
                    setFieldValue={setFieldValue}
                />
                <Input
                    name="color"
                    label="Color"
                    type="text"
                    required
                    component={
                        <ColorPicker
                            selectedColor={values.color || ""}
                            onChange={(color) => setFieldValue("color", color)}
                        />
                    }
                    description="Enter a hex code or pick from list of colors"
                />

                <Input
                    name="emoji"
                    value={values.emoji}
                    label="Emoji"
                    type="text"
                    description="Please only save one emoji"
                    component={
                        <>
                            <input
                                type="text"
                                value={values.emoji}
                                onChange={(e) => {
                                    setFieldValue("emoji", e.target.value);
                                }}
                            />
                            <Button
                                label="Select Emoji"
                                containerStyle={{
                                    marginLeft: 3,
                                    display: "inline-block"
                                }}
                                type="button"
                                onPress={() =>
                                    ReduxUtil.showModal("EmojiPickerModal", {
                                        onSelectEmoji: (emoji: string) =>
                                            setFieldValue("emoji", emoji)
                                    })
                                }
                            />
                        </>
                    }
                    required
                />
                <Input
                    name="phoneNumber"
                    value={values.phoneNumber || ""}
                    label="Store Phone Number"
                    type="phone"
                    required
                />
                <Input
                    name="region"
                    label="Region"
                    type="select"
                    value={values.region}
                    required
                    options={useSelector(getRegionOptionsWitTaxRate)}
                    afterChange={(option: { value: string }) => {
                        const region = findRegion(option.value);

                        if (region) {
                            setFieldValue("taxRate", region.taxRate);
                            const zone = getZone(region);
                            const newHours = { ...values.hours, zone };
                            const newDelivery = {
                                ...values.specialDeliveryHours,
                                zone
                            };
                            setFieldValue("hours", newHours); // Update zone for hours
                            setFieldValue("specialDeliveryHours", newDelivery); // Update zone for delivery hours
                        }
                    }}
                />

                <Input
                    name="pod"
                    label="Pod"
                    value={values.pod || undefined}
                    type="text"
                    required
                />

                <Input name="taxRate" label="TaxRate" type="number" required />
                <Input
                    name="isPrimaryStore"
                    label="is this store Primary?"
                    type="toggle"
                    description="Full stack (100% in store ordering), but Snackpass integrates or runs alongside their current POS"
                />
                <EnterpriseInput isEnterprise={initialValues.isEnterprise} />
                <ImageInput
                    setFieldValue={setFieldValue}
                    values={values}
                    name="logoUrl"
                    label="Store Logo"
                    description="Used by the online ordering site and potentially more"
                />

                <Input
                    name="dateLiveOnSnackpass"
                    label="Date live on Snackpass"
                    type="datetime"
                    description="Date in the store's timezone"
                    isDateOnly={true}
                    // TODO: Un-disable the dateMin feature when the storeObj
                    // dateLiveOnSnackpass' are backfilled in the database
                    // dateMin={props.storeObj ? props.storeObj.createdAt : null}
                />

                <div style={styles.box}>
                    <p style={styles.errorMessage}>
                        {JSON.stringify(errors.hours)}
                    </p>
                    <GraphicSelectHours
                        label="Weekly Hours"
                        name="hours"
                        hours={values.hours}
                        setFieldValue={setFieldValue}
                    />
                </div>
            </SectionContainer>
            <SectionContainer
                label="Products/Fulfillments"
                expanded={values.fulfillmentModesSectionExpanded}
                onClick={() =>
                    setFieldValue(
                        "fulfillmentModesSectionExpanded",
                        !values.fulfillmentModesSectionExpanded
                    )
                }
            >
                <h3>Multi Products</h3>
                <Input
                    name="onlineOrderingEnabled"
                    label="Online Ordering 💻"
                    type="toggle"
                    required
                />
                <Input
                    name="digitalMenuEnabled"
                    label="Digital Menu Enabled 📱"
                    type="toggle"
                    required
                />
                <br />
                <h3>Fulfillment Modes</h3>
                <Input
                    name="pickup"
                    label="Store has pickup"
                    type="toggle"
                    required
                />
                <Input
                    name="dineInPreferences.hasDineIn"
                    label="Store has dine-in"
                    type="toggle"
                    required
                />

                {values.dineInPreferences.hasDineIn && (
                    <Input
                        name="dineInPreferences.requireTableNumber"
                        label="Require table numbers"
                        description="For dine-in, app orders only (does not affect Kiosk/Register)"
                        type="toggle"
                    />
                )}

                <Input
                    name="delivery"
                    label="Store Has delivery"
                    type="toggle"
                    required
                />
            </SectionContainer>
            <SectionContainer
                label="Order Receiving & Alerts"
                expanded={values.orderAlertsSectionExpanded}
                onClick={() =>
                    setFieldValue(
                        "orderAlertsSectionExpanded",
                        !values.orderAlertsSectionExpanded
                    )
                }
            >
                <br />
                <h3>How would you like to confirm your orders?</h3>
                <Input
                    disabled
                    name="hasTablet"
                    label="Restaurant uses a tablet 📱"
                    type="toggle"
                    required
                />
                {values.hasTablet && (
                    <TabletSerial
                        setFieldValue={setFieldValue}
                        values={values}
                        editMode={editMode}
                    />
                )}
                <Input
                    name="alerts.fax"
                    label="Send fax for every purchase 📠"
                    type="toggle"
                    description="Auto accept required if stores want to be faxed orders."
                    required
                />
                {values.alerts.fax && (
                    <Input name="faxNumber" label="Fax Number" type="text" />
                )}
                <Input
                    name="allowOrderConfirmationMessages"
                    label="Text/Email Confirmation"
                    type="toggle"
                    description="customize by going to each admin in admins view and selecting order notifications"
                    required
                />
                <br />
                <h3>Receipts</h3>
                <Input
                    name="alerts.email"
                    label="Send email for every purchase 📩"
                    type="toggle"
                    required
                />
                <Input
                    name="alerts.phone"
                    label="Send a phone call for every purchase 📞"
                    type="toggle"
                    required
                />
            </SectionContainer>
            <SectionContainer
                label="POS Tablet Toggles"
                expanded={values.posTogglesExpanded}
                onClick={() =>
                    setFieldValue(
                        "posTogglesExpanded",
                        !values.posTogglesExpanded
                    )
                }
            >
                <Input
                    name="tabletUseQuietTone"
                    label="Use Softer Ring 🤫"
                    type="toggle"
                    required
                />
                <Input
                    name="allowPauseOnPOS"
                    label="Allow pause (on POS)"
                    type="toggle"
                    required
                />
                <Input
                    name="allowViewEarningsOnTablet"
                    label="Allow View Earnings On Tablet"
                    type="toggle"
                    required
                />
                <Input
                    name="pos.disableFlashAnimations"
                    label="Disable flashing animations"
                    description="e.g. for the safety of employees with epilepsy"
                    type="toggle"
                    required
                />
                <Input
                    name="pos.disableRefund"
                    label="Disable refund and price adjustment"
                    type="toggle"
                    required
                />
                <Input
                    name="pos.disableSoldOut"
                    label="Disable sold out management"
                    description="Items can still be marked as sold out/in-stock on the restaurant dashboard"
                    type="toggle"
                    required
                />
            </SectionContainer>
            <SectionContainer
                label="KDS Tablet Toggles"
                expanded={values.kdsTogglesExpanded}
                onClick={() =>
                    setFieldValue(
                        "kdsTogglesExpanded",
                        !values.kdsTogglesExpanded
                    )
                }
            >
                <Input
                    name="hasKds"
                    label="Enable KDS"
                    description="e.g. whether store has KDS units enabled."
                    type="toggle"
                    required
                />

                <Input
                    name="kdsSkipInProgress"
                    label="Enable Fast Mode"
                    description="fast mode = kds doesn't have a in-progress state"
                    type="toggle"
                    required
                />
            </SectionContainer>
            <SectionContainer
                label="Store Misc. Config"
                expanded={values.storeMiscSectionExpanded}
                onClick={() =>
                    setFieldValue(
                        "storeMiscSectionExpanded",
                        !values.storeMiscSectionExpanded
                    )
                }
            >
                <Input
                    name="notes"
                    label="Store Notes"
                    value={values.notes}
                    type="text-field"
                    description="Custom notes that will show up in store screen on app and online ordering"
                />
                <Input
                    name="noNotes"
                    label="No custom notes"
                    type="toggle"
                    description="Prevent customer from adding additional notes to order"
                    required
                />
                <Input
                    name="referralConversionText"
                    label="Referral Conversion Text"
                    type="text"
                    description="Used on conversion modals/screens for both kiosk and online ordering."
                />
                <Input
                    name="requestStoreToJoinSnackpass"
                    label="Store is in request mode"
                    type="toggle"
                    required
                    description="On the app, it will show the store as in request mode, which will allow users to send templated emails asking to join the platform"
                />
                <Input
                    name="comingSoon"
                    label="Store is in coming soon mode"
                    type="toggle"
                    required
                    description="This will show the store on the app but not order-able. Menu doesn't have to be complete."
                />
                <Input
                    name="isTestStore"
                    label="Store is used for testing"
                    type="toggle"
                    required
                    description="This is for internal purposes only, purchases made from this store will be denoted as test purchases."
                />
                <Input
                    name="initialReceiptNumber"
                    label="Receipt number start"
                    type="number"
                    description="This will modify the starting point for receipt numbers. By default, receipt numbers will start at 100 unless specified otherwise. Changes will take effect the following day."
                />
                <Input
                    name="hasEmployeeAuditTracking"
                    label="Store requires employee pin entry"
                    type="toggle"
                    required
                    description="This will make it so employees must enter a pin number every time they want to perform an auditable action such as issuing a refund."
                />
            </SectionContainer>
            <SectionContainer
                label="Pickup Config"
                expanded={values.pickupOptionsSectionExpanded}
                onClick={() =>
                    setFieldValue(
                        "pickupOptionsSectionExpanded",
                        !values.pickupOptionsSectionExpanded
                    )
                }
            >
                {values.pickup && (
                    <Input
                        name="pickupMin"
                        label="Store has pickup min"
                        description="ie. 15 for $15 min order"
                        type="number"
                    />
                )}
                <Input
                    name="pickupDirections"
                    label="Pick Up Directions"
                    type="text"
                />
                <Input
                    type="text"
                    name="customPickUpTimes"
                    label="Custom Pick Up Times"
                    description="Comma separated list"
                    placeholder="0, 5, 10, 15, 20"
                />
                <Input
                    name="allowOrderNow"
                    label="Show in app"
                    description="Warning ⚠️ if this is turned off the store will NOT show up in the app."
                    type="toggle"
                    required
                />
                <Input
                    name="hasPickupNow"
                    label="Pickup ASAP"
                    description="If customers can order for pickup ASAP. If this is false, customers will only be able to schedule ahead (and if both are false, customer cannot order)."
                    type="toggle"
                    required
                />
                <ScheduleAhead values={values} />
            </SectionContainer>
            <SectionContainer
                label="Delivery Config"
                expanded={values.deliveryOptionsSectionExpanded}
                onClick={() =>
                    setFieldValue(
                        "deliveryOptionsSectionExpanded",
                        !values.deliveryOptionsSectionExpanded
                    )
                }
            >
                <Input
                    type="text"
                    name="customDeliveryTimes"
                    label="Custom Delivery Times"
                    description="Comma separated list"
                    placeholder="20, 30, 40, 50, 60"
                />
                <Input
                    name="deliveryOnlyEmail"
                    label="Delivery Only Email"
                    type="text"
                    placeholder="Email for only delivery orders"
                />

                <Input
                    name="storeDelivers"
                    label="Store has 1P Delivery"
                    description="Store delivers without an integration"
                    type="toggle"
                />

                {values.delivery || values.posIntegrations?.chowly.enabled ? (
                    <div>
                        <Input
                            name="hasSpecialDeliveryHours"
                            label="Store Has Special Delivery Hours"
                            type="toggle"
                            onChange={_updateSpecialDeliveryHoursTZ}
                        />
                        <p
                            style={{
                                ...styles.errorMessage,
                                marginTop: 10
                            }}
                        >
                            {errors.specialDeliveryHours?.toString()}
                        </p>
                        {values.hasSpecialDeliveryHours ? (
                            <GraphicSelectHours
                                label="Delivery Hours"
                                name="specialDeliveryHours"
                                hours={values.specialDeliveryHours}
                                setFieldValue={setFieldValue}
                            />
                        ) : null}
                        <Input
                            label="Delivery time"
                            type="number"
                            placeholder="delivery time (10)"
                            name="deliveryTime"
                            description="Enter 5 for 5 minutes"
                        />
                        <div style={styles.box}>
                            <br />
                            <h3>Delivery Ranges:</h3>
                            <DeliveryRanges isCA={isCA} formProps={props} />
                        </div>
                    </div>
                ) : null}
            </SectionContainer>
            {IntegrationsSection}
            <SectionContainer
                label="Auto Refund & Auto Accept"
                expanded={values.autoAcceptOrdersSectionExpanded}
                onClick={() =>
                    setFieldValue(
                        "autoAcceptOrdersSectionExpanded",
                        !values.autoAcceptOrdersSectionExpanded
                    )
                }
            >
                <Input
                    name="serverAutoAcceptsOrders"
                    label="Integration auto-accepts orders"
                    description="For stores which do not use the Snackpass tablet for accepting orders. New orders will be automatically received and started (and customer notified as such) without the tablet."
                    type="toggle"
                    required
                />
                {storeAutoAcceptsOrders && (
                    <div>
                        <Input
                            name="pickupTimeType"
                            label="Pickup time type"
                            type="select"
                            options={pickupTimeTypes}
                            required
                        />
                        {values.pickupTimeType === "specific" && (
                            <Input
                                name="defaultPickupTime"
                                label="Default pickup time"
                                description="Type 5 for 5 minutes, 0 to immediately notify customer"
                                type="number"
                                required
                            />
                        )}
                        {values.pickupTimeType === "range" && (
                            <div>
                                <Input
                                    name="defaultPickupMinTime"
                                    label="Default pickup min time"
                                    description="Type 5 for 5 minutes"
                                    type="number"
                                    required
                                />
                                <Input
                                    name="defaultPickupMaxTime"
                                    label="Default pickup max time"
                                    description="Type 5 for 5 minutes"
                                    type="number"
                                    required
                                />
                            </div>
                        )}
                        <div>
                            <br />
                            <h4>Preview push notification:</h4>
                            <br />
                            {getAutoAcceptDefaultPickupMessage(values) ||
                                "Fix input errors to view preview"}
                        </div>
                        <Divider />
                    </div>
                )}
            </SectionContainer>

            <SectionContainer
                label="Server Direct Print 🖨"
                expanded={values.serverDirectPrintSectionExpanded}
                onClick={() =>
                    setFieldValue(
                        "serverDirectPrintSectionExpanded",
                        !values.serverDirectPrintSectionExpanded
                    )
                }
            >
                <Input
                    name="hasServerDirectPrint"
                    label="Server Direct Print enabled"
                    type="toggle"
                />
            </SectionContainer>
            <Divider />
            {isSubmitting ? (
                <BeatLoader color={Colors.blue} size={10} />
            ) : (
                <Button
                    label={editMode ? "Save" : "Create"}
                    onPress={() => props.handleSubmit()}
                    disabled={formActionDisabled}
                    style={{ width: 120, height: 40 }}
                />
            )}
            <hr />
            {tabletSerialIsTaken && (
                <div style={{ color: Colors.red }}>{`The tablet serial ${
                    values.tabletSerial
                } is already taken by ${
                    props.values.storeWithSerial &&
                    props.values.storeWithSerial.name
                }`}</div>
            )}
            <Warnings warnings={warnings} />
            {hasValidationErrors ? (
                <div
                    style={{
                        ...styles.textContainer,
                        ...styles.errorContainer
                    }}
                >
                    <p style={{ whiteSpace: "pre-wrap" }}>
                        {`${stringifyObject(errors, {
                            singleQuotes: false,
                            transform: (
                                obj: any | any[],
                                prop: any,
                                originalResult: string
                            ) => {
                                let value = obj[prop];
                                if (value && _.isArray(stringifyObject)) {
                                    // Remove all undefined or null
                                    // keys from the list.
                                    // If a list has 4 elements, and the last one is invalid,
                                    // Yup returns [ undefined, undefined, undefined, VALIDATION_ERRORS[3] ],
                                    // so we want to filter the keys that are valid out
                                    return stringifyObject(
                                        (value || []).filter((v: any) => v),
                                        { singleQuotes: true }
                                    );
                                } else {
                                    return originalResult;
                                }
                            }
                        })}`}
                    </p>
                </div>
            ) : null}
        </Form>
    );
};

const styles = {
    textContainer: {
        maxWidth: "80%",
        padding: 10,
        margin: 15,
        borderRadius: 4,
        borderStyle: "solid",
        borderWidth: 1
    },
    exampleButtonContainer: {
        display: "flex"
    },
    exampleButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: 800,
        padding: 12,
        color: "white",
        borderRadius: 150,
        margin: 5
    },
    errorContainer: {
        background: Colors.lightRed,
        borderColor: Colors.red
    },
    button: {
        color: Colors.white,
        backgroundColor: Colors.blue
    },
    errorMessage: {
        color: Colors.red,
        fontSize: 12
    },
    box: {
        borderRadius: 10,
        margin: 10,
        padding: 10,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: Colors.text
    },
    fetchMessage: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    } as CSSProperties
};
