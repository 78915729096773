import React, { CSSProperties, useState, useEffect, useMemo } from "react";

import { View, Text, Button } from "../../SharedComponents";
import ClearFilterButton from "./ClearButton";

import Select from "react-select";
import Search from "../../components/SearchBar";
import Fuse from "fuse.js";

import firebase from "../../firebase";
import { Colors } from "../../utils/Colors";
import * as Styles from "../../utils/Styles";
import StoreRow from "../../containers/StoreRow";
import { IOption } from "../../redux/types";
import { IPromotion, IStore, IUser, IRegion } from "@snackpass/snackpass-types";

import StoreFormModal from "../../modals/StoreFormModal";
import AdminModal from "../../modals/AdminModal";
import StoreChangesModal from "../../modals/StoreChangesModal";
import Loader from "react-loader-spinner";
import StorePauseModal from "../../containers/StorePauseModal";
import { isMobile } from "react-device-detect";
import Pagination from "@material-ui/lab/Pagination";
import _ from "lodash";
import { filterNulls } from "src/utils/arrays/filterNulls";

type Props = {
    addStore: (store: IStore) => void;
    stores: Array<IStore>;
    setPromotionsForStore: (promotions: IPromotion[]) => void;
    user: IUser;
    showModal: (modal: string, props?: Object) => void;
    fetchingStores: boolean;
    regionOptions: IOption[];
    regions: IRegion[];
};

type IStoreWithKeywords = IStore & {
    keywords: string[];
};

function _tokeniseStringWithQuotesBySpaces(string: string): string[] {
    // see https://github.com/krisk/Fuse/issues/235
    // Split the provided string by spaces (ignoring spaces within "quoted text")
    return string.match(/("[^"]*?"|[^"\s]+)+(?=\s*|\s*$)/g) ?? [];
}

function sortAlphabetical(a: IStore, b: IStore) {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
}

const LIVE_OPTIONS = [
    { value: true, label: "live" },
    { value: false, label: "test" }
];
const PRIORITY_OPTIONS = [
    { label: "1 - 🐟", value: 1 },
    { label: "2 - 🐬", value: 2 },
    { label: "3 - 🐳", value: 3 }
];
const ARCHIVED_OPTIONS = [
    { value: false, label: "not archived" },
    { value: true, label: "archived" }
];
const OFFLINE_OPTIONS = [
    { value: false, label: "online" },
    { value: true, label: "offline" }
];

export type Option = {
    value: boolean | number;
    label: string;
};

const ITEMS_PER_PAGE = 25;
const Stores = (props: Props) => {
    const [itemsPerPage] = useState<number>(ITEMS_PER_PAGE);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [query, setQuery] = useState("");
    const [region, setRegion] = useState<string | null>(null);
    const [city, setCity] = useState<string | null>(null);
    const [priority, setPriority] = useState<number | null>(null);
    const [tabletOffline, setTabletOffline] = useState<boolean | null>(null);
    const [isLive, setIsLive] = useState<boolean | null>(null);
    const [isArchived, setIsArchived] = useState<boolean | null>(false);
    const [comingSoonOnly, setComingSoonOnly] = useState<boolean>(false);
    const [searchedStores, setSearchedStores] = useState<IStore[]>([]);
    const { stores, showModal, fetchingStores, regions, regionOptions } = props;

    // this is to display the selection in the dropdown menu, i.e. the react-select component
    const [priorityDisplay, setPriorityDisplay] = useState<Option | null>();
    const [regionDisplay, setRegionDisplay] = useState<Option | null>();
    const [cityDisplay, setCityDisplay] = useState<Option | null>();
    const [liveDisplay, setLiveDisplay] = useState<Option | null>(null);
    const [offlineDisplay, setOfflineDisplay] = useState<Option | null>(null);
    const [archivedDisplay, setArchivedDisplay] = useState<Option | null>(
        ARCHIVED_OPTIONS[0]
    );

    // Initial setting of stores list
    const fuse = useMemo(() => {
        return new Fuse(
            stores.map((s) => {
                return {
                    ...s,
                    // keywords just brings together all the fields we are going to search by since we need to pre-process anyway for region name
                    // Note that _id matching is done separately, since a fuzzy match on _id pulls in too many results.
                    keywords: filterNulls([
                        s.region, // region is just a slug here, hence we grab the region name
                        regions.find((region) => region.slug === s.region)
                            ?.name,
                        s.name,
                        s.address
                    ])
                };
            }),
            {
                keys: ["keywords"],
                isCaseSensitive: false,
                // 0 = needs to be a perfect match, 1 = matches everything
                threshold: 0.2,
                shouldSort: false,
                ignoreLocation: true
            }
        );
        // setup fuse on initial load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stores]);
    useEffect(() => {
        // when fuse gets data, trigger the existing setup
        setSearchedStores(filteredStores(searchResults(query)));
        // Only for initial setup, handleSearch will handle when query changes;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fuse]);

    const REGION_OPTIONS = regionOptions;

    const storeOptions: IOption[] = stores
        .filter((s) => s?.addressComponents?.city)
        .map(
            (s): IOption => ({
                value: s?.addressComponents?.city,
                label: `${s?.addressComponents?.city ?? "Mega City"}, ${
                    s?.addressComponents?.state ?? "The Matrix"
                }`
            })
        );
    const uniqueCityOptions = _.uniqBy(storeOptions, "value");
    const CITY_OPTIONS = _.sortBy(uniqueCityOptions, "value");

    // Update list of stores after a filter has been changed
    useEffect(() => {
        setSearchedStores(filteredStores(searchResults(query)));
        //Reset to page 1
        setPageNumber(1);
    }, [
        priority,
        region,
        city,
        tabletOffline,
        isLive,
        isArchived,
        comingSoonOnly
    ]);

    const handleSearch = (query: string = "") => {
        setQuery(query);
        setSearchedStores(filteredStores(searchResults(query)));
        //Reset to page 1
        setPageNumber(1);
    };
    const changePage = (event: any, value: number) => {
        setPageNumber(value);
    };
    const fuseFilter = (query: string) => {
        if (query.length === 0 || fuse === null) {
            return stores;
        }
        const storesFromIdBasedExactMatching = stores.filter((store) =>
            store._id.includes(query)
        );

        const tokenisedSearchQuery = _tokeniseStringWithQuotesBySpaces(query);
        // Every token must match for this to be considered a match. See https://github.com/krisk/Fuse/issues/235
        // for the original idea.
        const fuseResult = fuse.search({
            $and: tokenisedSearchQuery.map((searchToken: string) => {
                return { keywords: searchToken };
            })
        });
        const storesFromFuseResult = fuseResult.map((r) => r.item);

        // Merge the results from the id-based exact matching and the fuzzy matching
        return _(storesFromIdBasedExactMatching)
            .keyBy("_id") // create a dictionary from exact matching on _id
            .merge(_.keyBy(storesFromFuseResult, "_id")) // create a dictionary from fuse results, and merge it to the 1st
            .values() // turn the combined dictionary to array
            .value();
    };
    const filterPriority = (store: IStore) =>
        !priority || store.salesPriority === priority;
    const filterRegion = (store: IStore) => !region || store.region === region;
    const filterCity = (store: IStore) =>
        !city || store?.addressComponents?.city === city;
    const filterLive = (store: IStore) => {
        if (isLive === null) return true;
        return store.isLive === !!isLive;
    };
    const filterOffline = (store: IStore) => {
        if (tabletOffline === null) return true;
        return store.tabletOffline === !!tabletOffline;
    };
    const filterArchived = (store: IStore) => store.isArchived === !!isArchived;
    const filterComingSoon = (store: IStore) =>
        !comingSoonOnly || store.comingSoon;
    const filteredStores = (stores: IStore[]) =>
        stores
            .filter(filterPriority)
            .filter(filterRegion)
            .filter(filterCity)
            .filter(filterLive)
            .filter(filterOffline)
            .filter(filterArchived)
            .filter(filterComingSoon)
            .sort(sortAlphabetical);
    const searchResults = (query: string = "") => fuseFilter(query);

    return (
        <View style={Styles.panel}>
            <Text large>Stores 🏠</Text>
            <hr />
            <div style={styles.searchArea}>
                <Search
                    placeholder="Search stores by name or ID"
                    onSearch={(query: string) => handleSearch(query)}
                    onClear={() => handleSearch()}
                ></Search>
            </div>
            <div style={styles.filterArea}>
                <Select
                    name="type"
                    placeholder="filter priority"
                    value={priorityDisplay}
                    options={PRIORITY_OPTIONS}
                    styles={{
                        control: (provided: any) => ({
                            ...provided,
                            ...styles.selector
                        })
                    }}
                    onChange={(item: any) => {
                        if (!item) return;
                        setPriority(item.value);
                        setPriorityDisplay(item);
                    }}
                    clearable={true}
                    searchable={true}
                />
                <ClearFilterButton
                    display={priorityDisplay}
                    setFilter={setPriority}
                    setFilterDisplay={setPriorityDisplay}
                />

                <Select
                    name="type"
                    placeholder="filter region"
                    value={regionDisplay}
                    options={REGION_OPTIONS}
                    styles={{
                        control: (provided: any) => ({
                            ...provided,
                            ...styles.selector
                        })
                    }}
                    onChange={(item: any) => {
                        if (!item) return;
                        setRegion(item.value);
                        setRegionDisplay(item);
                    }}
                    clearable={true}
                    searchable={true}
                />
                <ClearFilterButton
                    display={regionDisplay}
                    setFilter={setRegion}
                    setFilterDisplay={setRegionDisplay}
                />

                <Select
                    name="type"
                    placeholder="filter city"
                    value={cityDisplay}
                    options={CITY_OPTIONS}
                    styles={{
                        control: (provided: any) => ({
                            ...provided,
                            ...styles.selector
                        })
                    }}
                    onChange={(item: any) => {
                        if (!item) return;
                        setCity(item.value);
                        setCityDisplay(item);
                    }}
                    clearable={true}
                    searchable={true}
                />
                <ClearFilterButton
                    display={cityDisplay}
                    setFilter={setCity}
                    setFilterDisplay={setCityDisplay}
                />

                <Select
                    styles={{
                        control: (provided: any) => ({
                            ...provided,
                            ...styles.selector
                        })
                    }}
                    name="type"
                    placeholder="filter live"
                    value={liveDisplay}
                    options={LIVE_OPTIONS}
                    clearable={true}
                    clearValueText="clear"
                    onChange={(item: any) => {
                        if (!item) return;
                        setIsLive(item.value);
                        setLiveDisplay(item);
                    }}
                />
                <ClearFilterButton
                    display={liveDisplay}
                    setFilter={setIsLive}
                    setFilterDisplay={setLiveDisplay}
                />

                <Select
                    clearable={true}
                    name="type"
                    placeholder="filter offline"
                    value={offlineDisplay}
                    styles={{
                        control: (provided: any) => ({
                            ...provided,
                            ...styles.selector
                        })
                    }}
                    options={OFFLINE_OPTIONS}
                    onChange={(item: any) => {
                        if (!item) return;
                        setTabletOffline(item.value);
                        setOfflineDisplay(item);
                    }}
                />
                <ClearFilterButton
                    display={offlineDisplay}
                    setFilter={setTabletOffline}
                    setFilterDisplay={setOfflineDisplay}
                />

                <Select
                    name="type"
                    placeholder="filter archived"
                    value={archivedDisplay}
                    options={ARCHIVED_OPTIONS}
                    clearable={true}
                    clearValueText="clear"
                    styles={{
                        control: (provided: any) => ({
                            ...provided,
                            ...styles.selector
                        })
                    }}
                    onChange={(item: any) => {
                        if (!item) return;
                        setIsArchived(item.value);
                        setArchivedDisplay(item);
                    }}
                />
                <ClearFilterButton
                    display={archivedDisplay}
                    setFilter={setIsArchived}
                    setFilterDisplay={setArchivedDisplay}
                />

                <Button
                    label="Create Store"
                    onPress={() => {
                        showModal("StoreFormModal");
                    }}
                    style={styles.createButton}
                />
                <hr style={styles.forceNewRow} />
                <div style={styles.comingSoonArea}>
                    <label style={styles.comingSoonCheckboxLabel}>
                        <Text small bold>
                            "Coming soon" Stores
                        </Text>
                        <input
                            type="checkbox"
                            onChange={(e) =>
                                setComingSoonOnly(e.target.checked)
                            }
                            style={styles.comingSoonInput}
                        />
                    </label>
                </div>
            </div>
            {/* MODALS */}
            <StoreFormModal />
            <AdminModal />
            <StoreChangesModal />
            <StorePauseModal />
            {fetchingStores ? (
                <div>
                    <br />
                    <br />
                    <Loader
                        type="Oval"
                        color="#00BFFF"
                        height={80}
                        width={100}
                    />
                </div>
            ) : null}
            {searchedStores
                .slice(
                    (pageNumber - 1) * itemsPerPage,
                    (pageNumber - 1) * itemsPerPage + itemsPerPage
                )
                .map((store: IStore, i) => {
                    return (
                        <StoreRow
                            storeObj={store}
                            key={store._id}
                            regions={regions}
                        />
                    );
                })}
            <div style={styles.paginationContainer}>
                <Pagination
                    count={Math.ceil(searchedStores.length / itemsPerPage) || 5}
                    page={pageNumber}
                    shape={"rounded"}
                    variant={"outlined"}
                    onChange={changePage}
                />
            </div>
            <Button
                backgroundColor={Colors.gray}
                label="sign out"
                onPress={async () => {
                    await firebase.auth().signOut();
                    window.location.reload();
                }}
            />
        </View>
    );
};

export default Stores;

const styles = {
    searchArea: {
        width: "100%",
        height: 40,
        padding: "0 10px",
        marginBottom: 5
    } as CSSProperties,
    forceNewRow: { width: "100%", border: "none", margin: 0 } as CSSProperties,
    comingSoonArea: {
        margin: 10,
        width: 180,
        height: 30
    } as CSSProperties,
    comingSoonCheckboxLabel: {
        display: "flex",
        alignItems: "center",
        gap: 10
    } as CSSProperties,
    comingSoonInput: {
        transform: "scale(1.5)",
        margin: "auto"
    } as CSSProperties,
    filterArea: {
        display: "flex",
        width: "100%",
        flexDirection: isMobile ? "column" : "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        alignItems: "center"
    } as CSSProperties,
    selector: {
        width: 160,
        height: 30,
        margin: 10,
        cursor: "pointer"
    } as CSSProperties,
    createButton: {
        height: 40,
        borderRadius: 5,
        margin: 10
    } as CSSProperties,
    paginationContainer: {
        marginTop: 20
    } as CSSProperties
};
