import { connect } from "react-redux";
import {} from "../redux/activeUser";
import Regions from "../screens/Regions";
import { RegionState } from "../redux/types";
import { fetchRegions } from "../redux/regions";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { waitForUser } from "../firebase";
import { show, hide } from "redux-modal";
const mapStateToProps = (state: RegionState) => {
    return {
        regions: state.regions,
    };
};

// create handler than dispatches an action

const mapDispatchToProps = (
    dispatch: ThunkDispatch<RegionState, undefined, Action>
) => {
    return {
        showModal: (modal: string, props?: Object) => {
            dispatch(show(modal, props));
        },
        hideModal: (modal: string) => {
            dispatch(hide(modal));
        },
        fetchRegions: async () => {
            waitForUser(() => {
                return dispatch(fetchRegions());
            });
        },
    };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(Regions);

export default Container;
