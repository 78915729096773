import React from "react";

import {
    Text,
    Button,
    TouchableHighlight,
    Image,
} from "../../SharedComponents";
import Select from "react-select";

import "rc-time-picker/assets/index.css";
import { Colors } from "../../utils";
import { IOption } from "../../redux/types";
import TimePicker from "rc-time-picker";
import moment, { Moment } from "moment";
import { Field, FieldArray } from "formik";
import { HoursItemOptionProps, HoursItem } from "./types";
import { dayOptions, format } from "./lib";
import deleteIcon from "../../assets/icons/delete.png";
const HoursItemOption = ({
    name,
    index,
    arrayHelpers,
    form,
    hoursItem,
}: HoursItemOptionProps) => (
    <div style={styles.container}>
        <div style={styles.containerInner}>
            <div style={styles.row}>
                <Text>Open:</Text>
                <Select
                    required
                    name={"Day"}
                    value={dayOptions.find(
                        o => o.value === hoursItem.startTime.dayOfWeek
                    )}
                    className="snackpass__react-select"
                    options={dayOptions}
                    onChange={(item: IOption) => {
                        form.setFieldValue(
                            `${name}.${index}.startTime.dayOfWeek`,
                            item.value
                        );
                    }}
                />
                <TimePicker
                    showSecond={false}
                    defaultValue={moment(hoursItem.startTime.time)}
                    format={format}
                    use12Hours
                    onChange={(value: Moment) => {
                        form.setFieldValue(
                            `${name}.${index}.startTime.time`,
                            value ? value.toDate() : value
                        );
                    }}
                />
            </div>
            <div style={styles.row}>
                <Text>Close:</Text>
                <Select
                    required
                    name={"Day"}
                    value={dayOptions.find(
                        o => o.value === hoursItem.endTime.dayOfWeek
                    )}
                    className="snackpass__react-select"
                    options={dayOptions}
                    onChange={(item: IOption) => {
                        form.setFieldValue(
                            `${name}.${index}.endTime.dayOfWeek`,
                            item.value
                        );
                    }}
                />
                <TimePicker
                    showSecond={false}
                    defaultValue={moment(hoursItem.endTime.time)}
                    format={format}
                    use12Hours
                    onChange={(value: Moment) => {
                        form.setFieldValue(
                            `${name}.${index}.endTime.time`,
                            value ? value.toDate() : value
                        );
                    }}
                />
            </div>
            <Button
                label="duplicate"
                onPress={e => {
                    e.preventDefault();

                    arrayHelpers.push({ ...hoursItem });
                }}
            />
        </div>
        <RemoveButton
            onClick={e => {
                e.preventDefault();
                arrayHelpers.remove(index);
            }}
        />
    </div>
);

const RemoveButton = ({
    onClick,
}: {
    onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}) => (
    <TouchableHighlight
        onPress={onClick}
        style={{
            marginLeft: 10,
            justifyContent: "center",
        }}
    >
        <Image
            source={deleteIcon}
            style={{
                width: 25,
                height: 25,
            }}
        />
    </TouchableHighlight>
);

const hoursItemTemplate: HoursItem = {
    startTime: {
        dayOfWeek: "Sunday",
        time: new Date(),
    },
    endTime: {
        dayOfWeek: "Sunday",
        time: new Date(),
    },
};

const styles = {
    container: {
        flexDirection: "row" as "row",
        display: "flex" as "flex",
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: 10,
        padding: 10,
        paddingLeft: 25,
        paddingRight: 25,
        background: "white",
        boxShadow: "0px 0px 8px rgba(0, 0, 0, .2)",
        borderColor: "lightgray",
        margin: 10,
    },
    containerInner: {
        flexDirection: "column" as "column",
        flexGrow: 4,
    },
    row: {
        alignItems: "center",
        display: "flex",
    },
};

export const Hours = ({ name }: { name: any }) => (
    <Field
        name={name}
        render={({ field, form }: { field: any; form: any }) => (
            <FieldArray
                name={name}
                render={(arrayHelpers: any) => {
                    const form = arrayHelpers.form;
                    return (
                        <div>
                            <p>Click to select time!</p>
                            {field.value.map((hoursItem: any, i: number) => (
                                <HoursItemOption
                                    key={i}
                                    name={name}
                                    arrayHelpers={arrayHelpers}
                                    hoursItem={hoursItem}
                                    index={i}
                                    form={form}
                                />
                            ))}
                            <Button
                                onPress={e => {
                                    e.preventDefault();
                                    arrayHelpers.push({
                                        ...hoursItemTemplate,
                                    });
                                }}
                                backgroundColor={Colors.blue}
                                label={"Add Timerange"}
                            />
                        </div>
                    );
                }}
            />
        )}
    />
);
export * from "./lib";
export * from "./types";
