import React from "react";
import Input from "./Input";
import { FormValues } from "../types";
import { Colors } from "src/utils";

type Props = {
    values: FormValues;
};
const ScheduleAhead = ({ values }: Props) => {
    return (
        <div>
            <Input
                name="hasScheduledOrders"
                label="Store Has Schedule Ahead"
                description="Allows customers to schedule their order up to a week in advance."
                type="toggle"
            />
            {values.hasScheduledOrders && (
                <div>
                    <Input
                        name="scheduledOrderMinLeadTime"
                        label="Scheduled order minimum lead time (minutes)"
                        description="The minimum number of minutes in advance that scheduled orders must be placed."
                        type="number"
                        required
                    />

                    <Input
                        name="scheduleAheadInterval"
                        label="Length of schedule interval (minutes)"
                        type="number"
                        placeholder="15"
                        description="Ex. 30 would mean the customer would see times like 2:00, 2:30, 3:00, etc..."
                    />
                    <Input
                        type="number"
                        name="scheduledOrderPosTriggerTime"
                        label="POS Trigger Time of Scheduled Pickup Orders"
                        description="Overrides the num mins before the order hits the POS (default is pickup estimate time)"
                        placeholder="60"
                    />
                    {(values.scheduledOrderPosTriggerTime || 0) > 60 ? (
                        <div style={styles.errorContainer}>
                            <p>
                                🛑 Warning 🛑 It is recommended that an employee
                                is present to take an order before the store
                                opens if setting trigger time greater than 60
                                minutes
                            </p>
                        </div>
                    ) : null}
                    <Input
                        name="hasBatching"
                        label="Has batching"
                        type="toggle"
                        description="This will limit the number of people that are allowed to schedule orders in a given time interval."
                    />

                    {values.hasBatching && (
                        <Input
                            name="scheduleAheadBatchSize"
                            label="Batch size"
                            type="number"
                            placeholder="15"
                            description="Ex. 10 means that for every interval, only 10 orders can be placed."
                        />
                    )}
                </div>
            )}
        </div>
    );
};

const styles = {
    errorContainer: {
        background: Colors.lightRed,
        borderColor: Colors.red,
    },
    errorMessage: {
        color: Colors.red,
        fontSize: 12,
    },
};

export default ScheduleAhead;
