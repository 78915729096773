import React, { ReactNode } from "react";

import { View } from "../SharedComponents/View";

import { Colors } from "../utils/Colors";

const row: "row" = "row";

export const customStyles = {
    row: {
        backgroundColor: Colors.white,
        borderColor: Colors.lightGray,
        borderWidth: 0,
        borderBottomWidth: 1,
        padding: 10,
        paddingLeft: 15,
        paddingRight: 15,
        alignSelf: "stretch",
        flexDirection: row,
        alignItems: "center",
        borderStyle: "solid",
    },
};

export interface IRow {
    style?: React.CSSProperties;
    children: ReactNode;
}

export const Row = ({ style, children }: IRow) => (
    <View style={{ ...customStyles.row, ...style }}>{children}</View>
);
