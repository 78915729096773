import _ from "lodash";
import * as Sentry from "@sentry/browser";
import fileDialog from "file-dialog";
import React, { CSSProperties } from "react";
// @ts-ignore
import DropToUpload from "react-drop-to-upload";

import { Input } from "./Input";
import { FormValues } from "../types";
import API from "../../../api";
import { Button, Text, View } from "../../../SharedComponents";
import { Colors } from "../../../utils";
import { BeatLoader } from "react-spinners";
import CopyToClipboard from "react-copy-to-clipboard";
import { ImageCropperModal } from "./ImageCropperModal";
import { compressThenUpload } from "./helpers";

// 200 kb = 1000 bytes * 200 kb / bytes
const DEFAULT_MAX_FILE_SIZE: number = 1000 * 200;

type Props = {
    editMode?: boolean;
    values: FormValues;
    name: string;
    label: string;
    required?: boolean;
    maxBytesFileSize?: number;
    setFieldValue: (field: string, value: any) => void;
    description?: string;
};

type State = {
    loadingImage: boolean;
    copied: boolean;
    showCropModal: boolean;
    imageFile: File | null;
};

export default class ImageInput extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            loadingImage: false,
            copied: false,
            showCropModal: false,
            imageFile: null,
        };

        this.handleDrop = this.handleDrop.bind(this);
        this.removeImage = this.removeImage.bind(this);
        this.openFileDialog = this.openFileDialog.bind(this);
        this.upload = this.upload.bind(this);
        this.attemptUpload = this.attemptUpload.bind(this);
        this.openCropModal = this.openCropModal.bind(this);
        this.closeCropModal = this.closeCropModal.bind(this);
    }

    _maxSize = () => this.props.maxBytesFileSize || DEFAULT_MAX_FILE_SIZE;

    handleDrop(files: any[]) {
        let file = files[0];
        if (file.type.split("/")[0] === "image") {
            this.openCropModal(file);
        }
    }

    removeImage() {
        this.props.setFieldValue(this.props.name, null);
    }

    openFileDialog() {
        fileDialog({ multiple: false, accept: "image/*" }).then(
            (files: FileList) => {
                let file = files[0];
                this.openCropModal(file);
            }
        );
    }

    openCropModal(file: File) {
        this.setState({ imageFile: file, showCropModal: true });
    }

    closeCropModal() {
        this.setState({ showCropModal: false, imageFile: null });
    }

    attemptUpload(file: any) {
        if (file.size > this._maxSize()) {
            compressThenUpload(this.upload, file);
        } else {
            this.upload(file);
        }
    }

    upload(file: any) {
        const formData = new FormData();
        formData.append("photo", file);
        this.setState({ loadingImage: true });
        API.uploads
            .image(formData, this.props.values.name)
            .catch(err => {
                Sentry.captureException(err);
                window.alert("could not upload photo");
            })
            .then((res: any) =>
                this.props.setFieldValue(
                    this.props.name,
                    res.data.location
                        .split(" ")
                        .join("+")
                        .split("'")
                        .join("%27")
                )
            )
            .finally(() => this.setState({ loadingImage: false }));
    }
    _copyClipboard = () => {
        let url = _.get(this.props.values, this.props.name, "");
        if (!url) {
            return null;
        }
        return (
            <div>
                <CopyToClipboard
                    onCopy={() =>
                        this.setState({ copied: true }, () => {
                            setTimeout(() => {
                                this.setState({
                                    copied: false,
                                });
                            }, 1000);
                        })
                    }
                    text={url}
                >
                    <span style={styles.copyImg}>
                        Copy image link to clipboard
                    </span>
                </CopyToClipboard>
                {this.state.copied ? (
                    <span style={{ color: "red", margin: 10 }}>Copied 🤩</span>
                ) : null}
            </div>
        );
    };
    render() {
        let dropZoneContent = <BeatLoader color={Colors.blue} size={10} />;
        if (!this.state.loadingImage) {
            dropZoneContent = (
                <>
                    <Text style={{ color: "white" }}>
                        {"Drag Image To Upload"}
                    </Text>
                    <br />
                    <Text style={{ color: "white" }}>Or</Text>
                    <br />
                    <Button
                        label="Select Image from Here"
                        onPress={(
                            event: React.MouseEvent<HTMLButtonElement>
                        ) => {
                            event.preventDefault();
                            this.openFileDialog();
                        }}
                    />
                </>
            );
        }
        return (
            <View>
                <ImageCropperModal
                    isOpen={this.state.showCropModal}
                    onRequestClose={this.closeCropModal}
                    imageFile={this.state.imageFile}
                    onSubmit={(file: any) => {
                        this.attemptUpload(file);
                        this.closeCropModal();
                    }}
                    onCancel={this.closeCropModal}
                />
                <Input
                    name={this.props.name}
                    label={this.props.label}
                    type="text"
                    required={this.props.required}
                    description={this.props.description}
                    component={
                        <div>
                            <DropToUpload onDrop={this.handleDrop}>
                                <div
                                    style={{
                                        backgroundImage: `url(${_.get(
                                            this.props.values,
                                            this.props.name
                                        )})`,
                                        ...styles.backgroundImg,
                                    }}
                                >
                                    <View style={styles.dropzone}>
                                        {dropZoneContent}
                                    </View>
                                </div>
                            </DropToUpload>
                            {this._copyClipboard()}
                        </div>
                    }
                />
            </View>
        );
    }
}

const styles = {
    copyImg: {
        margin: 5,
        borderRadius: 4,
        borderColor: "gray",
        cursor: "pointer",
        fontSize: 12,
        textDecoration: "underline",
    } as CSSProperties,
    dropzone: {
        width: 324,
        height: 234,
        borderRadius: 3,
        backgroundColor: "#00000066",
        alignItems: "center",
        justifyContent: "center",
    } as CSSProperties,
    backgroundImg: {
        width: 324,
        height: 234,
        borderRadius: 3,
        backgroundSize: "cover",
        backgroundPosition: "center",
        overflow: "hidden",
    } as CSSProperties,
};
