import { connect } from "react-redux";
import Promotions from "../components/PromotionForm";
import { getStoreSelectOptions, getActiveStore } from "../redux/stores";
import { Dispatch } from "redux";
import { StoreState } from "../redux/types";
import { formatArrayForSelect } from "../utils/Helpers";

const mapStateToProps = (state: StoreState, props: any) => {
    let activeStore = getActiveStore(state);
    return {
        storeOptions: getStoreSelectOptions(state),
        categoryOptions: activeStore
            ? formatArrayForSelect(activeStore.productCategories)
            : [],
        productOptions: activeStore
            ? formatArrayForSelect(activeStore.products || [])
            : [],
        activeStore,
    };
};

// create handler than dispatches an action
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {};
};

const Container = connect(mapStateToProps, mapDispatchToProps)(Promotions);

export default Container;
