import React, {
    ChangeEventHandler,
    KeyboardEventHandler,
    RefObject,
} from "react";
import { SystemColors } from "@snackpass/design-system";

// TODO:
// number only
// phone number

function decimalPlaces(num: string) {
    var match = ("" + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
    if (!match) {
        return 0;
    }
    return Math.max(
        0,
        // Number of digits right of decimal point.
        (match[1] ? match[1].length : 0) -
            // Adjust for scientific notation.
            (match[2] ? +match[2] : 0)
    );
}

const filterNumber = (value: string) => {
    if (!value) {
        return true;
    }
    return !isNaN(parseFloat(value)) && decimalPlaces(value) <= 2;
};

const filterInteger = (value: string) => {
    const regexp = /^(?:[1-9]\d*|\d)$/;
    return regexp.test(value);
};

export interface ITextInput {
    label?: string;
    value?: any;
    placeholder?: string;
    onSubmit?: Function;
    onFocus?: Function;
    onBlur?: Function;
    onEscape?: Function;
    onChangeText?: (value: string) => void;
    type?: string;
    // use this for style
    style?: any;
    containerStyle?: any;
    number?: boolean;
    integer?: boolean;
    text?: string;
    isSet?: boolean;
    disabled?: boolean;
}

export const TextInput = ({
    label,
    value,
    placeholder,
    onSubmit,
    onFocus,
    onBlur,
    onEscape,
    onChangeText,
    type,
    style,
    containerStyle,
    number,
    integer,
    disabled,
    text,
    isSet,
}: ITextInput) => {
    // TODO: check if this createRef is used correctly
    const inputRef: RefObject<HTMLInputElement> = React.createRef();

    const handleSubmit = () => {
        if (onSubmit) {
            onSubmit();
            if (onEscape) {
                onEscape();
            }
        }
    };

    const onKeyDown: KeyboardEventHandler = event => {
        try {
            if (event.key === "Enter") {
                handleSubmit();
            }
            if (event.key === "Escape" && onEscape) {
                onEscape();
            }
        } catch (err) {}
    };

    const onChange: ChangeEventHandler<HTMLInputElement> = event => {
        if (!onChangeText) {
            return;
        }
        const value = event.target.value;
        if (number) {
            if (filterNumber(value)) {
                onChangeText(value);
            }
            return;
        }
        if (integer) {
            if (filterInteger(value)) {
                onChangeText(value);
            }
            return;
        }
        onChangeText(value);
    };

    const _onFocus = () => onFocus?.();
    const _onBlur = () => onBlur?.();

    return (
        <div
            style={{
                marginTop: 10,
                marginBottom: 10,
                ...containerStyle,
                display: "flex",
            }}
        >
            {label}
            <input
                ref={inputRef}
                placeholder={placeholder ?? ""}
                onKeyDown={onKeyDown}
                onChange={onChange}
                onFocus={_onFocus}
                onBlur={_onBlur}
                type={type ?? "text"}
                value={value}
                style={style}
                disabled={disabled ?? false}
            />
            {onSubmit && (
                <button onClick={handleSubmit} style={styles.submitButton}>
                    ✅ Save
                </button>
            )}
        </div>
    );
};

const styles = {
    submitButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: 5,
        backgroundColor: SystemColors.v1.gray80,
        fontSize: 15,
    },
};
