import PromotionsMetrics from "../screens/PromotionsMetrics";
import { connect } from "react-redux";
import { setPromotions, fetchPromotions } from "../redux/promotions";
import { StoreState } from "../redux/types";
import { IPromotion } from "@snackpass/snackpass-types";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";

const mapStateToProps = (state: StoreState) => {
    return {
        promotions: state.promotions,
        regions: state.regions,
    };
};

// create handler than dispatches an action
const mapDispatchToProps = (
    dispatch: ThunkDispatch<StoreState, undefined, Action>
) => {
    return {
        fetchPromotions: () => {
            return dispatch(fetchPromotions());
        },
        setPromotions: (promotions: IPromotion[]) => {
            dispatch(setPromotions(promotions));
        },
        dispatch,
    };
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(PromotionsMetrics);

export default Container;
