import React, { Component } from "react";

import { View, Text, TouchableHighlight } from "../../SharedComponents";

import { Colors } from "../../utils/Colors";
import { IPurchase } from "@snackpass/snackpass-types";

const row: "row" = "row";

type SelectTimesProps = {
    onPressTime: Function;
    purchase: IPurchase;
};

const secondsToTime = (secs: number) => {
    let hours = Math.floor(secs / 3600);
    secs %= 3600;
    let minutes = Math.floor(secs / 60);
    let seconds = secs % 60;

    let obj = {
        h: Math.floor(hours),
        m: Math.floor(minutes),
        s: Math.floor(seconds),
    };
    return obj;
};

const defaultPosPickupTimes = ["0", "2", "5", "8", "10", "15", "20", "25"];
const defaultPosDeliveryTimes = [
    "15",
    "20",
    "25",
    "30",
    "45",
    "60",
    "75",
    "90",
];

const isDelivery = (activePurchase: IPurchase) => {
    return activePurchase.fulfillment === "DELIVERY";
};

class SelectTimes extends Component<SelectTimesProps> {
    getStartOrderTimeButton = (time: string, i: number) => {
        let innerText = `${time}m`;
        if (time === "0") {
            innerText = "Now";
        }
        return (
            <TouchableHighlight
                key={i}
                onPress={() => {
                    this.handlePress(time);
                }}
                style={ComponentStyles.buttonContainer}
            >
                <View key={i} style={ComponentStyles.selectTimeButton}>
                    <Text center color={Colors.white}>
                        {innerText}
                    </Text>
                </View>
            </TouchableHighlight>
        );
    };
    handlePress = (time: string) => {
        this.props.onPressTime(time);
    };
    render() {
        const { purchase } = this.props;
        let times = defaultPosPickupTimes;

        if (isDelivery(purchase)) {
            times = defaultPosDeliveryTimes;
        }

        return (
            <View>
                <View style={ComponentStyles.selectTimeRow}>
                    {times.slice(0, 4).map((time, i) => {
                        return this.getStartOrderTimeButton(time, i);
                    })}
                </View>
                <View style={ComponentStyles.selectTimeRow}>
                    {times.slice(4, 8).map((time, i) => {
                        return this.getStartOrderTimeButton(time, i);
                    })}
                </View>
            </View>
        );
    }
}

type Time = {
    h: number;
    m: number;
    s: number;
};

type StartProps = {
    purchase: IPurchase;
    handlePressTime: (time: any) => void;
};

type StartState = {
    time: Time;
    seconds: number;
    submitted: boolean;
};

export default class Start extends Component<StartProps, StartState> {
    state = {
        time: {} as Time,
        seconds: 0,
        submitted: false,
    };

    componentDidMount() {
        let timeLeftVar = secondsToTime(this.state.seconds);
        this.setState({ time: timeLeftVar });
    }

    render() {
        const { submitted } = this.state;
        const { purchase } = this.props;
        if (submitted) {
            return null;
        }
        return (
            <View style={ComponentStyles.container}>
                <Text center bold color={Colors.green}>
                    {"Tell Customer "}
                    {isDelivery(purchase)
                        ? "Order Will Arrive In"
                        : "Pickup In"}
                </Text>
                <Text />
                <SelectTimes
                    purchase={purchase}
                    onPressTime={this.props.handlePressTime}
                />
            </View>
        );
    }
}

let ComponentStyles = {
    container: {
        padding: 10,
        borderRadius: 8,
        borderColor: Colors.green,
        marginHorizontal: 15,
        backgroundColor: "#F0FBF5",
    },
    buttonContainer: {
        width: "23%",
        height: 50,
        marginVertical: 5,
    },
    selectTimeButton: {
        backgroundColor: Colors.gray,
        borderRadius: 10,
        width: "100%",
        height: "100%",
        paddingVertical: 10,
        paddingHorizontal: 15,
        justifyContent: "center",
    },
    selectTimeRow: {
        flexDirection: row,
        justifyContent: "space-between",
    },
};
