import { ITochiMaterial } from "@snackpass/snackpass-types";
import { Form, Formik, FormikActions, FormikProps } from "formik";
import _ from "lodash";
import React, { useState, CSSProperties } from "react";
import Swal from "sweetalert2";
import api from "../../../../api";
import { Button, Input, Text, TextInput } from "../../../../SharedComponents";
import { TochiMaterialsForm } from "../../components/TochiMaterialsForm";
import {
    initializeFields,
    materialTypeOptions,
    validationSchema,
} from "./config";
import { TOCHIMATERIAL_FORM_TEMPLATE, FormValues } from "./types";

const TochiMaterialsSection = () => {
    const [materialId, setMaterialId] = useState("");
    const [material, setMaterial] = useState<ITochiMaterial | null>();

    const getMaterial = async (id: string) => {
        api.tochiMaterials
            .get(id)
            .then(res => {
                setMaterial(res.data.mat);
            })
            .catch(err => {
                Swal.fire({
                    type: "error",
                    title: "Error Getting Material",
                    text: `There was an issue getting material at id ${id}`,
                });
                console.log(err);
            });
    };

    const handleIdChange = (id: string) => {
        setMaterialId(id);
    };

    const handleIdSubmit = () => {
        getMaterial(materialId);
    };

    const handleSubmit = async (
        values: FormValues,
        formikActions: FormikActions<FormValues>
    ) => {
        try {
            formikActions.setSubmitting(true);
            await api.tochiMaterials.update(values, values._id);
            formikActions.resetForm();
        } catch (err) {
            Swal.fire({
                type: "error",
                title: `An error occurred`,
                text: _.get(
                    err,
                    "response.data.message",
                    "Could not save item."
                ),
            });
        } finally {
            Swal.fire({
                type: "success",
                title: "Material Updated",
            });
            setMaterialId("");
            setMaterial(null);
            formikActions.setSubmitting(false);
        }
    };

    return (
        <div>
            <div style={styles.search}>
                <TextInput
                    placeholder="enter material id here"
                    value={materialId}
                    onChangeText={handleIdChange}
                />
                <Button
                    label="search 🔍"
                    disabled={!materialId}
                    onPress={handleIdSubmit}
                    style={styles.searchButton}
                />
            </div>
            <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={initializeFields(material)}
                onSubmit={handleSubmit}
            >
                {(formProps: FormikProps<FormValues>) => {
                    const handleTypeSelect = () => {
                        const vals = TOCHIMATERIAL_FORM_TEMPLATE;
                        vals._id = materialId;
                        formProps.values = vals;
                    };

                    const handleCancel = () => {
                        setMaterialId("");
                        setMaterial(null);
                        formProps.resetForm();
                    };

                    return (
                        <Form>
                            {material && (
                                <div>
                                    <Text large>Material: {material.name}</Text>
                                    <Input
                                        name={"type"}
                                        label="Material Type"
                                        description="The type of material to create (ask Tim if you are unsure)"
                                        required
                                        type="select"
                                        options={materialTypeOptions}
                                        onSelect={handleTypeSelect}
                                    />
                                    <TochiMaterialsForm
                                        type={formProps.values.type}
                                        root=""
                                    />
                                </div>
                            )}
                            {material && (
                                <div style={styles.buttons}>
                                    <Button
                                        label={"confirm"}
                                        disabled={!formProps.isValid}
                                        onPress={() => {
                                            formProps.handleSubmit();
                                        }}
                                        type="button"
                                        className="submit-btn"
                                        loading={formProps.isSubmitting}
                                        style={styles.confirm}
                                    />
                                    <Button
                                        label="cancel"
                                        type="button"
                                        onPress={handleCancel}
                                        style={styles.cancel}
                                    />
                                </div>
                            )}
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

const styles = {
    buttons: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 10,
    } as CSSProperties,
    cancel: { marginLeft: 15 } as CSSProperties,
    confirm: { width: 120, height: 40 } as CSSProperties,
    search: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    } as CSSProperties,
    searchButton: { marginLeft: 15 } as CSSProperties,
};

export default TochiMaterialsSection;
