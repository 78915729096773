import React, { ReactNode } from "react";

import { View } from "./View";

export interface ICol {
    alignRight?: boolean;
    flex?: number;
    style?: React.CSSProperties;
    children?: ReactNode;
}

const center: "center" = "center";

// A stateless interface
export const Col = ({ alignRight, flex, style, children }: ICol) => {
    let alignItems = "default";
    if (alignRight) {
        alignItems = "flex-end";
    }
    return (
        <View
            style={{
                flex: flex,
                justifyContent: center,
                alignItems: alignItems,
                ...style,
            }}
        >
            {children}
        </View>
    );
};
