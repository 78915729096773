import { connect } from "react-redux";
import StoreTablets from "../screens/StoreTablets";
import {
    removeStoreTablet,
    setPrimaryStoreTablet,
    setTabletSilentMode,
    updateJAMFStoreTablet,
} from "../redux/storeTablets";
import { getStorePrimaryTablets } from "../redux/stores";

import * as Helpers from "../utils/Helpers";
import { Action } from "redux";
import { StoreState } from "../redux/types";
import { ThunkDispatch } from "redux-thunk";

const mapStateToProps = (state: StoreState) => ({
    stores: state.stores,
    storeItems: Helpers.formatArrayForSelect(state.stores),
    storeTablets: state.storeTablets,
    storeToPrimaryTablet: getStorePrimaryTablets(state),
});

// create handler than dispatches an action
const mapDispatchToProps = (
    dispatch: ThunkDispatch<StoreState, undefined, Action>
) => ({
    dispatch,
    removeStoreTablet: (storeTabletId: string) => {
        dispatch(removeStoreTablet(storeTabletId));
    },
    setPrimaryTablet: async (storeTabletId: string) => {
        return dispatch(setPrimaryStoreTablet(storeTabletId));
    },
    setTabletSilentMode: (tabletId: string, silentMode: boolean) =>
        dispatch(setTabletSilentMode(tabletId, silentMode)),
    updateJAMFTablet: async (storeTabletId: string) => {
        return dispatch(updateJAMFStoreTablet(storeTabletId));
    },
});

const Container = connect(mapStateToProps, mapDispatchToProps)(StoreTablets);

export default Container;
