import { ITochiItem } from "@snackpass/snackpass-types";
import { Dispatch } from "redux";
import api from "../api";
import * as Sentry from "@sentry/browser";
import _ from "lodash";

// Constants
export const SET_TOCHI_ITEMS = "SET_TOCHI_ITEMS";
export const ADD_TOCHI_ITEM = "ADD_TOCHI_ITEM";
export const UPDATE_TOCHI_ITEM = "UPDATE_TOCHI_ITEM";
export const REMOVE_TOCHI_ITEM = "REMOVE_TOCHI_ITEM";

// Actions
interface SetTochiItems {
    type: "SET_TOCHI_ITEMS";
    items: ITochiItem[];
}
export function setTochiItems(items: ITochiItem[]) {
    return { type: SET_TOCHI_ITEMS, items };
}

interface AddTochiItem {
    type: "ADD_TOCHI_ITEM";
    item: ITochiItem;
}
export function addTochiItem(item: ITochiItem) {
    return { type: ADD_TOCHI_ITEM, item };
}

interface UpdateTochiItem {
    type: "UPDATE_TOCHI_ITEM";
    item: ITochiItem;
}

export function updateTochiItem(item: ITochiItem) {
    return { type: UPDATE_TOCHI_ITEM, item };
}

interface RemoveTochiItem {
    type: "REMOVE_TOCHI_ITEM";
    item: ITochiItem;
}

export function removeTochiItem(item: ITochiItem) {
    return { type: REMOVE_TOCHI_ITEM, item };
}

export type FetchTochiItems = () => Promise<any>;
export function fetchTochiItems(query: any = {}) {
    return (dispatch: Dispatch) => {
        let params: any = query;
        return api.tochiItems
            .get(params)
            .then(response => {
                console.log(response.data.items);
                dispatch(setTochiItems(response.data.items));
            })
            .catch(err => {
                Sentry.captureException(err);
                dispatch(setTochiItems([]));
            });
    };
}

export type TochiItemAction =
    | SetTochiItems
    | AddTochiItem
    | UpdateTochiItem
    | RemoveTochiItem;

// Reducer
const initialState: ITochiItem[] = [];

export function tochiItems(state = initialState, action: TochiItemAction) {
    var items;
    switch (action.type) {
        case SET_TOCHI_ITEMS:
            return action.items;
        case ADD_TOCHI_ITEM:
            items = [...state];
            items.push(action.item);
            return items;
        case UPDATE_TOCHI_ITEM:
            return state.map((item: ITochiItem) => {
                if (item._id === action.item._id) {
                    console.log(action.item);
                    return action.item;
                }
                return item;
            });
        case REMOVE_TOCHI_ITEM:
            items = [...state];
            _.remove(items, function (item) {
                return item._id === action.item._id;
            });
            return items;
        default:
            return state;
    }
}

export const getTochiItems = (state: any) => state.tochiItems;
