import React from "react";
import { Button } from "../../../../SharedComponents";
import swal from "sweetalert2";
import _ from "lodash";
import api from "../../../../api";
import { Colors } from "../../../../utils";
import { IUser } from "@snackpass/snackpass-types";
import ReactTooltip from "react-tooltip";

type Props = {
    setAdmin: Function;
    admin: IUser;
};
type State = {
    tabletSerial: string | null;
    editMode: boolean;
    isSaving: boolean;
};

class TabletSerial extends React.Component<Props, State> {
    state: State = {
        tabletSerial: null,
        editMode: false,
        isSaving: false
    };

    handleButtonPress = async () => {
        let { admin } = this.props;
        let { editMode } = this.state;

        // if it is in edit mode,
        // that means should save the new serial number
        // with server
        if (editMode) {
            const { value: shouldUpdate } = await swal.fire({
                title: "Update tablet serial",
                text:
                    "Be sure you know what you're doing! Changing the serial on this document will " +
                    "cause this tablet to authenticate with a different user, which may cause the " +
                    "tablet to no longer work for its curent restaurant.",
                showCancelButton: true,
                confirmButtonText: "Update serial",
                cancelButtonText: "Never mind",
                type: "warning"
            });

            if (!shouldUpdate) {
                return;
            }

            this.setState({ isSaving: true });
            try {
                // save the tablet, this also does validation
                // to make sure the tabletSerial is filled out,
                // and throws an error if it is "" or null
                await this.saveTabletSerial();
                this.setState({ editMode: false });
            } catch (err) {
                swal.fire({
                    title: "Error Saving",
                    text: _.get(err, "response.data.message"),
                    type: "error"
                });
            }
            this.setState({ isSaving: false });
            return;
        }
        // otherwise toggle to edit mode
        // and set the tablet serial number in local state
        this.setState({
            editMode: true,
            tabletSerial: admin.snackpassPermissions.tabletSerial || null
        });
    };

    saveTabletSerial = async () => {
        let { admin } = this.props;
        let { tabletSerial } = this.state;

        // if tabletSerial is empty string set it to null
        // so that the server removes it from the admin document
        if (tabletSerial === "") {
            tabletSerial = null;
        }
        let body = {
            snackpassPermissions: {
                ...admin.snackpassPermissions,
                tabletSerial
            }
        };
        let response = await api.admins.update(admin._id, {
            user: body,
            communicationPreferences: []
        });
        this.props.setAdmin(response.data.user);
    };

    buttonLabel = () => {
        let { editMode } = this.state;
        let { admin } = this.props;
        // if user doesn't have a tablet yet, render add serial number text
        if (!admin.snackpassPermissions.tabletSerial) return "Add Serial";
        // otherwise return edit or save depending on editMode
        return editMode ? "Save" : "Edit";
    };

    render() {
        let { admin } = this.props;
        let { tabletSerial, editMode, isSaving } = this.state;
        return (
            <div>
                {/* if not edit mode, render the admin as it appears in redux */}
                {!editMode ? (
                    <span>{admin.snackpassPermissions.tabletSerial}</span>
                ) : (
                    // otherwise render an input field for user to type serial number
                    <div
                        data-tip={
                            "Do NOT edit this unless you know what you're doing! When in doubt, ask #dev-team 🙏"
                        }
                        data-for={`serialEdit-${tabletSerial}`}
                    >
                        <ReactTooltip
                            id={`serialEdit-${tabletSerial}`}
                            place="top"
                            type="warning"
                            effect="solid"
                            event="mousedown"
                        />
                        <input
                            value={tabletSerial || undefined}
                            onChange={(e) =>
                                this.setState({ tabletSerial: e.target.value })
                            }
                        />
                    </div>
                )}
                {/* if in edit mode, also render a cancel */}
                {editMode && (
                    <Button
                        label={"Cancel"}
                        backgroundColor={Colors.gray}
                        onPress={() => this.setState({ editMode: false })}
                    />
                )}
                <Button
                    loading={isSaving}
                    label={this.buttonLabel()}
                    onPress={this.handleButtonPress}
                />
            </div>
        );
    }
}

export default TabletSerial;
