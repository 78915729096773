import { IRegion } from "@snackpass/snackpass-types";
import { Dispatch } from "redux";
import api from "../api";

import { RegionState, IOption } from "./types";
import { createSelector } from "reselect";

// Constants
export const SET_REGIONS = "SET_REGIONS";
export const SET_REGIONS_LOADED = "SET_REGIONS_LOADED";
export const SET_REGION = "SET_REGION";
export const ADD_REGION = "ADD_REGION";
export const REMOVE_REGION = "REMOVE_REGION";

// Actions
export interface AddRegion {
    type: "ADD_REGION";
    region: IRegion;
}
export function addRegion(region: IRegion) {
    return { type: ADD_REGION, region };
}
export interface RemoveRegion {
    type: "REMOVE_REGION";
    region: string;
}
export function removeRegion(region: string) {
    return { type: REMOVE_REGION, region };
}
export interface SetRegions {
    type: "SET_REGIONS";
    regions: IRegion[];
}
export function setRegions(regions: IRegion[]) {
    return { type: SET_REGIONS, regions };
}
export interface SetRegion {
    type: "SET_REGION";
    region: IRegion;
}
export function setRegion(region: IRegion) {
    return { type: SET_REGION, region };
}
export interface SetRegionsLoaded {
    type: "SET_REGIONS_LOADED";
    regionsLoaded: boolean;
}
export function setRegionsLoaded(regionsLoaded: boolean) {
    return { type: SET_REGIONS_LOADED, regionsLoaded };
}

// Reducer
const initialState: IRegion[] = [];

export type RegionAction = SetRegions | SetRegionsLoaded | SetRegion;

export type FetchRegions = () => Promise<any>;

export function fetchRegions() {
    return (dispatch: Dispatch) => {
        return api.regions
            .get({ populateNumStores: true, discardArchivedRegions: true })
            .then(response => {
                dispatch(setRegions(response.data.regions));
            })
            .catch(err => {
                dispatch(setRegions([]));
            });
    };
}

export function regions(state = initialState, action: RegionAction) {
    switch (action.type) {
        case SET_REGIONS:
            return action.regions;
        case SET_REGION:
            return state.map(r => {
                if (r._id === action.region._id) {
                    return { ...r, ...action.region };
                }
                return r;
            });
        default:
            return state;
    }
}

export function regionsLoaded(state = false, action: RegionAction) {
    switch (action.type) {
        case SET_REGIONS:
            return true;
        case SET_REGIONS_LOADED:
            return action.regionsLoaded;
        default:
            return state;
    }
}

export const getRegions = (state: RegionState): IRegion[] => state.regions;

export const getRegionOptions = createSelector([getRegions], regions =>
    regions.map((r: IRegion) => ({ label: r.name, value: r.slug } as IOption))
);

export const getRegionOptionsWitTaxRate = (state: RegionState) =>
    state.regions.map((r: IRegion) => ({
        label: `${r.name} - ${r.taxRate} %`,
        value: r.slug,
    }));

export const getRegionsLoaded = (state: RegionState) => state.regionsLoaded;
