import React from "react";
import { Moment } from "moment-timezone";
import { DateRangePicker } from "react-dates";

const START_DATE_INPUT_ID = "START_DATE";
const END_DATE_INPUT_ID = "END_DATE";

type Props = {
    startDate?: Moment;
    endDate?: Moment;
    handleUpdateDateRange: ({
        startDate,
        endDate,
    }: {
        startDate: Moment;
        endDate: Moment;
    }) => void;
};

type State = {
    focusedDateInput: string | null;
};

export default class DateRangeSelector extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            focusedDateInput: null,
        };
    }

    onFocusChange = (focusedDateInput: string | null) =>
        this.setState({ focusedDateInput });

    render() {
        return (
            <DateRangePicker
                startDate={this.props.startDate}
                startDateId={START_DATE_INPUT_ID}
                endDate={this.props.endDate}
                endDateId={END_DATE_INPUT_ID}
                onDatesChange={this.props.handleUpdateDateRange}
                focusedInput={this.state.focusedDateInput}
                onFocusChange={this.onFocusChange}
                horizontalMargin={60}
                isOutsideRange={() => false}
            />
        );
    }
}
