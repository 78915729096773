import React from "react";
import { IStore } from "@snackpass/snackpass-types";
import { Helpers, Colors } from "../../../utils";
import slugify from "slugify";
import swal from "sweetalert2";
import API from "../../../api";
import { setStore } from "../../../redux/stores";

export function generateStoreRowDropdownOptions(storeObj: IStore) {
    if (storeObj.isArchived) {
        return [
            {
                label: (
                    <div>
                        See History <i className="fas fa-history" />
                    </div>
                ),
                value: "CHANGE_LOG",
            },
            {
                label: (
                    <div>
                        {storeObj.isArchived ? "Un-archive" : "Archive"}{" "}
                        <i className="fas fa-archive" />
                    </div>
                ),
                value: "ARCHIVE",
            },
        ];
    }
    return [
        {
            label: <div>Edit</div>,
            value: "EDIT",
        },
        {
            label: <div>Store Admins</div>,
            value: "ADMINS",
        },
        {
            label: <div>Pause / Rush Hour</div>,
            value: "PAUSE_RUSH_HOUR",
        },
        {
            label: (
                <div>
                    See History <i className="fas fa-history" />
                </div>
            ),
            value: "CHANGE_LOG",
        },
        {
            label: (
                <span>
                    Copy Order Link <i className="fas fa-link" />
                </span>
            ),
            value: "COPY_LINK",
        },
        {
            label: <span>Copy Store ID</span>,
            value: "COPY_STORE_ID",
        },
        {
            label: (
                <div>
                    {storeObj.isArchived ? "Un-archive" : "Archive"}{" "}
                    <i className="fas fa-archive" />
                </div>
            ),
            value: "ARCHIVE",
        },
        ...(storeObj.posIntegrations?.deliverect.enabled
            ? [
                  {
                      label: <div>Push menu to Deliverect</div>,
                      value: "PUSH_MENU_DELIVERECT",
                  },
              ]
            : []),
    ];
}

function _generateOnlineOrderingLink(storeObj: IStore): string {
    const slug = slugify(storeObj.name);
    return `https://order.snackpass.co/${slug}-${storeObj._id}`;
}

export async function handleSelectDropdownOption(
    item: any,
    storeObj: IStore,
    showModal: (modal: string, props?: any) => void,
    onArchiveStore: any
) {
    let value = item && item.value ? item.value : null;

    if (value === "EDIT") {
        API.stores
            .getById(storeObj._id)
            .then(res => {
                const storeObj = res.data.store;
                setStore(storeObj);
                showModal("StoreFormModal", {
                    storeObj,
                    editMode: true,
                });
            })
            .catch(err => {
                console.warn(err);
            });
    } else if (value === "ADMINS") {
        showModal("AdminModal", {
            storeObj,
            editMode: true,
        });
    } else if (value === "CHANGE_LOG") {
        showModal("StoreChangesModal", {
            storeObj,
        });
    } else if (value === "PAUSE_RUSH_HOUR") {
        showModal("StorePauseModal", {
            store: storeObj,
        });
    } else if (value === "COPY_LINK") {
        let onlineOrderLink = _generateOnlineOrderingLink(storeObj);
        Helpers.copyToClipboard(onlineOrderLink);
        swal.fire(
            "Copied to clipboard!",
            `Copied link to your clipboard, so now you can email or text it to the restaurant owner. \n \n  (The link is ${onlineOrderLink})`,
            "success"
        );
    } else if (value === "COPY_STORE_ID") {
        Helpers.copyToClipboard(storeObj._id);
        swal.fire(
            "Copied to clipboard!",
            `Copied the store's ID to your clipboard. \n \n  (The ID is ${storeObj._id})`,
            "success"
        );
    } else if (value === "ARCHIVE") {
        if (storeObj.isLive) {
            swal.fire(
                "Cannot Archive",
                `You cannot archive a store that is live. If you would like to archive this store, please turn it off of live mode, and then archive it.`,
                "error"
            );
            return;
        }
        // Confirm that user wants to archive the store
        const result = await swal.fire({
            title: `Are you sure you want to ${
                storeObj.isArchived ? "un-archive" : "archive"
            } ${storeObj.name || "this store"}?`,
            text: storeObj.isArchived
                ? `This will mark the store as un-archived and add it back to stores view. Un-archiving a store DOES NOT make the store live.`
                : `This will mark the store as archived and remove it from the stores view. To see archived stores, you need to scroll to the bottom of the stores page to the "Archive Stores" section and fetch archived stores.`,
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: Colors.green,
            cancelButtonColor: Colors.gray,
            confirmButtonText: `${
                storeObj.isArchived ? "Un-archive" : "Archive"
            } store`,
            cancelButtonText: "Cancel",
            focusCancel: true,
        });

        if (!result.value) {
            return;
        }
        onArchiveStore();
    } else if (value === "PUSH_MENU_DELIVERECT") {
        try {
            await API.stores.pushMenu(storeObj._id, {
                publishMenu: true,
            });
            swal.fire(
                "Pushed menu!",
                `${storeObj.name} menu is now synced on the Deliverect platform.`,
                "success"
            );
        } catch (err) {
            console.warn(err);
            swal.fire(
                "Error pushing menu!",
                `Failed reaching the server while syncing ${storeObj.name} menu.`,
                "error"
            );
        }
    }
}
