import React from "react";
import ReactTable, { CellInfo } from "react-table";
import { Text, TouchableHighlight, Button } from "../../../../SharedComponents";
import TabletSerial from "./TabletSerial";
import Password from "./Password";
import CopyPasswordResetLink from "../../../../containers/CopyPasswordResetLink";
import _ from "lodash";
import Select from "react-select";
import { Colors } from "../../../../utils";
import { IOption } from "../../../../redux/types";
import api from "../../../../api";
import { IUser } from "@snackpass/snackpass-types";
import moment from "moment";
import Swal from "sweetalert2";
import {
    getOwnedStores,
    getPayoutsForStores,
    getSelectedStores
} from "./helpers";
import { permissionsColumn } from "./Permissions";
import { storesNamesMap } from "src/components/AdminForm/types";

type Props = {
    admins: IUser[];
    loading: boolean;
    storeOptions: IOption[];
    storeNameById: storesNamesMap;
    removeAdmin: Function;
    setAdmin: Function;
};
type State = {};

class AdminsTable extends React.Component<Props, State> {
    handleSerialChange = (newSerial: string) => {
        this.setState({ serialNumber: newSerial });
    };
    handlePressDelete = async (admin: IUser) => {
        if (window.confirm("Remove admin?")) {
            let body = {
                snackpassPermissions: {
                    isAdmin: false,
                    team: false,
                    isSnackpassEmployee: false,
                    menu: false,
                    storeIds: []
                }
            };
            await api.admins.update(admin._id, {
                user: body,
                communicationPreferences: []
            });
            this.props.removeAdmin(admin._id);
        }
    };
    handleToggleTeam = async (admin: IUser) => {
        let body = {
            snackpassPermissions: {
                ...admin.snackpassPermissions,
                isSnackpassEmployee:
                    !admin.snackpassPermissions.isSnackpassEmployee
            }
        };
        try {
            let response = await api.admins.update(admin._id, {
                user: body,
                communicationPreferences: []
            });
            this.props.setAdmin(response.data.user);
        } catch (e) {
            Swal.fire({
                type: "error",
                text: e.response.data.message
            });
        }
    };
    handleSelectStoreId = async (
        admin: IUser,
        storeIds: { value: string }[]
    ) => {
        const newStoreIds = storeIds
            ? storeIds.filter((item) => item.value).map((item) => item.value)
            : [];

        let body = {
            snackpassPermissions: {
                ...admin.snackpassPermissions,
                storeIds: newStoreIds
            }
        };
        let response = await api.admins.update(admin._id, {
            user: body,
            communicationPreferences: []
        });
        this.props.setAdmin(response.data.user);
    };

    handleSelectOwnerStoreId = async (
        admin: IUser,
        storeIds: { value: string }[]
    ) => {
        let body = {
            permissions: {
                ...admin.permissions,
                isOwner: storeIds
                    ? storeIds
                          .filter((item) => item.value)
                          .map((item) => item.value)
                    : []
            }
        };
        let response = await api.admins.update(admin._id, {
            user: body,
            communicationPreferences: []
        });
        this.props.setAdmin(response.data.user);
    };

    handleSelectPayoutsStoreId = async (
        admin: IUser,
        storeIds: { value: string }[]
    ) => {
        let body = {
            permissions: {
                ...admin.permissions,
                hasPayouts: storeIds
                    ? storeIds
                          .filter((item) => item.value)
                          .map((item) => item.value)
                    : []
            }
        };
        let response = await api.admins.update(admin._id, {
            user: body,
            communicationPreferences: []
        });
        this.props.setAdmin(response.data.user);
    };

    nameColumn = () => ({
        Header: "Name",
        id: "user_name",
        accessor: "name",
        minWidth: 120,
        Filter: ({ onChange, filter }: any) => {
            return (
                <input
                    placeholder="Search name"
                    value={filter ? filter.value : ""}
                    onChange={(e) => onChange(e.target.value)}
                    style={{ width: 120 }}
                />
            );
        },
        Cell: (props: CellInfo) => (
            <div>
                <Text small>{props.value}</Text>
            </div>
        ),
        filterable: true,
        style: { whiteSpace: "unset" },
        filterMethod: (filter: any, row: any, column: any) => {
            const name: string = _.get(row, "user_name", "") ?? "";
            const value: string | null = _.get(filter, "value", "");
            if (value) {
                return name.toLowerCase().includes(value.toLowerCase());
            }
            return true;
        }
    });

    emailColumn = () => ({
        Header: "Email",
        id: "user_email",
        accessor: "email",
        minWidth: 200,
        Filter: ({ onChange, filter }: any) => {
            return (
                <input
                    placeholder="Search email"
                    value={filter ? filter.value : ""}
                    onChange={(e) => onChange(e.target.value)}
                />
            );
        },
        Cell: (props: CellInfo) => (
            <div>
                <Text small>{props.value}</Text>
                <CopyPasswordResetLink admin={props.original} />
            </div>
        ),
        filterable: true,
        style: { whiteSpace: "unset" },
        filterMethod: (filter: any, row: any, column: any) => {
            let email: string = _.get(row, "user_email", "");
            let value: string | null = _.get(filter, "value", "");
            if (value) {
                return email.toLowerCase().includes(value.toLowerCase());
            }
            return true;
        }
    });

    passwordColumn = (setAdmin: Function) => ({
        Header: "Password",
        id: "admin_password",
        accessor: "snackpassPermissions.adminPassword",
        minWidth: 250,
        Filter: ({ onChange, filter }: any) => {
            return (
                <input
                    placeholder="Search password"
                    value={filter ? filter.value : ""}
                    onChange={(e) => onChange(e.target.value)}
                />
            );
        },
        filterable: true,
        filterMethod: (filter: any, row: any, column: any) => {
            let value: string = _.get(filter, "value", "").toLowerCase();
            if (value) {
                return (row.admin_password || "").toLowerCase().includes(value);
            }
        },
        Cell: (props: CellInfo) => (
            <Password setAdmin={setAdmin} admin={props.original} />
        )
    });
    storesColumn = (
        storeNameById: storesNamesMap,
        storeOptions: IOption[]
    ) => ({
        Header: "Admin of Stores",
        minWidth: 200,
        id: "store_permissions",
        accessor: "snackpassPermissions.storeIds",
        Cell: (props: CellInfo) => (
            <Select
                name="type"
                placeholder="select stores"
                value={getSelectedStores(props.original, storeNameById)}
                isMulti
                styles={{
                    // this is needed or else the options are behind
                    // the table and cannot select them
                    menu: () => ({}),
                    multiValueLabel: () => ({
                        whiteSpace: "normal"
                    })
                }}
                isClearable={false}
                options={storeOptions}
                className="snackpass__react-select"
                onChange={(storeIds: any) =>
                    this.handleSelectStoreId(props.original, storeIds)
                }
            />
        ),
        Filter: ({ onChange, filter }: any) => {
            return (
                <input
                    placeholder="Search store"
                    value={filter ? filter.value : ""}
                    onChange={(e) => onChange(e.target.value)}
                />
            );
        },
        filterable: true,
        style: { whiteSpace: "unset" },
        filterMethod: (filter: any, row: any, column: any) => {
            let value: string = _.get(filter, "value", "").toLowerCase();
            if (value) {
                let nameMatches = getSelectedStores(
                    row._original,
                    storeNameById
                ).reduce((acc: boolean, option: IOption) => {
                    if (option.label) {
                        return (
                            acc || option.label.toLowerCase().includes(value)
                        );
                    }
                    return acc;
                }, false);
                return nameMatches;
            }
            return true;
        }
    });
    teamPermissionColumn = () => ({
        Header: "SP Employee",
        id: "snackpass_team_permission",
        accessor: "snackpassPermissions.isSnackpassEmployee",
        Cell: (props: CellInfo) => {
            let team = _.get(
                props,
                "original.snackpassPermissions.isSnackpassEmployee",
                false
            );
            return (
                <TouchableHighlight
                    style={{
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                    onPress={() => this.handleToggleTeam(props.original)}
                >
                    <Text color={team ? Colors.green : Colors.gray}>
                        SP Employee
                    </Text>
                </TouchableHighlight>
            );
        }
    });

    ownerPermissionColumn = (
        storeNameById: storesNamesMap,
        storeOptions: IOption[]
    ) => ({
        Header: "Owner of Stores",
        minWidth: 200,
        id: "snackpass_owner_permission",
        accessor: "permissions.isOwner",
        Cell: (props: CellInfo) => {
            return (
                <Select
                    name="type"
                    placeholder="select stores"
                    value={getOwnedStores(props.original, storeNameById)}
                    isMulti
                    styles={{
                        // this is needed or else the options are behind
                        // the table and cannot select them
                        menu: () => ({}),
                        multiValueLabel: () => ({
                            whiteSpace: "normal"
                        })
                    }}
                    isClearable={false}
                    options={storeOptions}
                    className="snackpass__react-select"
                    onChange={(storeIds: any) => {
                        this.handleSelectOwnerStoreId(props.original, storeIds);
                    }}
                />
            );
        }
    });

    payoutsPermissionColumn = (
        storeNameById: storesNamesMap,
        storeOptions: IOption[]
    ) => ({
        Header: "Payouts Permission for Stores",
        minWidth: 200,
        id: "snackpass_payouts_permission",
        accessor: "permissions.hasPayouts",
        Cell: (props: CellInfo) => {
            return (
                <Select
                    name="type"
                    placeholder="select stores"
                    value={getPayoutsForStores(props.original, storeNameById)}
                    isMulti
                    styles={{
                        // this is needed or else the options are behind
                        // the table and cannot select them
                        menu: () => ({}),
                        multiValueLabel: () => ({
                            whiteSpace: "normal"
                        })
                    }}
                    isClearable={false}
                    options={storeOptions}
                    className="snackpass__react-select"
                    onChange={(storeIds: any) => {
                        this.handleSelectPayoutsStoreId(
                            props.original,
                            storeIds
                        );
                    }}
                />
            );
        }
    });

    serialNumberColumn = (setAdmin: Function) => ({
        Header: "Serial #",
        minWidth: 120,
        id: "snackpass_serial",
        accessor: "snackpassPermissions.tabletSerial",
        Cell: (props: CellInfo) => (
            <TabletSerial setAdmin={setAdmin} admin={props.original} />
        ),
        Filter: ({ onChange, filter }: any) => {
            return (
                <input
                    placeholder="Search serial"
                    value={filter ? filter.value : ""}
                    onChange={(e) => onChange(e.target.value)}
                />
            );
        },
        filterable: true,
        filterMethod: (filter: any, row: any, column: any) => {
            let value: string = _.get(filter, "value", "").toLowerCase();
            if (value) {
                return (row.snackpass_serial || "")
                    .toLowerCase()
                    .includes(value);
            }
        }
    });
    deleteColumn = () => ({
        Header: "",
        id: "delete_column",
        Cell: (props: CellInfo) => (
            <Button
                backgroundColor={Colors.red}
                label={
                    <span>
                        Remove from
                        <br /> admins
                    </span>
                }
                onPress={() => this.handlePressDelete(props.original)}
            />
        ),
        sortable: false
    });
    updatedAtColumn = () => {
        return {
            Header: "Updated At",
            id: "updated_at",
            accessor: "updatedAt",
            Cell: (props: CellInfo) => (
                <span>
                    {moment(props.value).format("h:mm a")} <br />
                    {moment(props.value).format("M/DD/YYYY")}
                </span>
            ),
            sortable: true
        };
    };

    buildColumns = () => {
        let { storeOptions, storeNameById, setAdmin } = this.props;
        return [
            this.nameColumn(),
            this.emailColumn(),
            this.passwordColumn(setAdmin),
            this.storesColumn(storeNameById, storeOptions),
            this.teamPermissionColumn(),
            this.ownerPermissionColumn(storeNameById, storeOptions),
            this.payoutsPermissionColumn(storeNameById, storeOptions),
            permissionsColumn(this.props.setAdmin, storeNameById),
            this.serialNumberColumn(setAdmin),
            this.deleteColumn(),
            this.updatedAtColumn()
        ];
    };

    render() {
        let { admins, loading }: Props = this.props;
        return (
            <ReactTable
                style={{ width: "100%" }}
                data={admins}
                loading={loading}
                className="-striped scrollable-x"
                defaultPageSize={20}
                defaultSorted={[
                    {
                        // the sorting model for the table
                        id: "createdAt",
                        desc: true
                    }
                ]}
                columns={this.buildColumns()}
            />
        );
    }
}

export default AdminsTable;
