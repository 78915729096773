import React from "react";
import { useSelector } from "react-redux";
import useClipboard from "react-use-clipboard";
import { propOr } from "lodash/fp";
import QRCode from "qrcode.react";

import { getActiveStore } from "../../redux/stores";
import { Header } from "./components";
import { Button, Text } from "../../SharedComponents";
import { Colors, QrCodes } from "../../utils";

const QrCodeForm = () => {
    const activeStore = useSelector(getActiveStore);
    const storeId = propOr("", "_id", activeStore);
    const storeName = propOr("", "name", activeStore);
    const storeRegion = propOr("", "region", activeStore);
    const storeLink = `https://order.snackpass.co/${storeId}?utm_source=qr`;
    const [isCopied, setCopied] = useClipboard(storeLink);

    return (
        <div style={styles.contentWrap}>
            <Header label="Qr Code" />
            {activeStore && storeId !== "" && (
                <div style={styles.qrWrap}>
                    <Text xlarge>{storeName + " 😋"}</Text>
                    <br />
                    <Text large>{storeRegion + " 🌎"}</Text>
                    <div style={styles.divider}></div>
                    <QRCode
                        id={storeLink}
                        value={storeLink}
                        size={160}
                        style={styles.qrCanvas}
                    />
                    <div style={styles.divider} />
                    <Button
                        style={styles.downloadButton}
                        label="Download QR Code"
                        onPress={() =>
                            QrCodes.downloadQR(activeStore.name, storeLink)
                        }
                    />
                    <div style={styles.divider} />
                    <Button
                        style={styles.copyButton}
                        label={isCopied ? "Copied 😊" : "Copy QR Code Link"}
                        onPress={setCopied}
                    />
                </div>
            )}
        </div>
    );
};

export default QrCodeForm;

const styles = {
    contentWrap: {
        marginTop: 40,
        marginBottom: 60,
        width: "100%",
        textAlign: "left",
    } as React.CSSProperties,
    qrWrap: {
        marginTop: 60,
        width: "100%",
        textAlign: "center",
    } as React.CSSProperties,
    qrCanvas: {
        marginTop: 30,
        width: 160,
    } as React.CSSProperties,
    divider: {
        width: "100%",
    } as React.CSSProperties,
    downloadButton: {
        marginTop: 30,
        width: 160,
    } as React.CSSProperties,
    copyButton: {
        marginTop: 10,
        width: 160,
    } as React.CSSProperties,
    link: {
        color: Colors.blue,
        cursor: "pointer",
    } as React.CSSProperties,
};
