import { Formik, FormikActions, Form, Field, FieldProps } from "formik";
import { curry } from "lodash/fp";
import React from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import * as Yup from "yup";
import cuid from "cuid";
import { PrinterFormat } from "@snackpass/snackpass-types";
import Select from "react-select";

import api from "../../api";

import { Button } from "../../SharedComponents";

import { alertAxiosError, getPathFromState } from "../../utils/Helpers";
import _ from "lodash";

import { refreshPrinters } from "./utils";
import { SelectOption } from "src/shared/types";
import { View } from "src/components";

type CreatePrinterFormValues = {
    serial: string;
    format: PrinterFormat;
};

const INITIAL_VALUES: CreatePrinterFormValues = {
    serial: "",
    format: PrinterFormat.RECEIPT
};

const PRINTER_FORMAT_OPTIONS: SelectOption<PrinterFormat>[] = [
    { label: "Receipt", value: PrinterFormat.RECEIPT },
    { label: "Label", value: PrinterFormat.LABEL_IMAGE },
    { label: "Chit", value: PrinterFormat.CHIT },
    { label: "Customer", value: PrinterFormat.CUSTOMER }
];

const CreatePrinterForm = () => {
    const dispatch = useDispatch();
    const selectedStoreId = getPathFromState("printers.selectedStoreId");
    const selectedFoodhallId = getPathFromState("printers.selectedFoodhallId");

    const _onSubmit = (
        values: CreatePrinterFormValues,
        actions: FormikActions<CreatePrinterFormValues>
    ) =>
        onSubmit({
            dispatch,
            storeId: selectedStoreId,
            foodhallId: selectedFoodhallId,
            values,
            actions
        });

    return (
        <Formik
            initialValues={INITIAL_VALUES}
            validationSchema={validationSchema}
            onSubmit={_onSubmit}
        >
            <Form onKeyPress={ignoreEnterKey}>
                <View style={styles.dropdownContainer}>
                    <View style={styles.dropdownRow}>
                        <Field name="serial" render={renderSerialInput} />
                        <Field
                            name="format"
                            render={renderPrinterFormatInput}
                        />
                    </View>
                </View>
                <Button
                    style={styles.button}
                    label="Register New Printer"
                    type="submit"
                    disabled
                />
            </Form>
        </Formik>
    );
};

const validationSchema = Yup.object().shape({
    serial: Yup.string().trim().required("Serial is required")
});

const onSubmit = curry(
    (params: {
        dispatch: Dispatch;
        storeId?: string;
        foodhallId?: string;
        values: CreatePrinterFormValues;
        actions: FormikActions<CreatePrinterFormValues>;
    }) => {
        alert("Sorry, please use RDB devices page to create printers.");
    }
);

const renderSerialInput = ({
    field,
    form
}: FieldProps<CreatePrinterFormValues>) => (
    <div>
        <input
            style={styles.input}
            type="text"
            {...field}
            placeholder="Enter Serial Number..."
        />
        <span style={styles.errorMessage}>
            {form.touched.serial && form.errors.serial}
        </span>
    </div>
);

const renderPrinterFormatInput = ({
    field,
    form
}: FieldProps<CreatePrinterFormValues>) => (
    <div>
        <Select
            options={PRINTER_FORMAT_OPTIONS}
            name={field.name}
            value={PRINTER_FORMAT_OPTIONS.find(
                (option) => option.value === field.value
            )}
            onChange={(option: SelectOption<PrinterFormat>) =>
                form.setFieldValue(field.name, option.value)
            }
            onBlur={field.onBlur}
            style={styles.input}
        />
        <span style={styles.errorMessage}>
            {form.touched.format && form.errors.format}
        </span>
    </div>
);

const ignoreEnterKey = (e: { key: string; preventDefault: Function }) => {
    if (e.key === "Enter") {
        e.preventDefault();
    }
};

const styles = {
    input: {
        marginBottom: "10px",
        marginTop: "10px",
        width: "200px"
    } as React.CSSProperties,
    dropdownContainer: {
        flexDirection: "row"
    } as React.CSSProperties,
    dropdownRow: {
        width: "200px"
    } as React.CSSProperties,
    button: {
        marginLeft: "0px",
        width: "200px"
    } as React.CSSProperties,
    errorMessage: {
        color: "red",
        marginLeft: "10px"
    } as React.CSSProperties
};

export default CreatePrinterForm;
