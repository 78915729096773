import { IUser } from "@snackpass/snackpass-types";
import _ from "lodash";
import * as Yup from "yup";

import * as Helpers from "../../utils/Helpers";
import { FormValues } from "./types";
import {
    ADMIN_FORM_INTERNAL_TEMPLATE,
    ADMIN_TEMPLATE
} from "../../utils/Templates";

export function initializeFields(admin?: IUser, storeId?: string): FormValues {
    if (!admin) {
        return { ...ADMIN_TEMPLATE, internal: ADMIN_FORM_INTERNAL_TEMPLATE };
    }

    //get store role from storeId
    const storeRoles = _.get(admin, "snackpassPermissions.storeRoles", []);
    const storeRole = storeRoles.find(
        (user: any) => user.storeId && user.storeId === storeId
    );
    const role = _.get(storeRole, "role", "");

    return {
        firstName: _.get(admin, "firstName") || ADMIN_TEMPLATE.firstName,
        lastName: _.get(admin, "lastName") || ADMIN_TEMPLATE.lastName,
        email: _.get(admin, "email", ADMIN_TEMPLATE.email),
        number: _.get(admin, "number", ADMIN_TEMPLATE.number),
        snackpassPermissions: {
            accounting: _.get(
                admin,
                "snackpassPermissions.accounting",
                ADMIN_TEMPLATE.snackpassPermissions.accounting
            ),
            isSnackpassEmployee: _.get(
                admin,
                "snackpassPermissions.isSnackpassEmployee",
                ADMIN_TEMPLATE.snackpassPermissions.isSnackpassEmployee
            ),
            storeRole: role
        },
        permissions: {
            isOwner: admin.permissions?.isOwner ?? []
        },
        disabledSmsCommunications: _.get(
            admin,
            "disabledSmsCommunications",
            ADMIN_TEMPLATE.disabledSmsCommunications
        ),
        disabledEmailCommunications: _.get(
            admin,
            "disabledEmailCommunications",
            ADMIN_TEMPLATE.disabledEmailCommunications
        ),
        internal: ADMIN_FORM_INTERNAL_TEMPLATE
    };
}

export const validationSchema = Yup.object().shape({
    editMode: Yup.boolean(),
    storeObj: Yup.mixed(),
    firstName: Yup.string().trim().required("First name is required"),
    lastName: Yup.string().trim().required("Last name is required"),
    email: Yup.string().email().trim().required("Email is required"),
    number: Yup.string()
        .trim()
        .nullable()
        .test({
            name: "Phone number",
            message: "Phone number is not valid",
            test: (value: string): boolean => {
                if (!value) {
                    return true;
                }

                const normalizedPhoneNumber =
                    Helpers.normalizePhoneNumber(value);
                return Helpers.isTruthy(normalizedPhoneNumber);
            }
        })
});
