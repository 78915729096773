import { connect } from "react-redux";
import StoreChainModal from "../modals/StoreChainModal";
import { StoreState } from "../redux/types";
import { Dispatch } from "redux";

const mapStateToProps = (state: StoreState) => {
    return {};
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        dispatch,
    };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(StoreChainModal);
