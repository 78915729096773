import { SystemColors } from "@snackpass/design-system";
import React, { useState } from "react";
import { PulseLoader } from "react-spinners";
import ReactTable from "react-table";

import API from "../api";

const Styles = {
    button: {
        backgroundColor: SystemColors.v1.snackpassPrint,
        color: SystemColors.v1.white,
        marginBottom: 10,
    },
    errorMessage: { color: SystemColors.v1.dragonfruit100 },
    table: { textAlign: "center" },
};

// This mirrors how Payout info is store in redis on the backend.
export enum PayoutStatus {
    NeedsApproval,
    Approved,
    Error,
}

export type PayoutT = {
    status: PayoutStatus;
    payoutId: string;
    createdAt: Date;
    lastUpdated: Date;
    amount: string;
    description: string;
    error: string | null;
};

export const compare = (payoutA: PayoutT, payoutB: PayoutT): number =>
    new Date(payoutB.lastUpdated).valueOf() -
    new Date(payoutA.lastUpdated).valueOf();

type ListPayoutsResponse = {
    payouts: PayoutT[];
    stillWorking: boolean;
};

const isListPayoutsResponse = (
    maybeResponse: any
): maybeResponse is ListPayoutsResponse =>
    (maybeResponse as ListPayoutsResponse).payouts !== undefined &&
    (maybeResponse as ListPayoutsResponse).stillWorking !== undefined;

enum Status {
    NotStarted,
    SyncingPayouts,
    AwaitingApproval,
    ApprovingPayouts,
    Done,
    Error,
}

enum ActionType {
    ClickedSync,
    ClickedApprove,
    SyncedLastPayout,
    ApprovedLastPayout,
    GotPayouts,
    StartedPoller,
    StoppedPoller,
    HitError,
}

type ClickedSyncAction = { type: ActionType.ClickedSync };
type ClickedApproveAction = { type: ActionType.ClickedApprove };
type SyncedLastPayoutAction = { type: ActionType.SyncedLastPayout };
type ApprovedLastPayoutAction = { type: ActionType.ApprovedLastPayout };
type GotPayoutsAction = { type: ActionType.GotPayouts; payouts: PayoutT[] };
type StartedPollerAction = { type: ActionType.StartedPoller; pollerId: number };
type StoppedPollerAction = { type: ActionType.StoppedPoller };
type HitErrorAction = { type: ActionType.HitError };

type Action =
    | ClickedSyncAction
    | ClickedApproveAction
    | SyncedLastPayoutAction
    | ApprovedLastPayoutAction
    | GotPayoutsAction
    | StartedPollerAction
    | StoppedPollerAction
    | HitErrorAction;

type State = {
    status: Status;
    payouts: PayoutT[];
    pollerId: number | null;
};

const initialState: State = {
    status: Status.NotStarted,
    payouts: [],
    pollerId: null,
};

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case ActionType.ClickedSync:
            return { ...state, status: Status.SyncingPayouts };
        case ActionType.ClickedApprove:
            return { ...state, status: Status.ApprovingPayouts };
        case ActionType.SyncedLastPayout:
            return { ...state, status: Status.AwaitingApproval };
        case ActionType.ApprovedLastPayout:
            return { ...state, status: Status.Done };
        case ActionType.GotPayouts:
            return { ...state, payouts: action.payouts };
        case ActionType.StartedPoller:
            return { ...state, pollerId: action.pollerId };
        case ActionType.StoppedPoller:
            return { ...state, pollerId: null };
        case ActionType.HitError:
            return { ...state, status: Status.Error };
    }
};

const Payouts = () => {
    const [state, dispatch] = React.useReducer(reducer, initialState);

    const logAndDispatchError = (error: any | null): void => {
        if (error) {
            console.error(error);
        }

        dispatch({ type: ActionType.HitError });
    };

    const startPoller = (stopAction: Action) => {
        const handleApiResp = (payoutsJson: ListPayoutsResponse | Error) => {
            if (isListPayoutsResponse(payoutsJson)) {
                const { payouts, stillWorking } = payoutsJson;

                payouts.sort(compare);

                const foundError = payouts.some(
                    p => p.status === PayoutStatus.Error
                );

                if (foundError) {
                    // We pass None to logAndDispatchError here since there's
                    // no actual error object to deal with, and we'll display
                    // the error message next to the corresponding payout in
                    // the payouts table.
                    logAndDispatchError(null);
                }

                dispatch({ type: ActionType.GotPayouts, payouts });

                if (!stillWorking) {
                    dispatch(stopAction);
                }
            } else {
                logAndDispatchError(payoutsJson);
            }
        };

        const pollerId = setInterval(() => {
            API.pendingPayouts
                .list()
                .then(res => handleApiResp(res.data))
                .catch(handleApiResp);
        }, 5000) as unknown as number;

        dispatch({ type: ActionType.StartedPoller, pollerId });
    };

    const { status, pollerId } = state;

    React.useEffect(() => {
        if (status === Status.SyncingPayouts && !pollerId) {
            API.pendingPayouts.sync().then(() => {
                startPoller({ type: ActionType.SyncedLastPayout });
            });
        } else if (status === Status.ApprovingPayouts && !pollerId) {
            API.pendingPayouts
                .approve()
                .then(() => {
                    startPoller({ type: ActionType.ApprovedLastPayout });
                })
                .catch(logAndDispatchError);
        } else if (
            Boolean(pollerId) &&
            [
                Status.NotStarted,
                Status.AwaitingApproval,
                Status.Done,
                Status.Error,
            ].includes(status)
        ) {
            clearInterval(pollerId!);
            dispatch({ type: ActionType.StoppedPoller });
        }
    }, [status, pollerId]);

    let statusMessage: JSX.Element | null | undefined;
    let actionButton: JSX.Element | null | undefined;

    if (state.status === Status.NotStarted) {
        statusMessage = null;
        actionButton = (
            <button
                style={Styles.button}
                onClick={_ => dispatch({ type: ActionType.ClickedSync })}
            >
                Sync Payouts
            </button>
        );
    } else if (state.status === Status.SyncingPayouts) {
        statusMessage = (
            <div>
                <h2>Syncing Payouts</h2>
                <PulseLoader />
            </div>
        );
        actionButton = null;
    } else if (state.status === Status.AwaitingApproval) {
        statusMessage = null;
        actionButton = (
            <button
                style={Styles.button}
                onClick={_ => dispatch({ type: ActionType.ClickedApprove })}
            >
                Approve Payouts
            </button>
        );
    } else if (state.status === Status.ApprovingPayouts) {
        statusMessage = (
            <div>
                <h2> Approving Payouts </h2>
                <PulseLoader />
            </div>
        );
        actionButton = null;
    } else if (state.status === Status.Done) {
        statusMessage = <h2>Done!</h2>;
        actionButton = null;
    } else if (state.status === Status.Error) {
        statusMessage = (
            <h2 style={Styles.errorMessage}>
                Something went wrong
                <br />
                See the error column below for more information.
            </h2>
        );
    }

    const columns = [
        { Header: "ID", accessor: "payoutId" },
        { Header: "Description", accessor: "description" },
        { Header: "Amount", accessor: "amount" },
        { Header: "Status", accessor: "status" },
        { Header: "Error", accessor: "error" },
        { Header: "CreatedAt", accessor: "createdAt" },
        { Header: "LastUpdated", accessor: "lastUpdated" },
    ];

    const data = state.payouts;

    return (
        <div>
            <h1>Payouts 💸</h1>
            {actionButton}
            {statusMessage}
            <ReactTable columns={columns} data={data} style={Styles.table} />
        </div>
    );
};

export default Payouts;
