import _ from "lodash";
import { IStore, IUser } from "@snackpass/snackpass-types";
import React from "react";
import ReactTable, { CellInfo } from "react-table";
import swal from "sweetalert2";
import api from "../../../api";
import CopyPasswordResetLink from "../../../containers/CopyPasswordResetLink";
import { Colors } from "../../../utils/Colors";
import * as Helpers from "../../../utils/Helpers";
import Password from "../../../screens/Admins/components/AdminsTable/Password";
import { Button, Text } from "../../../SharedComponents";

type Props = {
    storeObj?: IStore;
    admins: IUser[];
    loadingAdmins: boolean;
    setAdmin: Function;
    dispatch: Function;
    switchToEditMode: Function;
};

const styles: { [className: string]: React.CSSProperties } = {
    tableCell: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        padding: 10
    }
};

export default class StoreAdminTable extends React.Component<Props> {
    _nameColumn = () => ({
        Header: "Name",
        id: "name",
        accessor: "name",
        minWidth: 120,
        Filter: ({ onChange, filter }: any) => {
            return (
                <input
                    placeholder="Search name"
                    value={filter ? filter.value : ""}
                    onChange={(e) => onChange(e.target.value)}
                />
            );
        },
        Cell: (props: CellInfo) => (
            <div
                style={{
                    ...styles.tableCell,
                    justifyContent: "center",
                    alignItems: "start",
                    flexDirection: "column"
                }}
            >
                <Text small>{props.value}</Text>
                <CopyPasswordResetLink admin={props.original} />
            </div>
        ),
        filterable: true,
        style: { whiteSpace: "unset" },
        filterMethod: (filter: any, row: any, column: any) => {
            const name: string = _.get(row, "name", "");
            const query: string | null = _.get(filter, "value", "");
            if (!query) {
                return true;
            }
            return name.toLowerCase().includes(query.toLowerCase());
        }
    });

    _emailColumn = () => ({
        Header: "Email",
        id: "email",
        accessor: "email",
        minWidth: 120,
        Filter: ({ onChange, filter }: any) => {
            return (
                <input
                    placeholder="Search email"
                    value={filter ? filter.value : ""}
                    onChange={(e) => onChange(e.target.value)}
                />
            );
        },
        Cell: (props: CellInfo) => (
            <div style={styles.tableCell}>
                <Text small>{props.value}</Text>
            </div>
        ),
        filterable: true,
        style: { whiteSpace: "unset" },
        filterMethod: (filter: any, row: any, column: any) => {
            const email: string = _.get(row, "email", "");
            const query: string | null = _.get(filter, "value", "");
            if (!query) {
                return true;
            }
            return email.toLowerCase().includes(query.toLowerCase());
        }
    });

    _phoneNumberColumn = () => ({
        Header: "Phone number",
        id: "phone_number",
        accessor: "number",
        minWidth: 120,
        Filter: ({ onChange, filter }: any) => {
            return (
                <input
                    placeholder="Search email"
                    value={filter ? filter.value : ""}
                    onChange={(e) => onChange(e.target.value)}
                />
            );
        },
        Cell: (props: CellInfo) => {
            let number = props.value;
            if (number) {
                number = Helpers._displayNumber(number);
            }

            return (
                <div style={styles.tableCell}>
                    <Text small>{number}</Text>
                </div>
            );
        },
        filterable: true,
        style: { whiteSpace: "unset" },
        filterMethod: (filter: any, row: any, column: any) => {
            const phoneNumber: string = _.get(row, "phone_number", "");
            const query: string | null = _.get(filter, "value", "");
            if (!query) {
                return true;
            }
            return phoneNumber.toLowerCase().includes(query.toLowerCase());
        }
    });

    _passwordColumn = () => ({
        Header: "Password",
        id: "password",
        accessor: "password",
        minWidth: 160,
        Filter: ({ onChange, filter }: any) => {
            return (
                <input
                    placeholder="Search password"
                    value={filter ? filter.value : ""}
                    onChange={(e) => onChange(e.target.value)}
                />
            );
        },
        Cell: (props: CellInfo) => (
            <Password setAdmin={this.props.setAdmin} admin={props.original} />
        ),
        filterable: true,
        style: { whiteSpace: "unset" },
        filterMethod: (filter: any, row: any, column: any) => {
            const password: string = _.get(row, "password", "");
            const query: string | null = _.get(filter, "value", "");
            if (!query) {
                return true;
            }
            return password.toLowerCase().includes(query.toLowerCase());
        }
    });

    _roleColumn = () => ({
        Header: "Role",
        id: "role",
        accessor: (user: IUser) => {
            const storeRole = user.snackpassPermissions.storeRoles
                ? user.snackpassPermissions.storeRoles.find(
                      (role: any) => role.storeId === this.props.storeObj?._id
                  )
                : undefined;
            return storeRole ? storeRole.role : "";
        },
        minWidth: 160,
        Filter: ({ onChange, filter }: any) => {
            return (
                <input
                    placeholder="Search Roles"
                    value={filter ? filter.value : ""}
                    onChange={(e) => onChange(e.target.value)}
                />
            );
        },
        Cell: (props: CellInfo) => (
            <div style={styles.tableCell}>
                <Text small>{props.value}</Text>
            </div>
        ),
        filterable: true,
        style: { whiteSpace: "unset" },
        filterMethod: (filter: any, row: any, column: any) => {
            const role: string = _.get(row, "role", "");
            const query: string | null = _.get(filter, "value", "");
            if (!query) {
                return true;
            }
            return role.toLowerCase().includes(query.toLowerCase());
        }
    });

    _actionColumn = () => ({
        Header: "Actions",
        id: "actions",
        minWidth: 120,
        Cell: (props: CellInfo) => (
            <div style={styles.tableCell}>
                <Button
                    label="Edit"
                    onPress={() => this.props.switchToEditMode(props.original)}
                />
                <Button
                    label="Remove"
                    backgroundColor={Colors.red}
                    onPress={() => this.handleDeletePress(props.original)}
                />
            </div>
        ),
        style: { whiteSpace: "unset" }
    });

    _getColumns = () => {
        return [
            this._nameColumn(),
            this._passwordColumn(),
            this._emailColumn(),
            this._phoneNumberColumn(),
            this._roleColumn(),
            this._actionColumn()
        ];
    };

    handleDeletePress = async (admin: IUser) => {
        const result = await swal.fire({
            title: `Remove ${admin.name} from store ${this.props.storeObj?.name}?`,
            text: `This will not delete ${admin.name}. This will only demote them from being an admin of ${this.props.storeObj?.name}`,
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: Colors.red,
            cancelButtonColor: Colors.gray,
            confirmButtonText: "Remove from admins",
            cancelButtonText: "Cancel",
            focusCancel: true
        });

        if (!result.value) {
            return;
        }

        const updatedStoreIds = admin.snackpassPermissions.storeIds.filter(
            (storeId) => storeId !== this.props.storeObj?._id
        );

        const res = await api.admins.update(admin._id, {
            user: {
                snackpassPermissions: {
                    ...admin.snackpassPermissions,
                    storeIds: updatedStoreIds
                }
            },
            communicationPreferences: []
        });

        this.props.setAdmin(res.data.user);
    };

    getAdmins = () =>
        this.props.admins.filter((admin) => admin.userType !== "KIOSK");

    render() {
        return (
            <ReactTable
                style={{ width: "100%" }}
                data={this.getAdmins()}
                loading={this.props.loadingAdmins}
                className="-striped scrollable-x"
                defaultPageSize={20}
                defaultSorted={[
                    {
                        // the sorting model for the table
                        id: "createdAt",
                        desc: true
                    }
                ]}
                columns={this._getColumns()}
            />
        );
    }
}
