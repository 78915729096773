import React, { useState, useEffect } from "react";

import Modal from "react-responsive-modal";
import { connectModal } from "redux-modal";
import { Colors } from "../utils";
import { IStore } from "@snackpass/snackpass-types";
import API from "../api/index";
import get from "lodash/get";
import moment from "moment";

const WHITELISTED_PATHS = new Set([
    "isLive",
    "defaultCommissionPolicy.percent",
    "defaultCommissionPolicy.fixed",
    "monthlyFee",
    "taxRate",
    "isArchived",
]);

const styles = {
    modal: {
        overlay: {
            background: "rgba(0,0,0,0.23)",
        },
        modal: {
            background: Colors.white,
            padding: 20,
            boxShadow: "0 2px 40px 0 rgba(0, 0, 0, 0.3)",
            borderRadius: 5,
            overflow: "hidden",
            marginBottom: 100,
            width: "85%",
        },
    },
};

export interface StoreChangesModalProps {
    show: boolean;
    //modalVisible: boolean;
    handleHide: () => void;
    handleDestroy: () => void;
    storeObj?: IStore; // store to pass in editMode
    editMode?: boolean;
    onSuccess: (store: IStore) => void;
}

const StoreChangesModal = ({
    show,
    handleHide,
    handleDestroy,
    storeObj,
}: StoreChangesModalProps) => {
    let [changes, setChanges] = useState<any[]>([]);
    let [loading, setLoading] = useState<boolean>(false);
    let [initiated, setInitialized] = useState<boolean>(false);

    useEffect(() => {
        if (!initiated && storeObj && storeObj._id) {
            setLoading(true);
            API.stores
                .getChangeLogs(storeObj._id)
                .then(res => {
                    setChanges(res.data.changes);
                    setLoading(false);
                })
                .catch(err => {
                    alert("An error occurred fetching changes.");
                    setLoading(false);
                });
        }
        setInitialized(true);
    });

    const renderChangeLog = (change: any) => {
        return (
            <div
                style={{ padding: "15px 0", borderBottom: "1px solid #efefef" }}
                key={change._id}
            >
                <b>{get(change, "user.name")}</b> changed <b>{change.path}</b>{" "}
                on {moment(change.createdAt).format("h:mm a, M/DD")}
                <br />
                <div
                    style={{
                        marginLeft: 20,
                    }}
                >
                    <b>Old Value:</b>{" "}
                    {JSON.stringify(get(change, "oldValue.value", ""))}
                    <br />
                    <b>New Value:</b>{" "}
                    {JSON.stringify(get(change, "newValue.value", ""))}
                </div>
            </div>
        );
    };

    const renderNoChanges = () => {
        return (
            <div style={{ padding: 20, textAlign: "center" }}>
                No changes yet.
            </div>
        );
    };

    const renderAllChanges = (changes: any[]) => {
        let changesToShow = changes.filter(change =>
            WHITELISTED_PATHS.has(change.path)
        );

        if (changesToShow.length === 0) {
            return renderNoChanges();
        }
        return changesToShow.map(change => renderChangeLog(change));
    };

    const renderLoading = () => {
        return (
            <div style={{ padding: 20, textAlign: "center" }}>Loading...</div>
        );
    };

    return (
        <Modal
            onClose={() => {
                handleHide();
            }}
            open={show}
            styles={styles.modal}
        >
            {loading ? renderLoading() : renderAllChanges(changes)}
        </Modal>
    );
};

export default connectModal({ name: "StoreChangesModal" })(StoreChangesModal);
