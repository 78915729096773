import { connect } from "react-redux";
import GiftCards from "../screens/GiftCards";
import {
    fetchStores,
    getStoreSelectOptions,
    getActiveStore,
    setStore,
    setStores,
} from "../redux/stores";
import { Action } from "redux";
import { StoreState } from "../redux/types";
import { ThunkDispatch } from "redux-thunk";
import { setActiveStore } from "../redux/activeStore";
import { fetchGiftCards, refundGiftCardAPI } from "src/redux/giftcards";
import { IStore } from "@snackpass/snackpass-types";
import { hide, show } from "redux-modal";

const mapStateToProps = (state: StoreState) => {
    return {
        giftcards: state.giftcards,
        storeOptions: getStoreSelectOptions(state),
        activeStore: getActiveStore(state),
    };
};

// create handler than dispatches an action
const mapDispatchToProps = (
    dispatch: ThunkDispatch<StoreState, undefined, Action>
) => {
    return {
        fetchStores: () => {
            return dispatch(fetchStores());
        },
        fetchStoreData: async (storeId: string) => {
            dispatch(setActiveStore(storeId));
            await dispatch(fetchGiftCards(storeId));
        },
        refundGiftCard: async (giftCardId: string) => {
            await dispatch(refundGiftCardAPI(giftCardId));
        },
        setStore: (store: IStore) => {
            dispatch(setStore(store));
        },
        showModal: (modal: string, props?: Object) => {
            dispatch(show(modal, props));
        },
        hideModal: (modal: string) => {
            dispatch(hide(modal));
        },
    };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(GiftCards);

export default Container;
