import React from "react";
import { Button } from "../SharedComponents";
import swal from "sweetalert2";
import copy from "clipboard-copy";
import _ from "lodash";
import api from "../api";
import { IUser } from "@snackpass/snackpass-types";

type Props = {
    admin: IUser;
    setAdmin: Function;
    styles?: any;
};

type State = {
    isGenerating: boolean;
};

class CopyPasswordResetLink extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isGenerating: false,
        };
    }

    handleButtonPress = async () => {
        const { admin } = this.props;
        if (!admin.email) {
            return;
        }

        const result = await swal.fire({
            text: `Getting a password reset link will invalidate ${admin.name}'s old password. Are you sure you want to get a password reset link?`,
            type: "warning",
            showCancelButton: true,
            confirmButtonText:
                "Invalidate old password and get password reset link",
            cancelButtonText: "Never mind",
        });

        if (!result.value) {
            return;
        }

        this.setState({ isGenerating: true });

        try {
            const response = await api.users.resetPassword(admin.email, true);

            copy(response.data.passwordResetLink);

            this.props.setAdmin(response.data.user);

            swal.fire({
                title: `Copied password reset email for ${admin.email} to clipboard`,
                text:
                    "You can send this link to the user and they can use it to reset their password." +
                    `In case it didn't copy, here's that url: ${response.data.passwordResetLink}`,
                type: "success",
            });
        } catch (err) {
            swal.fire({
                title: "Error generating reset email",
                text: _.get(err, "response.data.message"),
                type: "error",
            });
        }
        this.setState({ isGenerating: false });
    };

    render() {
        return (
            <Button
                disabled={this.state.isGenerating}
                loading={this.state.isGenerating}
                label="Copy password reset link"
                onPress={e => this.handleButtonPress()}
                type="button"
                style={{ margin: 0, ...this.props.styles }}
            />
        );
    }
}

export default CopyPasswordResetLink;
