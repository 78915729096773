// Constants
export const SET_AUTHORIZED = "SET_AUTHORIZED";

// Actions
export interface SetAuthorized {
    type: "SET_AUTHORIZED";
    authorized: boolean;
}
export function setAuthorized(authorized: boolean) {
    return { type: "SET_AUTHORIZED", authorized };
}

export type AuthorizedAction = SetAuthorized;

// Reducer
const initialState = true;

export function authorized(state = initialState, action: AuthorizedAction) {
    switch (action.type) {
        case "SET_AUTHORIZED":
            return action.authorized;
        default:
            return state;
    }
}
