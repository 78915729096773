import {
    CustomPolicy,
    DefaultTipType,
    FeePolicyPayer,
    FeePolicyRecipient,
    IDeliveryRange,
    IFixedChargeFeePolicy,
    IHoursSchema,
    Integration,
    IStore,
    ReservedFeeEnum,
    TransactionSource
} from "@snackpass/snackpass-types";
import emojiRegex from "emoji-regex/RGI_Emoji";
import { parsePhoneNumber } from "libphonenumber-js";
import _, { isEmpty } from "lodash";
import { curry, find, map, propOr } from "lodash/fp";
import * as Yup from "yup";

import { getRandomColor } from "../../components/ColorPicker";

import {
    specialDeliveryHoursLocalTemplate,
    STORE_TEMPLATE,
    convenienceFeePolicyParams
} from "../../utils/Templates";

import { FormValues } from "./types";

// ======== TYPES ======== //

type FeePolicyMapping<FeePolicy> = {
    [key in keyof FeePolicy]: FeePolicyType;
};

// Different categories of fee policies
type DeliveryFees =
    | ReservedFeeEnum.DeliveryFee3P
    | ReservedFeeEnum.DeliveryCommission3P;
type GeneralFees = ReservedFeeEnum.BagStoreFee;

type DeliveryFeePolicies = {
    [fee in DeliveryFees]: () => void;
};
type GeneralFeePolicies = {
    [fee in GeneralFees]: () => void;
};

export type FeePolicyType = {
    label: string;
    value: ReservedFeeEnum;
    description: string;
    payer: FeePolicyPayer;
    recipient: FeePolicyRecipient;
};

// ======== CONSTANTS ======== //

const DINE_IN_SERVICE_TYPE = ["self_service"];
const LANGUAGE_ENUM = ["chinese"];

export const DELIVERY_FEE_POLICY_TYPES: FeePolicyMapping<DeliveryFeePolicies> =
    {
        "3P_DELIVERY_FEE": {
            label: "3P Delivery Fee",
            value: ReservedFeeEnum.DeliveryFee3P,
            description: "This is the amount the customer pays for delivery",
            payer: FeePolicyPayer.Customer,
            recipient: FeePolicyRecipient.Snackpass
        },
        "3P_DELIVERY_COMMISSION": {
            label: "3P Delivery Commission",
            value: ReservedFeeEnum.DeliveryCommission3P,
            description: "This is the amount the store subsidizes for delivery",
            payer: FeePolicyPayer.Store,
            recipient: FeePolicyRecipient.Snackpass
        }
    };

export const FEE_POLICY_TYPES: FeePolicyMapping<GeneralFeePolicies> = {
    BAG_STORE_FEE: {
        label: "Bag Fee",
        value: ReservedFeeEnum.BagStoreFee,
        description: "This is the amount the store charges per bag",
        payer: FeePolicyPayer.Customer,
        recipient: FeePolicyRecipient.Store
    }
};

export const ONLY_FLAT_FEE_POLICIES = [ReservedFeeEnum.BagStoreFee];

export const deliveryRangeTemplate = (isCA: boolean): IDeliveryRange => ({
    start: 0,
    end: 4,
    deliveryFee: isCA ? 5.99 : 4.49,
    deliveryMin: 0,
    feePolicies: []
});

const emojiExp = emojiRegex();

// ======== END CONSTANTS ======== //

function getFieldValue(object: any, field: string, defaultValue: any) {
    return _.get(object, field, defaultValue) || defaultValue;
}

function hasOverlappingHours(value: any) {
    // only check local overlap
    let counter = 0;
    // sort by interval start time, then by end time if start times are equal
    let localSorted = value.local.sort((a: any, b: any) => {
        if (a.start > b.start) {
            return 1;
        } else if (a.start === b.start && a.end > b.end) {
            return 1;
        }
        return -1;
    });
    while (counter < localSorted.length - 1) {
        if (localSorted[counter].end > localSorted[counter + 1].start) {
            return false;
        }
        counter += 1;
    }
    return true;
}

function validateHoursStartEnd(value: any) {
    for (let range of value.local) {
        if (_.get(range, "end") <= _.get(range, "start")) {
            return false;
        }
    }
    return true;
}

export function initializeFields(storeObj?: IStore): FormValues {
    const findPolicy = curry(
        <T extends CustomPolicy>(params: CustomPolicy, policies: T[]) =>
            find(
                (policy) =>
                    policy.fulfillment === params.fulfillment &&
                    policy.transactionSource === params.transactionSource,
                policies
            )
    );

    // These arrays still need to exist for the store to pass validation.
    // However, we should NEVER pre-populate these arrays. The presence of
    // a policy item means that it will OVERRIDE the default and the absence
    // means that the DEFAULT will control. This is by design.
    const customChargeFeePolicies = storeObj?.customChargeFeePolicies ?? [];
    const customCommissionPolicies = storeObj?.customCommissionPolicies ?? [];

    const convenienceFeePolicies = map((policyTxSource: TransactionSource) => {
        const storeConvenienceFeePolicies = propOr(
            [],
            "convenienceFeePolicies",
            storeObj
        );

        const findPolicyForType = findPolicy({
            transactionSource: policyTxSource
        });

        const currPolicy = findPolicyForType(storeConvenienceFeePolicies);
        const defaultPolicy = findPolicyForType(
            STORE_TEMPLATE.convenienceFeePolicies
        );

        return currPolicy || defaultPolicy;
    }, convenienceFeePolicyParams);

    const storeAutoAcceptsOrders =
        _.get(storeObj, "posAutoAcceptsOrders", false) ||
        _.get(storeObj, "serverAutoAcceptsOrders", false);

    return {
        _id: _.get(storeObj, "_id", STORE_TEMPLATE._id) as any,
        name: _.get(storeObj, "name", STORE_TEMPLATE.name) as any,
        slug: _.get(storeObj, "slug", STORE_TEMPLATE.slug) as any,
        onboardingComplete: true,
        onboardingType: "default",
        address: _.get(storeObj, "address", STORE_TEMPLATE.address),
        addressComponents: {
            line1: _.get(
                storeObj,
                "addressComponents.line1",
                STORE_TEMPLATE.addressComponents.line1
            ),
            line2: _.get(
                storeObj,
                "addressComponents.line2",
                STORE_TEMPLATE.addressComponents.line2
            ),
            city: _.get(
                storeObj,
                "addressComponents.city",
                STORE_TEMPLATE.addressComponents.city
            ),
            county: _.get(
                storeObj,
                "addressComponents.county",
                STORE_TEMPLATE.addressComponents.county
            ),
            state: _.get(
                storeObj,
                "addressComponents.state",
                STORE_TEMPLATE.addressComponents.state
            ),
            zip: _.get(
                storeObj,
                "addressComponents.zip",
                STORE_TEMPLATE.addressComponents.zip
            ),
            country: _.get(
                storeObj,
                "addressComponents.country",
                STORE_TEMPLATE.addressComponents.country
            ),
            full: _.get(
                storeObj,
                "addressComponents.full",
                STORE_TEMPLATE.addressComponents.full
            )
        },
        color: _.get(storeObj, "color", getRandomColor()),
        brandColor: _.get(storeObj, "brandColor", ""),
        emoji: _.get(storeObj, "emoji", STORE_TEMPLATE.emoji),
        hours: _.get(storeObj, "hours", STORE_TEMPLATE.hours),
        region: _.get(storeObj, "region", STORE_TEMPLATE.region) as any,
        pod: _.get(storeObj, "pod", STORE_TEMPLATE.pod),
        taxRate: _.get(storeObj, "taxRate", STORE_TEMPLATE.taxRate),
        specifyTaxRateByFulfillment:
            storeObj?.specifyTaxRateByFulfillment ||
            STORE_TEMPLATE.specifyTaxRateByFulfillment,
        taxRateDineIn: storeObj?.taxRateDineIn || STORE_TEMPLATE.taxRateDineIn,
        taxRatePickup: storeObj?.taxRatePickup || STORE_TEMPLATE.taxRatePickup,
        taxRateDelivery:
            storeObj?.taxRateDelivery || STORE_TEMPLATE.taxRateDelivery,
        hasGifting: _.get(storeObj, "hasGifting", STORE_TEMPLATE.hasGifting),
        noNotes: _.get(storeObj, "noNotes", STORE_TEMPLATE.noNotes),
        showTipOnPickup: _.get(
            storeObj,
            "showTipOnPickup",
            STORE_TEMPLATE.showTipOnPickup
        ),
        showNoTip: _.get(storeObj, "showNoTip", STORE_TEMPLATE.showNoTip),
        defaultTipping: _.get(
            storeObj,
            "defaultTipping",
            STORE_TEMPLATE.defaultTipping
        ),
        defaultTip: {
            defaultTipType: _.get(
                storeObj,
                "defaultTip.defaultTipType",
                STORE_TEMPLATE.defaultTip.defaultTipType
            ),
            defaultTipsForm: _.get(
                storeObj,
                "defaultTip.defaultTips",
                STORE_TEMPLATE.defaultTip.defaultTips
            ).toString(),
            defaultTips: _.get(
                storeObj,
                "defaultTip.defaultTips",
                STORE_TEMPLATE.defaultTip.defaultTips
            ),
            defaultTipValue: _.get(
                storeObj,
                "defaultTip.defaultTipValue",
                STORE_TEMPLATE.defaultTip.defaultTipValue
            )
        },
        defaultTipPercentage: _.get(
            storeObj,
            "defaultTipPercentage",
            STORE_TEMPLATE.defaultTipPercentage
        ),
        phoneNumber: _.get(storeObj, "phoneNumber", STORE_TEMPLATE.phoneNumber),
        faxNumber: _.get(storeObj, "faxNumber", STORE_TEMPLATE.faxNumber),
        geolocation: _.get(
            storeObj,
            "geolocation",
            STORE_TEMPLATE.geolocation
        ) as any,
        email: _.get(storeObj, "email", STORE_TEMPLATE.email),
        alerts: {
            email: _.get(storeObj, "alerts.email", STORE_TEMPLATE.alerts.email),
            fax: _.get(storeObj, "alerts.fax", STORE_TEMPLATE.alerts.fax),
            phone: _.get(storeObj, "alerts.phone", STORE_TEMPLATE.alerts.phone)
        },
        pickupDirections: _.get(
            storeObj,
            "pickupDirections",
            STORE_TEMPLATE.pickupDirections
        ) as any,
        closedUntil: _.get(storeObj, "closedUntil", STORE_TEMPLATE.closedUntil),
        closedUntilReason: _.get(
            storeObj,
            "closedUntilReason",
            STORE_TEMPLATE.closedUntilReason
        ),
        rushHourUntil: _.get(
            storeObj,
            "rushHourUntil",
            STORE_TEMPLATE.rushHourUntil
        ),
        defaultCommissionPolicy: {
            fixed: _.get(
                storeObj,
                "defaultCommissionPolicy.fixed",
                STORE_TEMPLATE.defaultCommissionPolicy.fixed
            ),
            percent: _.get(
                storeObj,
                "defaultCommissionPolicy.percent",
                STORE_TEMPLATE.defaultCommissionPolicy.percent
            )
        },
        customCommissionPolicies,
        monthlyFee: _.get(storeObj, "monthlyFee", STORE_TEMPLATE.monthlyFee),
        pickup: _.get(storeObj, "pickup", STORE_TEMPLATE.pickup),
        pickupMin: _.get(storeObj, "pickupMin", STORE_TEMPLATE.pickupMin),
        scheduledOrderPosTriggerTime: _.get(
            storeObj,
            "scheduledOrderPosTriggerTime",
            STORE_TEMPLATE.scheduledOrderPosTriggerTime
        ),
        hasConvenienceFee: _.get(
            storeObj,
            "hasConvenienceFee",
            STORE_TEMPLATE.hasConvenienceFee
        ), // Fixed dollar amount store charges for every purchase. Should only be
        convenienceFee: _.get(
            storeObj,
            "convenienceFee",
            STORE_TEMPLATE.convenienceFee
        ),
        chargeFeePolicy: _.get(
            storeObj,
            "chargeFeePolicy",
            STORE_TEMPLATE.chargeFeePolicy as IFixedChargeFeePolicy
        ),
        customChargeFeePolicies,
        convenienceFeePolicies,
        storeDelivers: _.get(
            storeObj,
            "storeDelivers",
            STORE_TEMPLATE.storeDelivers
        ),
        delivery: _.get(storeObj, "delivery", STORE_TEMPLATE.delivery),
        deliveryOnlyEmail:
            _.get(storeObj, "deliveryOnlyEmail") ||
            STORE_TEMPLATE.deliveryOnlyEmail,
        deliveryFee: _.get(storeObj, "deliveryFee", STORE_TEMPLATE.deliveryFee),
        deliveryMin: _.get(storeObj, "deliveryMin", STORE_TEMPLATE.deliveryMin),
        deliveryTime: _.get(
            storeObj,
            "deliveryTime",
            STORE_TEMPLATE.deliveryTime
        ),
        // This should always default to an empty list
        // _.get(...) only returns the default if the value is undefined.
        // If deliveryRanges = null, the expression is null || [], so it resolves
        // to an empty list.
        // Must be empty list so the form can append and remove
        // from that list to add delivery ranges.
        deliveryRanges:
            _.get(storeObj, "deliveryRanges", STORE_TEMPLATE.deliveryRanges) ||
            [],
        hasSpecialDeliveryHours: _.get(
            storeObj,
            "hasSpecialDeliveryHours",
            STORE_TEMPLATE.hasSpecialDeliveryHours
        ),
        specialDeliveryHours: getFieldValue(
            storeObj,
            "specialDeliveryHours",
            STORE_TEMPLATE.specialDeliveryHours
        ),
        allowOrderConfirmationMessages: _.get(
            storeObj,
            "allowOrderConfirmationMessages",
            STORE_TEMPLATE.allowOrderConfirmationMessages
        ),
        allowViewEarningsOnTablet: _.get(
            storeObj,
            "allowViewEarningsOnTablet",
            STORE_TEMPLATE.allowViewEarningsOnTablet
        ),
        hasTablet: _.get(storeObj, "hasTablet", STORE_TEMPLATE.hasTablet),
        autoWakeStoreTablet: _.get(
            storeObj,
            "autoWakeStoreTablet",
            STORE_TEMPLATE.autoWakeStoreTablet
        ),
        tabletUseQuietTone: _.get(
            storeObj,
            "tabletUseQuietTone",
            STORE_TEMPLATE.tabletUseQuietTone
        ),
        primaryStoreTablet: _.get(
            storeObj,
            "primaryStoreTablet",
            STORE_TEMPLATE.primaryStoreTablet
        ),
        tabletSerial: null,
        unlinkPrimaryTablet: false,
        storeWithSerial: null,
        dineInPreferences: _.get(
            storeObj,
            "dineInPreferences",
            STORE_TEMPLATE.dineInPreferences
        ),
        isLive: _.get(storeObj, "isLive", STORE_TEMPLATE.isLive),
        integrations: _.get(
            storeObj,
            "integrations",
            STORE_TEMPLATE.integrations
        ),
        posIntegrations: _.get(
            storeObj,
            "posIntegrations",
            STORE_TEMPLATE.posIntegrations
        ),
        languages: _.get(storeObj, "languages", STORE_TEMPLATE.languages),
        notes: _.get(storeObj, "notes", STORE_TEMPLATE.notes),
        requestStoreToJoinSnackpass: _.get(
            storeObj,
            "requestStoreToJoinSnackpass",
            STORE_TEMPLATE.requestStoreToJoinSnackpass
        ),
        comingSoon: _.get(storeObj, "comingSoon", STORE_TEMPLATE.comingSoon),
        isTestStore: _.get(storeObj, "isTestStore", STORE_TEMPLATE.isTestStore),
        hasEmployeeAuditTracking: _.get(
            storeObj,
            "hasEmployeeAuditTracking",
            STORE_TEMPLATE.hasEmployeeAuditTracking
        ),
        initialReceiptNumber: _.get(
            storeObj,
            "initialReceiptNumber",
            STORE_TEMPLATE.initialReceiptNumber
        ),
        pos: _.get(storeObj, "pos", STORE_TEMPLATE.pos),
        shouldRestartPOS: _.get(storeObj, "shouldRestartPOS", false),
        allowPauseOnPOS: _.get(
            storeObj,
            "allowPauseOnPOS",
            STORE_TEMPLATE.allowPauseOnPOS
        ),
        allowPauseRestOfDayOnPOS: _.get(
            storeObj,
            "allowPauseRestOfDayOnPOS",
            STORE_TEMPLATE.allowPauseRestOfDayOnPOS
        ),
        aggressiveBatteryModeOnPOS: _.get(
            storeObj,
            "aggressiveBatteryModeOnPOS",
            STORE_TEMPLATE.aggressiveBatteryModeOnPOS
        ),
        payInStore: _.get(storeObj, "payInStore", STORE_TEMPLATE.payInStore),
        customPickUpTimes: (
            _.get(
                storeObj,
                "customPickUpTimes",
                STORE_TEMPLATE.customPickUpTimes
            ) || []
        ).join(", ") as any,
        customDeliveryTimes: (
            _.get(
                storeObj,
                "customDeliveryTimes",
                STORE_TEMPLATE.customDeliveryTimes
            ) || []
        ).join(", ") as any,
        orderStatusConfiguration:
            storeObj?.orderStatusConfiguration ??
            STORE_TEMPLATE.orderStatusConfiguration,
        serverAutoAcceptsOrders: _.get(
            storeObj,
            "serverAutoAcceptsOrders",
            STORE_TEMPLATE.serverAutoAcceptsOrders
        ),
        pickupTimeType: _.get(
            storeObj,
            "pickupTimeType",
            STORE_TEMPLATE.pickupTimeType
        ),
        defaultPickupTime: _.get(
            storeObj,
            "defaultPickupTime",
            STORE_TEMPLATE.defaultPickupTime
        ),
        defaultPickupMinTime: _.get(
            storeObj,
            "defaultPickupMinTime",
            STORE_TEMPLATE.defaultPickupMinTime
        ),
        defaultPickupMaxTime: _.get(
            storeObj,
            "defaultPickupMaxTime",
            STORE_TEMPLATE.defaultPickupMaxTime
        ),
        onlineOrderingEnabled: _.get(
            storeObj,
            "onlineOrderingEnabled",
            STORE_TEMPLATE.onlineOrderingEnabled
        ),
        isPrimaryStore: _.get(
            storeObj,
            "isPrimaryStore",
            STORE_TEMPLATE.isPrimaryStore
        ),
        isEnterprise: _.get(storeObj, "isEnterprise"),
        thumbnailUrl: _.get(
            storeObj,
            "thumbnailUrl",
            STORE_TEMPLATE.thumbnailUrl
        ),
        logoUrl: _.get(storeObj, "logoUrl", STORE_TEMPLATE.logoUrl),
        isArchived: _.get(storeObj, "isArchived", STORE_TEMPLATE.isArchived),
        hasScheduledOrders: _.get(
            storeObj,
            "hasScheduledOrders",
            STORE_TEMPLATE.hasScheduledOrders
        ),
        hasMultipleIntegrations: _.get(
            storeObj,
            "hasMultipleIntegrations",
            STORE_TEMPLATE.hasMultipleIntegrations
        ),
        hasBatching: _.get(storeObj, "hasBatching", STORE_TEMPLATE.hasBatching),
        scheduleAheadBatchSize: _.get(
            storeObj,
            "scheduleAheadBatchSize",
            STORE_TEMPLATE.scheduleAheadBatchSize
        ),
        scheduleAheadInterval: _.get(
            storeObj,
            "scheduleAheadInterval",
            STORE_TEMPLATE.scheduleAheadInterval
        ),
        scheduledOrderMinLeadTime: _.get(
            storeObj,
            "scheduledOrderMinLeadTime",
            STORE_TEMPLATE.scheduledOrderMinLeadTime
        ),
        accountManager: _.get(
            storeObj,
            "accountManager",
            STORE_TEMPLATE.accountManager
        ),
        accountExecutive: _.get(
            storeObj,
            "accountExecutive",
            STORE_TEMPLATE.accountExecutive
        ),
        numFreeTrialPurchases: _.get(
            storeObj,
            "numFreeTrialPurchases",
            STORE_TEMPLATE.numFreeTrialPurchases
        ),
        shouldCheckFreeTrial: _.get(
            storeObj,
            "shouldCheckFreeTrial",
            STORE_TEMPLATE.shouldCheckFreeTrial
        ),
        allowOrderNow: _.get(
            storeObj,
            "allowOrderNow",
            STORE_TEMPLATE.allowOrderNow
        ),
        hasPickupNow: _.get(
            storeObj,
            "hasPickupNow",
            STORE_TEMPLATE.hasPickupNow
        ),
        salesforceId: _.get(
            storeObj,
            "salesforceId",
            STORE_TEMPLATE.salesforceId
        ),
        dateLiveOnSnackpass:
            _.get(storeObj, "dateLiveOnSnackpass") ||
            STORE_TEMPLATE.dateLiveOnSnackpass,
        hasKds: _.get(storeObj, "hasKds", STORE_TEMPLATE.hasKds),
        kdsSkipInProgress: _.get(
            storeObj,
            "kdsSkipInProgress",
            STORE_TEMPLATE.kdsSkipInProgress
        ),
        hasKiosk: _.get(storeObj, "hasKiosk", STORE_TEMPLATE.hasKiosk),
        kioskPreferences: _.get(
            storeObj,
            "kioskPreferences",
            STORE_TEMPLATE.kioskPreferences
        ),
        registerRewardsEnabled: _.get(
            storeObj,
            "registerRewardsEnabled",
            STORE_TEMPLATE.registerRewardsEnabled
        ),
        tipImageUrl: _.get(storeObj, "tipImageUrl", STORE_TEMPLATE.tipImageUrl),
        digitalMenuEnabled: _.get(
            storeObj,
            "digitalMenuEnabled",
            STORE_TEMPLATE.digitalMenuEnabled
        ),
        aboutUs: _.get(storeObj, "aboutUs", STORE_TEMPLATE.aboutUs),
        updatedAt: _.get(storeObj, "updatedAt", ""),
        customFeePolicies: _.get(storeObj, "customFeePolicies") || [],
        pickupTimeStats: _.get(storeObj, "pickupTimeStats") || {},
        referralChannels: _.get(
            storeObj,
            "referralChannels",
            STORE_TEMPLATE.referralChannels
        ),
        referralConversionText: _.get(
            storeObj,
            "referralConversionText",
            STORE_TEMPLATE.referralConversionText
        ),
        customCommissions: _.get(
            storeObj,
            "customCommissions",
            STORE_TEMPLATE.customCommissions
        ),
        customCreditCardFee: _.get(
            storeObj,
            "customCreditCardFee",
            STORE_TEMPLATE.customCreditCardFee
        ),

        // TODO: Replace this with changes in tax remittance feature (leo)
        feePolicies: _.get(
            storeObj,
            "feePolicies",
            STORE_TEMPLATE.feePolicies
        ) as any,

        hasServerDirectPrint: _.get(
            storeObj,
            "hasServerDirectPrint",
            STORE_TEMPLATE.hasServerDirectPrint
        ),

        recurringPaymentsIds:
            storeObj?.recurringPaymentsIds ||
            STORE_TEMPLATE.recurringPaymentsIds,
        dashboardPreferences:
            storeObj?.dashboardPreferences ||
            STORE_TEMPLATE.dashboardPreferences,

        // Different sections of the store builder
        storeBuilderSectionExpanded: true,
        fulfillmentModesSectionExpanded: false,
        orderAlertsSectionExpanded: false,
        pickupOptionsSectionExpanded: false,
        dineInOptionsSectionExpanded: false,
        deliveryOptionsSectionExpanded: false,
        commissionSectionExpanded: false,
        storefrontSectionExpanded: false,
        creditCardPolicySectionExpanded: false,
        integrationsSectionExpanded: false,
        feeSectionExpanded: false,
        tabletOptionsSectionExpanded: false,
        autoAcceptOrdersSectionExpanded: false,
        peepTrackingSectionExpanded: false,
        posTogglesExpanded: false,
        kdsTogglesExpanded: false,
        upsellSectionExpanded: false,
        kioskPreferencesSectionExpanded: false,
        storeMiscSectionExpanded: false,
        recurringPaymentsSectionExpanded: false,
        serverDirectPrintSectionExpanded: false,
        tipOptionsExpanded: false,
        autoAcceptsOrders: storeAutoAcceptsOrders
    };
}

const TIME_MIN = 0;
const TIME_MAX = 7 * 24 * 60 - 1;

const timeRangeSchema = Yup.object().shape({
    start: Yup.number().min(TIME_MIN).max(TIME_MAX),
    end: Yup.number().min(TIME_MIN).max(TIME_MAX)
});

const hoursSchema = Yup.object().shape({
    zone: Yup.string().required(),
    local: Yup.array().of(timeRangeSchema)
});

const feePolicySchema = Yup.object().shape({
    name: Yup.string().oneOf(
        Object.values(ReservedFeeEnum),
        "Invalid fee policy type"
    ),
    payer: Yup.mixed().oneOf(
        Object.values(FeePolicyPayer),
        "Invalid fee policy payer"
    ),
    recipient: Yup.mixed().oneOf(
        Object.values(FeePolicyRecipient),
        "Invalid fee policy recipient"
    ),
    flat: Yup.number()
        .transform((value) => (Number.isNaN(value) ? 0 : value))
        // we allow $0 in the case of free, ex customer pays $0 and store pays $6
        .min(0, "Delivery flat fee must be greater than or equal to $0"),
    percent: Yup.number()
        .transform((value) => (Number.isNaN(value) ? 0 : value))
        .min(0, "Percent fee must be greater than or equal to 0")
        .max(100, "Percent fee cannot be greater than 100"),
    isTaxable: Yup.bool().nullable().notRequired()
});

const buildDeliveryRateSchema = (
    hasDelivery: boolean,
    storeDelivers: boolean
) => {
    const YupNumber = Yup.number()
        .transform((value) => (Number.isNaN(value) ? 0 : value))
        .min(0, "value must be greater than 0");
    const YupNumberNullable = Yup.number()
        .transform((value) => (!value ? null : value))
        .nullable();

    return Yup.object().shape({
        deliveryFee: hasDelivery
            ? // if there are fee policies then allow thee delivery fee to be null,
              // otherwise require it to be a number
              Yup.number().when("feePolicies", {
                  is: (val) => val?.length > 0,
                  then: (schema: Yup.NumberSchema) => schema.nullable(),
                  otherwise: (schema: Yup.NumberSchema) =>
                      schema
                          .min(0, "Fee must be at least 0")
                          .required("delivery fee missing")
              })
            : YupNumberNullable,
        deliveryMin: hasDelivery
            ? YupNumber.required("delivery min missing")
            : YupNumberNullable,
        start: hasDelivery
            ? YupNumber.required("delivery start missing")
            : YupNumberNullable,
        end: hasDelivery
            ? YupNumber.required("delivery end missing")
            : YupNumberNullable,
        feePolicies: hasDelivery
            ? storeDelivers
                ? // If 1P Delivery is on, fee policies should be empty
                  Yup.array().test({
                      message:
                          "Fee policies must be empty if 1P delivery is on",
                      test: (arr) => isEmpty(arr)
                  })
                : Yup.array()
                      .of(feePolicySchema)
                      .test({
                          message:
                              "At least one fee policy must be set when 1P delivery is off",
                          test: (arr) => !isEmpty(arr)
                      })
            : Yup.array()
    });
};

const integrationsValidation = (delivery: boolean) => {
    const deliveryOffMessage =
        "All delivery integrations must be off when delivery is turned off";
    return Yup.object().shape({
        kiwi: Yup.object()
            .shape({
                enabled: Yup.boolean()
            })
            .nullable(),
        checkmate: Yup.object()
            .shape({
                enabled: Yup.boolean()
            })
            .nullable(),
        postmates: Yup.object()
            .shape({
                enabled: delivery
                    ? Yup.boolean()
                    : Yup.boolean().oneOf([false], deliveryOffMessage)
            })
            .nullable(),
        onzway: Yup.object()
            .shape({
                enabled: Yup.boolean()
            })
            .nullable(),
        smarpus: Yup.object()
            .shape({
                enabled: Yup.boolean(),
                externalId: Yup.string().when("enabled", {
                    is: true,
                    then: Yup.string().required(),
                    otherwise: Yup.string().nullable()
                })
            })
            .nullable(),
        doordash: Yup.object()
            .shape({
                enabled: delivery
                    ? Yup.boolean()
                    : Yup.boolean().oneOf([false], deliveryOffMessage)
            })
            .nullable()
    });
};

const addressValidation = Yup.object().shape({
    line1: Yup.string().required(),
    line2: Yup.string().nullable(),
    city: Yup.string().required(),
    county: Yup.string().nullable(),
    state: Yup.string().length(2).required(),
    zip: Yup.string().length(5).required(),
    country: Yup.string().length(3).required()
});

type BuildDefaultPickupTimeSchemaParam = Partial<{
    ifNotAutoAccept: (schema: any) => any;
    ifSpecific: (schema: any) => any;
    ifRange: (schema: any) => any;
}>;

/**
 * Build a pickup time schema validator based on whether the store auto-accepts
 * orders (if so, the default time is unused) and if so the type of default time
 * (range or specific).
 */
const buildDefaultPickupTimeSchema = ({
    ifNotAutoAccept = (schema) => schema.nullable(),
    ifSpecific = (schema) => schema.nullable(),
    ifRange = (schema) => schema.nullable()
}: BuildDefaultPickupTimeSchemaParam) =>
    Yup.number().when(
        ["pickupTimeType", "serverAutoAcceptsOrders"],
        (
            pickupTimeType: "specific" | "range",
            serverAutoAcceptsOrders: boolean,
            schema: any
        ) => {
            if (!serverAutoAcceptsOrders) {
                return ifNotAutoAccept(schema);
            } else if (pickupTimeType === "specific") {
                return ifSpecific(schema);
            } else if (pickupTimeType === "range") {
                return ifRange(schema);
            }
        }
    );

/**
 * Yup validation schema helpers
 * When/where applicable, define validation helper functions below for any schema in `validationSchema`
 * this makes the file more readable/manageable as it grows
 */

// for `deliveryTime`
const validateDeliveryTime = () => {
    return Yup.string().when(
        ["storeDelivers", "posAutoAcceptsOrders"],
        (
            storeDelivers: boolean,
            posAutoAcceptsOrders: boolean,
            schema: any
        ) => {
            // delivery time required if 1P delivery and tablet auto-accept on
            if (storeDelivers && posAutoAcceptsOrders) {
                return schema.test({
                    name: "Delivery time required if 1P and tablet auto-accept on",
                    message:
                        "Must provide a delivery time under `Delivery Config` if 1P Delivery and Tablet Auto-Accepts Orders are on",
                    test: (time: string) => !isEmpty(time) && parseInt(time) > 0
                });
            }
            return schema.nullable();
        }
    );
};

// Yup validation schema
/**
 * Warning: if there is invalid Yup schema, form will fail silently
 * and go straight to submit. Need to fix this.
 */
export const validationSchema = Yup.object().shape({
    editMode: Yup.boolean(),
    storeObj: Yup.mixed(),
    name: Yup.string().required("Name for store is required"),
    slug: Yup.string()
        .matches(
            /^[a-z0-9]*$/,
            "Slug must contain only lowercase alpha-numeric characters."
        )
        .required("A unique slug is required for all new and updated Stores."),
    address: Yup.string().required("Address is required"),
    addressComponents: addressValidation,
    salesforceId: Yup.string().test({
        message: "Must be 18 characters long",
        test: (id) => (id ? id.length === 18 : true)
    }),
    color: Yup.string().required("Color is required"),
    emoji: Yup.string()
        .test({
            message: "Must be a valid emoji",
            test: (emoji: string) => {
                if (emoji) {
                    let match = emoji?.match(emojiExp);
                    return match ? !!match?.length : false;
                }
                return false;
            }
        })
        .required("Emoji is required"),
    geolocation: Yup.object().shape({
        type: Yup.string(),
        coordinates: Yup.array()
            .of(Yup.number())
            .test({
                message: "Must have valid geolocation",
                test: (coords) =>
                    coords && _.isArray(coords) && coords.length === 2
            })
    }),
    hours: hoursSchema
        .required()
        .test({
            name: "Overlapping Hours",
            message: "Store Hours cannot overlap!",
            test: (value) => {
                return hasOverlappingHours(value);
            }
        })
        .test({
            name: "End time must be after start time",
            message: "End time must be after start time!",
            test: (value) => {
                return validateHoursStartEnd(value);
            }
        }),
    region: Yup.mixed().required("A region is required"),
    dateLiveOnSnackpass: Yup.date().when("isLive", {
        is: true,
        then: Yup.date().required(),
        otherwise: Yup.date().nullable()
    }),
    pictureMenuProductIds: Yup.array().of(Yup.string().required()),
    productCategories: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required(),
            productIds: Yup.array().of(Yup.string().required())
        })
    ),
    _categoryOrder: Yup.array().of(Yup.string().required()),
    hasGifting: Yup.boolean(),
    noNotes: Yup.boolean(),
    showTipOnPickup: Yup.boolean(),
    defaultTipping: Yup.boolean(),
    defaultTip: Yup.object().shape({
        defaultTipType: Yup.string().oneOf([
            DefaultTipType.Flat,
            DefaultTipType.Percent
        ]),
        defaultTipsForm: Yup.string().test({
            name: "Default tip value",
            message:
                "Must be a comma separated list of four numbers in ascending order",
            test: (value: string): boolean => {
                const separated = value
                    .split(",")
                    .map((value) => Number(value.trim()));
                const isNumbers = separated.every((element) => {
                    return typeof element === "number";
                });
                const isAscending = separated.every((x, i) => {
                    return i === 0 || x > separated[i - 1];
                });
                return separated.length === 4 && isNumbers && isAscending;
            }
        })
    }),
    defaultTipPercentage: Yup.number(),
    phoneNumber: Yup.string()
        .test({
            name: "Phone Number",
            message: "This phone number is not valid",
            test: (value: string): boolean => {
                try {
                    let phone = parsePhoneNumber(value || "", "US");
                    return phone !== undefined && phone.isValid();
                } catch (err) {
                    console.log(err);
                }
                // if there is no value, it is fine because the phone number wasn't filled out
                return !value;
            }
        })
        .required(),
    faxNumber: Yup.string().nullable(),
    email: Yup.string().test({
        name: "Email Check",
        message: "Found an invalid store owner email",
        test: (value: string) => {
            let schema = Yup.string().email().required();

            // it's fine if no owner email is provided, but if there's
            // at least one provided, there cannot be extra blank boxes
            if (!value) {
                return true;
            }
            try {
                let emails = value.split(",");
                return emails.every((email) => schema.isValidSync(email));
            } catch (err) {
                return false;
            }
        }
    }),
    alerts: Yup.object()
        .shape({
            email: Yup.boolean().required(),
            fax: Yup.boolean().required(),
            phone: Yup.boolean().required()
        })
        .required(),
    allowOrderNow: Yup.boolean(),
    allowViewEarningsOnTablet: Yup.boolean(),
    hasPickupNow: Yup.boolean().when(["pickup", "hasScheduledOrders"], {
        is: (pickup: boolean, hasScheduledOrders: boolean) =>
            pickup && !hasScheduledOrders,
        then: (schema: Yup.Schema<any>) =>
            schema.oneOf(
                [true],
                "Pickup requires wither pickup ASAP and/or has scheduled orders to be toggled on."
            )
    }),
    pickupDirections: Yup.string().nullable(),
    closedUntil: Yup.date().nullable(),
    closedUntilReason: Yup.string().nullable(),
    rushHourUntil: Yup.date().nullable(),
    monthlyFee: Yup.number().min(0),
    pickup: Yup.boolean(),
    pickupMin: Yup.number().nullable().min(0),
    scheduledOrderPosTriggerTime: Yup.number().nullable(),
    // Delivery fields
    delivery: Yup.boolean().required(),
    deliveryOnlyEmail: Yup.string().email().nullable(),
    deliveryFee: Yup.number().when(["delivery", "deliveryRanges"], {
        // if there is delivery but there are no ranges,
        // this is required otherwise it is optional
        is: (delivery: boolean, ranges: IDeliveryRange[]) =>
            delivery && !ranges?.length,
        then: Yup.number().min(0).required("Delivery Fee Required"),
        otherwise: Yup.number().nullable()
    }),
    deliveryMin: Yup.number().nullable(),
    deliveryTime: validateDeliveryTime(),
    deliveryRanges: Yup.array().when(
        ["delivery", "storeDelivers"],
        (
            delivery: boolean,
            storeDelivers: boolean,
            schema: Yup.ArraySchema<IDeliveryRange>
        ) =>
            delivery
                ? // When delivery is on, both 1P and 3P require at least one delivery range to be set
                  schema
                      .of(buildDeliveryRateSchema(delivery, storeDelivers))
                      .test({
                          message:
                              "Must have at least 1 delivery range when delivery is enabled",
                          test: (arr) => !isEmpty(arr)
                      })
                : schema
    ),
    hasSpecialDeliveryHours: Yup.boolean(),
    specialDeliveryHours: Yup.object().when(
        ["hasSpecialDeliveryHours", "hours"],
        (
            hasSpecialDeliveryHours: boolean,
            hours: IHoursSchema,
            schema: any
        ) => {
            if (!hasSpecialDeliveryHours) {
                return undefined;
            }
            return schema
                .test({
                    name: "Check SpecialDeliveryHours",
                    message:
                        "If a store has Special Delivery Hours, you are required to input them!",
                    test: (specialHours: IHoursSchema) => {
                        let localSpecial = specialHours.local;
                        return !_.isEqual(
                            localSpecial,
                            specialDeliveryHoursLocalTemplate
                        );
                    }
                })
                .test({
                    name: "Check timezone matches store hours timezone",
                    message:
                        "The delivery timezone does not match the store hours timezone!",
                    test: (value: IHoursSchema) => {
                        return hours.zone === value.zone;
                    }
                });
        }
    ),

    // Kiosk config
    kioskPreferences: Yup.object().shape({
        attractScreenUrl: Yup.string().min(1).nullable()
    }),

    integrations: Yup.object().when(["delivery"], (delivery: boolean) => {
        return integrationsValidation(delivery);
    }),
    posIntegrations: Yup.object()
        .shape({
            chowly: Yup.object().shape({
                enabled: Yup.boolean().nullable(),
                apiKey: Yup.string().when(["enabled"], {
                    is: (enabled: boolean) => enabled,
                    then: Yup.string().min(1),
                    otherwise: Yup.string().max(0).nullable()
                })
            }),
            deliverect: Yup.object().shape({
                enabled: Yup.boolean().nullable(),
                locationId: Yup.string().when(["enabled"], {
                    is: (enabled: boolean) => enabled,
                    then: Yup.string().min(1),
                    otherwise: Yup.string().max(0).nullable()
                })
            })
        })
        .nullable(),
    storeDelivers: Yup.boolean().when(
        ["delivery", "integrations"],
        (
            delivery: boolean,
            integrations: {
                postmates: { enabled: boolean };
                doordash: { enabled: boolean };
            },
            schema: any
        ) => {
            // If delivery is off, 1P must be off
            if (!delivery)
                return schema.oneOf(
                    [false],
                    "Store cannot have 1P Delivery turned on if delivery is not enabled."
                );

            if (delivery) {
                // If all integrations are off but delivery is on, require 1P
                if (
                    !integrations?.postmates?.enabled &&
                    !integrations?.doordash?.enabled
                ) {
                    return schema.oneOf(
                        [true],
                        "Must have either 1P Delivery or 3P Delivery integrations on when delivery is enabled"
                    );
                }
                // If any 3P delivery integration is enabled, then 1P should be off
                if (
                    integrations?.postmates?.enabled ||
                    integrations?.doordash?.enabled
                ) {
                    return schema.oneOf(
                        [false],
                        "1P Delivery cannot be enabled at the same time as any 3P Delivery integration"
                    );
                }
            }
            return schema;
        }
    ),

    serverAutoAcceptsOrders: Yup.boolean()
        .required()
        .when(
            "posAutoAcceptsOrders",
            (posAutoAcceptsOrders: boolean, schema: any) =>
                posAutoAcceptsOrders
                    ? schema.oneOf(
                          [false],
                          "Integration auto-accept cannot be enabled while tablet auto-accept is enabled."
                      )
                    : schema
        ),

    // Various store settings
    hasTablet: Yup.boolean()
        .required()
        .when("tabletSerial", (tabletSerial: string | null, schema: any) => {
            if (tabletSerial) {
                return schema.test({
                    name: "Has tablet",
                    message: "Must be true if the store has a tablet",
                    test: (value: boolean) => value
                });
            }

            return schema;
        }),
    tabletSerial: Yup.string().nullable(),
    tabletVersion: Yup.string().nullable(),
    tabletUseQuietTone: Yup.boolean().required(),
    primaryStoreTablet: Yup.string().nullable(),
    dineInPreferences: Yup.object().shape({
        hasDineIn: Yup.boolean(),
        serviceType: Yup.string()
            .oneOf([...DINE_IN_SERVICE_TYPE, null])
            .nullable(),
        directions: Yup.string().nullable(),
        requireTableNumber: Yup.boolean()
    }),
    isLive: Yup.boolean().required(),
    doNotSendNotificationsToCustomer: Yup.boolean(),
    languages: Yup.array().of(Yup.string().oneOf(LANGUAGE_ENUM)),
    notes: Yup.string(),
    requestStoreToJoinSnackpass: Yup.boolean(),
    comingSoon: Yup.boolean(),
    isTestStore: Yup.boolean(),
    hasEmployeeAuditTracking: Yup.boolean(),
    initialReceiptNumber: Yup.number(),
    pos: Yup.object().shape({
        disable: Yup.boolean(),
        disableFlashAnimations: Yup.boolean(),
        disableRefund: Yup.boolean(),
        disableSoldOut: Yup.boolean()
    }),
    shouldRestartPOS: Yup.boolean(),
    allowPauseOnPOS: Yup.boolean(),
    allowPauseRestOfDayOnPOS: Yup.boolean(),
    aggressiveBatteryModeOnPOS: Yup.boolean(),
    payInStore: Yup.boolean(),
    // customPickUpTimes and customDeliveryTimes are strings
    // that are converted to list of numbers before sent to server
    customPickUpTimes: Yup.string(),
    customDeliveryTimes: Yup.string(),
    pickupTimeType: Yup.string().oneOf(["specific", "range"]),
    onlineOrderingEnabled: Yup.boolean().required(),
    thumbnailUrl: Yup.string().url().nullable(),
    logoUrl: Yup.string().url().nullable(),

    defaultPickupTime: buildDefaultPickupTimeSchema({
        ifSpecific: (schema) =>
            schema.required("${path}: need to set default pickup time.")
    }),
    defaultPickupMinTime: buildDefaultPickupTimeSchema({
        ifRange: (schema) =>
            schema
                .required("${path}: need to set default pickup min time.")
                .lessThan(
                    Yup.ref("defaultPickupMaxTime"),
                    "${path} must be less than max time"
                )
                .positive()
    }),
    defaultPickupMaxTime: buildDefaultPickupTimeSchema({
        ifRange: (schema) =>
            schema
                .required("${path}: need to set default pickup max time.")
                .positive()
    }),

    aboutUs: Yup.string().nullable(),
    hasScheduledOrders: Yup.boolean(),
    hasMultipleIntegrations: Yup.boolean(),
    hasBatching: Yup.boolean(),
    scheduleAheadInterval: Yup.number().when("hasScheduledOrders", {
        is: true,
        then: Yup.number().required(),
        otherwise: Yup.number()
    }),
    scheduledOrderMinLeadTime: Yup.number().min(0),
    scheduleAheadBatchSize: Yup.number().min(0),

    scheduledOrderInt: Yup.number().min(0),
    isArchived: Yup.boolean().required(),
    account: Yup.string().nullable(),
    accountManager: Yup.string().nullable(),
    accountExecutive: Yup.string().nullable(),
    numFreeTrialPurchases: Yup.number(),
    shouldCheckFreeTrial: Yup.boolean(),
    storeBuilderSectionExpanded: Yup.boolean(),
    pickupOptionsSectionExpanded: Yup.boolean(),
    tipOptionsExpanded: Yup.boolean(),
    deliveryOptionsSectionExpanded: Yup.boolean(),
    commissionSectionExpanded: Yup.boolean(),
    creditCardPolicySectionExpanded: Yup.boolean(),
    integrationsSectionExpanded: Yup.boolean(),
    convenienceSectionExpanded: Yup.boolean(),
    tabletOptionsSectionExpanded: Yup.boolean(),
    autoAcceptOrdersSectionExpanded: Yup.boolean(),
    recurringPaymentsSectionExpanded: Yup.boolean(),
    posTogglesExpanded: Yup.boolean(),
    kdsTogglesExpanded: Yup.boolean(),
    updatedAt: Yup.date(),
    isEnterprise: Yup.boolean().required(),
    dashboardPreferences: Yup.object().shape({
        bypassRecurringPaymentsRestriction: Yup.boolean()
    })
});

export const warningSchema = Yup.object().shape({
    hasBatching: Yup.boolean(),
    scheduleAheadBatchSize: Yup.number()
        .min(0)
        .when(["hasBatching", "hasScheduledOrders"], {
            is: (hasBatching: boolean, hasScheduledOrders: boolean) => {
                return hasBatching && hasScheduledOrders;
            },
            then: Yup.number().max(
                5,
                "⚠️ Warning! ⚠️ Store has `schedule ahead` on and a `batching size` greater than 5, which might be a lot of orders for the store."
            ),
            otherwise: Yup.number()
        }),
    hasPickupNow: Yup.boolean().when(["hasBatching"], {
        is: true,
        then: Yup.boolean().oneOf(
            [false],
            "⚠️ Warning! ⚠️ Store has pickup ASAP and batching on, are you sure pickup ASAP should be on?"
        ),
        otherwise: Yup.boolean()
    }),
    hasScheduledOrders: Yup.boolean().when(["integrations"], {
        is: (integrations: {
            onzway?: { enabled: boolean };
            chowly?: { enabled: boolean };
            checkmate?: { enabled: boolean };
            otter?: { enabled: boolean };
            smarpus?: { enabled: boolean };
        }) => {
            return (
                integrations.onzway?.enabled ||
                integrations.chowly?.enabled ||
                integrations.checkmate?.enabled ||
                integrations.otter?.enabled ||
                integrations.smarpus?.enabled
            );
        },
        then: Yup.boolean().oneOf(
            [false],
            "⚠️ Warning! ⚠️ Store has integrations `checkmate`, `otter`, `onzway`, `chowly`, and/or `smarpus` which are known not to work well with `schedule ahead`"
        ),
        otherwise: Yup.boolean()
    }),
    hasMultipleIntegrations: Yup.boolean().when(["integrations"], {
        is: (integrations: {
            chowly?: { enabled: boolean };
            checkmate?: { enabled: boolean };
            otter?: { enabled: boolean };
        }) => {
            if (
                integrations.chowly?.enabled &&
                integrations.checkmate?.enabled
            ) {
                return true;
            } else if (
                integrations.chowly?.enabled &&
                integrations.otter?.enabled
            ) {
                return true;
            } else if (
                integrations.otter?.enabled &&
                integrations.checkmate?.enabled
            ) {
                return true;
            }
            return false;
        },
        then: Yup.boolean().oneOf(
            [false],
            "⚠️ Warning! ⚠️ Stores can't have more than one 3P integration enabled at the same time."
        ),
        otherwise: Yup.boolean()
    }),
    integrations: Yup.object(),
    serverAutoAcceptsOrders: Yup.boolean().when(["integrations"], {
        is: (integrations: { [key: string]: { enabled: boolean } }) => {
            const INTEGRATIONS_OMIT: string[] = [
                Integration.Smarpus,
                Integration.Postmates,
                Integration.Doordash
            ];

            for (const key of Object.keys(integrations || {})) {
                const integration = _.get(integrations, key);

                if (_.includes(INTEGRATIONS_OMIT, key)) {
                    // Stores with these integrations use Snackpass tablets.
                    continue;
                }

                if (_.get(integration, "enabled")) {
                    return true;
                }
            }
            return false;
        },
        then: Yup.boolean().oneOf(
            [true],
            "⚠️ Warning! ⚠️ Stores with integrations should have `Integration auto-accepts orders` enabled!"
        ),
        otherwise: Yup.boolean()
    })
});
