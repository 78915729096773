import React, { useState } from "react";
import { over, flow } from "lodash/fp";

interface Props {
    onChange(value?: number): any;
    placeholder: string;
    style: React.CSSProperties;
}
/**
 * function to transform on change input event into a number or undefined.
 * @param {any} e - on input changed event
 * @return {number | undefined} value - number greater than 0 or undefined
 */
const transformInputValue = (e: any) => {
    // dont allow input values lower than 0 for order number
    const orderNumber =
        e.target.value !== "" && Number(e.target.value) > 0
            ? Number(e.target.value)
            : undefined;
    return orderNumber;
};

const OrderNumberInput = ({ onChange, placeholder, style }: Props) => {
    const [value, setValue] = useState<number | undefined>();

    /**
     * on key down event handler. Resets input to placeholder if all digits are removed
     * @param {any} e - on key down event
     * @return {void}
     */
    const onKeyDown = (e: any) => {
        if (e.key === "Backspace" && `${e.target.value}`.length === 1) {
            setValue(undefined);
            onChange();
        }
    };

    // on change event: first transform input value, then call onChange and set state
    const onChangeHandler = flow([
        transformInputValue,
        over([onChange, setValue]),
    ]);

    return (
        <input
            value={value || ""}
            placeholder={placeholder}
            style={style}
            type="number"
            onChange={onChangeHandler}
            onKeyDown={onKeyDown}
        />
    );
};

export default OrderNumberInput;
