import React from "react";
import api from "../../api";
import moment from "moment";
import "moment-timezone";
import { View, Text, Button } from "../../SharedComponents";
import { Colors, Styles } from "../../utils";

import { DateRangePicker } from "react-dates";
import * as Sentry from "@sentry/browser";
import { SnackpassTimezoneEnum } from "@snackpass/snackpass-types";
export default class BasicSheet extends React.Component {
    state = {
        startDate: null,
        endDate: null,
        focusedInput: null
    };
    _getHeader = () => {
        return (
            <Text xxlarge bold style={styles.header}>
                Accounting Payouts
            </Text>
        );
    };
    _getDescription = () => {
        return (
            <Text style={styles.description}>Choose Inclusive Date Range</Text>
        );
    };
    _getGenerateCSVButton = () => {
        const { startDate, endDate } = this.state;
        if (!startDate || !endDate) {
            return null;
        }

        return (
            <View style={styles.CSVButtonContainer}>
                <Button
                    label={"Generate CSV"}
                    style={styles.CSVButton}
                    onPress={this.generateCSV}
                />
            </View>
        );
    };
    generateCSV = () => {
        const { startDate, endDate } = this.state;

        let localTimezoneOffset =
            moment().utcOffset() -
            moment().tz(SnackpassTimezoneEnum.newYork).utcOffset();

        //@ts-ignore
        let start = moment(startDate)
            .add(localTimezoneOffset, "minutes")
            .toDate();
        //@ts-ignore
        let end = moment(endDate).add(localTimezoneOffset, "minutes").toDate();

        let params = {
            startDate: start.toString(),
            endDate: end.toString()
        };

        api.stores
            .getPayouts(params)
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                    "download",
                    `Payouts-${start.toLocaleDateString(
                        "en-US"
                    )}-${end.toLocaleDateString("en-US")}.csv`
                );
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {
                Sentry.captureException(err);
                console.log(err.response);
            });
    };
    _getDateRangeInput = () => {
        const { startDate, endDate, focusedInput } = this.state;
        return (
            <DateRangePicker
                startDate={startDate}
                startDateId="your_unique_start_date_id"
                endDate={endDate}
                endDateId="your_unique_end_date_id"
                onDatesChange={({
                    startDate,
                    endDate
                }: {
                    startDate: Date;
                    endDate: Date;
                }) => {
                    this.setState({
                        startDate: startDate,
                        endDate: endDate
                    });
                }}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput: any) =>
                    this.setState({ focusedInput: focusedInput })
                }
                isOutsideRange={() => {
                    return false;
                }}
            />
        );
    };

    render() {
        return (
            <View style={Styles.container}>
                {this._getHeader()}
                {this._getDescription()}
                {this._getDateRangeInput()}
                {this._getGenerateCSVButton()}
            </View>
        );
    }
}

const styles = {
    container: {
        padding: 20,
        backgroundColor: Colors.white,
        borderRadius: 8,
        ...Styles.shadowLight
    },
    header: { paddingBottom: 20, paddingTop: 20 },
    description: { paddingBottom: 10, paddingTop: 10 },
    CSVButtonContainer: { alignItems: "flex-end", marginTop: 10 },
    CSVButton: { height: 30, width: 100 }
};
