import React from "react";
import Swal from "sweetalert2";
import { Button } from "../../../SharedComponents";
import { Colors } from "../../../utils";

type infoButtonProps = {
    title: string;
    infoText: string[];
    diameter?: number;
};

//An easy, circular info button making use of Sweet Alert 2.
//Each new line of text you want to see should be its own infoText array element
const InfoButton = ({ title, infoText, diameter = 35 }: infoButtonProps) => {
    const makeText = () => {
        const br: string = "<br> <br>";
        let text = "";
        for (let line of infoText) text += line + br;
        return text;
    };
    const handlePress = () => {
        Swal.fire({
            type: "info",
            title: title,
            html: makeText(),
        });
    };
    return (
        <Button
            label="i"
            style={{
                width: diameter,
                height: diameter,
                borderRadius: diameter / 2,
                borderColor: Colors.gray,
                backgroundColor: Colors.lightGray,
                color: Colors.gray,
            }}
            onPress={handlePress}
        />
    );
};

export default InfoButton;
