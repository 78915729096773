import _ from "lodash";
import React, { Component } from "react";
import Select from "react-select";

import { IOption } from "../../../redux/types";

// Some of the message type are comment out for now, we will be revamping notifications with better infrastructure soon with knock.app

export type MessageType =
    | "all"
    | "admin_message"
    // | "customer_milestone"
    | "daily_clicks"
    | "daily_customer"
    | "daily_new_customers_report"
    // | "daily_promo_reminder"
    | "daily_sales_report"
    | "daily_salutation"
    // | "daily_summary"
    // | "monthly_sales_report"
    // | "monthly_slow_hours"
    | "payout_completed"
    | "payout_initiated"
    // | "sales_milestone"
    | "store_paused_alert"
    | "weekly_sales_report"
    // | "weekly_summary"
    | "weekly_top_store"
    | "new_online_order"
    | "new_app_order"
    | "new_kiosk_order";

export let MESSAGE_TYPE_TO_LABEL: { [key in MessageType]: string } = {
    all: "All messages",
    admin_message: "Admin message",
    // customer_milestone: "Customer milestone",
    daily_clicks: "Daily clicks",
    daily_customer: "Daily customer",
    daily_new_customers_report: "Daily new customers report",
    // daily_promo_reminder: "Daily promo reminder",
    daily_sales_report: "Daily sales report",
    daily_salutation: "Daily salutation",
    // daily_summary: "Daily summary",
    // monthly_sales_report: "Monthly sales report",
    // monthly_slow_hours: "Monthly slow hours",
    payout_completed: "Payout completed",
    payout_initiated: "Payout initiated",
    // sales_milestone: "Sales milestone",
    store_paused_alert: "Store paused alert",
    weekly_sales_report: "Weekly sales report",
    // weekly_summary: "Weekly summary",
    weekly_top_store: "Weekly top store",
    new_online_order: "New online order notifications",
    new_app_order: "New app notifications",
    new_kiosk_order: "New kiosk notifications"
};

export let MESSAGE_TYPES: MessageType[] = Object.keys(
    MESSAGE_TYPE_TO_LABEL
) as MessageType[];

type Props = {
    style?: Object;
    value: Set<MessageType>;
    onChange: (value: Set<MessageType>) => void;
};

export default class NotificationPreferenceSelect extends Component<Props> {
    // Returns true if the all option is selected
    isAll = () => {
        let { value } = this.props;
        return Array.from(value).includes("all");
    };

    // Returns true if none of items are disabled
    isNone = () => {
        let { value } = this.props;
        return value.size === 0;
    };
    // Build the selected values accounting for if none
    // are selected
    buildSelectedValues = () => {
        let { value } = this.props;
        if (this.isAll()) return { value: "all", label: "All messages" };
        if (this.isNone()) return { value: "NONE", label: "None" };
        return Array.from(value).map((value: MessageType) => ({
            value,
            label: MESSAGE_TYPE_TO_LABEL[value]
        }));
    };
    // Handle when the react-select is multiselect,
    // (this happens when there is greater than 1 options selected)
    // which is when isMulti for the react-select is true
    handleMultiselectOnChange = (items: IOption[]) => {
        let itemsSet: Set<any> = new Set(items.map((item) => item.value));
        if (itemsSet.has("NONE")) {
            return this.props.onChange(new Set([]));
        }
        return this.props.onChange(itemsSet);
    };
    // Handle single select,
    // This happens when the value is "NONE" and then is changed
    // to specific options instead
    handleSingleSelect = (item: IOption) => {
        if (item.value === "NONE") {
            return this.props.onChange(new Set([]));
        }
        return this.props.onChange(new Set([item.value] as MessageType[]));
    };
    // Builds all options including NONE
    // so can easily toggle none options
    buildSelectOptions = () => {
        return [
            ...Object.entries(MESSAGE_TYPE_TO_LABEL).map((entry: any) => ({
                value: entry[0],
                label: entry[1]
            })),
            { value: "NONE", label: "None" }
        ];
    };
    render() {
        return (
            <Select
                className="snackpass__react-select"
                // only multi if not none is selected
                // otherwise it is a single select with the option none
                isMulti={!this.isAll() && !this.isNone()}
                value={this.buildSelectedValues()}
                placeholder={"select option"}
                options={this.buildSelectOptions()}
                onChange={(items: IOption | IOption[]) => {
                    // if it is an array, handle multiselect
                    if (_.isArray(items)) {
                        return this.handleMultiselectOnChange(items);
                    }
                    // Otherwise it is single select (all or NONE)
                    return this.handleSingleSelect(items);
                }}
            />
        );
    }
}
