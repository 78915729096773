import React from "react";
import { ArrayHelpers, FieldArray, FormikProps } from "formik";
import { FormValues } from "../types";

import FeePolicies from "./FeePolicies";
import { Button, Input, Text } from "../../../SharedComponents";
import { Colors } from "../../../utils";
import { deliveryRangeTemplate, DELIVERY_FEE_POLICY_TYPES } from "../config";
import { IDeliveryRange } from "@snackpass/snackpass-types";
import { DELIVERY_FEE_POLICY_TEMPLATE } from "src/utils/Templates";

type Props = {
    formProps: FormikProps<FormValues>;
    isCA: boolean;
};

const DeliveryRangesEditor = (props: Props) => {
    const values = props.formProps.values;
    return (
        <FieldArray
            name="deliveryRanges"
            render={(arrayHelpers) => (
                <div>
                    <DeliveryRanges
                        values={values}
                        arrayHelpers={arrayHelpers}
                    />
                    <Button
                        label="Add Range"
                        type="button"
                        onPress={() => {
                            arrayHelpers.push({
                                ...deliveryRangeTemplate(props.isCA)
                            });
                        }}
                    />
                </div>
            )}
        />
    );
};

const DeliveryRanges = ({
    values,
    arrayHelpers
}: {
    values: FormValues;
    arrayHelpers: ArrayHelpers;
}) => (
    <div>
        {values.deliveryRanges.map((deliveryRange, index: number) => (
            <Range
                key={index}
                deliveryRange={deliveryRange}
                index={index}
                arrayHelpers={arrayHelpers}
            />
        ))}
    </div>
);

const Range = ({
    index,
    deliveryRange,
    arrayHelpers
}: {
    index: number;
    deliveryRange: IDeliveryRange;
    arrayHelpers: ArrayHelpers;
}) => (
    <div>
        <div style={styles.rangeTitleContainer}>
            <Text bold>Range #{index + 1}</Text>
            <Button
                label="Remove Range"
                backgroundColor={Colors.red}
                type="button"
                onPress={() => arrayHelpers.remove(index)}
            />
        </div>
        <Input
            type="number"
            label="Start (miles)"
            placeholder="start (0)"
            name={`deliveryRanges.${index}.start`}
        />
        <Input
            type="number"
            label="End (miles)"
            placeholder="end (0)"
            name={`deliveryRanges.${index}.end`}
        />

        {/* only show the delivery fee input if there are no fee policies */}
        <Input
            label="1P Delivery fee"
            type="number"
            placeholder="delivery fee"
            description="Delivery fee for the restaurant for 1P delivery."
            name={`deliveryRanges.${index}.deliveryFee`}
        />

        <Input
            label="Delivery min"
            type="number"
            placeholder="delivery min"
            name={`deliveryRanges.${index}.deliveryMin`}
        />
        <FeePolicies
            path={`deliveryRanges.${index}.feePolicies`}
            // Note: we hide is taxable atm bc by default all delivery fees
            // are taxable so this probably would require some backend changes
            // to support. something to do in the future
            showTaxable={false}
            policies={deliveryRange.feePolicies || []}
            template={DELIVERY_FEE_POLICY_TEMPLATE}
            options={DELIVERY_FEE_POLICY_TYPES}
        />
    </div>
);

const styles = {
    rangeTitleContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 8
    }
};

export default DeliveryRangesEditor;
