import React from "react";
import { Colors } from "../../../utils";
import { Button } from "../../../SharedComponents";

interface MyInputEmailProps {
    field: any;
    form: any;
    index: number;
    value: string;
}

// TODO (Sean & Connie): see if it's possible to have Formik default `null` to empty string so we don't need the truthy
// check throughout all the email-relevant bits of Store Form
export class MyInputEmail extends React.Component<MyInputEmailProps> {
    handleChange = (event: any) => {
        const { index, form } = this.props;

        let value = event.target.value;
        // prevent commas from being inputted
        if (value[value.length - 1] === ",") {
            return;
        }
        let emails = form.values.email ? form.values.email.split(",") : [];
        // add 1 item at index by replacing item there
        emails.splice(index, 1, value);
        form.setFieldValue("email", emails.join(","));
    };

    handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched.topcis
        this.props.form.setFieldTouched("email", true);
    };

    render() {
        const { form, index, value } = this.props;

        return (
            <div>
                <input
                    type="text"
                    value={value}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                />
                <Button
                    label="Remove"
                    type="button"
                    onPress={() => {
                        let emails = form.values.email
                            ? form.values.email.split(",")
                            : [];
                        emails.splice(index, 1); // remove 1 item at given index
                        form.setFieldValue("email", emails.join(","));
                    }}
                    backgroundColor={Colors.red}
                />
            </div>
        );
    }
}

export default MyInputEmail;
