import React, { CSSProperties } from "react";
import { View, TextInput } from "../SharedComponents";
import { SystemColors } from "@snackpass/design-system";

const COLOR_OPTIONS = [
    SystemColors.v1.dragonfruit70,
    SystemColors.v1.dragonfruit60,
    SystemColors.v1.melon70,
    SystemColors.v1.melon60,
    SystemColors.v1.apricot60,
    SystemColors.v1.apricot50,
    SystemColors.v1.candy70,
    SystemColors.v1.candy60,
    SystemColors.v1.blueberry70,
    SystemColors.v1.blueberry60,
    SystemColors.v1.ube70,
    SystemColors.v1.ube60,
    SystemColors.v1.ube50,
];

interface IBlock {
    onPress?: Function;
    color?: string;
    key?: string;
}

export const Block = ({ onPress, color }: IBlock) => (
    <View
        onPress={() => {
            onPress && onPress(color);
        }}
        style={{
            backgroundColor: color,
            margin: 10,
            height: 20,
            width: 20,
            borderRadius: 8,
            cursor: "auto",
        }}
    />
);

export const getRandomColor = () => {
    return COLOR_OPTIONS[Math.floor(Math.random() * COLOR_OPTIONS.length)];
};

export interface IColorPicker {
    onChange: (value: string) => void;
    selectedColor: string;
}

export const ColorPicker = ({ onChange, selectedColor }: IColorPicker) => (
    <View>
        <View style={styles.firstRow}>
            <TextInput
                placeholder="color"
                value={selectedColor}
                onChangeText={color => onChange(color)}
            />

            <Block color={selectedColor} />
        </View>
        <View style={styles.colorRow}>
            {COLOR_OPTIONS.map(color => {
                return <Block key={color} color={color} onPress={onChange} />;
            })}
        </View>
    </View>
);

const styles = {
    firstRow: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    } as CSSProperties,
    colorRow: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    } as CSSProperties,
};
