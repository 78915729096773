import { connect } from "react-redux";
import GiftBuilder from "../components/GiftBuilder";
import { StoreState } from "../redux/types";
import { Dispatch } from "redux";

const mapStateToProps = (state: StoreState) => {
    return {
        user: state.activeUser.user,
        stores: state.stores,
    };
};

// create handler than dispatches an action
const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        dispatch,
    };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(GiftBuilder);

export default Container;
