import * as Sentry from "@sentry/browser";
import { IStore } from "@snackpass/snackpass-types";
import Clipboard from "clipboard";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import { isMobile } from "react-device-detect";
import Select from "react-select";
import swal from "sweetalert2";

// Import CSS
import "react-confirm-alert/src/react-confirm-alert.css";

import api from "../../api";
import { View, Text, TouchableHighlight, Toggle } from "../../SharedComponents";
import Closed from "./components/Closed";
import OnlineStatus from "./components/OnlineStatus";
import { Row, Col } from "../../SharedComponents";
import Tablets from "./components/Tablets";
import { Colors } from "../../utils/Colors";
import { ReduxUtil } from "../../utils/ReduxUtil";
import * as Helpers from "../../utils/Helpers";
import { Constants } from "../../utils";
import { Block } from "../../components/ColorPicker";
import { BeatLoader } from "react-spinners";
import { StoreRowProps, StoreRowState } from "./utils/types";
import { styles } from "./utils/styles";
import {
    generateStoreRowDropdownOptions,
    handleSelectDropdownOption
} from "./utils/StoreRowDropdown";
import { IOption } from "../../redux/types";

import Labels from "./components/Labels";

const NO_PRIORITY_SET = "n/a -❗️";
const PRIORITY_OPTIONS = [
    { label: "1 - 🐟", value: 1 },
    { label: "2 - 🐬", value: 2 },
    { label: "3 - 🐳", value: 3 }
];
export default class StoreRow extends Component<StoreRowProps, StoreRowState> {
    state = {
        refreshTablets: 0,
        promotionsVisible: false,
        promotionsLoaded: false,
        tabletsInformationVisible: false,
        tabletsInformationLoaded: false,
        tablets: [],
        isLoadingTablets: false
    };

    componentDidMount = () => {
        new Clipboard(".copy-button");
    };

    hasRewards = () => {
        const { storeObj } = this.props;

        if (storeObj.promotions) {
            return storeObj.promotions.some((promo) => {
                return promo.type === "REWARD";
            });
        }
        return false;
    };

    handleToggleLive = () => {
        const { storeObj, setStore } = this.props;
        const isLive = !storeObj.isLive;

        api.stores
            .update(storeObj._id, {
                isLive
            })
            .then((response) => {
                setStore(response.data.store);
                api.logs.send({
                    channel: "forge-store-status",
                    title: `${storeObj.name} in ${storeObj.region}`,
                    description: `Turned ${storeObj.isLive ? "OFF" : "LIVE"}`
                });
            })
            .catch((err) => {
                Sentry.captureException(err);
                console.log(err.response);
            });
    };

    handleStoreArchive = () => {
        const {
            storeObj,
            removeStore,
            addStore,
            addArchivedStore,
            removeArchivedStore
        } = this.props;
        const { _id, isArchived, salesforceId } = storeObj;

        api.stores
            .update(_id, {
                isArchived: !isArchived,
                salesforceId: isArchived ? salesforceId : null
            })
            .then((response) => {
                let store = response.data.store;
                // remove from redux if archived,
                // otherwise add it to stores in redux
                if (store.isArchived) {
                    removeStore(store._id);
                    addArchivedStore(store);
                } else {
                    removeArchivedStore(store._id);
                    addStore(store);
                }
                api.logs.send({
                    channel: "logs-ops",
                    title: `${store.name} in ${store.region}`,
                    description: `${
                        store.isArchived ? "Archived" : "Un-archived"
                    } store`
                });
            })
            .catch((err) => {
                Sentry.captureException(err);
                console.log(err.response);
            });
    };

    changeStorePriority = async (salesPriority: number) => {
        const { storeObj, setStore } = this.props;
        if (storeObj.salesPriority === salesPriority) {
            return;
        }

        try {
            const res = await api.stores.update(storeObj._id, {
                salesPriority
            });
            const { store } = res.data;
            setStore(store);
        } catch (err) {
            Sentry.captureException(err);
            console.log(err.response);
        }
    };

    toggleAlert = async () => {
        const { storeObj } = this.props;

        // Prevent turning archived stores live
        const newIsLive = !storeObj.isLive;
        if (storeObj.isArchived && newIsLive) {
            swal.fire(
                "Cannot Make Live",
                `You cannot make an archived store live. If you would like to make this store live, please un-archive it first.`,
                "error"
            );
            return;
        }

        // Make the confirmation message object
        let message: any = {
            title: "Confirm to submit"
        };
        // Toggle the store live
        if (!storeObj.isLive) {
            await this.loadPromotions();
            // Only stores with reward promotions can be turned live
            const missingData = [];
            if (!this.hasRewards()) missingData.push("reward promotions");
            if (!storeObj.salesforceId) missingData.push("Salesforce IDs");
            if (missingData.length) {
                let message: any = {
                    title: `🚨 Cannot turn ${storeObj.name} live`,
                    message: `Only stores with ${missingData.join(
                        " and "
                    )} can be turned live!`,
                    buttons: [
                        {
                            label: "OK",
                            onClick: () => {}
                        }
                    ]
                };
                confirmAlert(message);
                return;
            }
            message.message = `Are you sure you want to toggle ${storeObj.name} to live? Customers will be able to see your store on the Snackpass app.`;
            message.buttons = [
                {
                    label: "Cancel",
                    onClick: () => {}
                },
                {
                    label: "Turn LIVE",
                    onClick: () => {
                        this.handleToggleLive();
                    }
                }
            ];
        } else {
            message.message = `Are you sure you want to toggle ${storeObj.name} off? Customers will not be able to see your store on the Snackpass app.`;
            message.buttons = [
                {
                    label: "Turn OFF",
                    onClick: () => {
                        this.handleToggleLive();
                    }
                },
                {
                    label: "Keep LIVE",
                    onClick: () => {}
                }
            ];
        }
        // Make alert
        confirmAlert(message);
    };

    toggleTabletsInformation = () => {
        if (!this.state.tabletsInformationVisible) {
            this.setState({
                refreshTablets: setInterval(this.loadTabletsInformation, 5000)
            });
        } else {
            clearInterval(this.state.refreshTablets);
        }
        this.setState({
            tabletsInformationVisible: !this.state.tabletsInformationVisible,
            promotionsVisible: !this.state.tabletsInformationVisible
                ? false
                : this.state.promotionsVisible
        });
        if (!this.state.tabletsInformationLoaded) {
            this.setState({ isLoadingTablets: true });
            this.loadTabletsInformation().finally(() => {
                this.setState({ isLoadingTablets: false });
            });
        }
    };

    loadPromotions = async () => {
        const { storeObj, dispatch } = this.props;
        this.setState({
            promotionsLoaded: true
        });

        await ReduxUtil.loadStorePromotions(storeObj._id, dispatch);
    };

    loadTabletsInformation = async () => {
        const { storeObj } = this.props;

        await api.stores
            .getTablets(storeObj._id)
            .then((response) => {
                this.setState({
                    tablets: response.data.tablets,
                    tabletsInformationLoaded: true
                });
            })
            .catch((err) => {
                Sentry.captureException(err);
                console.log(err.response);
            });
    };

    getSalesPriority = (prior: number | undefined) => {
        const priority = PRIORITY_OPTIONS.find(
            (option) => option.value === prior
        );
        return priority ? priority.label : NO_PRIORITY_SET;
    };

    _renderToggle = () => {
        const { storeObj } = this.props;
        if (storeObj._id === Constants.HOGWARTS_ID) {
            return null;
        }
        return (
            <Toggle
                activeLabel="LIVE"
                inactiveLabel="OFF"
                value={storeObj.isLive}
                onToggle={this.toggleAlert}
            />
        );
    };

    _handleSelectAction = async (item: any) => {
        handleSelectDropdownOption(
            item,
            this.props.storeObj,
            this.props.showModal,
            this.handleStoreArchive
        );
    };

    _getRegion = (store: IStore) => {
        const region = this.props.regions.find(
            (region) => region.slug === store.region
        );
        return region && region.name;
    };

    _getTabletWarning = (store: IStore) => {
        const hasPrimaryTablet = Helpers.isTruthy(store.primaryStoreTablet);
        const missingTablet = store.hasTablet && !hasPrimaryTablet;
        return missingTablet ? "No primary tablet" : null;
    };

    _renderMobile = () => {
        const { storeObj, setStore } = this.props;
        const regionName = this._getRegion(storeObj);

        return (
            <div style={styles.mobileRowContainer}>
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        paddingTop: "8px",
                        paddingLeft: "5px",
                        paddingBottom: "20px"
                    }}
                >
                    <Text large bold center>
                        {storeObj.name}
                    </Text>
                </div>
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                            padding: 5,
                            justifyContent: "space-between",
                            maxWidth: "40%"
                        }}
                    >
                        <Text small>{regionName}</Text>
                        <p />
                        {Helpers._displayNumber(
                            storeObj.phoneNumber || "",
                            "tel",
                            Colors.gray
                        )}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                            padding: 5,
                            justifyContent: "space-between",
                            maxWidth: "30%"
                        }}
                    >
                        <OnlineStatus storeObj={storeObj} />
                        {this._renderToggle()}
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                            alignContent: "center",
                            maxWidth: "30%",
                            cursor: "pointer"
                        }}
                    >
                        <Closed
                            storeObj={storeObj}
                            setStore={setStore}
                            onShowModal={() =>
                                this.props.showModal("StorePauseModal", {
                                    store: storeObj
                                })
                            }
                        />
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        alignSelf: "flexStart",
                        paddingTop: "10px"
                    }}
                >
                    <Select
                        className="snackpass__react-select"
                        placeholder={"Actions"}
                        options={generateStoreRowDropdownOptions(storeObj)}
                        onChange={this._handleSelectAction}
                    />
                </div>
            </div>
        );
    };

    _renderWeb = () => {
        const { storeObj, setStore } = this.props;
        const tabletVersionLabel =
            storeObj.tabletVersion +
            " " +
            (this.state.tabletsInformationVisible ? "🔽" : "▶️");
        const regionName = this._getRegion(storeObj);
        const tabletWarning = this._getTabletWarning(storeObj);
        return (
            <Row>
                <Col flex={1}>
                    <Select
                        name="salesPriority"
                        options={PRIORITY_OPTIONS}
                        placeholder={this.getSalesPriority(
                            storeObj.salesPriority
                        )}
                        styles={{
                            control: (provided: any) => ({
                                ...provided,
                                width: 120,
                                marginRight: 10,
                                border: "none"
                            })
                        }}
                        onChange={(item: IOption) => {
                            if (!item) return;
                            this.changeStorePriority(Number(item.value));
                        }}
                    />
                </Col>
                <Col flex={1}>
                    <Block color={String(storeObj.color)} />
                </Col>
                <Col flex={1}>
                    <span role="img">{storeObj.emoji}</span>
                </Col>
                <Col flex={2}>
                    <Text small>{storeObj.name}</Text>
                    {Helpers._displayNumber(
                        storeObj.phoneNumber || "",
                        "tel",
                        Colors.gray
                    )}
                </Col>
                <Col flex={1}>
                    <Text small>{regionName}</Text>
                </Col>
                <Col flex={1}>
                    {storeObj.hasTablet && storeObj.tabletVersion ? (
                        <TouchableHighlight
                            style={{
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                            onPress={this.toggleTabletsInformation}
                        >
                            <Text small>{tabletVersionLabel}</Text>
                        </TouchableHighlight>
                    ) : null}
                </Col>
                <Col flex={1}>
                    <Text small color={Colors.red}>
                        {tabletWarning}
                    </Text>
                </Col>
                <Col flex={2}>
                    <div>
                        <Text small>⏱ {storeObj.hoursDescription}</Text>
                        <br />
                        <Labels store={storeObj} />
                    </div>
                </Col>
                <Col flex={0.5}>
                    <OnlineStatus storeObj={storeObj} />
                </Col>
                <Col flex={1} style={{ cursor: "pointer" }}>
                    <Closed
                        storeObj={storeObj}
                        setStore={setStore}
                        onShowModal={() =>
                            this.props.showModal("StorePauseModal", {
                                store: storeObj
                            })
                        }
                    />
                </Col>
                <Col flex={1}>{this._renderToggle()}</Col>
                <Col flex={1}>
                    <Select
                        className="snackpass__react-select"
                        placeholder={"actions"}
                        options={generateStoreRowDropdownOptions(storeObj)}
                        onChange={this._handleSelectAction}
                    />
                </Col>
            </Row>
        );
    };
    render() {
        const { storeObj, setPrimaryStoreTablet } = this.props;
        const { tabletsInformationVisible, tablets } = this.state;

        let tabletSection = (
            <div
                style={{
                    padding: 20,
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <BeatLoader color={Colors.blue} size={10} />
            </div>
        );
        if (!this.state.isLoadingTablets) {
            tabletSection = (
                <Tablets
                    store={storeObj}
                    tablets={tablets}
                    visible={tabletsInformationVisible}
                    setPrimaryStoreTablet={setPrimaryStoreTablet}
                />
            );
        }

        return (
            <View>
                {isMobile ? this._renderMobile() : this._renderWeb()}
                {tabletSection}
            </View>
        );
    }
}
