import _ from "lodash";
import { compose, prop } from "lodash/fp";
import moment from "moment";
import {
    formatNumber,
    ParsedNumber,
    parseNumber,
    NumberFormat,
    CountryCode
} from "libphonenumber-js";
import { useSelector } from "react-redux";
import { IStore } from "@snackpass/snackpass-types";
import { IRegion } from "@snackpass/snackpass-types";
import validator from "validator";
import { IOption } from "../redux/types";
const google_libphonenumber = require("google-libphonenumber");

/**
 * Capitalizes a word
 *
 * @param s String
 */
export const capitalize = (s: any): string => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};

/**
 * This function adds commas to number and returns string
 *
 * @param {number} x a number representing USD
 * @return {string} the number as a string with commas every thousand digits
 *      ie: numberWithCommas(4560.43) => "$4,560.43"
 */
export const numberWithCommas = (
    x: number = 0,
    toFixed: number = 2
): string => {
    if (typeof x !== "number") {
        return "";
    }
    return x.toFixed(toFixed).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * Return whether a given string contains an integer.
 *
 * @param {string} x
 * @return {boolean}
 */
export const isIntegerString = (x?: string | number): boolean => {
    if (!x) {
        return false;
    }
    const parsed = Number.parseInt(x.toString());
    if (parsed === 0) {
        return true;
    }
    return parsed ? true : false;
};

export const dollarWithCommas = (x: number = 0): string => {
    return "$" + x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const intWithCommas = (x: number): string => {
    return x.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * This function adds commas to number and returns string
 *
 * @param {number} x a number representing USD
 * @return {string} the number as a string with commas every thousand digits
 *      ie: numberWithCommas(4560.43) => "4,560.43"
 */
export const monthDayYear = (date: Date): string => {
    return moment(date).format("MM/DD/YY");
};

export function formatArrayForSelect(items: Array<any>) {
    return items.map((item) => {
        return {
            value: item._id,
            label: item.name
        };
    });
}

export const formatEnumForSelect = (e_num: Object): IOption[] =>
    Object.entries(e_num).map((entry) => {
        return { label: entry[0], value: entry[1] };
    });

export function formatDateTime(date: Date) {
    return moment(date).format("ddd h:mm a");
}

export function formatDateTimeLong(date: Date) {
    return moment(date).format("ddd MMM D YYYY, h:mm a");
}

export const toPercentString = (
    value: number,
    precision: number = 0,
    padLength: number = 0,
    padChar: string = "0"
): string => {
    if (typeof value !== "number") {
        value = parseFloat(value);
    }
    return `${_.padStart(
        _.round(value * 100).toFixed(precision),
        padLength,
        padChar
    )}%`;
};

export const phoneFormat = (input: string | number) => {
    if (!input) {
        input = "";
    }
    if (typeof input !== "string") {
        input = input.toString();
    }
    // Strip all characters from the input except digits
    input = input.replace(/\D/g, "");

    // Trim the remaining input to ten characters, to preserve phone number format
    input = input.substring(0, 11);

    // Based upon the length of the string, we add formatting as necessary
    var size = input.length;
    if (size === 0) {
        // input = input;
    } else if (size < 4) {
        input = "(" + input;
    } else if (size < 7) {
        input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
    } else {
        input =
            "(" +
            input.substring(0, 3) +
            ") " +
            input.substring(3, 6) +
            "-" +
            input.substring(6, 10);
    }
    return input;
};

export const toDollar = (value: string | number) => {
    if (typeof value !== "number") {
        value = parseFloat(value);
    }
    return `$${(value || 0).toFixed(2)}`;
};

export function getSchool(email: string) {
    let emailParts = email.split("@");
    return emailParts[1] || "";
}

const validateEmailSpecialCharacters = (str: string) => {
    if (!str) {
        return false;
    }
    if (str.indexOf("+") !== -1) {
        return false;
    }
    return true;
};

export const copyToClipboard = (textToCopy: string) => {
    const input = document.createElement("input");
    document.body.appendChild(input);
    input.value = textToCopy;
    input.select();
    document.execCommand("copy");
    input.remove();
};

export const validateEmail = (email: string) => {
    if (!email) {
        return false;
    }
    if (!validateEmailSpecialCharacters(email)) {
        return false;
    }
    var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

export function arrToObj(arr: any[]) {
    let obj: any = {};
    arr.forEach((item) => {
        obj[item._id] = { ...item };
    });
    return obj;
}

export const _displayNumber = (
    number: string | null | undefined,
    linkType: string = "sms",
    color: string = "#0645AD",
    countryCode?: CountryCode
) => {
    if (number) {
        if (countryCode && countryCode !== "US") {
            return (
                <a href={`${linkType}:${number}`} style={{ color: color }}>
                    <span style={{ color: "red" }}>Non-US:</span>
                    {formatNumber(
                        number,
                        countryCode,
                        "International" as NumberFormat
                    )}
                </a>
            );
        } else {
            // strip all non-numerical characters to format number
            number = `+1${number.replace(/[^0-9]/gi, "")}`;
            return (
                <a href={`${linkType}:${number}`} style={{ color: color }}>
                    {formatNumber(number, "US", "National" as NumberFormat)}
                </a>
            );
        }
    }
    return null;
};

export function normalizePhoneNumber(phoneNumber: string): string | null {
    if (!phoneNumber) {
        return null;
    }
    let parsed = parseNumber(phoneNumber, "US");
    if (_.get(parsed, "phone")) {
        return formatNumber(parsed as ParsedNumber, "E.164");
    }
    return null;
}

/**
 * Return the result of dividing the numerator by the denominator, returning the zeroRes if the
 * denominator is zero.
 *
 * @param {number} numerator
 * @param {number} denominator
 * @param {any} zeroRes Defaults to null
 */
export function safeDivide(
    numerator: number,
    denominator: number,
    zeroRes: any = null
) {
    if (denominator === 0) {
        return zeroRes;
    }

    return numerator / denominator;
}

/**
 * Return whether or not the given value is truthy.
 *
 * @param {any} val
 * @return {boolean}
 */
export function isTruthy(val: any): boolean {
    return !!val;
}

export function isSubstring(baseString: string, subString: string): boolean {
    return baseString.indexOf(subString) >= 0;
}

/**
 * Handle whether a string is meant to be a phone number
 *
 * @param {string} val
 * @return {string}
 */
export function transformIfPhoneNumber(val: string) {
    // google-libphonenumber allows alpha chars in a phone number,
    // but we want to exclude those
    if (val.match(/[a-z]/i)) {
        return val;
    }

    // get an instance of `PhoneNumberUtil`.
    const phoneUtil = google_libphonenumber.PhoneNumberUtil.getInstance();
    const PNF = google_libphonenumber.PhoneNumberFormat;

    // just mutates US phone numbers, international ones remain the same
    try {
        let number = phoneUtil.parse(val, "US");
        if (!phoneUtil.isPossibleNumber(number)) {
            return val;
        }
        return phoneUtil.format(number, PNF.E164);
    } catch (err) {
        return val;
    }
}

export function isEmail(val: string) {
    return validator.isEmail(val);
}

export const getPathFromState: (path: string) => any = compose(
    useSelector,
    prop
);

export const alertAxiosError = compose(
    alert,
    (msg) => `ERROR: ${msg}`,
    prop("response.data.message")
);

export const getRegion = (store: IStore, regions: IRegion[]) => {
    const region = regions.find((region) => region.slug === store.region);
    return region && region.name;
};
