import firebase from "firebase/app";
import "firebase/auth";

var config = {
    apiKey: "AIzaSyDFYc6u8l2RZ0WvoocxpNHTc3bsQ3slLQ8",
    authDomain: "futura-c7f12.firebaseapp.com",
    databaseURL: "https://futura-c7f12.firebaseio.com",
    projectId: "futura-c7f12",
    storageBucket: "futura-c7f12.appspot.com",
    messagingSenderId: "616491088441"
};

firebase.initializeApp(config);

export default firebase;

export function waitForUser(fxn: Function, params?: any) {
    firebase.auth().onAuthStateChanged((u) => {
        setTimeout(() => {
            fxn(params);
        }, 100);
    });
}
