import {
    CustomFeeInDollars,
    FeePolicyPayer,
    IPurchase,
    PurchaseFeeEnum,
    ReservedFeeEnum
} from "@snackpass/snackpass-types";
import { sumBy } from "lodash/fp";
import prop from "lodash/fp/prop";
import React from "react";

import { Text, View } from "../../SharedComponents";
import { is3P } from "../../utils/Delivery";
import * as Helpers from "../../utils/Helpers";

type Props = { purchase: IPurchase };

const _getDeliveryFee = (purchase: IPurchase): number => {
    if (purchase.fulfillment !== "DELIVERY") {
        return 0;
    }

    if (is3P(purchase)) {
        // lookup 3p fees from fees list if it is there
        const deliveryFees = purchase.fees?.filter(
            (f) => f.fee.name === ReservedFeeEnum.DeliveryFee3P
        );

        // take the sum of the total. if there is a refund, you will see a delivery fee + a reverse,
        // ex. +$2.00 and then -$2.00 which sum to 0
        const deliveryFeeTotal = sumBy("total", deliveryFees);

        return deliveryFees?.length
            ? deliveryFeeTotal
            : purchase.deliveryFee || 0;
    }

    return purchase.deliveryFee || 0;
};

const Accounting = ({ purchase }: Props) => {
    const subtotal = Helpers.toDollar(purchase.subtotal || 0);
    const upchargeAmount = Helpers.toDollar(purchase.upChargeAmount || 0);
    const deliveryFee = Helpers.toDollar(_getDeliveryFee(purchase));
    const serviceFee = Helpers.toDollar(purchase.convenienceFee || 0);
    const customFees = prop("customFees", purchase) || [];
    const salesTaxAmount = Helpers.toDollar(purchase.salesTaxAmount || 0);
    const giftCardCredit = Helpers.toDollar(purchase.giftCardsCreditUsed || 0);
    const tip = Helpers.toDollar(purchase.tip || 0);
    const storeCreditUsed = Helpers.toDollar(purchase.storeCreditUsed || 0);
    const globalCreditUsed = Helpers.toDollar(purchase.globalCreditUsed || 0);
    const amountPaidByCustomer = Helpers.toDollar(
        purchase.amountPaidByCustomer || 0
    );

    return (
        <View>
            <Text>{`Accounting`}</Text>
            <Text small>{`Subtotal: ${subtotal}`}</Text>
            {purchase.upCharge && (
                <Text small>{`Upcharge: ${upchargeAmount}`}</Text>
            )}
            <Text small>{`Delivery fee: ${deliveryFee}`}</Text>
            <Text small>{`Service fee: ${serviceFee}`}</Text>
            {customFees.map((fee: any) => (
                <Text small>{`${fee.name}: ${Helpers.toDollar(
                    fee.total
                )}`}</Text>
            ))}
            <Text small>{`Tax: ${salesTaxAmount}`}</Text>
            <Text small>{`Tip: ${tip}`}</Text>
            <Text small>{`Gift card credit: ${giftCardCredit}`}</Text>
            <Text small>{`Store credit: ${storeCreditUsed}`}</Text>
            <Text small>{`Snackpass credit: ${globalCreditUsed}`}</Text>
            <CustomerFees purchase={purchase} />
            <Text small>{`Total: ${amountPaidByCustomer}`}</Text>
        </View>
    );
};

// list of fees to exclude from the customer fees section (for now this is only 3P delivery fee)
const FEES_TO_EXCLUDE = new Set<string>([ReservedFeeEnum.DeliveryFee3P]);

const CustomerFees = ({ purchase }: Props) => {
    const customerFees = purchase.fees?.filter(
        (f) =>
            f.fee.payer === FeePolicyPayer.Customer &&
            !FEES_TO_EXCLUDE.has(f.fee.name)
    );

    if (!customerFees?.length) {
        return null;
    }

    return (
        <View>
            <Text>Customer Fees:</Text>
            <br />
            {customerFees.map((f) => {
                let feeName = f.fee.name;
                if (f.fee.name === ReservedFeeEnum.SnackpassConvenienceFee) {
                    feeName = "Operating Fee";
                }
                return (
                    <Text small>{`${feeName}: ${Helpers.toDollar(
                        f.total
                    )}`}</Text>
                );
            })}
        </View>
    );
};

export default Accounting;
