/* eslint-disable no-unused-vars */
import { IStoreContact, IUser } from "@snackpass/snackpass-types";
import { MessageType } from "./components/NotificationPreferenceSelect";
import { ObjectId } from "src/redux/types";

export interface FormValues {
    firstName: string;
    lastName: string;
    email: string;
    number: string | null;
    snackpassPermissions: {
        accounting: boolean;
        storeRole: string;
        isSnackpassEmployee?: boolean;
    };
    permissions: {
        hasDashboard?: ObjectId[];
        hasOrders?: ObjectId[];
        hasFinancialReports?: ObjectId[];
        hasMenuEditing?: ObjectId[];
        hasTopCustomers?: ObjectId[];
        hasAnalytics?: ObjectId[];
        hasPromos?: ObjectId[];
        hasGuestbook?: ObjectId[];
        hasBilling?: ObjectId[];
        hasBillingWrite?: ObjectId[];
        hasEmployees?: ObjectId[];
        hasPayroll?: ObjectId[];
        hasSettings?: ObjectId[];
        hasSettingsWrite?: ObjectId[];
        hasEditAdmin?: ObjectId[];
        hasPayouts?: ObjectId[];
        isOwner?: ObjectId[];
        employeeId?: ObjectId;
        hasSalesReports?: ObjectId[];
        hasReportsMenu?: ObjectId[];
        hasReportsCustomers?: ObjectId[];
        hasReportsLocations?: ObjectId[];
        hasReportsPromotions?: ObjectId[];
        hasReportsLabor?: ObjectId[];
        hasReportsGiftCards?: ObjectId[];
        hasCatering?: ObjectId[];
        hasDevices?: ObjectId[];
    };
    disabledSmsCommunications: Set<MessageType>;
    disabledEmailCommunications: Set<MessageType>;
    internal: {
        storeContact: IStoreContact | null;
        matchingEmailUser: IUser | null;
        matchingNumberUser: IUser | null;
        loadingUsers: boolean;
        success?: string;
        error?: string;
    };
}

export interface storesNamesMap {
    [storeId: string]: string;
}

// Typescript complaining that this is never used but it is used in apps/snackface/src/screens/Admins/components/AdminsTable/index.tsx
export enum ToggleablePermissions {
    hasDashboard = "hasDashboard",
    hasOrders = "hasOrders",
    hasMenuEditing = "hasMenuEditing",
    hasFinancialReports = "hasFinancialReports",
    hasTopCustomers = "hasTopCustomers",
    hasAnalytics = "hasAnalytics",
    hasPromos = "hasPromos",
    hasGuestbook = "hasGuestbook",
    hasBilling = "hasBilling",
    hasBillingWrite = "hasBillingWrite",
    hasEmployees = "hasEmployees",
    hasPayroll = "hasPayroll",
    hasSettings = "hasSettings",
    hasSettingsWrite = "hasSettingsWrite",
    hasSalesReports = "hasSalesReports",
    hasReportsMenu = "hasReportsMenu",
    hasReportsCustomers = "hasReportsCustomers",
    hasReportsLocations = "hasReportsLocations",
    hasReportsPromotions = "hasReportsPromotions",
    hasReportsLabor = "hasReportsLabor",
    hasReportsGiftCards = "hasReportsGiftCards",
    hasCatering = "hasCatering",
    hasDevices = "hasDevices",
    hasEditAdmin = "hasEditAdmin"
    // ---- Sensitive permissions
    // isOwner left out on purpose
    // hasPayouts left out on purpose
}
