// download QR code as png
export const downloadQR = (
    fileName: string, // name of the .png file
    canvasId: string // id of the canvas rendered by qrcode.react library
) => {
    const canvas = document.getElementById(canvasId) as HTMLCanvasElement;
    const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${fileName}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};
