import React, { CSSProperties } from "react";
import "rc-time-picker/assets/index.css";
import { Field, FieldArray, FormikProps } from "formik";
import { Colors } from "../../utils";
import { FormValues } from "./types";
import { tiersTemplate } from "./config";
import { Text } from "../../SharedComponents";

type Props = {
    formProps: FormikProps<FormValues>;
};

const PartyPromotionTiers = (props: Props) => (
    <FieldArray
        name="tiers"
        render={(arrayHelpers: any) => {
            return (
                <div style={styles.tiersContainer}>
                    {props.formProps.values.tiers &&
                    props.formProps.values.tiers.length > 0 ? (
                        props.formProps.values.tiers?.map((tier, n) => (
                            <div style={styles.rowContainer} key={n}>
                                <Field
                                    style={styles.peopleCount}
                                    name={`tiers.${n}.conditions.minPeople`}
                                    type="number"
                                    placeholder="min"
                                    min={0}
                                />
                                <span>to</span>
                                <Field
                                    style={styles.peopleCount}
                                    name={`tiers.${n}.conditions.maxPeople`}
                                    type="number"
                                    placeholder="max"
                                    min={1}
                                />
                                <span>people unlocks</span>
                                <Field
                                    style={styles.peopleCount}
                                    name={`tiers.${n}.discount.percentOff`}
                                    type="number"
                                    placeholder="%"
                                    min={0}
                                    max={100}
                                />
                                <span> % discount.</span>
                                <button
                                    type="button"
                                    style={styles.removeButton}
                                    onClick={() => arrayHelpers.remove(n)}
                                >
                                    remove
                                </button>
                            </div>
                        ))
                    ) : (
                        <Text small>Click + to add a new promotion tier</Text>
                    )}
                    <button
                        style={styles.addButton}
                        type="button"
                        onClick={() =>
                            arrayHelpers.push({
                                ...tiersTemplate,
                            })
                        }
                    >
                        +
                    </button>
                </div>
            );
        }}
    />
);

const styles = {
    tiersContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        marginTop: 8,
    } as CSSProperties,
    rowContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 50px",
        maxWidth: "95%",
        margin: "auto",
    },
    peopleCount: {
        width: 75,
        padding: 5,
        margin: "0 10px",
    },
    removeButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Colors.red,
        color: Colors.offWhite,
        padding: "4px 8px",
        marginLeft: 10,
    },
    addButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Colors.offWhite,
        color: Colors.text,
        height: 30,
        width: 30,
        margin: "10px 0",
    },
};

export default PartyPromotionTiers;
