import * as Sentry from "@sentry/browser";
import {
    IPurchase,
    Fulfillment,
    TransactionSource
} from "@snackpass/snackpass-types";
import { AxiosResponse } from "axios";
import moment, { Moment } from "moment-timezone";
import $ from "jquery";
import { compose, concat, last, orderBy, prop, propOr, sumBy } from "lodash/fp";
import React, { CSSProperties, Component, useState, useEffect } from "react";
import Modal from "react-modal";
import { match } from "react-router-dom";
import Select from "react-select";

import api from "../../api";
import { history } from "../../utils/history";
import { Button, Text, View } from "../../SharedComponents";
import { Colors } from "../../utils/Colors";
import * as Helpers from "../../utils/Helpers";
import * as Styles from "../../utils/Styles";
import { isMobile } from "react-device-detect";
import SearchBar from "../../components/SearchBar";
import PurchaseDetails from "../../components/PurchaseDetails";
import PurchaseRow from "../../containers/PurchaseRow";
import DisputeEvidenceModal from "../../modals/DisputeEvidenceModal";

import DateRangeSelector from "./components/DateRangeSelector";
import OrderNumberInput from "./components/OrderNumberInput";

// Field needs to be specified for searches
type QueryableField =
    | "STORE_NAME"
    | "ADDRESS"
    | "EMAIL"
    | "NAME"
    | "PHONE_NUMBER"
    | "STRIPE_PAYMENT_INTENT"
    | "STRIPE_CHARGE_ID";
const FIELD_OPTIONS = [
    {
        value: "EMAIL",
        label: "User's email"
    },
    {
        value: "ADDRESS",
        label: "User's Address"
    },
    {
        value: "NAME",
        label: "User's name"
    },
    {
        value: "PHONE_NUMBER",
        label: "User's phone"
    },
    {
        value: "STORE_NAME",
        label: "Store's Name"
    },
    {
        value: "STRIPE_PAYMENT_INTENT",
        label: "Stripe Payment Int."
    },
    {
        value: "STRIPE_CHARGE_ID",
        label: "Stripe Charge ID"
    }
];

// for purchase status
type Status =
    | "NO_STATUS"
    | "NO_STATUS || RECEIVED"
    | "RECEIVED"
    | "STARTED"
    | "CANCELED"
    | "COMPLETED";
const STATUS_OPTIONS = [
    {
        value: "NO_STATUS",
        label: "no status (not received)"
    },
    {
        value: "NO_STATUS || RECEIVED",
        label: "not completed or started"
    },
    {
        value: "RECEIVED",
        label: "received"
    },
    {
        value: "STARTED",
        label: "started"
    },
    {
        value: "CANCELED",
        label: "canceled"
    },
    {
        value: "COMPLETED",
        label: "completed"
    }
];

// TODO: Use this function for the rest of options lists
const toOptionsList = function <T extends string>(options: {
    [key in T]: string;
}) {
    return Object.entries(options).map(([value, label]) => ({
        value,
        label
    }));
};

const FULFILMENT_OPTIONS = toOptionsList<Fulfillment>({
    PICKUP: "Pickup",
    DINE_IN: "Dine in",
    DELIVERY: "Delivery"
});

const SOURCE_OPTIONS = toOptionsList<TransactionSource>({
    app: "App",
    kiosk: "Kiosk",
    online: "Online",
    thirdParty: "Third Party"
});

// TODO: at some point we should make a type for combined Purchase/KioskPurchase
// and use that across projects which have a mix loaded in Redux.
const _parseApiResp = (res: AxiosResponse<any>): IPurchase[] =>
    orderBy(
        prop("purchaseLiveAt"),
        ["desc"],
        concat(res.data.purchases, res.data.kioskPurchases || [])
    );

type Props = {
    purchases: IPurchase[];
    setPurchases: (purchases: IPurchase[]) => void;
    purchasesLoaded?: boolean;
    addPurchases: (purchases: IPurchase[]) => void;
    replacePurchase: (purchase: IPurchase) => void;
    length?: number;
    match: match<{ id: string }>;
    showModal: (modal: string, params: any) => void;
};

type State = {
    field: QueryableField;
    status: Status | null;
    fulfilment: Fulfillment | null;
    transactionSource: TransactionSource | null;
    orderNumber?: number;
    loading: boolean;
    query: string;
    startDate?: Moment;
    endDate?: Moment;
    filteredPurchases: IPurchase[];
    endOfHistory: boolean;
};

const getAmountSpent = compose(Helpers.toDollar, sumBy("amountPaidByCustomer"));

const getDateRange = (
    state: State
): Partial<{ startDate: Date; endDate: Date }> => ({
    startDate: state.startDate?.toDate(),
    endDate: state.endDate?.toDate()
});

const Stats = ({ purchases }: { purchases: IPurchase[] }) => (
    <View
        style={{
            position: "fixed",
            padding: 15,
            backgroundColor: Colors.green,
            borderRadius: 8,
            ...Styles.shadowLight,
            bottom: 20,
            right: 20,
            flexDirection: "row"
        }}
    >
        <Text bold color={Colors.white}>
            {purchases.length}
        </Text>
        <Text style={{ marginLeft: 20 }} bold color={Colors.white}>
            {getAmountSpent(purchases)}
        </Text>
    </View>
);

export default class Purchases extends Component<Props, State> {
    typingTimer: any;
    state: State = {
        field: "EMAIL",
        status: null,
        fulfilment: null,
        transactionSource: null,
        loading: false,
        query: "",
        filteredPurchases: [],
        endOfHistory: false
    };
    componentDidMount() {
        setTimeout(this.loadInitialPurchases, 500);
        this.setScrollListener();
    }

    /**
     * Checks if user has scrolled to bottom
     */
    setScrollListener = () =>
        $(window).on("scroll", () => {
            const scrollHeight = $(document).height() || 0;
            const scrollPosition =
                ($(window).height() || 0) + ($(window).scrollTop() || 0);
            // when reach bottom of page
            if (scrollHeight - scrollPosition < 50) {
                this.loadMoreRows();
            }
        });

    loadInitialPurchases = () => {
        this.startLoading();

        api.purchases
            .search({ ...getDateRange(this.state) })
            .then((res) => this.props.setPurchases(_parseApiResp(res)))
            .finally(this.endLoading);
    };

    startLoading = () => this.setState({ loading: true, endOfHistory: false });

    endLoading = () => this.setState({ loading: false });

    searchPurchases = (queryableField: QueryableField, query: string) => {
        // check and transform if value is a phone number
        const q = Helpers.transformIfPhoneNumber(query);

        api.purchases
            .search({
                ...getDateRange(this.state),
                field: queryableField,
                query: q
            })
            .then((response) => {
                this.props.setPurchases(_parseApiResp(response));
                this.endLoading();
            });
    };

    loadMoreRows = () => {
        const { purchases } = this.props;
        const { field, endOfHistory, loading, query } = this.state;

        // don't load more if still loading
        if (loading || endOfHistory) {
            return;
        }

        this.startLoading();

        const oldestPurchaseTime = compose(
            propOr(null, "createdAt"),
            last
        )(purchases);

        const baseRequest = {
            before: oldestPurchaseTime,
            ...getDateRange(this.state)
        };

        // use search if query exists
        if (query) {
            // check and transform if value is a phone number
            const q = Helpers.transformIfPhoneNumber(query);

            return api.purchases
                .search({ ...baseRequest, field, query })
                .then((response) => {
                    response.data.purchases.length === 0
                        ? this.setState({ endOfHistory: true })
                        : this.props.addPurchases(_parseApiResp(response));
                    this.endLoading();
                })
                .catch(Sentry.captureException);
        }

        api.purchases
            .search({ ...baseRequest })
            .then((res) => this.props.addPurchases(_parseApiResp(res)))
            .finally(() => setTimeout(this.endLoading, 500));
    };

    handleUpdateDateRange = ({
        startDate,
        endDate
    }: {
        startDate: Moment;
        endDate: Moment;
    }) => {
        this.setState({
            startDate,
            endDate
        });
    };

    handleChangeField = (field: QueryableField) => {
        this.setState({ field: field, endOfHistory: false });
        if (field === this.state.field) {
            return;
        }

        // Clear current query so it doesn't initiate a search without new context (will still load initial)
        this.handleChangeQuery(this.state.query);
    };

    automaticallyInferredField = (query: string): QueryableField | null => {
        if (query.length > 26) {
            if (query.startsWith("ch_")) {
                return "STRIPE_CHARGE_ID";
            } else if (query.startsWith("pi_")) {
                return "STRIPE_PAYMENT_INTENT";
            }
        }
        return null;
    };

    handleChangeQueryString = (query: string) => {
        // We only auto infer fields when the query string changes, this is
        // so the user can manually override the auto inferred field if necessary.
        const automaticallyInferredField =
            this.automaticallyInferredField(query);
        if (
            automaticallyInferredField !== null &&
            automaticallyInferredField !== undefined
        ) {
            this.setState({ field: automaticallyInferredField });
        }
        this.handleChangeQuery(query);
    };

    handleChangeQuery = (query: string) => {
        this.setState({ query: query, endOfHistory: false });
        this.startLoading();
        this.props.setPurchases([]);
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => {
            query
                ? this.searchPurchases(this.state.field, query)
                : this.loadInitialPurchases();
        }, 400);
    };

    handleOrderNumberInputChange = (value?: number) => {
        this.setState({ orderNumber: value });
    };

    filterStatus = (purchase: IPurchase): boolean => {
        const { status } = this.state;
        if (!status) {
            return true;
        }

        const currentStatus: any = compose(
            last,
            propOr([], "status")
            // @ts-ignore seems to be an issue with types for `compose`
        )(purchase);

        // if filtering NO_STATUS, return true only
        // if the currentStatus is undefined
        if (status === "NO_STATUS") {
            return !currentStatus;
        }
        // if the current status is no status or received
        if (status === "NO_STATUS || RECEIVED") {
            return !currentStatus || currentStatus.type === "RECEIVED";
        }
        // otherwise return true only if there is a current status
        // and the type is equal to the filtering status
        return currentStatus && currentStatus.type === status;
    };

    // only filter if this.state.fulfilment is set
    filterFulfilment = (purchase: IPurchase): boolean =>
        !this.state.fulfilment ||
        this.state.fulfilment === purchase.fulfillment;

    // only filter if this.state.transactionSource is set
    filterSource = (purchase: IPurchase): boolean =>
        !this.state.transactionSource ||
        this.state.transactionSource === purchase.transactionSource;

    // only filter if this.state.orderNumber is set
    filterOrder = (purchase: IPurchase): boolean =>
        !this.state.orderNumber ||
        this.state.orderNumber === purchase.receiptNumber;

    render() {
        const { match, purchases, replacePurchase, showModal } = this.props;
        const { endOfHistory, loading, field } = this.state;

        const searchTypeOptions = FIELD_OPTIONS.map((option) => ({
            value: option.value,
            label: option.label
        }));

        const showButton = !loading && !endOfHistory && purchases.length;
        const showLoading = loading && !endOfHistory;

        return (
            <View style={{ ...Styles.panel, width: "100%" }}>
                <ActivePurchaseModal
                    match={match}
                    showModal={showModal}
                    replacePurchase={replacePurchase}
                    startLoading={this.startLoading}
                    endLoading={this.endLoading}
                />
                <DisputeEvidenceModal />
                <div style={styles.upperArea}>
                    <Text large>Purchases 👜</Text>
                    <DateRangeSelector
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        handleUpdateDateRange={this.handleUpdateDateRange}
                    />
                </div>
                <>
                    <div style={styles.searchArea}>
                        <Select
                            value={searchTypeOptions.find(
                                (o) => o.value === field
                            )}
                            isClearable={false}
                            isSearchable={false}
                            styles={{
                                control: (provided: any) => ({
                                    ...provided,
                                    ...styles.selector
                                })
                            }}
                            options={searchTypeOptions}
                            onChange={(option: any) => {
                                this.handleChangeField(option.value);
                            }}
                        />
                        <SearchBar // We will want to change this back to an AsyncSelect, similar to Users search page
                            placeholder="Search purchases by store or customer (select option on the left)"
                            onSearch={(query: string) =>
                                this.handleChangeQueryString(query)
                            }
                            onClear={() => this.handleChangeQueryString("")}
                            containerStyle={styles.searchBarContainer}
                        ></SearchBar>
                    </div>
                    <div style={styles.filterArea}>
                        <Select
                            placeholder="Filter status"
                            isClearable={true}
                            isSearchable={true}
                            options={STATUS_OPTIONS.map((option) => ({
                                value: option.value,
                                label: option.label
                            }))}
                            styles={{
                                control: (provided: any) => ({
                                    ...provided,
                                    ...styles.filter
                                })
                            }}
                            onChange={(option: any) =>
                                this.setState({
                                    status: propOr(null, "value", option)
                                })
                            }
                        />
                        <Select
                            placeholder="Filter fulfilment"
                            isClearable={true}
                            isSearchable={true}
                            options={FULFILMENT_OPTIONS.map((option) => ({
                                value: option.value,
                                label: option.label
                            }))}
                            styles={{
                                control: (provided: any) => ({
                                    ...provided,
                                    ...styles.filter
                                })
                            }}
                            onChange={(option: any) =>
                                this.setState({
                                    fulfilment: propOr(null, "value", option)
                                })
                            }
                        />
                        <Select
                            placeholder="Filter Source"
                            isClearable={true}
                            isSearchable={true}
                            options={SOURCE_OPTIONS.map((option) => ({
                                value: option.value,
                                label: option.label
                            }))}
                            styles={{
                                control: (provided: any) => ({
                                    ...provided,
                                    ...styles.filter
                                })
                            }}
                            onChange={(option: any) =>
                                this.setState({
                                    transactionSource: propOr(
                                        null,
                                        "value",
                                        option
                                    )
                                })
                            }
                        />
                        <OrderNumberInput
                            onChange={this.handleOrderNumberInputChange}
                            placeholder="Order number #:"
                            style={{
                                ...styles.orderNumberInput
                            }}
                        />
                    </div>
                </>
                <hr />
                {purchases
                    .filter(this.filterStatus)
                    .filter(this.filterFulfilment)
                    .filter(this.filterSource)
                    .filter(this.filterOrder)
                    .map((purchase, i) => (
                        <PurchaseRow purchase={purchase} key={i} />
                    ))}
                {showLoading && <Text style={{ margin: 20 }}>🏃‍♀️..</Text>}
                {showButton && (
                    <Button
                        label="load more"
                        onPress={this.loadMoreRows}
                        style={{ marginTop: 20 }}
                    />
                )}
                {endOfHistory && (
                    <Text style={{ margin: 20 }}>End of history ⏳</Text>
                )}
                <Stats purchases={purchases} />
            </View>
        );
    }
}

type ActivePurchaseModalProps = {
    match: match<{ id: string }>;
    showModal: (modal: string, params: any) => void;
    replacePurchase: (purchase: IPurchase) => void;
    startLoading: () => void;
    endLoading: () => void;
};

const ActivePurchaseModal = (props: ActivePurchaseModalProps) => {
    const [activePurchase, setActivePurchase] = useState<IPurchase | null>(
        null
    );
    const [showActivePurchase, setShowActivePurchase] =
        useState<boolean>(false);
    const { match, showModal, replacePurchase, startLoading, endLoading } =
        props;

    const closeActivePurchaseModal = () => {
        setActivePurchase(null);
        setShowActivePurchase(false);
        history.push("/purchases");
    };

    // if there is a purchaseId in url fetch it
    useEffect(() => {
        setTimeout(() => {
            const purchaseId = match.params.id;
            if (purchaseId) {
                try {
                    startLoading();
                    api.purchases.getPurchase(purchaseId).then((response) => {
                        setActivePurchase(response.data.purchase);
                        setShowActivePurchase(true);
                    });
                } catch (err) {
                    console.log(err);
                    Sentry.captureException(err);
                    console.log("Could not fetch purchase in url params.");
                } finally {
                    endLoading();
                }
            }
        }, 500);
    }, []);

    if (!activePurchase) {
        return <React.Fragment />;
    }

    return (
        <Modal
            style={styles.activePurchaseModal}
            isOpen={showActivePurchase}
            onRequestClose={closeActivePurchaseModal}
            contentLabel="Purchase"
        >
            <PurchaseDetails
                showModal={showModal}
                purchase={activePurchase}
                closeModal={closeActivePurchaseModal}
                replacePurchase={replacePurchase}
            />
        </Modal>
    );
};

const styles = {
    upperArea: {
        display: "flex",
        width: "100%",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 10px",
        marginTop: 10,
        marginBottom: 10
    } as CSSProperties,
    searchArea: {
        display: "flex",
        width: "100%",
        alignContent: "center",
        justifyContent: "center",
        height: 40,
        padding: "0 10px",
        marginTop: 10,
        marginBottom: 10
    } as CSSProperties,
    orderNumberInput: {
        borderRadius: 4,
        height: 38,
        width: 200,
        borderWidth: 1
    } as CSSProperties,
    filterArea: {
        display: "flex",
        width: "100%",
        padding: "0 10px",
        flexDirection: isMobile ? "column" : "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        gap: 10,
        alignContent: "center"
    } as CSSProperties,
    searchBarContainer: {
        flex: 2
    },
    selector: {
        width: 160,
        height: 30,
        marginRight: 10,
        cursor: "pointer"
    } as CSSProperties,
    filter: {
        width: 200,
        height: 30,
        margin: 0,
        cursor: "pointer"
    } as CSSProperties,
    activePurchaseModal: {
        content: {
            margin: "auto",
            maxWidth: 500,
            width: "90%",
            left: "5%"
        }
    }
};
