export const TochiColors = [
    "CHICKEN-PRIMARY",
    "CHICKEN-SECONDARY",
    "CHICKEN-DECAL",
    "CHICKEN-HAIR",
    "CHICKEN-CHEEKS",
    "CHICKEN-EYES",
];
export const calculateColorPos = (colors: string[]) => {
    if (!colors.length) return [];
    let iter = 1 / (colors.length - 1);
    iter = Math.round((iter + Number.EPSILON) * 100) / 100;
    let pos = [];
    for (let i = 0; i <= 1; i += iter) pos.push(Number(i.toFixed(2)));
    if (colors.length === pos.length + 1) pos.push(1);
    if (pos[pos.length - 1] < 1) pos[pos.length - 1] = 1;
    return pos;
};
