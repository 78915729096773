import API from "../../api";

export function sendPromotionApprovalLog(
    storeName: string | undefined,
    region: string | undefined,
    promotionName: string | undefined,
    isLive: boolean = true
) {
    if (isLive) {
        return API.logs.send({
            channel: "logs-ops",
            title: `${storeName} in ${region}`,
            description: `Promotion was created: ${promotionName}\nNEEDS APPROVAL 📣`,
            mentions: ["onboarding-on-call"],
        });
    }
    API.logs.send({
        channel: "logs-ops",
        title: `${storeName} in ${region}`,
        description: `Promotion was turned OFF: ${promotionName}`,
    });
}
