import React, { FC, useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { compose } from "lodash/fp";
import { show } from "redux-modal";
import CacheBustingModal from "../modals/CacheBustingModal";

type Props = {
    children?: React.ReactNode;
};

const CacheBusting: FC<Props> = ({ children }) => {
    const dispatch = useDispatch();
    const [version, setVersion] = useState<string>(
        process.env.REACT_APP_COMMIT_REF ?? ""
    );

    const showModal = compose(dispatch, show);

    const getVersion = async () => {
        const minutesSinceEpoch = Math.round(Date.now() / 1000 / 60);
        const resp = await axios.get(`/version.txt?${minutesSinceEpoch}`, {
            baseURL: window.location.origin
        });
        if (resp && resp.data) {
            // text from version.txt is not treated as a string
            if (String(resp.data) !== version) {
                setVersion(resp.data);
                showModal("CacheBustingModal");
            }
        }
    };

    useEffect(() => {
        const interval = setInterval(getVersion, 15 * 60 * 1000);
        window.addEventListener("focus", getVersion);

        void getVersion();
        return () => {
            clearInterval(interval);
            window.removeEventListener("focus", getVersion);
        };
    }, []);

    return (
        <>
            <CacheBustingModal />
            {children}
        </>
    );
};

export default CacheBusting;
