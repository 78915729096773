import React from "react";
import { Colors } from "../../../utils";
import { Button } from "../../../SharedComponents";
import _ from "lodash";
import Table from "react-table";
import moment from "moment";
import { ITablet as IStoreTablet } from "@snackpass/snackpass-types";
import Select from "react-select";
import { IOption } from "../../../redux/types";

type Props = {
    storeToPrimaryTablet: any;
    setPrimaryTablet: (storeTabletId: string) => void;
    setTabletSilentMode: (tabletId: string, silentMode: boolean) => void;
    updateJAMFTablet: (storeTabletId: string) => void;
    onDelete: (storeTabletId: string) => void;
    onEdit: (storeTablet: IStoreTablet) => void;
    storeTablets: IStoreTablet[];
};
type State = {};

export default class StoreTabletsTable extends React.Component<Props, State> {
    storeNameColumn = () => ({
        Header: "Store",
        accessor: "store.name",
        minWidth: 150,
        Filter: ({ onChange, filter }: any) => {
            return (
                <input
                    style={{ width: 125 }}
                    placeholder="Search store"
                    value={filter ? filter.value : ""}
                    onChange={(e) => onChange(e.target.value)}
                />
            );
        },
        filterable: true,
        filterMethod: (filter: any, row: any, column: any) => {
            let name: string = row["store.name"] || "";
            let value: string | null = _.get(filter, "value", "");
            if (value) {
                return name.toLowerCase().includes(value.toLowerCase());
            }
            return true;
        },
        style: { whiteSpace: "unset" }
    });
    nameColumn = () => ({
        Header: "Name",
        accessor: "user.name",
        minWidth: 150,
        Filter: ({ onChange, filter }: any) => {
            return (
                <input
                    style={{ width: 125 }}
                    placeholder="Search name"
                    value={filter ? filter.value : ""}
                    onChange={(e) => onChange(e.target.value)}
                />
            );
        },
        filterable: true,
        filterMethod: (filter: any, row: any, column: any) => {
            let name: string = row["user.name"] || "";
            let value: string | null = _.get(filter, "value", "");
            if (value) {
                return name.toLowerCase().includes(value.toLowerCase());
            }
            return true;
        },
        style: { whiteSpace: "unset" }
    });
    serialColumn = () => ({
        Header: "Serial #",
        accessor: "serial",
        minWidth: 200,
        Filter: ({ onChange, filter }: any) => {
            return (
                <input
                    placeholder="Search for serial #"
                    value={filter ? filter.value : ""}
                    onChange={(e) => onChange(e.target.value)}
                />
            );
        },
        filterable: true,
        filterMethod: (filter: any, row: any, column: any) => {
            let serial: string = row.serial || "";
            let value: string | null = _.get(filter, "value", "");
            if (value) {
                return serial.toLowerCase().includes(value.toLowerCase());
            }
            return true;
        }
    });
    printColumn = () => ({
        Header: "Printer Model",
        accessor: "printer.model",
        minWidth: 125
    });
    printerConnectColumn = () => ({
        Header: "Printer Connection",
        accessor: "printer.isConnected",
        minWidth: 125,
        Cell: (props: any) => {
            return <span>{props.value ? "connected" : "not connected"}</span>;
        }
    });
    lastPingColumn = () => ({
        Header: "Last Ping",
        accessor: "lastPing",
        minWidth: 125,
        Cell: (props: any) => {
            return props.value ? (
                <span>
                    {moment(props.value).format("ddd MM/DD")}
                    <br />
                    {moment(props.value).format("(h:mm:ss a)")}
                </span>
            ) : null;
        }
    });
    batteryColumn = () => ({
        Header: "Battery Level",
        accessor: "battery.isCharging",
        minWidth: 100,
        Cell: (props: any) => {
            return <span>{props.value ? "charging" : "not charging"}</span>;
        }
    });
    batteryPercentageColumn = () => ({
        Header: "Battery %",
        accessor: "battery.level",
        minWidth: 75,
        Cell: (props: any) => {
            return <span>{props.value}%</span>;
        }
    });
    orderColumn = () => ({
        Header: "Orders",
        accessor: "orders",
        minWidth: 100,
        Cell: (props: any) => {
            let orders = props.value;
            return (
                <span>
                    active: {orders.active} <br />
                    pending: {orders.pending}{" "}
                </span>
            );
        }
    });
    objectIdColumns = () => ({
        Header: "Object Id",
        accessor: "_id",
        minWidth: 250,
        Filter: ({ onChange, filter }: any) => {
            return (
                <input
                    placeholder="Search for id"
                    value={filter ? filter.value : ""}
                    onChange={(e) => onChange(e.target.value)}
                />
            );
        },
        filterable: true,
        filterMethod: (filter: any, row: any, column: any) => {
            let tabletId: string = row._id || "";
            let value: string | null = _.get(filter, "value", "");
            if (value) {
                return tabletId.toLowerCase().includes(value.toLowerCase());
            }
            return true;
        }
    });

    silentModeColumn = () => ({
        Header: "Silent Mode",
        accessor: "silentMode",
        id: "silentMode",
        minWidth: 100,
        Cell: (props: any) => {
            const silentMode = Boolean(props.original.silentMode);
            const tabletId = props.original._id;
            return (
                <Button
                    backgroundColor={silentMode ? Colors.red : Colors.green}
                    label={silentMode ? "Disable" : "Enable"}
                    onPress={() =>
                        this.props.setTabletSilentMode(tabletId, !silentMode)
                    }
                />
            );
        }
    });

    setPrimary = () => ({
        Header: "Primary",
        accessor: "_id",
        id: "primary",
        minWidth: 130,
        Cell: (props: any) => {
            let storeTabletId: string = props.original._id;
            let storeId = props.original.store._id;

            let isPrimaryTablet: boolean =
                this.props.storeToPrimaryTablet[storeId] === storeTabletId;
            return (
                <Button
                    onPress={() => this.props.setPrimaryTablet(storeTabletId)}
                    backgroundColor={
                        isPrimaryTablet ? Colors.red : Colors.green
                    }
                    label={isPrimaryTablet ? "Unset primary" : "Set Primary"}
                />
            );
        },
        Filter: ({ onChange, filter }: any) => {
            return (
                <Select
                    name="type"
                    placeholder="filter"
                    isClearable={true}
                    className="snackpass__react-select"
                    options={[
                        {
                            value: "PRIMARY",
                            label: "Primary"
                        },
                        { value: "NOT_PRIMARY", label: "Not primary" }
                    ]}
                    styles={{
                        // this is needed or else the options are behind
                        // the table and cannot select them
                        menu: () => ({}),
                        container: () => ({ width: 120 })
                    }}
                    onChange={(option: IOption) => {
                        onChange(option ? option.value : null);
                    }}
                />
            );
        },
        filterable: true,
        filterMethod: (filter: any, row: any, column: any) => {
            let storeTabletId = row._id;
            let storeId = _.get(row._original, "store._id", "");
            let value = _.get(filter, "value", null);
            if (value === "PRIMARY")
                return (
                    this.props.storeToPrimaryTablet[storeId] === storeTabletId
                );
            if (value === "NOT_PRIMARY")
                return (
                    this.props.storeToPrimaryTablet[storeId] !== storeTabletId
                );
            return true;
        }
    });
    updateJAMF = () => ({
        Header: "JAMF",
        accessor: "",
        id: "jamf",
        minWidth: 100,
        Cell: (props: any) => {
            let storeTabletId: string = props.original._id;
            let hasJAMF: boolean =
                props.original.JAMFId !== null &&
                props.original.JAMFId !== undefined;

            return (
                <Button
                    onPress={() => this.props.updateJAMFTablet(storeTabletId)}
                    backgroundColor={hasJAMF ? Colors.red : Colors.green}
                    label={hasJAMF ? "Unset JAMF" : "Update JAMF"}
                />
            );
        }
    });
    deleteColumn = () => ({
        Header: "Delete",
        accessor: "",
        id: "delete",
        minWidth: 100,
        Cell: (props: any) => {
            let storeTabletId: string = props.original._id;
            return (
                <Button
                    disabled
                    onPress={() => {
                        this.props.onDelete(storeTabletId);
                    }}
                    backgroundColor={Colors.red}
                    label="Delete"
                />
            );
        }
    });
    buildColumns = () => {
        return [
            this.storeNameColumn(),
            this.nameColumn(),
            this.serialColumn(),
            this.printColumn(),
            this.printerConnectColumn(),
            this.lastPingColumn(),
            this.batteryColumn(),
            this.batteryPercentageColumn(),
            this.orderColumn(),
            this.objectIdColumns(),
            this.silentModeColumn(),
            this.setPrimary(),
            this.updateJAMF(),
            this.deleteColumn()
        ];
    };
    render() {
        const { storeTablets } = this.props;
        return (
            <Table
                style={{ textAlign: "center" }}
                data={storeTablets}
                loading={false}
                className="-striped scrollable-x"
                defaultPageSize={20}
                columns={this.buildColumns()}
            />
        );
    }
}
