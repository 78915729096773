import React, { Component } from "react";
import { Text } from "../../../SharedComponents";

import { Colors } from "../../../utils/Colors";

import { isOpen } from "../../../utils/TimeUtil";
import { IStore } from "@snackpass/snackpass-types";

type Props = {
    storeObj: IStore;
};
export default class OnlineStatus extends Component<Props> {
    static defaultProps = {
        storeObj: {},
    };
    render() {
        const { storeObj } = this.props;
        if (!isOpen(storeObj.hours)) {
            return (
                <Text small color={Colors.gray}>
                    Closed
                </Text>
            );
        }
        return (
            <Text
                small
                color={storeObj.tabletOffline ? Colors.red : Colors.green}
            >
                {storeObj.tabletOffline ? "Offline" : "Online"}
            </Text>
        );
    }
}
